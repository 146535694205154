import { gql } from "apollo-boost"
import { deleteVehicle, deleteVehicleVariables } from "../generated/deleteVehicle"

export const DELETE_VEHICLE_MUTATION = gql`
  mutation deleteVehicle($vehicleId: ID!) {
    deleteVehicle(vehicleId: $vehicleId)
  }
`

export type DeleteVehicleResult = deleteVehicle
export type DeleteVehicleVariables = deleteVehicleVariables
