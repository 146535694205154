import { gql } from "apollo-boost"

export const FRAGMENT_DAILY_GROUPED_STATS_FOR_ANALYSIS = gql`
  fragment FragmentDailyGroupedStatsForAnalysis on DailyGroupedStatsForAnalysis {
    max {
      value
    }
    min {
      value
    }
    overall
    date
  }
`
export const FRAGMENT_DAILY_GROUPED_STATS_FOR_ANALYSIS_WITH_TOWN = gql`
  fragment FragmentDailyGroupedStatsForAnalysisWithTown on DailyGroupedStatsForAnalysis {
    max {
      value
      town {
        id
        name
      }
    }
    min {
      value
      town {
        id
        name
      }
    }
    overall
    date
  }
`
