import React, { FunctionComponent, Fragment, useContext } from "react"
import { FormControl, Grid, InputLabel, Select, MenuItem, makeStyles, Theme, Button } from "@material-ui/core"
import { useTranslation } from "react-i18next"
import lodash from "lodash"
import WertstoffDropdown from "../controls/WertstoffDropdown.jsx"
import { WertstoffDatePicker } from "../controls/WertstoffDatePicker"
import { getFilterDates } from "../analysis-filter/helpers/getFilterDates"
import { TourFilter, TourType } from "../../../api/graphql/generated/globalTypes"
import { useQuery } from "react-apollo"
import { VEHICLES_QUERY, VehiclesResult } from "../../../api/graphql/query/vehicles"
import { TourFilterContext } from "../../../context/TourFilterContext"
import { getISOString } from "../../../utils/get-iso-string"
import { PermittedAssociationsContext } from "../../../context/PermittedAssociationsContext"

const useStyles = makeStyles((theme: Theme) => ({
  itemContainer: {
    height: "100%",
    paddingBottom: theme.spacing(1),
  },
}))

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: "100%",
    },
  },
  getContentAnchorEl: null,
}

interface ISelectOption {
  id: string | undefined
  name: string
}
interface ISelectTarget {
  value: ISelectOption
}

interface ITourFilterComponentProps {}

export const TourFilterComponent: FunctionComponent<ITourFilterComponentProps> = (props) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { filter, setFilter, setApplyFilter } = useContext(TourFilterContext)

  const yesNoOptions = [
    {
      id: "yes",
      name: t("tour_page.filter.has_data"),
      value: true,
    },
    {
      id: "no",
      name: t("tour_page.filter.doesnt_have_data"),
      value: false,
    },
  ]

  const tourTypeOptions = [
    {
      id: TourType.residual,
      name: t("analysis_page.filter_tourtype_residual"),
    },
    {
      id: TourType.organic,
      name: t("analysis_page.filter_tourtype_organic"),
    },
  ]

  const { minDateFrom, minDateUntil, maxDateFrom, maxDateUntil } = getFilterDates(
    filter?.timeRange?.end,
    filter?.timeRange?.start,
  )

  const { permittedAssociations } = useContext(PermittedAssociationsContext)
  const { data: vehiclesData } = useQuery<VehiclesResult>(VEHICLES_QUERY, { fetchPolicy: "network-only" })

  const wasteScannerIdOptions: ISelectOption[] = lodash.compact(
    (vehiclesData?.me?.vehicles || []).map((v) => {
      const wasteScannerId = v?.wasteScannerId
      if (!lodash.isString(wasteScannerId) || lodash.isEmpty(wasteScannerId)) return null
      return { id: wasteScannerId, name: wasteScannerId }
    }),
  )

  const permittedAssociationsOptions: ISelectOption[] =
    permittedAssociations.map((pm) => {
      return { id: pm.associationId, name: pm.name }
    }) ?? []

  const permittedTowns: ISelectOption[] =
    permittedAssociations
      .find((pm) => pm.associationId === filter.associationId)
      ?.towns.map((town) => {
        return { id: town.id, name: town.name }
      }) ?? []
  let townOptions: ISelectOption[] = [{ id: undefined, name: t("general.all").toUpperCase() }]
  townOptions = townOptions.concat(...permittedTowns)

  const handleChangeTowns = (event: React.ChangeEvent<{ name?: string | undefined; value: unknown }>) => {
    const optionsSelected: string[] = lodash.get(event, "target.value", [])
    const allTownIds = lodash.compact(townOptions.map((pt) => pt.id))
    if (optionsSelected.some((o) => o === undefined)) {
      // "All" option selected
      setFilter({ ...filter, townIds: [] })
      return
    }
    const townIds: string[] = allTownIds.filter((ptId) => optionsSelected.includes(ptId))
    setFilter({ ...filter, townIds: !!townIds ? townIds : [] })
  }

  return (
    <Fragment>
      {/* VIEW */}
      <Grid container spacing={2}>
        <Grid item xs={2}>
          <WertstoffDropdown
            onChange={(event: React.MouseEvent<ISelectTarget>) =>
              setFilter({ ...filter, tourType: lodash.get(event, "target.value") })
            }
            value={filter.tourType ?? null}
            values={tourTypeOptions}
            allPossible={true}
            name={t("analysis_page.filter_tourtype_name")}
          />
        </Grid>

        <Grid item xs={2}>
          <WertstoffDropdown
            onChange={(event: React.MouseEvent<ISelectTarget>) => {
              const associationId: string | null = lodash.get(event, "target.value")
              setFilter({ ...filter, associationId, townIds: [] })
            }}
            value={filter.associationId ?? null}
            values={permittedAssociationsOptions}
            allPossible={true}
            name={t("analysis_page.filter.association")}
          />
        </Grid>
        <Grid item xs={2}>
          <FormControl disabled={lodash.isNil(filter.associationId)} fullWidth={true} margin={"normal"}>
            <InputLabel id="analysis_page.filter.town.label">{t("analysis_page.filter.town")}</InputLabel>
            <Select
              labelId="analysis_page.filter.town.label"
              id="analysis_page.filter.town"
              multiple
              value={filter.townIds ?? []}
              onChange={handleChangeTowns}
              fullWidth
              MenuProps={MenuProps}
            >
              {townOptions.map((town, index) => (
                <MenuItem key={`${town.id} ${index}`} value={town.id}>
                  {town.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={2}>
          <WertstoffDropdown
            onChange={(event: React.MouseEvent<ISelectTarget>) => {
              const wasteScannerId: string | null = lodash.get(event, "target.value")
              setFilter({ ...filter, wasteScannerId })
            }}
            value={filter.wasteScannerId ?? null}
            values={wasteScannerIdOptions}
            allPossible={true}
            name={t("analysis_page.filter.waste_scanner")}
          />
        </Grid>
        <Grid item xs={2}>
          <WertstoffDatePicker
            name={t("analysis_page.filter.period_from")}
            minDate={minDateFrom}
            maxDate={maxDateFrom}
            value={getISOString(filter.timeRange?.start)}
            onChange={(date) => {
              let newFilter: TourFilter = lodash.cloneDeep(filter)
              if (!newFilter.timeRange) newFilter.timeRange = {}
              newFilter.timeRange.start = date?.startOf("d").toISOString() ?? null
              setFilter(newFilter)
            }}
          />
        </Grid>
        <Grid item xs={2}>
          <WertstoffDatePicker
            name={t("analysis_page.filter.period_to")}
            minDate={minDateUntil}
            maxDate={maxDateUntil}
            value={getISOString(filter.timeRange?.end)}
            onChange={(date) => {
              let newFilter: TourFilter = lodash.cloneDeep(filter)
              if (!newFilter.timeRange) newFilter.timeRange = {}
              newFilter.timeRange.end = date?.endOf("d").toISOString() ?? null
              setFilter(newFilter)
            }}
          />
        </Grid>

        <Grid item xs={2}>
          <WertstoffDropdown
            customKey="value"
            onChange={(event: React.MouseEvent<ISelectTarget>) =>
              setFilter({ ...filter, hasAnalysis: lodash.get(event, "target.value") })
            }
            value={filter.hasAnalysis ?? null}
            values={yesNoOptions}
            allPossible={true}
            name={t("tour.property.analysis_data")}
          />
        </Grid>
        <Grid item xs={2}>
          <WertstoffDropdown
            customKey="value"
            onChange={(event: React.MouseEvent<ISelectTarget>) =>
              setFilter({ ...filter, hasRfidReader: lodash.get(event, "target.value") })
            }
            value={filter.hasRfidReader ?? null}
            values={yesNoOptions}
            allPossible={true}
            name={t("tour.property.rfidReader")}
          />
        </Grid>
        <Grid item xs={2}></Grid>
        <Grid item xs={2}></Grid>
        <Grid item xs={2}></Grid>
        <Grid item xs={2}>
          <Grid
            container
            justify="flex-end"
            alignContent="flex-end"
            alignItems="flex-end"
            className={classes.itemContainer}
          >
            <Button variant="contained" color="primary" onClick={() => setApplyFilter(true)} fullWidth>
              {t("general.apply")}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Fragment>
  )
}
