import React, { FunctionComponent } from "react"
import { Theme, makeStyles, Grid, Typography, SvgIconTypeMap, Tooltip } from "@material-ui/core"
import { useTranslation } from "react-i18next"
import { EdaQueue } from "../../../api/graphql/query/eda-queues"
import { Cached, MarkunreadMailbox, Timer } from "@material-ui/icons"
import { OverridableComponent } from "@material-ui/core/OverridableComponent"

const useStyles = makeStyles((theme: Theme) => ({
  iconCircle: {
    position: "absolute",
    top: 0,
    right: 0,
    height: 30,
    width: 30,
    borderRadius: 90,
    backgroundColor: theme.palette.secondary.main,
    color: "white",
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
  },
  circle: {
    position: "relative",
    height: 85,
    width: 85,
    borderRadius: 90,
    backgroundColor: theme.palette.secondary.light,
    color: theme.palette.secondary.main,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  circleContainer: {
    marginTop: theme.spacing(2),
  },
  container: {
    height: "100%",
  },
  icon: {
    height: 20,
    width: 20,
  },
}))

const Circle: FunctionComponent<{
  content: string | number | undefined
  tooltipTitle: string
  Icon: OverridableComponent<SvgIconTypeMap<{}, "svg">>
}> = (props) => {
  const { content, Icon, tooltipTitle } = props
  const classes = useStyles()
  return (
    <Tooltip title={tooltipTitle} placement="right" arrow>
      <Grid container className={classes.circle}>
        <div className={classes.iconCircle}>
          <Icon className={classes.icon} />
        </div>
        <Grid item>
          <Typography>{content}</Typography>
        </Grid>
      </Grid>
    </Tooltip>
  )
}

interface IEdaBoardLaneQueueContentProps {
  queue: EdaQueue | undefined
  loading: boolean
}

export const EdaBoardLaneQueueContent: FunctionComponent<IEdaBoardLaneQueueContentProps> = (props) => {
  const { queue } = props
  const classes = useStyles()
  const { t } = useTranslation()
  return (
    <Grid container justify="center" direction="row">
      <Grid item container justify="center" className={classes.circleContainer}>
        <Circle
          content={queue?.attributes.approximateNumberOfMessages}
          Icon={MarkunreadMailbox}
          tooltipTitle={t("eda_page.approximateNumberOfMessages_tooltip", {
            count: queue?.attributes.approximateNumberOfMessages,
          })}
        />
      </Grid>

      <Grid item container justify="center" className={classes.circleContainer}>
        <Circle
          content={queue?.attributes.approximateNumberOfMessagesNotVisible}
          Icon={Cached}
          tooltipTitle={t("eda_page.approximateNumberOfMessagesNotVisible_tooltip", {
            count: queue?.attributes.approximateNumberOfMessagesNotVisible,
          })}
        />
      </Grid>
      <Grid item container justify="center" className={classes.circleContainer}>
        <Circle
          content={`${queue?.attributes.delaySeconds}s`}
          Icon={Timer}
          tooltipTitle={t("eda_page.delaySeconds_tooltip", {
            count: queue?.attributes.delaySeconds,
          })}
        />
      </Grid>
    </Grid>
  )
}
