import React from "react"
import { Mutation } from "react-apollo"
import Button from "@material-ui/core/Button"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogContentText from "@material-ui/core/DialogContentText"
import CircularProgress from "@material-ui/core/CircularProgress"
import DialogTitle from "@material-ui/core/DialogTitle"
import { v4 } from "uuid"
import SnackbarWrapper from "../snackbar/SnackbarWrapper"
import dot from "../../../utils/dot-prop-immutable"
import { MUTATION as DELETE_REPORT_MUTATION } from "../../../api/graphql/mutation/delete-report"
import { withTranslation } from "react-i18next"

class ReportDeleteDialog extends React.Component {
  state = {
    key: `rd-${v4()}`,
    title: "",
    errorMessage: null,
    inProgress: false,
  }

  handleClose = () => {
    if (this.props.onClose) this.props.onClose()

    this.setState({ inProgress: false, errorMessage: null })
  }

  handleDeleteReportComplete = () => {
    if (this.props.onComplete) this.props.onComplete()
    this.handleClose()
  }

  handleDeleteReportError = (error) => {
    this.setState({ inProgress: false, errorMessage: error.message })
  }

  createDeleteHandler = (mutation) => () => {
    if (!this.props.report) {
      this.handleClose()
      return
    }

    this.setState({ inProgress: true, errorMessage: null })
    mutation({ variables: { id: this.props.report.id } })
  }

  render() {
    const { t } = this.props

    return (
      <Dialog open={this.props.open} onClose={this.handleClose} aria-labelledby={this.state.key}>
        <DialogTitle id={this.state.key}>{t("report.delete")}</DialogTitle>
        <DialogContent>
          {this.state.errorMessage && (
            <SnackbarWrapper
              variant="error"
              message={"Beim Löschen trat ein Fehler auf!"}
              style={{ marginBottom: "12px" }}
            />
          )}
          <DialogContentText>
            {t("report.delete_description", {
              report: dot.get(this.props, "report.title"),
            })}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button disabled={this.state.inProgress} onClick={this.handleClose} color="primary">
            {t("general.no")}
          </Button>
          <Mutation
            mutation={DELETE_REPORT_MUTATION}
            onCompleted={this.handleDeleteReportComplete}
            onError={this.handleDeleteReportError}
          >
            {(deleteReport) => (
              <Button
                variant="contained"
                disabled={this.state.inProgress}
                onClick={this.createDeleteHandler(deleteReport)}
                color="primary"
              >
                {this.state.inProgress && <CircularProgress size={20} style={{ marginRight: "12px" }} />}
                {t("general.yes_delete")}
              </Button>
            )}
          </Mutation>
        </DialogActions>
      </Dialog>
    )
  }
}

export default withTranslation()(ReportDeleteDialog)
