import { gql } from "apollo-boost"
import { classifiers, classifiers_classifiers } from "../generated/classifiers"

export const CLASSIFIERS_QUERY = gql`
  query classifiers {
    classifiers {
      id
      tourType
      title
      deployedAt
      classes {
        materialType
        translations {
          de
          en
        }
      }
    }
  }
`

export type ClassifiersResult = classifiers
export type Classifier = classifiers_classifiers
