import React, { createContext, useContext, useEffect, useRef } from "react"
import { useQuery } from "react-apollo"
import { AnalysisFilterContext } from "./AnalysisFilterContext"
import { BookmarksForUserResult, BOOKMARKS_FOR_USER } from "../api/graphql/query/bookmarks-for-user"
import { useBookmarkEffect, useUrlChangeEffect, useUrlUpdateEffect } from "../custom-hooks/AnalysisFilter"

export interface IAnalysisUrlContext {}
export const AnalysisUrlContext = createContext<IAnalysisUrlContext>({})

interface IAnaylsisUrlProvider extends IAnalysisUrlContext {
  children: JSX.Element[] | JSX.Element | null
}

export const AnalysisUrlProvider = (props: IAnaylsisUrlProvider) => {
  const { filterModel, selectedBookmark, setSelectedBookmark, setApplyFilter } = useContext(AnalysisFilterContext)
  const { data: bookmarksForUser } = useQuery<BookmarksForUserResult>(BOOKMARKS_FOR_USER, {
    fetchPolicy: "network-only",
  })
  const selectedBookmarkId = useRef<string | null>(null)
  const isLoaded = useRef(false)

  // (Execution Order: does not matter) Executed when bookmarks are loaded async → Updates selected bookmark
  useBookmarkEffect(selectedBookmarkId, setSelectedBookmark, bookmarksForUser)

  // (Execution Order: 1) Executed when hash changes → Updates state / context
  useUrlChangeEffect(selectedBookmarkId, selectedBookmark, filterModel)

  // (Execution Order: 2) Executed when context / state changes → Updates URL hash (!only when data changed = compare formatted form of values)
  useUrlUpdateEffect(isLoaded, selectedBookmarkId, selectedBookmark, filterModel)

  // (Execution Order: 3) Prevent loop on first page load when URL & context would infinitely update each other
  useEffect(() => {
    isLoaded.current = true
    setApplyFilter(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return <AnalysisUrlContext.Provider value={{}}>{props.children}</AnalysisUrlContext.Provider>
}
