import { gql } from "apollo-boost"
import {
  deleteCitizen,
  deleteCitizenVariables
} from "../generated/deleteCitizen"

export const DELETE_CITIZEN_MUTATION = gql`
  mutation deleteCitizen($id: ID!) {
    deleteCitizen(id: $id) {
      error
      message
    }
  }
`

export type DeleteCitizenResult = deleteCitizen
export type DeleteCitizenVariables = deleteCitizenVariables
