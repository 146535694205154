/*global google*/
import React, { useContext, useMemo } from "react"
import { compose, withProps } from "recompose"
import { withScriptjs, withGoogleMap, GoogleMap } from "react-google-maps"
import HeatmapLayer from "react-google-maps/lib/components/visualization/HeatmapLayer"
import { mapStyleSilver } from "../../styled/mapStyleSilver"
import { AnalysisFilterContext } from "../../../context/AnalysisFilterContext"
import { getZoomFromBounds, MIN_ZOOM } from "../../../utils/map.js"
import { MaterialAnalysisForMap } from "../../../api/graphql/query/material-analysis-for-map"
import { config } from "../../../utils/config"

interface IBounds {
  ne: {
    lat: number | null
    lng: number | null
  }
  sw: {
    lat: number | null
    lng: number | null
  }
}

export const latLongGraz = { lat: 47.0707, lng: 15.4395 }
export const zoomGraz = 12

const generateHeatmaps = (analysisData: MaterialAnalysisForMap[]) => {
  let analysisDataByRating: { [key: string]: MaterialAnalysisForMap[] } = analysisData.reduce(function (r, a) {
    let aRating = a.calculated.rating
    r[aRating] = r[aRating] || []
    r[aRating].push(a)
    return r
  }, {})

  return Object.entries(analysisDataByRating).map((rating) => {
    let points = getPoints(rating[1])
    let gradient = getGradient(rating[0])
    return (
      <HeatmapLayer
        key={rating[0]}
        data={points}
        options={{
          gradient: gradient,
          data: points,
          maxIntensity: 1.25,
          radius: 10,
        }}
      />
    )
  })
}

const calculateCenterAndBounds = (analysisData: MaterialAnalysisForMap[], width) => {
  if (analysisData.length === 1) {
    return {
      center: {
        lat: analysisData[0].metadata.location?.latitude,
        lng: analysisData[0].metadata.location?.longitude,
      },
      zoom: MIN_ZOOM,
    }
  }

  if (analysisData.length > 0) {
    const bounds: IBounds = {
      ne: {
        lat: null,
        lng: null,
      },
      sw: {
        lat: null,
        lng: null,
      },
    }

    analysisData.forEach((analysis) => {
      const latitude = analysis.metadata.location?.latitude
      const longitude = analysis.metadata.location?.longitude

      if (!!latitude && !!longitude) {
        if (bounds.ne.lat === null || bounds.ne.lat < latitude) {
          bounds.ne.lat = latitude
        } else if (bounds.sw.lat === null || bounds.sw.lat > latitude) {
          bounds.sw.lat = latitude
        }

        if (bounds.ne.lng === null || bounds.ne.lng < longitude) {
          bounds.ne.lng = longitude
        } else if (bounds.sw.lng === null || bounds.sw.lng > longitude) {
          bounds.sw.lng = longitude
        }
      }
    })

    const latlngBounds = new google.maps.LatLngBounds(
      new google.maps.LatLng(bounds.sw.lat!, bounds.sw.lng!),
      new google.maps.LatLng(bounds.ne.lat!, bounds.ne.lng!),
    )

    const zoomLevel = Math.min(getZoomFromBounds(bounds, 400, width) - 1, MIN_ZOOM)

    return {
      center: {
        lat: latlngBounds.getCenter().lat(),
        lng: latlngBounds.getCenter().lng(),
      },
      zoom: zoomLevel,
    }
  }
  return {}
}

const getPoints = (analysisData: MaterialAnalysisForMap[]) => {
  return analysisData.map((entry) => {
    const latitude = entry.metadata.location?.latitude
    const longitude = entry.metadata.location?.longitude
    if (!latitude || !longitude) {
      return null
    }
    return new google.maps.LatLng(latitude, longitude)
  })
}

const getGradient = (rating: string) => {
  switch (rating) {
    case "High":
    case "H":
    case "G":
      return ["rgba(255, 255, 255, 0)", "#79d475", "#40bc3b"]
    case "Medium":
    case "M":
      return ["rgba(255, 255, 255, 0)", "#f2d665", "#ebc116"]
    case "Low":
    case "L":
    case "S":
      return ["rgba(255, 255, 255, 0)", "#CE3E47", "#C20E1A"]
    case "Unknown":
    default:
      return ["rgba(255, 255, 255, 0)", "rgba(0, 176, 176, 255)", "rgba(0, 125, 125, 255)"]
  }
}

interface IAnalysisMapProps {
  materialAnalysis: MaterialAnalysisForMap[]
  width?: number
}

export const AnalysisMap = (props: IAnalysisMapProps) => {
  const { permittedAssociations, persistedAssociation, persistedTowns } = useContext(AnalysisFilterContext)

  const MyMap = useMemo(
    () =>
      compose(
        withProps({
          googleMapURL: `https://maps.googleapis.com/maps/api/js?key=${config.REACT_APP_GOOGLE_MAPS_API_KEY}&v=3.exp&libraries=geometry,drawing,places,visualization`,
          loadingElement: <div style={{ height: `400px` }} />,
          containerElement: <div style={{ height: `400px` }} />,
          mapElement: <div style={{ height: `400px` }} />,
        }),
        withScriptjs,
        withGoogleMap,
      )((props: IAnalysisMapProps) => {
        const { center, zoom } = calculateCenterAndBounds(props.materialAnalysis, props.width)

        return (
          <GoogleMap
            defaultZoom={zoom}
            defaultCenter={center}
            options={{
              styles: mapStyleSilver,
              scrollwheel: false,
              streetViewControl: false,
              scaleControl: true,
            }}
          >
            {generateHeatmaps(props.materialAnalysis)}
          </GoogleMap>
        )
      }),
    [persistedAssociation, persistedTowns, permittedAssociations],
  )
  return <MyMap {...props} />
}
