import { AssociationWithBookmarks } from "../../../../../api/graphql/query/bookmarks-for-user"

export const filterAssociationBookmarks = (
  associations: AssociationWithBookmarks[]
): AssociationWithBookmarks[] => {
  return associations
    .map(association => {
      const filteredTowns = association.towns.filter(
        town => town.filters.length > 0
      )
      return {
        ...association,
        towns: filteredTowns
      }
    })
    .filter(
      association =>
        association.filters.length > 0 || association.towns.length > 0
    )
}
