import { gql } from "apollo-boost"
import {
  usersAndAssignedAssociations,
  usersAndAssignedAssociations_usersAndAssignedAssociations,
  usersAndAssignedAssociations_usersAndAssignedAssociations_permittedAssociations,
  usersAndAssignedAssociations_usersAndAssignedAssociations_user,
  usersAndAssignedAssociations_usersAndAssignedAssociations_user_vehicles,
  usersAndAssignedAssociations_usersAndAssignedAssociations_user_housingManagements,
} from "../generated/usersAndAssignedAssociations"
import { FRAGMENT_ASSOCIATION_WITH_TOWNS } from "../fragments/association-with-town"

export const USERS_AND_ASSIGNED_ASSOCIATIONS_QUERY = gql`
  query usersAndAssignedAssociations {
    usersAndAssignedAssociations {
      user {
        id
        email
        name
        abilities
        isPasswordSet
        vehicles {
          wasteScannerId
          id
        }
        defaultTown {
          id
          name
        }
        housingManagements {
          id
          name
        }
      }
      permittedAssociations {
        ...FragmentAssociationWithTowns
        accessToAllTowns
        associationId
      }
    }
  }
  ${FRAGMENT_ASSOCIATION_WITH_TOWNS}
`

export type UsersAndAssignedAssociationsResult = usersAndAssignedAssociations
export type UserAndPermissions = usersAndAssignedAssociations_usersAndAssignedAssociations
export type UserAndPermissionsUser = usersAndAssignedAssociations_usersAndAssignedAssociations_user
export type UserAndPermissionsUserVehicle = usersAndAssignedAssociations_usersAndAssignedAssociations_user_vehicles
export type UserAndPermissionsUserHousingManagement = usersAndAssignedAssociations_usersAndAssignedAssociations_user_housingManagements
export type UserAndPermissionsAssociation = usersAndAssignedAssociations_usersAndAssignedAssociations_permittedAssociations
