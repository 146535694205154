import lodash from "lodash"
import { basicFractionArray, FractionInfo, MaterialDistributionEnum } from "../../constants/Constants"
import { TFunction } from "i18next"
import { TourType } from "../../api/graphql/generated/globalTypes"

export interface TourTypeInfo {
  type: TourType
  title: string
  fraction: FractionInfo
}

export const TourTypeDictionary: TourTypeInfo[] = [
  {
    type: TourType.residual,
    title: "tour.types.residual",
    fraction: lodash.find(basicFractionArray, { id: MaterialDistributionEnum.Residual })!,
  },
  {
    type: TourType.organic,
    title: "tour.types.organic",
    fraction: lodash.find(basicFractionArray, { id: MaterialDistributionEnum.Organic })!,
  },
]

export function titleForTourType(tourTypeEnumValue: string | null, t: TFunction): string {
  switch (tourTypeEnumValue) {
    case TourType.residual:
      return t("tour.types.residual")
    case TourType.organic:
      return t("tour.types.organic")
    default:
      return t("general.waste")
  }
}

export function tourTypeInfoForValue(value: string | TourType): TourTypeInfo | null {
  return TourTypeDictionary.find((entry) => entry.type === value) || null
}
