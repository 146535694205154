import React, { FunctionComponent, Fragment, useState, useEffect, useContext } from "react"
import { Button, Grid } from "@material-ui/core"
import { useTranslation } from "react-i18next"
import { RootGrid } from "../partials/layout/RootGrid"
import { UserAndPermissionsAssociation } from "../../api/graphql/query/permitted-associations-for-user"
import { PageLoadingIndicator } from "../partials/indicator/PageLoadingIndicator"
import lodash from "lodash"
import { AssociationExpansionPanelWithTownTable } from "../partials/associaton-management/AssociationExpansionPanelWithTownTable"
import { ManageAssociationDialog } from "../partials/associaton-management/ManageAssociationDialog"
import { TownForAssociation } from "../../api/graphql/query/towns-for-association"
import { TopBar } from "../partials/heading/TopBar"
import { PermittedAssociationsContext } from "../../context/PermittedAssociationsContext"
import { useLazyQuery } from "react-apollo"
import {
  DefaultClassifiersForAssociationQueryResult,
  DefaultClassifiersForAssociationQueryVariables,
  DEFAULT_CLASSIFIERS_FOR_ASSOCIATION_QUERY,
} from "../../api/graphql/query/defaultClassifiersForAssociation"
import { ClassifierForTourTypeInput } from "../../api/graphql/generated/globalTypes"

interface IAssociationManagementPageProps {}

export const AssociationManagementPage: FunctionComponent<IAssociationManagementPageProps> = (props) => {
  const { t } = useTranslation()
  const [initialData, setInitialData] = useState<ClassifierForTourTypeInput[]>([])

  const {
    permittedAssociationsLoading,
    permittedAssociationsError,
    permittedAssociations,
    refetchPermittedAssociations,
  } = useContext(PermittedAssociationsContext)

  if (permittedAssociationsError) {
    console.log(permittedAssociationsError)
  }

  // Association Management Dialog Stuff
  const [associationManagementDialogOpen, setAssociationManagementDialogOpen] = useState<boolean>(false)

  const [townsForAssociationToEdit, setTownsForAssociationToEdit] = useState<TownForAssociation[] | undefined>(
    undefined,
  )

  const [associationToEdit, setAssociationToEdit] = useState<UserAndPermissionsAssociation | undefined>(undefined)

  const onShouldReload = async () => {
    return refetchPermittedAssociations()
  }

  const [fetchInitialClassifiers] = useLazyQuery<
    DefaultClassifiersForAssociationQueryResult,
    DefaultClassifiersForAssociationQueryVariables
  >(DEFAULT_CLASSIFIERS_FOR_ASSOCIATION_QUERY, {
    onCompleted: (data) => {
      setInitialData(data.defaultClassifiersForAssociation)
    },
    fetchPolicy: "no-cache",
  })

  useEffect(() => {
    setAssociationManagementDialogOpen(!lodash.isNil(associationToEdit))
  }, [associationToEdit])

  const onAssociationManagementDialogClose = () => {
    setAssociationManagementDialogOpen(false)
    setAssociationToEdit(undefined)
    setTownsForAssociationToEdit(undefined)
  }

  const onAssociationManagementDialogSuccess = () => {
    onAssociationManagementDialogClose()
    refetchPermittedAssociations()
  }

  const onSetAssociationToEdit = (association: UserAndPermissionsAssociation) => {
    fetchInitialClassifiers({
      variables: {
        associationId: association.associationId,
      },
    })
    setAssociationToEdit(association)
  }

  const onCreateNewAssociation = () => {
    setInitialData([])
    setAssociationManagementDialogOpen(true)
  }

  return (
    <Fragment>
      {/* DIALOGS */}
      {associationManagementDialogOpen && (
        <ManageAssociationDialog
          onCloseDialog={onAssociationManagementDialogClose}
          onSuccess={onAssociationManagementDialogSuccess}
          isOpen={associationManagementDialogOpen}
          association={associationToEdit}
          towns={townsForAssociationToEdit}
          initialData={initialData}
        />
      )}

      <TopBar>{t("association_management.heading")}</TopBar>
      <RootGrid spacing={2}>
        <Grid item xs={12} container justify={"flex-end"}>
          <Button
            variant="contained"
            color="primary"
            onClick={onCreateNewAssociation}
            disabled={permittedAssociationsLoading}
          >
            {t("association_management.add_association")}
          </Button>
        </Grid>

        {permittedAssociationsLoading && permittedAssociations.length <= 0 && (
          <Grid item xs={12}>
            <PageLoadingIndicator />
          </Grid>
        )}

        {permittedAssociations.length > 0 && (
          <Grid item xs={12}>
            {permittedAssociations.map(
              (association) =>
                association && (
                  <AssociationExpansionPanelWithTownTable
                    key={association.id}
                    association={association}
                    setAssociationToEdit={onSetAssociationToEdit}
                    setTownsForAssociationToEdit={setTownsForAssociationToEdit}
                    onShouldReload={onShouldReload}
                  />
                ),
            )}
          </Grid>
        )}
      </RootGrid>
    </Fragment>
  )
}
