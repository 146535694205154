import React from "react"
import PropTypes from "prop-types"
import debounce from "lodash/debounce"
import { ClipLoader } from "react-spinners"

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles"
import TextField from "@material-ui/core/TextField"
import InputAdornment from "@material-ui/core/InputAdornment"
import lodash from "lodash"

// @Styles

const styles = (theme) => ({
  menu: {
    width: 200,
  },
  debounceSpacer: {
    width: 14,
    height: 14,
  },
})

class WertstoffTextfield extends React.Component {
  constructor(props) {
    super(props)
    this.props = props
    this.state = {
      value: null,
      debounceLoading: false,
    }
  }

  componentWillReceiveProps(nextProps) {
    this.props = nextProps
  }

  handleChange = (key) => (event) => {
    const originalValue = event.target.value
    if (this.props.preprocessor && event && event.target) {
      event.target.value = this.props.preprocessor(event.target.value)
    }

    if (!!this.props.debounce) {
      this.setState({ value: originalValue, debounceLoading: true })
      this.handleDebounce({ target: { value: event.target.value } })
    } else {
      this.props.onChange(event)
    }
  }

  handleDebounce = debounce((event) => {
    this.props.onChange(event)
    this.setState({ value: null, debounceLoading: false })
  }, 1000)

  render() {
    const {
      classes,
      name,
      value: propsValue,
      disabled,
      error,
      adornment,
      startAdornment,
      placeholder,
      margin,
      variant,
      size,
    } = this.props
    const key = name
    const { value: stateValue, debounceLoading } = this.state

    let endAdornment = undefined

    if (adornment) {
      endAdornment = <InputAdornment position="end">{adornment}</InputAdornment>
    } else if (debounce && !debounceLoading) {
      endAdornment = (
        <InputAdornment position="end">
          <div className={classes.debounceSpacer} />
        </InputAdornment>
      )
    } else if (debounceLoading) {
      endAdornment = (
        <InputAdornment position="end">
          <ClipLoader size={10} />
        </InputAdornment>
      )
    }

    return (
      <TextField
        label={name}
        placeholder={placeholder}
        className={classes.textField}
        value={!lodash.isNil(stateValue) ? stateValue : propsValue || ""}
        id={key}
        key={key}
        margin={margin}
        fullWidth
        variant={variant}
        size={size}
        disabled={disabled}
        error={error}
        onChange={this.handleChange(key)}
        InputProps={{
          startAdornment: startAdornment && <InputAdornment position="start">{startAdornment}</InputAdornment>,
          endAdornment,
        }}
        inputProps={{ style: { margin: 0 } }}
      ></TextField>
    )
  }
}

WertstoffTextfield.propTypes = {
  name: PropTypes.string,
  placeholder: PropTypes.string,
  adornment: PropTypes.string,
  variant: PropTypes.string,
  size: PropTypes.string,
  margin: PropTypes.string,
  startAdornment: PropTypes.any,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func.isRequired,
  error: PropTypes.bool,
  disabled: PropTypes.bool,
  debounce: PropTypes.bool,

  preprocessor: PropTypes.func,
}

WertstoffTextfield.defaultProps = {
  value: null,
  adornment: null,
  startAdornment: null,
  error: false,
  disabled: false,
  margin: "normal",
  debounce: false,
}

export default withStyles(styles)(WertstoffTextfield)
