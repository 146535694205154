import React, { FunctionComponent, Fragment, useContext } from "react"
import { Autocomplete } from "@material-ui/lab"
import { TextField, Grid, makeStyles, Theme, List } from "@material-ui/core"
import { useTranslation } from "react-i18next"
import { ClipLoader } from "react-spinners"
import lodash from "lodash"
import {
  AssociationsWithTownsResult,
  ASSOCIATIONS_WITH_TOWNS_QUERY,
} from "../../../../api/graphql/query/associations-with-towns"
import { AssociationListItem } from "./AssociationListItem"
import { useQuery } from "react-apollo"
import { UserAndPermissionsAssociation } from "../../../../api/graphql/query/users-and-assigned-associations"
import {
  AssociationWithTowns,
  TownsOfAssociationWithTowns,
} from "../../../../api/graphql/fragments/association-with-town"
import { ManageUserContext } from "./context/ManageUserContext"

const useStyles = makeStyles((theme: Theme) => ({
  textFieldContainer: {
    margin: 0,
  },
  subheading: {
    fontSize: 18,
  },
}))

const convertAssociationToPermittedAssociation = (
  association: AssociationWithTowns,
  userId: string,
): UserAndPermissionsAssociation => {
  return {
    ...association,
    id: `${userId}/${association.id}`,
    associationId: association.id,
    accessToAllTowns: true,
  }
}

interface IAssociationAndTownSelectionProps {}

export const AssociationAndTownSelection: FunctionComponent<IAssociationAndTownSelectionProps> = (props) => {
  const { t } = useTranslation()
  const classes = useStyles()

  const { defaultTownId, setDefaultTownId, setPermittedAssociations, userId, permittedAssociations } = useContext(
    ManageUserContext,
  )

  const onAssociationsChange = (newPermittedAssociations: UserAndPermissionsAssociation[]) => {
    //check if defaultTownId is still in one of the permittedAssociations
    if (
      !lodash.some(newPermittedAssociations, (permAssociation) => {
        const index = permAssociation.towns.findIndex((town) => town.id === defaultTownId)
        return index >= 0
      })
    ) {
      setDefaultTownId(null)
    }
    setPermittedAssociations(newPermittedAssociations)
  }

  const { data: asssociationsData, error: associationsError, loading: associationsLoading } = useQuery<
    AssociationsWithTownsResult
  >(ASSOCIATIONS_WITH_TOWNS_QUERY)

  const allAssociations: AssociationWithTowns[] = asssociationsData?.associations ?? []

  if (associationsError) {
    console.log(associationsError)
  }

  const associationOptions = allAssociations.filter(
    (option) => !permittedAssociations.map((a) => a.associationId).includes(option.id),
  )

  const onAddAssociation = (newValue: AssociationWithTowns[]) => {
    const added = newValue[newValue.length - 1]
    const newAssociation = convertAssociationToPermittedAssociation(added, userId)

    const newAssociations = [...permittedAssociations, newAssociation]
    onAssociationsChange(newAssociations)
  }

  const onRemoveAssociation = (removed: UserAndPermissionsAssociation) => {
    const newAssociations = [...permittedAssociations]
    newAssociations.splice(permittedAssociations.indexOf(removed), 1)
    onAssociationsChange(newAssociations)
  }

  const onTownsChange = (
    towns: TownsOfAssociationWithTowns[],
    accessToAllTowns: boolean,
    association: UserAndPermissionsAssociation,
  ) => {
    const newAssociations = [...permittedAssociations]
    newAssociations[permittedAssociations.indexOf(association)] = {
      ...association,
      accessToAllTowns,
      towns,
    }
    onAssociationsChange(newAssociations)
  }

  const getTownOptionsForAssociation = (association: UserAndPermissionsAssociation): TownsOfAssociationWithTowns[] => {
    const allTowns: TownsOfAssociationWithTowns[] = lodash.get(
      allAssociations.find((aa) => aa.id === association.associationId),
      "towns",
      [],
    )

    if (association.accessToAllTowns) {
      return allTowns
    }

    const townOptions: TownsOfAssociationWithTowns[] = allTowns.filter(
      (town) => !association.towns.map((t) => t.id).includes(town.id),
    )
    return townOptions
  }

  const permittedAssociationsCasted: AssociationWithTowns[] = permittedAssociations

  return (
    <Fragment>
      <Grid item xs={12} className={classes.textFieldContainer}>
        <Autocomplete
          multiple
          options={associationOptions}
          value={permittedAssociationsCasted}
          getOptionLabel={(option) => option.name}
          onChange={(event, newValue) => onAddAssociation(newValue)}
          loading={associationsLoading}
          loadingText={t("general.data_loading")}
          disableClearable
          renderTags={() => null}
          renderInput={(params) => (
            <TextField
              name="abilities"
              {...params}
              variant="standard"
              label={t("general.associations")}
              margin="normal"
              fullWidth
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <React.Fragment>
                    {associationsLoading ? <ClipLoader color="inherit" size={15} /> : null}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                ),
              }}
            />
          )}
        />
      </Grid>
      <Grid item xs={12} className={classes.textFieldContainer}>
        <List>
          {permittedAssociations.map((association, index) => (
            <AssociationListItem
              townOptions={getTownOptionsForAssociation(association)}
              key={association.id}
              association={association}
              onRemove={(removed) => onRemoveAssociation(removed)}
              onTownsChange={(towns, accessToAllTowns) => onTownsChange(towns, accessToAllTowns, association)}
            />
          ))}
        </List>
      </Grid>
    </Fragment>
  )
}
