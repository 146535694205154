import React, { FunctionComponent, useEffect, useMemo, useState } from "react"
import { FractionMappingDialog, IBaseFractionMappingDialogProps } from "./FractionMappingDialog"
import {
  DefaultFractionMappingForTownQueryResult,
  DefaultFractionMappingForTownQueryVariables,
  DEFAULT_FRACTION_MAPPING_FOR_TOWN,
} from "../../../api/graphql/query/default-fraction-mapping-for-town"
import { useLazyQuery } from "react-apollo"
import { FractionMappingInput } from "../../../api/graphql/generated/globalTypes"

interface ITownFractionMappingDialogProps extends IBaseFractionMappingDialogProps {
  associationId: string
  townId?: string
}

export const TownFractionMappingDialog: FunctionComponent<ITownFractionMappingDialogProps> = (props) => {
  const { townId, associationId, open, ...rest } = props
  const [defaultFractionMappingFetched, setDefaultFractionMappingFetched] = useState<boolean>(false)

  const [loadDefaultFractionMapping, { data, loading }] = useLazyQuery<
    DefaultFractionMappingForTownQueryResult,
    DefaultFractionMappingForTownQueryVariables
  >(DEFAULT_FRACTION_MAPPING_FOR_TOWN, { fetchPolicy: "no-cache" })

  useEffect(() => {
    if (open && !defaultFractionMappingFetched) {
      loadDefaultFractionMapping({ variables: { associationId, townId } })
      setDefaultFractionMappingFetched(true)
    } else if (!open) {
      setDefaultFractionMappingFetched(false)
    }
  }, [loadDefaultFractionMapping, open, defaultFractionMappingFetched, townId, associationId])

  const fractionMapping = useMemo(() => data?.defaultFractionMappingForTown as FractionMappingInput[], [data])

  return (
    <FractionMappingDialog
      defaultFractionMapping={fractionMapping}
      defaultFractionMappingLoading={loading}
      open={open}
      {...rest}
    />
  )
}
