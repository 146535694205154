import React, { FunctionComponent } from "react"
import { Theme, makeStyles, Drawer, List, Typography, Grid } from "@material-ui/core"
import { useQuery } from "react-apollo"
import {
  BookmarksForUserResult,
  BOOKMARKS_FOR_USER,
  AssociationWithBookmarks,
  Bookmark,
} from "../../../../api/graphql/query/bookmarks-for-user"
import lodash from "lodash"
import { PageLoadingIndicator } from "../../indicator/PageLoadingIndicator"
import { filterAssociationBookmarks } from "./helpers/filterAssociationBookmarks"
import { useTranslation } from "react-i18next"
import { BookmarkForAssociationList } from "./BookmarkForAssociationList"

const useStyles = makeStyles((theme: Theme) => ({
  list: {
    width: 300,
  },
  noDataIndicator: {
    height: "100vh",
  },
}))

interface IBookmarkDrawerProps {
  open: boolean
  onClose: () => void
  onSelectBookmark: (bookmark: Bookmark) => void
  selectedBookmark: Bookmark | null
}

export const BookmarkDrawer: FunctionComponent<IBookmarkDrawerProps> = (props) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { open, onClose, onSelectBookmark, selectedBookmark } = props

  const { data, loading, error } = useQuery<BookmarksForUserResult>(BOOKMARKS_FOR_USER, {
    skip: !open,
    fetchPolicy: "network-only",
  })

  if (error) {
    console.log(error)
  }

  const associations: AssociationWithBookmarks[] = lodash.get(data, "userAndPermissions.permittedAssociations")

  const filteredAssociations = associations && filterAssociationBookmarks(associations)

  return (
    <Drawer open={open} onClose={onClose} anchor={"right"}>
      <List className={classes.list}>
        {!loading && open && filteredAssociations.length <= 0 && (
          <Grid container justify={"center"} alignItems={"center"} className={classes.noDataIndicator}>
            <Typography align={"center"}>{t("bookmark.no_bookmarks_found")}</Typography>
          </Grid>
        )}
        {!loading &&
          open &&
          filteredAssociations.map((association) => (
            <BookmarkForAssociationList
              key={association.id}
              association={association}
              onSelectBookmark={onSelectBookmark}
              selectedBookmark={selectedBookmark}
            />
          ))}
        {loading && open && (
          <Grid container justify={"center"} alignItems={"center"} className={classes.noDataIndicator}>
            <PageLoadingIndicator />
          </Grid>
        )}
      </List>
    </Drawer>
  )
}
