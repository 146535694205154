// validateTours
import { gql } from "apollo-boost"
import { validateTours } from "../generated/validateTours"

export const VALIDATE_TOURS_QUERY = gql`
  query validateTours($excelFile: Upload!) {
    validateTours(excelFile: $excelFile) {
      errors {
        id
        type
        line
        additionalData
      }
      warnings {
        id
        type
        line
        additionalData
      }
    }
  }
`

export type ValidateToursResult = validateTours
