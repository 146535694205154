import { gql } from "apollo-boost"
import {
  createWidgetTextTemplate,
  createWidgetTextTemplateVariables
} from "../generated/createWidgetTextTemplate"

export const CREATE_WIDGET_TEMPLATE_MUTATION = gql`
  mutation createWidgetTextTemplate($template: WidgetTextTemplateInput!) {
    createWidgetTextTemplate(template: $template) {
      id
    }
  }
`

export type CreateWidgetTextTemplateResult = createWidgetTextTemplate
export type CreateWidgetTextTemplateVariables = createWidgetTextTemplateVariables
