import { gql } from "apollo-boost"
import {
  tours,
  toursVariables,
  tours_tours,
  tours_tours_towns_association,
  tours_tours_towns,
} from "../generated/tours"

export const TOURS_QUERY = gql`
  query tours($filter: TourFilter!) {
    tours(filter: $filter) {
      id
      name
      tourStart
      tourEnd
      tourType
      rfidReader
      geoCeptImei
      includesSplittedImages
      mappedClassification
      emptying {
        id
        startDate
        weekNumber
        yearNumber
      }
      materialAnalysisCount: materialAnalysisCount
      materialAnalysisCountValid: materialAnalysisCount(isInvalid: false)
      materialAnalysisCountSplitAll: materialAnalysisCount(splitType: all)
      materialAnalysisCountHasRfidReadings: materialAnalysisCount(hasRfidReadings: true)
      newestAnalysis: materialAnalysis(select: NEWEST) {
        metadata {
          recordedAt
        }
      }
      oldestAnalysis: materialAnalysis(select: OLDEST) {
        metadata {
          recordedAt
        }
      }

      towns {
        id
        name
        displayOnlyAutomaticAnalysis
        externalTown {
          id
          externalId
          type
        }
        association {
          id
          name
        }
      }
      vehicle {
        id
        wasteScannerId
        geoCeptImei
      }
    }
  }
`

export type ToursResult = tours
export type Tour = tours_tours
export type AssociationForTour = tours_tours_towns_association
export type TownForTour = tours_tours_towns
export type ToursVariables = toursVariables
