import { gql } from "apollo-boost"
import {
  FragmentAssociationWithTowns,
  FragmentAssociationWithTowns_towns,
} from "../generated/fragmentAssociationWithTowns"

export const FRAGMENT_ASSOCIATION_WITH_TOWNS = gql`
  fragment FragmentAssociationWithTowns on AssociationInterface {
    id
    name
    latitude
    longitude
    radius
    towns {
      id
      name
    }
  }
`
export type AssociationWithTowns = FragmentAssociationWithTowns
export type TownsOfAssociationWithTowns = FragmentAssociationWithTowns_towns
