import { FilterModelKeys } from "../models/FilterModelKeys"
import lodash from "lodash"
import moment from "moment"

export function transformPersistentFilterToUiFilter(persistentFilter) {
  const newFilterModel = { ...persistentFilter }

  Object.keys(newFilterModel).forEach(filterKey => {
    let value = newFilterModel[filterKey]

    if (filterKey === "fractionType") {
      newFilterModel[FilterModelKeys.filterFractionType] = value
    } else if (filterKey === "fractionFrom") {
      newFilterModel[FilterModelKeys.filterFractionFrom] = lodash.isNil(value)
        ? null
        : String(value) + ".0"
    } else if (filterKey === "fractionTo") {
      newFilterModel[FilterModelKeys.filterFractionTo] = lodash.isNil(value)
        ? null
        : String(value) + ".0"
    } else if (filterKey === "rating") {
      newFilterModel[FilterModelKeys.filterRating] = lodash.isNil(value)
        ? null
        : String(value)
    } else if (filterKey === "dateFrom") {
      newFilterModel[FilterModelKeys.filterDateFrom] = moment(value)
    } else if (filterKey === "dateUntil") {
      newFilterModel[FilterModelKeys.filterDateUntil] = moment(value)
    } else if (
      ["id", "__typename", "createdAt", "updatedAt"].includes(filterKey) ||
      Object.values(FilterModelKeys).includes(filterKey)
    ) {
      // nothing to do
    } else if (filterKey === FilterModelKeys.association) {
      // nothing to do
    } else if (filterKey === FilterModelKeys.municipal) {
      // nothing to do
    } else if (filterKey === FilterModelKeys.tour) {
      // nothing to do
    } else {
      console.log("unknown key: ", filterKey)
    }
  })

  return newFilterModel
}
