import React, { FunctionComponent, useContext, useState } from "react"
import {
  Theme,
  makeStyles,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Button,
  Grid,
  Chip,
} from "@material-ui/core"
import { useTranslation } from "react-i18next"
import { WidgetTextTemplate } from "../../../../api/graphql/query/widget-text-templates-for-category"
import lodash from "lodash"
import { TextTemplateEditor } from "../editor/TextTemplateEditor"
import {
  CREATE_WIDGET_TEMPLATE_MUTATION,
  CreateWidgetTextTemplateResult,
  CreateWidgetTextTemplateVariables,
} from "../../../../api/graphql/mutation/create-widget-text-template"
import { useMutation } from "react-apollo"
import {
  UpdateWidgetTextTemplateResult,
  UpdateWidgetTextTemplateVariables,
  UPDATE_WIDGET_TEMPLATE_MUTATION,
} from "../../../../api/graphql/mutation/update-widget-text-template"
import { WidgetTextTemplateInput, Rating } from "../../../../api/graphql/generated/globalTypes"
import { useTextFilter } from "../../../../context/TextFilterContext"
import { WidgetTextCategoryEssential } from "../../../../api/graphql/generated/widgetTextCategoryEssential"
import { PermittedAssociationsContext } from "../../../../context/PermittedAssociationsContext"

const useStyles = makeStyles((theme: Theme) => ({
  dialog: {
    backgroundColor: theme.palette.secondary.light,
  },
  templateEditor: {
    height: "70vh",
    overflowY: "auto",
  },
}))

interface IManageTemplateDialogProps {
  open: boolean
  template: WidgetTextTemplate | null
  category: WidgetTextCategoryEssential
  ratingKey: Rating
  forceCreateNew?: boolean
  onClose: () => void
  onSuccess: () => void
}

export const ManageTemplateDialog: FunctionComponent<IManageTemplateDialogProps> = (props) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { open, onClose, onSuccess, template, ratingKey, category, forceCreateNew } = props
  const { associationId, townId } = useTextFilter()
  const { permittedAssociations } = useContext(PermittedAssociationsContext)

  const [text, setText] = useState<string>(lodash.isNil(template) ? "" : template.textTemplate)
  const [title, setTitle] = useState<string>(lodash.isNil(template) ? "" : template.title)

  const [createTemplateMutation, { loading: createTemplateMutationLoading }] = useMutation<
    CreateWidgetTextTemplateResult,
    CreateWidgetTextTemplateVariables
  >(CREATE_WIDGET_TEMPLATE_MUTATION, {
    onError: (error) => {
      console.log("error: ", error)
    },
    onCompleted: (data) => {
      console.log("create success: ", data)
      onSuccess()
    },
  })

  const [updateTemplateMutation, { loading: updateTemplateMutationLoading }] = useMutation<
    UpdateWidgetTextTemplateResult,
    UpdateWidgetTextTemplateVariables
  >(UPDATE_WIDGET_TEMPLATE_MUTATION, {
    onError: (error) => {
      console.log("error: ", error)
    },
    onCompleted: (data) => {
      console.log("update success: ", data)
      onSuccess()
    },
  })

  const isInCreateMode = lodash.isNil(template) || forceCreateNew === true
  const isInEditMode = !isInCreateMode
  const loading = updateTemplateMutationLoading || createTemplateMutationLoading

  const onSave = () => {
    const baseInput = {
      title: title,
      textTemplate: text,
    }

    if (isInCreateMode) {
      const inputCreate: WidgetTextTemplateInput = {
        ...baseInput,
        widgetTextCategoryId: category.id,
        rating: ratingKey,
        associationId,
        townId: townId === "all" ? null : townId,
      }
      createTemplateMutation({ variables: { template: inputCreate } })
    }
    if (isInEditMode) {
      const id = lodash.isNil(template) ? "" : template.id
      updateTemplateMutation({ variables: { id, template: baseInput } })
    }
  }

  const getAssocationName = (associationId: string) => {
    return permittedAssociations.find((association) => association.associationId === associationId)?.name || ""
  }

  const getTownName = (townId: string) => {
    const selectedAssociation = permittedAssociations.find((association) => association.associationId === associationId)

    if (!selectedAssociation) {
      return ""
    }

    const town = selectedAssociation.towns.find((town) => town.id === townId)

    if (!town) {
      return null
    }

    return town.name
  }

  const townName = townId ? getTownName(townId) : null

  return (
    <Dialog open={open} onClose={onClose} maxWidth={"xl"} fullWidth classes={{ paper: classes.dialog }}>
      <DialogTitle>
        {isInCreateMode ? t("text_management.dialog.create.title") : t("text_management.dialog.update.title")}
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item container spacing={1} direction={"row"}>
            <Grid item>
              <Chip variant={"default"} color={"primary"} label={category.name} />
            </Grid>
            <Grid item>
              <Chip variant={"default"} color={"primary"} label={t(`waste_quality.${ratingKey}`)} />
            </Grid>
            <Grid item>
              <Chip variant={"default"} color={"primary"} label={getAssocationName(associationId)} />
            </Grid>
            {townName && (
              <Grid item>
                <Chip variant={"default"} color={"primary"} label={townName} />
              </Grid>
            )}
          </Grid>
          <Grid container item className={classes.templateEditor}>
            <TextTemplateEditor
              title={title}
              text={text}
              rating={ratingKey}
              onTitleChange={(newTitle) => setTitle(newTitle)}
              onTextChange={(newText) => setText(newText)}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={() => onClose()} disabled={loading}>
          {t("general.cancel")}
        </Button>
        <Button color="primary" onClick={() => onSave()} disabled={loading}>
          {isInCreateMode ? t("text_management.dialog.create.button") : t("text_management.dialog.update.button")}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
