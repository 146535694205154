import React from "react"
import PropTypes from "prop-types"
import { Mutation } from "react-apollo"
import lodash from "lodash"
import { withTranslation } from "react-i18next"

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles"

import MoreVert from "@material-ui/icons/MoreVert"
import GridItem from "../../layout/GridItem"
import { WidgetModificationType } from "../../../../domain/models/WidgetModels"
import { DELETE_WIDGET_MUTATION } from "../../../../api/graphql/mutation/delete-widget"
import { MUTATION as CREATE_WIDGET_MUTATION } from "../../../../api/graphql/mutation/create-new-widget"
import { UPDATE_WIDGET_MUTATION } from "../../../../api/graphql/mutation/update-widget"
import { Typography, Grid, Button, Popover } from "@material-ui/core"
import { WidgetType } from "../../../../api/graphql/generated/globalTypes"
import { TransformWidgetGraphDialog } from "../dialogs/TransformWidgetGraphDialog"

// @Styles
const styles = (theme) => ({
  rootDiv: {
    padding: theme.spacing(1),
  },
  spaceDiv: {
    height: "2px",
    backgroundColor: "#e8e8e8",
  },
  gridContainer: {
    marginBottom: theme.spacing(1),
  },
  widgetTitleTypo: {
    overflowWrap: "break-word",
  },
  clickableIcon: {
    cursor: "pointer",
  },
})

class WertstoffWidgetHeader extends React.Component {
  constructor(props) {
    super(props)
    this.props = props
    this.state = {
      anchorEl: null,
      transformDialogOpen: false,
    }
  }

  componentWillReceiveProps(nextProps) {
    this.props = nextProps
  }

  handleClick = (event) => {
    this.setState({
      anchorEl: event.currentTarget,
    })
  }

  handleClose = (event) => {
    this.setState({
      anchorEl: null,
    })
  }

  handleWidgetMutationComplete = (modificationType, args) => {
    this.props.onModification && this.props.onModification(modificationType, this.props.widgetModel)
    console.log("handleWidgetMutationComplete", args)
    this.setState({
      anchorEl: null,
    })
  }

  handleWidgetMutationError = () => {
    this.props.onModification && this.props.onModification(WidgetModificationType.DELETE, this.props.widgetModel)
    console.log("handleWidgetMutationError")
    this.setState({
      anchorEl: null,
    })
  }

  handleModification = (event, type, widgetModel, mutation) => {
    if (type === WidgetModificationType.DELETE) {
      mutation({
        variables: {
          id: widgetModel.id,
        },
      })
    } else if (type === WidgetModificationType.DUPLICATE) {
      mutation({
        variables: {
          reportId: lodash.get(widgetModel, "report.id"),
          widgetType: widgetModel.widgetType,
          filterId: lodash.get(widgetModel, "filter.id"),
          textTitle: widgetModel.textTitle,
          textContent: widgetModel.textContent,
          chartCompact: widgetModel.chartCompact,
          channelType: widgetModel.channelType,
        },
      })
    } else if (type === WidgetModificationType.TRANSFORM) {
      this.setState({ transformDialogOpen: true })
    } else {
      this.props.onModification && this.props.onModification(type, widgetModel)
    }

    this.setState({
      anchorEl: null,
    })
  }

  render() {
    const { classes, widgetModel, t } = this.props
    const { anchorEl, transformDialogOpen } = this.state
    const open = Boolean(anchorEl)

    return (
      <>
        <TransformWidgetGraphDialog
          open={transformDialogOpen}
          widget={widgetModel}
          onClose={() => this.setState({ transformDialogOpen: false })}
          onConfirm={(args) => this.handleWidgetMutationComplete(WidgetModificationType.TRANSFORM, args)}
        />
        <Mutation
          mutation={DELETE_WIDGET_MUTATION}
          onCompleted={(args) => this.handleWidgetMutationComplete(WidgetModificationType.DELETE, args)}
          onError={this.handleWidgetMutationError}
        >
          {(deleteWidgetMutation) => (
            <Mutation
              mutation={CREATE_WIDGET_MUTATION}
              onCompleted={(args) => this.handleWidgetMutationComplete(WidgetModificationType.DUPLICATE, args)}
              onError={this.handleWidgetMutationError}
            >
              {(duplicateWidgetMutation) => (
                // <Mutation
                //   mutation={UPDATE_WIDGET_MUTATION}
                //   onCompleted={(args) => this.handleWidgetMutationComplete(WidgetModificationType.TRANSFORM, args)}
                //   onError={this.handleWidgetMutationError}
                // >
                //   {(transformWidgetMutation) => (
                <Mutation
                  mutation={UPDATE_WIDGET_MUTATION}
                  onCompleted={(args) => this.handleWidgetMutationComplete(WidgetModificationType.EDIT, args)}
                  onError={this.handleWidgetMutationError}
                >
                  {(updateWidgetMutation) => (
                    <div className={classes.rootDiv}>
                      <Grid
                        container
                        className={classes.gridContainer}
                        direction={"row"}
                        justify="space-between"
                        alignItems="flex-start"
                      >
                        <Grid item xs={11}>
                          <Typography className={classes.widgetTitleTypo}>
                            {lodash.get(widgetModel, "title") || "??"}
                          </Typography>
                        </Grid>
                        <Grid item xs={1}>
                          <Grid container justify="flex-end">
                            <MoreVert className={classes.clickableIcon} onClick={this.handleClick} />
                          </Grid>
                          <Popover
                            id="simple-popper"
                            open={open}
                            anchorEl={anchorEl}
                            onClose={this.handleClose}
                            anchorOrigin={{
                              vertical: "bottom",
                              horizontal: "center",
                            }}
                            transformOrigin={{
                              vertical: "top",
                              horizontal: "center",
                            }}
                          >
                            <Grid container direction={"column"} justify="flex-start" alignItems="stretch">
                              <GridItem xs={12}>
                                <Button
                                  onClick={(event) =>
                                    this.handleModification(
                                      event,
                                      WidgetModificationType.EDIT,
                                      widgetModel,
                                      updateWidgetMutation,
                                    )
                                  }
                                >
                                  {t("general.edit")}
                                </Button>
                              </GridItem>
                              {widgetModel.widgetType.includes("chart_") && (
                                <GridItem xs={12}>
                                  <Button
                                    onClick={(event) =>
                                      this.handleModification(event, WidgetModificationType.TRANSFORM, widgetModel)
                                    }
                                  >
                                    {t("general.convert")}
                                  </Button>
                                </GridItem>
                              )}
                              {!widgetModel.widgetType.includes(WidgetType.text_individual) && (
                                <GridItem xs={12}>
                                  <Button
                                    onClick={(event) =>
                                      this.handleModification(
                                        event,
                                        WidgetModificationType.DUPLICATE,
                                        widgetModel,
                                        duplicateWidgetMutation,
                                      )
                                    }
                                  >
                                    {t("general.duplicate")}
                                  </Button>
                                </GridItem>
                              )}
                              <GridItem>
                                <Button
                                  onClick={(event) =>
                                    this.handleModification(
                                      event,
                                      WidgetModificationType.DELETE,
                                      widgetModel,
                                      deleteWidgetMutation,
                                    )
                                  }
                                >
                                  {t("general.delete")}
                                </Button>
                              </GridItem>
                            </Grid>
                          </Popover>
                        </Grid>
                      </Grid>
                      <div className={classes.spaceDiv} />
                    </div>
                  )}
                </Mutation>
                //   )}
                // </Mutation>
              )}
            </Mutation>
          )}
        </Mutation>
      </>
    )
  }
}

WertstoffWidgetHeader.propTypes = {
  widgetModel: PropTypes.object,
  onModification: PropTypes.func,
}

WertstoffWidgetHeader.defaultProps = {
  widgetModel: null,
  onModification: null,
}

export default withStyles(styles)(withTranslation()(WertstoffWidgetHeader))
