import React, { FunctionComponent, useContext } from "react"
import { Theme, makeStyles, Grid } from "@material-ui/core"
import { useTranslation } from "react-i18next"
import { ReportDetail } from "../report/ReportDetail"
import { ReportDetailContextProvider } from "../report/context/ReportDetailContext"
import { CommunicationCenterContext } from "./CommunicationCenterContext"
import { COMMUNICATION_CENTER_LIST_WIDTH } from "./CommunicationCenterSidebar"

const useStyles = makeStyles((theme: Theme) => ({
  pageContent: {
    margin: theme.spacing(2),
    marginLeft: COMMUNICATION_CENTER_LIST_WIDTH + theme.spacing(6),
  },
}))

interface ICommunicationCenterPageContentProps {}

export const CommunicationCenterPageContent: FunctionComponent<ICommunicationCenterPageContentProps> = (props) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { selectedReportId, fetchReports } = useContext(CommunicationCenterContext)

  return (
    <Grid className={classes.pageContent}>
      <ReportDetailContextProvider>
        <ReportDetail reportId={selectedReportId} refetchReports={fetchReports} />
      </ReportDetailContextProvider>
    </Grid>
  )
}
