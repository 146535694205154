/*
    Hook which provides all filter functionalities
*/

import React, { createContext, useState, FunctionComponent, useEffect } from "react"
import { Rating, TourType } from "../api/graphql/generated/globalTypes"
import moment from "moment"
import { useQuery } from "react-apollo"
import lodash from "lodash"
import {
  PERMITTED_ASSOCIATIONS_FOR_FILTER_QUERY,
  PermittedAssociationsForFilterResult,
  UserAndPermissionsAssociationForFilter,
  UserAndPermissionsTownForFilter,
} from "../api/graphql/query/permitted-associations-for-filter"
import { permittedAssociationsForFilterVariables } from "../api/graphql/generated/permittedAssociationsForFilter"

interface PDFFilterContextType {
  associationId: string | undefined
  setAssociationId: (associationId: string | undefined) => void
  townId: string | undefined
  setTownId: (townId: string | undefined) => void
  tourType: TourType
  setTourType: (value: TourType) => void
  startDate: moment.Moment
  setStartDate: (startDate: moment.Moment) => void
  updateStartDate: (startDate: moment.Moment | undefined) => void
  endDate: moment.Moment
  setEndDate: (endDate: moment.Moment) => void
  updateEndDate: (startDate: moment.Moment | undefined) => void
  ratings: Rating[]
  setRatings: (rating: Rating[]) => void
  associations: UserAndPermissionsAssociationForFilter[]
  towns: UserAndPermissionsTownForFilter[]
  isFilterValid: boolean
}

export const PDFFilterContext = createContext<PDFFilterContextType>({
  associationId: undefined,
  setAssociationId: () => undefined,
  townId: undefined,
  setTownId: () => undefined,
  tourType: TourType.residual,
  setTourType: () => undefined,
  startDate: moment().subtract(3, "weeks"),
  setStartDate: () => undefined,
  updateStartDate: () => undefined,
  endDate: moment(),
  setEndDate: () => undefined,
  updateEndDate: () => undefined,
  ratings: [Rating.Low, Rating.Medium],
  setRatings: () => undefined,
  associations: [],
  towns: [],
  isFilterValid: false,
})

export const PDFFilterContextProvider: FunctionComponent = (props) => {
  const [associationId, setAssociationId] = useState<string>()
  const [townId, setTownId] = useState<string>()
  const [tourType, setTourType] = useState<TourType>(TourType.residual)
  const [startDate, setStartDate] = useState<moment.Moment>(moment().subtract(3, "weeks").startOf("day"))
  const [endDate, setEndDate] = useState<moment.Moment>(moment().endOf("day"))
  const [ratings, setRatings] = useState<Rating[]>([Rating.Low, Rating.Medium])
  const [associations, setAssociations] = useState<UserAndPermissionsAssociationForFilter[]>([])
  const [towns, setTowns] = useState<UserAndPermissionsTownForFilter[]>([])
  const [isFilterValid, setIsFilterValid] = useState<boolean>(false)

  const { data: permittedAssociationsData } = useQuery<
    PermittedAssociationsForFilterResult,
    permittedAssociationsForFilterVariables
  >(PERMITTED_ASSOCIATIONS_FOR_FILTER_QUERY)

  useEffect(() => {
    if (!lodash.isNil(permittedAssociationsData) && lodash.isNil(associationId)) {
      const defaultAssociationId = permittedAssociationsData.userAndPermissions.user.defaultTown?.association.id
      const defaultTownId = permittedAssociationsData.userAndPermissions.user.defaultTown?.id
      const defaultAssociation = permittedAssociationsData.userAndPermissions.permittedAssociations.find(
        (association) => association.associationId === defaultAssociationId,
      )
      const defaultTown = defaultAssociation?.towns?.find((town) => town.id === defaultTownId)

      if (lodash.isNil(defaultAssociation) || lodash.isNil(defaultTown)) {
        setAssociationId(permittedAssociationsData.userAndPermissions.permittedAssociations[0]?.associationId)
      } else {
        setAssociationId(defaultAssociation.associationId)
      }
    }
  }, [permittedAssociationsData, associationId])

  useEffect(() => {
    setAssociations(permittedAssociationsData?.userAndPermissions.permittedAssociations ?? [])
  }, [permittedAssociationsData])

  useEffect(() => {
    const newTowns =
      permittedAssociationsData?.userAndPermissions.permittedAssociations.find(
        (pm) => pm.associationId === associationId,
      )?.towns ?? []

    setTowns(newTowns)
    setTownId(undefined)
  }, [associationId, associations, towns.length, permittedAssociationsData])

  useEffect(() => {
    setIsFilterValid(
      !lodash.isNil(associationId) &&
        ratings.length !== 0 &&
        endDate.isSameOrBefore(startDate.clone().add(6, "months"), "day"),
    )
  }, [associationId, townId, ratings, startDate, endDate])

  const updateStartDate = (date?: moment.Moment) => {
    const newStartDate = date || moment()
    setStartDate(newStartDate.startOf("day"))
    if (newStartDate.isAfter(endDate)) {
      setEndDate(newStartDate.clone().endOf("day"))
    }
  }

  const updateEndDate = (date?: moment.Moment) => {
    const newEndDate = date || moment()
    setEndDate(newEndDate.endOf("day"))
    if (newEndDate.isBefore(startDate)) {
      setStartDate(newEndDate.clone().startOf("day"))
    }
  }

  return (
    <PDFFilterContext.Provider
      value={{
        associationId,
        setAssociationId,
        townId,
        setTownId,
        tourType,
        setTourType,
        startDate,
        setStartDate,
        updateStartDate,
        endDate,
        setEndDate,
        updateEndDate,
        ratings,
        setRatings,
        associations,
        towns,
        isFilterValid,
      }}
    >
      {props.children}
    </PDFFilterContext.Provider>
  )
}
