import React, { FunctionComponent, useMemo } from "react"
import { useTranslation } from "react-i18next"
import { MaterialAnalysisForMap } from "../../../../api/graphql/query/material-analysis-for-map"
import { createWasteQualityDictionary } from "../../../../components/partials/analysis/waste-quality/create-waste-quality-dictionary"
import { WasteQualityPieGraph } from "../../analysis/waste-quality/WasteQualityPieGraph"

interface IWidgetPieChartWasteQualityProps {
  materialAnalysis: MaterialAnalysisForMap[]
  onExportCreated?: (fileUri: string, extension: string) => void
}

export const WidgetPieChartWasteQuality: FunctionComponent<IWidgetPieChartWasteQualityProps> = (props) => {
  const { t } = useTranslation()
  const { materialAnalysis, onExportCreated } = props

  const wasteQualityDataDictionary = useMemo(() => createWasteQualityDictionary(materialAnalysis), [materialAnalysis])

  return <WasteQualityPieGraph dictionary={wasteQualityDataDictionary} legend onExportCreated={onExportCreated} />
}
