import { gql } from "apollo-boost"
import {
  deleteWidgetTextTemplate,
  deleteWidgetTextTemplateVariables
} from "../generated/deleteWidgetTextTemplate"

export const DELETE_WIDGET_TEMPLATE_MUTATION = gql`
  mutation deleteWidgetTextTemplate($id: ID!) {
    deleteWidgetTextTemplate(id: $id) {
      error
      message
    }
  }
`

export type DeleteWidgetTextTemplateResult = deleteWidgetTextTemplate
export type DeleteWidgetTextTemplateVariables = deleteWidgetTextTemplateVariables
