// Libraries
import React from "react"
import PropTypes from "prop-types"
import _ from "lodash"

// Material UI
import withStyles from "@material-ui/core/styles/withStyles"

// Project Components / Models / etc.
import GridContainer from "../layout/GridContainer"
import Typography from "@material-ui/core/Typography"
import { CircularProgress, Grid } from "@material-ui/core"

const styles = (theme) => ({
  rootGrid: {
    flexGrow: 1,
    padding: 16,
  },
  errorContainer: {
    padding: theme.spacing(2),
  },
})

class QueryState extends React.Component {
  constructor(props) {
    super(props)
    this.props = props
  }

  render() {
    const { classes, loading, error, notNil, children } = this.props

    if (loading) {
      return (
        <GridContainer className={classes.rootGrid} direction={"column"} justify={"center"} alignItems={"center"}>
          <CircularProgress />
        </GridContainer>
      )
    } else if (!_.isNil(error)) {
      return (
        <Grid direction={"row"} justify={"space-between"} alignItems={"center"} className={classes.errorContainer}>
          <Typography variant={"h6"}>Es ist ein Fehler aufgetreten, bitte laden sie die Seite neu.</Typography>
        </Grid>
      )
    } else if (_.isNil(notNil)) {
      return (
        <Grid direction={"row"} justify={"space-between"} alignItems={"center"} className={classes.errorContainer}>
          <Typography variant={"h6"}>Es wurden fehlerhafte Daten empfangen, bitte laden sie die Seite neu.</Typography>
        </Grid>
      )
    } else {
      return <div>{children}</div>
    }
  }
}

QueryState.propTypes = {
  loading: PropTypes.bool,
  error: PropTypes.object,
  notNil: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
}

QueryState.defaultProps = {
  loading: false,
  error: null,
  notNil: {},
  children: <div>Children are missing</div>,
}

export default withStyles(styles)(QueryState)
