import React, { FunctionComponent, Fragment, useEffect, useContext, useMemo } from "react"
import { Theme, makeStyles, Grid } from "@material-ui/core"
import { useTranslation } from "react-i18next"
import { UserAndPermissionsTown } from "../../../api/graphql/query/permitted-associations-for-user"
import {
  RfidChip,
  RfidChipsQueryResult,
  RfidChipsQueryVariables,
  RFID_CHIPS_QUERY,
} from "../../../api/graphql/query/rfid-chip-for-town"
import { RfidTableForTown } from "./RfidTableForTown"
import { RfidChipFilter } from "./filter/RfidChipFilter"
import { UnassignableAddressesBannerForTown } from "./unassignable-address/UnassignableAddressesBannerForTown"
import { useQuery } from "react-apollo"
import { RfidChipFilterContext } from "./context/RfidChipFilterContext"
import { searchFields } from "../../../utils/search-fields"
import { AuthContext } from "../../../context/AuthContext"

const useStyles = makeStyles((theme: Theme) => ({}))

interface IRfidTableWrapperProps {
  town: UserAndPermissionsTown
  setRfidChipToEdit: (rfidChip: RfidChip) => void
  setRfidChipToDelete: (rfidChip: RfidChip) => void
  toggleRefetch: boolean
}

export const RfidTableWrapper: FunctionComponent<IRfidTableWrapperProps> = (props) => {
  const { t } = useTranslation()
  const { town, toggleRefetch, setRfidChipToEdit, setRfidChipToDelete } = props

  const { data, error, loading, refetch } = useQuery<RfidChipsQueryResult, RfidChipsQueryVariables>(RFID_CHIPS_QUERY, {
    variables: { townId: town.id },
  })

  const { isAdmin } = useContext(AuthContext)
  const { rfidSearchTerm, uniquelyAssignableChecked } = useContext(RfidChipFilterContext)

  useEffect(() => {
    refetch()
  }, [toggleRefetch, refetch])

  if (error) {
    console.log(error)
  }

  const rfidChips = useMemo(
    () =>
      (data?.rfidChips || []).filter((rfidChip) => {
        const searchTermFound = searchFields(
          [
            rfidChip.detailedAddress?.street || "",
            rfidChip.detailedAddress?.streetNumber || "",
            rfidChip.detailedAddress?.city || "",
            rfidChip.detailedAddress?.postalcode || "",
            rfidChip.rfid,
          ],
          rfidSearchTerm,
        )
        let uniquelyAssignableFound = true
        if (uniquelyAssignableChecked !== null) {
          uniquelyAssignableFound = rfidChip.uniquelyAssignable === uniquelyAssignableChecked
        }

        return searchTermFound && uniquelyAssignableFound
      }),
    [data?.rfidChips, rfidSearchTerm, uniquelyAssignableChecked],
  )

  return (
    <Grid container direction="column" spacing={2}>
      <Grid item>{isAdmin() && <UnassignableAddressesBannerForTown town={town} />}</Grid>
      <Grid item>
        <RfidChipFilter />
      </Grid>
      <Grid item>
        <RfidTableForTown
          loading={loading}
          setRfidChipToEdit={setRfidChipToEdit}
          setRfidChipToDelete={setRfidChipToDelete}
          rfidChips={rfidChips}
        />
      </Grid>
    </Grid>
  )
}
