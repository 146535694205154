import React, { FunctionComponent, useContext, useMemo } from "react"
import { Theme, makeStyles, Paper, Grid, Button } from "@material-ui/core"
import { useTranslation } from "react-i18next"
import { TownFilter } from "../text-filter/TownFilter"
import { AssociationFilter } from "../text-filter/AssociationFilter"
import { WertstoffDatePicker } from "../controls/WertstoffDatePicker"
import { getFilterDates } from "../analysis-filter/helpers/getFilterDates"
import { LogFilterContext } from "./context/LogFilterContext"
import { getISOString } from "../../../utils/get-iso-string"
import WertstoffTextfield from "../controls/WertstoffTextfield"

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    marginTop: 0,
    padding: theme.spacing(2),
    justifyContent: "center",
    color: theme.palette.text.secondary,
    borderRadius: theme.spacing(0),
  },
}))

interface ILogPageFilterProps {}

export const LogPageFilter: FunctionComponent<ILogPageFilterProps> = (props) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const { dateFrom, dateUntil, setDateFrom, setDateUntil, recipient, setRecipient, setApplyFilter } = useContext(
    LogFilterContext,
  )
  const { minDateFrom, minDateUntil, maxDateFrom, maxDateUntil, disableFuture } = useMemo(
    () => getFilterDates(dateUntil, dateFrom),
    [dateFrom, dateUntil],
  )

  return (
    <Paper elevation={2} className={classes.paper}>
      <Grid container spacing={2} alignItems="flex-end">
        <Grid item xs={2}>
          <AssociationFilter />
        </Grid>
        <Grid item xs={2}>
          <TownFilter />
        </Grid>
        <Grid item xs={2}>
          <WertstoffTextfield
            margin="none"
            value={recipient}
            onChange={(event) => setRecipient(event.target.value)}
            name={t("log.property.receiver")}
          />
        </Grid>
        <Grid item xs={2}>
          <WertstoffDatePicker
            useDateTimePicker
            margin="none"
            name={t("log.filter.date_from")}
            disableFuture={disableFuture}
            minDate={minDateFrom}
            maxDate={maxDateFrom}
            value={getISOString(dateFrom)}
            onChange={(date) => setDateFrom(date?.toDate())}
          />
        </Grid>
        <Grid item xs={2}>
          <WertstoffDatePicker
            useDateTimePicker
            margin="none"
            name={t("log.filter.date_until")}
            disableFuture={disableFuture}
            minDate={minDateUntil}
            maxDate={maxDateUntil}
            value={getISOString(dateUntil)}
            onChange={(date) => setDateUntil(date?.toDate())}
          />
        </Grid>
        <Grid item xs={2}>
          <Button fullWidth variant="contained" color="primary" onClick={() => setApplyFilter(true)}>
            {t("general.apply")}
          </Button>
        </Grid>
      </Grid>
    </Paper>
  )
}
