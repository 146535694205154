import { gql } from "apollo-boost"
import { createVehicle, createVehicleVariables } from "../generated/createVehicle"

export const CREATE_VEHICLE_MUTATION = gql`
  mutation createVehicle(
    $wasteScannerId: ID!
    $rfidReader: ID
    $geoCeptImei: ID
    $rfidReadingToMaterialAnalysisOffset: MinMaxInput
    $geoCeptToMaterialAnalysisOffset: MinMaxInput
    $imageLimit: Int
  ) {
    createVehicle(
      wasteScannerId: $wasteScannerId
      rfidReader: $rfidReader
      geoCeptImei: $geoCeptImei
      rfidReadingToMaterialAnalysisOffset: $rfidReadingToMaterialAnalysisOffset
      geoCeptToMaterialAnalysisOffset: $geoCeptToMaterialAnalysisOffset
      imageLimit: $imageLimit
    ) {
      id
    }
  }
`

export type CreateVehicleResult = createVehicle
export type CreateVehicleVariables = createVehicleVariables
