import React, { FunctionComponent, useEffect, useContext, useState, ChangeEvent, useMemo } from "react"
import { Theme, makeStyles, Grid, Typography, Card, useTheme } from "@material-ui/core"
import { useTranslation } from "react-i18next"
import { useLocation, useHistory } from "react-router"
import queryString from "query-string"
import lodash from "lodash"
// import saubermacherLogo from "../../assets/saubermacher-logo-schriftzug-rot.svg"
import { AuthContext } from "../../context/AuthContext"
import { useMutation } from "react-apollo"
import {
  VERIFY_COMMUNICATION_CHANNEL_MUTATION,
  VerifyCommunicationChannelResult,
  VerifyCommunicationChannelVariables,
} from "../../api/graphql/mutation/verify-communication-channel"
import { toast } from "react-toastify"
import { getGraphqlErrorCode } from "../../utils/get-graphql-error-code"
import { LoginLogo } from "../partials/logo/LoginLogo"
import { ClipLoader } from "react-spinners"

const useStyles = makeStyles((theme: Theme) => ({
  background: {
    background: theme.palette.secondary.light,
    height: "100vh",
  },
  wrapper: {
    flexDirection: "row",
    height: "70vh",
    minHeight: "500px",
    marginRight: "auto;",
    marginBottom: "0px;",
    marginLeft: "auto;",
    width: "400px",
    color: "white",
  },
  infoTypo: {
    color: theme.palette.secondary.main,
    fontSize: 14,
  },
  link: {
    color: theme.palette.primary.main,
  },
  headingTypo: {
    marginTop: 10,
    textTransform: "uppercase",
  },
  card: {
    padding: theme.spacing(3),
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(5),
  },
  cardHeading: {
    color: theme.palette.secondary.dark,
    textTransform: "uppercase",
    fontSize: 16,
  },
  control: {
    width: "80%",
  },

  loadingIndicator: {
    color: theme.palette.secondary.main,
  },
  verifiedContainer: {
    height: 100,
    width: 200,
    alignItems: "center",
  },
}))

interface IVerifyCommunicationChannelPageProps {}

export const VerifyCommunicationChannelPage: FunctionComponent<IVerifyCommunicationChannelPageProps> = (props) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { search } = useLocation()
  const history = useHistory()
  const theme = useTheme()
  const tokenDeprecated = useMemo(
    () => lodash.get(queryString.parse(search), "verify-communication-channel-token", "") as string,
    [search],
  )

  const token = useMemo(() => lodash.get(queryString.parse(search), "c", "") as string, [search])
  const [success, setSuccess] = useState<boolean>(false)

  const [changePasswordMutation, { loading, error }] = useMutation<
    VerifyCommunicationChannelResult,
    VerifyCommunicationChannelVariables
  >(VERIFY_COMMUNICATION_CHANNEL_MUTATION, {
    onCompleted: () => setSuccess(true),
    onError: (error) => {
      console.log("error: ", error)
    },
  })

  useEffect(() => {
    if (!!token || !!tokenDeprecated) {
      changePasswordMutation({ variables: { token: token || tokenDeprecated } })
    } else {
      history.push("/login")
    }
  }, [token, history])

  return (
    <div className={classes.background}>
      <Grid container justify="center" alignContent="center" className={classes.wrapper}>
        <Grid item>
          <Card className={classes.card}>
            <Grid container spacing={3}>
              <Grid item xs={12} container justify={"center"}>
                <Grid item xs={12} container>
                  <LoginLogo />
                </Grid>
              </Grid>
              <Grid item xs={12} container justify={"center"} className={classes.verifiedContainer}>
                {loading && <ClipLoader color={theme.palette.primary.main} />}
                {success && (
                  <Typography align="center">{t("verify_communication_channel.verified_successfully")}</Typography>
                )}
                {error && <Typography align="center">{t(`api_error.${getGraphqlErrorCode(error)}`)}</Typography>}
              </Grid>
            </Grid>
          </Card>
        </Grid>
      </Grid>
    </div>
  )
}
