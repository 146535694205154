import { gql } from "apollo-boost"
import {
  categories_widgetTextCategories,
  categories
} from "../generated/categories"

export const WIDGET_TEXT_CATEGORY_QUERY = gql`
  query categories {
    widgetTextCategories {
      id
      name
      translationKey
      widgetTextTemplates {
        id
        rating
        title
        textTemplate
      }
    }
  }
`

export type WidgetTextCategoriesResult = categories
export type WidgetTextCategory = categories_widgetTextCategories
