import { gql } from "apollo-boost"
import {
  externalTownSearch,
  externalTownSearchVariables,
  externalTownSearch_externalTownSearch,
} from "../generated/externalTownSearch"

export const EXTERNAL_TOWN_SEARCH_QUERY = gql`
  query externalTownSearch($searchString: String!, $type: ExternalTownType!) {
    externalTownSearch(searchString: $searchString, type: $type) {
      id
      name
      type
      postalcode
      externalId
      latitude
      longitude
    }
  }
`

export type ExternalTownSearchQueryResult = externalTownSearch
export type ExternalTownSearchQueryVariables = externalTownSearchVariables
export type ExternalTown = externalTownSearch_externalTownSearch
