import { gql } from "apollo-boost"
import {
  deleteAssociation,
  deleteAssociationVariables
} from "../generated/deleteAssociation"

export const DELETE_ASSOCIATION_MUTATION = gql`
  mutation deleteAssociation($id: ID!) {
    deleteAssociation(id: $id) {
      error
      message
    }
  }
`

export type DeleteAssociationResult = deleteAssociation
export type DeleteAssociationVariables = deleteAssociationVariables
