import React, { FunctionComponent } from "react"
import { makeStyles, Theme, AppBar, Toolbar, Typography, Grid } from "@material-ui/core"

const useStyles = makeStyles((theme: Theme) => ({
  topBar: {
    zIndex: 100,
  },
}))

interface ITopBarProps {}

export const TopBar: FunctionComponent<ITopBarProps> = (props) => {
  const { children } = props
  const classes = useStyles()

  return (
    <AppBar position={"relative"} elevation={1} className={classes.topBar}>
      <Toolbar>
        <Grid container justify={"space-between"} alignContent={"center"} alignItems={"center"}>
          <Typography variant={"h6"}>{children}</Typography>
        </Grid>
      </Toolbar>
    </AppBar>
  )
}
