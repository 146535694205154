import React, { FunctionComponent, useContext } from "react"
import { Theme, makeStyles, Grid, ButtonGroup, Button, Typography, Divider } from "@material-ui/core"
import { useTranslation } from "react-i18next"
import { Paper } from "@material-ui/core"
import { Check, Search } from "@material-ui/icons"
import { RfidChipFilterContext } from "../context/RfidChipFilterContext"
import WertstoffTextfield from "../../controls/WertstoffTextfield.jsx"
import Close from "@material-ui/icons/Close"

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    padding: theme.spacing(2),
    justifyContent: "center",
    color: theme.palette.text.secondary,
    borderRadius: theme.spacing(0),
  },
}))

interface IRfidChipFilterProps {}

export const RfidChipFilter: FunctionComponent<IRfidChipFilterProps> = (props) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const { rfidSearchTerm, setRfidSearchTerm, uniquelyAssignableChecked, setUniquelyAssignableChecked } = useContext(
    RfidChipFilterContext,
  )

  return (
    <Paper className={classes.paper}>
      <Grid container spacing={2}>
        <Grid item xs>
          <WertstoffTextfield
            debounce
            placeholder={t("general.search")}
            value={rfidSearchTerm}
            onChange={(event) => setRfidSearchTerm(event.target.value)}
            startAdornment={<Search />}
          />
        </Grid>
        <Grid item>
          <Divider orientation="vertical" />
        </Grid>
        <Grid item>
          <Grid container direction="column">
            <Grid item>
              <Typography align="center">{t("rfid.property.uniquely_assignable")}</Typography>
            </Grid>
            <Grid item>
              <ButtonGroup color="primary">
                <Button
                  variant={uniquelyAssignableChecked === true ? "contained" : undefined}
                  disableElevation
                  onClick={() => setUniquelyAssignableChecked(true)}
                >
                  <Check />
                </Button>
                <Button
                  variant={uniquelyAssignableChecked === false ? "contained" : undefined}
                  disableElevation
                  onClick={() => setUniquelyAssignableChecked(false)}
                >
                  <Close />
                </Button>
                <Button
                  variant={uniquelyAssignableChecked === null ? "contained" : undefined}
                  disableElevation
                  onClick={() => setUniquelyAssignableChecked(null)}
                >
                  {t("general.all")}
                </Button>
              </ButtonGroup>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  )
}
