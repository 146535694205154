import React, { FunctionComponent } from "react"
import { Grid, Typography, Theme, makeStyles } from "@material-ui/core"
import { useTranslation } from "react-i18next"
import { ReportFilter } from "../../../api/graphql/query/reports"
import { useQuery } from "react-apollo"
import { getRatingInfo } from "../../../domain/helper/CalcRating"
import {
  EvaluationMinimumForFilter,
  EvaluationMinimumForFilterVariables,
} from "../../../api/graphql/generated/EvaluationMinimumForFilter"
import { EVALULATION_MINIMUM_FOR_FILTER_QUERY } from "../../../api/graphql/query/evaluationMinimumForFilter"
import lodash from "lodash"

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    margin: 0,
  },
}))

interface IOverallRatingHeadingProps {
  filter: ReportFilter
}

export const OverallRatingHeading: FunctionComponent<IOverallRatingHeadingProps> = (props) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const { filter } = props
  const { data, loading } = useQuery<EvaluationMinimumForFilter, EvaluationMinimumForFilterVariables>(
    EVALULATION_MINIMUM_FOR_FILTER_QUERY,
    {
      variables: {
        filterId: filter.id,
      },
    },
  )

  const rating = data?.materialAnalysisForFilter?.evaluation?.rating
  const overallRating: string = lodash.isNil(rating) ? t("table.no_data") : getRatingInfo(rating).rating

  return (
    <Grid container direction={"row"} justify={"flex-start"} spacing={2} className={classes.container}>
      <Grid item>
        <Grid container direction={"column"}>
          <Grid item>
            <Typography variant={"body1"}>{t("report.overall_rating")}</Typography>
          </Grid>
          <Grid item>
            <Typography variant={"h6"}>
              {!loading && t(overallRating)}
              {loading && "-"}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}
