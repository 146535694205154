import { gql } from "apollo-boost"
import {
  associationsWithTowns
} from "../generated/associationsWithTowns"
import { FRAGMENT_ASSOCIATION_WITH_TOWNS } from "../fragments/association-with-town"

export const ASSOCIATIONS_WITH_TOWNS_QUERY = gql`
  query associationsWithTowns {
    associations {
      ...FragmentAssociationWithTowns
    }
  }
  ${FRAGMENT_ASSOCIATION_WITH_TOWNS}
`

export type AssociationsWithTownsResult = associationsWithTowns
