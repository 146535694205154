import {
  UpdateHousingManagement as UpdateHousingManagementRes,
  UpdateHousingManagement_updateHousingManagement,
} from "./../generated/UpdateHousingManagement"
import { gql } from "apollo-boost"

export const UPDATE_HOUSING_MANAGEMENT_MUTATION = gql`
  mutation UpdateHousingManagement($updateHousingManagementId: String!, $name: String!, $rfids: [String!]!) {
    updateHousingManagement(id: $updateHousingManagementId, name: $name, rfids: $rfids) {
      name
      rfids
      associations {
        id
        name
      }
      towns {
        id
        associationId
        name
      }
    }
  }
`

export interface UpdateHousingManagementResult extends UpdateHousingManagementRes {}
export interface UpdateHousingManagement extends UpdateHousingManagement_updateHousingManagement {}
