import React, { FunctionComponent, useState, useEffect } from "react"
import { Theme, makeStyles, Grid, Select, InputLabel, MenuItem } from "@material-ui/core"
import { useTranslation } from "react-i18next"
import { ClassifierForTourTypeInput, TourType } from "../../../api/graphql/generated/globalTypes"
import { useQuery } from "react-apollo"
import { Classifier, ClassifiersResult, CLASSIFIERS_QUERY } from "../../../api/graphql/query/classifiers"
import moment from "moment"

const useStyles = makeStyles((theme: Theme) => ({
  textFieldContainer: {
    margin: 0,
  },
  defaultItem: {
    fontStyle: "italic",
  },
}))

export const DEFAULT_CLASSIFIER_ID = "default"

interface ITourTypeClassifier {
  setClassifiers: (classifiers: ClassifierForTourTypeInput[]) => void
  initialClassifiers: ClassifierForTourTypeInput[]
  defaultClassifiers: ClassifierForTourTypeInput[]
}

export const TourTypeClassifier: FunctionComponent<ITourTypeClassifier> = (props) => {
  const { setClassifiers, initialClassifiers, defaultClassifiers } = props
  const [selectedClassifiers, setSelectedClassifiers] = useState<ClassifierForTourTypeInput[]>([])

  const { data: classifierData, loading: classifierLoading } = useQuery<ClassifiersResult>(CLASSIFIERS_QUERY, {
    fetchPolicy: "no-cache",
  })
  const classifiers: Classifier[] = classifierData?.classifiers ?? []

  useEffect(() => {
    setSelectedClassifiers(initialClassifiers)
  }, [initialClassifiers])

  const updateSelectedClassifiers = (classifierId: string, selectedTourType: string) => {
    const updatedClassifiers = selectedClassifiers.filter(
      (classifier) => classifier.tourType !== (selectedTourType as TourType),
    )
    const updateClassifier = {
      tourType: selectedTourType as TourType,
      classifierId,
    }
    updatedClassifiers.push(updateClassifier)
    setSelectedClassifiers(updatedClassifiers)
    setClassifiers(updatedClassifiers.filter((c) => c.classifierId !== DEFAULT_CLASSIFIER_ID))
  }

  const initValue = (tourType: TourType) => {
    const selectedClassifier = initialClassifiers.find((classifier) => classifier.tourType === tourType)
    return selectedClassifier ? selectedClassifier.classifierId : DEFAULT_CLASSIFIER_ID
  }

  const findDefaultClassifier = (tourType: TourType): Classifier => {
    const defaultClassifierFromProps = defaultClassifiers.find((c) => c.tourType === tourType)
    if (defaultClassifierFromProps) {
      return classifiers.find((c) => c.id === defaultClassifierFromProps.classifierId)
    }
    return classifiers.filter((c) => c.tourType === tourType).sort((a, b) => moment(b.deployedAt).diff(a.deployedAt))[0]
  }

  return (
    <>
      {!classifierLoading &&
        Object.keys(TourType).map((tourType) => {
          return (
            <TourTypeClassifierSelection
              tourType={tourType}
              onChange={updateSelectedClassifiers}
              classifiers={classifiers.filter((classifier: Classifier) => classifier.tourType === tourType)}
              initialValue={initValue(tourType as TourType)}
              key={tourType}
              defaultClassifier={findDefaultClassifier(tourType as TourType)}
            />
          )
        })}
    </>
  )
}

interface ITourTypeClassifierSelection {
  tourType: string
  classifiers: Classifier[]
  defaultClassifier: Classifier
  onChange: (classifierId: string, tourType: string) => void
  initialValue: string
}

export const TourTypeClassifierSelection: FunctionComponent<ITourTypeClassifierSelection> = (props) => {
  const { tourType, classifiers, onChange, initialValue, defaultClassifier } = props
  const { t } = useTranslation()
  const classes = useStyles()
  const [classifier, setClassifier] = useState<string>(initialValue)

  const onSelectionChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const classifierId = event.target.value as string
    setClassifier(classifierId)
    onChange(classifierId, tourType)
  }

  return (
    <Grid item xs={6} className={classes.textFieldContainer}>
      <InputLabel id={`association-default-classifier-${tourType}-label`}>
        {t(`tour_types.${tourType}`)} {t("classifier")}
      </InputLabel>
      <Select
        fullWidth
        labelId={`association-default-classifier-${tourType}-label`}
        id={`association-default-classifier-${tourType}`}
        value={classifier}
        onChange={onSelectionChange}
        className={classifier === DEFAULT_CLASSIFIER_ID ? classes.defaultItem : null}
      >
        <MenuItem key={DEFAULT_CLASSIFIER_ID} value={DEFAULT_CLASSIFIER_ID} className={classes.defaultItem}>
          {t("general.default")} - {defaultClassifier.title}
        </MenuItem>
        {classifiers &&
          classifiers
            .filter((tourtype) => tourtype.tourType === tourType)
            .map((classifier) => {
              return (
                <MenuItem value={classifier.id} key={classifier.id}>
                  {classifier.title} - {moment(classifier.deployedAt).format("DD.MM.YYYY")}
                </MenuItem>
              )
            })}
      </Select>
    </Grid>
  )
}
