import { gql } from "apollo-boost"
import { deleteUnassignableAddress, deleteUnassignableAddressVariables } from "../generated/deleteUnassignableAddress"

export const DELETE_UNASSIGNABLE_ADDRESS_MUTATION = gql`
  mutation deleteUnassignableAddress($id: ID!) {
    deleteUnassignableAddress(id: $id)
  }
`

export type DeleteUnassignableAddressMutationResult = deleteUnassignableAddress
export type DeleteUnassignableAddressMutationVariables = deleteUnassignableAddressVariables
