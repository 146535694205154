import React from "react"
import { ListItem, ListItemText, ListItemSecondaryAction, IconButton } from "@material-ui/core"
import { Delete } from "@material-ui/icons"

export interface IRfidGroupRfidCollectionItemProps {
  rfid: string
  removeRfid: (rfid: string) => void
}

export const RfidGroupRfidCollectionItem: React.FunctionComponent<IRfidGroupRfidCollectionItemProps> = (props) => {
  const { rfid, removeRfid } = props

  return (
    <ListItem key={rfid}>
      <ListItemText>{rfid}</ListItemText>
      <ListItemSecondaryAction>
        <IconButton edge="end" aria-label="delete" onClick={() => removeRfid(rfid)}>
          <Delete />
        </IconButton>
      </ListItemSecondaryAction>
    </ListItem>
  )
}
