import React, { FunctionComponent, useContext, useState, Fragment } from "react"
import { Grid, Typography, CircularProgress } from "@material-ui/core"
import { useTranslation } from "react-i18next"
import { AdvancementPDFButton } from "./AdvancementPDFButton"
import { useQuery } from "react-apollo"
import lodash from "lodash"
import { AdvancementPdfItems, AdvancementPdfItemsVariables } from "../../../api/graphql/generated/AdvancementPdfItems"
import { ADVANCEMENT_PDF_ITEMS } from "../../../api/graphql/query/advancementPDFItems"
import { PDFFilterContext } from "../../../context/PDFFilterContext"

interface IAdvancementPDFSectionProps {}

export const AdvancementPDFSection: FunctionComponent<IAdvancementPDFSectionProps> = (props) => {
  const { t } = useTranslation()
  const { isFilterValid, associationId, townId, startDate, endDate, ratings, tourType } = useContext(PDFFilterContext)
  const [numberOfItems, setNumberOfItems] = useState(0)

  const { loading, error } = useQuery<AdvancementPdfItems, AdvancementPdfItemsVariables>(ADVANCEMENT_PDF_ITEMS, {
    fetchPolicy: "no-cache",
    onError: console.log,
    variables: {
      associationIds: associationId ? [associationId] : null,
      townIds: townId ? [townId] : null,
      tourType,
      firstDate: startDate,
      lastDate: endDate,
      ratings: ratings,
    },
    onCompleted: (result) => {
      setNumberOfItems(result?.materialAnalysis?.materialAnalysis.length ?? 0)
    },
    skip: !isFilterValid,
  })

  return (
    <Grid container spacing={2}>
      {(!lodash.isNil(error) || numberOfItems === 0 || !isFilterValid) && !loading && (
        <Grid item xs container justify="center" alignItems="center">
          <Grid item>
            <Typography color="secondary">{t("analysis_page.no_data_for_this_filter")}</Typography>
          </Grid>
        </Grid>
      )}
      {lodash.isNil(error) && numberOfItems !== 0 && isFilterValid && !loading && (
        <Fragment>
          <Grid item container>
            <Grid>
              <Typography variant={"body1"}>
                {t("analysis_page.advancement_pdf.disclaimer", { numberOfItems })}
              </Typography>
            </Grid>
          </Grid>
          <Grid item container>
            <Grid>
              <Typography variant={"body1"}>{t("analysis_page.advancement_pdf.terms_and_condition")}</Typography>
            </Grid>
          </Grid>
          <Grid item container>
            <Grid>
              <Typography variant={"body1"}>{t("analysis_page.advancement_pdf.gps_accuracy")}</Typography>
            </Grid>
          </Grid>
        </Fragment>
      )}
      {loading && (
        <Grid container item xs={12} justify="center">
          <CircularProgress size={24} />
        </Grid>
      )}
      <Grid item container justify={"flex-end"} spacing={2}>
        <Grid item>
          <AdvancementPDFButton disabled={loading || !lodash.isNil(error) || numberOfItems === 0 || !isFilterValid} />
        </Grid>
      </Grid>
    </Grid>
  )
}
