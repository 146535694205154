import React, { FunctionComponent, useContext } from "react"
import { Button, ButtonProps } from "@material-ui/core"
import { useTranslation } from "react-i18next"
import { TextFilterContext } from "../../../context/TextFilterContext"

interface IClearTextFilterButtonProps extends ButtonProps {}

export const ClearTextFilterButton: FunctionComponent<
  IClearTextFilterButtonProps
> = props => {
  const { t } = useTranslation()

  const { resetFilter } = useContext(TextFilterContext)

  return (
    <Button onClick={resetFilter} {...props}>
      {t("general.clear")}
    </Button>
  )
}
