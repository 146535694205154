import React from "react"
import PropTypes from "prop-types"
import lodash from "lodash"
import { withTranslation } from "react-i18next"

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles"

import { WidgetTypesAll } from "../../../../domain/models/WidgetModels"
import Fab from "@material-ui/core/Fab"
import AddIcon from "@material-ui/icons/Add"
import { Button, Select, Dialog, FormControl, DialogContent, DialogTitle, DialogActions, Grid } from "@material-ui/core"
import { Mutation } from "react-apollo"
import { MUTATION as CREATE_WIDGET_MUTATION } from "../../../../api/graphql/mutation/create-new-widget"
import { generateWidgetTitleFromFilter } from "../../../../domain/helper/GenerateWidgetTitle"

// @Styles
const styles = (theme) => ({
  gridContainer: {
    width: "100%",
    flex: 1,
  },
})

class WidgetNew extends React.Component {
  constructor(props) {
    super(props)
    this.props = props
    this.state = {
      dialogOpen: false,
      selectedWidgetItem: -1,
    }
  }

  componentWillReceiveProps(nextProps) {
    this.props = nextProps
  }

  handleClick = (event) => {
    this.setState({
      dialogOpen: true,
    })
  }

  handleWidgetSelectionIndexChange = (event) => {
    this.setState({
      selectedWidgetItem: event.target.value,
    })
  }

  handleDialogSave = (event, createWidgetMutation) => {
    const { t } = this.props
    let reportId = this.props.report.id

    if (this.state.selectedWidgetItem === -1) {
      return
    }
    let widgetType = WidgetTypesAll.find((type) => type.title === this.state.selectedWidgetItem)
    if (!widgetType) {
      return
    }

    const filter = lodash.get(this.props, "report.filter")

    this.setState({
      dialogOpen: false,
      selectedWidgetItem: -1,
    })

    createWidgetMutation({
      variables: {
        reportId: reportId,
        widgetType: widgetType.type,
        channelType: this.props.channelType,
        filterId: this.props.report.filter.id,
        title: generateWidgetTitleFromFilter(widgetType, filter, t),
        chartCompact: true,
      },
    })
  }

  handleCreateWidgetMutationComplete = () => {
    this.props.triggerRefresh()
  }

  handleCreateWidgetMutationError = () => {
    this.props.triggerRefresh()
  }

  handleDialogClose = () => {
    this.setState({
      dialogOpen: false,
      selectedWidgetItem: -1,
    })
  }

  render() {
    const { classes, widgetModel, t } = this.props

    return (
      <Mutation
        mutation={CREATE_WIDGET_MUTATION}
        onCompleted={this.handleCreateWidgetMutationComplete}
        onError={this.handleCreateWidgetMutationError}
      >
        {(createWidgetMutation) => (
          <>
            <Grid
              container
              className={classes.gridContainer}
              direction={"column"}
              justify="center"
              alignItems="center"
              alignContent="center"
            >
              <Grid item>
                <Fab color="primary" aria-label="Add" className={classes.margin} onClick={this.handleClick}>
                  <AddIcon />
                </Fab>
              </Grid>
              <Grid item>
                <Button onClick={this.handleClick}>{widgetModel.widgetTitle}</Button>
              </Grid>
            </Grid>

            {/* Dialog for editing the text widget: */}
            {this.state.dialogOpen && (
              <Dialog
                open={this.state.dialogOpen}
                onClose={this.handleEditClose}
                aria-labelledby="form-dialog-title"
                maxWidth={"xs"}
                fullWidth={true}
                disableBackdropClick={true}
              >
                <DialogTitle id="form-dialog-title">{t("communication_channels.choose_component")}</DialogTitle>
                <DialogContent>
                  <form className={classes.form} noValidate>
                    <FormControl className={classes.formControl}>
                      <Select
                        style={{ marginBottom: "8px" }}
                        value={this.state.selectedWidgetItem}
                        onChange={this.handleWidgetSelectionIndexChange}
                        fullWidth
                      >
                        {this.props.menuItems}
                      </Select>
                    </FormControl>
                  </form>
                </DialogContent>
                <DialogActions>
                  <Button onClick={this.handleDialogClose} color="primary">
                    {t("general.cancel")}
                  </Button>
                  <Button
                    onClick={(event) => this.handleDialogSave(event, createWidgetMutation)}
                    color="primary"
                    disabled={this.state.selectedWidgetItem === -1}
                  >
                    {t("general.create")}
                  </Button>
                </DialogActions>
              </Dialog>
            )}
          </>
        )}
      </Mutation>
    )
  }
}

WidgetNew.propTypes = {
  widgetModel: PropTypes.object,
  onClick: PropTypes.func,
  triggerRefresh: PropTypes.func.isRequired,
  report: PropTypes.object.isRequired,
  channel: PropTypes.object.isRequired,
}

WidgetNew.defaultProps = {
  widgetModel: null,
  onClick: null,
}

export default withStyles(styles)(withTranslation()(WidgetNew))
