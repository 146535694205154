import React from "react"
import { TFunction } from "i18next"
import { TextPlaceholderKey } from "../../../api/graphql/generated/globalTypes"

/**
 * generates an list of boldified span objects
 * @param text: should come from "generateTextFromTemplate(..., markAsBold: true) "
 * @param t: i18n TranslationFuntion
 */
export function generateBoldTexts(text: string, t: TFunction): JSX.Element[] {
  const boldStart = "<b>"
  const boldEnd = "</b>"
  const boldMark = "€€€"

  const textManipulated = text.replace(new RegExp(boldStart, "g"), `${boldStart}${boldMark}`)

  const splitted = textManipulated.split(new RegExp(`${boldStart}|${boldEnd}`, "g")).map((e) => {
    const isBold = e.startsWith(boldMark)
    let newE = e.replace(boldMark, "")

    // Handle SINGLE and DOUBLE Line:
    if (newE === t(`text_placeholder.${TextPlaceholderKey.NEW_LINE_SINGLE}`)) {
      newE = t(`text_placeholder.preview.${TextPlaceholderKey.NEW_LINE_SINGLE}`)
    } else if (newE === t(`text_placeholder.${TextPlaceholderKey.NEW_LINE_DOUBLE}`)) {
      newE = t(`text_placeholder.preview.${TextPlaceholderKey.NEW_LINE_DOUBLE}`)
    }

    return <span style={{ fontWeight: isBold ? "bold" : "normal" }}>{newE}</span>
  })

  return splitted
}
