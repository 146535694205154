import React, { FC, FunctionComponent, useMemo } from "react"
import { useTranslation } from "react-i18next"
import {
  DatePicker,
  DatePickerProps,
  DateTimePicker,
  DateTimePickerProps,
  KeyboardDatePicker,
  KeyboardDatePickerProps,
  KeyboardDateTimePicker,
  KeyboardDateTimePickerProps,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers"
import moment, { Moment } from "moment"
import MomentUtils from "@date-io/moment"
import { ArrowLeft, ArrowRight, ArrowDropDown } from "@material-ui/icons"
import { makeStyles, Theme } from "@material-ui/core"
import { ParsableDate } from "@material-ui/pickers/constants/prop-types"
import lodash from "lodash"

const useStyles = makeStyles((theme: Theme) => ({
  menu: {
    width: 200,
  },
  datePicker: {
    cursor: "pointer !important",
  },
}))

interface IWertstoffDatePickerProps {
  name?: string | undefined
  value?: ParsableDate | undefined
  disableFuture?: boolean | undefined
  onChange: (date: Moment | undefined) => void
  error?: boolean | undefined
  disabled?: boolean | undefined
  allPossible?: boolean | undefined
  minDate?: string | undefined
  maxDate?: string | undefined
  isClearable?: boolean | undefined
  helperText?: string | undefined
  margin?: "normal" | "none" | "dense"
  useDateTimePicker?: boolean
  useKeyboardPicker?: boolean
  fullWidth?: boolean | undefined
  required?: boolean | undefined
}

export const WertstoffDatePicker: FunctionComponent<IWertstoffDatePickerProps> = (props) => {
  const classes = useStyles()
  const { t, i18n } = useTranslation()

  const {
    name,
    value,
    disableFuture,
    minDate,
    maxDate,
    onChange,
    isClearable,
    helperText,
    error,
    margin = "normal",
    useDateTimePicker,
    useKeyboardPicker,
    fullWidth = true,
    required,
  } = props

  const language = useMemo(() => {
    const allLocals = moment.locales()
    const locale = allLocals.find((momentLocale) => momentLocale.startsWith(i18n.language, 0))
    moment.locale(locale)
    return locale || allLocals[0]
  }, [i18n.language])

  const { Component, format } = useMemo(() => {
    let _Component:
      | FC<DatePickerProps>
      | FC<DateTimePickerProps>
      | FC<KeyboardDatePickerProps>
      | FC<KeyboardDateTimePickerProps> = DatePicker

    if (useKeyboardPicker && useDateTimePicker) {
      _Component = KeyboardDateTimePicker
    } else if (useKeyboardPicker) {
      _Component = KeyboardDatePicker
    } else if (useDateTimePicker) {
      _Component = DateTimePicker
    }

    return {
      Component: _Component,
      format: useDateTimePicker ? "DD.MM.YYYY / HH:mm" : "DD.MM.YYYY",
    }
  }, [useDateTimePicker])

  const clearable = useMemo(() => {
    if (lodash.isNil(isClearable)) {
      return true
    }
    return isClearable
  }, [isClearable])

  return (
    <MuiPickersUtilsProvider utils={MomentUtils} libInstance={moment} locale={language}>
      <Component
        translate="yes"
        label={name}
        placeholder={""}
        ampm={false}
        disableFuture={disableFuture}
        minDateMessage="Zeitraum darf nicht vor dem minimalen Zeitpunkt liegen."
        maxDateMessage="Zeitraum darf nicht in der Zukunft liegen"
        invalidDateMessage={t("validation.date.notpossible")}
        value={value}
        minDate={minDate}
        maxDate={maxDate}
        onChange={(value) => onChange(value || undefined)}
        clearable={clearable}
        autoOk
        clearLabel={t("general.delete")}
        cancelLabel={t("general.cancel")}
        okLabel={"Ok"}
        leftArrowIcon={<ArrowLeft color={"primary"} />}
        rightArrowIcon={<ArrowRight color={"primary"} />}
        InputLabelProps={{
          shrink: true,
        }}
        InputProps={{
          endAdornment: <ArrowDropDown />,
          className: classes.datePicker,
        }}
        fullWidth={fullWidth}
        helperText={helperText}
        margin={margin}
        format={format}
        error={error}
        required={required}
      />
    </MuiPickersUtilsProvider>
  )
}
