import { gql } from "apollo-boost"
import { citizensXLSXForTown, citizensXLSXForTownVariables } from "../generated/citizensXLSXForTown"

export const CITIZENS_XLSX_FOR_TOWN_QUERY = gql`
  query citizensXLSXForTown($townId: ID!) {
    citizensXLSXForTown(townId: $townId) {
      base64
    }
  }
`

export type citizensXLSXResult = citizensXLSXForTown
export type citizensXLSXVariables = citizensXLSXForTownVariables
