import React, { FunctionComponent, useContext, useEffect, useMemo } from "react"
import { Theme, makeStyles, Grid, Paper, Typography } from "@material-ui/core"
import { useTranslation } from "react-i18next"
import { LogsQueryResult, LogsQueryVariables, LOGS_QUERY } from "../../../api/graphql/query/logs"
import { RootGrid } from "../layout/RootGrid"
import { useLazyQuery } from "react-apollo"
import { PageLoadingIndicator } from "../indicator/PageLoadingIndicator"
import { LogTable } from "./LogTable"
import { LogFilterContext } from "./context/LogFilterContext"

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    padding: theme.spacing(2),
  },
  paper: {
    flex: 1,
    display: "flex",
  },
}))

interface ILogPageContentProps {}

export const LogPageContent: FunctionComponent<ILogPageContentProps> = (props) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const { associationId, townId, applyFilter, setApplyFilter, dateFrom, dateUntil, recipient } = useContext(
    LogFilterContext,
  )

  const [logsQuery, { loading, data }] = useLazyQuery<LogsQueryResult, LogsQueryVariables>(LOGS_QUERY, {
    fetchPolicy: "no-cache",
  })

  useEffect(() => {
    if (applyFilter) {
      logsQuery({
        variables: {
          associationId,
          townId: !!townId && townId !== "all" ? townId : null,
          dateFrom,
          dateUntil,
          recipient,
        },
      })
      setApplyFilter(false)
    }
  }, [applyFilter, logsQuery, associationId, townId, dateFrom, dateUntil, recipient])

  const logs = useMemo(() => data?.logs || [], [data])

  return (
    <RootGrid spacing={2} direction="column" item xs>
      {loading && <PageLoadingIndicator />}
      {!loading && logs.length <= 0 && (
        <Grid item xs container justify="center" alignItems="center">
          <Grid item>
            <Typography color="secondary">{t("analysis_page.no_data_for_this_filter")}</Typography>
          </Grid>
        </Grid>
      )}
      {!loading && logs.length > 0 && (
        <Grid item xs container>
          <Paper className={classes.paper}>
            <LogTable logEntries={logs} />
          </Paper>
        </Grid>
      )}
    </RootGrid>
  )
}
