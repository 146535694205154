import { MaterialAnalysisSource } from "./../api/graphql/generated/globalTypes"
import { useCallback, useState } from "react"
import { Rating, TourType } from "../api/graphql/generated/globalTypes"
import { IAnalysisFilter } from "../domain/models/AnalysisFilterModel"
import moment from "moment"

export const getInitialAnalysisFilterDates = () => {
  return { dateFrom: moment().subtract(10, "d").toDate(), dateUntil: moment().toDate() }
}

export const useFilter = (): IAnalysisFilter => {
  const [associationId, setAssociationId] = useState<string | null>(null)
  const [townIds, setTownIds] = useState<string[] | null>(null)
  const [emptyingId, setEmptyingId] = useState<string | null>(null)
  const [tourDate, setTourDate] = useState<Date | null>(null)
  const [displayOnlyAutomaticAnalysis, setDisplayOnlyAutomaticAnalysis] = useState<boolean | null>(null)
  const [rating, setRating] = useState<Rating | null>(null)
  const [tourType, setTourType] = useState<TourType | null>(TourType.residual)
  const [source, setSource] = useState<MaterialAnalysisSource | null>(null)
  const [fractionType, setFractionType] = useState<string | null>(null)
  const [fractionFrom, setFractionFrom] = useState<string | null>(null)
  const [fractionTo, setFractionTo] = useState<string | null>(null)
  const [dateFrom, setDateFrom] = useState<Date | null>(getInitialAnalysisFilterDates().dateFrom)
  const [dateUntil, setDateUntil] = useState<Date | null>(getInitialAnalysisFilterDates().dateUntil)
  const [rfids, setRfids] = useState<string | null>(null)

  const setModel = useCallback(
    (newModel) => {
      setAssociationId(newModel.associationId)
      setTownIds(newModel.townIds)
      setEmptyingId(newModel.emptyingId)
      setTourDate(newModel.tourDate)
      setDisplayOnlyAutomaticAnalysis(newModel.displayOnlyAutomaticAnalysis)
      setRating(newModel.rating)
      setTourType(newModel.tourType)
      setSource(newModel.source)
      setFractionType(newModel.fractionType)
      setFractionFrom(newModel.fractionFrom)
      setFractionTo(newModel.fractionTo)
      setDateFrom(newModel.dateFrom)
      setDateUntil(newModel.dateUntil)
      setRfids(newModel.rfids)
    },
    [
      setAssociationId,
      setTownIds,
      setEmptyingId,
      setTourDate,
      setDisplayOnlyAutomaticAnalysis,
      setRating,
      setTourType,
      setSource,
      setFractionType,
      setFractionFrom,
      setFractionTo,
      setDateFrom,
      setDateUntil,
      setRfids,
    ],
  )

  return {
    associationId,
    setAssociationId,
    townIds,
    setTownIds,
    emptyingId,
    setEmptyingId,
    tourDate,
    setTourDate,
    displayOnlyAutomaticAnalysis,
    setDisplayOnlyAutomaticAnalysis,
    rating,
    setRating,
    tourType,
    setTourType,
    source,
    setSource,
    fractionType,
    setFractionType,
    fractionFrom,
    setFractionFrom,
    fractionTo,
    setFractionTo,
    dateFrom,
    setDateFrom,
    dateUntil,
    setDateUntil,
    rfids,
    setRfids,
    setModel,
  }
}
