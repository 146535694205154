import { gql } from "apollo-boost"
import { createUser, createUserVariables } from "../generated/createUser"

export const CREATE_USER_MUTATION = gql`
  mutation createUser($userAndPermissions: UserAndPermissionsInput!) {
    createUser(userAndPermissions: $userAndPermissions) {
      name
    }
  }
`

export type CreateUserResult = createUser
export type CreateUserVariables = createUserVariables
