import React, { FunctionComponent, useEffect } from "react"
import { Grid } from "@material-ui/core"
import { UserAndPermissionsTown } from "../../../api/graphql/query/permitted-associations-for-user"
import { RfidGroupTableForTown } from "./RfidGroupTableForTown"
import { useQuery } from "react-apollo"
import {
  RfidGroup,
  RfidGroupsQueryResult,
  RfidGroupsQueryVariables,
  RFID_GROUPS_QUERY,
} from "../../../api/graphql/query/rfid-groups-for-town"

interface IRfidGroupTableWrapperProps {
  town: UserAndPermissionsTown
  setRfidGroupToEdit: (rfidGroup: RfidGroup) => void
  setRfidGroupToDelete: (rfidChip: RfidGroup) => void
  toggleRefetch: boolean
}

export const RfidGroupTableWrapper: FunctionComponent<IRfidGroupTableWrapperProps> = (props) => {
  const { town, toggleRefetch, setRfidGroupToEdit, setRfidGroupToDelete } = props

  const { data, error, loading, refetch } = useQuery<RfidGroupsQueryResult, RfidGroupsQueryVariables>(
    RFID_GROUPS_QUERY,
    {
      variables: { townId: town.id },
    },
  )

  useEffect(() => {
    refetch()
  }, [toggleRefetch, refetch])

  if (error) {
    console.log(error)
  }

  return (
    <Grid container direction="column" spacing={2}>
      <Grid item>
        {!loading && data?.rfidGroups && (
          <RfidGroupTableForTown
            loading={loading}
            setRfidGroupToEdit={setRfidGroupToEdit}
            setRfidGroupToDelete={setRfidGroupToDelete}
            rfidGroups={data?.rfidGroups}
          />
        )}
      </Grid>
    </Grid>
  )
}
