import { gql } from "apollo-boost"
import { updateTour, updateTourVariables } from "../generated/updateTour"

export const UPDATE_TOUR_MUTATION = gql`
  mutation updateTour($id: ID!, $tour: TourInput!) {
    updateTour(id: $id, tour: $tour) {
      towns {
        id
        name
      }
      vehicle {
        id
        wasteScannerId
      }
      tourEnd
      tourStart
      tourType
      id
    }
  }
`

export type UpdateTourResult = updateTour
export type UpdateTourVariables = updateTourVariables
