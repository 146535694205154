import React, { FunctionComponent, useContext, useMemo } from "react"
import { Grid, FormControl, InputLabel, Select, Input, MenuItem, FormHelperText, Typography } from "@material-ui/core"
import { useTranslation } from "react-i18next"
import { WertstoffDatePicker } from "../controls/WertstoffDatePicker"
import { PDFFilterContext } from "../../../context/PDFFilterContext"
import { Rating, TourType } from "../../../api/graphql/generated/globalTypes"
import { Info } from "@material-ui/icons"
import WertstoffDropdown from "../controls/WertstoffDropdown"
import { getTourTypeOptions } from "../analysis-filter/AnalysisFilter"

interface IPDFFilterComponentProps {}

export const PDFFilterComponent: FunctionComponent<IPDFFilterComponentProps> = (props) => {
  const { t } = useTranslation()

  const tourTypeOptions = useMemo(() => getTourTypeOptions(t), [t])

  const {
    associationId,
    setAssociationId,
    townId,
    setTownId,
    tourType,
    setTourType,
    startDate,
    updateStartDate,
    endDate,
    updateEndDate,
    ratings,
    setRatings,
    associations,
    towns,
  } = useContext(PDFFilterContext)

  return (
    <Grid container direction="row" spacing={2}>
      <Grid item xs={4}>
        <WertstoffDropdown
          onChange={(event: any) => setTourType(event.target.value as TourType)}
          value={tourType}
          values={tourTypeOptions}
          allPossible={false}
          name={t("analysis_page.filter_tourtype_name")}
        />
      </Grid>
      <Grid item xs={4}>
        <WertstoffDropdown
          onChange={(event: any) => setAssociationId(event.target.value)}
          value={associationId}
          values={associations}
          allPossible={false}
          name={t("analysis_page.filter.association")}
          customKey={"associationId"}
        />
      </Grid>
      <Grid item xs={4}>
        <WertstoffDropdown
          onChange={(event: any) => setTownId(event.target.value)}
          value={townId || ""}
          values={towns}
          allPossible
          disabled={towns.length === 0}
          name={t("analysis_page.filter.town")}
        />
      </Grid>
      <Grid item xs={4}>
        <FormControl fullWidth={true} margin="normal" error={ratings.length === 0}>
          <InputLabel id="analysis_page.filter.rating.label" shrink={ratings.length !== 0}>
            {t("analysis_page.filter.rating")}
          </InputLabel>
          <Select
            labelId="analysis_page.filter.rating.label"
            id="analysis_page.filter.rating"
            multiple
            value={ratings || []}
            onChange={(event) => setRatings(event.target.value as Rating[])}
            input={<Input />}
            fullWidth
          >
            <MenuItem key={Rating.Low} value={Rating.Low}>
              {t(`waste_quality.${Rating.Low}`)}
            </MenuItem>
            <MenuItem key={Rating.Medium} value={Rating.Medium}>
              {t(`waste_quality.${Rating.Medium}`)}
            </MenuItem>
          </Select>
          {ratings.length === 0 && <FormHelperText>{t("analysis_page.advancement_pdf.ratings_error")}</FormHelperText>}
        </FormControl>
      </Grid>
      <Grid item xs={4}>
        <WertstoffDatePicker
          name={t("analysis_page.filter.period_from")}
          disableFuture
          isClearable={false}
          value={startDate}
          onChange={updateStartDate}
          helperText={
            endDate.isAfter(startDate.clone().add(6, "months"), "day")
              ? t("analysis_page.advancement_pdf.timespan_error")
              : undefined
          }
          error={endDate.isAfter(startDate.clone().add(6, "months"), "day")}
        />
      </Grid>
      <Grid item xs={4}>
        <WertstoffDatePicker
          name={t("analysis_page.filter.period_to")}
          disableFuture
          isClearable={false}
          value={endDate}
          onChange={updateEndDate}
          helperText={
            endDate.isAfter(startDate.clone().add(6, "months"), "day")
              ? t("analysis_page.advancement_pdf.timespan_error")
              : undefined
          }
          error={endDate.isAfter(startDate.clone().add(6, "months"), "day")}
        />
      </Grid>
      <Grid item xs={4} />
      <Grid item xs={8} container direction="row" alignItems="flex-start" spacing={2}>
        <Grid item>
          <Info />
        </Grid>
        <Grid item xs>
          <Typography>{t("analysis_page.advancement_pdf.timespan_info")}</Typography>
        </Grid>
      </Grid>
    </Grid>
  )
}
