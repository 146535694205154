import { gql } from "apollo-boost"
import { updateFilter, updateFilterVariables, updateFilter_updateFilter } from "../generated/updateFilter"

export const UPDATE_FILTER_MUTATION = gql`
  mutation updateFilter($id: ID!, $filter: PersistedFilterInput!) {
    updateFilter(id: $id, filter: $filter) {
      id
      name
      associationId
      townIds
      emptyingWeekNumber
      emptyingYearNumber
      rating
      fractionType
      fractionFrom
      fractionTo
      dateFrom
      dateUntil
      emptying {
        id
        startDate
        weekNumber
        yearNumber
        tours {
          id
          name
        }
      }
      tourType
      displayOnlyAutomaticAnalysis
      rfids
    }
  }
`
export type UpdateFilterResult = updateFilter
export type UpdateFilterVariables = updateFilterVariables
export type UpdatedBookmark = updateFilter_updateFilter
