import { ClipLoader } from "react-spinners"
import React, { ChangeEvent, SyntheticEvent, useCallback, useMemo } from "react"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  makeStyles,
  TextField,
  Theme,
  Checkbox,
  FormControlLabel,
  Divider,
} from "@material-ui/core"
import { useMutation } from "@apollo/react-hooks"
import moment from "moment"
import { RfidChip } from "../../../../api/graphql/query/rfid-chip-for-town"
import {
  UpdateRfidChipMutationResult,
  UpdateRfidChipMutationVariables,
  UPDATE_RFID_CHIP_MUTATION,
} from "../../../../api/graphql/mutation/update-rfid-chip"
import { convertValueToTextfieldType } from "../../../../utils/convert-value-to-textfield-type"
import lodash from "lodash"
import WertstoffDropdown from "../../../partials/controls/WertstoffDropdown"
import { RfidChipType } from "../../../../api/graphql/generated/globalTypes"

moment.locale("de")

const useStyles = makeStyles((theme: Theme) => ({
  separator: {
    width: "100%",
    height: 1,
    backgroundColor: "#979797",
    marginBottom: 37,
  },
  contentRoot: {
    paddingLeft: 0,
    paddingRight: 0,
  },
  content: {
    paddingLeft: 24,
    paddingRight: 24,
    paddingTop: 5,
    paddingBottom: 17,
  },
  highlightedText: {
    backgroundColor: "#D5D5D5",
  },
  menuItemRoot: {
    backgroundColor: "transparent",
    "&:focus": {
      background: "transparent",
    },
  },
  dialogRoot: {
    minHeight: "200px",
    minWidth: "360px",
  },
  formControl: {
    // margin: theme.spacing(1),
    // minWidth: 120,
    width: "100%",
    position: "relative",
  },
  textField: {
    // marginLeft: theme.spacing(1),
    // marginRight: theme.spacing(1),
  },

  textFieldContainer: {
    margin: 0,
  },
  commentContainer: {
    marginTop: theme.spacing(1),
  }
}))

export interface IManageRfidChipDialog {
  rfidChip: RfidChip
  onSuccess: () => void
  isOpen: boolean
  onCloseDialog: () => void
}

interface ISelectOption {
  id: string | undefined
  name: string
}
interface ISelectTarget {
  value: ISelectOption
}

export const ManageRfidChipDialog: React.FunctionComponent<IManageRfidChipDialog> = (props) => {
  const { rfidChip, onSuccess, isOpen, onCloseDialog } = props
  const classes = useStyles()
  const { t } = useTranslation()

  const [modifiedRfidChip, setModifiedRfidChip] = useState<RfidChip>(rfidChip)
  const [isValid, setIsValid] = useState<boolean>(false)

  const typeOptions: ISelectOption[] = useMemo(
    () => Object.keys(RfidChipType).map((type) => ({ id: type, name: t(`rfid.property.types.${type}`) })),
    [t],
  )

  const [updateRfidChipMutation, { loading }] = useMutation<
    UpdateRfidChipMutationResult,
    UpdateRfidChipMutationVariables
  >(UPDATE_RFID_CHIP_MUTATION, { onCompleted: onSuccess, onError: (error) => console.log("error: ", error) })

  const udpate = useCallback(() => {
    if (isValid) {
      updateRfidChipMutation({
        variables: {
          id: modifiedRfidChip.id,
          detailedAddress: {
            city: modifiedRfidChip.detailedAddress?.city,
            postalcode: modifiedRfidChip.detailedAddress?.postalcode,
            street: modifiedRfidChip.detailedAddress?.street,
            streetNumber: modifiedRfidChip.detailedAddress?.streetNumber,
          },
          code: modifiedRfidChip.code,
          uniquelyAssignable: modifiedRfidChip.uniquelyAssignable,
          rfid: modifiedRfidChip.rfid,
          type: modifiedRfidChip.type,
          comment: modifiedRfidChip.comment,
        },
      })
    }
  }, [modifiedRfidChip, isValid, updateRfidChipMutation])

  useEffect(() => {
    if (
      !modifiedRfidChip.rfid ||
      !modifiedRfidChip.detailedAddress?.city ||
      !modifiedRfidChip.detailedAddress?.postalcode ||
      !modifiedRfidChip.detailedAddress?.street ||
      !modifiedRfidChip.detailedAddress?.streetNumber ||
      !modifiedRfidChip.type
    ) {
      setIsValid(false)
    } else {
      setIsValid(true)
    }
  }, [modifiedRfidChip])

  const updateModifiedRfidChip = useCallback(
    (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const temp: RfidChip = lodash.cloneDeep(modifiedRfidChip)
      const { name, type, value } = event.target
      lodash.set(temp, name, convertValueToTextfieldType(value, type))
      setModifiedRfidChip(temp)
    },
    [modifiedRfidChip],
  )

  const updateRfidType = useCallback(
    (event: SyntheticEvent<ISelectTarget>) => {
      const type: RfidChipType = event.target["value"]
      setModifiedRfidChip({
        ...modifiedRfidChip,
        type,
        comment: type === RfidChipType.Sonstiges ? modifiedRfidChip.comment : null,
      })
    },
    [modifiedRfidChip],
  )

  return (
    <Dialog open={isOpen} fullWidth={false} className={classes.dialogRoot} classes={{ paper: classes.dialogRoot }}>
      <form style={{ display: "contents" }}>
        <DialogTitle>{t("rfid_management.dialog.update.title")}</DialogTitle>
        <DialogContent>
          <Grid container spacing={2} alignItems="center" alignContent="center">
            <Grid item xs={12} className={classes.textFieldContainer}>
              <TextField
                name="rfid"
                type="text"
                label={t("rfid.property.rfid")}
                value={modifiedRfidChip.rfid || ""}
                onChange={updateModifiedRfidChip}
                fullWidth
              />
            </Grid>
            <Grid item xs={6} className={classes.textFieldContainer}>
              <WertstoffDropdown
                onChange={updateRfidType}
                value={modifiedRfidChip.type}
                values={typeOptions}
                allPossible={false}
                name={t("rfid.property.type")}
              />
            </Grid>
            {modifiedRfidChip.type === RfidChipType.Sonstiges && (
              <Grid item xs={6} className={classes.commentContainer}>
                <TextField
                  name="comment"
                  type="text"
                  label={t("rfid.property.comment")}
                  value={modifiedRfidChip.comment || ""}
                  onChange={updateModifiedRfidChip}
                  fullWidth
                />
              </Grid>
            )}
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={8} className={classes.textFieldContainer}>
              <TextField
                name={"detailedAddress.street"}
                type="text"
                label={t("detailed_address.property.street")}
                value={modifiedRfidChip.detailedAddress?.street || ""}
                onChange={updateModifiedRfidChip}
                fullWidth
              />
            </Grid>
            <Grid item xs={4} className={classes.textFieldContainer}>
              <TextField
                name={"detailedAddress.streetNumber"}
                type="text"
                label={t("detailed_address.property.street_number")}
                value={modifiedRfidChip.detailedAddress?.streetNumber || ""}
                onChange={updateModifiedRfidChip}
                fullWidth
              />
            </Grid>
            <Grid item xs={4} className={classes.textFieldContainer}>
              <TextField
                name={"detailedAddress.postalcode"}
                type="text"
                label={t("detailed_address.property.postalcode")}
                value={modifiedRfidChip.detailedAddress?.postalcode || ""}
                onChange={updateModifiedRfidChip}
                fullWidth
              />
            </Grid>
            <Grid item xs={8} className={classes.textFieldContainer}>
              <TextField
                name={"detailedAddress.city"}
                type="text"
                label={t("detailed_address.property.city")}
                value={modifiedRfidChip.detailedAddress?.city || ""}
                onChange={updateModifiedRfidChip}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={6} className={classes.textFieldContainer}>
              <TextField
                name="code"
                label={t("rfid.property.code")}
                value={modifiedRfidChip.code}
                fullWidth
                onChange={updateModifiedRfidChip}
              />
            </Grid>
            <Grid item xs={6} className={classes.textFieldContainer}>
              <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    checked={!!modifiedRfidChip.uniquelyAssignable}
                    onChange={() =>
                      setModifiedRfidChip({
                        ...modifiedRfidChip,
                        uniquelyAssignable: !modifiedRfidChip.uniquelyAssignable,
                      })
                    }
                  />
                }
                label={t("rfid.property.uniquely_assignable")}
              />
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions style={{ paddingBottom: 10, paddingRight: 10 }} key={"cancel"}>
          <Button color="primary" onClick={onCloseDialog}>
            {t("general.cancel")}
          </Button>

          <Button color="primary" variant={"contained"} onClick={udpate} disabled={!isValid}>
            {loading ? <ClipLoader color={"white"} size={17} /> : t("tour.dialog.update.button")}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}
