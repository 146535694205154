import { gql } from "apollo-boost"
import { rfidChips, rfidChips_rfidChips, rfidChipsVariables } from "../generated/rfidChips"

export const RFID_CHIPS_QUERY = gql`
  query rfidChips($townId: ID) {
    rfidChips(townId: $townId) {
      id
      rfid
      detailedAddress {
        city
        street
        streetNumber
        postalcode
      }
      code
      uniquelyAssignable
      type
      comment
      citizens {
        _id
      }
    }
  }
`

export type RfidChipsQueryResult = rfidChips
export type RfidChip = rfidChips_rfidChips
export type RfidChipsQueryVariables = rfidChipsVariables
