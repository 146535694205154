import lodash from "lodash"
import moment, { Moment } from "moment"

export function getISOString(value: string | number | void | Moment | Date | null | undefined): string | null {
  if (lodash.isNil(value)) {
    return null
  }

  const momentValue = moment(value)
  if (!momentValue.isValid()) {
    return null
  }
  return momentValue.toISOString()
}
