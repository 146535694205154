import React, { FunctionComponent, useState, ChangeEvent, useEffect, useMemo } from "react"
import {
  Theme,
  makeStyles,
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  TextField,
  DialogActions,
  Button,
} from "@material-ui/core"
import { useTranslation } from "react-i18next"
import lodash from "lodash"
import { useMutation } from "react-apollo"
import { ClipLoader } from "react-spinners"
import { toast } from "react-toastify"
import { getGraphQLErrorLocalized } from "../../../utils/get-graphql-error-code"
import { HousingManagement } from "../../../api/graphql/query/housing-managements"
import {
  CreateHousingManagementResult,
  CREATE_HOUSING_MANAGEMENT_MUTATION,
} from "../../../api/graphql/mutation/create-housing-management"
import {
  UpdateHousingManagementResult,
  UPDATE_HOUSING_MANAGEMENT_MUTATION,
} from "../../../api/graphql/mutation/update-housing-management"
import {
  DeleteHousingManagementResult,
  DELETE_HOUSING_MANAGEMENT_MUTATION,
} from "../../../api/graphql/mutation/delete-housing-management"
import { CreateHousingManagementVariables } from "../../../api/graphql/generated/CreateHousingManagement"
import { UpdateHousingManagementVariables } from "../../../api/graphql/generated/UpdateHousingManagement"
import { DeleteHousingManagementVariables } from "../../../api/graphql/generated/DeleteHousingManagement"
import { useCallback } from "react"

const useStyles = makeStyles((theme: Theme) => ({
  dialogRoot: {
    minHeight: "200px",
    minWidth: "360px",
  },
}))

interface IHousingManagementEditDialogProps {
  housingManagement: HousingManagement | null
  isOpen: boolean
  onCloseDialog: () => void
  onSuccess: () => void
}

export const HousingManagementEditDialog: FunctionComponent<IHousingManagementEditDialogProps> = (props) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { isOpen, onCloseDialog, onSuccess, housingManagement } = props

  const [modified, setModified] = useState<HousingManagement | null>(housingManagement)

  const [createHousingManagementMutation, { loading: createHousingManagementMutationLoading }] = useMutation<
    CreateHousingManagementResult,
    CreateHousingManagementVariables
  >(CREATE_HOUSING_MANAGEMENT_MUTATION, {
    onError: (error) => {
      toast.error(getGraphQLErrorLocalized(error, t))
    },
    onCompleted: (data) => {
      console.log("create success: ", data)
      handleSuccess()
    },
  })

  const [updateHousingManagementMutation, { loading: updateHousingManagementMutationLoading }] = useMutation<
    UpdateHousingManagementResult,
    UpdateHousingManagementVariables
  >(UPDATE_HOUSING_MANAGEMENT_MUTATION, {
    onError: (error) => {
      toast.error(getGraphQLErrorLocalized(error, t))
    },
    onCompleted: () => {
      handleSuccess()
    },
    update: () => undefined,
  })

  const [deleteHousingManagementMutation, { loading: deleteHousingManagementMutationLoading }] = useMutation<
    DeleteHousingManagementResult,
    DeleteHousingManagementVariables
  >(DELETE_HOUSING_MANAGEMENT_MUTATION, {
    onError: (error) => {
      toast.error(getGraphQLErrorLocalized(error, t))
    },
    onCompleted: () => {
      handleSuccess()
    },
  })

  const handleCloseDialog = useCallback(() => {
    setModified(null)
    onCloseDialog()
  }, [onCloseDialog])

  const handleSuccess = useCallback(() => {
    setModified(null)
    onSuccess()
  }, [onSuccess])

  const isInCreateMode = lodash.isNil(housingManagement)
  const isInEditMode = !isInCreateMode

  const isValid = useMemo(() => (modified?.name ? modified.name.length > 0 : false), [modified])

  const onCreateHousingManagement = async () => {
    if (isValid && modified !== null) {
      await createHousingManagementMutation({
        variables: {
          name: modified.name,
          rfids: modified.rfids ? modified.rfids : [],
        },
      })
    }
  }

  const onUpdateHousingManagement = async () => {
    if (isValid && modified !== null) {
      await updateHousingManagementMutation({
        variables: {
          updateHousingManagementId: modified.id,
          name: modified.name,
          rfids: modified.rfids,
        },
      })
    }
  }

  const onDeleteHousingManagement = async () => {
    if (modified !== null) {
      await deleteHousingManagementMutation({
        variables: { deleteHousingManagementId: modified.id },
      })
    }
  }

  const updateModified = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const tempModified = { ...modified }
    const { name, type, value } = event.target
    if (type === "number") {
      lodash.set(tempModified, name, value !== "" ? Number(value) : undefined)
    } else {
      lodash.set(tempModified, name, value)
    }
    setModified(tempModified)
  }
  useEffect(() => {
    setModified(housingManagement)
  }, [housingManagement])

  return (
    <Dialog
      open={isOpen}
      fullWidth
      maxWidth="sm"
      className={classes.dialogRoot}
      classes={{ paper: classes.dialogRoot }}
      onClose={handleCloseDialog}
    >
      <form
        style={{ display: "contents" }}
        onSubmit={(e) => {
          e.preventDefault()
        }}
      >
        <DialogTitle>
          {isInCreateMode ? t("housing_management.dialog.create.title") : t("housing_management.dialog.update.title")}
        </DialogTitle>

        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                name={"name"}
                label={t("housing_management.property.name")}
                value={modified?.name || ""}
                onChange={updateModified}
                fullWidth
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Grid container justify={"space-between"}>
            <Grid item>
              {isInEditMode && (
                <Button
                  color="primary"
                  variant={"contained"}
                  onClick={onDeleteHousingManagement}
                  disabled={!lodash.isString(modified?.id) || deleteHousingManagementMutationLoading}
                >
                  {t("housing_management.dialog.delete.button")}
                </Button>
              )}
            </Grid>
            <Grid item>
              <Button
                color="primary"
                onClick={handleCloseDialog}
                disabled={
                  deleteHousingManagementMutationLoading ||
                  createHousingManagementMutationLoading ||
                  updateHousingManagementMutationLoading
                }
              >
                {t("general.cancel")}
              </Button>
              {isInCreateMode && (
                <Button
                  color="primary"
                  variant={"contained"}
                  onClick={onCreateHousingManagement}
                  disabled={!isValid || createHousingManagementMutationLoading}
                >
                  {createHousingManagementMutationLoading ? (
                    <ClipLoader color={"white"} size={17} />
                  ) : (
                    t("housing_management.dialog.create.button")
                  )}
                </Button>
              )}
              {isInEditMode && (
                <Button
                  color="primary"
                  variant={"contained"}
                  onClick={onUpdateHousingManagement}
                  disabled={!isValid || updateHousingManagementMutationLoading}
                >
                  {t("housing_management.dialog.update.button")}
                </Button>
              )}
            </Grid>
          </Grid>
        </DialogActions>
      </form>
    </Dialog>
  )
}
