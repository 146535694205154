import React, { FunctionComponent } from "react"
import Button from "@material-ui/core/Button"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogContentText from "@material-ui/core/DialogContentText"
import DialogTitle from "@material-ui/core/DialogTitle"

interface ISimpleAlertDialogProps {
  isOpen: boolean
  title: string
  message: string
  onClose: Function
}

export const SimpleAlertDialog: FunctionComponent<
  ISimpleAlertDialogProps
> = props => {
  return (
    <div>
      <Dialog
        open={props.isOpen}
        onClose={() => props.onClose()}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{props.title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {props.message}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => props.onClose()} color="primary" autoFocus>
            {"OK"}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}
