import React, { FunctionComponent, useContext, createContext, useState } from "react"
import { TextFilterContext, ITextFilterContext } from "../../../../context/TextFilterContext"
import { getInitialAnalysisFilterDates } from "../../../../custom-hooks/useFilter"
import moment from "moment"

interface ILogFilterContextProps extends ITextFilterContext {
  recipient: string
  setRecipient: (recipient: string) => void
  dateFrom: Date
  setDateFrom: (dateFrom: Date) => void
  dateUntil: Date
  setDateUntil: (dateUntil: Date) => void
  setApplyFilter: (applyFilter: boolean) => void
  applyFilter: boolean
}

export const LogFilterContext = createContext<ILogFilterContextProps>({} as ILogFilterContextProps)

interface ILogFilterContextProviderProps {}

export const LogFilterContextProvider: FunctionComponent<ILogFilterContextProviderProps> = (props) => {
  const textFilterContextValues = useContext(TextFilterContext)

  const [recipient, setRecipient] = useState<string>("")
  const [dateFrom, setDateFrom] = useState<Date | null>(
    moment(getInitialAnalysisFilterDates().dateFrom).startOf("day").toDate(),
  )
  const [dateUntil, setDateUntil] = useState<Date | null>(
    moment(getInitialAnalysisFilterDates().dateUntil).endOf("day").toDate(),
  )
  const [applyFilter, setApplyFilter] = useState<boolean>(true)

  return (
    <LogFilterContext.Provider
      value={{
        ...textFilterContextValues,
        recipient,
        setRecipient,
        dateFrom,
        setDateFrom,
        dateUntil,
        setDateUntil,
        setApplyFilter,
        applyFilter,
      }}
    >
      {props.children}
    </LogFilterContext.Provider>
  )
}
