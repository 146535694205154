import ReactDOM from "react-dom"
import React from "react"
import { BrowserRouter as Router, Route, Switch } from "react-router-dom"
import { ApolloProvider } from "react-apollo"
import { ApolloClient } from "apollo-client"
import { ApolloLink } from "apollo-link"
import { createUploadLink } from "apollo-upload-client"
import { InMemoryCache } from "apollo-cache-inmemory"
import { I18nextProvider } from "react-i18next"
import store from "store"
import HttpsRedirect from "react-https-redirect"

import { authLink, errorLink, queryOrMutationLink /*subscriptionLink, requestLink*/ } from "./utils/links"
import i18n, { updateMomentLocale } from "./utils/i18n"
import { AuthProvider, AuthConsumer } from "./context/AuthContext"
import { MuiThemeProvider } from "@material-ui/core/styles"
import indexRoutes from "./routes/index.jsx"
import { ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

import "./components/styles/basestyle.css"

import { UserContextProvider } from "./context/UserContext"
import { muiTheme } from "./theme/theme"
import { PermittedAssociationsContextProvider } from "./context/PermittedAssociationsContext"
import { config } from "./utils/config"

import "moment/locale/de-at"
import "moment/locale/en-gb"
import "moment/locale/sl"

window.React = React
window.ReactDOM = ReactDOM

function getParameterByName(name, url) {
  if (!url) url = window.location.href
  name = name.replace(/[[]]/g, "\\$&")
  var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
    results = regex.exec(url)
  if (!results) return null
  if (!results[2]) return ""
  return decodeURIComponent(results[2].replace(/\+/g, " "))
}

const GRAPHQL_ENDPOINT = config.REACT_APP_GRAPHQL_ENDPOINT || "http://localhost:5432/graphql"

// const SUBSCRIPTION_ENDPOINT = config.REACT_APP_GRAPHQL_SUBSCRIPTION_ENDPOINT || "ws://localhost:5432/subscriptions"

// Store token in session
if (getParameterByName("token")) {
  store.set("access_token", getParameterByName("token"))
}

const links = [
  authLink,
  errorLink,
  createUploadLink({ uri: GRAPHQL_ENDPOINT }),
  queryOrMutationLink(GRAPHQL_ENDPOINT),
  // requestLink({
  //     queryOrMutationLink: queryOrMutationLink(GRAPHQL_ENDPOINT),
  //     subscriptionLink: subscriptionLink(SUBSCRIPTION_ENDPOINT),
  // }),
]

updateMomentLocale(null)

export const client = new ApolloClient({
  link: ApolloLink.from(links),
  cache: new InMemoryCache({ addTypename: false }),
})

ReactDOM.render(
  <HttpsRedirect>
    <I18nextProvider i18n={i18n}>
      <ApolloProvider client={client}>
        <AuthProvider token={store.get("access_token")} client={client}>
          <UserContextProvider>
            <Router>
              <MuiThemeProvider theme={muiTheme}>
                <PermittedAssociationsContextProvider>
                  <ToastContainer />
                  <AuthConsumer>
                    {() => (
                      <Switch>
                        {indexRoutes.map((route, key) => {
                          if (route.render) {
                            return <Route path={route.path} component={undefined} render={route.render} key={key} />
                          } else if (route.component) {
                            return <Route path={route.path} component={route.component} key={key} />
                          }
                        })}
                      </Switch>
                    )}
                  </AuthConsumer>
                </PermittedAssociationsContextProvider>
              </MuiThemeProvider>
            </Router>
          </UserContextProvider>
        </AuthProvider>
      </ApolloProvider>
    </I18nextProvider>
  </HttpsRedirect>,
  document.getElementById("root"),
)
