import React, { FunctionComponent, useEffect, useMemo, useState } from "react"
import { FractionMappingDialog, IBaseFractionMappingDialogProps } from "./FractionMappingDialog"
import {
  DefaultFractionMappingForAssociationQueryResult,
  DefaultFractionMappingForAssociationQueryVariables,
  DEFAULT_FRACTION_MAPPING_FOR_ASSOCIATION,
} from "../../../api/graphql/query/default-fraction-mapping-for-association"
import { useLazyQuery } from "react-apollo"
import { FractionMappingInput } from "../../../api/graphql/generated/globalTypes"

interface IAssociationFractionMappingDialogProps extends IBaseFractionMappingDialogProps {
  associationId?: string
}

export const AssociationFractionMappingDialog: FunctionComponent<IAssociationFractionMappingDialogProps> = (props) => {
  const { associationId, open, ...rest } = props
  const [defaultFractionMappingFetched, setDefaultFractionMappingFetched] = useState<boolean>(false)

  const [loadDefaultFractionMapping, { data, loading }] = useLazyQuery<
    DefaultFractionMappingForAssociationQueryResult,
    DefaultFractionMappingForAssociationQueryVariables
  >(DEFAULT_FRACTION_MAPPING_FOR_ASSOCIATION)

  useEffect(() => {
    if (open && !defaultFractionMappingFetched) {
      loadDefaultFractionMapping({ variables: { id: associationId } })
      setDefaultFractionMappingFetched(true)
    } else if (!open) {
      setDefaultFractionMappingFetched(false)
    }
  }, [loadDefaultFractionMapping, open, defaultFractionMappingFetched, associationId])

  const fractionMapping = useMemo(() => data?.defaultFractionMappingForAssociation as FractionMappingInput[], [data])

  return (
    <FractionMappingDialog
      defaultFractionMapping={fractionMapping}
      defaultFractionMappingLoading={loading}
      open={open}
      {...rest}
    />
  )
}
