import React, { FunctionComponent } from "react"
import { Theme, makeStyles, Dialog, DialogTitle, DialogContent, Grid, DialogActions, Button } from "@material-ui/core"
import { useTranslation } from "react-i18next"
import { HousingManagementRfidCollection } from "./HousingManagementRfidCollection"

const useStyles = makeStyles((theme: Theme) => ({
  dialogRoot: {
    minHeight: "200px",
    minWidth: "360px",
  },
}))

interface IHousingManagementRfidDialogProps {
  isOpen: boolean
  setRfids: (rfids: string[]) => void
  rfids: string[]
  onCloseDialog: () => void
}

export const HousingManagementRfidDialog: FunctionComponent<IHousingManagementRfidDialogProps> = (props) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { isOpen, rfids, setRfids, onCloseDialog } = props

  return (
    <Dialog
      open={isOpen}
      fullWidth
      maxWidth="lg"
      className={classes.dialogRoot}
      classes={{ root: classes.dialogRoot, paper: classes.dialogRoot }}
      onClose={onCloseDialog}
    >
      <DialogTitle>{t("housing_management.rfids.title")}</DialogTitle>

      <DialogContent>
        <HousingManagementRfidCollection rfids={rfids} setRfids={setRfids} />
      </DialogContent>
      <DialogActions>
        <Grid container justify={"flex-end"}>
          <Button color="primary" variant={"contained"} onClick={onCloseDialog}>
            {t("housing_management.dialog.close.button")}
          </Button>
        </Grid>
      </DialogActions>
    </Dialog>
  )
}
