import lodash from "lodash"
import { ApolloError } from "apollo-boost"
import { GraphQLError } from "graphql"
import { WER_ERROR_CODE } from "../api/graphql/generated/globalTypes"
import { TFunction } from "i18next"

export const getGraphqlErrorCode = (error: ApolloError): number => {
  const errorCode = Number(error.graphQLErrors[0]?.extensions?.code)

  if (errorCode && !isNaN(errorCode)) {
    return errorCode
  }

  return error.graphQLErrors[0]?.extensions?.exception.code || -1
}

export function getGraphQLError(error: ApolloError | Error): GraphQLError {
  const fallbackError: GraphQLError = {
    name: error.name,
    message: error.message,
    extensions: { code: WER_ERROR_CODE.OTHER },
    locations: [],
    path: [],
    nodes: [],
    source: undefined,
    positions: [],
    originalError: error,
  }

  return lodash.get(error, "graphQLErrors[0]", fallbackError)
}

export function getWERErrorCode(error: ApolloError | Error): WER_ERROR_CODE {
  const graphQLError = getGraphQLError(error)
  return graphQLError.extensions?.code ?? WER_ERROR_CODE.OTHER
}

export function getGraphQLErrorLocalized(error: ApolloError | Error, t: TFunction): string {
  const errorCode = getWERErrorCode(error)
  return t(`WER_ERROR_CODE.${errorCode}`)
}
