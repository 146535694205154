import React, { FunctionComponent, Fragment } from "react"
import { Menu, MenuItem, IconButton } from "@material-ui/core"
import { useTranslation } from "react-i18next"
import lodash from "lodash"
import { EditorItemType } from "./EditorItem"
import { Add } from "@material-ui/icons"

interface IMenuSelection {
  anchor: HTMLElement
  index: number
}

interface IAddEditorItemButtonProps {
  index: number
  onAdd: (type: EditorItemType) => void
}

export const AddEditorItemButton: FunctionComponent<
  IAddEditorItemButtonProps
> = props => {
  const { t } = useTranslation()
  const [anchorEl, setAnchorEl] = React.useState<null | IMenuSelection>(null)
  const { index, onAdd } = props

  const onMenuClose = () => {
    setAnchorEl(null)
  }

  const onMenuOpen = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    index: number
  ) => {
    setAnchorEl({
      anchor: event.currentTarget,
      index
    })
  }

  const customOnAdd = (type: EditorItemType) => {
    onAdd(type)
    onMenuClose()
  }

  return (
    <Fragment>
      <IconButton size="small" onClick={event => onMenuOpen(event, index)}>
        <Add />
      </IconButton>
      <Menu
        anchorEl={lodash.get(anchorEl, "anchor", null)}
        keepMounted
        open={index === lodash.get(anchorEl, "index", -1)}
        onClose={onMenuClose}
      >
        <MenuItem onClick={() => customOnAdd(EditorItemType.FREETEXT)}>
          {t("text_management.freetext")}
        </MenuItem>
        <MenuItem onClick={() => customOnAdd(EditorItemType.PLACEHOLDER)}>
          {t("text_management.placeholder")}
        </MenuItem>
      </Menu>
    </Fragment>
  )
}
