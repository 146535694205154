import React, { FunctionComponent } from "react"
import { Theme, makeStyles, Paper } from "@material-ui/core"
import { useTranslation } from "react-i18next"
import { TopBar } from "../partials/heading/TopBar"
import { PDFFilterContextProvider } from "../../context/PDFFilterContext"
import { PDFFilterComponent } from "../partials/pdf/PDFFilterComponent"
import { AdvancementPDFSection } from "../partials/pdf/AdvancementPDFSection"

const useStyles = makeStyles((theme: Theme) => ({
  filterPaper: {
    marginTop: 0,
    padding: theme.spacing(2),
    justifyContent: "center",
    color: theme.palette.text.secondary,
    borderRadius: theme.spacing(0),
  },
  contentPaper: {
    margin: theme.spacing(2),
    padding: theme.spacing(2),
  },
}))

interface IPDFPageProps {}

export const PDFPage: FunctionComponent<IPDFPageProps> = (props) => {
  const classes = useStyles()
  const { t } = useTranslation()
  return (
    <PDFFilterContextProvider>
      <TopBar>{t("pdf_page.heading")}</TopBar>
      <Paper elevation={2} className={classes.filterPaper}>
        <PDFFilterComponent />
      </Paper>
      <Paper className={classes.contentPaper}>
        <AdvancementPDFSection />
      </Paper>
    </PDFFilterContextProvider>
  )
}
