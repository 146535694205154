import React, { FunctionComponent, useCallback, useContext, useEffect, useState } from "react"
import {
  Theme,
  makeStyles,
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  DialogActions,
  Button,
} from "@material-ui/core"
import { useTranslation } from "react-i18next"
import {
  UserAndPermissionsAssociation,
  UserAndPermissionsTown,
} from "../../../../api/graphql/query/permitted-associations-for-user"
import { PermittedAssociationsContext } from "../../../../context/PermittedAssociationsContext"
import { useLazyQuery } from "react-apollo"
import { COLORS } from "../../../../theme/theme"
import {
  rfidGroupsXLSXResult,
  rfidGroupsXLSXVariables,
  RFID_GROUPS_XLSX_FOR_TOWN_QUERY,
} from "../../../../api/graphql/query/rfid-group-export"
import { toast } from "react-toastify"
import { getGraphqlErrorCode, getWERErrorCode } from "../../../../utils/get-graphql-error-code"
import { ExportRfidGroupsError } from "../../../../api/graphql/generated/globalTypes"
import moment from "moment"

const useStyles = makeStyles((theme: Theme) => ({
  content: {
    width: 800,
  },
  loadingIndicator: {
    marginTop: theme.spacing(4),
  },
  checkIcon: {
    color: COLORS.lime,
    fontSize: 50,
    marginRight: theme.spacing(2),
  },
}))

interface IExportRfidGroupDialogProps {
  open: boolean
  onClose: () => void
}

export const ExportRfidGroupDialog: FunctionComponent<IExportRfidGroupDialogProps> = (props) => {
  const { open, onClose } = props
  const classes = useStyles()
  const { t } = useTranslation()

  const { permittedAssociations } = useContext(PermittedAssociationsContext)
  const [selectedAssociation, setSelectedAssociation] = useState<UserAndPermissionsAssociation | undefined>(
    permittedAssociations[0],
  )
  const [selectedTown, setSelectedTown] = useState<UserAndPermissionsTown | undefined>(
    permittedAssociations[0]?.towns[0] || undefined,
  )

  const [currentlyExportingTown, setCurrentlyExportingTown] = useState<UserAndPermissionsTown | undefined>()

  useEffect(() => {
    if (open) {
      setSelectedAssociation(permittedAssociations[0])
      setSelectedTown(permittedAssociations[0]?.towns[0])
    }
  }, [open, setSelectedAssociation, setSelectedTown, permittedAssociations])

  const handleChangeAssociationId = useCallback(
    (associationId: string) => {
      const association = permittedAssociations.find((association) => association.id === associationId)

      if (association) {
        setSelectedAssociation(association)
        setSelectedTown(association.towns[0])
      }
    },
    [permittedAssociations],
  )

  const downloadExportedExcel = (data: rfidGroupsXLSXResult) => {
    const base64 = data?.rfidGroupsXLSXForTown?.base64

    if (base64) {
      saveAs(
        new Blob([Buffer.from(base64, "base64")]),
        `${moment().format("YYYY-MM-DD")}_${t("rfid_groups.dialog.export.file_name")}_${currentlyExportingTown?.name}.xlsx`,
      )
    }
    onClose()
  }

  const [exportQuery, { loading }] = useLazyQuery<rfidGroupsXLSXResult, rfidGroupsXLSXVariables>(
    RFID_GROUPS_XLSX_FOR_TOWN_QUERY,
    {
      fetchPolicy: "no-cache",
      onCompleted: downloadExportedExcel,
      onError: (error) => {
        if (Object.values<string>(ExportRfidGroupsError).includes(getWERErrorCode(error))) {
          toast.error(t(`api_error.export_rfid_groups.${getWERErrorCode(error)}`))
        } else {
          toast.error(t(`api_error.${getGraphqlErrorCode(error)}`))
        }
      },
    },
  )

  const onSelectTown = useCallback(
    (
      event: React.ChangeEvent<{
        name?: string | undefined
        value: unknown
      }>,
    ) => {
      const town = selectedAssociation?.towns.find((town) => town.id === (event.target.value as string))
      setSelectedTown(town)
    },
    [selectedAssociation],
  )

  const onExportButtonClicked = () => {
    setCurrentlyExportingTown(selectedTown)
    if (selectedTown?.id) {
      exportQuery({
        variables: {
          townId: selectedTown.id,
        },
      })
    }
  }

  return (
    <Dialog maxWidth="xl" onClose={onClose} open={open}>
      <DialogTitle>{t("rfid_groups.dialog.export.title")}</DialogTitle>
      <DialogContent className={classes.content}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <FormControl fullWidth>
              <InputLabel id="inputlabel-association">{t("rfid_groups.dialog.export.association_label")}</InputLabel>
              <Select
                value={selectedAssociation?.id || ""}
                onChange={(event) => handleChangeAssociationId(event.target.value as string)}
                disabled={loading}
              >
                {permittedAssociations.map((association) => (
                  <MenuItem key={association.id} value={association.id}>
                    {association.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth>
              <InputLabel id="inputlabel-town">{t("rfid_groups.dialog.export.town_label")}</InputLabel>
              <Select value={selectedTown?.id} onChange={onSelectTown} disabled={loading}>
                {selectedAssociation?.towns.map((town) => (
                  <MenuItem key={town.id} value={town.id as string}>
                    {town.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button color="primary" variant={"contained"} onClick={onClose}>
          {t("rfid_groups.dialog.export.close")}
        </Button>
        <Button
          disabled={loading || !selectedTown?.id || !selectedAssociation?.id}
          color="primary"
          variant={"contained"}
          onClick={onExportButtonClicked}
        >
          {t("rfid_groups.dialog.export.export")}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
