import { gql } from "apollo-boost"
import {createAdvancementPDF, createAdvancementPDFVariables} from "../generated/createAdvancementPDF"

export const CREATE_ADVANCEMENT_PDF = gql`
  mutation createAdvancementPDF(
    $tourType: TourType!
    $townId: ID
    $associationId: ID
    $startDate: DateTime
    $endDate: DateTime
    $simplifyEvaluation: Boolean
    $splitPDFByAddress: Boolean
    $ratings: [Rating!]!
  ) {
    createAdvancementPDF(
      tourType: $tourType
      townId: $townId
      associationId: $associationId
      startDate: $startDate
      endDate: $endDate
      simplifyEvaluation: $simplifyEvaluation
      splitPDFByAddress: $splitPDFByAddress
      ratings: $ratings
    )
  }
`

export type CreateAdvancementPDFResult = createAdvancementPDF
export type CreateAdvancementPDFVariables = createAdvancementPDFVariables