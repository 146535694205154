import React, { FunctionComponent, Fragment, useMemo, useState, useCallback } from "react"
import { Theme, makeStyles, Paper, Typography, Grid, Button, useTheme } from "@material-ui/core"
import { useTranslation } from "react-i18next"
import { useQuery } from "react-apollo"
import {
  UnassignableAddressesQueryResult,
  UnassignableAddressesQueryVariables,
  UNASSIGNABLE_ADDRESSES_QUERY,
} from "../../../../api/graphql/query/unassignableAddressesForTown"
import { Warning } from "@material-ui/icons"
import { UnassignableAddressesDialog } from "./UnassignableAddressesDialog"
import { UserAndPermissionsTown } from "../../../../api/graphql/query/permitted-associations-for-user"

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    backgroundColor: theme.palette.primary.main,
    padding: theme.spacing(2),
    color: theme.palette.common.white,
    borderRadius: 0,
  },
  button: {
    color: theme.palette.common.white,
    borderColor: theme.palette.common.white,
  },
}))

interface IUnassignableAddressesBannerForTownProps {
  town: UserAndPermissionsTown
}

export const UnassignableAddressesBannerForTown: FunctionComponent<IUnassignableAddressesBannerForTownProps> = (
  props,
) => {
  const { town } = props
  const classes = useStyles()
  const { t } = useTranslation()
  const [dialogOpen, setDialogOpen] = useState<boolean>(false)

  const { data, refetch } = useQuery<UnassignableAddressesQueryResult, UnassignableAddressesQueryVariables>(
    UNASSIGNABLE_ADDRESSES_QUERY,
    {
      variables: { townId: town.id },
    },
  )

  const unassignableAddresses = useMemo(() => data?.unassignableAddresses || [], [data])
  const numUnassignableAddresses = useMemo(() => unassignableAddresses.length || 0, [unassignableAddresses])

  const customOnClose = useCallback(() => {
    setDialogOpen(false)
    refetch()
  }, [setDialogOpen, refetch])

  if (numUnassignableAddresses === 0) {
    return <></>
  }

  return (
    <>
      <UnassignableAddressesDialog
        open={dialogOpen}
        onClose={customOnClose}
        unassignableAddresses={unassignableAddresses}
        townName={town.name}
        townId={town.id}
        refetchUnassignableAddresses={refetch}
      />
      <Paper className={classes.paper}>
        <Grid container alignItems="center" spacing={2}>
          <Grid item>
            <Warning />
          </Grid>
          <Grid item xs>
            <Typography>{t("rfid_management.unassignable_addresses.info", { numUnassignableAddresses })}</Typography>
          </Grid>
          <Grid item>
            <Button variant="outlined" className={classes.button} onClick={() => setDialogOpen(true)}>
              {t("rfid_management.unassignable_addresses.resolve")}
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </>
  )
}
