import React, { FunctionComponent, useState, useEffect, ChangeEvent } from "react"
import {
  Theme,
  makeStyles,
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  TextField,
  DialogActions,
  Button,
  Fab,
  Divider,
  IconButton,
} from "@material-ui/core"
import { useTranslation } from "react-i18next"
import { CitizenForTown } from "../../../api/graphql/query/citizens-for-town"
import { useMutation } from "react-apollo"
import {
  UPDATE_CITIZEN_MUTATION,
  UpdateCitizenResult,
  UpdateCitizenVariables,
} from "../../../api/graphql/mutation/update-citizen"
import {
  DeleteCitizenResult,
  DELETE_CITIZEN_MUTATION,
  DeleteCitizenVariables,
} from "../../../api/graphql/mutation/delete-citizen"
import lodash from "lodash"
import { CitizenInput, CitizenCommunicationChannel } from "../../../api/graphql/generated/globalTypes"
import { DeleteForever, Delete, Add } from "@material-ui/icons"
import { getGraphqlErrorCode } from "../../../utils/get-graphql-error-code"
import { toast } from "react-toastify"
import { convertValueToTextfieldType } from "../../../utils/convert-value-to-textfield-type"

const useStyles = makeStyles((theme: Theme) => ({
  dialogRoot: {
    minHeight: "200px",
    minWidth: "360px",
  },
  textFieldContainer: {
    margin: 0,
  },
  preferredCommunicationChannel: {
    fontSize: 10,
    height: 12,
    width: 12,
    backgroundColor: theme.palette.primary.light,
    borderRadius: 90,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "white",
    fontWeight: 700,
    marginBottom: 10,
  },
  preferredCommunicationChannelText: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    marginLeft: 5,
  },
}))

interface IManageCitizenDialogProps {
  citizen: CitizenForTown
  isOpen: boolean
  onCloseDialog: () => void
  onSuccess: () => void
}

type CitizenForUpdate = Omit<CitizenForTown, "address" | "id" | "preferredCommunicationChannels">

export const ManageCitizenDialog: FunctionComponent<IManageCitizenDialogProps> = (props) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { isOpen, onCloseDialog, onSuccess, citizen } = props

  const [updateCitizenMutation, { loading: updateCitizenMutationLoading }] = useMutation<
    UpdateCitizenResult,
    UpdateCitizenVariables
  >(UPDATE_CITIZEN_MUTATION, {
    onError: (error) => {
      const code = getGraphqlErrorCode(error)
      toast.error(t(`api_error.${code}`))
      console.error("error: ", error, code)
    },
    onCompleted: (data) => {
      console.log("update success: ", data)
      onSuccess()
    },
  })

  const [deleteCitizenMutation, { loading: deleteCitizenMutationLoading }] = useMutation<
    DeleteCitizenResult,
    DeleteCitizenVariables
  >(DELETE_CITIZEN_MUTATION, {
    onError: (error) => {
      console.error("error: ", error)
    },
    onCompleted: (data) => {
      console.log("delete success: ", data)
      onSuccess()
    },
  })

  const citizenInput: CitizenForUpdate = {
    firstName: citizen.firstName,
    lastName: citizen.lastName,
    detailedAddress: {
      city: citizen.detailedAddress?.city,
      postalcode: citizen.detailedAddress?.postalcode,
      street: citizen.detailedAddress?.street,
      streetNumber: citizen.detailedAddress?.streetNumber,
    },
    rfids: citizen.rfids,
    phones: citizen.phones ?? [],
    email: citizen.email ?? null,
    deviceId: citizen.deviceId ?? "",
  }

  const [modifiedCitizen, setModifiedCitizen] = useState<CitizenForUpdate>(lodash.cloneDeep(citizenInput))
  const [isValid, setIsValid] = useState<boolean>(false)
  const [showDeleteForever, setShowDeleteForever] = useState<boolean>(false)

  useEffect(() => {
    const validateInput = () => {
      if (
        !modifiedCitizen.firstName ||
        !modifiedCitizen.lastName ||
        modifiedCitizen.rfids.length === 0 ||
        modifiedCitizen.rfids.some((rfid) => !rfid) ||
        !modifiedCitizen.detailedAddress?.city ||
        !modifiedCitizen.detailedAddress?.postalcode ||
        !modifiedCitizen.detailedAddress?.street ||
        !modifiedCitizen.detailedAddress?.streetNumber ||
        lodash.isNil(modifiedCitizen.phones) ||
        lodash.isEmpty(modifiedCitizen.phones)
      ) {
        setIsValid(false)
        return
      }

      setIsValid(true)
    }
    validateInput()
  }, [modifiedCitizen])

  const updateModifiedCitizen = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const tempModifiedCitizen: CitizenForUpdate = lodash.cloneDeep(modifiedCitizen)
    const { name, type, value } = event.target
    lodash.set(tempModifiedCitizen, name, convertValueToTextfieldType(value, type))
    setModifiedCitizen(tempModifiedCitizen)
  }

  const onUpdateCitizen = async () => {
    if (isValid) {
      const tempModifiedCitizen: CitizenForUpdate = lodash.cloneDeep(modifiedCitizen)
      const rfids = tempModifiedCitizen.rfids
      delete tempModifiedCitizen.rfids
      await updateCitizenMutation({
        variables: {
          id: citizen.id,
          citizen: {
            ...tempModifiedCitizen,
            deviceId: modifiedCitizen.deviceId || null,
            rfid: rfids.join(","),
          },
        },
      })
    }
  }

  const onDeleteCitizen = async () => {
    if (lodash.isString(citizen.id)) {
      await deleteCitizenMutation({
        variables: { id: citizen.id },
      })
    }
  }

  const removeRfid = (index: number) => () => {
    const tempModifiedCitizen: CitizenForUpdate = lodash.cloneDeep(modifiedCitizen)
    tempModifiedCitizen.rfids.splice(index, 1)
    setModifiedCitizen(tempModifiedCitizen)
  }

  const addRfid = () => {
    const tempModifiedCitizen: CitizenForUpdate = lodash.cloneDeep(modifiedCitizen)
    tempModifiedCitizen.rfids.push("")
    setModifiedCitizen(tempModifiedCitizen)
  }

  const preferredCommunicationChannelInput = (child: JSX.Element) => (
    <Grid container direction="row" alignItems="flex-end">
      <Grid item className={classes.preferredCommunicationChannel}>
        b
      </Grid>
      <Grid item xs className={classes.preferredCommunicationChannelText}>
        {child}
      </Grid>
    </Grid>
  )

  const phoneInput = (
    <TextField
      name="phones[0]"
      type="text"
      label={t("citizen.property.phone1")}
      value={modifiedCitizen.phones[0] || ""}
      onChange={updateModifiedCitizen}
      fullWidth
    />
  )

  const emailInput = (
    <TextField
      name="email"
      type="text"
      label={t("citizen.property.email")}
      value={modifiedCitizen.email || ""}
      onChange={updateModifiedCitizen}
      fullWidth
    />
  )

  return (
    <Dialog
      open={isOpen}
      fullWidth={false}
      className={classes.dialogRoot}
      classes={{ paper: classes.dialogRoot }}
      onClose={onCloseDialog}
    >
      <form style={{ display: "contents" }}>
        <DialogTitle>{t("citizen_management.dialog.update.title")}</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={6} className={classes.textFieldContainer}>
              <TextField
                name="firstName"
                label={t("citizen.property.firstName")}
                value={modifiedCitizen.firstName || ""}
                onChange={updateModifiedCitizen}
                fullWidth
              />
            </Grid>
            <Grid item xs={6} className={classes.textFieldContainer}>
              <TextField
                name="lastName"
                label={t("citizen.property.lastName")}
                value={modifiedCitizen.lastName || ""}
                onChange={updateModifiedCitizen}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={8} className={classes.textFieldContainer}>
              <TextField
                name="detailedAddress.street"
                type="text"
                label={t("detailed_address.property.street")}
                value={modifiedCitizen.detailedAddress?.street || ""}
                onChange={updateModifiedCitizen}
                fullWidth
              />
            </Grid>
            <Grid item xs={4} className={classes.textFieldContainer}>
              <TextField
                name="detailedAddress.streetNumber"
                type="text"
                label={t("detailed_address.property.street_number")}
                value={modifiedCitizen.detailedAddress?.streetNumber || ""}
                onChange={updateModifiedCitizen}
                fullWidth
              />
            </Grid>
            <Grid item xs={4} className={classes.textFieldContainer}>
              <TextField
                name="detailedAddress.postalcode"
                type="text"
                label={t("detailed_address.property.postalcode")}
                value={modifiedCitizen.detailedAddress?.postalcode || ""}
                onChange={updateModifiedCitizen}
                fullWidth
              />
            </Grid>
            <Grid item xs={8} className={classes.textFieldContainer}>
              <TextField
                name="detailedAddress.city"
                type="text"
                label={t("detailed_address.property.city")}
                value={modifiedCitizen.detailedAddress?.city || ""}
                onChange={updateModifiedCitizen}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={6} className={classes.textFieldContainer}>
              {citizen?.preferredCommunicationChannels?.includes(CitizenCommunicationChannel.phone)
                ? preferredCommunicationChannelInput(phoneInput)
                : phoneInput}
            </Grid>
            <Grid item xs={6} className={classes.textFieldContainer}>
              <TextField
                name="phones[1]"
                type="text"
                label={t("citizen.property.phone2")}
                value={modifiedCitizen.phones[1] || ""}
                onChange={updateModifiedCitizen}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} className={classes.textFieldContainer}>
              {citizen?.preferredCommunicationChannels?.includes(CitizenCommunicationChannel.email)
                ? preferredCommunicationChannelInput(emailInput)
                : emailInput}
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            {modifiedCitizen.rfids.map((rfid, index) => (
              <Grid item container xs={6} className={classes.textFieldContainer} alignItems="flex-end">
                <Grid item xs>
                  <TextField
                    name={`rfids[${index}]`}
                    type="text"
                    label={t("citizen.property.rfid", { number: index + 1 })}
                    value={rfid}
                    onChange={updateModifiedCitizen}
                    fullWidth
                  />
                </Grid>
                <Grid item>
                  <IconButton size="small" color="primary" disabled={modifiedCitizen.rfids.length === 1 && index === 0}>
                    <Delete onClick={removeRfid(index)} />
                  </IconButton>
                </Grid>
              </Grid>
            ))}
            <Grid item xs={6} container alignItems="center">
              <Fab size="small" color="primary">
                <Add onClick={addRfid} />
              </Fab>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          {showDeleteForever && (
            <Button
              color="primary"
              variant="contained"
              onClick={() => onDeleteCitizen()}
              disabled={!lodash.isString(lodash.get(citizen, "id")) || deleteCitizenMutationLoading}
            >
              {t("citizen_management.dialog.delete.button")}
            </Button>
          )}
          <Button
            color="primary"
            onClick={() => (showDeleteForever ? setShowDeleteForever(false) : onCloseDialog())}
            disabled={deleteCitizenMutationLoading || updateCitizenMutationLoading}
          >
            {t("general.cancel")}
          </Button>
          {!showDeleteForever && (
            <Button
              color="primary"
              variant="contained"
              onClick={() => onUpdateCitizen()}
              disabled={!isValid || updateCitizenMutationLoading}
            >
              {t("citizen_management.dialog.update.button")}
            </Button>
          )}
          {!showDeleteForever && (
            <Fab
              color="primary"
              size="small"
              onClick={() => setShowDeleteForever(true)}
              disabled={updateCitizenMutationLoading}
            >
              <DeleteForever />
            </Fab>
          )}
        </DialogActions>
      </form>
    </Dialog>
  )
}
