import React, { FunctionComponent, createContext, useContext, useEffect } from "react"
import { useLazyQuery } from "react-apollo"
import { CommunicationCenterContext } from "../../communication-center/CommunicationCenterContext"
import {
  ReportDetail,
  ReportDetailResult,
  ReportDetailVarialbes,
  REPORT_DETAILED_QUERY,
} from "../../../../api/graphql/query/report-detailed"
import { TextFilterContext } from "../../../../context/TextFilterContext"
import lodash from "lodash"

interface IReportDetailContext {
  loadingReportDetails: boolean
  refetchAllReportData: () => void
  refetchReportDetails: () => void
  reportDetail?: ReportDetail
}

export const ReportDetailContext = createContext<IReportDetailContext>({
  loadingReportDetails: false,
  refetchAllReportData: () => {},
  refetchReportDetails: () => {},
  reportDetail: undefined,
})

interface IReportDetailContextProviderProps {}

export const ReportDetailContextProvider: FunctionComponent<IReportDetailContextProviderProps> = (props) => {
  const { fetchReports, selectedReportId } = useContext(CommunicationCenterContext)

  const { associationId, townId, setTownId, setAssociation } = useContext(TextFilterContext)

  const refetchAllReportData = () => {
    fetchReports()
    refetchReportDetails()
  }

  const onReportDetailsQueryCompleted = () => {
    const newAssociationId = reportDetails?.report?.filter?.associationId
    const newTownId = reportDetails?.report?.filter?.townId

    if (!lodash.isNil(newAssociationId) && newAssociationId !== associationId) {
      setAssociation(newAssociationId)
    }

    if (!lodash.isNil(newTownId) && newTownId !== townId) {
      setTownId(newTownId)
    }

    if (lodash.isNil(newTownId)) {
      setTownId("all")
    }
  }

  const [
    reportDetailsQuery,
    { data: reportDetails, loading: loadingReportDetails, refetch: refetchReportDetails },
  ] = useLazyQuery<ReportDetailResult, ReportDetailVarialbes>(REPORT_DETAILED_QUERY, {
    onCompleted: onReportDetailsQueryCompleted,
    fetchPolicy: "no-cache",
    onError: (error) => console.log("error: ", error),
  })

  useEffect(() => {
    if (selectedReportId && selectedReportId !== reportDetails?.report?.id) {
      reportDetailsQuery({
        variables: {
          id: selectedReportId,
        },
      })
    }
  }, [reportDetails, reportDetailsQuery, selectedReportId])

  return (
    <ReportDetailContext.Provider
      value={{
        refetchAllReportData,
        refetchReportDetails,
        loadingReportDetails,
        reportDetail: reportDetails?.report || undefined,
      }}
    >
      {props.children}
    </ReportDetailContext.Provider>
  )
}
