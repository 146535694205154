import React, { FunctionComponent, Fragment, useState, useEffect } from "react"
import { Theme, makeStyles, Typography } from "@material-ui/core"
// import { useTranslation } from "react-i18next"
import WidgetHeader from "./WidgetHeader"
import { WidgetModificationType } from "../../../../domain/models/WidgetModels"
import { EditWidgetTextIndividualDialog } from "../dialogs/EditWidgetTextIndividualDialog"
import { TextTemplatesForCategory } from "../dialogs/TextTemplatesForCategory"
import lodash from "lodash"
import {
  DeleteWidgetResult,
  DeleteWidgetVariables,
  DELETE_WIDGET_MUTATION,
} from "../../../../api/graphql/mutation/delete-widget"
import { useMutation } from "react-apollo"
import { ApolloError } from "apollo-boost"
import { toast } from "react-toastify"
import { getGraphqlErrorCode } from "../../../../utils/get-graphql-error-code"
import { useTranslation } from "react-i18next"

const useStyles = makeStyles((theme: Theme) => ({
  content: {
    padding: theme.spacing(2),
  },
  textHeading: {
    fontSize: 16,
  },
  textContainer: {},
  textField: {
    height: 40,
    marginTop: "16px",
    marginBottom: "8px",
  },
  title: {
    marginBottom: theme.spacing(0.5),
    overflowWrap: "break-word",
  },
}))

interface IWidgetTextIndividualProps {
  widgetModel: any
  onModification: Function
  onExportCreated: (fileName: string, uri: string, extension: string, payload: any) => void
}

export const WidgetTextIndividual: FunctionComponent<IWidgetTextIndividualProps> = (props) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { widgetModel, onModification, onExportCreated } = props
  const [editDialogOpen, setEditDialogOpen] = useState<boolean>(
    (props.widgetModel.individualTextTemplateIds || []).length === 0,
  )

  const handleWidgetMutationError = (error: ApolloError) => {
    toast.error(t(`api_error.${getGraphqlErrorCode(error)}`))
    props.onModification && props.onModification(WidgetModificationType.EDIT, props.widgetModel)
    setEditDialogOpen(false)
  }

  const handleWidgetMutationComplete = (modificationType: WidgetModificationType) => {
    props.onModification && props.onModification(modificationType, props.widgetModel)
    setEditDialogOpen(false)
  }

  const [deleteWidgetMutation] = useMutation<DeleteWidgetResult, DeleteWidgetVariables>(DELETE_WIDGET_MUTATION, {
    onError: (error) => {
      handleWidgetMutationError(error)
    },
    onCompleted: () => {
      handleWidgetMutationComplete(WidgetModificationType.DELETE)
    },
  })

  const handleModification = (type: any, widgetModel: any) => {
    if (type === WidgetModificationType.DELETE) {
      onModification(type, widgetModel)
    } else if (type === WidgetModificationType.EDIT) {
      onModification(type, widgetModel)
      setEditDialogOpen(true)
    }
  }

  useEffect(() => {
    if (widgetModel.widgetTextCategory && widgetModel.individualTextTemplateIds) {
      onExportCreated("DUMMY_FILENAME", "DUMMY_URI", "DUMMY_EXTENSION", "DUMMY_PAYLOAD")
    }
    // eslint-disable-next-line
  }, [widgetModel.widgetTextCategory, widgetModel.individualTextTemplateIds])

  const onClose = (shouldDelete: boolean) => {
    if ((props.widgetModel.individualTextTemplateIds || []).length === 0 && shouldDelete) {
      deleteWidgetMutation({
        variables: {
          id: props.widgetModel.id,
        },
      }).then()
    } else {
      setEditDialogOpen(false)
    }
  }

  return (
    <Fragment>
      {editDialogOpen && (
        <EditWidgetTextIndividualDialog
          open={editDialogOpen}
          onClose={onClose}
          onModification={() => {
            onModification(WidgetModificationType.EDIT, widgetModel)
          }}
          widgetModel={widgetModel}
        />
      )}
      <WidgetHeader widgetModel={widgetModel} onModification={handleModification} />
      <div className={classes.content}>
        <Typography variant={"h5"} className={classes.title}>
          {
            <TextTemplatesForCategory
              selectedCategory={widgetModel.widgetTextCategory}
              textTemplateIds={widgetModel.individualTextTemplateIds}
              onChangedTextTemplateSelection={null}
              associationId={widgetModel?.filter?.associationId}
              townId={(widgetModel.filter?.townIds || []).length === 1 ? widgetModel.filter.townIds[0] : null}
            />
          }
        </Typography>
      </div>
    </Fragment>
  )
}
