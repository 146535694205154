import React, { FunctionComponent, Fragment, useContext, useState } from "react"
import { Theme, makeStyles, DialogActions, Button, Fab } from "@material-ui/core"
import { useTranslation } from "react-i18next"
import { useMutation } from "react-apollo"
import {
  DeleteUserResult,
  DeleteUserVariables,
  DELETE_USER_MUTATION,
} from "../../../../api/graphql/mutation/delete-user"
import { ManageUserContext } from "./context/ManageUserContext"
import { ClipLoader } from "react-spinners"
import { DeleteForever } from "@material-ui/icons"

const useStyles = makeStyles((theme: Theme) => ({}))

interface IManageUserDialogActionsProps {
  onCloseDialog: () => void
}

export const ManageUserDialogActions: FunctionComponent<IManageUserDialogActionsProps> = (props) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const { onCloseDialog } = props

  const {
    userId,
    isInEditMode,
    isInCreateMode,
    onSuccess,
    isValid,
    createUserMutationLoading,
    updateUserMutationLoading,
    onCreateUserAndPermissions,
    onUpdateUserAndPermissions,
  } = useContext(ManageUserContext)

  const [showDeleteForever, setShowDeleteForever] = useState<boolean>(false)

  const [deleteUserMutation, { loading: deleteUserMutationLoading }] = useMutation<
    DeleteUserResult,
    DeleteUserVariables
  >(DELETE_USER_MUTATION, {
    onError: (error) => {
      console.log("error: ", error)
    },
    onCompleted: (data) => {
      console.log("delete success: ", data)
      onSuccess()
    },
  })

  const onDeleteUserAndPermissions = async () => {
    await deleteUserMutation({
      variables: { id: userId },
    })
  }

  return (
    <DialogActions>
      {isInEditMode && showDeleteForever && (
        <Button color="primary" variant={"contained"} onClick={() => onDeleteUserAndPermissions()} disabled={!userId}>
          {t("user_management.dialog.delete.button")}
        </Button>
      )}
      <Button
        color="primary"
        onClick={() => (showDeleteForever ? setShowDeleteForever(false) : onCloseDialog())}
        disabled={deleteUserMutationLoading || createUserMutationLoading || updateUserMutationLoading}
      >
        {t("general.cancel")}
      </Button>
      {isInCreateMode && (
        <Button
          color="primary"
          variant={"contained"}
          onClick={() => onCreateUserAndPermissions()}
          disabled={!isValid || createUserMutationLoading}
        >
          {createUserMutationLoading ? (
            <ClipLoader color={"white"} size={17} />
          ) : (
            t("user_management.dialog.create.button")
          )}
        </Button>
      )}
      {isInEditMode && !showDeleteForever && (
        <Button
          color="primary"
          variant={"contained"}
          onClick={() => onUpdateUserAndPermissions()}
          disabled={!isValid || updateUserMutationLoading}
        >
          {t("user_management.dialog.update.button")}
        </Button>
      )}
      {isInEditMode && !showDeleteForever && (
        <Fab
          color={"primary"}
          size={"small"}
          onClick={() => setShowDeleteForever(true)}
          disabled={updateUserMutationLoading}
        >
          <DeleteForever />
        </Fab>
      )}
    </DialogActions>
  )
}
