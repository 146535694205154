import { gql } from "apollo-boost"
import { userLanguage } from "../generated/userLanguage"

export const USER_LANGUAGE_QUERY = gql`
  query userLanguage {
    userLanguage
  }
`

export type UserLanguageResult = userLanguage
