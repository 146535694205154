import { gql } from "apollo-boost"

export const STATISTICS_QUERY = gql`
  query Statistics($filter: StatisticsFilter!) {
    statistics(filter: $filter) {
      overall {
        total {
          value
          percent
        }
        invalid {
          value
          percent
        }
        valid {
          value
          percent
        }
      }
      daily {
        total
        invalid
        valid
        date
        endDate
      }
      weekly {
        total
        invalid
        valid
        date
        endDate
      }
      monthly {
        total
        invalid
        valid
        date
        endDate
      }

      materialAnalysis(select: INVALID) {
        id
        invalidity {
          invalidReason
          invalidType
          isInvalid
        }
        metadata {
          recordedAt
          wasteScannerId
          file
        }
        documents {
          documentType
          url
          title
        }
      }
    }
  }
`
