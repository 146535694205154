import React, { FunctionComponent } from "react"
import { Theme, makeStyles, Grid } from "@material-ui/core"
import { useTranslation } from "react-i18next"
import { TopBar } from "../partials/heading/TopBar"
import { FileTemplatePageContent } from "../partials/file-template/FileTemplatePageContent"
import { FileTemplateContextProvider } from "../partials/file-template/context/FileTemplageContext"

const useStyles = makeStyles((theme: Theme) => ({
  content: {},
}))

interface IFileTemplatePageProps {}

export const FileTemplatePage: FunctionComponent<IFileTemplatePageProps> = (props) => {
  const classes = useStyles()
  const { t } = useTranslation()
  return (
    <FileTemplateContextProvider>
      <TopBar>{t("file_template_page.heading")}</TopBar>
      <FileTemplatePageContent />
    </FileTemplateContextProvider>
  )
}
