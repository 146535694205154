import { gql } from "apollo-boost"
import { edaQueues, edaQueues_edaQueues, edaQueues_edaQueues_attributes } from "../generated/edaQueues"

export const EDA_QUEUES_QUERY = gql`
  query edaQueues {
    edaQueues {
      queueName
      attributes {
        approximateNumberOfMessages
        approximateNumberOfMessagesDelayed
        approximateNumberOfMessagesNotVisible
        createdTimestamp
        delaySeconds
        lastModifiedTimestamp
        maximumMessageSize
        messageRetentionPeriod
        receiveMessageWaitTimeSeconds
        visibilityTimeout
        queueArn
      }
    }
  }
`

export type EdaQueuesQueryResult = edaQueues
export type EdaQueue = edaQueues_edaQueues
export type EdaQueueAttributes = edaQueues_edaQueues_attributes
