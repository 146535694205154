import { DailyGroupedStatsForAnalysis } from "../../../api/graphql/query/daily-grouped-stats-for-analysis"
import { findLineByLeastSquares } from "../../../utils/linear-regression"
import moment from "moment"

export const OVERALL_DATA_KEY = "overall"
export const TREND_DATA_KEY = "trend"
export const TOWN_DATA_KEY = "town"

interface ITrendLineCoordinates {
  date: string
  [TREND_DATA_KEY]: number
}

export const getValuesForTrendLine = (
  dailyGroupedStatsForAnalysis: DailyGroupedStatsForAnalysis[],
): ITrendLineCoordinates[] => {
  // we filter all null values, so we dont end up with invalid data on the chart
  const dailyGroupedStatsNotNull = dailyGroupedStatsForAnalysis.filter(
    (dailyGroupedStats) => dailyGroupedStats.overall !== null,
  )

  // using linear regression we get all the values we need to draw the trendline
  const linearRegression = findLineByLeastSquares(
    dailyGroupedStatsNotNull.map((dailyGrouped) => moment(dailyGrouped.date).unix()),
    dailyGroupedStatsNotNull.map((dailyGrouped) => dailyGrouped.overall),
  )
  const dataPoints = []
  for (let i = 0; i < linearRegression.x.length; i++) {
    dataPoints.push({
      date: moment.unix(linearRegression.x[i]).toISOString(),
      trend: linearRegression.y[i] < 1 ? linearRegression.y[i] : 1,
    })
  }

  return dataPoints
}
