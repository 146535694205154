import React, { useCallback, useState } from "react"
import {
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  makeStyles,
  Theme,
  ListItemIcon,
  TextField,
  darken,
  lighten,
} from "@material-ui/core"
import { Add } from "@material-ui/icons"
import {
  ValidateRfidGroupRfidQueryResult,
  ValidateRfidGroupRfidQueryVariables,
  VALIDATE_RFID_GROUP_RFID_QUERY,
} from "../../../api/graphql/query/rfid-group-validate-rfid"
import { ValidationSign } from "./ValidationSign"
import { useLazyQuery } from "@apollo/react-hooks"
import { useTranslation } from "react-i18next"

const useStyles = makeStyles((theme: Theme) => ({
  rfidList: {
    flexGrow: 1,
  },
  rfidListValidationIcon: {
    minWidth: 24,
    paddingRight: theme.spacing(1),
  },
  rfidListItem: {
    paddingLeft: 0,
  },
  addButton: {
    backgroundColor: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: darken(theme.palette.primary.main, 0.3),
    },
    "&:disabled": {
      backgroundColor: lighten(theme.palette.primary.main, 0.5),
    },
  },
  addIcon: {
    color: theme.palette.common.white,
  },
}))

export interface IRfidGroupRfidCollectionNewItemProps {
  addRfidToGroup: (rfid: string) => void
  existingRfids: string[]
  rfidGroupId?: string
}

export const RfidGroupRfidCollectionNewItem: React.FunctionComponent<IRfidGroupRfidCollectionNewItemProps> = (
  props,
) => {
  const { addRfidToGroup, existingRfids } = props
  const classes = useStyles()
  const { t } = useTranslation()
  const [rfid, setRfid] = useState<string>("")
  const [isValid, setIsValid] = useState<boolean>(false)

  const [validateRfid, { loading: validateRfidLoading, data: rfidValidationResult }] = useLazyQuery<
    ValidateRfidGroupRfidQueryResult,
    ValidateRfidGroupRfidQueryVariables
  >(VALIDATE_RFID_GROUP_RFID_QUERY, {
    onCompleted: () => handleRfidValidation(),
    fetchPolicy: "no-cache",
  })

  const handleRfid = useCallback(
    (rfidValue: string) => {
      if (rfidValue.length <= 0) {
        setIsValid(false)
      } else {
        validateRfid({
          variables: {
            rfid: rfidValue,
          },
        })
      }
      setRfid(rfidValue)
    },
    [setIsValid, validateRfid],
  )

  const addRfid = useCallback(() => {
    if (isValid) {
      addRfidToGroup(rfid)
      setRfid("")
      setIsValid(false)
    }
  }, [isValid, addRfidToGroup, setRfid, setIsValid, rfid])

  const handleRfidValidation = useCallback(() => {
    if (rfidValidationResult?.validateRfidForGroup && !existingRfids.includes(rfid) && rfid.length > 0) {
      setIsValid(true)
    } else {
      setIsValid(false)
    }
  }, [rfidValidationResult, existingRfids, rfid, setIsValid])

  return (
    <ListItem key={"new-rfid"} classes={{ gutters: classes.rfidListItem }}>
      <ListItemIcon
        classes={{
          root: classes.rfidListValidationIcon,
        }}
      >
        <ValidationSign isValid={isValid} />
      </ListItemIcon>
      <ListItemText>
        <TextField
          label={t("rfid_groups.dialog.create.rfid_field")}
          value={rfid}
          onChange={(event) => handleRfid(event.target.value)}
          fullWidth
          variant="outlined"
        />
      </ListItemText>
      <ListItemSecondaryAction>
        <IconButton
          color="primary"
          onClick={addRfid}
          size="small"
          edge="end"
          aria-label="add"
          className={classes.addButton}
          disabled={!isValid}
        >
          <Add className={classes.addIcon} />
        </IconButton>
      </ListItemSecondaryAction>
    </ListItem>
  )
}
