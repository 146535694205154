import { gql } from "apollo-boost"
import { rfidGroups, rfidGroupsVariables, rfidGroups_rfidGroups } from "../generated/rfidGroups"

export const RFID_GROUPS_QUERY = gql`
  query rfidGroups($townId: ID) {
    rfidGroups(townId: $townId) {
      _id
      rfids
      address {
        street
        streetNumber
        city
        postalcode
      }
    }
  }
`
export type RfidGroupsQueryResult = rfidGroups
export type RfidGroup = rfidGroups_rfidGroups
export type RfidGroupsQueryVariables = rfidGroupsVariables
