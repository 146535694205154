import React, { FunctionComponent } from "react"
import { Theme, makeStyles, Dialog, DialogTitle, DialogContent, DialogActions, Button } from "@material-ui/core"
import { useTranslation } from "react-i18next"
import { UnassignableAddress } from "../../../../api/graphql/query/unassignableAddressesForTown"
import { UnassignableAddressesTable } from "./UnassignableAddressesTable"
import { UnassignableAddressesExportButton } from "./UnassignableAddressesExportButton"

const useStyles = makeStyles((theme: Theme) => ({
  loadingIndicator: {
    marginTop: theme.spacing(4),
  },
  checkIcon: {
    fontSize: 50,
    marginRight: theme.spacing(2),
  },
}))

interface IUnassignableAddressesDialogProps {
  open: boolean
  onClose: () => void
  unassignableAddresses: UnassignableAddress[]
  townName: string
  townId?: string
  refetchUnassignableAddresses: () => void
}

export const UnassignableAddressesDialog: FunctionComponent<IUnassignableAddressesDialogProps> = (props) => {
  const { open, onClose, unassignableAddresses, townName, refetchUnassignableAddresses, townId } = props
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <Dialog maxWidth="md" onClose={onClose} open={open} fullWidth>
      <DialogTitle>
        {t("rfid_management.unassignable_addresses.dialog.title")} - {townName}
      </DialogTitle>
      <DialogContent>
        {townId && <UnassignableAddressesExportButton onClose={onClose} townName={townName} townId={townId} />}
        <UnassignableAddressesTable
          unassignableAddresses={unassignableAddresses}
          refetchUnassignableAddresses={refetchUnassignableAddresses}
        />
      </DialogContent>
      <DialogActions>
        <Button color="primary" variant={"contained"} onClick={onClose}>
          {t("general.close")}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
