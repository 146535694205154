import React, { FunctionComponent, useMemo } from "react"
import { useTranslation } from "react-i18next"
import { DailyGroupedStatsComparisonForAnalysis } from "../../../api/graphql/query/daily-grouped-stats-comparison-for-analysis"
import { TourTypeInfo } from "../../../domain/models/TourTypes"
import {
  ResponsiveContainer,
  ComposedChart,
  XAxis,
  CartesianGrid,
  Scatter,
  YAxis,
  Label,
  Line,
  Tooltip,
  Legend,
} from "recharts"
import { tickFormat, TimeLevel } from "./tick-format"
import { getValuesForTrendLine, TREND_DATA_KEY, TOWN_DATA_KEY, OVERALL_DATA_KEY } from "./trend-line"
import moment from "moment"
import lodash from "lodash"
import { WertstoffAnalysisTrendComparisonGraphTooltip } from "./WerstoffAnalysisTrendComparisonGraphTooltip"
import { trimDailyGroupedAnaylsis, trimDailyGroupedAnaylsisWithBoundaries } from "./trend-graph-helpers"
import { WertstoffAnalysisTrendComparisonGraphLegend } from "./WertstoffAnalysisTrendComparisonGraphLegend"
import { StarRate, PlayArrow, Add } from "@material-ui/icons"
import { COLORS } from "../../../theme/theme"
import { sortASC } from "../../../utils/sort"

interface ShapeDictEntry {
  name: "triangle" | "cross" | "star"
  icon: JSX.Element
  color: string
}

export const TrendComparisonGraphShapeDict: { [key: number]: ShapeDictEntry } = {
  0: {
    name: "triangle",
    icon: <PlayArrow style={{ color: COLORS.turquoise, transform: "rotate(-90deg)" }} />,
    color: COLORS.turquoise,
  },
  1: { name: "cross", icon: <Add style={{ color: COLORS.orange }} />, color: COLORS.orange },
  2: { name: "star", icon: <StarRate style={{ color: COLORS.pink }} />, color: COLORS.pink },
}

interface IWertstoffAnalysisTrendComparisonGraphProps {
  dailyGroupedStatsComparisonForAnalysis: DailyGroupedStatsComparisonForAnalysis
  tourTypeInfo: TourTypeInfo | undefined | null
  chartRef?: any
  onChartIsDrawn?: () => void
  legend?: boolean
}

export const WertstoffAnalysisTrendComparisonGraph: FunctionComponent<IWertstoffAnalysisTrendComparisonGraphProps> = (
  props,
) => {
  const { t } = useTranslation()
  const { tourTypeInfo, chartRef, onChartIsDrawn, legend, dailyGroupedStatsComparisonForAnalysis } = props

  const { townStats, overallStats } = dailyGroupedStatsComparisonForAnalysis
  const townStatsOrdered = townStats.sort((a, b) => sortASC(a.town.name, b.town.name))

  const dataPointsForTrendLine = useMemo(() => {
    return getValuesForTrendLine(overallStats)
  }, [overallStats])

  const overallStatsTrimmed = useMemo(() => {
    return trimDailyGroupedAnaylsis(overallStats)
  }, [overallStats])

  const townStatsTrimmed = useMemo(() => {
    return townStatsOrdered.map((townStat) => {
      return {
        town: townStat.town,
        // we need to trim this with the overall boundaries, bc recharts does weird things if the stats array isnt null
        stats: trimDailyGroupedAnaylsisWithBoundaries(townStat.stats, overallStats).map((stats) => {
          return {
            ...stats,
            // we need to add the town in here, so we can access it in the tooltip
            town: townStat.town,
          }
        }),
      }
    })
  }, [townStatsOrdered, overallStats])

  const townScatters = useMemo(
    () =>
      townStatsTrimmed.map((townStat, index) => {
        return (
          <Scatter
            key={townStat.town.name}
            dataKey={OVERALL_DATA_KEY}
            name={TOWN_DATA_KEY}
            data={townStat.stats}
            fill={TrendComparisonGraphShapeDict[index].color}
            shape={TrendComparisonGraphShapeDict[index].name}
            strokeWidth={2}
          />
        )
      }),
    [townStatsTrimmed],
  )

  const minMaxScatters = useMemo(
    () =>
      overallStatsTrimmed.map((dailyGrouped) => (
        <Scatter
          key={dailyGrouped.date}
          dataKey="value"
          name="minmax"
          data={[
            { date: dailyGrouped.date, value: dailyGrouped.min?.value },
            { date: dailyGrouped.date, value: dailyGrouped.max?.value },
          ]}
          fill={tourTypeInfo?.fraction.color}
          line
          shapeRendering="geometricPrecision"
          shape="square"
          strokeWidth={2}
          onAnimationEnd={dataPointsForTrendLine.length === 1 && onChartIsDrawn ? onChartIsDrawn : undefined}
        />
      )),
    [overallStatsTrimmed, tourTypeInfo, dataPointsForTrendLine, onChartIsDrawn],
  )
  return (
    <ResponsiveContainer width={legend ? "90%" : "95%"} height={250} ref={chartRef}>
      <ComposedChart data={overallStatsTrimmed} margin={{ top: 10, right: 50, left: 20, bottom: 5 }}>
        <XAxis
          dataKey="date"
          tickFormatter={(date) => tickFormat(date, TimeLevel.DAY)}
          allowDuplicatedCategory={false}
        />
        <YAxis tickFormatter={(value) => value * 100}>
          <Label
            value={`${t("graph.percent")}`}
            angle={-90}
            offset={0}
            position={"insideLeft"}
            style={{ textAnchor: "middle" }}
          />
        </YAxis>
        <CartesianGrid />

        {minMaxScatters}
        <Scatter
          dataKey={OVERALL_DATA_KEY}
          name={OVERALL_DATA_KEY}
          data={overallStatsTrimmed}
          fill={tourTypeInfo?.fraction.color}
          shapeRendering="geometricPrecision"
          shape="circle"
          strokeWidth={2}
          onAnimationEnd={dataPointsForTrendLine.length === 1 && onChartIsDrawn ? onChartIsDrawn : undefined}
        />
        {dataPointsForTrendLine.length > 1 && (
          <Line
            dot={false}
            strokeWidth={2}
            strokeDasharray="8 8"
            dataKey={TREND_DATA_KEY}
            data={dataPointsForTrendLine}
            stroke={tourTypeInfo?.fraction.color}
            onAnimationEnd={onChartIsDrawn && onChartIsDrawn}
          />
        )}

        {townScatters}
        <Tooltip
          content={(props: any) => (
            <WertstoffAnalysisTrendComparisonGraphTooltip
              {...props}
              color={tourTypeInfo?.fraction.color}
              showTrend={dataPointsForTrendLine.length > 1}
            />
          )}
          // className={classes.tooltip}
          labelFormatter={(timeStr) => moment(timeStr).format("DD MMM YYYY")}
          formatter={(value, name, props) => `${(Number(value) * 100).toFixed(0)} %`}
          itemSorter={(a, b) => {
            return lodash.get(a, "value", -1) > lodash.get(b, "value", -1) ? -1 : 1
          }}
        />

        {!!legend && (
          <Legend
            layout={"vertical"}
            verticalAlign={"middle"}
            align={"right"}
            iconType={"circle"}
            iconSize={10}
            content={() => (
              <WertstoffAnalysisTrendComparisonGraphLegend
                color={tourTypeInfo?.fraction.color}
                showTrend={dataPointsForTrendLine.length > 1}
                towns={townStatsOrdered.map((townStat) => townStat.town)}
              />
            )}
            wrapperStyle={{ right: "5px", top: "10px" }}
          />
        )}
      </ComposedChart>
    </ResponsiveContainer>
  )
}
