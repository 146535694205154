import React, { FunctionComponent } from "react"
import lodash from "lodash"
import formatDate from "../../../utils/format-date"
import { basicFractionArray } from "../../../constants/Constants"
import Typography from "@material-ui/core/Typography"
import { useTranslation } from "react-i18next"
import { nameOfEmptying } from "../../../utils/emptying-helper"
import { getLocalizeableRating } from "../../../domain/helper/CalcRating"
import { Grid, Theme, makeStyles } from "@material-ui/core"
import { ReportDetailFilter } from "../../../api/graphql/query/report-detailed"

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    margin: 0,
  },
}))

interface IFilterHeadingProps {
  filter: ReportDetailFilter | null
}

export const FilterHeading: FunctionComponent<IFilterHeadingProps> = (props) => {
  const { filter } = props
  const classes = useStyles()
  const { t } = useTranslation()

  if (lodash.isNil(filter)) {
    console.log("Error: no Filter")
    return null
  }

  const headerProps: any[any] = []
  headerProps[t("analysis_page.filter_tourtype_name")] = t(`analysis_page.filter_tourtype_${filter.tourType}`)
  headerProps[t("analysis_page.filter.association")] = filter.association?.name || ""
  headerProps[t("analysis_page.filter.town")] = (filter.towns || []).map((town) => town.name).join(", ") || ""
  headerProps[t("analysis_page.filter.empty_week")] =
    filter.emptyingWeekNumber && filter.emptyingYearNumber
      ? nameOfEmptying(
          {
            weekNumber: filter.emptyingWeekNumber,
            yearNumber: filter.emptyingYearNumber,
          },
          t,
        )
      : null
  headerProps[t("analysis_page.filter.waste_type")] = filter?.fractionName || "--"
  if (filter.fractionFrom) {
    headerProps[t("analysis_page.filter.percent_of")] = `${filter.fractionFrom * 100} %`
  }
  if (filter.fractionTo) {
    headerProps[t("analysis_page.filter.percent_to")] = `${filter.fractionTo * 100} %`
  }
  headerProps[t("analysis_page.filter.rating")] = filter.rating ? t(getLocalizeableRating(filter.rating)) : "--"

  switch (filter.displayOnlyAutomaticAnalysis) {
    case true:
      headerProps[t("analysis_page.filter.classification")] = t("analysis_page.filter_classification_auto")
      break
    case false:
      headerProps[t("analysis_page.filter.classification")] = t("analysis_page.filter_classification_manually")
      break
    default:
      headerProps[t("analysis_page.filter.classification")] = t("analysis_page.filter_classification_default")
      break
  }

  headerProps[t("analysis_page.filter.from")] = formatDate(filter.dateFrom)
  headerProps[t("analysis_page.filter.to")] = formatDate(filter.dateUntil)

  if (filter.rfids?.length) {
    headerProps[t("analysis_page.filter.rfids")] = filter.rfids.join(", ")
  }

  const headers = []
  for (let heading in headerProps) {
    if (headerProps[heading]) {
      headers.push(
        <Grid item key={heading}>
          <Grid container direction={"column"}>
            <Grid item>
              <Typography variant={"body1"}>{heading}</Typography>
            </Grid>
            <Grid item>
              <Typography variant={"h6"}>{headerProps[heading]}</Typography>
            </Grid>
          </Grid>
        </Grid>,
      )
    }
  }

  return (
    <Grid container direction={"row"} justify={"flex-start"} spacing={2} className={classes.container}>
      {headers}
    </Grid>
  )
}
