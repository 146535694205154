// validateTours
import { gql } from "apollo-boost"
import { vehicles_me_vehicles, vehicles, vehicles_me_vehicles_imageUsage } from "../generated/vehicles"

export const VEHICLES_QUERY = gql`
  query vehicles {
    me {
      vehicles {
        id
        rfidReader
        wasteScannerId
        geoCeptImei
        tourCount
        rfidReadingToMaterialAnalysisOffset {
          min
          max
        }
        geoCeptToMaterialAnalysisOffset {
          min
          max
        }
        imageLimit
        imageUsage {
          available
          used
          usedPercent
        }
      }
    }
  }
`

export type VehiclesResult = vehicles
export type Vehicle = vehicles_me_vehicles
export type ImageUsage = vehicles_me_vehicles_imageUsage
