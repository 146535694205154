import React, { FunctionComponent, Fragment, useContext, useMemo, useEffect } from "react"
import { Theme, makeStyles, Card, CardHeader, Grid, Avatar, CardContent } from "@material-ui/core"
import { useTranslation } from "react-i18next"
import { RootGrid } from "../partials/layout/RootGrid"
import { Subway, Bookmark as BookmarkIcon, LocalShippingRounded } from "@material-ui/icons"
import { BookmarkSection } from "../partials/dashboard/BookmarkSection"
import moment from "moment"
import { useLazyQuery } from "react-apollo"
import { ToursResult, TOURS_QUERY, Tour, ToursVariables } from "../../api/graphql/query/tours"
import { AnalysisFilterContext } from "../../context/AnalysisFilterContext"
import { Bookmark } from "../../api/graphql/query/bookmarks-for-user"
import { useHistory } from "react-router"
import { VehicleSection } from "../partials/dashboard/VehicleSection"
import { TourSection } from "../partials/dashboard/TourSection"
import { forceUTC } from "../../domain/helper/ForceUTC"
import { COLORS } from "../../theme/theme"
import { PermittedAssociationsContext } from "../../context/PermittedAssociationsContext"
import { AuthContext } from "../../context/AuthContext"

const useStyles = makeStyles((theme: Theme) => ({
  fullHeightRoot: {
    height: "100vh",
  },
  fullHeight: {
    height: "100%",
  },
  dashboardItem: {
    height: "50%",
  },
  cardHeading: {
    fontSize: 18,
    color: theme.palette.secondary.dark,
  },
  cardContent: {
    overflow: "auto",
    height: "calc(100% - 111px)",
  },
  tourAvatar: {
    background: COLORS.turquoise,
  },
  bookmarkAvatar: {
    background: COLORS.orange,
  },
  scannerAvatar: {
    background: COLORS.lime,
  },
  dataAvatar: {
    background: COLORS.pink,
  },
}))

interface IDashboardPageProps {}

export const DashboardPage: FunctionComponent<IDashboardPageProps> = (props) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const history = useHistory()
  const { filterModel, setSelectedBookmark, updateFilterWithBookmark } = useContext(AnalysisFilterContext)
  const { permittedTowns } = useContext(PermittedAssociationsContext)
  const { isHousingManagement } = useContext(AuthContext)

  const tourStartDate = useMemo(() => moment().subtract(30, "days"), [])
  const tourEndDate = useMemo(() => moment().add(30, "days"), [])

  const [tourQuery, { data, loading, error }] = useLazyQuery<ToursResult, ToursVariables>(TOURS_QUERY, {
    fetchPolicy: "no-cache",
  })

  useEffect(() => {
    if (permittedTowns.length !== 0) {
      tourQuery({
        variables: {
          filter: {
            timeRange: {
              start: forceUTC(tourStartDate),
              end: forceUTC(tourEndDate),
            },
            townIds: permittedTowns.map((town) => town.id),
          },
        },
      })
    }
  }, [permittedTowns, tourQuery, tourStartDate, tourEndDate])

  if (error) {
    console.log(error)
  }

  const tours = data?.tours ?? []

  const handleTourClicked = (tour: Tour) => {
    filterModel.setTourType(tour.tourType)
    filterModel.setAssociationId(tour.towns[0].association.id)

    if (tour.towns.length === 1) {
      filterModel.setTownIds(tour.towns.map((town) => town.id))
    } else {
      filterModel.setTownIds(null)
    }

    filterModel.setDateFrom(tour.tourStart)
    filterModel.setDateUntil(tour.tourEnd)
    // set everything else to null, to ensure that no other filter ist set:
    filterModel.setRating(null)
    filterModel.setFractionType(null)
    filterModel.setFractionFrom(null)
    filterModel.setFractionTo(null)
    filterModel.setDisplayOnlyAutomaticAnalysis(null)
    filterModel.setEmptyingId(null)
    filterModel.setTourDate(null)
    filterModel.setRfids(null)
    setSelectedBookmark(null)
    history.push("/analysis")
  }

  const handleBookmarkClicked = (bookmark: Bookmark) => {
    updateFilterWithBookmark(bookmark)
    setSelectedBookmark(bookmark)
    history.push("/analysis")
  }

  return (
    <Fragment>
      <RootGrid className={classes.fullHeightRoot} justify={"flex-start"} spacing={1}>
        <Grid item xs={12} className={`${classes.dashboardItem}`}>
          <Card classes={{ root: classes.fullHeight }}>
            <CardHeader
              title={t("dashboard_page.tour_section.heading")}
              titleTypographyProps={{ className: classes.cardHeading }}
              avatar={
                <Avatar variant={"rounded"} className={classes.tourAvatar}>
                  <Subway />
                </Avatar>
              }
            />
            <CardContent className={classes.cardContent}>
              <TourSection tours={tours} loading={loading} onAction={handleTourClicked} />
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={isHousingManagement() ? 12 : 6} className={`${classes.dashboardItem}`}>
          <Card classes={{ root: classes.fullHeight }}>
            <CardHeader
              title={t("general.bookmarks")}
              titleTypographyProps={{ className: classes.cardHeading }}
              avatar={
                <Avatar variant={"rounded"} className={classes.bookmarkAvatar}>
                  <BookmarkIcon />
                </Avatar>
              }
            />
            <CardContent className={classes.cardContent}>
              <BookmarkSection onAction={handleBookmarkClicked} />
            </CardContent>
          </Card>
        </Grid>
        {!isHousingManagement() && (
          <Grid item xs={6} className={`${classes.dashboardItem}`}>
            <Card classes={{ root: classes.fullHeight }}>
              <CardHeader
                title={t("dashboard_page.vehicle_section.heading")}
                titleTypographyProps={{ className: classes.cardHeading }}
                avatar={
                  <Avatar variant={"rounded"} className={classes.scannerAvatar}>
                    <LocalShippingRounded />
                  </Avatar>
                }
              />
              <CardContent className={classes.cardContent}>
                <VehicleSection tours={tours} loading={loading} />
              </CardContent>
            </Card>
          </Grid>
        )}
      </RootGrid>
    </Fragment>
  )
}
