import { gql } from "apollo-boost"
import {
  defaultFractionMappingForAssociation,
  defaultFractionMappingForAssociationVariables,
  defaultFractionMappingForAssociation_defaultFractionMappingForAssociation,
} from "../generated/defaultFractionMappingForAssociation"

export const DEFAULT_FRACTION_MAPPING_FOR_ASSOCIATION = gql`
  query defaultFractionMappingForAssociation($id: ID) {
    defaultFractionMappingForAssociation(id: $id) {
      tourType
      mappedFractions {
        fraction
        mappedMaterialType
      }
    }
  }
`

export type DefaultFractionMappingForAssociationQueryResult = defaultFractionMappingForAssociation
export type DefaultFractionMappingForAssociationQueryVariables = defaultFractionMappingForAssociationVariables
export type DefaultFractionMappingForAssociation = defaultFractionMappingForAssociation_defaultFractionMappingForAssociation
