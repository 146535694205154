import { TourTypeInfo } from "../domain/models/TourTypes"
import { basicFractionGroupArray, FractionCategoryInfo, FractionInfo, FractionType } from "./Constants"
import lodash from "lodash"
import { LabelSetMaterialType } from "../api/graphql/generated/globalTypes"
import { Evaluation } from "../api/graphql/fragments/evaluation_fragments"

export interface ValuePerFraction {
  fraction: FractionInfo
  value: number
  isMainFraction: boolean
}

export function createEvaluationsPerFraction(
  evaluation: Evaluation,
  labelSetMaterialTypes?: LabelSetMaterialType[],
  withBags?: boolean,
): ValuePerFraction[] {
  let distributions =
    withBags === true
      ? evaluation.materialDistributionWithBagsDetailed
      : evaluation.materialDistributionWithoutBagsDetailed

  if (lodash.isNil(distributions)) return []

  if (!lodash.isNil(labelSetMaterialTypes)) {
    distributions = distributions.filter((distribution) => labelSetMaterialTypes.includes(distribution.materialType))
  }

  return distributions.map((distribution) => ({
    value: Number(distribution.value),
    fraction: { id: distribution.id, color: distribution.color, name: distribution.name },
    isMainFraction: distribution.materialType === LabelSetMaterialType.main_fraction,
  }))
}

export interface ValuePerFractionType {
  fractionType: FractionCategoryInfo
  value: number
}

export function createEvaluationsPerType(
  evaluation: Evaluation,
  tourTypeInfo: TourTypeInfo,
  types?: FractionType[],
  withBags?: boolean,
): ValuePerFractionType[] {
  const percentages = withBags === true ? evaluation.percentOfCategoryWithBags : evaluation.percentOfCategoryWithoutBags
  if (lodash.isNil(percentages)) return []
  return lodash.compact(
    Object.entries(percentages).map(([typeKey, value]) => {
      const fractionType = basicFractionGroupArray.find((type) => typeKey === type.fractionType)
      if (lodash.isNil(fractionType)) return null
      if (!lodash.isNil(types) && !types.includes(fractionType.fractionType)) return null
      if (lodash.isNil(value)) return null

      if (fractionType.id === LabelSetMaterialType.main_fraction) {
        fractionType.color = tourTypeInfo.fraction.color
        fractionType.name = tourTypeInfo.fraction.name
      }

      return {
        fractionType,
        value,
      }
    }),
  )
}
