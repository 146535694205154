import { gql } from "apollo-boost"
import {
  permittedAssociationsForFilter,
  permittedAssociationsForFilterVariables,
  permittedAssociationsForFilter_userAndPermissions,
  permittedAssociationsForFilter_userAndPermissions_permittedAssociations,
  permittedAssociationsForFilter_userAndPermissions_permittedAssociations_towns,
} from "../generated/permittedAssociationsForFilter"
import { FRAGMENT_ASSOCIATION_WITH_TOWNS } from "../fragments/association-with-town"

export const PERMITTED_ASSOCIATIONS_FOR_FILTER_QUERY = gql`
  query permittedAssociationsForFilter($userId: ID) {
    userAndPermissions(userId: $userId) {
      user {
        defaultTown {
          id
          name
          association {
            id
            name
          }
        }
      }
      permittedAssociations {
        associationId
        ...FragmentAssociationWithTowns
      }
    }
  }
  ${FRAGMENT_ASSOCIATION_WITH_TOWNS}
`

export type PermittedAssociationsForFilterResult = permittedAssociationsForFilter
export type PermittedAssociationsForFilterVariables = permittedAssociationsForFilterVariables
export type UserAndPermissionsForFilter = permittedAssociationsForFilter_userAndPermissions
export type UserAndPermissionsAssociationForFilter = permittedAssociationsForFilter_userAndPermissions_permittedAssociations
export type UserAndPermissionsTownForFilter = permittedAssociationsForFilter_userAndPermissions_permittedAssociations_towns
