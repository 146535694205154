import React, { FunctionComponent, Fragment, useContext, useMemo } from "react"
import { Autocomplete } from "@material-ui/lab"
import { TextField, Grid, makeStyles, Theme, List } from "@material-ui/core"
import { useTranslation } from "react-i18next"
import { ClipLoader } from "react-spinners"
import { ManageUserContext } from "./context/ManageUserContext"
import { useHousingManagements } from "../../../../context/HousingManagementContext"
import { HousingManagement } from "../../../../api/graphql/query/housing-managements"
import { HousingManagementListitem } from "./HousingManagementListItem"

const useStyles = makeStyles((theme: Theme) => ({
  textFieldContainer: {
    margin: 0,
  },
  subheading: {
    fontSize: 18,
  },
}))

interface IHousingManagementSelectionProps {}

export const HousingManagementSelection: FunctionComponent<IHousingManagementSelectionProps> = (props) => {
  const { t } = useTranslation()
  const classes = useStyles()

  const { housingManagements, setHousingManagements } = useContext(ManageUserContext)
  const { housingManagements: allHousingManagements, housingManagementsLoading } = useHousingManagements()

  const onRemoveHousingManagements = (removed: HousingManagement) => {
    const newHousingManagements = [...housingManagements]
    newHousingManagements.splice(housingManagements.indexOf(removed), 1)
    setHousingManagements(newHousingManagements)
  }

  const housingManagementOptions = useMemo(
    () => allHousingManagements.filter((hm) => !housingManagements.find((selected) => selected.id === hm.id)),
    [allHousingManagements, housingManagements],
  )

  return (
    <Fragment>
      <Grid item xs={12} className={classes.textFieldContainer}>
        <Autocomplete
          multiple
          options={housingManagementOptions}
          value={housingManagements}
          getOptionLabel={(option) => option.name}
          onChange={(_event, newValue) => setHousingManagements(newValue)}
          loading={housingManagementsLoading}
          loadingText={t("general.data_loading")}
          disableClearable
          renderTags={() => null}
          renderInput={(params) => (
            <TextField
              name="abilities"
              {...params}
              variant="standard"
              label={t("general.housing_management")}
              margin="normal"
              fullWidth
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {housingManagementsLoading ? <ClipLoader color="inherit" size={15} /> : null}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
            />
          )}
        />
      </Grid>
      <Grid item xs={12} className={classes.textFieldContainer}>
        <List>
          {housingManagements.map((housingManagement, index) => (
            <HousingManagementListitem
              housingManagement={housingManagement}
              onRemove={onRemoveHousingManagements}
              key={housingManagement.name}
            />
          ))}
        </List>
      </Grid>
    </Fragment>
  )
}
