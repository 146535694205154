import { gql } from "apollo-boost"
import { updateWidgetVariables, updateWidget } from "../generated/updateWidget"

export const UPDATE_WIDGET_MUTATION = gql`
  mutation updateWidget(
    $id: ID!
    $reportId: ID
    $filterId: ID
    $title: String
    $textTitle: String
    $textContent: String
    $chartCompact: Boolean
    $widgetType: WidgetType
    $channelType: ChannelType
    $payload: String
    $individualTextTemplateIds: [ValueForRatingInput!]
    $widgetTextCategoryId: ID
  ) {
    updateWidget(
      id: $id
      reportId: $reportId
      filterId: $filterId
      title: $title
      textTitle: $textTitle
      textContent: $textContent
      chartCompact: $chartCompact
      widgetType: $widgetType
      channelType: $channelType
      payload: $payload
      individualTextTemplateIds: $individualTextTemplateIds
      widgetTextCategoryId: $widgetTextCategoryId
    ) {
      id
      widgetType
      updatedAt
      individualTextTemplateIds {
        rating
        value
      }
      individualTextTemplates {
        rating
        value
      }
      widgetTextCategory {
        id
        name
      }
    }
  }
`

export type UpdateWidgetResult = updateWidget
export type UpdateWidgetVariables = updateWidgetVariables
