// validateTours
import { gql } from "apollo-boost"
import { importTours } from "../generated/importTours"

export const IMPORT_TOURS_MUTATION = gql`
  mutation importTours($excelFile: Upload!) {
    importTours(excelFile: $excelFile) {
      message
    }
  }
`

export type ImportToursResult = importTours
