import { InvalidityType, Rating } from "../../../../api/graphql/generated/globalTypes"
import { MaterialAnalysisForMap } from "../../../../api/graphql/query/material-analysis-for-map"
import { IWasteQualityDictionary } from "./AnalysisWasteQualityPieGraphSection"

export const createWasteQualityDictionary = (materialAnalysis: MaterialAnalysisForMap[]) => {
  const dictionary: IWasteQualityDictionary = {}
  const validMaterialAnalysis = materialAnalysis.filter(
    (analysis) =>
      !analysis.invalidity.isInvalid || analysis.invalidity.invalidType !== InvalidityType.SPLIT_NOT_VALIDATED,
  )

  const materialAnalysisNotClassifiable = validMaterialAnalysis.filter(
    (singleAnalysis) => singleAnalysis.calculated.rating === Rating.Unknown || singleAnalysis.invalidity.isInvalid,
  )

  const numClassifiableAnalysis = validMaterialAnalysis.length - materialAnalysisNotClassifiable.length

  Object.keys(Rating).forEach((ratingKey) => {
    const analysisForClassifiableRating = validMaterialAnalysis.filter(
      (singleAnalysis) => singleAnalysis.calculated.rating === ratingKey && !singleAnalysis.invalidity.isInvalid,
    )

    dictionary[ratingKey] = {
      analysis: ratingKey !== Rating.Unknown ? analysisForClassifiableRating : materialAnalysisNotClassifiable,
      percentage: ratingKey !== Rating.Unknown ? analysisForClassifiableRating.length / numClassifiableAnalysis : 0,
    }
  })

  return dictionary
}
