import React, { FunctionComponent } from "react"
import {
  Theme,
  makeStyles,
  TextField,
  Card,
  IconButton
} from "@material-ui/core"
import { useTranslation } from "react-i18next"
import { Clear } from "@material-ui/icons"

const useStyles = makeStyles((theme: Theme) => ({
  editorItem: {
    padding: theme.spacing(1)
  }
}))

interface IEditorItemTextFieldProps {
  value: string
  onChange: (value: string) => void
  onRemove: () => void
}

export const EditorItemTextField: FunctionComponent<
  IEditorItemTextFieldProps
> = props => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { onRemove, value, onChange } = props
  return (
    <Card className={classes.editorItem}>
      <TextField
        name={"value"}
        placeholder={t("text_management.freetext")}
        value={value}
        onChange={event => onChange(event.target.value)}
      />
      <IconButton size={"small"} onClick={onRemove}>
        <Clear />
      </IconButton>
    </Card>
  )
}
