import React, { FunctionComponent, useContext, useMemo } from "react"
import { useTranslation } from "react-i18next"
import { useTextFilter } from "../../../context/TextFilterContext"
import { FilterDropDown } from "./FilterDropDown"
import { PermittedAssociationsContext } from "../../../context/PermittedAssociationsContext"

interface ITownFilterProps {
  onChange?: Function
  style?: any
}

export const TownFilter: FunctionComponent<ITownFilterProps> = (props) => {
  const { t } = useTranslation()
  const { onChange } = props

  const { associationId, townId, setTownId } = useTextFilter()
  const { permittedAssociations } = useContext(PermittedAssociationsContext)

  const items = useMemo(() => {
    const selectedAssociation = permittedAssociations.find((permission) => permission.associationId === associationId)

    if (!selectedAssociation) {
      return []
    }

    return selectedAssociation.towns.map((town) => ({ key: town.id, value: town.name }))
  }, [permittedAssociations, associationId])

  return (
    <FilterDropDown
      style={props.style}
      items={items}
      selectedItem={townId || "all"}
      onChange={(id: string) => {
        setTownId(id)
        onChange && onChange()
      }}
      keyPrefix="towns"
      title={t("tour.property.town")}
      noSelectionEnabled
    />
  )
}
