import React, { FunctionComponent } from "react"
import {
  Theme,
  makeStyles,
  ExpansionPanel,
  ExpansionPanelSummary,
  Typography,
  ExpansionPanelDetails,
  Grid,
  Fab,
  Tooltip,
} from "@material-ui/core"
import { useTranslation } from "react-i18next"
import { ExpandMore, Edit, Add } from "@material-ui/icons"
import { UserAndPermissionsAssociation } from "../../../api/graphql/query/permitted-associations-for-user"

const useStyles = makeStyles((theme: Theme) => ({
  expPanelSummaryActive: {
    background: theme.palette.primary.main,
    color: "#ffffff",
  },
  expPanelSummary: {
    height: 60,
  },
  expPanelSummaryTypo: {
    fontSize: 20,
  },
  subheading: {
    color: theme.palette.primary.main,
    fontSize: 18,
    marginBottom: theme.spacing(1),
  },
  section: {
    marginTop: theme.spacing(2),
  },
  detailSection: {
    margin: theme.spacing(1),
  },
}))

interface IAssociationExpansionPanelProps {
  association: UserAndPermissionsAssociation
  onEdit?: () => void
  onAdd?: () => void

  open: boolean
  toggle: () => void
}

export const AssociationExpansionPanel: FunctionComponent<IAssociationExpansionPanelProps> = (props) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { association, onEdit, onAdd, open, toggle } = props

  return (
    <ExpansionPanel expanded={open}>
      <ExpansionPanelSummary
        expandIcon={<ExpandMore />}
        className={classes.expPanelSummary}
        classes={{ expanded: classes.expPanelSummaryActive }}
        onClick={toggle}
      >
        <Typography className={classes.expPanelSummaryTypo}>{association.name}</Typography>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails>
        <Grid container>
          {/* ASSOCIATION DETAILS */}
          <Grid container item direction="row" xs={10}>
            <Grid item className={classes.detailSection}>
              <Typography color="primary">{t("general.latitude")}</Typography>
              <Typography>{association.latitude}</Typography>
            </Grid>
            <Grid item className={classes.detailSection}>
              <Typography color="primary">{t("general.longitude")}</Typography>
              <Typography>{association.longitude}</Typography>
            </Grid>
            <Grid item className={classes.detailSection}>
              <Typography color="primary">{t("general.radius")}</Typography>
              <Typography>{association.radius}</Typography>
            </Grid>
            <Grid item className={classes.detailSection}>
              <Typography color="primary">{t("general.rating_schema")}</Typography>
              <Typography>{association.ratingSchema ? t("general.custom") : t("general.default")}</Typography>
            </Grid>
          </Grid>
          {/* ASSOCIATION ACTIONS */}
          {(onEdit || onAdd) && (
            <Grid container item direction="row" xs={2} justify="flex-end">
              {onAdd && (
                <Grid item className={classes.detailSection}>
                  <Tooltip title={t("association_management.add_town")}>
                    <Fab size="small" color="primary" onClick={onAdd}>
                      <Add />
                    </Fab>
                  </Tooltip>
                </Grid>
              )}
              {onEdit && (
                <Grid item className={classes.detailSection}>
                  <Tooltip title={t("general.edit")}>
                    <Fab size="small" color="primary" onClick={onEdit}>
                      <Edit />
                    </Fab>
                  </Tooltip>
                </Grid>
              )}
            </Grid>
          )}

          {open && (
            <Grid item xs={12} className={classes.section}>
              {props.children}
            </Grid>
          )}
        </Grid>
      </ExpansionPanelDetails>
    </ExpansionPanel>
  )
}
