import React, { FunctionComponent, useContext, useCallback, useEffect, useMemo } from "react"
import { createContext } from "react"
import { useLazyQuery } from "react-apollo"
import { Report, ReportsResult, ReportsVariables, REPORTS_QUERY } from "../../../api/graphql/query/reports"
import queryString from "query-string"
import lodash from "lodash"
import { TextFilterContext } from "../../../context/TextFilterContext"
import { ApolloError } from "apollo-client"

interface ICommunicationCenterContext {
  reports: Report[]
  selectedReportId: string
  reportsLoading: boolean
  reportsError: ApolloError | undefined
  fetchReports: () => void
}

export const CommunicationCenterContext = createContext<ICommunicationCenterContext>({
  reports: [],
  selectedReportId: "",
  reportsLoading: true,
  reportsError: undefined,
  fetchReports: () => {},
})

interface ICommunicationCenterContextProviderProps {}

export const CommunicationCenterContextProvider: FunctionComponent<ICommunicationCenterContextProviderProps> = (
  props,
) => {
  const { associationId, townId } = useContext(TextFilterContext)

  const selectedReportId = lodash.get(queryString.parse(location.search), "id", "") as string
  const [reportsQuery, { data, loading, error }] = useLazyQuery<ReportsResult, ReportsVariables>(REPORTS_QUERY, {
    fetchPolicy: "no-cache",
  })

  const fetchReports = useCallback(() => {
    if (associationId) {
      reportsQuery({
        variables:townId ? { associationId, townId } : { associationId }
      })
    }
  }, [associationId, townId, reportsQuery])

  useEffect(() => fetchReports(), [fetchReports])

  const reports = useMemo(() => data?.reports || [], [data]) as Report[]

  return (
    <CommunicationCenterContext.Provider
      value={{
        reports,
        selectedReportId,
        fetchReports,
        reportsLoading: loading,
        reportsError: error,
      }}
    >
      {props.children}
    </CommunicationCenterContext.Provider>
  )
}
