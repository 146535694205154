export const buildSearchTerms = (searchTerm: string): string[] => {
  return searchTerm
    .split(" ")
    .map(s => s.trim().toLowerCase())
    .filter(s => s.length > 0)
}

export const searchFields = (
  fields: Array<string>,
  searchTerm: string
): boolean => {
  const search = buildSearchTerms(searchTerm) // build array of search terms

  const result = search.map(s => {
    return (
      fields.filter(f => f.toLowerCase().includes(s)).filter(r => r).length > 0
    ) // loop through fields to determine whether a search term matches with a field or not
  })

  return result.filter(r => r).length === search.length // make sure all search terms are found in fields
}
