/*
    Hook which provides all filter functionalities
*/

import React, { useState, createContext, useEffect, useCallback, useContext } from "react"
import { useLazyQuery } from "@apollo/react-hooks"
import { TourFilter } from "../api/graphql/generated/globalTypes"
import { ApolloError } from "apollo-client"
import moment from "moment"
import { forceUTC } from "../domain/helper/ForceUTC"
import { ToursResult, TOURS_QUERY, Tour, ToursVariables } from "../api/graphql/query/tours"
import { PermittedAssociationsContext } from "./PermittedAssociationsContext"

interface TourFilterContextType {
  tours: Tour[]
  setApplyFilter: (applyFilter: boolean) => void
  filter: TourFilter
  setFilter: (filter: TourFilter) => void
  resetFilter: () => void
  contextError: ApolloError | undefined
  contextLoading: boolean
  contextRefetch: () => void
  generateFilterVariables: () => TourFilter
}

export const TourFilterContext = createContext<TourFilterContextType>({
  tours: [],
  setApplyFilter: () => {},
  filter: { townIds: [] },
  setFilter: () => {},
  resetFilter: () => {},
  contextError: undefined,
  contextLoading: true,
  contextRefetch: () => {},
  generateFilterVariables: () => ({ townIds: [] }),
})

export const TourFilterProvider = (props: any) => {
  const auth = useTourFilterProvider()

  return <TourFilterContext.Provider value={auth}>{props.children}</TourFilterContext.Provider>
}

const useTourFilterProvider = (): TourFilterContextType => {
  const [filter, setFilter] = useState<TourFilter>({
    townIds: [],
    timeRange: { start: moment().startOf("day"), end: moment().add(3, "weeks").endOf("day") },
  })
  const [applyFilter, setApplyFilter] = useState<boolean>(false)
  const { permittedTowns, permittedAssociationsLoading } = useContext(PermittedAssociationsContext)

  const [
    tourQuery,
    { data: newData, error: contextError, loading: contextLoading, refetch: contextRefetch },
  ] = useLazyQuery<ToursResult, ToursVariables>(TOURS_QUERY, { onError: console.error, fetchPolicy: "no-cache" })

  const generateFilterVariables = useCallback(() => {
    const start = filter?.timeRange?.start ? forceUTC(filter.timeRange.start) : null
    const end = filter?.timeRange?.end ? forceUTC(filter.timeRange.end) : null
    let townIds = filter?.townIds ?? []

    if (townIds.length === 0 && !filter?.associationId) {
      townIds = permittedTowns.map((town) => town.id)
    }

    return {
      ...filter,
      timeRange: {
        start,
        end,
      },
      townIds,
    }
  }, [filter, permittedTowns])

  useEffect(() => {
    if (applyFilter || !permittedAssociationsLoading) {
      const filter = generateFilterVariables()
      // in order to prevent unnecessary calls initially
      // only execute query if either association or at least one town is selected
      // townIds default to all permitted towns if nothing is selected (see generateFilterVariables)
      if (filter.townIds.length > 0 || filter.associationId) {
        tourQuery({ variables: { filter } })
        setApplyFilter(false)
      }
    }
  }, [applyFilter, generateFilterVariables, tourQuery, permittedAssociationsLoading])

  const tours = newData?.tours ?? []

  const resetFilter = () => {
    setFilter({ townIds: [] })
  }

  return {
    tours,
    setApplyFilter,
    filter,
    setFilter,
    resetFilter,
    contextError,
    contextLoading,
    contextRefetch,
    generateFilterVariables,
  }
}
