import React, { useEffect } from "react"
import { useLocation, useHistory } from "react-router-dom"
import qs from "query-string"
import lodash from "lodash"
import moment from "moment"
import { TourType, TourFilter } from "../api/graphql/generated/globalTypes"

const ARRAY_DELIMITER = ","

export function useUrlChangeEffect(filter: TourFilter, setFilter: (filter: TourFilter) => void) {
  const location = useLocation()

  // (Execution Order: 1) Executed when hash changes → Updates state / context
  useEffect(() => {
    const hashedState = getHashedState(location.hash)
    const hashedModel = getHashedModel(filter)
    const newFilter: TourFilter = { ...filter }

    Object.keys(hashedState).map((key) => {
      const target = hashedState[key]
      const current = hashedModel[key]

      if (current !== target) {
        switch (key) {
          case "tourType":
            newFilter.tourType = target as TourType
            break
          case "associationId":
            newFilter.associationId = target
            break
          case "townIds":
            newFilter.townIds = `${target}`.split(ARRAY_DELIMITER)
            break
          case "hasRfidReader":
            newFilter.hasRfidReader = target ? target === "true" : null
            break
          case "hasAnalysis":
            newFilter.hasAnalysis = target ? target === "true" : null
            break
          case "dateFrom":
            if (newFilter.timeRange) {
              newFilter.timeRange.start = target
            } else {
              newFilter.timeRange = { ...filter.timeRange, start: target }
            }
            break
          case "dateUntil":
            if (newFilter.timeRange) {
              newFilter.timeRange.end = target
            } else {
              newFilter.timeRange = { ...filter.timeRange, end: target }
            }
            break
          case "wasteScannerId":
            newFilter.wasteScannerId = target
            break
        }
      }
      return key
    })
    setFilter(newFilter)
    // We only want to execute effect in specific circumstances, to prevent loops between effects
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.hash])
}

export function useUrlUpdateEffect(isLoaded: React.MutableRefObject<boolean>, filter: TourFilter) {
  const location = useLocation()
  const history = useHistory()
  const effectFunctionGenerator = (override: boolean = false) => () => {
    // Prevent execution on infinite loop on first page load (→ Hash in URL overrides filter model on page load!)
    if (isLoaded.current || (override && !location.hash)) {
      const { associationId, hasAnalysis, hasRfidReader, timeRange, tourType, townIds, wasteScannerId } = filter

      const parsed = getHashedState(location.hash)
      const updated: { [key: string]: string | string[] | boolean | null | undefined } = {
        tourType,
        associationId,
        townIds: townIds?.join(ARRAY_DELIMITER),
        dateFrom: timeRange?.start ? moment(timeRange.start).format("YYYY-MM-DD") : null,
        dateUntil: timeRange?.end ? moment(timeRange?.end).format("YYYY-MM-DD") : null,
        wasteScannerId,
        hasAnalysis,
        hasRfidReader,
      }

      // Update hash in URL only if data have really changed! → Otherwise yours would have to hit back button twice, or more often
      const shouldUpdate = Object.keys(updated).reduce((acc, key) => {
        return acc || updated[key] !== parsed[key]
      }, false)
      if (shouldUpdate) {
        const hash = `${qs.stringify(updated)}`
        history.push({ pathname: location.pathname, hash })
      }
    }
    // We only want to execute effect in specific circumstances, to prevent loops between effects
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }

  // eslint-disable-next-line
  useEffect(
    effectFunctionGenerator(),
    // We only want to execute effect in specific circumstances, to prevent loops between effects
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      filter.associationId,
      filter.hasAnalysis,
      filter.hasRfidReader,
      filter.timeRange?.start,
      filter.timeRange?.end,
      filter.tourType,
      filter.townIds,
      filter.wasteScannerId,
    ],
  )

  // eslint-disable-next-line
  useEffect(effectFunctionGenerator(true), [])
}

// Parse Hash from URL and transform it into commonly formatted state (for comparison)
const getHashedState = (hash: string): { [key: string]: any } => {
  const parsed = qs.parse(hash)
  const combined = Object.keys(parsed).reduce((acc, key) => {
    const target = lodash.get(parsed, key, null)
    return {
      ...acc,
      [key]: target,
    }
  }, {})

  const townIds = lodash.get(parsed, "townIds", null)

  return {
    ...combined,
    townIds: townIds ? (townIds as string).split(ARRAY_DELIMITER) : null,
  }
}

// Transform model into commonly formatted state (for comparison)
const getHashedModel = (filter: TourFilter): { [key: string]: any } => {
  return {
    tourType: filter.tourType || null,
    associationId: filter.associationId || null,
    townIds: filter.townIds?.join(ARRAY_DELIMITER) || null,
    hasAnalysis: filter.hasAnalysis || null,
    hasRfidReader: filter.hasRfidReader || null,
    dateFrom: filter.timeRange?.start || null,
    dateUntil: filter.timeRange?.end || null,
    wasteScannerId: filter.wasteScannerId || null,
  }
}
