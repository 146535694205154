import React from "react"
import cx from "classnames"
import PropTypes from "prop-types"
import { Switch, Route, Redirect } from "react-router-dom"
// creates a beautiful scrollbar
import PerfectScrollbar from "perfect-scrollbar"
import "perfect-scrollbar/css/perfect-scrollbar.css"
import { AuthConsumer } from "../../../context/AuthContext"

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles"

// core components
import Sidebar from "../sidebar/Sidebar.jsx"

import dashboardRoutes from "../../../routes/dashboard.jsx"

import appStyle from "../../styles/dashboardStyle.jsx"
import { AnalysisFilterProvider } from "../../../context/AnalysisFilterContext"
import { TextFilterProvider } from "../../../context/TextFilterContext"

const filteredDashboardRoutes = (isAuthenticated, hasOneRequiredAbility, getUserInfo) => {
  let filteredRoutes = dashboardRoutes.filter((route) => {
    if (route.requiredAbilities && route.requiredAbilities.length > 0) {
      return hasOneRequiredAbility(route.requiredAbilities)
    }
    if (route.unauthenticatedAccess) {
      return true
    }
    return isAuthenticated()
  })

  let userInfo = getUserInfo && getUserInfo()
  if (!isAuthenticated() || !userInfo) {
    return filteredRoutes
  } else {
    return filteredRoutes.map((route) => {
      if (route.path === "/login" && !!userInfo && !!userInfo.full_name) {
        return {
          ...route,
          name: userInfo.full_name,
        }
      }
      return route
    })
  }
}

const switchRoutes = (
  <AuthConsumer>
    {({ isAuthenticated, hasOneRequiredAbility }) => (
      <AnalysisFilterProvider>
        <TextFilterProvider>
          <Switch>
            {filteredDashboardRoutes(isAuthenticated, hasOneRequiredAbility).map((prop, key) => {
              if (prop.redirect) return <Redirect from={prop.path} to={prop.pathTo} key={key} />
              if (prop.collapse) {
                return prop.views.map((prop, key) => {
                  return <Route path={prop.path} component={prop.component} key={key} />
                })
              }
              return <Route path={prop.path} component={prop.component} key={key} />
            })}
          </Switch>
        </TextFilterProvider>
      </AnalysisFilterProvider>
    )}
  </AuthConsumer>
)

var ps

class Dashboard extends React.Component {
  constructor(props) {
    super(props)
    this.myRef = React.createRef()
  }

  state = {
    mobileOpen: false,
    miniActive: false,
  }
  handleDrawerToggle = () => {
    this.setState({ mobileOpen: !this.state.mobileOpen })
  }
  getRoute() {
    return this.props.location.pathname !== "/maps/full-screen-maps"
  }
  componentDidMount() {
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(this.myRef.current, {
        suppressScrollX: true,
        suppressScrollY: false,
      })
      // TODO: set overflow for IE
      // document.body.style.overflow = "hidden"
    }
  }
  componentWillUnmount() {
    if (navigator.platform.indexOf("Win") > -1) {
      ps.destroy()
    }
  }
  componentDidUpdate(e) {
    if (e.history.location.pathname !== e.location.pathname) {
      this.myRef.current.scrollTop = 0
      if (this.state.mobileOpen) {
        this.setState({ mobileOpen: false })
      }
    }
  }
  sidebarMinimize() {
    this.setState({ miniActive: !this.state.miniActive })
  }
  render() {
    const { classes, ...rest } = this.props
    const mainPanel =
      classes.mainPanel +
      " " +
      cx({
        [classes.mainPanelSidebarMini]: this.state.miniActive,
        [classes.mainPanelWithPerfectScrollbar]: navigator.platform.indexOf("Win") > -1,
      })

    return (
      <AuthConsumer>
        {({ isAuthenticated, hasOneRequiredAbility }) => {
          return (
          <div className={classes.wrapper}>
            <Sidebar
              routes={filteredDashboardRoutes(isAuthenticated, hasOneRequiredAbility)}
              logo={null}
              handleDrawerToggle={this.handleDrawerToggle}
              open={this.state.mobileOpen}
              color="primary"
              bgColor="white"
              miniActive={this.state.miniActive}
              {...rest}
            />
            <div className={mainPanel} ref={this.myRef}>
              {/*<Header*/}
              {/*sidebarMinimize={this.sidebarMinimize.bind(this)}*/}
              {/*miniActive={this.state.miniActive}*/}
              {/*routes={filteredDashboardRoutes(isAuthenticated, hasOneRequiredAbility)}*/}
              {/*handleDrawerToggle={this.handleDrawerToggle}*/}
              {/*{...rest}*/}
              {/*/>*/}
              {/* On the /maps/full-screen-maps route we want the map to be on full screen - this is not possible if the content and conatiner classes are present because they have some paddings which would make the map smaller */}
              {this.getRoute() ? (
                <div className={classes.content}>
                  <div className={classes.container}>{switchRoutes}</div>
                </div>
              ) : (
                <div className={classes.map}>{switchRoutes}</div>
              )}
              {/*{this.getRoute() ? <Footer fluid /> : null}*/}
            </div>
          </div>
        )}}
      </AuthConsumer>
    )
  }
}

Dashboard.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(appStyle)(Dashboard)
