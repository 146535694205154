import React, { FunctionComponent, Fragment, useState } from "react"
import { Theme, makeStyles, Slider, Grid, Typography } from "@material-ui/core"
import { useTranslation } from "react-i18next"
import { RatingSchemaPercentage } from "../../../../api/graphql/query/associations"
import { Rating } from "../../../../api/graphql/generated/globalTypes"

const useStyles = makeStyles((theme: Theme) => ({
  slider: {
    width: "90%"
  },
  ratingLabelContainer: {
    width: "90%"
  },
  ratingLabel: {
    textAlign: "center",
    color: theme.palette.secondary.main,
    fontSize: 14
  }
}))

interface ISliderMark {
  value: number
  label: string
}

const marks: ISliderMark[] = [
  {
    value: 0,
    label: "0%"
  },
  {
    value: 25,
    label: "25%"
  },
  {
    value: 50,
    label: "50%"
  },
  {
    value: 75,
    label: "75%"
  },
  {
    value: 100,
    label: "100%"
  }
]

interface IRatingSliderProps {
  ratingSchemaPercentage: RatingSchemaPercentage
  onChange: (ratingSchemaPercentage: RatingSchemaPercentage) => void
}

export const RatingSlider: FunctionComponent<IRatingSliderProps> = props => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { ratingSchemaPercentage, onChange } = props
  const { percentageFrom, percentageTo } = ratingSchemaPercentage

  const [values, setValues] = useState<number[]>([percentageFrom, percentageTo])

  const widthLabelLow = values[0]
  const widthLabelMedium = values[1] - values[0]
  const widthLabelHigh = 100 - values[1]

  const onSliderChange = (newValues: number[]) => {
    setValues(newValues)
  }

  const onSliderChangeComitted = () => {
    onChange({
      ...ratingSchemaPercentage,
      percentageFrom: values[0],
      percentageTo: values[1]
    })
  }

  return (
    <Fragment>
      <Grid
        container
        justify={"center"}
        direction={"row"}
        className={classes.ratingLabelContainer}
      >
        <Grid item style={{ width: `${widthLabelLow}%` }}>
          <Typography className={classes.ratingLabel}>
            {widthLabelLow >= 10 ? t(`waste_quality.${Rating.Low}`) : t(`waste_quality_short.${Rating.Low}`)}
          </Typography>
        </Grid>
        <Grid
          item
          className={classes.ratingLabel}
          style={{ width: `${widthLabelMedium}%` }}
        >
          <Typography className={classes.ratingLabel}>
            {widthLabelMedium >= 10
              ? t(`waste_quality.${Rating.Medium}`)
              : t(`waste_quality_short.${Rating.Medium}`)}
          </Typography>
        </Grid>
        <Grid item style={{ width: `${widthLabelHigh}%` }}>
          <Typography className={classes.ratingLabel}>
            {widthLabelHigh >= 10 ? t(`waste_quality.${Rating.High}`) : t(`waste_quality_short.${Rating.High}`)}
          </Typography>
        </Grid>
      </Grid>
      <Slider
        marks={marks}
        track={"normal"}
        value={values}
        onChange={(event, value) => onSliderChange(value as number[])}
        onChangeCommitted={onSliderChangeComitted}
        valueLabelDisplay="auto"
        className={classes.slider}
      />
    </Fragment>
  )
}
