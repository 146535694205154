import { gql } from "apollo-boost"

import {
  templatesForRating_widgetTextCategories_widgetTextTemplates,
  templatesForRating,
  templatesForRatingVariables,
  templatesForRating_widgetTextCategories,
} from "../generated/templatesForRating"

export const WIDGET_TEXT_TEMPLATES_FOR_RATING_QUERY = gql`
  query templatesForRating($rating: Rating, $associationId: ID, $townId: ID) {
    widgetTextCategories {
      id
      name
      translationKey
      widgetTextTemplates(rating: $rating, associationId: $associationId, townId: $townId) {
        id
        rating
        title
        textTemplate
      }
    }
  }
`

export type WidgetTextCategoriesWithTemplatesResultForRating = templatesForRating
export type WidgetTextCategoriesWithTemplatesForRatingVariables = templatesForRatingVariables
export type WidgetTextCategoryForRating = templatesForRating_widgetTextCategories
export type WidgetTextTemplateForRating = templatesForRating_widgetTextCategories_widgetTextTemplates
