import React, { FunctionComponent, Fragment } from "react"
import { Theme, makeStyles, Typography, Grid } from "@material-ui/core"
import { useTranslation } from "react-i18next"
import { HousingManagementById_housingManagement_towns } from "../../../api/graphql/generated/HousingManagementById"
import { PageLoadingIndicator } from "../indicator/PageLoadingIndicator"
import lodash from "lodash"

const useStyles = makeStyles((theme: Theme) => ({
  title: {
    fontSize: 16,
    fontWeight: "bold",
    paddingBottom: theme.spacing(2),
    color: theme.palette.primary.main,
  },
  name: {
    border: "1px solid #00000022",
    borderRadius: 8,
    padding: theme.spacing(1),
  },
}))

interface IHousingManagementTownsProps {
  loading: boolean
  towns?: HousingManagementById_housingManagement_towns[]
}

export const HousingManagementTowns: FunctionComponent<IHousingManagementTownsProps> = (props) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { loading, towns } = props
  return (
    <Fragment>
      <Typography className={classes.title}>{t("housing_management.towns")}</Typography>
      <Grid container spacing={2}>
        {!loading &&
          towns &&
          lodash.orderBy(towns, (t) => t.name).map((town, i) => (
            <Grid item>
              <Typography key={i} className={classes.name}>
                {town.name}
              </Typography>
            </Grid>
          ))}
        {!loading && !towns.length && (
          <Grid item>
            <Typography>-</Typography>
          </Grid>
        )}
        {loading && <PageLoadingIndicator />}
      </Grid>
    </Fragment>
  )
}
