import React, { FunctionComponent, useEffect, useContext, useState, ChangeEvent } from "react"
import {
  Theme,
  makeStyles,
  Grid,
  Typography,
  Card,
  TextField,
  Button,
  Divider,
  CircularProgress,
} from "@material-ui/core"
import { useTranslation } from "react-i18next"
import { useHistory } from "react-router"
import lodash from "lodash"
import { AuthContext } from "../../context/AuthContext"
import { useMutation, useQuery, useLazyQuery } from "react-apollo"
import { toast } from "react-toastify"
import { VersionResult, VERSION_QUERY } from "../../api/graphql/query/version"
import validator from "email-validator"
import { SIGN_IN_MUTATION, SignInResult, SignInVariables } from "../../api/graphql/mutation/signin"
import { executeCallbackOnEnter } from "../../utils/form"
import { USER_LANGUAGE_QUERY, UserLanguageResult } from "../../api/graphql/query/user-language"
import { changeLanguage } from "../../utils/i18n"
import { getGraphQLErrorLocalized } from "../../utils/get-graphql-error-code"
import { LoginLogo } from "../partials/logo/LoginLogo"
import { PasswordResetDialog } from "../partials/password-reset/PasswordResetDialog"

const useStyles = makeStyles((theme: Theme) => ({
  background: {
    background: theme.palette.secondary.light,
    height: "100vh",
  },
  wrapper: {
    flexDirection: "row",
    height: "70vh",
    minHeight: "500px",
    marginRight: "auto;",
    marginBottom: "0px;",
    marginLeft: "auto;",
    width: "500px",
    color: "white",
  },
  link: {
    color: theme.palette.text.secondary,
  },
  passwordLink: {
    color: theme.palette.primary.main,
    paddingBottom: theme.spacing(2),
    cursor: "pointer",
  },

  card: {
    padding: theme.spacing(3),
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(5),
  },
  control: {
    width: "80%",
  },
  button: {
    width: "80%",
    textTransform: "none",
    letterSpacing: 2,
  },
  loadingIndicator: {
    color: theme.palette.secondary.main,
  },
}))

interface ILoginPageProps {}

export const LoginPage: FunctionComponent<ILoginPageProps> = (props) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const history = useHistory()
  const { authin } = useContext(AuthContext)

  const [signInMutation, { loading: signInMutationLoading }] = useMutation<SignInResult, SignInVariables>(
    SIGN_IN_MUTATION,
    {
      onError: (error) => {
        toast.error(getGraphQLErrorLocalized(error, t))
      },
      onCompleted: (data) => signInMutationCompleted(data),
    },
  )

  const { data: versionData } = useQuery<VersionResult>(VERSION_QUERY, {
    fetchPolicy: "network-only",
  })

  const [languageQuery] = useLazyQuery<UserLanguageResult>(USER_LANGUAGE_QUERY, {
    fetchPolicy: "no-cache",
    onCompleted: (data) => languageQueryCompleted(data),
  })

  const [isValid, setIsValid] = useState<boolean>(false)
  const [password, setPassword] = useState<string>("")
  const [email, setEmail] = useState<string>("")
  const [toggleResetPasswordDialog, setToggleResetPasswordDialog] = useState<boolean>(false)

  useEffect(() => {
    setIsValid(validator.validate(email) && !!password)
  }, [password, email])

  const onLoginClicked = async () => {
    signInMutation({ variables: { email, password } })
  }

  const signInMutationCompleted = async (data: SignInResult) => {
    if (!lodash.get(data, "changePassword.error")) {
      authin(data.signin?.token)
      languageQuery()
    }
  }

  const languageQueryCompleted = async (data: UserLanguageResult) => {
    await changeLanguage(String(data.userLanguage))
    history.push("/dashboard")
  }

  const handleEmailChange = (event: ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value)
  }

  return (
    <div className={classes.background}>
      {toggleResetPasswordDialog && (
        <PasswordResetDialog isOpen={toggleResetPasswordDialog} onToggleOpenDialog={setToggleResetPasswordDialog} />
      )}
      <Grid container justify="center" alignContent="center" className={classes.wrapper}>
        <Grid item>
          <Card className={classes.card}>
            <Grid container spacing={3}>
              <Grid item xs={12} container>
                <LoginLogo />
              </Grid>
              <Grid item xs={12} container justify="center">
                <TextField
                  className={classes.control}
                  onChange={handleEmailChange}
                  autoComplete="username email"
                  name="email"
                  type="email"
                  label={t("login_page.email")}
                  onKeyDown={isValid ? executeCallbackOnEnter(onLoginClicked) : undefined}
                />
              </Grid>
              <Grid item xs={12} container justify="center">
                <TextField
                  className={classes.control}
                  autoComplete="current-password"
                  onChange={(event: ChangeEvent<HTMLInputElement>) => setPassword(event.target.value)}
                  name="password"
                  type="password"
                  label={t("login_page.password")}
                  onKeyDown={isValid ? executeCallbackOnEnter(onLoginClicked) : undefined}
                />
              </Grid>
              <Grid item xs={12} container justify="center">
                <Button
                  color="primary"
                  variant="contained"
                  className={classes.button}
                  disabled={!isValid || signInMutationLoading}
                  onClick={onLoginClicked}
                  endIcon={signInMutationLoading && <CircularProgress size={20} className={classes.loadingIndicator} />}
                  type="submit"
                >
                  {t("login_page.login")}
                </Button>
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              <Grid container direction="column" justify="center" alignItems="center">
                <Grid item>
                  <a onClick={() => setToggleResetPasswordDialog(true)} className={classes.passwordLink}>
                    {t("login_page.forgot_password")}
                  </a>
                </Grid>
                <Grid item>
                  <a href={t("privacy_policy_link")} className={classes.link} target="_blank" rel="noopener noreferrer">
                    <Typography align="center" color="textSecondary" variant="caption">
                      {t("login_page.data_privacy_statement")}
                    </Typography>
                  </a>
                </Grid>
                <Grid item>
                  <a
                    href={t("terms_and_conditions_link")}
                    className={classes.link}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Typography align="center" color="textSecondary" variant="caption">
                      {t("login_page.terms_and_conditions")}
                    </Typography>
                  </a>
                </Grid>
                <Grid item>
                  {versionData?.version && (
                    <Typography align="center" color="textSecondary" variant="caption">
                      {versionData.version.env + " " + (versionData.version.tag || "")}
                    </Typography>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Card>
        </Grid>
      </Grid>
    </div>
  )
}
