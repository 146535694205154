import { gql } from "apollo-boost"
import { logs, logsVariables, logs_logs } from "../generated/logs"

export const LOGS_QUERY = gql`
  query logs($associationId: ID!, $townId: ID, $dateFrom: DateTime, $dateUntil: DateTime, $recipient: String) {
    logs(
      associationId: $associationId
      townId: $townId
      types: [sms, email]
      dateFrom: $dateFrom
      dateUntil: $dateUntil
      recipient: $recipient
    ) {
      level
      type
      time
      userEmail
      payload {
        citizen {
          firstName
          lastName
        }
        message
        communicationChannelReceiver
        mailData {
          tourType
          association
          town
        }
      }
    }
  }
`

export type LogsQueryVariables = logsVariables
export type LogsQueryResult = logs
export type LogEntry = logs_logs
