import { gql } from "apollo-boost"
import {
  materialAnalyisForMap,
  materialAnalyisForMapVariables,
  materialAnalyisForMap_materialAnalyisForMap,
} from "../generated/materialAnalyisForMap"

export const MATERIAL_ANALYSIS_FOR_MAP_QUERY = gql`
  query materialAnalyisForMap(
    $tourType: TourType
    $associationIds: [ID!]
    $displayOnlyAutomaticAnalysis: Boolean
    $townIds: [ID!]
    $tourIds: [ID!]
    $firstDate: DateTime
    $lastDate: DateTime
    $fractions: [FractionFilter!]
    $ratings: [Rating!]
    $disableMapping: Boolean
    $simplifyEvaluation: Boolean
    $source: MaterialAnalysisSource
    $rfids: [String!]
  ) {
    materialAnalyisForMap(
      tourType: $tourType
      associationIds: $associationIds
      displayOnlyAutomaticAnalysis: $displayOnlyAutomaticAnalysis
      townIds: $townIds
      tourIds: $tourIds
      firstDate: $firstDate
      lastDate: $lastDate
      fractions: $fractions
      ratings: $ratings
      disableMapping: $disableMapping
      simplifyEvaluation: $simplifyEvaluation
      source: $source
      rfids: $rfids
    ) {
      id
      metadata {
        location {
          latitude
          longitude
        }
      }
      calculated {
        rating
      }
      documentIds
      invalidity {
        isInvalid
        invalidType
      }
    }
  }
`

export type MaterialAnalysisForMapResult = materialAnalyisForMap
export type MaterialAnalysisForMapVariables = materialAnalyisForMapVariables
export type MaterialAnalysisForMap = materialAnalyisForMap_materialAnalyisForMap
