import React, { FunctionComponent, useEffect, useState } from "react"
import {
  Theme,
  makeStyles,
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  DialogActions,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  useTheme,
} from "@material-ui/core"
import { useTranslation } from "react-i18next"
import { useMutation } from "react-apollo"
import {
  UPDATE_WIDGET_MUTATION,
  UpdateWidgetVariables,
  UpdateWidgetResult,
} from "../../../../api/graphql/mutation/update-widget"
import { useQuery } from "react-apollo"
import {
  WidgetTextCategoriesResult,
  WIDGET_TEXT_CATEGORY_QUERY,
  WidgetTextCategory,
} from "../../../../api/graphql/query/widget-text-categories"
import lodash from "lodash"
import { TextTemplatesForCategory } from "./TextTemplatesForCategory"
import { ValueForRating } from "../../../../api/graphql/query/report"
import { ratingsAll } from "../../../../domain/helper/CalcRating"
import Typography from "@material-ui/core/Typography"
import { ClipLoader } from "react-spinners"
import { Widget } from "../../../../api/graphql/query/report-detailed"

const useStyles = makeStyles((theme: Theme) => ({
  dialogContent: {
    marginTop: 0,
    paddingTop: 0,
  },
  laneSection: {
    display: "flex",
    flex: 1,
    minHeight: 520,
    backgroundColor: theme.palette.secondary.light,
    borderRadius: 4,
  },
  button: { minWidth: 120 },
}))

interface IEditWidgetTextIndividualDialogProps {
  open: boolean
  onClose: Function
  onModification: Function
  widgetModel: Widget
}

export const EditWidgetTextIndividualDialog: FunctionComponent<IEditWidgetTextIndividualDialogProps> = (props) => {
  const classes = useStyles()
  const theme = useTheme()
  const { t } = useTranslation()
  const { open, onClose, onModification, widgetModel } = props

  const [selectedCategory, setSelectedCategory] = useState<WidgetTextCategory | null>(
    widgetModel.widgetTextCategory as WidgetTextCategory,
  )
  const [selectedTextTemplateIds, setSelectedTextTemplateIds] = useState<ValueForRating[] | null>(
    lodash.get(widgetModel, "individualTextTemplateIds"),
  )

  // const individualTextTemplateIdsPerRating = lodash.get(widgetModel, "individualTextTemplateIds")
  const [readyToSave, setReadyToSave] = useState<Boolean>(false)

  const customOnClose = () => onClose(!readyToSave)

  useEffect(() => {
    const id = lodash.get(selectedCategory, "id")
    if (!lodash.isNil(id) && id === lodash.get(widgetModel, "widgetTextCategory.id")) {
      setSelectedTextTemplateIds(lodash.get(widgetModel, "individualTextTemplateIds"))
    } else {
      setSelectedTextTemplateIds([])
      setReadyToSave(false)
    }
    // eslint-disable-next-line
  }, [selectedCategory])

  const {
    data: categoryData,
    // eslint-disable-next-line
    loading: categoryLoading,
    // eslint-disable-next-line
    error: categoryError,
  } = useQuery<WidgetTextCategoriesResult>(WIDGET_TEXT_CATEGORY_QUERY)

  const categories = (lodash.get(categoryData, "widgetTextCategories", []) as WidgetTextCategory[]).filter(
    (category) => lodash.get(category, "name") !== "Schwarzes Brett",
  )

  const [updateWidgetMutation, { loading: updateLoading }] = useMutation<UpdateWidgetResult, UpdateWidgetVariables>(
    UPDATE_WIDGET_MUTATION,
  )

  const onChangedTextTemplateSelection = (newSelectedTextTemplateIds: ValueForRating[]) => {
    const everyRatingSelected = lodash.every(ratingsAll, (rating) => {
      return newSelectedTextTemplateIds.map((selected) => selected.rating).includes(rating)
    })
    setReadyToSave(everyRatingSelected)
    setSelectedTextTemplateIds(newSelectedTextTemplateIds)
    return
  }

  const onSave = () => {
    const variables: UpdateWidgetVariables = {
      id: widgetModel.id,
      widgetTextCategoryId: lodash.get(selectedCategory, "id"),
      title: `${t("communication_center.text_individual_widget.dialog.heading")}: ${lodash.get(
        selectedCategory,
        "name",
        "",
      )}`,
    }
    if (lodash.isArray(selectedTextTemplateIds)) {
      variables.individualTextTemplateIds = selectedTextTemplateIds.map((valueForRating) => {
        const { rating, value } = valueForRating
        return { rating, value }
      })
    }

    updateWidgetMutation({ variables })
      .then((_) => {
        onModification()
        customOnClose()
      })
      .catch((reason) => {
        console.error("reason: ", reason)
        customOnClose()
      })
  }

  return (
    <Dialog open={open} onClose={() => customOnClose()} maxWidth={"xl"} fullWidth={true}>
      <DialogTitle>{t("communication_center.text_individual_widget.dialog.heading")}</DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel id="inputlabel-text-categories">
                {t("communication_center.text_individual_widget.dialog.text_category")}
              </InputLabel>
              <Select
                onChange={(event: React.ChangeEvent<{ value: unknown }>) =>
                  setSelectedCategory(categories.find((category) => category.id === event.target.value) || null)
                }
                value={lodash.get(selectedCategory, "id")}
              >
                {categories.map((category) => (
                  <MenuItem key={category.id} value={category.id}>
                    {t(category.translationKey)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} className={classes.laneSection}>
            {selectedCategory && (
              <TextTemplatesForCategory
                selectedCategory={selectedCategory}
                textTemplateIds={selectedTextTemplateIds}
                onChangedTextTemplateSelection={onChangedTextTemplateSelection}
                associationId={lodash.get(widgetModel, "filter.associationId", null)}
                townId={(widgetModel.filter?.townIds || []).length === 1 ? widgetModel.filter.townIds[0] : null}
                clickable
              />
            )}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Typography variant={"body1"} align={"left"} color={"textSecondary"}>
          {!readyToSave ? t("sms_dialog.warning_select_every_lane") : ""}
        </Typography>
        <Button color="primary" onClick={() => customOnClose()} className={classes.button}>
          {t("general.cancel")}
        </Button>
        <Button
          color="primary"
          onClick={() => onSave()}
          disabled={!readyToSave || updateLoading}
          className={classes.button}
          variant="contained"
        >
          {updateLoading ? <ClipLoader color={theme.palette.common.white} size={16} /> : t("general.save")}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
