import { gql } from "apollo-boost"

export const MUTATION = gql`
  mutation deleteReport($id: ID!) {
    deleteReport(id: $id) {
      error
      message
    }
  }
`
