import React, { FunctionComponent, Fragment, useMemo } from "react"
import { reportDetail_report, reportDetail_report_widgets } from "../../../api/graphql/generated/reportDetail"
import { CommunicationChannelTypeDictionary } from "../../../domain/models/CommunicationChannelTypes"
import { ExternalTownChannelTypeSection } from "./channel-type-sections/ExternalTownChannelTypeSection"
import { NewspaperChannelTypeSection } from "./channel-type-sections/NewspaperChannelTypeSection"
import { SMSEmailChannelTypeSection } from "./channel-type-sections/SMSEmailChannelTypeSection"
import { ChannelType } from "../../../api/graphql/generated/globalTypes"
import { IGenericChannelTypeSectionProps } from "./channel-type-sections/ChannelTypeSectionTypes"
import { WidgetExportsContextProvider } from "./context/WidgetExportsContext"

interface IReportDetailContentProps {
  report: reportDetail_report
  refetchReportDetails: () => void
}

const ChannelTypeSectionDictionary: { [key: string]: React.FC<IGenericChannelTypeSectionProps> } = {
  [ChannelType.black_board]: NewspaperChannelTypeSection,
  [ChannelType.daheim_app]: ExternalTownChannelTypeSection,
  [ChannelType.newspaper]: NewspaperChannelTypeSection,
  [ChannelType.sms]: SMSEmailChannelTypeSection,
  [ChannelType.email]: SMSEmailChannelTypeSection,
  [ChannelType.symvaro]: ExternalTownChannelTypeSection,
}

export const ReportDetailContent: FunctionComponent<IReportDetailContentProps> = (props) => {
  const { report } = props

  const channelTypesForReport = useMemo(() => {
    const externalTownType = report.filter.towns?.length === 1 ? report.filter.towns[0].externalTown?.type : null

    return Object.keys(CommunicationChannelTypeDictionary).filter(
      (channelType) =>
        CommunicationChannelTypeDictionary[channelType].externalTownType === externalTownType ||
        !CommunicationChannelTypeDictionary[channelType].externalTownType,
    )
  }, [report])

  const widgetDictionary = useMemo(() => {
    let dict: { [key: string]: reportDetail_report_widgets[] } = {}
    channelTypesForReport.map((channelType) => {
      dict[channelType] = (report?.widgets || []).filter((widget) => widget?.channelType === channelType) || []
    })

    return dict
  }, [report, channelTypesForReport])

  return (
    <Fragment>
      {channelTypesForReport.map((channel) => {
        const Component = ChannelTypeSectionDictionary[channel]
        return (
          <WidgetExportsContextProvider widgets={widgetDictionary[channel]}>
            <Component widgets={widgetDictionary[channel]} channelType={channel as ChannelType} report={report} />
          </WidgetExportsContextProvider>
        )
      })}
    </Fragment>
  )
}
