import { gql } from "apollo-boost"
import { createReport, createReportVariables } from "../generated/createReport"

export const CREATE_REPORT_MUTATION = gql`
  mutation createReport(
    $title: String!
    $associationId: ID
    $townIds: [ID!]
    $rating: Rating
    $fractionType: String
    $fractionFrom: Float
    $fractionTo: Float
    $dateFrom: Date
    $dateUntil: Date
    $emptyingYearNumber: Int
    $emptyingWeekNumber: Int
    $createWidgetType: WidgetType
    $chartCompact: Boolean
    $channelTypesForWidgets: [ChannelType]
    $widgetTitle: String
    $displayOnlyAutomaticAnalysis: Boolean
    $tourType: TourType
    $widgetComparisonTownIds: [ID!]
    $rfids: [String!]
  ) {
    createReport(
      title: $title
      associationId: $associationId
      townIds: $townIds
      rating: $rating
      fractionType: $fractionType
      fractionFrom: $fractionFrom
      fractionTo: $fractionTo
      dateFrom: $dateFrom
      dateUntil: $dateUntil
      emptyingYearNumber: $emptyingYearNumber
      emptyingWeekNumber: $emptyingWeekNumber
      createWidgetType: $createWidgetType
      chartCompact: $chartCompact
      channelTypesForWidgets: $channelTypesForWidgets
      widgetTitle: $widgetTitle
      displayOnlyAutomaticAnalysis: $displayOnlyAutomaticAnalysis
      tourType: $tourType
      widgetComparisonTownIds: $widgetComparisonTownIds
      rfids: $rfids
    ) {
      id
      title
      filter {
        id
        associationId
        townIds
        rating
        fractionName
        fractionType
        fractionFrom
        fractionTo
        dateFrom
        dateUntil
        emptyingYearNumber
        emptyingWeekNumber
        tourType
        rfids
      }
      widgets {
        id
        filter {
          id
          associationId
          townIds
          rating
          fractionName
          fractionType
          fractionFrom
          fractionTo
          dateFrom
          dateUntil
          emptyingYearNumber
          emptyingWeekNumber
          rfids
        }
      }
    }
  }
`

export type CreateReportResult = createReport
export type CreateReportVariables = createReportVariables
