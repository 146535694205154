import { gql } from "apollo-boost"
import {
    emptyingsOfTowns,
    emptyingsOfTownsVariables,
    emptyingsOfTowns_emptyingsOfTowns,
} from "../generated/emptyingsOfTowns"

export const EMPTYINGS_OF_TOWNS_QUERY = gql`
  query emptyingsOfTowns($ids: [ID!]!) {
    emptyingsOfTowns(ids: $ids) {
      id
      startDate
      weekNumber
      yearNumber
      tours {
        id
        name
        materialAnalysisCount
      }
    }
  }
`

export type EmptyingsOfTownsResult = emptyingsOfTowns
export type EmptyingsOfTownsVariables = emptyingsOfTownsVariables
export type EmptyingOfTown = emptyingsOfTowns_emptyingsOfTowns
