import React, { FunctionComponent } from "react"
import { useTranslation } from "react-i18next"
import { Grid, makeStyles, Theme, useTheme, Typography } from "@material-ui/core"

interface IAnalysisPageNoAssociationsIndicatorProps {
  label?: string
}

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: "flex",
    alignContent: "center",
    alignItems: "center",
    justifyContent: "center",
    height: "100vh",
    flexDirection: "column",
  },
}))

export const AnalysisPageNoAssociationsIndicator: FunctionComponent<IAnalysisPageNoAssociationsIndicatorProps> = (
  props,
) => {
  const { label } = props
  const { t } = useTranslation()
  const classes = useStyles()
  const theme = useTheme()
  return (
    <Grid container className={classes.container} justify="center">
      <Grid item>
        <Typography color="primary">{t("analysis_page.no_available_associations")}</Typography>
      </Grid>
    </Grid>
  )
}
