import { gql } from "apollo-boost"
import {
  defaultClassifiersForTown,
  defaultClassifiersForTownVariables,
  defaultClassifiersForTown_defaultClassifiersForTown,
} from "../generated/defaultClassifiersForTown"

export const DEFAULT_CLASSIFIERS_FOR_TOWN_QUERY = gql`
  query defaultClassifiersForTown($townId: String!) {
    defaultClassifiersForTown(townId: $townId) {
      tourType
      classifierId
    }
  }
`

export type DefaultClassifiersForTownQueryResult = defaultClassifiersForTown
export type DefaultClassifiersForTownQueryVariables = defaultClassifiersForTownVariables
export type DefaultClassifierForTown = defaultClassifiersForTown_defaultClassifiersForTown
