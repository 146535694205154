import React, { FunctionComponent, useContext, useEffect, useState, useMemo, useCallback } from "react"
import { Theme, makeStyles, Grid, Typography, useTheme, Button, FormControlLabel, Checkbox } from "@material-ui/core"
import { useTranslation } from "react-i18next"
import { ClipLoader } from "react-spinners"
import { TourTypeInfo } from "../../../domain/models/TourTypes"
import { AnalysisFilterContext } from "../../../context/AnalysisFilterContext"
import {
  DailyGroupedStatsComparisonForAnalysisQueryResult,
  DailyGroupedStatsComparisonForAnalysisQueryVariables,
  DAILY_GROUPED_STATS_COMPARISON_FOR_ANALYSIS_QUERY,
} from "../../../api/graphql/query/daily-grouped-stats-comparison-for-analysis"
import { useLazyQuery } from "react-apollo"
import { WertstoffAnalysisTrendComparisonGraph } from "../analysis-data/WertstoffAnalysisTrendComparisonGraph"
import {
  DetailedUserAndPermissionsAssociation,
  DetailedUserAndPermissionsTown,
} from "../../../api/graphql/query/detailed-user-and-permissions"
import { sortASC } from "../../../utils/sort"
import { AuthContext } from "../../../context/AuthContext"
import { KeyboardArrowDown } from "@material-ui/icons"

interface IStyleProps {
  open: boolean
}

const useStyles = makeStyles((theme: Theme) => ({
  container: {},
  reportCreateButton: {
    minWidth: 180,
  },
  graphSection: {
    minHeight: 250,
  },
  townSection: {
    background: theme.palette.secondary.light,
    borderRadius: theme.spacing(0.5),
    padding: theme.spacing(0.5),
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  icon: {
    transform: (props: IStyleProps) => `rotate(${props.open ? "180deg" : "0deg"})`,
    transition: "200ms",
    marginRight: theme.spacing(1),
  },
}))

const getInitiallySelectedTowns = (
  persistedAssociation: DetailedUserAndPermissionsAssociation | null,
  persistedTowns: DetailedUserAndPermissionsTown[],
): DetailedUserAndPermissionsTown[] => {
  const availableTowns = (persistedAssociation?.towns || []).filter((town) =>
    persistedTowns.find((persisted) => persisted.id === town.id),
  )

  return availableTowns.filter((selectableTown) => !!selectableTown).slice(0, 3)
}

interface IAnalysisTrendComparisonGraphSectionProps {
  onReportCreate: (widgetComparisonTownIds: string[]) => void
  tourTypeInfo: TourTypeInfo | undefined | null
}

export const AnalysisTrendComparisonGraphSection: FunctionComponent<IAnalysisTrendComparisonGraphSectionProps> = (
  props,
) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const { tourTypeInfo, onReportCreate } = props
  const { persistedAssociation, getAnalyticsQueryVariables, persistedTowns } = useContext(AnalysisFilterContext)
  const [open, setOpen] = useState<boolean>(false)
  const classes = useStyles({ open })

  const { isHousingManagement } = useContext(AuthContext)

  const [selectedTowns, setSelectedTowns] = useState<DetailedUserAndPermissionsTown[]>(
    getInitiallySelectedTowns(persistedAssociation, persistedTowns || []),
  )

  const [dailyGroupedStatsComparisonForAnalysisQuery, { data, loading, error }] = useLazyQuery<
    DailyGroupedStatsComparisonForAnalysisQueryResult,
    DailyGroupedStatsComparisonForAnalysisQueryVariables
  >(DAILY_GROUPED_STATS_COMPARISON_FOR_ANALYSIS_QUERY, {
    fetchPolicy: "cache-first",
  })

  const availableTowns = useMemo(() => persistedAssociation?.towns.sort((a, b) => sortASC(a.name, b.name)) || [], [
    persistedAssociation,
  ])
  const dailyGroupedStatsComparisonForAnalysis = data?.dailyGroupedStatsComparisonForAnalysis

  useEffect(() => {
    setSelectedTowns(getInitiallySelectedTowns(persistedAssociation, persistedTowns || []))
  }, [persistedAssociation, persistedTowns])

  useEffect(() => {
    if (selectedTowns.length > 0 && open && !data) {
      dailyGroupedStatsComparisonForAnalysisQuery({
        variables: { ...getAnalyticsQueryVariables(false), townIds: selectedTowns.map((town) => town.id) },
      })
    }
  }, [dailyGroupedStatsComparisonForAnalysisQuery, getAnalyticsQueryVariables, selectedTowns, open, data])

  const onChangeTowns = useCallback(
    (town: DetailedUserAndPermissionsTown) => {
      const index = selectedTowns.indexOf(town)
      let newSelectedTowns = [...selectedTowns]
      if (index >= 0) {
        newSelectedTowns.splice(index, 1)
      } else if (index < 0 && selectedTowns.length < 3) {
        newSelectedTowns = [...selectedTowns, town]
      }

      if (newSelectedTowns.length > 0) {
        dailyGroupedStatsComparisonForAnalysisQuery({
          variables: { ...getAnalyticsQueryVariables(false), townIds: newSelectedTowns.map((town) => town.id) },
        })
      }
      setSelectedTowns(newSelectedTowns)
    },
    [selectedTowns, setSelectedTowns, dailyGroupedStatsComparisonForAnalysisQuery, getAnalyticsQueryVariables],
  )

  return (
    <Grid container spacing={2} className={classes.container} justify="space-between" direction="column">
      <Grid container item xs={12} onClick={() => setOpen(!open)}>
        <Grid item>
          <KeyboardArrowDown fontSize="large" className={classes.icon} />
        </Grid>
        <Grid item xs>
          <Typography variant={"h5"}>
            {t("graph.comparison_trend.heading", { associationName: persistedAssociation?.name })}
          </Typography>
        </Grid>
      </Grid>
      {open && (
        <>
          {loading && (
            <Grid item xs container alignItems="center" justify="center" className={classes.graphSection}>
              <Grid item>
                <ClipLoader color={theme.palette.primary.main} />
              </Grid>
            </Grid>
          )}
          {!loading && error && (
            <Grid item xs container alignItems="center" justify="center" className={classes.graphSection}>
              <Grid item>
                <Typography>{t("general.no_data_available_reload_page")}</Typography>
              </Grid>
            </Grid>
          )}
          {!loading && !error && selectedTowns.length <= 0 && (
            <Grid item xs container alignItems="center" justify="center" className={classes.graphSection}>
              <Grid item>
                <Typography>{t("graph.comparison_trend.select_towns")}</Typography>
              </Grid>
            </Grid>
          )}
          {!loading && !error && dailyGroupedStatsComparisonForAnalysis && selectedTowns.length > 0 && (
            <Grid container justify="center">
              <WertstoffAnalysisTrendComparisonGraph
                dailyGroupedStatsComparisonForAnalysis={dailyGroupedStatsComparisonForAnalysis}
                tourTypeInfo={tourTypeInfo}
                legend
              />
            </Grid>
          )}
          <Grid item xs={12} className={classes.townSection}>
            <Grid container spacing={2} justify="flex-start" alignItems="center" alignContent="center">
              {availableTowns.map((town) => (
                <Grid item md={3} lg={2} key={town.name}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        color="primary"
                        checked={selectedTowns.includes(town)}
                        onChange={() => onChangeTowns(town)}
                      />
                    }
                    label={town.name}
                  />
                </Grid>
              ))}
            </Grid>
          </Grid>
          {!isHousingManagement() && (
            <Grid item xs={12}>
              <Grid container justify={"flex-end"}>
                <Grid item>
                  <Button
                    variant="contained"
                    color="primary"
                    aria-label="Bericht erstellen"
                    onClick={() => onReportCreate(selectedTowns.map((town) => town.id))}
                    className={classes.reportCreateButton}
                  >
                    {t("report.create_report")}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          )}
        </>
      )}
    </Grid>
  )
}
