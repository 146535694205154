import React, { FunctionComponent, Fragment, useState, useMemo, useContext, useCallback } from "react"
import { Theme, makeStyles, Fab } from "@material-ui/core"
import { useTranslation } from "react-i18next"
import { DeleteTourDataDialog } from "./DeleteTourDataDialog"
import { Memory } from "@material-ui/icons"
import { COLORS } from "../../../theme/theme"
import {
  RfidReadingCountForTourResult,
  RfidReadingCountForTourVariables,
  RFID_READING_COUNT_FOR_TOUR_QUERY,
} from "../../../api/graphql/query/rfid-reading-count-for-tour"
import { useQuery } from "react-apollo"
import { CircleLoader } from "react-spinners"
import { Tour } from "../../../api/graphql/query/tours"
import { TourFilterContext } from "../../../context/TourFilterContext"

const useStyles = makeStyles((theme: Theme) => ({
  deleteRfidReadingsFab: {
    position: "relative",
  },
  stroke1: {
    height: 1,
    background: COLORS.primary,
    width: 25,
    position: "absolute",
    top: 17,
    transform: "rotate(45deg)",
  },
  stroke2: {
    height: 3,
    background: "white",
    width: 26,
    position: "absolute",
    top: 19,
    left: 7,
    transform: "rotate(45deg)",
  },
  stroke3: {
    height: 1,
    background: COLORS.primary,
    width: 25,
    position: "absolute",
    top: 23,
    transform: "rotate(45deg)",
  },
}))

interface IDeleteTourDataButtonProps {
  tour: Tour
}

export const DeleteTourDataButton: FunctionComponent<IDeleteTourDataButtonProps> = (props) => {
  const { tour } = props
  const classes = useStyles()
  const { t } = useTranslation()
  const [deleteTourDataDialogOpen, setDeleteTourDataDialogOpen] = useState<boolean>(false)
  const [deleteSuccessful, setDeleteSuccessful] = useState<boolean>(false)
  const { contextRefetch } = useContext(TourFilterContext)

  const { loading, data } = useQuery<RfidReadingCountForTourResult, RfidReadingCountForTourVariables>(
    RFID_READING_COUNT_FOR_TOUR_QUERY,
    {
      onError: (error) => {
        console.log("error: ", error)
      },
      variables: {
        id: tour.id,
      },
    },
  )

  const rfidReadingCount = useMemo(() => data?.tour?.rfidReadingCount || 0, [data])
  const buttonDisabled = useMemo(
    () => (rfidReadingCount <= 0 && tour.materialAnalysisCount <= 0) || loading || deleteSuccessful,
    [rfidReadingCount, loading],
  )

  const onCloseDialog = useCallback(() => {
    contextRefetch()
    setDeleteTourDataDialogOpen(false)
  }, [contextRefetch, setDeleteTourDataDialogOpen])

  return (
    <Fragment>
      <DeleteTourDataDialog
        tour={tour}
        open={deleteTourDataDialogOpen}
        onClose={onCloseDialog}
        onDeleted={() => setDeleteSuccessful(true)}
      />

      <Fab
        color={"primary"}
        size={"small"}
        onClick={() => setDeleteTourDataDialogOpen(true)}
        className={classes.deleteRfidReadingsFab}
        disabled={buttonDisabled}
      >
        {!loading && (
          <Fragment>
            <div
              className={classes.stroke1}
              style={{ backgroundColor: buttonDisabled ? COLORS.disabled : COLORS.primary }}
            />
            <div
              className={classes.stroke2}
              style={{ backgroundColor: buttonDisabled ? COLORS.disabledForeground : "white" }}
            />
            <div
              className={classes.stroke3}
              style={{ backgroundColor: buttonDisabled ? COLORS.disabled : COLORS.primary }}
            />
            <Memory />
          </Fragment>
        )}
        {loading && <CircleLoader color="white" />}
      </Fab>
    </Fragment>
  )
}
