import { gql } from "apollo-boost"
import {
  changePassword,
  changePasswordVariables
} from "../generated/changePassword"

export const CHANGE_PASSWORD_MUTATION = gql`
  mutation changePassword($password: String!, $token: String!) {
    changePassword(password: $password, token: $token) {
      error
      message
    }
  }
`

export type ChangePasswordResult = changePassword
export type ChangePasswordVariables = changePasswordVariables
