import React from "react"
import { useTranslation } from "react-i18next"
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
  Theme,
  Typography,
} from "@material-ui/core"
import { useMutation } from "@apollo/react-hooks"
import { Town } from "../../../../api/graphql/query/towns"
import {
  CreateDefaultRfidGroupsMutationResult,
  CreateDefaultRfidGroupsMutationVariables,
  CREATE_DEFAULT_RFID_GROUPS_MUTATION,
} from "../../../../api/graphql/mutation/create-default-rfid-groups"
import { toast } from "react-toastify"

const useStyles = makeStyles((theme: Theme) => ({
  content: {
    width: 600,
  },
  validationField: {
    display: "flex",
    verticalAlign: "bottom",
  },
  dialogActions: {
    padding: theme.spacing(4),
  },
}))

export interface ICreateDefaultRfidGroupsDialog {
  onSuccess: () => void
  isOpen: boolean
  onCloseDialog: () => void
  town: Town
}

export const CreateDefaultRfidGroupsDialog: React.FunctionComponent<ICreateDefaultRfidGroupsDialog> = (props) => {
  const { onSuccess, isOpen, onCloseDialog, town } = props
  const classes = useStyles()
  const { t } = useTranslation()

  const [createDefaultRfidGroupsMutation, { loading: createDefaultRfidGroupsMutationLoading }] = useMutation<
    CreateDefaultRfidGroupsMutationResult,
    CreateDefaultRfidGroupsMutationVariables
  >(CREATE_DEFAULT_RFID_GROUPS_MUTATION, {
    onError: (error) => {
      console.log("error:", error)
    },
    onCompleted: (data) => {
      if (data.createDefaultRfidGroups.createdCount > 0) {
        onSuccess()
      } else {
        toast.info(t("rfid_groups.dialog.create_default.no_new_groups"))
        onSuccess()
      }
    },
  })

  return (
    <Dialog maxWidth="xl" open={isOpen}>
      <DialogTitle>{t("rfid_groups.dialog.create_default.title")}</DialogTitle>
      <DialogContent className={classes.content}>
        <Typography>{t("rfid_groups.dialog.create_default.content")}</Typography>
      </DialogContent>

      <DialogActions className={classes.dialogActions}>
        <Button color="primary" onClick={onCloseDialog}>
          {t("general.no")}
        </Button>
        <Button
          color="primary"
          variant="contained"
          onClick={() => {
            createDefaultRfidGroupsMutation({
              variables: {
                townId: town.id,
              },
            })
          }}
          disabled={createDefaultRfidGroupsMutationLoading}
        >
          {t("general.yes")}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
