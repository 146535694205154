import { gql } from "apollo-boost"
import {
  createFilter,
  createFilterVariables,
  createFilter_createFilter
} from "../generated/createFilter"

export const CREATE_FILTER_MUTATION = gql`
  mutation createFilter($filter: PersistedFilterInput!) {
    createFilter(filter: $filter) {
      id
      name
      associationId
      townIds
      emptyingWeekNumber
      emptyingYearNumber
      rating
      fractionType
      fractionFrom
      fractionTo
      dateFrom
      dateUntil
      emptying {
        id
        startDate
        weekNumber
        yearNumber
        tours {
          id
          name
        }
      }
      tourType
      source
      displayOnlyAutomaticAnalysis
      rfids
    }
  }
`

export type CreateFilterResult = createFilter
export type CreateFilterVariables = createFilterVariables
export type CreatedBookmark = createFilter_createFilter
