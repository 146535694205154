import { gql } from "apollo-boost"
import { updateTown, updateTownVariables } from "../generated/updateTown"

export const UPDATE_TOWN_MUTATION = gql`
  mutation updateTown($id: ID!, $town: TownInputUpdate!) {
    updateTown(id: $id, town: $town) {
      name
    }
  }
`

export type UpdateTownResult = updateTown
export type UpdateTownVariables = updateTownVariables
