/*
    Hook which provides all filter functionalities
*/

import React, { createContext, useContext, useEffect, useState } from "react"
import { useQuery } from "react-apollo"
import { AuthContext } from "./AuthContext"
import { REPLACEMENT_PLACEHOLDERS, TextReplacementPlaceholder } from "../api/graphql/query/replacement_placeholders"
import { ReplacementPlaceholders } from "../api/graphql/generated/ReplacementPlaceholders"

export interface ITextReplacementContext {
  placeholders: TextReplacementPlaceholder[]
}

export const TextReplacementContext = createContext<ITextReplacementContext>({
  placeholders: [],
})

interface ITextReplacementContextProvider {
  children: JSX.Element[] | JSX.Element | null
}

export const TextReplacementContextProvider = (props: ITextReplacementContextProvider) => {
  const { data, refetch } = useQuery<ReplacementPlaceholders>(REPLACEMENT_PLACEHOLDERS, {
    onCompleted: (data) => setPlaceholders(data?.replacementInfo?.placeholders ?? []),
  })
  const [placeholders, setPlaceholders] = useState<TextReplacementPlaceholder[]>(
    data?.replacementInfo?.placeholders ?? [],
  )
  const { authenticated } = useContext(AuthContext)

  useEffect(() => {
    if (authenticated === true) {
      refetch().then()
    } else {
      setPlaceholders([])
    }
  }, [authenticated, refetch])

  return <TextReplacementContext.Provider value={{ placeholders }}>{props.children}</TextReplacementContext.Provider>
}
