import React, { FunctionComponent, useContext } from "react"
import { Theme, makeStyles, Card, IconButton, Grid, Select, MenuItem } from "@material-ui/core"
import { useTranslation } from "react-i18next"
import { Clear } from "@material-ui/icons"
import { TextReplacementContext } from "../../../../context/TextReplacementContext"
import { Rating } from "../../../../api/graphql/generated/globalTypes"
import lodash from "lodash"

const useStyles = makeStyles((theme: Theme) => ({
  placeholderItem: {
    background: theme.palette.secondary.light,
    color: "white",
    padding: theme.spacing(1),
  },
}))

interface IEditorItemPlaceholderProps {
  value: string
  rating: Rating
  onChange: (value: string) => void
  onRemove: () => void
}

export const EditorItemPlaceholder: FunctionComponent<IEditorItemPlaceholderProps> = (props) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { value, rating, onRemove, onChange } = props
  const { placeholders } = useContext(TextReplacementContext)

  return (
    <Card className={classes.placeholderItem}>
      <Grid container direction={"row"} justify={"center"} alignItems={"center"}>
        <Select
          displayEmpty
          value={value !== "" ? value : "-"}
          onChange={(event) => onChange(event.target.value as string)}
        >
          <MenuItem key={""} value={"-"} disabled>
            {t("text_management.placeholder")}
          </MenuItem>
          {(placeholders ?? [])
            .filter(
              (p) =>
                lodash.isNil(p.ratingRestriction) ||
                lodash.isEmpty(p.ratingRestriction) ||
                p.ratingRestriction?.includes(rating),
            )
            .map((placeholder) => (
              <MenuItem key={placeholder.mark} value={placeholder.mark}>
                {t(`text_placeholder.${placeholder.key}`)}
              </MenuItem>
            ))}
        </Select>
        <IconButton size={"small"} onClick={onRemove}>
          <Clear />
        </IconButton>
      </Grid>
    </Card>
  )
}
