import React, { FunctionComponent } from "react"
import {
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  Theme
} from "@material-ui/core"
import { Bookmark } from "../../../../api/graphql/query/bookmarks-for-user"
import { Bookmark as BookmarkIcon } from "@material-ui/icons"

const useStyles = makeStyles((theme: Theme) => ({
  listItem: {
    fontSize: 15
  }
}))

interface IBookmarkListItemProps {
  filter: Bookmark
  onClick: (bookmark: Bookmark) => void
  selected: boolean
}

export const BookmarkListItem: FunctionComponent<
  IBookmarkListItemProps
> = props => {
  const classes = useStyles()
  const { filter, onClick, selected } = props
  return (
    <ListItem button onClick={() => onClick(filter)} selected={selected}>
      <ListItemIcon>
        <BookmarkIcon />
      </ListItemIcon>
      <ListItemText
        className={classes.listItem}
        classes={{ primary: classes.listItem }}
      >
        {filter.name || ""}
      </ListItemText>
    </ListItem>
  )
}
