import { gql } from "apollo-boost"
import {
  defaultFractionMappingForTown,
  defaultFractionMappingForTownVariables,
  defaultFractionMappingForTown_defaultFractionMappingForTown,
} from "../generated/defaultFractionMappingForTown"

export const DEFAULT_FRACTION_MAPPING_FOR_TOWN = gql`
  query defaultFractionMappingForTown($associationId: ID!, $townId: ID) {
    defaultFractionMappingForTown(associationId: $associationId, townId: $townId) {
      tourType
      mappedFractions {
        fraction
        mappedMaterialType
      }
    }
  }
`

export type DefaultFractionMappingForTownQueryResult = defaultFractionMappingForTown
export type DefaultFractionMappingForTownQueryVariables = defaultFractionMappingForTownVariables
export type DefaultFractionMappingForTown = defaultFractionMappingForTown_defaultFractionMappingForTown
