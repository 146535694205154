import React, { FunctionComponent, useEffect, useRef } from "react"
import { useTranslation } from "react-i18next"
import lodash from "lodash"
// @material-ui/core components
import { Cell, Legend, Pie, PieChart, ResponsiveContainer, Tooltip } from "recharts"
// @ts-ignore
import { TourTypeInfo } from "../../../domain/models/TourTypes"
import { createEvaluationsPerFraction, createEvaluationsPerType } from "../../../constants/DataManipulators"
// @ts-ignore
import domToImage from "dom-to-image-more"
import { Evaluation } from "../../../api/graphql/fragments/evaluation_fragments"
import { FractionType } from "../../../constants/Constants"
import { renderPieChartLabel } from "../../../utils/pie-chart"
import { LabelSetMaterialType } from "../../../api/graphql/generated/globalTypes"

// @Styles
// const useStyles = makeStyles((theme: Theme) => ({}))

interface IWertstoffAnalysisPieGraphProps {
  evaluation: Evaluation
  compact: boolean
  tourTypeInfo: TourTypeInfo
  legend?: boolean
  onExportCreated?: (fileUri: string, extension: string) => void
}

export const WertstoffAnalysisPieGraph: FunctionComponent<IWertstoffAnalysisPieGraphProps> = (props) => {
  // const classes = useStyles()
  const { t } = useTranslation()
  const { legend, tourTypeInfo, compact, evaluation, onExportCreated } = props
  const onPieEnter = undefined
  let currentChartRef = useRef(null)

  useEffect(() => {
    if (!lodash.isNil(onExportCreated)) {
      setTimeout(function () {
        exportChart()
      }, 200)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  function exportChart() {
    console.log("exportChart started")
    if (!lodash.isFunction(onExportCreated)) {
      return
    }

    let foundNode = window.ReactDOM.findDOMNode(currentChartRef.current)
    if (lodash.isNil(foundNode)) {
      return
    }

    domToImage.toPng(foundNode, { scale: 10 }).then(function (imageBase64: string) {
      if (!lodash.isNil(onExportCreated)) {
        // console.log("exportChart created", imageBase64)
        onExportCreated(imageBase64, ".png")
      }
    })
  }

  interface PieData {
    value: number
    name: string
    color: string
  }

  let pieData: PieData[]

  if (compact) {
    pieData = createEvaluationsPerType(evaluation, tourTypeInfo, [FractionType.MAIN, FractionType.MISSTHROW]).map(
      (entry) => {
        return {
          value: entry.value * 100,
          name: t(entry.fractionType.name),
          color: entry.fractionType.color,
        }
      },
    )
  } else {
    pieData = lodash.compact(
      createEvaluationsPerFraction(evaluation, [LabelSetMaterialType.missthrow]).map((entry) => {
        if (entry.fraction.id === tourTypeInfo.fraction.id) return null
        return {
          value: entry.value * 100,
          name: t(entry.fraction.name),
          color: entry.fraction.color,
        }
      }),
    )
  }

  return (
    <ResponsiveContainer width={320} maxHeight={"100%"} aspect={1 / 1}>
      <PieChart onMouseEnter={onPieEnter} ref={currentChartRef}>
        <Tooltip formatter={(value, name, props) => `${Number(value).toFixed(0)} %`} />
        {legend && (
          <Legend layout={"vertical"} verticalAlign={"middle"} align={"right"} iconType={"circle"} iconSize={10} />
        )}
        <Pie
          dataKey={"value"}
          key={"keyForPieData"}
          data={pieData}
          labelLine={false}
          label={renderPieChartLabel}
          outerRadius={"80%"}
          fill="#8884d8"
          isAnimationActive={false}
        >
          {pieData.map((entry, index) => (
            <Cell key={index} fill={entry.color} />
          ))}
        </Pie>
      </PieChart>
    </ResponsiveContainer>
  )
}
