import React, { FunctionComponent, useState, useEffect, ChangeEvent, Fragment, useContext, useMemo } from "react"
import {
  Theme,
  makeStyles,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  DialogActions,
  Button,
  Fab,
  Checkbox,
  FormControlLabel,
  Typography,
  Divider,
  InputAdornment,
} from "@material-ui/core"
import { useTranslation } from "react-i18next"
import lodash from "lodash"
import {
  AbilityName,
  ClassifierForTourTypeInput,
  ExternalTownType,
  TownInput,
  TownInputUpdate,
} from "../../../api/graphql/generated/globalTypes"
import { useMutation, useQuery } from "react-apollo"
import { ClipLoader } from "react-spinners"
import { DeleteForever } from "@material-ui/icons"
import { TownForAssociation } from "../../../api/graphql/query/towns-for-association"
import { UserAndPermissionsAssociation } from "../../../api/graphql/query/permitted-associations-for-user"
import {
  CREATE_TOWNS_MUTATION,
  CreateTownsResult,
  CreateTownsVariables,
} from "../../../api/graphql/mutation/create-town"
import { DELETE_TOWN_MUTATION, DeleteTownResult, DeleteTownVariables } from "../../../api/graphql/mutation/delete-town"
import { UPDATE_TOWN_MUTATION, UpdateTownResult, UpdateTownVariables } from "../../../api/graphql/mutation/update-town"
import { RatingSchemaControl } from "./rating-schema/RatingSchemaControl"
import { AuthContext } from "../../../context/AuthContext"
import { TownBoundaryAutocomplete } from "./town-boundaries/TownBoundaryAutocomplete"
import { TownBoundarySearchResult } from "../../../api/graphql/query/townBoundarySearch"
import { ExternalTownSelect } from "./ExternalTownSelect"
import { ExternalTown } from "../../../api/graphql/query/external-town-search"
import { convertValueToTextfieldType } from "../../../utils/convert-value-to-textfield-type"
import { TourTypeClassifier } from "./TourTypeClassifier"
import {
  DefaultClassifiersForAssociationQueryResult,
  DefaultClassifiersForAssociationQueryVariables,
  DEFAULT_CLASSIFIERS_FOR_ASSOCIATION_QUERY,
} from "../../../api/graphql/query/defaultClassifiersForAssociation"
import { AdminBadgeDivider } from "../badge/AdminBadgeDivider"
import { TownFractionMappingDialog } from "../fraction-mapping/TownFractionMappingDialog"
import { config } from "../../../utils/config"

const useStyles = makeStyles((theme: Theme) => ({
  dialogRoot: {
    minHeight: "200px",
    minWidth: "360px",
  },
  textFieldContainer: {
    margin: 0,
  },
  daheimBadge: {
    textAlign: "center",
  },
  adminInfo: {
    fontSize: 14,
    color: theme.palette.secondary.main,
  },
  dialogContent: {
    marginBottom: theme.spacing(2),
  },
}))

interface IManageTownDialogProps {
  town: TownForAssociation | undefined
  association: UserAndPermissionsAssociation | undefined
  isOpen: boolean
  onCloseDialog: () => void
  onSuccess: () => void
  initialData: ClassifierForTourTypeInput[]
}

export const ManageTownDialog: FunctionComponent<IManageTownDialogProps> = (props) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { isOpen, onCloseDialog, onSuccess, town, association, initialData } = props
  const associationId = association ? association.associationId : undefined
  const { hasOneRequiredAbility, isAdmin } = useContext(AuthContext)
  const [classifiers, setClassifiers] = useState<ClassifierForTourTypeInput[]>([])
  const [fractionMappingDialogOpen, setFractionMappingDialogOpen] = useState<boolean>(false)

  const { data } = useQuery<
    DefaultClassifiersForAssociationQueryResult,
    DefaultClassifiersForAssociationQueryVariables
  >(DEFAULT_CLASSIFIERS_FOR_ASSOCIATION_QUERY, {
    variables: {
      associationId,
    },
    fetchPolicy: "no-cache",
    skip: !isOpen || !associationId,
  })

  const defaultDocumentDeletionRangeInDays = useMemo(() => {
    if (association?.documentDeletionRangeInDays) {
      return `${association.documentDeletionRangeInDays}`
    }
    return config.REACT_APP_DEFAULT_DOCUMENT_DELETION_RANGE_IN_DAYS
  }, [association])

  useEffect(() => {
    setClassifiers(initialData)
  }, [initialData])

  const [createTownsMutation, { loading: createTownsMutationLoading }] = useMutation<
    CreateTownsResult,
    CreateTownsVariables
  >(CREATE_TOWNS_MUTATION, {
    onError: (error) => {
      console.log("error: ", error)
    },
    onCompleted: (data) => {
      console.log("create success: ", data)
      onSuccess()
    },
  })

  const [updateTownMutation, { loading: updateTownMutationLoading }] = useMutation<
    UpdateTownResult,
    UpdateTownVariables
  >(UPDATE_TOWN_MUTATION, {
    onError: (error) => {
      console.log("error: ", error)
    },
    onCompleted: (data) => {
      console.log("update success: ", data)
      onSuccess()
    },
  })

  const [deleteTownMutation, { loading: deleteTownMutationLoading }] = useMutation<
    DeleteTownResult,
    DeleteTownVariables
  >(DELETE_TOWN_MUTATION, {
    onError: (error) => {
      console.log("error: ", error)
    },
    onCompleted: (data) => {
      console.log("delete success: ", data)
      onSuccess()
    },
  })

  const isInCreateMode = lodash.isNil(town)
  const isInEditMode = !isInCreateMode

  // Priority of rating schema (1. town -> 2. association -> 3. fallback)
  const baseRatingSchema =
    town && town.ratingSchema
      ? town.ratingSchema
      : association && association.ratingSchema
      ? association.ratingSchema
      : {
          // if no specific RatingSchema is set: use 70% / 90% as Fallback
          // info: https://denovo-gmbh.atlassian.net/browse/WER-303
          residual: { percentageFrom: 0.7, percentageTo: 0.9 },
          organic: { percentageFrom: 0.7, percentageTo: 0.9 },
        }

  const townAsInput = (town
    ? {
        name: town.name,
        location: {
          latitude: town.latitude,
          longitude: town.longitude,
        },
        radius: town.radius,
        zipCode: town.zipCode,
        externalTown: town.externalTown
          ? { externalId: town.externalTown.externalId, type: town.externalTown.type }
          : null,
        associationId,
        defaultClassifiers: classifiers,
        documentDeletionRangeInDays:
          town.documentDeletionRangeInDays || config.REACT_APP_DEFAULT_DOCUMENT_DELETION_RANGE_IN_DAYS,
        displayOnlyAutomaticAnalysis: town.displayOnlyAutomaticAnalysis,
        fractionMapping: town.fractionMapping,
        ratingSchema: {
          residual: {
            percentageFrom: baseRatingSchema.residual.percentageFrom * 100,
            percentageTo: baseRatingSchema.residual.percentageTo * 100,
          },
          organic: {
            percentageFrom: baseRatingSchema.organic.percentageFrom * 100,
            percentageTo: baseRatingSchema.organic.percentageTo * 100,
          },
        },
      }
    : { associationId, radius: 5000 }) as TownInputUpdate

  const [modifiedTown, setModifiedTown] = useState<TownInputUpdate>(townAsInput)

  const [isValid, setIsValid] = useState<boolean>(false)
  const [showDeleteForever, setShowDeleteForever] = useState<boolean>(false)
  const [shouldBeCreatedAsExternalTown, setShouldBeCreatedAsExternalTown] = useState<boolean>(false)
  const [selectedTownBoundary, setSelectedTownBoundary] = useState<TownBoundarySearchResult | null>(
    town?.boundary || null,
  )
  const [isOnlyManuallyAnalysis, setIsOnlyManuallyAnalysis] = useState<boolean>(
    townAsInput?.displayOnlyAutomaticAnalysis !== undefined ? !townAsInput.displayOnlyAutomaticAnalysis : false,
  )
  const [isLinkedToTownBoundaries, setIsLinkedToTownBoundaries] = useState<boolean>(!!town?.boundary)
  const [showRatingSchema, setShowRatingSchema] = useState<boolean>(!lodash.isNil(lodash.get(town, "ratingSchema")))

  useEffect(() => {
    if (
      !modifiedTown.name ||
      !lodash.get(modifiedTown, "location.latitude") ||
      !lodash.get(modifiedTown, "location.longitude") ||
      !modifiedTown.radius ||
      (Number(lodash.get(modifiedTown, "documentDeletionRangeInDays", -1)) <= 0 && isInEditMode) ||
      (isLinkedToTownBoundaries && !selectedTownBoundary)
    ) {
      setIsValid(false)
    } else {
      setIsValid(true)
    }
  }, [modifiedTown, isInEditMode, isLinkedToTownBoundaries, selectedTownBoundary])

  const updateModifiedTown = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const tempModifiedTown = { ...modifiedTown }

    const { name, type, value } = event.target
    lodash.set(tempModifiedTown, name, convertValueToTextfieldType(value, type))
    setModifiedTown(tempModifiedTown)
  }

  const onCreateTown = async () => {
    if (isValid) {
      console.log("new Town to create: ", modifiedTown)
      await createTownsMutation({
        variables: {
          towns: [
            {
              ...modifiedTown,
              fractionMapping: (modifiedTown.fractionMapping || []).length > 0 ? modifiedTown.fractionMapping : null,
              defaultClassifiers: classifiers,
              townBoundaryId: isLinkedToTownBoundaries ? selectedTownBoundary?.id : null,
              displayOnlyAutomaticAnalysis: !isOnlyManuallyAnalysis,
            },
          ] as [TownInput],
        },
      })
    }
  }

  const onUpdateTown = async () => {
    if (isValid) {
      console.log("updating Town: ", modifiedTown)
      await updateTownMutation({
        variables: {
          id: lodash.get(town, "id", ""),
          town: {
            ...modifiedTown,
            fractionMapping: (modifiedTown.fractionMapping || []).length > 0 ? modifiedTown.fractionMapping : null,
            townBoundaryId: isLinkedToTownBoundaries ? selectedTownBoundary?.id : null,
            displayOnlyAutomaticAnalysis: !isOnlyManuallyAnalysis,
            defaultClassifiers: classifiers,
            documentDeletionRangeInDays: isAdmin ? Number(modifiedTown.documentDeletionRangeInDays) : undefined,
            ratingSchema:
              showRatingSchema && modifiedTown.ratingSchema
                ? {
                    residual: {
                      percentageFrom: modifiedTown.ratingSchema.residual.percentageFrom / 100,
                      percentageTo: modifiedTown.ratingSchema.residual.percentageTo / 100,
                    },
                    organic: {
                      percentageFrom: modifiedTown.ratingSchema.organic.percentageFrom / 100,
                      percentageTo: modifiedTown.ratingSchema.organic.percentageTo / 100,
                    },
                  }
                : null,
          },
        },
      })
    }
  }

  const onDeleteTown = async () => {
    console.log("deleting Town: ", modifiedTown)
    if (lodash.isString(lodash.get(town, "id"))) {
      await deleteTownMutation({
        variables: { id: lodash.get(town, "id", "") },
      })
    }
  }
  // dont allow deletion when town has tours
  const disableDelete = !!(town && town.tours && town.tours.length > 0)

  const onExternalTownChange = (externalTown: ExternalTown | null) => {
    console.log("CHANGE", externalTown)
    let tempModifiedTown: TownInputUpdate = {
      ...modifiedTown,
      externalTown: null,
    }

    if (externalTown) {
      tempModifiedTown = {
        ...modifiedTown,
        externalTown: {
          externalId: externalTown.externalId,
          type: externalTown.type,
        },
        name: String(externalTown.name),
        location: {
          latitude: externalTown.latitude || 0,
          longitude: externalTown.longitude || 0,
        },
      }
    }

    setModifiedTown(tempModifiedTown)
  }

  const onSetShouldBeCreatedAsDaheimTown = () => {
    if (shouldBeCreatedAsExternalTown) {
      setModifiedTown({ ...modifiedTown, externalTown: null })
    }
    setShouldBeCreatedAsExternalTown(!shouldBeCreatedAsExternalTown)
  }

  return (
    <>
      <TownFractionMappingDialog
        associationId={associationId}
        open={fractionMappingDialogOpen}
        onChange={(fractionMapping) => setModifiedTown({ ...modifiedTown, fractionMapping })}
        onClose={() => setFractionMappingDialogOpen(false)}
        fractionMapping={modifiedTown.fractionMapping}
        townId={town?.id}
      />
      <Dialog
        open={isOpen}
        fullWidth={false}
        className={classes.dialogRoot}
        classes={{ paper: classes.dialogRoot }}
        onClose={onCloseDialog}
      >
        <form style={{ display: "contents" }}>
          <DialogTitle>
            <Grid container justify="space-between" alignContent="center">
              <Grid item>
                {isInCreateMode
                  ? t("association_management.town_dialog.create.title")
                  : t("association_management.town_dialog.update.title")}
              </Grid>
              <Grid item>
                {!!modifiedTown.externalTown?.type && (
                  <Typography className={classes.daheimBadge} color="primary">
                    {`${t(`external_town_type.${modifiedTown.externalTown.type}`)} ${t("general.town")}`}
                  </Typography>
                )}
              </Grid>
            </Grid>
          </DialogTitle>
          <DialogContent className={classes.dialogContent}>
            <Grid container spacing={2}>
              <Grid item xs={12} className={classes.textFieldContainer}>
                <TextField
                  name={"name"}
                  label={t("town.property.name")}
                  value={lodash.get(modifiedTown, "name", "") || ""}
                  onChange={updateModifiedTown}
                  fullWidth
                  disabled={!!(modifiedTown.externalTown?.type || shouldBeCreatedAsExternalTown)}
                />
              </Grid>
              <Grid item xs={4} className={classes.textFieldContainer}>
                <TextField
                  name={"location.latitude"}
                  type="number"
                  label={t("town.property.latitude")}
                  value={lodash.get(modifiedTown, "location.latitude", "") || ""}
                  onChange={updateModifiedTown}
                  fullWidth
                  disabled={!!(modifiedTown.externalTown?.type === ExternalTownType.daheim)}
                />
              </Grid>
              <Grid item xs={4} className={classes.textFieldContainer}>
                <TextField
                  name={"location.longitude"}
                  type="number"
                  label={t("town.property.longitude")}
                  value={lodash.get(modifiedTown, "location.longitude", "") || ""}
                  onChange={updateModifiedTown}
                  fullWidth
                  disabled={!!(modifiedTown.externalTown?.type === ExternalTownType.daheim)}
                />
              </Grid>
              <Grid item xs={4} className={classes.textFieldContainer}>
                <TextField
                  name={"radius"}
                  type="number"
                  label={t("town.property.radius")}
                  value={lodash.get(modifiedTown, "radius", "") || ""}
                  onChange={updateModifiedTown}
                  fullWidth
                />
              </Grid>
              {isAdmin() && (
                <>
                  <Grid item xs={12} className={classes.textFieldContainer}>
                    <AdminBadgeDivider />
                  </Grid>

                  <TourTypeClassifier
                    setClassifiers={setClassifiers}
                    initialClassifiers={initialData}
                    defaultClassifiers={data?.defaultClassifiersForAssociation ?? []}
                  />
                  <Grid item xs={6} className={classes.textFieldContainer}>
                    <TextField
                      name={"documentDeletionRangeInDays"}
                      type="number"
                      label={t("association.property.document_deletion_range_in_days")}
                      placeholder={defaultDocumentDeletionRangeInDays}
                      value={lodash.get(modifiedTown, "documentDeletionRangeInDays")}
                      onChange={updateModifiedTown}
                      fullWidth
                      InputProps={{
                        endAdornment: <InputAdornment position="end">{t("general.day", { count: 2 })}</InputAdornment>,
                      }}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    className={classes.textFieldContainer}
                    container
                    justify="center"
                    alignContent="center"
                  >
                    <Button onClick={() => setFractionMappingDialogOpen(true)} color="primary" variant="contained">
                      {t("fraction_assignment.assign_fractions")}
                    </Button>
                  </Grid>
                </>
              )}
              {hasOneRequiredAbility([AbilityName.town_boundaries]) && (
                <>
                  <Grid item xs={12} className={classes.textFieldContainer}>
                    <Divider />
                  </Grid>
                  <Grid item xs={12} className={classes.textFieldContainer}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          color="primary"
                          checked={isLinkedToTownBoundaries}
                          onChange={() => setIsLinkedToTownBoundaries(!isLinkedToTownBoundaries)}
                        />
                      }
                      label={t("association_management.town_dialog.link_with_town_boundaries")}
                    />
                    <Typography className={classes.adminInfo}>
                      {t("association_management.town_dialog.link_with_town_boundaries_info")}
                    </Typography>
                  </Grid>
                  {isLinkedToTownBoundaries && (
                    <Grid item xs={12}>
                      <TownBoundaryAutocomplete
                        selectedTownBoundary={selectedTownBoundary}
                        setSelectedTownBoundary={setSelectedTownBoundary}
                      />
                    </Grid>
                  )}
                </>
              )}

              <Grid item xs={12} className={classes.textFieldContainer}>
                <Divider />
              </Grid>
              {hasOneRequiredAbility([AbilityName.admin]) && (
                <Grid item xs={12} className={classes.textFieldContainer}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        color="primary"
                        checked={isOnlyManuallyAnalysis}
                        onChange={() => setIsOnlyManuallyAnalysis(!isOnlyManuallyAnalysis)}
                      />
                    }
                    label={t("association_management.town_dialog.only_manually_analysis")}
                  />

                  <Typography className={classes.adminInfo}>
                    {t("association_management.town_dialog.label_info")}
                  </Typography>
                </Grid>
              )}
              {hasOneRequiredAbility([AbilityName.admin]) && (
                <Grid item xs={12} className={classes.textFieldContainer}>
                  <Divider />
                </Grid>
              )}
              {isInEditMode && (
                <Fragment>
                  <Grid item xs={12} className={classes.textFieldContainer}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          color="primary"
                          checked={showRatingSchema}
                          onChange={() => setShowRatingSchema(!showRatingSchema)}
                        />
                      }
                      label={t("association_management.enable_custom_rating_schema")}
                    />
                  </Grid>
                  {showRatingSchema && modifiedTown.ratingSchema && (
                    <Grid item xs={12} className={classes.textFieldContainer}>
                      <RatingSchemaControl
                        ratingSchema={modifiedTown.ratingSchema}
                        onChange={(newRatingSchema) =>
                          setModifiedTown({
                            ...modifiedTown,
                            ratingSchema: newRatingSchema,
                          })
                        }
                      />
                    </Grid>
                  )}
                </Fragment>
              )}
              {(isInCreateMode || !town?.externalTown) && (
                <Fragment>
                  <Grid item xs={12} className={classes.textFieldContainer}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          color="primary"
                          checked={shouldBeCreatedAsExternalTown}
                          onChange={onSetShouldBeCreatedAsDaheimTown}
                        />
                      }
                      label={t("association_management.town_dialog.create_as_external_town")}
                    />
                  </Grid>
                  {shouldBeCreatedAsExternalTown && (
                    <Grid item xs={12} className={classes.textFieldContainer}>
                      <ExternalTownSelect onChange={onExternalTownChange} />
                    </Grid>
                  )}
                </Fragment>
              )}
            </Grid>
          </DialogContent>
          <DialogActions>
            {isInEditMode && showDeleteForever && (
              <Button
                color="primary"
                variant={"contained"}
                onClick={() => onDeleteTown()}
                disabled={disableDelete || !lodash.isString(lodash.get(town, "id")) || deleteTownMutationLoading}
              >
                {t("association_management.town_dialog.delete.button")}
              </Button>
            )}
            <Button
              color="primary"
              onClick={() => (showDeleteForever ? setShowDeleteForever(false) : onCloseDialog())}
              disabled={deleteTownMutationLoading || createTownsMutationLoading || updateTownMutationLoading}
            >
              {t("general.cancel")}
            </Button>
            {isInCreateMode && (
              <Button
                color="primary"
                variant={"contained"}
                onClick={() => onCreateTown()}
                disabled={!isValid || createTownsMutationLoading}
              >
                {createTownsMutationLoading ? (
                  <ClipLoader color={"white"} size={17} />
                ) : (
                  t("association_management.town_dialog.create.button")
                )}
              </Button>
            )}
            {isInEditMode && !showDeleteForever && (
              <Button
                color="primary"
                variant={"contained"}
                onClick={() => onUpdateTown()}
                disabled={!isValid || updateTownMutationLoading}
              >
                {t("association_management.town_dialog.update.button")}
              </Button>
            )}
            {isInEditMode && !showDeleteForever && (
              <Fab
                color={"primary"}
                size={"small"}
                onClick={() => setShowDeleteForever(true)}
                disabled={disableDelete || updateTownMutationLoading}
              >
                <DeleteForever />
              </Fab>
            )}
          </DialogActions>
        </form>
      </Dialog>
    </>
  )
}
