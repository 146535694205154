import { gql } from "apollo-boost"

export const FETCH_ADVANCEMENT_PDF = gql`
  query FetchAdvancementPDF($id: ID!) {
    advancementPDF(id: $id) {
      status
      document {
        id
        title
        url
      }
    }
  }
`
