// validateTours
import { gql } from "apollo-boost"
import {
  importRfidReadings,
  importRfidReadingsVariables,
  importRfidReadings_importRfidReadings,
} from "../generated/importRfidReadings"

export const IMPORT_RFID_READINGS_MUTATION = gql`
  mutation importRfidReadings($townId: ID!, $file: Upload!, $ignoreWarnings: Boolean!) {
    importRfidReadings(townId: $townId, file: $file, ignoreWarnings: $ignoreWarnings) {
      importDone
      errors {
        id
        type
        line
        additionalData
      }
      warnings {
        id
        type
        line
        additionalData
      }
    }
  }
`

export type ImportRfidReadingsVariables = importRfidReadingsVariables
export type ImportRfidReadingsResult = importRfidReadings
export type ImportRfidReadingsValidationResult = importRfidReadings_importRfidReadings
