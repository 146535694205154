import React, { Fragment, FunctionComponent, useContext } from "react"
import { Theme, makeStyles, Dialog, DialogTitle, DialogContent, Grid, TextField, Divider } from "@material-ui/core"
import { useTranslation } from "react-i18next"
import { AbilityName } from "../../../../api/graphql/generated/globalTypes"
import { Autocomplete } from "@material-ui/lab"
import { ManageUserTabs } from "./ManageUserTabs"
import { ManageUserDialogActions } from "./ManageUserDialogActions"
import { ManageUserContext } from "./context/ManageUserContext"
import { ManageUserDialogResetPassword } from "./ManageUserDialogResetPassword"

const useStyles = makeStyles((theme: Theme) => ({
  dialogRoot: {
    minHeight: "200px",
    minWidth: "360px",
  },
  textFieldContainer: {
    margin: 0,
  },
  subheading: {
    fontSize: 18,
  },
  associationAndTownsSection: {},
  passwordInfoText: {
    fontSize: 14,
  },
  linkResetPassword: {
    cursor: "pointer",
  },
  progress: {
    display: "block",
  },
  passwordInfo: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}))

interface IManageUserDialogProps {
  isOpen: boolean
  onCloseDialog: () => void
}

export const ManageUserDialog: FunctionComponent<IManageUserDialogProps> = (props) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { isOpen, onCloseDialog } = props

  const { email, setEmail, abilities, updateAbilities, isInCreateMode, isInEditMode } = useContext(ManageUserContext)

  const abilityOptions = Object.values(AbilityName).filter((ability) => !abilities.includes(ability))

  return (
    <Dialog
      open={isOpen}
      fullWidth
      maxWidth="sm"
      className={classes.dialogRoot}
      classes={{ paper: classes.dialogRoot }}
      onClose={onCloseDialog}
    >
      <form style={{ display: "contents" }}>
        <DialogTitle>
          {isInCreateMode ? t("user_management.dialog.create.title") : t("user_management.dialog.update.title")}
        </DialogTitle>

        <DialogContent>
          <Grid container>
            <Grid item xs={12} className={classes.textFieldContainer}>
              <TextField
                type={"email"}
                name={"user.email"}
                label={t("user.property.email")}
                value={email}
                onChange={(event) => setEmail(event.target.value)}
                fullWidth
                disabled={isInEditMode}
              />
            </Grid>
            <Grid item xs={12} className={classes.textFieldContainer}>
              <Autocomplete
                multiple
                options={abilityOptions}
                value={abilities}
                onChange={(event, newValue) => updateAbilities(newValue)}
                getOptionLabel={(ability) => t(`user_management.ability.${ability}`)}
                renderInput={(params) => (
                  <TextField
                    name="abilities"
                    {...params}
                    variant="standard"
                    label={t("user.property.abilities")}
                    margin="normal"
                    fullWidth
                  />
                )}
              />
            </Grid>
            {isInEditMode && (
              <Fragment>
                <Grid item xs={12} className={classes.divider}>
                  <Divider />
                </Grid>
                <ManageUserDialogResetPassword />
              </Fragment>
            )}
            <Grid item xs={12} className={classes.divider}>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <ManageUserTabs />
            </Grid>
          </Grid>
        </DialogContent>
        <ManageUserDialogActions onCloseDialog={onCloseDialog} />
      </form>
    </Dialog>
  )
}
