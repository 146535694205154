import { gql } from "apollo-boost"
import { FRAGMENT_EVALUATION_FULL } from "../fragments/evaluation_fragments"

export const ANALYTICS_FOR_FILTER_QUERY = gql`
  query AnalyticsForFilter($filterId: ID!) {
    materialAnalysisForFilter(filterId: $filterId) {
      materialAnalysis {
        id
        association {
          id
          name
        }
        town {
          id
          name
        }
        tour {
          id
          name
        }
        tourType
        metadata {
          recordedAt
          analysedAt
          wasteScannerId
          location {
            latitude
            longitude
            formattedAddress
          }
          automatic
          descriptionLabel
          file
        }
        rfidData {
          rfidIds
        }
        calculatedDetailed {
          rating
          percentOfCategory {
            main
            missThrow
          }
          pixelCountDetailed {
            id
            value
            name
            color
            materialType
          }
        }
        calculated {
          rating
        }
        invalidity {
          isInvalid
          invalidReason
          invalidType
        }

        createdAt
        updatedAt
        deletedAt
      }
      evaluation {
        ...FragmentEvaluationFull
      }
    }
  }
  ${FRAGMENT_EVALUATION_FULL}
`
