import React, { FunctionComponent, Fragment, useState, useMemo, useContext } from "react"
import { Theme, makeStyles, DialogActions, Button, Fab, Grid } from "@material-ui/core"
import { useTranslation } from "react-i18next"
import { ClipLoader } from "react-spinners"
import lodash from "lodash"
import { Tour } from "../../../api/graphql/query/tours"
import { AuthContext } from "../../../context/AuthContext"
import { AbilityName, TourInput } from "../../../api/graphql/generated/globalTypes"
import { toast } from "react-toastify"
import { OperationVariables } from "apollo-client"
import { QueryLazyOptions } from "@apollo/react-hooks"
import { DeleteForever } from "@material-ui/icons"
import { DeleteTourDataButton } from "./DeleteTourDataButton"

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    marginLeft: theme.spacing(1),
  },
}))

interface IManageTourDialogActionsProps {
  isInCreateMode: boolean
  onDeleteTour: () => void
  tour?: Tour
  modifiedTour: TourInput
  deleteTourMutationLoading: boolean
  updateTourMutationLoading: boolean
  createToursMutationLoading: boolean
  isValid: boolean
  onClose: () => void
  validateTour: (options?: QueryLazyOptions<OperationVariables>) => void
}

export const ManageTourDialogActions: FunctionComponent<IManageTourDialogActionsProps> = (props) => {
  const {
    isInCreateMode,
    onDeleteTour,
    tour,
    deleteTourMutationLoading,
    updateTourMutationLoading,
    createToursMutationLoading,
    onClose,
    isValid,
    modifiedTour,
    validateTour,
  } = props
  const isInEditMode = useMemo(() => !isInCreateMode, [isInCreateMode])
  const classes = useStyles()
  const { t } = useTranslation()
  const { hasOneRequiredAbility } = useContext(AuthContext)
  const [showDeleteForever, setShowDeleteForever] = useState<boolean>(false)
  const isAllowedToDelete = useMemo(() => isInEditMode && tour?.materialAnalysisCount === 0, [isInEditMode, tour])
  const isAllowedToDeleteTourData: boolean = useMemo(
    () => !!tour && isInEditMode && hasOneRequiredAbility([AbilityName.admin]),
    [isInEditMode, hasOneRequiredAbility],
  )

  const onCreateTourClicked = async () => {
    if (isValid) {
      if (modifiedTour.tourStart.diff(modifiedTour.tourEnd, "minutes") < 0) {
        validateTour({
          variables: { tour: modifiedTour as TourInput },
        })
      } else {
        toast.error(t("errors.endtime_before_starttime"))
      }
    }
  }

  const onUpdateTourClicked = async () => {
    if (isValid) {
      if (modifiedTour.tourStart.diff(modifiedTour.tourEnd, "minutes") < 0) {
        validateTour({
          variables: {
            tour: {
              rfidReader: modifiedTour.rfidReader,
              tourEnd: modifiedTour.tourEnd,
              tourStart: modifiedTour.tourStart,
              tourType: modifiedTour.tourType,
              townIds: modifiedTour.townIds,
              vehicleId: modifiedTour.vehicleId,
              geoCeptImei: modifiedTour.geoCeptImei,
              includesSplittedImages: modifiedTour.includesSplittedImages,
              mappedClassification: modifiedTour.mappedClassification,
            } as TourInput,
            tourId: lodash.get(tour, "id", ""),
          },
        })
      } else {
        toast.error(t("errors.endtime_before_starttime"))
      }
    }
  }

  return (
    <Fragment>
      <DialogActions style={{ paddingBottom: 10, paddingRight: 10, minHeight: 40 }} key={"cancel"}>
        <Grid container justify="space-between" alignItems="center" spacing={1}>
          <Grid item>{isAllowedToDeleteTourData && <DeleteTourDataButton tour={tour!} />}</Grid>
          <Grid item>
            {isInEditMode && showDeleteForever && (
              <Button
                color="primary"
                variant={"contained"}
                onClick={() => onDeleteTour()}
                disabled={!lodash.isString(lodash.get(tour, "id")) || deleteTourMutationLoading}
                className={classes.button}
              >
                {t("tour.dialog.delete.button")}
              </Button>
            )}
            <Button
              color="primary"
              onClick={() => (showDeleteForever ? setShowDeleteForever(false) : onClose())}
              disabled={deleteTourMutationLoading || createToursMutationLoading || updateTourMutationLoading}
              className={classes.button}
            >
              {t("general.cancel")}
            </Button>
            {isInCreateMode && (
              <Button
                color="primary"
                variant={"contained"}
                onClick={() => onCreateTourClicked()}
                disabled={!isValid || createToursMutationLoading}
                className={classes.button}
              >
                {createToursMutationLoading ? <ClipLoader color={"white"} size={17} /> : t("tour.dialog.create.button")}
              </Button>
            )}
            {isInEditMode && !showDeleteForever && (
              <Button
                color="primary"
                variant={"contained"}
                onClick={() => onUpdateTourClicked()}
                disabled={!isValid || updateTourMutationLoading}
                className={classes.button}
              >
                {t("tour.dialog.update.button")}
              </Button>
            )}
            {isAllowedToDelete && !showDeleteForever && (
              <Fab
                color={"primary"}
                size={"small"}
                onClick={() => setShowDeleteForever(true)}
                disabled={updateTourMutationLoading}
                className={classes.button}
              >
                <DeleteForever />
              </Fab>
            )}
          </Grid>
        </Grid>
      </DialogActions>
    </Fragment>
  )
}
