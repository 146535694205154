import React, { FunctionComponent, Fragment, useMemo, useState, useContext } from "react"
import { useTranslation } from "react-i18next"
import { IGenericChannelTypeSectionProps } from "./ChannelTypeSectionTypes"
import { ChannelTypeSection, IChannelTypeAction } from "./ChannelTypeSection"
import { CommunicationChannelTypeDictionary } from "../../../../domain/models/CommunicationChannelTypes"
import { SendToChannelDialog } from "../dialogs/SendToChannelDialog"
import { TestSendToChannelDialog } from "../dialogs/TestSendToChannelDialog"
import { ChannelType } from "../../../../api/graphql/generated/globalTypes"
import { ReportDetailContext } from "../context/ReportDetailContext"

interface ISMSEmailChannelTypeSectionProps extends IGenericChannelTypeSectionProps {}

export const SMSEmailChannelTypeSection: FunctionComponent<ISMSEmailChannelTypeSectionProps> = (props) => {
  const { t } = useTranslation()
  const { refetchReportDetails } = useContext(ReportDetailContext)
  const { channelType, report, widgets } = props

  const [testSendDialogOpen, setTestSendDialogOpen] = useState<boolean>(false)
  const [sendDialogOpen, setSendDialogOpen] = useState<boolean>(false)

  const associationId = report.filter.associationId

  const actions: IChannelTypeAction[] = useMemo(
    () => [
      {
        name: `${t("general.test")} ${t(CommunicationChannelTypeDictionary[channelType].title)}`,
        onClick: () => setTestSendDialogOpen(true),
      },
      {
        name: `${t("general.send")}`,
        onClick: () => setSendDialogOpen(true),
      },
    ],
    [channelType, t],
  )

  return (
    <>
      {(channelType === ChannelType.email || channelType === ChannelType.sms) && (
        <>
          <SendToChannelDialog
            open={sendDialogOpen}
            onClose={() => setSendDialogOpen(false)}
            widgets={widgets}
            refetchReportDetails={refetchReportDetails}
            filter={report.filter}
            channelType={channelType}
            associationId={associationId}
          />
          <TestSendToChannelDialog
            open={testSendDialogOpen}
            onClose={() => setTestSendDialogOpen(false)}
            infoText={t(`${channelType}_dialog.test_info_texts`)}
            channelType={channelType}
            reportChannelData={widgets[0]}
          />
        </>
      )}
      <ChannelTypeSection widgets={widgets} channelType={channelType} report={report} actions={actions} />
    </>
  )
}
