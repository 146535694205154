import { gql } from "apollo-boost"
import { deleteTown, deleteTownVariables } from "../generated/deleteTown"

export const DELETE_TOWN_MUTATION = gql`
  mutation deleteTown($id: ID!) {
    deleteTown(id: $id) {
      error
      message
    }
  }
`

export type DeleteTownResult = deleteTown
export type DeleteTownVariables = deleteTownVariables
