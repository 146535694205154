import React, { FunctionComponent, Fragment } from "react"
import { Theme, makeStyles, Grid } from "@material-ui/core"
import { ImportInfo } from "./ImportInfo"
import { ImportInfoItem } from "./ImportInfoItem"
import { ImportInfoItemType } from "./ImportInfoItemType"

const useStyles = makeStyles((theme: Theme) => ({
  card: {
    width: "100%",
    maxHeight: 600,
    overflowY: "scroll"
  }
}))

interface IImportCheckResultProps {
  errors: ImportInfo[] | undefined
  warnings: ImportInfo[] | undefined
}

export const ImportCheckResult: FunctionComponent<
  IImportCheckResultProps
> = props => {
  const classes = useStyles()

  const renderErrors = () => {
    if (!props.errors) {
      return null
    }

    return props.errors.map((error, key) => (
      <ImportInfoItem key={key} item={error} type={ImportInfoItemType.ERROR} />
    ))
  }

  const renderWarnings = () => {
    if (!props.warnings) {
      return null
    }

    return props.warnings.map((warning, key) => (
      <ImportInfoItem
        key={key}
        item={warning}
        type={ImportInfoItemType.WARNING}
      />
    ))
  }

  return (
    <Fragment>
      <Grid className={classes.card}>
        {renderErrors()}
        {renderWarnings()}
      </Grid>
    </Fragment>
  )
}
