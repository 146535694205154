import React, { useState, useContext, createContext, useCallback, useEffect } from "react"
import { PermittedAssociationsContext } from "./PermittedAssociationsContext"

export interface ITextFilterContext {
  associationId: string
  townId: string | null
  fractionId: string | null
  setTownId: (value: string) => void
  setAssociation: (value: string) => void
  setFractionId: (value: string) => void
  resetFilter: () => void
}

export const TextFilterContext = createContext<ITextFilterContext>({} as ITextFilterContext)

export const TextFilterProvider = (props: any) => {
  const value = useTextFilterProvider()

  return <TextFilterContext.Provider value={value}>{props.children}</TextFilterContext.Provider>
}

export const useTextFilter = () => {
  return useContext(TextFilterContext)
}

const useTextFilterProvider = (): ITextFilterContext => {
  const { permittedAssociations } = useContext(PermittedAssociationsContext)

  const [associationId, setAssociationId] = useState<string>(permittedAssociations[0]?.associationId || "")
  const [townId, setTownId] = useState<string | null>(null)
  const [fractionId, setFractionId] = useState<string | null>("all")

  useEffect(() => {
    if (permittedAssociations[0] && !associationId) {
      setAssociationId(permittedAssociations[0].associationId)
    }
  }, [permittedAssociations, setAssociationId, associationId])

  const setAssociation = useCallback((id: string) => {
    setAssociationId(id)
    setTownId(null)
  }, [])

  const resetFilter = useCallback(() => {
    setTownId(null)
    setFractionId("all")
  }, [])

  return {
    associationId,
    setAssociation,
    townId,
    setTownId,
    fractionId,
    setFractionId,
    resetFilter,
  }
}
