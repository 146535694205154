import React from "react"
import Button from "@material-ui/core/Button"
import TextField from "@material-ui/core/TextField"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogContentText from "@material-ui/core/DialogContentText"
import CircularProgress from "@material-ui/core/CircularProgress"
import DialogTitle from "@material-ui/core/DialogTitle"
import { v4 } from "uuid"
import { withTranslation } from "react-i18next"

import SnackbarWrapper from "../snackbar/SnackbarWrapper"

class ReportCreateDialog extends React.Component {
  state = {
    key: `fd-${v4()}`,
    title: "",
  }

  handleClose = () => {
    if (this.props.onClose) this.props.onClose()

    this.setState({ title: "" })
  }

  handleCreate = () => {
    if (this.props.onCreate) this.props.onCreate(this.state.title)

    this.setState({ title: "" })
  }

  handleTitleChange = (event) => {
    this.setState({ title: event.target.value })
  }

  render() {
    const { t } = this.props

    return (
      <Dialog open={this.props.open} onClose={this.handleClose} aria-labelledby={this.state.key}>
        <DialogTitle id={this.state.key}>{t("report.create_report")}</DialogTitle>
        <DialogContent>
          {this.props.errorMessage && (
            <SnackbarWrapper
              variant="error"
              message={"Beim Erstellen trat ein Fehler auf!"}
              style={{ marginBottom: "12px" }}
            />
          )}
          <DialogContentText>{t("report.description_title")}</DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="title"
            label={t("report.title")}
            type="text"
            fullWidth
            onChange={this.handleTitleChange}
            value={this.state.title}
            disabled={this.props.inProgress}
          />
        </DialogContent>
        <DialogActions>
          <Button disabled={this.props.inProgress} onClick={this.handleClose} color="primary">
            {t("general.cancel")}
          </Button>
          <Button
            variant="contained"
            disabled={this.props.inProgress || !this.state.title || this.state.title.length < 5}
            onClick={this.handleCreate}
            color="primary"
          >
            {this.props.inProgress && <CircularProgress size={20} style={{ marginRight: "12px" }} />}
            {t("general.create")}
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

export default withTranslation()(ReportCreateDialog)
