import React, { FunctionComponent, Fragment } from "react"
import { Theme, makeStyles, Grid, Typography, Divider } from "@material-ui/core"
import { useTranslation } from "react-i18next"
import {
  TrendGraphOverallIndicator,
  TrendGraphMinMaxIndicator,
  TrendGraphTrendIndicator,
} from "./WertstoffAnalysisTrendGraphTooltip"
import { Town } from "../../../api/graphql/query/towns"
import { TrendComparisonGraphShapeDict } from "./WertstoffAnalysisTrendComparisonGraph"

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    width: 120,
  },
  typo: {
    color: (props: any) => props.color,
  },
  townTypo: {
    color: (props: any) => props.color,
    fontSize: 11,
  },
}))

export const WertstoffAnalysisTrendComparisonGraphLegend: FunctionComponent<any> = (props) => {
  const { color, showTrend, towns } = props
  const classes = useStyles({ color })
  const { t } = useTranslation()

  return (
    <Grid container spacing={1} alignContent="flex-start" className={classes.container}>
      <Grid item xs={2} container justify="center" alignContent="center">
        <TrendGraphOverallIndicator color={color} />
      </Grid>
      <Grid item xs={10}>
        <Typography className={classes.typo} align="left">
          {t("graph.trend.tooltip.overall")}
        </Typography>
      </Grid>
      <Grid item xs={2} container justify="center" alignContent="center">
        <TrendGraphMinMaxIndicator color={color} />
      </Grid>
      <Grid item xs={10}>
        <Typography className={classes.typo} align="left">
          {t("graph.trend.tooltip.max")}
        </Typography>
      </Grid>
      <Grid item xs={2} container justify="center" alignContent="center">
        <TrendGraphMinMaxIndicator color={color} />
      </Grid>
      <Grid item xs={10}>
        <Typography className={classes.typo} align="left">
          {t("graph.trend.tooltip.min")}
        </Typography>
      </Grid>
      {showTrend && (
        <>
          <Grid item xs={2} container justify="center" alignContent="center">
            <TrendGraphTrendIndicator color={color} />
          </Grid>
          <Grid item xs={10}>
            <Typography className={classes.typo} align="left">
              {t("graph.trend.tooltip.trend")}
            </Typography>
          </Grid>
        </>
      )}
      {!!towns && (
        <>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          {towns.map((town: Town, index: number) => (
            <Fragment key={town.name}>
              <Grid item xs={2} container justify="center" alignContent="center">
                {TrendComparisonGraphShapeDict[index].icon}
              </Grid>
              <Grid item xs={10}>
                <Typography
                  className={classes.typo}
                  style={{ color: TrendComparisonGraphShapeDict[index].color }}
                  align="left"
                >
                  {town.name}
                </Typography>
              </Grid>
            </Fragment>
          ))}
        </>
      )}
    </Grid>
  )
}
