import React, { FunctionComponent, Fragment } from "react"
import {
  Theme,
  makeStyles,
  Card,
  Typography,
  Grid,
  Divider
} from "@material-ui/core"
import {
  TourType,
  RatingSchemaInput
} from "../../../../api/graphql/generated/globalTypes"
import { RatingSlider } from "./RatingSlider"
import { TourTypeDictionary } from "../../../../domain/models/TourTypes"
import {
  RatingSchema,
  RatingSchemaPercentage
} from "../../../../api/graphql/query/associations"
import lodash from "lodash"
import { useTranslation } from "react-i18next"

const useStyles = makeStyles((theme: Theme) => ({
  card: {
    background: "white",
    padding: theme.spacing(1)
  },
  divider: {
    marginBottom: theme.spacing(1)
  }
}))

interface IRatingSchemaControlProps {
  ratingSchema: RatingSchema | RatingSchemaInput
  onChange: (ratingSchema: RatingSchema | RatingSchemaInput) => void
}

export const RatingSchemaControl: FunctionComponent<IRatingSchemaControlProps> = props => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { ratingSchema, onChange } = props

  const onRatingSchemaPercentageChange = (
    ratingSchemaPercentage: RatingSchemaPercentage,
    tourTypeKey: TourType
  ) => {
    const newRatingSchema = lodash.cloneDeep(ratingSchema)
    lodash.set(newRatingSchema, tourTypeKey, ratingSchemaPercentage)
    onChange(newRatingSchema)
  }

  const RatingSchemaSliderControl = (props: { tourTypeKey: string }) => {
    const { tourTypeKey } = props
    const tourTypeDict = TourTypeDictionary.find(
      tourType => tourType.type === tourTypeKey
    )
    const tourType = lodash.get(tourTypeDict, "type", TourType.residual)

    return (
      <Fragment>
        <Typography>
          {t(lodash.get(tourTypeDict, "title", ""))}
        </Typography>
        <Grid container justify={"center"}>
          <RatingSlider
            ratingSchemaPercentage={lodash.get(ratingSchema, tourTypeKey)}
            onChange={ratingSchemaPercentage =>
              onRatingSchemaPercentageChange(ratingSchemaPercentage, tourType)
            }
          />
        </Grid>
      </Fragment>
    )
  }

  return (
    <Card className={classes.card}>
      {Object.keys(TourType).map((tourTypeKey, index) => (
        <Fragment key={index}>
          <RatingSchemaSliderControl tourTypeKey={tourTypeKey} />
          {!(index === Object.keys(TourType).length - 1) && (
            <Divider className={classes.divider} />
          )}
        </Fragment>
      ))}
    </Card>
  )
}
