import React, { FunctionComponent, Fragment } from "react"
import {
  Theme,
  makeStyles,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  Typography,
  Divider,
  Grid,
} from "@material-ui/core"
import { useTranslation } from "react-i18next"
import { Vehicle } from "../../../../api/graphql/query/vehicles"
import { Clear, LocalShippingRounded } from "@material-ui/icons"

const useStyles = makeStyles((theme: Theme) => ({
  clearButton: {
    cursor: "pointer",
    color: theme.palette.secondary.dark,
  },
  listItem: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    alignItems: "flex-start",
  },
}))

interface IVehicleListItemProps {
  vehicle: Vehicle
  onRemove: (vehicle: Vehicle) => void
}

export const VehicleListItem: FunctionComponent<IVehicleListItemProps> = (props) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { vehicle, onRemove } = props
  return (
    <Fragment>
      <ListItem className={classes.listItem}>
        <ListItemAvatar>
          <Avatar>
            <LocalShippingRounded />
          </Avatar>
        </ListItemAvatar>
        <ListItemText>
          <Grid container direction="row">
            <Grid item xs>
              <Typography>{vehicle.wasteScannerId}</Typography>
            </Grid>
            <Grid item>
              <Grid container justify="flex-end">
                <Clear onClick={() => onRemove(vehicle)} className={classes.clearButton} />
              </Grid>
            </Grid>
          </Grid>
        </ListItemText>
      </ListItem>
      <Divider component="li" />
    </Fragment>
  )
}
