import React, { FunctionComponent, useState, ChangeEvent, useEffect } from "react"
import {
  Theme,
  makeStyles,
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  TextField,
  DialogActions,
  Button,
  Fab,
  InputAdornment,
  Divider,
} from "@material-ui/core"
import { useTranslation } from "react-i18next"
import lodash from "lodash"
import {
  CREATE_VEHICLE_MUTATION,
  CreateVehicleResult,
  CreateVehicleVariables,
} from "../../../api/graphql/mutation/create-vehicle"
import { useMutation } from "react-apollo"
import {
  UpdateVehicleResult,
  UpdateVehicleVariables,
  UPDATE_VEHICLE_MUTATION,
} from "../../../api/graphql/mutation/update-vehicle"
import { ClipLoader } from "react-spinners"
import { DeleteForever } from "@material-ui/icons"
import {
  DELETE_VEHICLE_MUTATION,
  DeleteVehicleVariables,
  DeleteVehicleResult,
} from "../../../api/graphql/mutation/delete-vehicle"
import { toast } from "react-toastify"
import { getGraphqlErrorCode } from "../../../utils/get-graphql-error-code"
import { Vehicle } from "../../../api/graphql/query/vehicles"

const useStyles = makeStyles((theme: Theme) => ({
  dialogRoot: {
    minHeight: "200px",
    minWidth: "360px",
  },
  subheading: {
    fontSize: 18,
  },
  associationAndTownsSection: {
    marginTop: theme.spacing(4),
  },
  passwordInfoText: {
    fontSize: 14,
  },
  linkResetPassword: {
    cursor: "pointer",
  },
  progress: {
    display: "block",
  },
  passwordInfo: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}))

const getVariables = (modified: Vehicle) => ({
  vehicleId: modified.id,
  wasteScannerId: modified.wasteScannerId,
  rfidReader: modified.rfidReader || null,
  geoCeptImei: modified.geoCeptImei || null,
  rfidReadingToMaterialAnalysisOffset:
    !lodash.isNil(modified?.rfidReadingToMaterialAnalysisOffset?.min) &&
    !lodash.isNil(modified?.rfidReadingToMaterialAnalysisOffset?.max)
      ? {
          max: Number(modified.rfidReadingToMaterialAnalysisOffset.max),
          min: Number(modified.rfidReadingToMaterialAnalysisOffset.min),
        }
      : null,
  geoCeptToMaterialAnalysisOffset:
    !lodash.isNil(modified?.geoCeptToMaterialAnalysisOffset?.min) &&
    !lodash.isNil(modified?.geoCeptToMaterialAnalysisOffset?.max)
      ? {
          max: Number(modified.geoCeptToMaterialAnalysisOffset.max),
          min: Number(modified.geoCeptToMaterialAnalysisOffset.min),
        }
      : null,
  imageLimit: modified.imageLimit || null,
})
interface IManageVehicleDialogProps {
  vehicle: Vehicle | null
  isOpen: boolean
  onCloseDialog: () => void
  onSuccess: () => void
}

export const ManageVehicleDialog: FunctionComponent<IManageVehicleDialogProps> = (props) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { isOpen, onCloseDialog, onSuccess, vehicle } = props

  const [createVehicleMutation, { loading: createVehicleMutationLoading }] = useMutation<
    CreateVehicleResult,
    CreateVehicleVariables
  >(CREATE_VEHICLE_MUTATION, {
    onError: (error) => {
      toast.error(
        t(`api_error.${getGraphqlErrorCode(error)}`, {
          entity: modified.wasteScannerId,
        }),
      )
    },
    onCompleted: (data) => {
      console.log("create success: ", data)
      onSuccess()
    },
  })

  const [updateVehicleMutation, { loading: updateVehicleMutationLoading }] = useMutation<
    UpdateVehicleResult,
    UpdateVehicleVariables
  >(UPDATE_VEHICLE_MUTATION, {
    onError: (error) => {
      toast.error(
        t(`api_error.${getGraphqlErrorCode(error)}`, {
          entity: modified.wasteScannerId,
        }),
      )
    },
    onCompleted: () => {
      onSuccess()
    },
    update: () => undefined,
  })

  const [deleteVehicleMutation, { loading: deleteVehicleMutationLoading }] = useMutation<
    DeleteVehicleResult,
    DeleteVehicleVariables
  >(DELETE_VEHICLE_MUTATION, {
    onError: (error) => {
      toast.error(
        t(`api_error.${getGraphqlErrorCode(error)}`, {
          entity: modified.wasteScannerId,
        }),
      )
    },
    onCompleted: () => {
      onSuccess()
    },
  })

  const isInCreateMode = lodash.isNil(vehicle)
  const isInEditMode = !isInCreateMode

  const vehicleAsInput = vehicle ? vehicle : ({} as Vehicle)
  const [modified, setModified] = useState<Vehicle>(vehicleAsInput)

  const [showDeleteForever, setShowDeleteForever] = useState<boolean>(false)
  const [isValid, setIsValid] = useState<boolean>(false)

  useEffect(() => {
    const validateInput = () => {
      if (
        !modified.wasteScannerId ||
        !validateOffset(
          modified?.rfidReadingToMaterialAnalysisOffset?.min,
          modified?.rfidReadingToMaterialAnalysisOffset?.max,
        ) ||
        !validateOffset(modified?.geoCeptToMaterialAnalysisOffset?.min, modified?.geoCeptToMaterialAnalysisOffset?.max)
      ) {
        setIsValid(false)
        return
      }
      setIsValid(true)
    }
    validateInput()
  }, [modified, setIsValid])

  const validateOffset = (min?: number, max?: number): boolean =>
    !(
      (lodash.isNil(max) && !lodash.isNil(min)) ||
      (lodash.isNil(min) && !lodash.isNil(max)) ||
      (!lodash.isNil(max) && !lodash.isNil(min) && max <= min)
    )

  const onCreateVehicle = async () => {
    if (isValid) {
      await createVehicleMutation({
        variables: getVariables(modified),
      })
    }
  }

  const onUpdateVehicle = async () => {
    if (isValid) {
      await updateVehicleMutation({
        variables: getVariables(modified),
      })
    }
  }

  const onDeleteVehicle = async () => {
    await deleteVehicleMutation({
      variables: { vehicleId: modified.id },
    })
  }

  const updateModified = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const tempModified = { ...modified }
    const { name, type, value } = event.target
    if (type === "number") {
      lodash.set(tempModified, name, value !== "" ? Number(value) : undefined)
    } else {
      lodash.set(tempModified, name, value)
    }
    setModified(tempModified)
  }

  return (
    <Dialog
      open={isOpen}
      fullWidth
      maxWidth="sm"
      className={classes.dialogRoot}
      classes={{ paper: classes.dialogRoot }}
      onClose={onCloseDialog}
    >
      <form style={{ display: "contents" }}>
        <DialogTitle>
          {isInCreateMode ? t("vehicle_management.dialog.create.title") : t("vehicle_management.dialog.update.title")}
        </DialogTitle>

        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField
                name={"wasteScannerId"}
                label={t("vehicle.property.wasteScannerId")}
                value={modified?.wasteScannerId || ""}
                onChange={updateModified}
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                name={"imageLimit"}
                label={t("vehicle.property.image_limit")}
                value={modified?.imageLimit}
                onChange={updateModified}
                type="number"
                InputProps={{ inputProps: { min: 1 } }}
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                name={"geoCeptImei"}
                label={t("vehicle.property.geocept_device")}
                value={modified?.geoCeptImei || ""}
                onChange={updateModified}
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                name={"rfidReader"}
                label={t("vehicle.property.rfidReader")}
                value={modified?.rfidReader || ""}
                onChange={updateModified}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={6}>
              <TextField
                name={"rfidReadingToMaterialAnalysisOffset.min"}
                label={t("vehicle.property.rfidReadingToMaterialAnalysisOffset.min")}
                value={
                  modified?.rfidReadingToMaterialAnalysisOffset?.min === undefined
                    ? ""
                    : modified?.rfidReadingToMaterialAnalysisOffset?.min
                }
                error={
                  !validateOffset(
                    modified?.rfidReadingToMaterialAnalysisOffset?.min,
                    modified?.rfidReadingToMaterialAnalysisOffset?.max,
                  )
                }
                onChange={updateModified}
                fullWidth
                type="number"
                InputProps={{
                  endAdornment: <InputAdornment position="end">s</InputAdornment>,
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                name={"rfidReadingToMaterialAnalysisOffset.max"}
                label={t("vehicle.property.rfidReadingToMaterialAnalysisOffset.max")}
                value={
                  modified?.rfidReadingToMaterialAnalysisOffset?.max === undefined
                    ? ""
                    : modified?.rfidReadingToMaterialAnalysisOffset?.max
                }
                error={
                  !validateOffset(
                    modified?.rfidReadingToMaterialAnalysisOffset?.min,
                    modified?.rfidReadingToMaterialAnalysisOffset?.max,
                  )
                }
                onChange={updateModified}
                fullWidth
                type="number"
                InputProps={{
                  endAdornment: <InputAdornment position="end">s</InputAdornment>,
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                name={"geoCeptToMaterialAnalysisOffset.min"}
                label={t("vehicle.property.geoCeptToMaterialAnalysisOffset.min")}
                value={
                  modified?.geoCeptToMaterialAnalysisOffset?.min === undefined
                    ? ""
                    : modified?.geoCeptToMaterialAnalysisOffset?.min
                }
                onChange={updateModified}
                error={
                  !validateOffset(
                    modified?.geoCeptToMaterialAnalysisOffset?.min,
                    modified?.geoCeptToMaterialAnalysisOffset?.max,
                  )
                }
                fullWidth
                type="number"
                InputProps={{
                  endAdornment: <InputAdornment position="end">s</InputAdornment>,
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                name={"geoCeptToMaterialAnalysisOffset.max"}
                label={t("vehicle.property.geoCeptToMaterialAnalysisOffset.max")}
                value={
                  modified?.geoCeptToMaterialAnalysisOffset?.max === undefined
                    ? ""
                    : modified?.geoCeptToMaterialAnalysisOffset?.max
                }
                error={
                  !validateOffset(
                    modified?.geoCeptToMaterialAnalysisOffset?.min,
                    modified?.geoCeptToMaterialAnalysisOffset?.max,
                  )
                }
                onChange={updateModified}
                fullWidth
                type="number"
                InputProps={{
                  endAdornment: <InputAdornment position="end">s</InputAdornment>,
                }}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          {isInEditMode && showDeleteForever && (
            <Button
              color="primary"
              variant={"contained"}
              onClick={onDeleteVehicle}
              disabled={!lodash.isString(modified?.id) || deleteVehicleMutationLoading}
            >
              {t("vehicle_management.dialog.delete.button")}
            </Button>
          )}
          <Button
            color="primary"
            onClick={() => (showDeleteForever ? setShowDeleteForever(false) : onCloseDialog())}
            disabled={deleteVehicleMutationLoading || createVehicleMutationLoading || updateVehicleMutationLoading}
          >
            {t("general.cancel")}
          </Button>
          {isInCreateMode && (
            <Button
              color="primary"
              variant={"contained"}
              onClick={onCreateVehicle}
              disabled={!isValid || createVehicleMutationLoading}
            >
              {createVehicleMutationLoading ? (
                <ClipLoader color={"white"} size={17} />
              ) : (
                t("vehicle_management.dialog.create.button")
              )}
            </Button>
          )}
          {isInEditMode && !showDeleteForever && (
            <Button
              color="primary"
              variant={"contained"}
              onClick={onUpdateVehicle}
              disabled={!isValid || updateVehicleMutationLoading}
            >
              {t("vehicle_management.dialog.update.button")}
            </Button>
          )}
          {isInEditMode && !showDeleteForever && (
            <Fab
              color={"primary"}
              size={"small"}
              onClick={() => setShowDeleteForever(true)}
              disabled={updateVehicleMutationLoading || Number(vehicle?.tourCount) > 0}
            >
              <DeleteForever />
            </Fab>
          )}
        </DialogActions>
      </form>
    </Dialog>
  )
}
