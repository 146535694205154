import React from "react"
import PropTypes from "prop-types"
import { withTranslation } from "react-i18next"
import lodash from "lodash"

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles"
import TextField from "@material-ui/core/TextField"
import MenuItem from "@material-ui/core/MenuItem"
import { Grid } from "@material-ui/core"
import { AdminBadge } from "../badge/AdminBadge"
// @Styles

const styles = (theme) => ({
  menu: {
    width: 200,
  },
  adminBadge: {
    marginLeft: theme.spacing(1),
  },
  labelWithBadge: {
    lineHeight: 1,
  },
})

class WertstoffDropdown extends React.Component {
  constructor(props) {
    super(props)
    this.props = props
  }

  componentWillReceiveProps(nextProps) {
    this.props = nextProps
  }

  handleChange = (key) => (event) => {
    this.props.onChange(event)
  }

  render() {
    const {
      classes,
      name,
      values,
      allTranslationKey,
      value,
      disabled,
      allPossible,
      error,
      customKey,
      multiple,
      translate,
      t,
      margin,
      showAdminBadge,
    } = this.props
    const key = name

    return (
      <TextField
        label={
          showAdminBadge ? (
            <Grid container justify="space-between" alignItems="center" alignContent="center">
              <Grid item xs className={classes.labelWithBadge}>
                {name}
              </Grid>
              <Grid item xs className={classes.adminBadge}>
                <AdminBadge disableFontSize />
              </Grid>
            </Grid>
          ) : (
            name
          )
        }
        value={lodash.isNil(value) ? "" : value}
        id={key}
        key={key}
        margin={margin}
        fullWidth
        select
        disabled={disabled}
        error={error}
        SelectProps={{
          multiple,
          color: "primary",
          MenuProps: {
            className: classes.menu,
          },
          onChange: this.handleChange(key),
        }}
      >
        {allPossible && (
          <MenuItem key={undefined} value={undefined}>
            {allTranslationKey ? t(allTranslationKey) : t("general.all").toUpperCase()}
          </MenuItem>
        )}
        {Object.values(values).map((option) => {
          const key = option.id
          const item = option.name
          if (!lodash.isBoolean(key) && (!key || !item)) {
            return null
          }

          return (
            <MenuItem key={key} value={option[customKey]}>
              {translate ? t(item) : item}
            </MenuItem>
          )
        })}
      </TextField>
    )
  }
}

WertstoffDropdown.propTypes = {
  name: PropTypes.string,
  margin: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, undefined]),
  values: PropTypes.array,
  onChange: PropTypes.func.isRequired,
  error: PropTypes.bool,
  disabled: PropTypes.bool,
  allPossible: PropTypes.bool,
  allTranslationKey: PropTypes.oneOfType([PropTypes.string, undefined]),
  customKey: PropTypes.string,
  customValueKey: PropTypes.string,
  translate: PropTypes.bool,
  showAdminBadge: PropTypes.bool,
}

WertstoffDropdown.defaultProps = {
  value: null,
  values: [],
  error: false,
  disabled: false,
  allPossible: true,
  allTranslationKey: undefined,
  customKey: "id",
  customValueKey: "id",
  translate: false,
  showAdminBadge: false,
  margin: "normal",
}

export default withStyles(styles)(withTranslation()(WertstoffDropdown))
