import { gql } from "apollo-boost"
import { FRAGMENT_EVALUATION_FULL } from "../fragments/evaluation_fragments"
import {
  materialAnalyisAndEvaluation,
  materialAnalyisAndEvaluationVariables,
  materialAnalyisAndEvaluation_materialAnalyisAndEvaluation,
  materialAnalyisAndEvaluation_materialAnalyisAndEvaluation_materialAnalysis,
} from "../generated/materialAnalyisAndEvaluation"

export const MATERIAL_AND_EVALUATION_QUERY = gql`
  query materialAnalyisAndEvaluation(
    $tourType: TourType
    $associationIds: [ID!]
    $displayOnlyAutomaticAnalysis: Boolean
    $townIds: [ID!]
    $tourIds: [ID!]
    $firstDate: DateTime
    $lastDate: DateTime
    $fractions: [FractionFilter!]
    $ratings: [Rating!]
    $disableMapping: Boolean
    $simplifyEvaluation: Boolean
    $source: MaterialAnalysisSource
    $rfids: [String!]
  ) {
    materialAnalyisAndEvaluation(
      tourType: $tourType
      associationIds: $associationIds
      displayOnlyAutomaticAnalysis: $displayOnlyAutomaticAnalysis
      townIds: $townIds
      tourIds: $tourIds
      firstDate: $firstDate
      lastDate: $lastDate
      fractions: $fractions
      ratings: $ratings
      disableMapping: $disableMapping
      simplifyEvaluation: $simplifyEvaluation
      source: $source
      rfids: $rfids
    ) {
      materialAnalysis {
        id
        invalidity {
          isInvalid
        }
        metadata {
          recordedAt
        }
        calculatedDetailed {
          pixelCountDetailed {
            id
            value
            materialType
          }
          percentOfCategory {
            main
            missThrow
          }
        }
      }
      evaluation {
        ...FragmentEvaluationFull
      }
      analysisAvailableForOtherRoles
    }
  }
  ${FRAGMENT_EVALUATION_FULL}
`
export type MaterialAnalysisAndEvaluationResult = materialAnalyisAndEvaluation
export type MaterialAnalysisAndEvaluationVariables = materialAnalyisAndEvaluationVariables
export type MaterialAnalysisAndEvaluationResponse = materialAnalyisAndEvaluation_materialAnalyisAndEvaluation
export type MaterialAnalysisForEvaluation = materialAnalyisAndEvaluation_materialAnalyisAndEvaluation_materialAnalysis
