import React, { FunctionComponent, Fragment, useState } from "react"
import {
  Theme,
  makeStyles,
  TextField,
  useTheme,
  List,
  ListItem,
  Typography,
  Grid,
  InputAdornment
} from "@material-ui/core"
import { useTranslation } from "react-i18next"
import { useQuery } from "react-apollo"
import {
  DaheimTownsResult,
  DAHEIM_TOWNS_QUERY,
  DaheimTown
} from "../../../api/graphql/query/daheim-towns"
import lodash from "lodash"
import { ClipLoader } from "react-spinners"
import { Search } from "@material-ui/icons"

const useStyles = makeStyles((theme: Theme) => ({
  list: {
    height: 300,
    overflowY: "scroll",
    width: "100%"
  }
}))

interface IDaheimTownSelectProps {
  daheimTown: DaheimTown | null
  setDaheimTown: (daheimTown: DaheimTown | null) => void
}

export const DaheimTownSelect: FunctionComponent<
  IDaheimTownSelectProps
> = props => {
  const classes = useStyles()
  const { t } = useTranslation()
  const theme = useTheme()
  const { daheimTown, setDaheimTown } = props

  const [searchTerm, setSearchTerm] = useState<string>("")

  const { data, error, loading } = useQuery<DaheimTownsResult>(
    DAHEIM_TOWNS_QUERY,
    { fetchPolicy: "network-only" }
  )

  if (error) {
    console.log(error)
  }

  const daheimTowns = (lodash.get(data, "daheimTowns", []) ||
    []) as DaheimTown[]

  const filteredDaheimTowns = daheimTowns.filter(town =>
    town.name.toLowerCase().includes(searchTerm.toLowerCase())
  )

  const onClick = (town: DaheimTown) => {
    setDaheimTown(town)
    setSearchTerm(town.name)
  }

  return (
    <Fragment>
      {!loading && daheimTowns && (
        <Fragment>
          <TextField
            fullWidth
            placeholder={t("general.search")}
            value={searchTerm}
            onChange={event => setSearchTerm(event.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              )
            }}
          />
          <List className={classes.list}>
            {filteredDaheimTowns.map(town => (
              <ListItem
                button
                key={town.id}
                onClick={() => onClick(town)}
                selected={daheimTown === town}
              >
                <Typography>{town.name}</Typography>
              </ListItem>
            ))}
          </List>
        </Fragment>
      )}
      {/* crushed dreams: */}
      {/* {!loading && daheimTowns && (
        <Autocomplete
          options={daheimTowns}
          getOptionLabel={(option: DaheimTown) => option.name}
          style={{ width: "100%" }}
          value={daheimTown}
          onChange={(event, newValue) => setDaheimTown(newValue)}
          renderInput={params => (
            <TextField {...params} label={t("general.daheim_town")} fullWidth />
          )}
        />
      )} */}
      {loading && (
        <Grid container justify="center">
          <ClipLoader color={theme.palette.primary.main} />
        </Grid>
      )}
    </Fragment>
  )
}
