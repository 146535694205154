import React, { FunctionComponent, Fragment } from "react"
import { makeStyles, Theme, Paper } from "@material-ui/core"
import { useTranslation } from "react-i18next"
import { TopBar } from "../partials/heading/TopBar"
import { StatisticsFilterProvider } from "../../context/StatisticsFilterContext"
import { StatisticsFilterComponent } from "../partials/statistics/StatisticsFilterComponent"
import { StatisticsPageContent } from "../partials/statistics/StatisticsPageContent"
import { StatisticsRefinementProvider } from "../../context/StatisticsRefinementContext"

const useStyles = makeStyles((theme: Theme) => ({
  controlCard: {
    padding: theme.spacing(2),
  },
  paper: {
    marginTop: 0,
    marginBottom: theme.spacing(1),
    padding: theme.spacing(2),
    justifyContent: "center",
    color: theme.palette.text.secondary,
    borderRadius: theme.spacing(0),
  },
}))

interface IStatisticsPageProps {}

export const StatisticsPage: FunctionComponent<IStatisticsPageProps> = (props) => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <Fragment>
      <StatisticsFilterProvider>
        <StatisticsRefinementProvider>
          {/* VIEW */}
          <TopBar>{t("statistics.heading")}</TopBar>
          <Paper elevation={2} className={classes.paper}>
            <StatisticsFilterComponent />
          </Paper>
          <StatisticsPageContent />
        </StatisticsRefinementProvider>
      </StatisticsFilterProvider>
    </Fragment>
  )
}
