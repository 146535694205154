import React, { Fragment, useState } from "react"
import store from "store"
import { makeStyles } from "@material-ui/core"
import InputLabel from "@material-ui/core/InputLabel"
import MenuItem from "@material-ui/core/MenuItem"
import FormControl from "@material-ui/core/FormControl"
import Select from "@material-ui/core/Select"
import { useTranslation } from "react-i18next"
import { useMutation } from "react-apollo"
import { AVAILABLE_LANGUAGES, changeLanguage } from "../../../utils/i18n"
import { UPDATE_LANGUAGE_MUTATION } from "../../../api/graphql/mutation/update-language"

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: "100%",
  },
}))

export const LanguageChanger = (props) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const [updateLanguageMutation] = useMutation(UPDATE_LANGUAGE_MUTATION)

  const [language, setLanguage] = useState(store.get("language"))

  const onChange = (ev) => {
    setLanguage(ev.target.value)
    changeLanguage(ev.target.value)
    updateLanguageMutation({ variables: { language: ev.target.value } })
  }

  return (
    <Fragment>
      <FormControl className={classes.formControl}>
        <InputLabel id="inputlabel-languages">{t("language")}</InputLabel>
        <Select labelId="select-languages" id="id-select-languages" value={language} onChange={onChange} fullWidth>
          {AVAILABLE_LANGUAGES.map((language) => (
            <MenuItem key={language} value={language}>
              {t(`languages.${language}`)}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Fragment>
  )
}
