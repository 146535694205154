import { gql } from "apollo-boost"
import { FileTemplates, FileTemplates_fileTemplates } from "../generated/FileTemplates"

export const FILE_TEMPLATES_QUERY = gql`
  query FileTemplates {
    fileTemplates {
      name
      url
    }
  }
`

export type FileTemplateQueryResult = FileTemplates
export type FileTemplate = FileTemplates_fileTemplates
