import React, { useEffect } from "react"
import { useLocation, useHistory } from "react-router-dom"
import qs from "query-string"
import lodash from "lodash"
import moment from "moment"
import { AnalysisFilterModel } from "../domain/models/AnalysisFilterModel"
import {
  bookmarksForUser_userAndPermissions_permittedAssociations_towns_filters,
  bookmarksForUser_userAndPermissions_permittedAssociations_filters,
  bookmarksForUser_userAndPermissions_permittedAssociations,
} from "../api/graphql/generated/bookmarksForUser"
import { TourType } from "../api/graphql/generated/globalTypes"
import { BookmarksForUserResult } from "../api/graphql/query/bookmarks-for-user"

type SelectedBookmarkRef = React.MutableRefObject<string | null>
type SelectedBookmark = bookmarksForUser_userAndPermissions_permittedAssociations_towns_filters | null
type SetSelectedBookmark = (bookmark: SelectedBookmark) => void
type BookmarksForUser = BookmarksForUserResult | undefined

const ARRAY_DELIMITER = ","

const getBookmarksFromTowns = (association: bookmarksForUser_userAndPermissions_permittedAssociations) => {
  return association.towns.reduce(
    (acc, town) => [...acc, ...town.filters],
    Array<bookmarksForUser_userAndPermissions_permittedAssociations_towns_filters>(),
  )
}

export function useBookmarkEffect(
  selectedBookmarkId: SelectedBookmarkRef,
  setSelectedBookmark: SetSelectedBookmark,
  bookmarksForUser: BookmarksForUser,
) {
  // (Execution Order: does not matter) Executed when bookmarks are loaded async → Updates selected bookmark
  useEffect(() => {
    if (selectedBookmarkId.current && bookmarksForUser) {
      // All bookmarks for user from GraphQL query
      const bookmarks = bookmarksForUser
        ? bookmarksForUser.userAndPermissions.permittedAssociations.reduce(
            (acc, association) => [...acc, ...association.filters, ...getBookmarksFromTowns(association)],
            Array<bookmarksForUser_userAndPermissions_permittedAssociations_filters>(),
          )
        : null

      // Find selected bookmark and set it to context
      const selected = bookmarks?.filter((bookmark) => bookmark.id === selectedBookmarkId.current).shift()
      if (selected) {
        setSelectedBookmark({
          ...selected,
          dateFrom: selected.dateFrom ? new Date(selected.dateFrom) : null,
          dateUntil: selected.dateUntil ? new Date(selected.dateUntil) : null,
          rfids: selected.rfids,
        })
        selectedBookmarkId.current = null
      }
    }
    // We only want to execute effect in specific circumstances, to prevent loops between effects
  }, [selectedBookmarkId, bookmarksForUser, setSelectedBookmark])
}

export function useUrlChangeEffect(
  selectedBookmarkId: SelectedBookmarkRef,
  selectedBookmark: SelectedBookmark,
  filterModel: AnalysisFilterModel,
) {
  const location = useLocation()

  // (Execution Order: 1) Executed when hash changes → Updates state / context
  useEffect(() => {
    const {
      setTourType,
      setAssociationId,
      setTownIds,
      setEmptyingId,
      setDateFrom,
      setDateUntil,
      setFractionType,
      setFractionFrom,
      setFractionTo,
      setDisplayOnlyAutomaticAnalysis,
      setRating,
      setSource,
      setRfids,
    } = filterModel

    const hashedState = getHashedState(location.hash, selectedBookmark)
    const hashedModel = getHashedModel(filterModel, selectedBookmark)

    Object.keys(hashedState).forEach((key) => {
      const target = hashedState[key]
      const current = hashedModel[key]

      const functionArray = []

      if (current !== target) {
        switch (key) {
          case "tourType":
            functionArray.push(() => setTourType(target as TourType))
            break
          case "associationId":
            functionArray.push(() => setAssociationId(target))
            break
          case "townIds":
            functionArray.push(() => setTownIds((target || null)?.split(ARRAY_DELIMITER)))
            break
          case "emptyingId":
            functionArray.push(() => setEmptyingId(target))
            break
          case "dateFrom":
            let newDateFrom: Date | null = null
            if (target) {
              newDateFrom = new Date(target)
              newDateFrom.setHours(0, 0, 0, 0)
            }
            functionArray.push(() => setDateFrom(newDateFrom))
            break
          case "dateUntil":
            let newDateUntil: Date | null = null
            if (target) {
              newDateUntil = new Date(target)
              newDateUntil.setHours(23, 59, 59, 999)
            }
            functionArray.push(() => setDateUntil(newDateUntil))
            break
          case "fractionType":
            functionArray.push(() => setFractionType(target))
            break
          case "fractionFrom":
            functionArray.push(() => setFractionFrom(target))
            break
          case "fractionTo":
            functionArray.push(() => setFractionTo(target))
            break
          case "displayOnlyAutomaticAnalysis":
            functionArray.push(() => setDisplayOnlyAutomaticAnalysis(target ? target === "true" : null))
            break
          case "rating":
            functionArray.push(() => setRating(target))
            break
          case "source":
            functionArray.push(() => setSource(target))
            break
          case "rfids":
            functionArray.push(() => setRfids(target))
            break
          case "bookmark":
            // We cannot set context directly because boomarks are loaded async
            selectedBookmarkId.current = target
            break
        }
      }
      functionArray.forEach((func) => {
        func()
      })
      return key
    })
    // We only want to execute effect in specific circumstances, to prevent loops between effects
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.hash])
}

export function useUrlUpdateEffect(
  isLoaded: React.MutableRefObject<boolean>,
  selectedBookmarkId: SelectedBookmarkRef,
  selectedBookmark: SelectedBookmark,
  filterModel: AnalysisFilterModel,
) {
  const bookmarkDependency = selectedBookmark?.id
  const location = useLocation()
  const history = useHistory()
  const effectFunctionGenerator = (override: boolean = false) => () => {
    // Prevent execution on infinite loop on first page load (→ Hash in URL overrides filter model on page load!)
    if (isLoaded.current || (override && !location.hash)) {
      const {
        tourType,
        associationId,
        townIds,
        emptyingId,
        dateFrom,
        dateUntil,
        fractionType,
        fractionFrom,
        fractionTo,
        displayOnlyAutomaticAnalysis,
        rating,
        source,
        rfids,
      } = filterModel

      const parsed = getHashedState(location.hash, selectedBookmark)
      const updated: { [key: string]: string | string[] | boolean | null | undefined } = {
        tourType,
        associationId,
        townIds: (townIds || []).join(ARRAY_DELIMITER),
        emptyingId,
        dateFrom: dateFrom ? moment(dateFrom).format("YYYY-MM-DD") : null,
        dateUntil: dateUntil ? moment(dateUntil).format("YYYY-MM-DD") : null,
        fractionType,
        fractionFrom,
        fractionTo,
        displayOnlyAutomaticAnalysis,
        rating,
        source,
        bookmark: selectedBookmark?.id || selectedBookmarkId.current || null,
        rfids,
      }

      // Update hash in URL only if data have really changed! → Otherwise yours would have to hit back button twice, or more often
      const shouldUpdate = Object.keys(updated).reduce((acc, key) => {
        return acc || updated[key] !== parsed[key]
      }, false)

      if (shouldUpdate) {
        const hash = `${qs.stringify(updated)}`
        history.push({ pathname: location.pathname, hash })
      }
    }
    // We only want to execute effect in specific circumstances, to prevent loops between effects
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }
  // eslint-disable-next-line
  useEffect(
    effectFunctionGenerator(),
    // We only want to execute effect in specific circumstances, to prevent loops between effects
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      filterModel.tourType,
      filterModel.associationId,
      filterModel.townIds,
      filterModel.emptyingId,
      filterModel.dateFrom,
      filterModel.dateUntil,
      filterModel.fractionType,
      filterModel.fractionFrom,
      filterModel.fractionTo,
      filterModel.displayOnlyAutomaticAnalysis,
      filterModel.rating,
      filterModel.source,
      filterModel.rfids,
      bookmarkDependency,
      location.pathname,
    ],
  )

  // eslint-disable-next-line
  useEffect(effectFunctionGenerator(true), [])
}

// Parse Hash from URL and transform it into commonly formatted state (for comparison)
const getHashedState = (
  hash: string,
  selectedBookmark: bookmarksForUser_userAndPermissions_permittedAssociations_towns_filters | null,
): { [key: string]: any } => {
  const parsed = qs.parse(hash)
  const combined = Object.keys(parsed).reduce((acc, key) => {
    const target = lodash.get(parsed, key, null)
    return {
      ...acc,
      [key]: target,
    }
  }, {})

  return {
    ...combined,
    bookmark: lodash.get(parsed, "bookmark", null),
  }
}

// Transform model into commonly formatted state (for comparison)
const getHashedModel = (
  filterModel: AnalysisFilterModel,
  selectedBookmark: bookmarksForUser_userAndPermissions_permittedAssociations_towns_filters | null,
): { [key: string]: any } => {
  return {
    tourType: filterModel.tourType,
    associationId: filterModel.associationId,
    townIds: filterModel.townIds?.join(ARRAY_DELIMITER) || null,
    emptyingId: filterModel.emptyingId,
    dateFrom: filterModel.dateFrom ? moment(filterModel.dateFrom).format("YYYY-MM-DD") : null,
    dateUntil: filterModel.dateUntil ? moment(filterModel.dateUntil).format("YYYY-MM-DD") : null,
    fractionType: filterModel.fractionType,
    fractionFrom: filterModel.fractionFrom,
    fractionTo: filterModel.fractionTo,
    displayOnlyAutomaticAnalysis: filterModel.displayOnlyAutomaticAnalysis || null,
    rating: filterModel.rating,
    source: filterModel.source,
    rfids: filterModel.rfids,
    bookmark: selectedBookmark?.id || null,
  }
}
