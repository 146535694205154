import { gql } from "apollo-boost"
import { rfidGroupsXLSXForTown, rfidGroupsXLSXForTownVariables } from "../generated/rfidGroupsXLSXForTown"

export const RFID_GROUPS_XLSX_FOR_TOWN_QUERY = gql`
  query rfidGroupsXLSXForTown($townId: ID!) {
    rfidGroupsXLSXForTown(townId: $townId) {
      base64
    }
  }
`

export type rfidGroupsXLSXResult = rfidGroupsXLSXForTown
export type rfidGroupsXLSXVariables = rfidGroupsXLSXForTownVariables
