import React, { createContext, FunctionComponent, useMemo, useState } from "react"
import { MutationFunctionOptions, useMutation, useQuery } from "react-apollo"
import {
  analyticsDetail_materialAnalysis_evaluation,
  analyticsDetail_materialAnalysis_materialAnalysis,
} from "../../../../api/graphql/generated/analyticsDetail"
import { MappedClassification, MaterialAnalysisInvalidityInput } from "../../../../api/graphql/generated/globalTypes"
import {
  UpdateMaterialAnalysisResult,
  UpdateMaterialAnalysisVariables,
  UPDATE_MATERIAL_ANALYSIS_MUTATION,
} from "../../../../api/graphql/mutation/update-material-analysis"
import {
  AnalyticsDetailQueryResult,
  AnalyticsDetailQueryVariables,
  ANALYTICS_DETAIL_QUERY,
} from "../../../../api/graphql/query/analyticsDetail"

interface WertstoffDetailContextType {
  analysis?: analyticsDetail_materialAnalysis_materialAnalysis
  mappedClassification?: MappedClassification
  evaluation?: analyticsDetail_materialAnalysis_evaluation
  updateMaterialAnalysisMutation: (
    options?: MutationFunctionOptions<UpdateMaterialAnalysisResult, UpdateMaterialAnalysisVariables>,
  ) => Promise<any>

  useBagSeparation?: boolean
  loading: boolean
  shouldInvalidateAnalysis: boolean
  setShouldInvalidateAnalysis: (should: boolean) => void
  invalidReason: string
  setInvalidReason: (reason: string) => void
  onAnalysisDeleted: (id: string) => void
  setMappedClassification: (mappedClassification: MappedClassification) => void
}

export const WertstoffDetailContext = createContext<WertstoffDetailContextType>({} as WertstoffDetailContextType)

interface IWertstoffDetailContextProviderProps {
  analysisId: string
  useBagSeparation?: boolean
  onAnalysisDeleted: (id: string) => void
  onAnalysisUpdated?: (id: string, invalidity: MaterialAnalysisInvalidityInput) => void
}

export const WertstoffDetailContextProvider: FunctionComponent<IWertstoffDetailContextProviderProps> = (props) => {
  const { useBagSeparation, analysisId, onAnalysisDeleted, onAnalysisUpdated } = props

  const [mappedClassification, setMappedClassification] = useState<MappedClassification>()
  const [invalidReason, setInvalidReason] = useState<string>("")
  const [shouldInvalidateAnalysis, setShouldInvalidateAnalysis] = useState<boolean>(false)

  const { data, loading } = useQuery<AnalyticsDetailQueryResult, AnalyticsDetailQueryVariables>(
    ANALYTICS_DETAIL_QUERY,
    {
      fetchPolicy: "network-only",
      variables: { ids: [analysisId], simplifyEvaluation: true },
      onCompleted: (_data) => {
        setMappedClassification(_data?.materialAnalysis?.materialAnalysis[0].metadata.mappedClassification)
      },
    },
  )

  const [updateMaterialAnalysisMutation] = useMutation<UpdateMaterialAnalysisResult, UpdateMaterialAnalysisVariables>(
    UPDATE_MATERIAL_ANALYSIS_MUTATION,
    {
      onError: (error) => {
        console.log("error:", error)
      },
      onCompleted: (_data) => {
        if (!!onAnalysisUpdated) {
          onAnalysisUpdated(analysisId, _data.updateMaterialAnalysis.invalidity as any)
        }
      },
    },
  )

  const { analysis, evaluation } = useMemo(
    () => ({
      analysis: data?.materialAnalysis?.materialAnalysis[0],
      evaluation: data?.materialAnalysis?.evaluation ?? undefined,
    }),
    [data],
  )

  return (
    <WertstoffDetailContext.Provider
      value={{
        analysis,
        evaluation,
        mappedClassification,
        updateMaterialAnalysisMutation,
        useBagSeparation,
        loading,
        setMappedClassification,
        onAnalysisDeleted,
        shouldInvalidateAnalysis,
        setShouldInvalidateAnalysis,
        invalidReason,
        setInvalidReason,
      }}
    >
      {props.children}
    </WertstoffDetailContext.Provider>
  )
}
