import React, { FunctionComponent, Fragment, useMemo } from "react"
import { Theme, makeStyles, Paper } from "@material-ui/core"
import { useTranslation } from "react-i18next"

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    margin: theme.spacing(1),
    minHeight: 250,
    flex: 1,
  },
  flex: {
    display: "flex",
  },
}))

interface IPaperWidgetWrapperProps {
  flex?: boolean
}

export const PaperWidgetWrapper: FunctionComponent<IPaperWidgetWrapperProps> = (props) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const { flex = false } = props

  const className = useMemo(() => (flex ? `${classes.paper} ${classes.flex}` : classes.paper), [flex, classes])

  return <Paper className={className}>{props.children}</Paper>
}
