import { gql } from "apollo-boost"
import { FRAGMENT_MATERIAL_ANALYSIS_WITH_DOCUMENTS } from "../fragments/material-analysis-with-documents"
import {
  materialWithDocumentsAnalysisSearch,
  materialWithDocumentsAnalysisSearchVariables,
} from "../generated/materialWithDocumentsAnalysisSearch"

export const MATERIAL_ANALYSIS_WITH_DOCUMENTS_SEARCH_QUERY = gql`
  query materialWithDocumentsAnalysisSearch(
    $tourType: TourType
    $associationIds: [ID!]
    $displayOnlyAutomaticAnalysis: Boolean
    $townIds: [ID!]
    $tourIds: [ID!]
    $firstDate: Date
    $lastDate: Date
    $fractions: [FractionFilter!]
    $ratings: [Rating!]
    $disableMapping: Boolean
    $simplifyEvaluation: Boolean
    $source: MaterialAnalysisSource
    $searchString: String!
  ) {
    materialAnalysisSearch(
      tourType: $tourType
      associationIds: $associationIds
      displayOnlyAutomaticAnalysis: $displayOnlyAutomaticAnalysis
      townIds: $townIds
      tourIds: $tourIds
      firstDate: $firstDate
      lastDate: $lastDate
      fractions: $fractions
      ratings: $ratings
      disableMapping: $disableMapping
      simplifyEvaluation: $simplifyEvaluation
      source: $source
      searchString: $searchString
    ) {
      ...FragmentMaterialAnalysisWithDocuments
    }
  }
  ${FRAGMENT_MATERIAL_ANALYSIS_WITH_DOCUMENTS}
`
export type MaterialAnalysisWithDocumentsSearchResult = materialWithDocumentsAnalysisSearch
export type MaterialAnalysisWithDocumentsSearchVariables = materialWithDocumentsAnalysisSearchVariables
