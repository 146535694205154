/*
    Hook which provides all filter functionalities
*/

import React, { useState, createContext } from "react"
import { Frequency } from "../api/graphql/generated/globalTypes"

interface StatisticsRefinementContextType {
  selectedFrequency: Frequency
  setSelectedFrequency: (freq: Frequency) => void
}

export const StatisticsRefinementContext = createContext<StatisticsRefinementContextType>({
  selectedFrequency: Frequency.daily,
  setSelectedFrequency: (freq: Frequency) => {},
})

export const StatisticsRefinementProvider = (props: any) => {
  const [selectedFrequency, setSelectedFrequency] = useState<Frequency>(Frequency.daily)
  return (
    <StatisticsRefinementContext.Provider value={{ selectedFrequency, setSelectedFrequency }}>
      {props.children}
    </StatisticsRefinementContext.Provider>
  )
}
