import { useCallback, useContext, useState } from "react"
import { useLazyQuery } from "react-apollo"
import { MaterialAnalysisWithDocuments } from "../../../../api/graphql/query/analyticsDocuments"
import { MaterialAnalysisForMap } from "../../../../api/graphql/query/material-analysis-for-map"
import {
  MATERIAL_ANALYSIS_WITH_DOCUMENTS_SEARCH_QUERY,
  MaterialAnalysisWithDocumentsSearchResult,
  MaterialAnalysisWithDocumentsSearchVariables,
} from "../../../../api/graphql/query/materialAnalysisSearch"
import { AnalysisFilterContext } from "../../../../context/AnalysisFilterContext"

export const useMaterialAnalysisWithDocumentsSearch = (allMaterialAnalysis: MaterialAnalysisForMap[]) => {
  const [searchString, setSearchString] = useState<string>("")
  const [isInSearchView, setIsInSearchView] = useState<boolean>(false)
  const [foundMaterialAnalysis, setFoundMaterialAnalysis] = useState<MaterialAnalysisWithDocuments[]>([])

  const { getAnalyticsQueryVariables } = useContext(AnalysisFilterContext)

  const [
    analyticsWithDocumentsQuery,
    { loading: searchLoading, error: searchError, data: searchData, refetch: refetchSearch },
  ] = useLazyQuery<MaterialAnalysisWithDocumentsSearchResult, MaterialAnalysisWithDocumentsSearchVariables>(
    MATERIAL_ANALYSIS_WITH_DOCUMENTS_SEARCH_QUERY,
    {
      fetchPolicy: "cache-and-network",
      onCompleted: (searchData) => {
        setFoundMaterialAnalysis(
          //this filter removes unwanted search results (e.g. invalid analysis)
          (searchData?.materialAnalysisSearch || []).filter((singleFoundMaterialAnalysis) =>
            allMaterialAnalysis.find((materialAnalysis) => materialAnalysis.id === singleFoundMaterialAnalysis.id),
          ),
        )
      },
    },
  )

  const search = useCallback(() => {
    setIsInSearchView(true)
    analyticsWithDocumentsQuery({
      variables: {
        searchString,
        ...getAnalyticsQueryVariables(true),
      },
    })
  }, [searchString])

  const onChangeSeachString = useCallback(
    (newValue: string) => {
      if (newValue === "") {
        setIsInSearchView(false)
      }
      setSearchString(newValue)
    },
    [setSearchString, isInSearchView],
  )

  const removeAnalysisFromFoundMaterialAnalysis = useCallback(
    (id: string) => {
      const clone = [...foundMaterialAnalysis]
      clone.splice(
        clone.findIndex((materialAnalysis) => materialAnalysis.id === id),
        1,
      )
      setFoundMaterialAnalysis(clone)
    },
    [foundMaterialAnalysis],
  )

  return {
    searchString,
    onChangeSeachString,
    isInSearchView,
    setIsInSearchView,
    search,
    searchLoading,
    searchError,
    refetchSearch,
    foundMaterialAnalysis,
    removeAnalysisFromFoundMaterialAnalysis,
  }
}
