import React, { FunctionComponent, Fragment } from "react"
import { Grid, useTheme, Tooltip, Fab, Typography } from "@material-ui/core"
import { useTranslation } from "react-i18next"
import { ClipLoader } from "react-spinners"
import { IColumnHeader, EColumnHeaderType, cellForRow, createColumns, DefaultTable } from "../table/DefaultTable"
import { DerivedDataObject, Column } from "react-table"
import { Delete, Edit } from "@material-ui/icons"
import lodash from "lodash"
import { RfidGroup } from "../../../api/graphql/query/rfid-groups-for-town"

interface IRfidGroupTableForTownProps {
  rfidGroups: RfidGroup[]
  setRfidGroupToEdit: (rfidGroup: RfidGroup) => void
  setRfidGroupToDelete: (rfidGroup: RfidGroup) => void
  loading: boolean
}

export const RfidGroupTableForTown: FunctionComponent<IRfidGroupTableForTownProps> = (props) => {
  const { t } = useTranslation()
  const { rfidGroups, setRfidGroupToEdit, setRfidGroupToDelete, loading } = props
  const theme = useTheme()

  const columnHeaders: IColumnHeader[] = [
    {
      title: t("rfid_groups.property.street"),
      type: EColumnHeaderType.STRING,
      accessor: "address.street",
    },
    {
      title: t("rfid_groups.property.streetNumber"),
      type: EColumnHeaderType.STRING,
      accessor: "address.streetNumber",
    },
    {
      title: t("rfid_groups.property.postalcode"),
      type: EColumnHeaderType.STRING,
      accessor: "address.postalcode",
    },
    {
      title: t("rfid_groups.property.city"),
      type: EColumnHeaderType.STRING,
      accessor: "address.city",
    },
    {
      title: t("rfid_groups.property.rfids"),
      type: EColumnHeaderType.ARRAY_COUNT,
      accessor: "rfids",
    },
  ]

  const columns: Column[] = createColumns(
    columnHeaders
      .map((column) => {
        return {
          Header: column.title,
          accessor: column.accessor,
          Cell: ({ row }: { row: DerivedDataObject }) => cellForRow(column, row),
        }
      })
      .concat({
        Header: "",
        accessor: "",
        Cell: ({ row }: { row: DerivedDataObject }) => {
          return (
            <Grid container item direction="row" spacing={2} justify="flex-end">
              <Grid item>
                <Tooltip title={t("general.delete")} style={{ marginRight: 8 }}>
                  <Fab size="small" color="primary" onClick={() => setRfidGroupToDelete(row._original)}>
                    <Delete />
                  </Fab>
                </Tooltip>
                <Tooltip title={t("general.edit")}>
                  <Fab size="small" color="primary" onClick={() => setRfidGroupToEdit(row._original)}>
                    <Edit />
                  </Fab>
                </Tooltip>
              </Grid>
            </Grid>
          )
        },
      }),
  )

  const numberOfGroups = lodash.isArray(rfidGroups) ? rfidGroups.length : 0
  return (
    <Fragment>
      {numberOfGroups > 0 && !loading && (
        <DefaultTable
          columns={columns}
          loading={loading}
          data={rfidGroups}
          minRows={1}
          defaultPageSize={10}
          showPagination={true}
        />
      )}
      {loading && (
        <Grid container justify="center">
          <ClipLoader color={theme.palette.primary.main} />
        </Grid>
      )}
      {numberOfGroups === 0 && !loading && (
        <Grid container justify="center">
          <Typography>{t("rfid_groups.no_rfidgroups")}</Typography>
        </Grid>
      )}
    </Fragment>
  )
}
