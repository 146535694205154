import { gql } from "apollo-boost"
import { version, version_version } from "../generated/version"

export const VERSION_QUERY = gql`
  query version {
    version {
      tag
      env
      commit
      ahead
    }
  }
`
export type VersionResult = version
export type Version = version_version
