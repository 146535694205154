import { useCallback, useContext, useState } from "react"
import { useLazyQuery } from "react-apollo"
import { Evaluation } from "../../../../api/graphql/fragments/evaluation_fragments"
import { MaterialAnalysisInvalidityInput } from "../../../../api/graphql/generated/globalTypes"
import {
  MaterialAnalysisForMap,
  MaterialAnalysisForMapResult,
  MaterialAnalysisForMapVariables,
  MATERIAL_ANALYSIS_FOR_MAP_QUERY,
} from "../../../../api/graphql/query/material-analysis-for-map"
import {
  MATERIAL_AND_EVALUATION_QUERY,
  MaterialAnalysisAndEvaluationResult,
  MaterialAnalysisAndEvaluationVariables,
  MaterialAnalysisForEvaluation,
} from "../../../../api/graphql/query/material-analysis-and-evaluation"
import { AnalysisFilterContext } from "../../../../context/AnalysisFilterContext"

export const useAnalyticsFromFilterContext = (onQueryCompleted: () => void) => {
  const [materialAnalysis, setMaterialAnalysis] = useState<MaterialAnalysisForEvaluation[]>([])
  const [materialAnalysisForMap, setMaterialAnalysisForMap] = useState<MaterialAnalysisForMap[]>([])
  const [evaluation, setEvaluation] = useState<Evaluation | null>(null)
  const [analysisAvailableForOtherRoles, setAnalysisAvailableForOtherRoles] = useState<boolean>()

  const { getAnalyticsQueryVariables } = useContext(AnalysisFilterContext)

  const [analyticsQuery, { loading: analysisLoading, error: analysisError, called: analysisCalled }] = useLazyQuery<
    MaterialAnalysisAndEvaluationResult,
    MaterialAnalysisAndEvaluationVariables
  >(MATERIAL_AND_EVALUATION_QUERY, {
    fetchPolicy: "cache-first",
    onCompleted: (data) => {
      onQueryCompleted()
      setMaterialAnalysis(data?.materialAnalyisAndEvaluation?.materialAnalysis || [])
      setEvaluation(data?.materialAnalyisAndEvaluation?.evaluation)
      setAnalysisAvailableForOtherRoles(data.materialAnalyisAndEvaluation.analysisAvailableForOtherRoles)
    },
  })

  const [analysisQueryForMap, { loading: analysisForMapLoading, error: analysisForMapError, called: analysisCalledForMap }] = useLazyQuery<
    MaterialAnalysisForMapResult,
    MaterialAnalysisForMapVariables
  >(MATERIAL_ANALYSIS_FOR_MAP_QUERY, {
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      onQueryCompleted()
      setMaterialAnalysisForMap(data?.materialAnalyisForMap || [])
    },
  })

  const fetchAnalysis = useCallback(() => {
    analyticsQuery({ variables: getAnalyticsQueryVariables(false) })
  }, [getAnalyticsQueryVariables, analyticsQuery])

  const fetchAnalysisForMap = useCallback(() => {
    analysisQueryForMap({ variables: getAnalyticsQueryVariables(false) })
  }, [getAnalyticsQueryVariables, analysisQueryForMap])

  const removeFromLocalMaterialAnalysis = useCallback(
    (id: string) => {
      const clone = [...materialAnalysisForMap]
      clone.splice(
        clone.findIndex((materialAnalysis) => materialAnalysis.id === id),
        1,
      )
      setMaterialAnalysisForMap(clone)
    },
    [materialAnalysisForMap],
  )

  const invalidateLocalAnalysis = useCallback(
    (id, invalidity: MaterialAnalysisInvalidityInput) => {
      const index = materialAnalysisForMap.findIndex((analysis) => analysis.id === id)
      const clone = [...materialAnalysisForMap]
      clone.splice(index, 1, { ...clone[index], invalidity: { isInvalid: invalidity.isInvalid } })
      setMaterialAnalysisForMap(clone)
    },
    [materialAnalysisForMap],
  )

  return {
    materialAnalysis,
    materialAnalysisForMap,
    evaluation,
    analysisLoading,
    analysisError,
    analysisForMapLoading,
    analysisForMapError,
    analysisCalled,
    analysisCalledForMap,
    fetchAnalysis,
    fetchAnalysisForMap,
    removeFromLocalMaterialAnalysis,
    invalidateLocalAnalysis,
    analysisAvailableForOtherRoles,
  }
}
