import { gql } from "apollo-boost"
import {
  permittedAssociationsForUser,
  permittedAssociationsForUser_userAndPermissions,
  permittedAssociationsForUser_userAndPermissions_permittedAssociations,
  permittedAssociationsForUser_userAndPermissions_permittedAssociations_towns,
} from "../generated/permittedAssociationsForUser"

export const PERMITTED_ASSOCIATIONS_FOR_USER_QUERY = gql`
  query permittedAssociationsForUser {
    userAndPermissions {
      permittedAssociations {
        id
        associationId
        documentDeletionRangeInDays
        name
        latitude
        longitude
        radius
        ratingSchema {
          residual {
            percentageFrom
            percentageTo
          }
          organic {
            percentageFrom
            percentageTo
          }
        }
        fractionMapping {
          tourType
          mappedFractions {
            fraction
            mappedMaterialType
          }
        }
        smsSender
        towns {
          associationId
          documentDeletionRangeInDays
          id
          name
          zipCode
          latitude
          longitude
          radius
          externalTown {
            id
            externalId
            type
          }
          displayOnlyAutomaticAnalysis
          boundary {
            id
            townName
          }
        }
      }
    }
  }
`

export type PermittedAssociationsForUserResult = permittedAssociationsForUser
export type UserAndPermissions = permittedAssociationsForUser_userAndPermissions
export type UserAndPermissionsAssociation = permittedAssociationsForUser_userAndPermissions_permittedAssociations
export type UserAndPermissionsTown = permittedAssociationsForUser_userAndPermissions_permittedAssociations_towns
