import { gql } from "apollo-boost"
import {
  templatesForCategory_widgetTextCategory_widgetTextTemplates,
  templatesForCategory,
  templatesForCategoryVariables
} from "../generated/templatesForCategory"
import { WIDGET_TEXT_CATEGORY_ESSENTIAL } from "../fragments/widget_text_category_fragments"

export const WIDGET_TEXT_TEMPLATES_FOR_CATEGORY_QUERY = gql`
  query templatesForCategory(
    $id: ID!
    $associationId: ID
    $townId: ID
    $isExclusive: Boolean
  ) {
    widgetTextCategory(id: $id) {
      ...WidgetTextCategoryEssential
      widgetTextTemplates(
        associationId: $associationId
        townId: $townId
        isExclusive: $isExclusive
      ) {
        id
        rating
        title
        textTemplate
        createdAt
        isDefault
        widgetTextCategory {
            ...WidgetTextCategoryEssential
        }
      }
    }
  }

  ${WIDGET_TEXT_CATEGORY_ESSENTIAL}
`

export type WidgetTextCategoriesWithTemplatesResult = templatesForCategory
export type WidgetTextCategoriesWithTemplatesVariables = templatesForCategoryVariables
export type WidgetTextTemplate = templatesForCategory_widgetTextCategory_widgetTextTemplates
