import { gql } from "apollo-boost"
import {
  townBoundarySearch,
  townBoundarySearchVariables,
  townBoundarySearch_townBoundarySearch,
} from "../generated/townBoundarySearch"

export const TOWN_BOUNDARY_SEARCH_QUERY = gql`
  query townBoundarySearch($searchString: String!) {
    townBoundarySearch(searchString: $searchString) {
      id
      townName
    }
  }
`

export type TownBoundarySearchQueryResult = townBoundarySearch
export type TownBoundarySearchVariables = townBoundarySearchVariables
export type TownBoundarySearchResult = townBoundarySearch_townBoundarySearch
