import React, { FunctionComponent, useMemo, useCallback, useRef, useEffect } from "react"
import { Theme, makeStyles } from "@material-ui/core"
import { useTranslation } from "react-i18next"
import { IWasteQualityDictionary } from "./AnalysisWasteQualityPieGraphSection"
import { Legend, Pie, ResponsiveContainer, PieChart, Cell, Tooltip } from "recharts"
import { getRatingInfo } from "../../../../domain/helper/CalcRating"
import { renderPieChartLabel } from "../../../../utils/pie-chart"
import lodash from "lodash"
import domToImage from "dom-to-image-more"
import { Rating } from "../../../../api/graphql/generated/globalTypes"
import { WasteQualityPieChartLegend } from "./WasteQualityPieChartLegend"

const useStyles = makeStyles((theme: Theme) => ({}))

interface IWasteQualityPieGraphProps {
  dictionary: IWasteQualityDictionary
  legend?: boolean
  onExportCreated?: (fileUri: string, extension: string) => void
}

export const WasteQualityPieGraph: FunctionComponent<IWasteQualityPieGraphProps> = (props) => {
  const classes = useStyles()
  const { t } = useTranslation()
  let currentChartRef = useRef(null)

  useEffect(() => {
    if (!lodash.isNil(onExportCreated)) {
      setTimeout(function () {
        exportChart()
      }, 200)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const { dictionary, legend = false, onExportCreated } = props

  const pieData = useMemo(() => {
    const _pieData: { value: number; name: string; numValue: number; color: string; rating: Rating }[] = []
    Object.keys(dictionary).forEach((key) => {
      if (key !== Rating.Unknown) {
        const { rating, color } = getRatingInfo(key)
        _pieData.push({
          value: dictionary[key].percentage * 100,
          numValue: dictionary[key]?.analysis?.length || 0,
          name: t(rating),
          color,
          rating: key as Rating,
        })
      }
    })
    return _pieData
  }, [dictionary, t])

  const exportChart = useCallback(() => {
    console.log("exportChart started")
    if (!lodash.isFunction(onExportCreated)) {
      return
    }

    let foundNode = window.ReactDOM.findDOMNode(currentChartRef.current)
    if (lodash.isNil(foundNode)) {
      return
    }

    domToImage.toPng(foundNode, { scale: 10 }).then(function (imageBase64: string) {
      if (!lodash.isNil(onExportCreated)) {
        onExportCreated(imageBase64, ".png")
      }
    })
  }, [onExportCreated])

  return (
    <ResponsiveContainer width={320} maxHeight={"100%"} aspect={1 / 1}>
      <PieChart ref={currentChartRef}>
        <Tooltip formatter={(value, name, entry) => `${entry?.payload?.numValue} | ${Number(value).toFixed(0)}%`} />
        {legend && (
          <Legend
            layout={"vertical"}
            verticalAlign={"middle"}
            align={"right"}
            iconType={"circle"}
            iconSize={10}
            content={WasteQualityPieChartLegend}
          />
        )}
        <Pie
          dataKey={"value"}
          key={"keyForPieData"}
          data={pieData}
          labelLine={false}
          label={renderPieChartLabel}
          outerRadius={"80%"}
          isAnimationActive={false}
        >
          {pieData.map((entry, index) => (
            <Cell key={index} fill={entry.color} />
          ))}
        </Pie>
      </PieChart>
    </ResponsiveContainer>
  )
}
