import { gql } from "apollo-boost"
import { updateReport, updateReportVariables } from "../generated/updateReport"

export const MUTATION = gql`
  mutation updateReport($id: ID!, $title: String!) {
    updateReport(id: $id, title: $title) {
      error
      message
    }
  }
`

export type UpdateReportMutationResult = updateReport
export type UpdateReportMutationVariables = updateReportVariables
