import React, { FunctionComponent, useContext, useState } from "react"
import { Button, Grid, makeStyles, Theme } from "@material-ui/core"
import { PermittedAssociationsContext } from "../../../context/PermittedAssociationsContext"
import { PageLoadingIndicator } from "../../partials/indicator/PageLoadingIndicator"
import { TownExpansionPanelWithRfidGroupTable } from "./TownExpansionPanelWithRfidGroupTable"
import { AssociationSection } from "../citizen-management/AssociationSection"
import { RootGrid } from "../../partials/layout/RootGrid"
import { useTranslation } from "react-i18next"
import { ExportRfidGroupDialog } from "./dialogs/ExportRfidGroupDialog"

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    paddingRight: theme.spacing(2),
  },
}))

interface IRfidGroupsPageContentProps {}

export const RfidGroupsPageContent: FunctionComponent<IRfidGroupsPageContentProps> = (props) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const [exportDialogOpen, setExportDialogOpen] = useState<boolean>(false)
  const { permittedAssociations, permittedAssociationsLoading } = useContext(PermittedAssociationsContext)

  return (
    <>
      <ExportRfidGroupDialog open={exportDialogOpen} onClose={() => setExportDialogOpen(false)} />
      <RootGrid spacing={2}>
        {permittedAssociationsLoading && (
          <Grid item xs={12}>
            <PageLoadingIndicator />
          </Grid>
        )}
        {!permittedAssociationsLoading && permittedAssociations && (
          <>
            <Grid item xs={12} container justify={"flex-end"}>
              <Grid item>
                <Button variant="contained" color="primary" onClick={() => setExportDialogOpen(true)}>
                  {t("rfid_groups.export_rfid_groups")}
                </Button>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              {permittedAssociations.map(
                (association) =>
                  association && (
                    <AssociationSection key={association.id} association={association}>
                      {association.towns.map((town) => (
                        <TownExpansionPanelWithRfidGroupTable town={town} key={town.id} />
                      ))}
                    </AssociationSection>
                  ),
              )}
            </Grid>
          </>
        )}
      </RootGrid>
    </>
  )
}
