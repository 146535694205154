import { nameOfEmptying } from "../../../../utils/emptying-helper"
import { TFunction } from "i18next"
import lodash from "lodash"
import moment from "moment"
import { sortASC } from "../../../../utils/sort"
import { EmptyingOfTown } from "../../../../api/graphql/query/emptyings-for-towns"

export interface MergedEmptying extends EmptyingOfTown {
  ids: string[]
}

export function getEmptyings(emptyings: EmptyingOfTown[]): MergedEmptying[] {
  const sortedEmptyings = emptyings.sort(
    (a, b) => sortASC(a.id, b.id), // has to be sorted, so reloading works
  )
  const groupedEmptyings = lodash.groupBy(sortedEmptyings, "startDate")

  // merge Tours of grouped-Emptyings
  const allEmptyings = lodash.compact(
    Object.values(groupedEmptyings).map((e) => {
      if (lodash.isEmpty(e)) {
        return null
      }
      let mergedEmptying: MergedEmptying = {
        ...e[0],
        ids: e.map((e) => e.id),
        tours: e.flatMap((e) => e.tours || []),
      }

      return mergedEmptying
    }),
  )

  // WER-326 (II): Filter all emptying-weeks with no pictures taken:
  return allEmptyings
    .map((e) => {
      let emptying = lodash.cloneDeep(e)
      emptying.tours = emptying.tours?.filter((t) => (t?.materialAnalysisCount ?? 0) > 0) ?? []
      return { ...emptying, ids: [emptying.id] }
    })
    .filter((e) => (e.tours?.length ?? 0) > 0)
}
export const getEmptyingOptions = (emptyings: MergedEmptying[], t: TFunction) => {
  return getEmptyings(emptyings)
    .sort((a, b) => {
      if (lodash.isString(a.startDate) && lodash.isString(b.startDate)) {
        return moment(b.startDate).diff(moment(a.startDate))
      }
      return 0
    })
    .map((emptying) => {
      return {
        ...emptying,
        name: nameOfEmptying(emptying, t),
        date: emptying.startDate,
      }
    })
  // TODO: (RF) Not sure why this is there: @MAX?
  // .filter((emptying) => filterEmptyingFilterItemsByDate(dateFrom, dateUntil, emptying))
}
