import { FunctionComponent, useState, useCallback } from "react"
import {
  Grid,
  InputLabel,
  Select,
  MenuItem,
  makeStyles,
  Theme,
  Button,
  IconButton,
  FormControl,
} from "@material-ui/core"
import { useTranslation } from "react-i18next"
import { MappedClassification } from "../../../api/graphql/generated/globalTypes"
import { Save } from "@material-ui/icons"
import lodash from "lodash"

interface IWertstoffMappedClassificationDropdownProps {
  mappedClassification: MappedClassification
  automatic: boolean | null
  onMappedClassificationUpdated: (mappedClassification: MappedClassification) => void
}

const useStyles = makeStyles((theme: Theme) => ({
  textFieldContainer: {
    margin: 0,
  },
  defaultItem: {
    fontStyle: "italic",
  },
}))

export const WertstoffMappedClassificationDropdown: FunctionComponent<IWertstoffMappedClassificationDropdownProps> = (
  props,
) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const { onMappedClassificationUpdated, automatic, mappedClassification } = props

  const onSelectionChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const newValue = event.target.value as MappedClassification
    onMappedClassificationUpdated(newValue)
  }

  const getTranslationForOriginalValue = useCallback(
    () =>
      !lodash.isNil(automatic)
        ? `(${t(
            `mapped_classification.${automatic ? MappedClassification.AUTOMATICALLY : MappedClassification.MANUALLY}`,
          )})`
        : "",
    [automatic, t],
  )

  const selectedClassification = mappedClassification

  return (
    <Grid container spacing={1} alignItems="flex-end">
      <Grid item xs>
        <FormControl fullWidth>
          <InputLabel id="mapped-classification-label">{t("mapped_classification.label")}</InputLabel>
          <Select
            fullWidth
            labelId="mapped-classification-label"
            value={selectedClassification || ""}
            onChange={onSelectionChange}
            className={selectedClassification === MappedClassification.ORIGINAL ? classes.defaultItem : null}
          >
            {Object.keys(MappedClassification).map((classification) => {
              return (
                <MenuItem
                  value={classification}
                  key={classification}
                  className={classification === MappedClassification.ORIGINAL ? classes.defaultItem : null}
                >
                  {t(`mapped_classification.${classification}`, { originalValue: getTranslationForOriginalValue() })}
                </MenuItem>
              )
            })}
          </Select>
        </FormControl>
      </Grid>
    </Grid>
  )
}
