import React, { FunctionComponent, Fragment, useContext, useState } from "react"
import { Theme, makeStyles, Button, Menu, MenuItem, useTheme, Grid, Typography } from "@material-ui/core"
import { useTranslation } from "react-i18next"
import { useLazyQuery } from "react-apollo"
import { AnalysisFilterContext } from "../../../context/AnalysisFilterContext"
import { ClipLoader } from "react-spinners"
import { AdminBadge } from "../badge/AdminBadge"
import { AuthContext } from "../../../context/AuthContext"
import { AbilityName } from "../../../api/graphql/generated/globalTypes"
import {
  MATERIAL_ANALYSIS_EXCEL_EXPORT_QUERY,
  MaterialAnalysisExcelExportResult,
  MaterialAnalysisExcelExportVariables,
} from "../../../api/graphql/query/materialAnalysisExcelExport"
import { saveAs } from "file-saver"
import lodash from "lodash"

const useStyles = makeStyles((theme: Theme) => ({
  exportButton: { minWidth: 180 },
  menuItemTypo: {
    fontSize: 16,
  },
}))

interface IExcelExportButtonProps {}

export const ExcelExportButton: FunctionComponent<IExcelExportButtonProps> = (props) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const [menuAnchorElement, setMenuAnchorElement] = useState<Element | null>(null)
  const theme = useTheme()

  const { getAnalyticsQueryVariables } = useContext(AnalysisFilterContext)

  const { hasOneRequiredAbility } = useContext(AuthContext)

  const downloadExportedExcel = (data: MaterialAnalysisExcelExportResult) => {
    const base64 = data?.materialAnalysisExcelExport?.base64

    if (base64) {
      saveAs(new Blob([Buffer.from(base64, "base64")]), "Wertstoffscanner.xlsx")
    }
  }

  const [materialAnalysisExcelExportQuery, { loading }] = useLazyQuery<
    MaterialAnalysisExcelExportResult,
    MaterialAnalysisExcelExportVariables
  >(MATERIAL_ANALYSIS_EXCEL_EXPORT_QUERY, {
    fetchPolicy: "no-cache",
    onCompleted: downloadExportedExcel,
    onError: console.log,
  })

  const onExport = async (adminMode: boolean) => {
    const variables = { ...getAnalyticsQueryVariables(false) }
    const rating = lodash.isArray(variables.ratings) ? variables.ratings[0] : variables.ratings
    delete variables.ratings

    materialAnalysisExcelExportQuery({
      variables: {
        ...variables,
        rating,
        disableMapping: adminMode,
      },
    })
    setMenuAnchorElement(null)
  }

  const onExportButtonClicked = (event: any) => {
    if (hasOneRequiredAbility([AbilityName.admin])) {
      setMenuAnchorElement(event.currentTarget)
    } else {
      onExport(false)
    }
  }

  return (
    <Fragment>
      <Button
        onClick={(event) => onExportButtonClicked(event)}
        variant="contained"
        color="primary"
        className={classes.exportButton}
      >
        {loading ? <ClipLoader color={theme.palette.common.white} size={16} /> : t("excel_export.excel_export")}
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={menuAnchorElement}
        keepMounted
        open={Boolean(menuAnchorElement)}
        onClose={() => setMenuAnchorElement(null)}
      >
        <MenuItem onClick={() => onExport(true)}>
          <Grid container direction="row" alignItems="center" alignContent="center" spacing={2}>
            <Grid item>
              <Typography className={classes.menuItemTypo}>{t("excel_export.export_unmapped")}</Typography>
            </Grid>
            <Grid item>
              <AdminBadge />
            </Grid>
          </Grid>
        </MenuItem>
        <MenuItem onClick={() => onExport(false)}>
          <Typography className={classes.menuItemTypo}>{t("excel_export.export_mapped")}</Typography>
        </MenuItem>
      </Menu>
    </Fragment>
  )
}
