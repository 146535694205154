import React, { useContext } from "react"
import { makeStyles, Grid } from "@material-ui/core"
import lodash from "lodash"
import Tooltip from "@material-ui/core/Tooltip"
import { UserContext } from "../../../context/UserContext"

const useStyles = makeStyles((theme) => ({
  containerWrap: {},
  containerNoWrap: {
    overflow: "hidden",
    whiteSpace: "nowrap",
  },
  nameField: {
    width: "100%",
    wordWrap: "break-word",
  },
  emailField: {
    width: "100%",
    textOverflow: "ellipsis",
  },
}))

export const UserInfo = (props) => {
  const classes = useStyles()

  const { user } = useContext(UserContext)

  if (lodash.isNil(user)) {
    return <p></p>
  }

  let displayName = ""
  let displayClass = ""
  let containerClass = ""
  const userName = lodash.defaultTo(lodash.get(user, "name", ""), "").trim()
  const email = lodash.defaultTo(lodash.get(user, "email", ""), "").trim()
  if (lodash.isString(userName) && !lodash.isEmpty(userName)) {
    displayName = userName
    displayClass = classes.nameField
    containerClass = classes.containerWrap
  } else if (lodash.isString(email) && !lodash.isEmpty(email)) {
    displayName = email
    displayClass = classes.emailField
    containerClass = classes.containerNoWrap
  }

  return (
    <Grid container className={containerClass}>
      <Tooltip title={email}>
        <p className={displayClass}>{displayName}</p>
      </Tooltip>
    </Grid>
  )
}
