import { gql } from "apollo-boost"
import { validateRfidForGroup, validateRfidForGroupVariables } from "../generated/validateRfidForGroup"

export const VALIDATE_RFID_GROUP_RFID_QUERY = gql`
  query validateRfidForGroup($rfid: String!) {
    validateRfidForGroup(rfid: $rfid)
  }
`

export type ValidateRfidGroupRfidQueryResult = validateRfidForGroup
export type ValidateRfidGroupRfidQueryVariables = validateRfidForGroupVariables
