import { gql } from "apollo-boost"
import { FragmentEvaluationFull } from "../generated/FragmentEvaluationFull"

export const FRAGMENT_EVALUATION_FULL = gql`
  fragment FragmentEvaluationFull on Evaluation {
    sumOfPixels {
      total
      main
      dontCare
      missThrow
      bags
      unknown
    }
    materialDistributionWithBagsDetailed {
      id
      value
      name
      color
      materialType
    }
    materialDistributionWithoutBagsDetailed {
      id
      value
      name
      color
      materialType
    }
    percentOfCategory {
      total
      main
      dontCare
      missThrow
      bags
      unknown
    }
    percentOfCategoryWithBags {
      total
      main
      dontCare
      missThrow
      bags
      unknown
    }
    percentOfCategoryWithoutBags {
      total
      main
      dontCare
      missThrow
      bags
      unknown
    }
    rating
    analysisCount
    numberOfAnalysisWithPixelCount
    numberOfAnalysisWithoutPixelCount
  }
`
export type Evaluation = FragmentEvaluationFull
