import React, { FunctionComponent } from "react"
import { useTranslation } from "react-i18next"
import { Grid, makeStyles, Theme, Typography } from "@material-ui/core"

interface INoDataErrorIndicatorProps {
  label?: string
}

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: "flex",
    alignContent: "center",
    alignItems: "center",
    justifyContent: "center",
    height: "50vh",
    flexDirection: "column"
  }
}))

export const NoDataErrorIndicator: FunctionComponent<
  INoDataErrorIndicatorProps
> = props => {
  const { label } = props
  const { t } = useTranslation()
  const classes = useStyles()
  return (
    <Grid container className={classes.container} justify="center">
      <Grid item>
        {label || (
          <Typography color="primary">
            {t("general.no_data_available_reload_page")}
          </Typography>
        )}
      </Grid>
    </Grid>
  )
}
