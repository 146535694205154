import React, { FunctionComponent, useContext, useEffect, useState } from "react"
import { Button, makeStyles, Theme, Typography, useTheme, Grid, Menu, MenuItem } from "@material-ui/core"
import { useTranslation } from "react-i18next"
import { useLazyQuery, useMutation } from "react-apollo"
import { ClipLoader } from "react-spinners"
import { FetchAdvancementPDF, FetchAdvancementPDFVariables } from "../../../api/graphql/generated/FetchAdvancementPDF"
import { FETCH_ADVANCEMENT_PDF } from "../../../api/graphql/query/fetchAdvancementPDF"
import {
  CREATE_ADVANCEMENT_PDF,
  CreateAdvancementPDFVariables,
  CreateAdvancementPDFResult,
} from "../../../api/graphql/mutation/create-advancement-pdf"
import lodash from "lodash"
import { AdvancementPDFStatus } from "../../../api/graphql/generated/globalTypes"
import { toast } from "react-toastify"
import { getGraphQLErrorLocalized } from "../../../utils/get-graphql-error-code"
import { PDFFilterContext } from "../../../context/PDFFilterContext"

const useStyles = makeStyles((theme: Theme) => ({
  exportButton: { minWidth: 180 },
  menuItemTypo: {
    fontSize: 16,
  },
}))

interface IAdvancementPDFButtonProps {
  disabled: boolean
}

export enum AdvancementPDFButtonStatus {
  notStarted,
  creatingPdf,
  readyToDownload,
  failed,
}

export const AdvancementPDFButton: FunctionComponent<IAdvancementPDFButtonProps> = (props) => {
  const classes = useStyles()
  const { disabled } = props
  const { t } = useTranslation()

  const theme = useTheme()

  const [status, setStatus] = useState<AdvancementPDFButtonStatus>(AdvancementPDFButtonStatus.notStarted)
  const [pdfId, setPdfId] = useState<string | undefined>(undefined)
  const [pdfUrl, setPdfUrl] = useState<string | undefined>(undefined)
  const [menuAnchorElement, setMenuAnchorElement] = useState<Element | null>(null)

  const { townId, startDate, endDate, tourType, ratings, associationId } = useContext(PDFFilterContext)

  const [fetchAdvancementPDFQuery, { stopPolling }] = useLazyQuery<FetchAdvancementPDF, FetchAdvancementPDFVariables>(
    FETCH_ADVANCEMENT_PDF,
    {
      fetchPolicy: "no-cache",
      notifyOnNetworkStatusChange: true,
      onCompleted: (result) => {
        console.log("fetched AdvancementPDFQuery: ", result)
        const loadedUrl = result.advancementPDF?.document?.url
        const queryStatus = result.advancementPDF?.status

        if (queryStatus === AdvancementPDFStatus.COMPLETED && lodash.isString(loadedUrl)) {
          stopPolling()
          setPdfUrl(loadedUrl)
        } else if (queryStatus === AdvancementPDFStatus.FAILED) {
          stopPolling()
          setStatus(AdvancementPDFButtonStatus.failed)
        }
      },

      onError: (error) => {
        const localized = getGraphQLErrorLocalized(error, t)
        // toast.error(localized)
        console.error(localized, error)
      },
      pollInterval: 3000, // milliseconds
    },
  )

  useEffect(() => {
    console.log("Change status for: ", pdfId, pdfUrl)

    if (lodash.isNil(pdfId) && lodash.isNil(pdfUrl)) {
      setStatus(AdvancementPDFButtonStatus.notStarted)
    } else if (lodash.isString(pdfUrl)) {
      setStatus(AdvancementPDFButtonStatus.readyToDownload)
    } else if (lodash.isString(pdfId)) {
      setStatus(AdvancementPDFButtonStatus.creatingPdf)
      startFetchingPdf()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pdfId, pdfUrl])

  function startFetchingPdf() {
    console.log("startFetchingPdf: ", pdfId)
    if (lodash.isString(pdfId)) {
      fetchAdvancementPDFQuery({
        variables: {
          id: pdfId,
        },
      })
    }
  }

  const [createAdvancementPDF] = useMutation<CreateAdvancementPDFResult, CreateAdvancementPDFVariables>(
    CREATE_ADVANCEMENT_PDF,
    {
      onCompleted: (data) => {
        setPdfId(data.createAdvancementPDF)
      },
      onError: (error) => {
        const localized = getGraphQLErrorLocalized(error, t)
        toast.error(localized)
        console.error(localized, error)
      },
    },
  )

  function startCreatingAdvancementPdf(splitPDFByAddress: boolean) {
    console.log("PDF creation starting now")

    createAdvancementPDF({
      variables: {
        tourType,
        associationId: associationId ? String(associationId) : undefined,
        townId: townId ? String(townId) : undefined,
        simplifyEvaluation: true,
        splitPDFByAddress,
        startDate,
        endDate,
        ratings,
      },
    }).then()
    setMenuAnchorElement(null)
  }

  const onButtonClicked = (event: any) => {
    switch (status) {
      case AdvancementPDFButtonStatus.creatingPdf:
        console.log("Do nothing")
        break
      case AdvancementPDFButtonStatus.failed:
        console.log("failed to create PDF")
        setPdfUrl(undefined)
        setPdfId(undefined)
        break
      case AdvancementPDFButtonStatus.notStarted:
        setMenuAnchorElement(event.currentTarget)
        break
      case AdvancementPDFButtonStatus.readyToDownload:
        console.log("Download clicked -> reset status")
        setPdfUrl(undefined)
        setPdfId(undefined)
        break
    }
  }

  const isLoading = status === AdvancementPDFButtonStatus.creatingPdf

  return (
    <Grid
      container
      direction={"row"}
      alignItems={"flex-end"}
      justify={"flex-end"}
      spacing={2}
      style={{ marginLeft: "-7px" }}
    >
      {isLoading && (
        <Grid item>
          <Typography variant={"body1"}>{t(`analysis_page.advancement_pdf.tooltip_loading`)}</Typography>
        </Grid>
      )}
      <Grid item>
        <Button
          variant="contained"
          color="primary"
          className={classes.exportButton}
          disabled={disabled || status === AdvancementPDFButtonStatus.creatingPdf}
          onClick={(event) => onButtonClicked(event)}
          href={pdfUrl}
        >
          {isLoading ? (
            <ClipLoader color={theme.palette.common.white} size={16} />
          ) : (
            t(`analysis_page.advancement_pdf.button.${status}`)
          )}
        </Button>
        <Menu
          id="simple-menu"
          anchorEl={menuAnchorElement}
          keepMounted
          open={Boolean(menuAnchorElement)}
          onClose={() => setMenuAnchorElement(null)}
        >
          <MenuItem onClick={() => startCreatingAdvancementPdf(true)}>
            <Typography className={classes.menuItemTypo}>
              {t(`analysis_page.advancement_pdf.button.menu.create_zip`)}
            </Typography>
          </MenuItem>
          <MenuItem onClick={() => startCreatingAdvancementPdf(false)}>
            <Typography className={classes.menuItemTypo}>
              {t(`analysis_page.advancement_pdf.button.menu.create_pdf`)}
            </Typography>
          </MenuItem>
        </Menu>
      </Grid>
    </Grid>
  )
}
