import { gql } from "apollo-boost"
import {
  materialAnalysisExcelExport,
  materialAnalysisExcelExportVariables,
} from "../generated/materialAnalysisExcelExport"

export const MATERIAL_ANALYSIS_EXCEL_EXPORT_QUERY = gql`
  query materialAnalysisExcelExport(
    $tourType: TourType
    $associationIds: [ID!]
    $displayOnlyAutomaticAnalysis: Boolean
    $townIds: [ID!]
    $tourIds: [ID!]
    $firstDate: DateTime
    $lastDate: DateTime
    $fractions: [FractionFilter!]
    $rating: Rating
    $disableMapping: Boolean
    $rfids: [String!]
  ) {
    materialAnalysisExcelExport(
      tourType: $tourType
      associationIds: $associationIds
      displayOnlyAutomaticAnalysis: $displayOnlyAutomaticAnalysis
      townIds: $townIds
      tourIds: $tourIds
      firstDate: $firstDate
      lastDate: $lastDate
      fractions: $fractions
      rating: $rating
      disableMapping: $disableMapping
      rfids: $rfids
    ) {
      base64
    }
  }
`

export type MaterialAnalysisExcelExportResult = materialAnalysisExcelExport
export type MaterialAnalysisExcelExportVariables = materialAnalysisExcelExportVariables
