import React, { FunctionComponent, useCallback, useContext, useMemo } from "react"
import { Button, Grid, makeStyles, Paper, Theme, Typography } from "@material-ui/core"
import { useTranslation } from "react-i18next"
import { AnalysisFilterContext } from "../../../context/AnalysisFilterContext"
import { titleForTourType, TourTypeInfo } from "../../../domain/models/TourTypes"
import { WertstoffAnalysisList } from "../analysis-data/WertstoffAnalysisList"
import { WertstoffAnalysisPieGraph } from "../analysis-data/WertstoffAnalysisPieGraph"
import { ExcelExportButton } from "./ExcelExportButton"
import { Evaluation } from "../../../api/graphql/fragments/evaluation_fragments"
import { getRatingInfo } from "../../../domain/helper/CalcRating"
import { AdminBadge } from "../badge/AdminBadge"
import { AuthContext } from "../../../context/AuthContext"
import { CustomPaper } from "../paper/custom-paper"

const useStyles = makeStyles((theme: Theme) => ({
  paperFilled: {
    margin: 0,
    marginBottom: 8,
    padding: 0,
    justifyContent: "center",
    color: theme.palette.text.secondary,
    borderRadius: theme.spacing(0.5),
  },
  spanRating: {
    fontFamily: "Roboto",
    fontSize: "14px",
    fontWeight: "bold",
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: 1.14,
    letterSpacing: "-0.1px",
    color: "#ffffff",
  },
  paperRating: {
    margin: 0,
    padding: 2,
    justifyContent: "center",
    // color: theme.palette.text.secondary,
    borderRadius: theme.spacing(0.5),
    textAlign: "center",
    alignContent: "center",
    verticalAlign: "center",
    backgroundColor: theme.palette.primary.main,
    minWidth: "60px",
  },
  reportCreateButton: {
    minWidth: 180,
  },
}))

interface IAnalysisPieGraphSectionProps {
  evaluation: Evaluation | null
  tourTypeInfo: TourTypeInfo
  meanCompact: boolean
  setMeanCompact: (meanCompact: boolean) => void
  onReportCreate: () => void
  fetchAnalysis: () => void
  analysisLoading: boolean
}

export const AnalysisPieGraphSection: FunctionComponent<IAnalysisPieGraphSectionProps> = (props) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const {
    analysisLoading,
    evaluation,
    fetchAnalysis,
    tourTypeInfo,
    meanCompact,
    setMeanCompact,
    onReportCreate,
  } = props
  const { filterModel } = useContext(AnalysisFilterContext)
  const { isHousingManagement } = useContext(AuthContext)

  const overallRating = useMemo(() => (evaluation ? getRatingInfo(evaluation.rating) : null), [evaluation])
  const handleChangeMeanToggle = () => {
    setMeanCompact(!meanCompact)
  }

  const handleOpenToggled = useCallback(
    (open: boolean) => {
      if (open) {
        fetchAnalysis()
      }
    },
    [fetchAnalysis],
  )

  const headerContent = useMemo(
    () => (
      <Grid item xs>
        <Typography variant={"h5"}>
          {t("analysis_page.composition_heading", {
            type: titleForTourType(filterModel.tourType, t),
          })}
        </Typography>
      </Grid>
    ),
    [t, filterModel.tourType],
  )

  const additionalHeader = useMemo(
    () =>
      overallRating ? (
        <Grid item>
          <Grid container justify={"flex-end"} alignItems={"center"} spacing={1}>
            <Grid item>
              <Typography variant={"h6"}>{t(`analysis_page.quality_of_seperation`)}:</Typography>
            </Grid>
            <Grid item>
              <Paper
                className={classes.paperRating}
                elevation={0}
                style={{
                  backgroundColor: overallRating.color,
                }}
              >
                <span className={classes.spanRating}>{t(overallRating.rating)}</span>
              </Paper>
            </Grid>
          </Grid>
        </Grid>
      ) : null,
    [t, classes, overallRating],
  )

  return (
    <CustomPaper
      contentLoading={analysisLoading}
      headerContent={headerContent}
      additionalHeader={additionalHeader}
      onToggleOpen={handleOpenToggled}
    >
      {evaluation && !analysisLoading && (
        <Grid container spacing={2}>
          <Grid item container direction={"row"} alignItems={"stretch"} justify={"space-evenly"}>
            <Grid item xs={6} sm={6} md={5} lg={4} xl={4}>
              <WertstoffAnalysisList
                tourTypeInfo={tourTypeInfo}
                evaluationData={evaluation}
                compact={meanCompact}
                onCompactChange={handleChangeMeanToggle}
              />
            </Grid>
            <Grid item xs={6} sm={6} md={5} lg={4} xl={4}>
              <WertstoffAnalysisPieGraph tourTypeInfo={tourTypeInfo} evaluation={evaluation} compact={meanCompact} />
            </Grid>
          </Grid>

          <Grid item container justify={"flex-end"} spacing={2}>
            <Grid item>
              <ExcelExportButton />
            </Grid>
            {!isHousingManagement() && (
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  aria-label="Bericht erstellen"
                  onClick={onReportCreate}
                  className={classes.reportCreateButton}
                >
                  {t("report.create_report")}
                </Button>
              </Grid>
            )}
          </Grid>
        </Grid>
      )}
    </CustomPaper>
  )
}
