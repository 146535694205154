import React, { FunctionComponent, Fragment } from "react"
import { Theme, makeStyles, Grid } from "@material-ui/core"
import { useTranslation } from "react-i18next"
import { CommunicationCenterContextProvider } from "../partials/communication-center/CommunicationCenterContext"
import { CommunicationCenterSidebar } from "../partials/communication-center/CommunicationCenterSidebar"
import { CommunicationCenterPageContent } from "../partials/communication-center/CommunicationCenterPageContent"
import { TopBar } from "../partials/heading/TopBar"

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    position: "relative",
    flex: 1,
  },
}))

interface ICommunicationCenterPageProps {}

export const CommunicationCenterPage: FunctionComponent<ICommunicationCenterPageProps> = (props) => {
  const classes = useStyles()
  const { t } = useTranslation()
  return (
    <CommunicationCenterContextProvider>
      <TopBar>{t("reports_page.heading")}</TopBar>
      <Grid className={classes.wrapper}>
        <CommunicationCenterSidebar />
        <CommunicationCenterPageContent />
      </Grid>
    </CommunicationCenterContextProvider>
  )
}
