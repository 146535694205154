import React, { FunctionComponent } from "react"
import { Theme, makeStyles, Grid, Typography } from "@material-ui/core"
import { useTranslation } from "react-i18next"
import { useDropzone } from "react-dropzone"
import { toast } from "react-toastify"
import { Backup } from "@material-ui/icons"

const useStyles = makeStyles((theme: Theme) => ({
  dropZone: {
    height: 200,
    width: 760,
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    borderWith: 0.5,
    borderStyle: "dashed",
    borderColor: theme.palette.secondary.light,
  },
  icon: {
    color: theme.palette.secondary.light,
    fontSize: 45,
    marginBottom: theme.spacing(1),
  },
  text: {
    fontSize: "20px",
    fontWeight: 500,
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: "1.2",
    letterSpacing: "normal",
  },
}))

interface IDropzoneProps {
  accept: string
  onFilesDropped: (acceptedFiles: File[]) => void
}

export const Dropzone: FunctionComponent<IDropzoneProps> = (props) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { accept, onFilesDropped } = props

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: onFilesDropped,
    accept,
    maxSize: 2097152,
    onDropRejected: () => {
      toast.error(t("import_tours_dialog.file_rejected"))
    },
  })

  // ts-ignore in render is necessary because of type mismatch in library (readonly string[] instead of string[])
  return (
    // @ts-ignore
    <Grid container justify="center" alignItems="center" className={classes.dropZone} {...getRootProps({})}>
      {/* @ts-ignore */}
      <input {...getInputProps()} accept={accept} />
      {!isDragActive ? (
        <Grid container justify="center" alignItems="center" direction="column">
          <Backup className={classes.icon} />
          <Typography className={classes.text}>{t("import_tours_dialog.move_files")}</Typography>
          <Typography className={classes.text}>{t("import_tours_dialog.select_files")}</Typography>
        </Grid>
      ) : (
        <Typography className={classes.text}>{t("import_tours_dialog.drop_files")}</Typography>
      )}
    </Grid>
  )
}
