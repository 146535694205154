export const MIN_ZOOM = 15

export const radiusToZoom = (radius, pixelSize = 500) => {
  // http://jeffjason.com/2011/12/google-maps-radius-to-zoom/

  // Convert radius meters to miles
  const radius_miles = (parseFloat(radius) / 1000) * 0.621371

  // Normalize radius to pixelSize (formula is based on 500px size) and project to logarithmic zoom factor
  return Math.round(
    ((14 - Math.log(radius_miles) / Math.LN2) / 500) * pixelSize
  )
}

//got from stackoverflow - do not trust
export const getZoomFromBounds = (bounds, pixelHeight, pixelWidth) => {
  const GLOBE_WIDTH = 256 // a constant in Google's map projection
  let angle = bounds.ne.lat - bounds.sw.lat
  if (angle < 0) {
    angle += 360
  }

  const zoomForHeight = Math.log((pixelHeight * 360) / angle / GLOBE_WIDTH) / Math.LN2
  if (!pixelWidth) {
    return zoomForHeight
  }

  angle = bounds.ne.lng - bounds.sw.lng
  if (angle < 0) {
    angle += 360
  }

  const zoomForWidth = Math.log((pixelWidth * 360) / angle / GLOBE_WIDTH) / Math.LN2

  return Math.min(zoomForHeight, zoomForWidth)
}
