import { gql } from "apollo-boost"
import { ReplacementPlaceholders_replacementInfo_placeholders } from "../generated/ReplacementPlaceholders"

export const REPLACEMENT_PLACEHOLDERS = gql`
  query ReplacementPlaceholders {
    replacementInfo {
      placeholders {
        key
        mark
        markForText
        ratingRestriction
        type {
          key
          description
          prefix
          suffix
        }
      }
    }
  }
`

export type TextReplacementPlaceholder = ReplacementPlaceholders_replacementInfo_placeholders
