import React, { FunctionComponent, Fragment, useCallback, useState } from "react"
import { Theme, makeStyles, Tooltip, Grid, Fab } from "@material-ui/core"
import { useTranslation } from "react-i18next"
import { IColumnHeader, EColumnHeaderType, cellForRow, createColumns, DefaultTable } from "../../table/DefaultTable"
import { Column, DerivedDataObject } from "react-table"
import { Delete } from "@material-ui/icons"
import { UnassignableAddress } from "../../../../api/graphql/query/unassignableAddressesForTown"
import { useMutation } from "react-apollo"
import {
  DeleteUnassignableAddressMutationResult,
  DeleteUnassignableAddressMutationVariables,
  DELETE_UNASSIGNABLE_ADDRESS_MUTATION,
} from "../../../../api/graphql/mutation/delete-unassignable-address"

const useStyles = makeStyles((theme: Theme) => ({}))

interface IUnassignableAddressesTableProps {
  unassignableAddresses: UnassignableAddress[]
  refetchUnassignableAddresses: () => void
}

export const UnassignableAddressesTable: FunctionComponent<IUnassignableAddressesTableProps> = (props) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { unassignableAddresses, refetchUnassignableAddresses } = props

  const [currentlyDeletingIds, setCurrentlyDeletingIds] = useState<string[]>([])

  const [deleteUnassignableAddressMutation] = useMutation<
    DeleteUnassignableAddressMutationResult,
    DeleteUnassignableAddressMutationVariables
  >(DELETE_UNASSIGNABLE_ADDRESS_MUTATION, {
    onCompleted: (data) => {
      const clone = [...currentlyDeletingIds]
      clone.splice(clone.indexOf(data.deleteUnassignableAddress, 1))
      setCurrentlyDeletingIds(clone)
      refetchUnassignableAddresses()
    },
  })

  const columnHeaders: IColumnHeader[] = [
    {
      title: t("unassignable_address.property.address"),
      type: EColumnHeaderType.STRING,
      accessor: "address",
    },
    {
      title: t("unassignable_address.property.comment"),
      type: EColumnHeaderType.STRING,
      accessor: "comment",
    },
  ]

  const onDelete = useCallback(
    (unassignableAddressId: string) => {
      console.log(unassignableAddressId)
      setCurrentlyDeletingIds([...currentlyDeletingIds, unassignableAddressId])
      deleteUnassignableAddressMutation({ variables: { id: unassignableAddressId } })
    },
    [setCurrentlyDeletingIds, currentlyDeletingIds, deleteUnassignableAddressMutation],
  )

  const columns: Column[] = createColumns(
    columnHeaders
      .map((column) => {
        return {
          Header: column.title,
          accessor: column.accessor,
          Cell: ({ row }: { row: DerivedDataObject }) => cellForRow(column, row),
        }
      })
      .concat({
        Header: "",
        accessor: "",
        Cell: ({ row }: { row: DerivedDataObject }) => {
          return (
            <Grid container item direction="row" spacing={2} justify="flex-end">
              <Grid item>
                <Tooltip title={t("general.delete")}>
                  <Fab
                    size="small"
                    color="primary"
                    onClick={() => onDelete(row._original.id)}
                    disabled={!!currentlyDeletingIds.find((id) => id === row._original.id)}
                  >
                    <Delete />
                  </Fab>
                </Tooltip>
              </Grid>
            </Grid>
          )
        },
      }),
  )

  return <DefaultTable columns={columns} data={unassignableAddresses} minRows={10} />
}
