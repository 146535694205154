import { gql } from "apollo-boost"

export const MUTATION = gql`
  mutation createWidget(
    $widgetType: WidgetType!
    $reportId: ID!
    $filterId: ID
    $title: String
    $textTitle: String
    $textContent: String
    $channelType: ChannelType
    $chartCompact: Boolean
    $payload: String
  ) {
    createWidget(
      widgetType: $widgetType
      reportId: $reportId
      filterId: $filterId
      title: $title
      textTitle: $textTitle
      textContent: $textContent
      channelType: $channelType
      chartCompact: $chartCompact
      payload: $payload
    ) {
      id
      widgetType
    }
  }
`
