import React, { FunctionComponent } from "react"
import { ClipLoader } from "react-spinners"
import { useTranslation } from "react-i18next"
import {
  Grid,
  makeStyles,
  Theme,
  useTheme,
  Typography
} from "@material-ui/core"

interface IPageLoadingIndicatorProps {
  label?: string
}

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: "flex",
    alignContent: "center",
    alignItems: "center",
    justifyContent: "center",
    height: "50vh",
    flexDirection: "column"
  }
}))

export const PageLoadingIndicator: FunctionComponent<
  IPageLoadingIndicatorProps
> = props => {
  const { label } = props
  const { t } = useTranslation()
  const classes = useStyles()
  const theme = useTheme()
  return (
    <Grid container className={classes.container} justify="center">
      <ClipLoader color={theme.palette.primary.main} />
      <br />
      <Grid item>
        {label || (
          <Typography color="primary">{t("general.data_loading")}</Typography>
        )}
      </Grid>
    </Grid>
  )
}
