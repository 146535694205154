import React, { FunctionComponent, useState, ChangeEvent } from "react"
import {
  Grid,
  TextField,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  makeStyles,
  Theme,
} from "@material-ui/core"
import { useTranslation } from "react-i18next"
import { useMutation } from "react-apollo"
import { toast } from "react-toastify"
import {
  SendPasswordResetMailResult,
  SendPasswordResetMailVariables,
  SEND_PASSWORD_RESET_MAIL_MUTATION,
} from "../../../api/graphql/mutation/send-password-reset-mail"
import validator from "email-validator"

const useStyles = makeStyles((theme: Theme) => ({
  dialog: {
    padding: theme.spacing(3),
  },
  dialogTitle: {
    padding: theme.spacing(3),
    paddingBottom: theme.spacing(1),
  },
  dialogContent: {
    padding: theme.spacing(3),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
}))
interface IPasswordResetDialogProps {
  isOpen: boolean
  onToggleOpenDialog: (isOpen: boolean) => void
}

export const PasswordResetDialog: FunctionComponent<IPasswordResetDialogProps> = (props) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const { isOpen, onToggleOpenDialog } = props

  const [sendPasswordResetMailMutation] = useMutation<SendPasswordResetMailResult, SendPasswordResetMailVariables>(
    SEND_PASSWORD_RESET_MAIL_MUTATION,
    {
      onError: (error) => {
        toast.error(t("login_page.password_reset_error"))
      },
      onCompleted: () => {
        toast.success(t("login_page.password_reset_success"))
        onToggleOpenDialog(false)
      },
    },
  )

  const [email, setEmail] = useState<string>("")

  const onResetPasswordConfirm = async () => {
    sendPasswordResetMailMutation({ variables: { email } })
  }

  const handleEmailChange = (event: ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value)
  }

  const onClosePasswordResetDialog = () => {
    onToggleOpenDialog(false)
  }

  const isValid = () => {
    return validator.validate(email)
  }

  return (
    <Dialog open={isOpen} fullWidth maxWidth="sm" onClose={onClosePasswordResetDialog}>
      <DialogTitle className={classes.dialogTitle}>{t("login_page.password_reset_dialog.title")}</DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <Grid item xs={12}>
          <TextField
            type="email"
            name="email"
            fullWidth
            label={t("login_page.email")}
            value={email}
            onChange={handleEmailChange}
          />
        </Grid>
      </DialogContent>
      <DialogActions className={classes.dialog}>
        <Button color="primary" variant="outlined" onClick={onClosePasswordResetDialog}>
          {t("login_page.password_reset_dialog.cancel")}
        </Button>
        <Button color="primary" variant="contained" onClick={onResetPasswordConfirm} disabled={!isValid()}>
          {t("login_page.password_reset_dialog.confirm")}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
