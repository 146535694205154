import { gql } from "apollo-boost"
import { citizensForTown, citizensForTownVariables, citizensForTown_town_citizens } from "../generated/citizensForTown"

export const CITIZENS_FOR_TOWN_QUERY = gql`
  query citizensForTown($id: ID!) {
    town(id: $id) {
      citizens {
        id
        firstName
        lastName
        rfids
        address
        detailedAddress {
          city
          street
          streetNumber
          postalcode
        }
        phones
        email
        deviceId
        preferredCommunicationChannels
      }
    }
  }
`

export type CitizensForTownResult = citizensForTown
export type CitizenForTown = citizensForTown_town_citizens
export type CitizensForTownVariables = citizensForTownVariables
