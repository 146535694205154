import React, { FunctionComponent, Fragment, useState } from "react"
import {
  Theme,
  makeStyles,
  ListItem,
  Grid,
  Typography,
  FormControlLabel,
  Checkbox,
  Divider,
  Fade,
  TextField,
  ListItemAvatar,
  Avatar,
  ListItemText,
  List
} from "@material-ui/core"
import { ExpandMore, Clear, LocationCity } from "@material-ui/icons"
import { useTranslation } from "react-i18next"
import { Autocomplete } from "@material-ui/lab"
import {
  UserAndPermissionsAssociation,
} from "../../../../api/graphql/query/users-and-assigned-associations"
import { TownListItem } from "./TownListItem"
import { TownsOfAssociationWithTowns } from "../../../../api/graphql/fragments/association-with-town"

const useStyles = makeStyles((theme: Theme) => ({
  clearButton: {
    cursor: "pointer",
    color: theme.palette.secondary.dark
  },
  clickableTypo: {
    cursor: "pointer",
    color: theme.palette.secondary.dark
  },
  formLabelRoot: {
    margin: 0,
    marginTop: theme.spacing(1)
  },
  checkboxRoot: {
    padding: 0,
    marginRight: theme.spacing(1)
  },
  iconOpen: {
    transform: "scaleY(-1)"
  },
  chip: {
    margin: theme.spacing(1)
  },
  infoTypo: {
    color: theme.palette.secondary.main,
    fontSize: 14
  },
  infoTypoContainer: {
    height: "100%"
  },
  townSelectionSection: {
    minHeight: "8vh"
  },
  listItem: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    alignItems: "flex-start"
  },
  closeableSection: {
    marginLeft: "1.3em"
  },
  townSelectionTextField: {
    marginTop: theme.spacing(1)
  },
  townListContainer: {
    marginTop: theme.spacing(1)
  },
  townList: {
    width: "100%"
  }
}))

interface IAssociationListItemProps {
  association: UserAndPermissionsAssociation,
  townOptions: TownsOfAssociationWithTowns[],
  onRemove: (removed: UserAndPermissionsAssociation) => void,
  onTownsChange: (
    newValue: TownsOfAssociationWithTowns[],
    allTownsSelected: boolean
  ) => void
}

export const AssociationListItem: FunctionComponent<IAssociationListItemProps> = props => {
  const classes = useStyles()
  const { association, onRemove, onTownsChange, townOptions } = props
  const { t } = useTranslation()
  const [accessToAllTowns, setAccessToAllTowns] = useState<boolean>(
    association.accessToAllTowns
  )

  const onAccessToAllTownsChange = () => {
    onTownsChange([], !accessToAllTowns)
    setAccessToAllTowns(!accessToAllTowns)
  }

  const onRemoveTown = (index: number) => {
    const newTowns = [...association.towns]
    newTowns.splice(index, 1)
    onTownsChange(newTowns, accessToAllTowns)
  }

  const [open, setOpen] = useState<boolean>(false)

  return (
    <Fragment key={association.id}>
      <ListItem className={classes.listItem}>
        <ListItemAvatar>
          <Avatar>
            <LocationCity />
          </Avatar>
        </ListItemAvatar>
        <ListItemText>
          <Grid container>
            <Grid
              item
              xs={12}
              container
              alignContent="flex-start"
              alignItems="flex-start"
              justify="space-between"
            >
              <Grid item>
                <Grid container justify="flex-start">
                  <ExpandMore
                    onClick={() => setOpen(!open)}
                    className={
                      open
                        ? `${classes.iconOpen} ${classes.clearButton}`
                        : `${classes.clearButton}`
                    }
                  />
                  <Typography
                    onClick={() => setOpen(!open)}
                    className={classes.clickableTypo}
                  >
                    {association.name}
                  </Typography>
                </Grid>
              </Grid>
              <Grid item>
                <Grid container justify="flex-end">
                  <Clear
                    onClick={() => onRemove(association)}
                    className={classes.clearButton}
                  />
                </Grid>
              </Grid>
            </Grid>
            {open && (
              <Fade in={open}>
                <Grid
                  container
                  item
                  xs={12}
                  className={classes.closeableSection}
                >
                  <Grid item xs={12} container justify="flex-start">
                    <FormControlLabel
                      classes={{ root: classes.formLabelRoot }}
                      control={
                        <Checkbox
                          checked={accessToAllTowns}
                          onClick={onAccessToAllTownsChange}
                          classes={{
                            root: classes.checkboxRoot
                          }}
                        />
                      }
                      label={t("user_management.assign_all_towns")}
                    />
                  </Grid>
                  <Grid item xs={12} className={classes.townSelectionSection}>
                    {accessToAllTowns && (
                      <Grid
                        container
                        alignContent="center"
                        alignItems="center"
                        className={classes.infoTypoContainer}
                      >
                        <Grid container className={classes.townListContainer}>
                          <List className={classes.townList}>
                            {townOptions.map((town, index) => (
                              <TownListItem
                                key={town.id}
                                town={town}
                                index={index}
                              />
                            ))}
                          </List>
                        </Grid>
                      </Grid>
                    )}
                    {!accessToAllTowns && (
                      <Autocomplete
                        multiple
                        options={townOptions}
                        value={association.towns}
                        getOptionLabel={option => option.name}
                        onChange={(event, newValue) =>
                          onTownsChange(newValue, accessToAllTowns)
                        }
                        disableClearable
                        loadingText={t("general.data_loading")}
                        renderTags={() => null}
                        renderInput={params => (
                          <TextField
                            className={classes.townSelectionTextField}
                            name="abilities"
                            {...params}
                            variant="standard"
                            label={t("general.towns")}
                            fullWidth
                          />
                        )}
                      />
                    )}
                    {!accessToAllTowns && (
                      <Grid container className={classes.townListContainer}>
                        <List className={classes.townList}>
                          {association.towns.map((town, index) => (
                            <TownListItem
                              key={town.id}
                              town={town}
                              index={index}
                              onRemove={() => onRemoveTown(index)}
                              removable
                            />
                          ))}
                        </List>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              </Fade>
            )}
          </Grid>
        </ListItemText>
      </ListItem>
      <Divider component="li" />
    </Fragment>
  )
}
