import Dashboard from "../components/partials/layout/Dashboard.jsx"
import { LoginPage } from "../components/pages/LoginPage"
import { PasswordResetPage } from "../components/pages/PasswordResetPage"
import { VerifyCommunicationChannelPage } from "../components/pages/VerifyCommunicationChannelPage"

var indexRoutes = [
  { path: "/login", name: "Anmelden", component: LoginPage }, //maybe use later
  {
    path: "/reset-password",
    name: "PasswortReset",
    render: () => <PasswordResetPage initiallySetPassword={false} />,
  },
  {
    path: "/set-password",
    name: "PasswortSet",
    render: () => <PasswordResetPage initiallySetPassword />,
  },
  {
    path: "/vcc",
    name: "VerifyCommunicationChannel",
    component: VerifyCommunicationChannelPage,
  },
  {
    path: "/verify-communication-channel", // deprecated
    name: "VerifyCommunicationChannel",
    component: VerifyCommunicationChannelPage,
  },
  { path: "/", name: "Home", component: Dashboard },
]

export default indexRoutes
