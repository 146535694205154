import { rfidChips_rfidChips_detailedAddress } from "../api/graphql/generated/rfidChips"

export const compareDetailedAddress = (
  detailedAddress1: rfidChips_rfidChips_detailedAddress | null,
  detailedAddress2: rfidChips_rfidChips_detailedAddress | null,
) => {
  if (detailedAddress1 === null) {
    return 1
  }
  if (detailedAddress2 === null) {
    return -1
  }

  return `${detailedAddress2.street} ${detailedAddress2.streetNumber}, ${detailedAddress2.postalcode} ${detailedAddress2.city}`.localeCompare(
    `${detailedAddress1.street} ${detailedAddress1.streetNumber}, ${detailedAddress1.postalcode} ${detailedAddress1.city}`,
  )
}
