import React, { FunctionComponent, Fragment } from "react"
import { Theme, makeStyles } from "@material-ui/core"
import { useTranslation } from "react-i18next"
import { TopBar } from "../partials/heading/TopBar"
import { RfidGroupsPageContent } from "../partials/rfid-groups/RfidGroupsPageContent"

const useStyles = makeStyles((theme: Theme) => ({}))

interface IRfidGroupsPageProps {}

export const RfidGroupsPage: FunctionComponent<IRfidGroupsPageProps> = (props) => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <Fragment>
      <TopBar>{t("rfid_groups.heading")}</TopBar>
      <RfidGroupsPageContent />
    </Fragment>
  )
}
