import React, { FunctionComponent, useState, Fragment, useEffect } from "react"
import { TownTableForAssociation } from "./TownTableForAssociation"
import lodash from "lodash"
import { ManageTownDialog } from "./ManageTownDialog"
import {
  PermittedAssociationsForUserResult,
  UserAndPermissionsAssociation,
} from "../../../api/graphql/query/permitted-associations-for-user"
import { TownForAssociation } from "../../../api/graphql/query/towns-for-association"
import { ApolloQueryResult } from "apollo-client"
import { AssociationExpansionPanel } from "./AssociationExpansionPanel"
import { useLazyQuery } from "react-apollo"
import {
  DefaultClassifiersForTownQueryResult,
  DefaultClassifiersForTownQueryVariables,
  DEFAULT_CLASSIFIERS_FOR_TOWN_QUERY,
} from "../../../api/graphql/query/defaultClassifiersForTown"
import { ClassifierForTourTypeInput } from "../../../api/graphql/generated/globalTypes"

interface IAssociationExpansionPanelWithTownTableProps {
  association: UserAndPermissionsAssociation
  setAssociationToEdit: (association: UserAndPermissionsAssociation) => void
  setTownsForAssociationToEdit: (town: TownForAssociation[]) => void
  onShouldReload: () => Promise<ApolloQueryResult<PermittedAssociationsForUserResult>>
}

export const AssociationExpansionPanelWithTownTable: FunctionComponent<IAssociationExpansionPanelWithTownTableProps> = (
  props,
) => {
  const { association, setAssociationToEdit, setTownsForAssociationToEdit, onShouldReload } = props
  // we use an extra variable for isOpen, so we can remove the town table from the dom (lazy loading for towns)
  // could also be used for lazy loading the details of an association, but since there arent that many, this isnt necessary
  const [open, setOpen] = useState<boolean>(false)
  const [toggleRefetch, setToggleRefetch] = useState<boolean>(false)
  const [initialData, setInitialData] = useState<ClassifierForTourTypeInput[]>([])

  // Town Management Dialog Stuff
  const [townManagementDialogOpen, setTownManagementDialogOpen] = useState<boolean>(false)
  const [townToEdit, setTownToEdit] = useState<TownForAssociation | undefined>(undefined)

  useEffect(() => {
    setTownManagementDialogOpen(!lodash.isNil(townToEdit))
  }, [townToEdit])

  const onTownManagementDialogClose = () => {
    setTownManagementDialogOpen(false)
    setTownToEdit(undefined)
  }

  const onTownManagementDialogSuccess = () => {
    const reload = async () => {
      await onShouldReload()
      setToggleRefetch(!toggleRefetch)
    }
    reload()
    onTownManagementDialogClose()
  }

  const [fetchInitialClassifiers] = useLazyQuery<
    DefaultClassifiersForTownQueryResult,
    DefaultClassifiersForTownQueryVariables
  >(DEFAULT_CLASSIFIERS_FOR_TOWN_QUERY, {
    onCompleted: (data) => {
      setInitialData(data.defaultClassifiersForTown)
    },
    fetchPolicy: "no-cache",
  })

  const onSetTownForAssociationToEdit = (town: TownForAssociation) => {
    fetchInitialClassifiers({
      variables: {
        townId: town.id,
      },
    })
    setTownToEdit(town)
  }

  const onAddTownClicked = () => {
    setTownManagementDialogOpen(true)
    setInitialData([])
  }

  return (
    <Fragment>
      {/* DIALOGS */}
      {townManagementDialogOpen && (
        <ManageTownDialog
          onCloseDialog={onTownManagementDialogClose}
          onSuccess={onTownManagementDialogSuccess}
          isOpen={townManagementDialogOpen}
          town={townToEdit}
          association={association}
          initialData={initialData}
        />
      )}
      {/* VIEW */}
      <AssociationExpansionPanel
        open={open}
        toggle={() => setOpen(!open)}
        association={association}
        onEdit={() => setAssociationToEdit(association)}
        onAdd={onAddTownClicked}
      >
        <TownTableForAssociation
          association={association}
          setTownToEdit={onSetTownForAssociationToEdit}
          toggleRefetch={toggleRefetch}
          setTownsForAssociationToEdit={setTownsForAssociationToEdit}
        />
      </AssociationExpansionPanel>
    </Fragment>
  )
}
