import React, { FunctionComponent } from "react"
import { TourPageContent } from "../partials/tour-management/TourPageContent"
import { TourUrlContextProvider } from "../../context/TourUrlContext"
import { TourFilterProvider } from "../../context/TourFilterContext"

interface ITourPageProps {}

export const TourPage: FunctionComponent<ITourPageProps> = (props) => {
  return (
    <TourFilterProvider>
      <TourUrlContextProvider>
        <TourPageContent />
      </TourUrlContextProvider>
    </TourFilterProvider>
  )
}
