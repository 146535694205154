import React, { Fragment, useMemo, useContext } from "react"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  MenuItem,
  Select,
  makeStyles,
  TextField,
  Theme,
  InputLabel,
  FormControl,
  Typography,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core"
import { useMutation, useQuery, useLazyQuery } from "@apollo/react-hooks"
import lodash from "lodash"
import { TourInput, MappedClassification } from "../../../api/graphql/generated/globalTypes"
import moment, { Moment } from "moment"
import {
  CREATE_TOURS_MUTATION,
  CreateToursResult,
  CreateToursVariables,
} from "../../../api/graphql/mutation/create-tour"
import { UPDATE_TOUR_MUTATION, UpdateTourResult, UpdateTourVariables } from "../../../api/graphql/mutation/update-tour"
import { DELETE_TOUR_MUTATION, DeleteTourResult, DeleteTourVariables } from "../../../api/graphql/mutation/delete-tour"
import { KeyboardTimePicker, KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers"
import { titleForTourType, TourTypeDictionary, tourTypeInfoForValue } from "../../../domain/models/TourTypes"
import { toast } from "react-toastify"
import { VehiclesResult, VEHICLES_QUERY, Vehicle } from "../../../api/graphql/query/vehicles"
import { VALIDATE_TOUR_QUERY, ValidateTourResult } from "../../../api/graphql/query/validate-tour"
import { sortASC } from "../../../utils/sort"
import { TourWarningsDialog } from "./TourWarningsDialog"
import { Autocomplete } from "@material-ui/lab"
import { UserAndPermissionsTown } from "../../../api/graphql/query/permitted-associations-for-user"
import { Tour } from "../../../api/graphql/query/tours"
import { PermittedAssociationsContext } from "../../../context/PermittedAssociationsContext"
import { ManageTourDialogActions } from "./ManageTourDialogActions"
import { WertstoffMappedClassificationDropdown } from "../controls/WertstoffMappedClassificationDropdown"
import { AuthContext } from "../../../context/AuthContext"
import MomentUtils from "@date-io/moment"

moment.locale("de")

const useStyles = makeStyles((theme: Theme) => ({
  separator: {
    width: "100%",
    height: 1,
    backgroundColor: "#979797",
    marginBottom: 37,
  },
  contentRoot: {
    paddingLeft: 0,
    paddingRight: 0,
  },
  content: {
    paddingLeft: 24,
    paddingRight: 24,
    paddingTop: 5,
    paddingBottom: 17,
  },
  highlightedText: {
    backgroundColor: "#D5D5D5",
  },
  menuItemRoot: {
    backgroundColor: "transparent",
    "&:focus": {
      background: "transparent",
    },
  },
  dialogRoot: {
    minHeight: "200px",
    minWidth: "360px",
  },
  formControl: {
    width: "100%",
    position: "relative",
  },
  checkBox: {
    marginLeft: theme.spacing(0.5),
  },
  checkBoxContainer: {
    paddingBottom: 0,
    paddingTop: theme.spacing(2),
    flexGrow: 0,
    maxWidth: "33.333333%",
    flexBasis: "33.333333%",
  },
  textFieldContainer: {
    margin: 0,
  },
}))

const momentFrom = (tour: Tour | undefined, path: string): Moment | null => {
  if (lodash.isNil(tour)) {
    return null
  }
  let param = lodash.get(tour, path)
  if (lodash.isNil(param)) {
    return null
  }
  if (lodash.isString(param)) {
    return moment(param)
  }
  let momentParam = moment(param)
  if (momentParam.isValid()) {
    return momentParam
  }
  return moment()
}

interface ITimeBoundaries {
  min: Moment | undefined
  max: Moment | undefined
}
const getTimeBoundariesForTour = (tour: Tour | undefined): ITimeBoundaries => {
  if (!tour) {
    return {
      min: undefined,
      max: undefined,
    }
  }

  const firstDate = lodash.first(tour.newestAnalysis)?.metadata.recordedAt
  const oldestDate = lodash.first(tour.oldestAnalysis)?.metadata.recordedAt

  if (!firstDate || !oldestDate) {
    return {
      min: undefined,
      max: undefined,
    }
  }

  return {
    min: moment(firstDate).utc(),
    max: moment(oldestDate).utc(),
  }
}

export interface IManageTourDialog {
  tour: Tour | undefined
  onSuccess: (tourId: string) => void
  isOpen: boolean
  onCloseDialog: () => void
}

export const ManageTourDialog: React.FunctionComponent<IManageTourDialog> = ({
  tour,
  onCloseDialog,
  isOpen,
  onSuccess,
}) => {
  const classes = useStyles()
  const { t, i18n } = useTranslation()
  const { permittedTowns, permittedAssociations } = useContext(PermittedAssociationsContext)

  const isInCreateMode = useMemo(() => lodash.isNil(tour), [tour])

  //for editing a tour after it has received analysis data
  const materialAnalysisCount = tour?.materialAnalysisCount ?? 0
  const tourHasAnalysisData = materialAnalysisCount > 0
  const { min, max } = getTimeBoundariesForTour(tour)
  const { isAdmin } = useContext(AuthContext)

  let tourAsInput: TourInput = {
    ...tour,
    townIds: tour?.towns.map((town) => town.id),
    vehicleId: tour?.vehicle.id,
    tourStart: moment(tour?.tourStart),
    tourEnd: moment(tour?.tourEnd),
    includesSplittedImages: tour?.includesSplittedImages ?? false,
    mappedClassification: tour?.mappedClassification || MappedClassification.ORIGINAL,
  } as TourInput
  const [modifiedTour, setModifiedTour] = useState<TourInput>(tourAsInput)
  const [isTourWarningsDialogOpen, setIsTourWarningsDialogOpen] = useState<boolean>(false)

  const { data } = useQuery<VehiclesResult>(VEHICLES_QUERY, {
    onCompleted: (data) => {
      const vehicle = (data?.me?.vehicles || []).find((vehicle) => vehicle.id === modifiedTour.vehicleId)
      const geoCeptImei = modifiedTour.geoCeptImei ? modifiedTour.geoCeptImei : vehicle?.geoCeptImei
      const rfidReader = modifiedTour.rfidReader ? modifiedTour.rfidReader : vehicle?.rfidReader

      setModifiedTour({ ...modifiedTour, geoCeptImei, rfidReader })
    },
  })
  const vehicles = lodash.get(data, "me.vehicles", []) as Vehicle[]

  useEffect(() => {
    function validateInput() {
      if (
        lodash.isNil(modifiedTour.tourStart) ||
        lodash.isNil(modifiedTour.tourEnd) ||
        lodash.isNil(modifiedTour.tourType) ||
        lodash.isNil(modifiedTour.vehicleId) ||
        lodash.isEmpty(modifiedTour.townIds) ||
        lodash.isEmpty(modifiedTour.tourStart) ||
        lodash.isEmpty(modifiedTour.tourEnd) ||
        lodash.isEmpty(modifiedTour.tourType) ||
        lodash.isEmpty(modifiedTour.vehicleId) ||
        moment(modifiedTour.tourStart).isBetween(min, max) ||
        moment(modifiedTour.tourEnd).isBetween(min, max)
      ) {
        setIsValid(false)
        return
      }

      setIsValid(true)
    }
    validateInput()
  }, [modifiedTour, isInCreateMode, min, max])

  const [isValid, setIsValid] = useState<boolean>(false)
  const [selectedTowns, setSelectedTowns] = useState<UserAndPermissionsTown[]>(
    permittedTowns.filter((at) => tour?.towns?.find((t) => t.id === at.id)),
  )
  const [selectedDate, setSelectedDate] = useState<Moment | null>(momentFrom(tour, "tourStart"))
  const [selectedStartTime, setSelectedStartTime] = useState<Moment | null>(momentFrom(tour, "tourStart"))
  const [selectedEndTime, setSelectedEndTime] = useState<Moment | null>(momentFrom(tour, "tourEnd"))

  const onStartTimeChanged = (time: Moment | null) => {
    setSelectedStartTime(time)
    applySelectedTourTimes(time, selectedEndTime, selectedDate)
  }

  const onEndTimeChanged = (time: Moment | null) => {
    setSelectedEndTime(time)
    applySelectedTourTimes(selectedStartTime, time, selectedDate)
  }

  const onDateChanged = (date: Moment | null) => {
    setSelectedDate(date)
    applySelectedTourTimes(selectedStartTime, selectedEndTime, date)
  }

  const applySelectedTourTimes = (startTime: Moment | null, endTime: Moment | null, date: Moment | null) => {
    if (lodash.isNil(date) || lodash.isNil(startTime) || lodash.isNil(endTime)) {
      return
    }

    const newTourStart = date.clone().set({
      hour: startTime.get("hour"),
      minute: startTime.get("minute"),
      second: 0,
    })

    const newTourEnd = date.clone().set({
      hour: endTime.get("hour"),
      minute: endTime.get("minute"),
      second: 0,
    })

    setModifiedTour((tour) => {
      return {
        ...tour,
        tourStart: newTourStart,
        tourEnd: newTourEnd,
      }
    })
  }

  const [validateTour, { data: validationResult }] = useLazyQuery<ValidateTourResult>(VALIDATE_TOUR_QUERY, {
    onCompleted: () => handleTourValidation(),
    fetchPolicy: "no-cache",
  })

  const [createToursMutation, { loading: createToursMutationLoading }] = useMutation<
    CreateToursResult,
    CreateToursVariables
  >(CREATE_TOURS_MUTATION, {
    onError: (error) => {
      console.log("error: ", error)
    },
    onCompleted: (data) => {
      console.log("create success: ", data)
      if (data.createTours && data.createTours[0]) {
        onSuccess(data.createTours[0].id)
      }
    },
  })

  const handleTourValidation = async () => {
    if (validationResult && validationResult.validateTour) {
      if (validationResult.validateTour.errors.length) {
        toast.error(t(`tour_page.errors.${validationResult.validateTour.errors[0]!.type.toLowerCase()}`))
      } else if (validationResult.validateTour.warnings.length) {
        setIsTourWarningsDialogOpen(true)
      } else {
        if (isInCreateMode) {
          await createTour()
        } else {
          await updateTour()
        }
      }
    }
  }

  const createTour = async () => {
    setIsTourWarningsDialogOpen(false)
    await createToursMutation({
      variables: { tours: [modifiedTour as TourInput] },
    })
  }

  const [updateTourMutation, { loading: updateTourMutationLoading }] = useMutation<
    UpdateTourResult,
    UpdateTourVariables
  >(UPDATE_TOUR_MUTATION, {
    onError: (error) => {
      console.log("error: ", error)
    },
    onCompleted: (data) => {
      if (tour) {
        onSuccess(tour.id)
      }
    },
  })

  const updateTour = async () => {
    const tourToModify: TourInput = {
      tourEnd: modifiedTour.tourEnd,
      tourStart: modifiedTour.tourStart,
      tourType: modifiedTour.tourType,
      townIds: modifiedTour.townIds,
      vehicleId: modifiedTour.vehicleId,
      rfidReader: modifiedTour.rfidReader,
      geoCeptImei: modifiedTour.geoCeptImei,
      includesSplittedImages: modifiedTour.includesSplittedImages,
      mappedClassification: modifiedTour.mappedClassification,
    }

    await updateTourMutation({
      variables: { id: lodash.get(tour, "id", ""), tour: tourToModify },
    })
  }

  const [deleteTourMutation, { loading: deleteTourMutationLoading }] = useMutation<
    DeleteTourResult,
    DeleteTourVariables
  >(DELETE_TOUR_MUTATION, {
    onError: (error) => {
      console.log("error: ", error)
    },
    onCompleted: (data) => {
      if (tour) {
        onSuccess(tour.id)
      }
    },
  })
  const onDeleteTour = async () => {
    if (lodash.isString(lodash.get(tour, "id"))) {
      await deleteTourMutation({
        variables: { id: lodash.get(tour, "id", "") },
      })
    }
  }

  const onTownSelected = (newSelectedTowns: UserAndPermissionsTown[]) => {
    setSelectedTowns(newSelectedTowns)
    setModifiedTour((m) => {
      return { ...m, townIds: newSelectedTowns.map((town) => town.id) }
    })
  }

  const renderWasteScannerId = (value: string): string => {
    const wasteScannerId = lodash.get(
      vehicles.find((vehicle) => vehicle.id === value),
      "wasteScannerId",
      "",
    )
    return wasteScannerId
  }

  const availableTownsForAssociation = useMemo(() => {
    if (selectedTowns.length > 0) {
      return permittedTowns
        .filter((town) => selectedTowns[0].associationId === town.associationId)
        .filter((town) => !selectedTowns.find((selectedTown) => selectedTown.id === town.id))
    }
    return permittedTowns.sort((a, b) => sortASC(a.name.toLowerCase(), b.name.toLowerCase()))
  }, [selectedTowns, permittedTowns])

  const language = useMemo(() => {
    const allLocals = moment.locales()
    const locale = allLocals.find((momentLocale) => momentLocale.startsWith(i18n.language, 0))
    moment.locale(locale)
    return locale || allLocals[0]
  }, [i18n.language])

  return (
    <MuiPickersUtilsProvider utils={MomentUtils} libInstance={moment} locale={language}>
      <TourWarningsDialog
        isOpen={isTourWarningsDialogOpen}
        isInCreateMode={isInCreateMode}
        onCloseDialog={() => setIsTourWarningsDialogOpen(false)}
        onConfirmClicked={isInCreateMode ? createTour : updateTour}
        warnings={lodash.get(validationResult, "validateTour.warnings", [])}
      />
      <Dialog
        open={isOpen}
        fullWidth={false}
        className={classes.dialogRoot}
        classes={{ paper: classes.dialogRoot }}
        onClose={onCloseDialog}
      >
        <form style={{ display: "contents" }}>
          <DialogTitle>{isInCreateMode ? t("tour.dialog.create.title") : t("tour.dialog.update.title")}</DialogTitle>
          <DialogContent>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={8} className={classes.textFieldContainer}>
                <Autocomplete<UserAndPermissionsTown>
                  options={availableTownsForAssociation}
                  multiple
                  value={selectedTowns}
                  getOptionLabel={(town) => town.name}
                  onChange={(event, newValue) => onTownSelected(newValue)}
                  renderInput={(params) => (
                    <TextField
                      name="abilities"
                      {...params}
                      variant="standard"
                      margin="none"
                      fullWidth
                      label={t("tour.property.towns")}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={4} className={classes.textFieldContainer}>
                <TextField
                  label={t("tour.property.association")}
                  value={
                    permittedAssociations.find(
                      (association) => selectedTowns[0]?.associationId === association.associationId,
                    )?.name ?? ""
                  }
                  disabled
                  fullWidth
                />
              </Grid>
              <Grid item xs={isAdmin() ? 4 : 8} className={classes.textFieldContainer}>
                <FormControl className={classes.formControl}>
                  <InputLabel required id="tourtype">
                    {t("tour.property.tourType")}
                  </InputLabel>
                  <Select
                    fullWidth
                    renderValue={(value) => titleForTourType(value as string, t)}
                    value={lodash.get(modifiedTour, "tourType", "")}
                    displayEmpty={false}
                    onChange={({ target: { value } }) => {
                      setModifiedTour({
                        ...modifiedTour,
                        tourType: tourTypeInfoForValue(value as string)!.type,
                      })
                    }}
                    disabled={tourHasAnalysisData}
                  >
                    {TourTypeDictionary.sort((a, b) => (a.title === b.title ? 0 : a.title < b.title ? -1 : 1)).map(
                      (tourType) => (
                        <MenuItem classes={{ root: classes.menuItemRoot }} key={tourType.type} value={tourType.type}>
                          {t(tourType.title)}
                        </MenuItem>
                      ),
                    )}
                  </Select>
                </FormControl>
              </Grid>
              {isAdmin() && (
                <Grid item xs={4} className={classes.textFieldContainer}>
                  <WertstoffMappedClassificationDropdown
                    automatic={null}
                    mappedClassification={modifiedTour.mappedClassification}
                    onMappedClassificationUpdated={(mappedClassification) =>
                      setModifiedTour({ ...modifiedTour, mappedClassification })
                    }
                  />
                </Grid>
              )}
              <Grid item xs={4} className={classes.textFieldContainer}>
                <FormControl className={classes.formControl}>
                  <InputLabel required id="tourtype">
                    {t("tour.property.wasteScanner")}
                  </InputLabel>
                  <Select
                    fullWidth
                    required
                    renderValue={(value) => renderWasteScannerId(value as string)}
                    value={lodash.get(modifiedTour, "vehicleId", "")}
                    displayEmpty={false}
                    onChange={(event) => {
                      const vehicle = vehicles.find((vehicle) => vehicle.id === event.target.value)
                      setModifiedTour({
                        ...modifiedTour,
                        rfidReader: vehicle?.rfidReader || "",
                        geoCeptImei: vehicle?.geoCeptImei || "",
                        vehicleId: event.target.value as string,
                      })
                    }}
                    disabled={tourHasAnalysisData}
                  >
                    {vehicles
                      .sort((a, b) => sortASC(a.wasteScannerId, b.wasteScannerId))
                      .map((vehicle) => (
                        <MenuItem classes={{ root: classes.menuItemRoot }} key={vehicle.id} value={vehicle.id}>
                          {vehicle.wasteScannerId}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={4} className={classes.textFieldContainer}>
                <TextField
                  label={t("tour.property.geocept_device")}
                  value={modifiedTour?.geoCeptImei || ""}
                  onChange={(event) => {
                    setModifiedTour({
                      ...modifiedTour,
                      geoCeptImei: event.target.value,
                    })
                  }}
                  fullWidth
                />
              </Grid>
              <Grid item xs={4} className={classes.textFieldContainer}>
                <TextField
                  label={t("tour.property.rfidReader")}
                  value={modifiedTour?.rfidReader || ""}
                  onChange={(event) => {
                    setModifiedTour({
                      ...modifiedTour,
                      rfidReader: event.target.value,
                    })
                  }}
                  fullWidth
                />
              </Grid>
              <div className={classes.checkBoxContainer}>
                <FormControlLabel
                  control={
                    <Checkbox
                      disabled={tourHasAnalysisData}
                      color="primary"
                      className={classes.checkBox}
                      checked={modifiedTour.includesSplittedImages}
                      onChange={() =>
                        setModifiedTour({
                          ...modifiedTour,
                          includesSplittedImages: !modifiedTour.includesSplittedImages,
                        })
                      }
                    />
                  }
                  label={t("tour.property.is_splitted")}
                />
              </div>
              <Grid item xs={4} className={classes.textFieldContainer}>
                <KeyboardDatePicker
                  invalidDateMessage={t("validation.date.notpossible")}
                  fullWidth
                  required
                  color="primary"
                  format="DD.MM.YYYY"
                  margin="normal"
                  id="date-picker-inline"
                  label={t("tour.property.date")}
                  value={selectedDate}
                  onChange={onDateChanged}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                  disabled={tourHasAnalysisData}
                  okLabel={t("general.ok")}
                />
              </Grid>
              <Grid item xs={4} className={classes.textFieldContainer}>
                <KeyboardTimePicker
                  ampm={false}
                  mask="__:__"
                  required
                  invalidDateMessage={t("validation.time.notpossible")}
                  fullWidth
                  color="primary"
                  margin="normal"
                  id="time-picker"
                  label={t("tour.property.startTime")}
                  KeyboardButtonProps={{
                    "aria-label": "change time",
                  }}
                  value={selectedStartTime}
                  onChange={onStartTimeChanged}
                />
              </Grid>
              <Grid item xs={4} className={classes.textFieldContainer}>
                <KeyboardTimePicker
                  ampm={false}
                  mask="__:__"
                  invalidDateMessage={t("validation.time.notpossible")}
                  required
                  fullWidth
                  color="primary"
                  margin="normal"
                  id="time-picker"
                  label={t("tour.property.endTime")}
                  KeyboardButtonProps={{
                    "aria-label": "change time",
                  }}
                  value={selectedEndTime}
                  onChange={onEndTimeChanged}
                />
              </Grid>
              {tourHasAnalysisData && min && max && (
                <Grid item xs={12}>
                  <Typography color="secondary">
                    {t("tour.dialog.update.time_info", {
                      min: min.local().format("HH:mm"),
                      max: max.local().format("HH:mm"),
                    })}
                  </Typography>
                </Grid>
              )}
            </Grid>
          </DialogContent>
          <ManageTourDialogActions
            isInCreateMode={isInCreateMode}
            onDeleteTour={onDeleteTour}
            tour={tour}
            deleteTourMutationLoading={deleteTourMutationLoading}
            updateTourMutationLoading={updateTourMutationLoading}
            createToursMutationLoading={createToursMutationLoading}
            onClose={onCloseDialog}
            isValid={isValid}
            modifiedTour={modifiedTour}
            validateTour={validateTour}
          />
        </form>
      </Dialog>
    </MuiPickersUtilsProvider>
  )
}
