import React, { FunctionComponent, Fragment, useState, useContext } from "react"
import {
  Theme,
  makeStyles,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  IconButton,
  Tooltip,
  Typography,
} from "@material-ui/core"
import { useTranslation } from "react-i18next"
import { WidgetTextTemplate } from "../../../api/graphql/query/widget-text-templates-for-category"
import moment from "moment"
import { Edit, Delete, Textsms, ControlPointDuplicateOutlined } from "@material-ui/icons"
import { createdAtDate } from "../../../utils/format-date"
import { ConfirmDialog } from "../dialog/ConfirmDialog"
import { useMutation } from "react-apollo"
import {
  DELETE_WIDGET_TEMPLATE_MUTATION,
  DeleteWidgetTextTemplateVariables,
  DeleteWidgetTextTemplateResult,
} from "../../../api/graphql/mutation/delete-widget-text-template"
import { AuthContext } from "../../../context/AuthContext"
import { AdminBadge } from "../badge/AdminBadge"
import { ManageTemplateDialog } from "./dialogs/ManageTemplateDialog"
import { UserContext } from "../../../context/UserContext"
import { TextReplacementContext } from "../../../context/TextReplacementContext"
import { dummyReplacementArray, generateTextFromTemplate } from "../../../domain/models/TextPlaceholders"
import { Rating, ChannelType } from "../../../api/graphql/generated/globalTypes"
import { TestSendToChannelDialog } from "../report/dialogs/TestSendToChannelDialog"
moment.locale("de")

const useStyles = makeStyles((theme: Theme) => ({
  cardHeading: {
    fontSize: 18,
  },
  cardActions: {
    justifyContent: "flex-end",
  },
}))

interface ITextTemplateCardProps {
  rating: Rating
  template: WidgetTextTemplate
  setTemplateToEdit: (template: WidgetTextTemplate) => void
  refetch: () => void
  noDuplicates?: boolean
}

export const TextTemplateCard: FunctionComponent<ITextTemplateCardProps> = (props) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { template, setTemplateToEdit, refetch, rating, noDuplicates } = props
  const { isDenovoAdmin, isAdmin } = useContext(AuthContext)
  const { user } = useContext(UserContext)
  const { placeholders } = useContext(TextReplacementContext)

  const [deleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false)
  const [duplicateDialogOpen, setDuplicateDialogOpen] = useState<boolean>(false)
  const [smsDialogOpen, setSmsDialogOpen] = useState(false)

  const createdAtFormatted = createdAtDate(template.createdAt)

  const [deleteTemplateMutation] = useMutation<DeleteWidgetTextTemplateResult, DeleteWidgetTextTemplateVariables>(
    DELETE_WIDGET_TEMPLATE_MUTATION,
    {
      onError: (error) => {
        console.log("error: ", error)
      },
      onCompleted: (data) => {
        console.log("delete success: ", data)
      },
    },
  )

  const onDelete = () => {
    deleteTemplateMutation({ variables: { id: template.id } })
    refetch()
    setDeleteDialogOpen(false)
  }

  const previewText: string = generateTextFromTemplate(
    template.textTemplate,
    placeholders ?? [],
    dummyReplacementArray(placeholders, rating, t),
  )

  return (
    <Fragment>
      {/* DIALOGS */}
      {deleteDialogOpen && (
        <ConfirmDialog
          open={deleteDialogOpen}
          onClose={() => setDeleteDialogOpen(false)}
          onConfirm={onDelete}
          text={t("text_management.dialog.delete.text")}
          heading={t("text_management.dialog.delete.heading")}
          confirmText={t("text_management.dialog.delete.confirmText")}
        />
      )}
      {duplicateDialogOpen && (
        <ManageTemplateDialog
          open={duplicateDialogOpen}
          onClose={() => setDuplicateDialogOpen(false)}
          category={template.widgetTextCategory}
          template={template}
          ratingKey={template.rating}
          forceCreateNew={true}
          onSuccess={() => {
            setDuplicateDialogOpen(false)
            refetch()
          }}
        />
      )}
      {smsDialogOpen && (
        <TestSendToChannelDialog
          open={smsDialogOpen}
          onClose={() => setSmsDialogOpen(false)}
          infoText={t("sms_dialog.test_info_text")}
          textsToSend={[previewText]}
          channelType={ChannelType.sms}
        />
      )}
      {/* VIEW */}
      <Card>
        <CardHeader
          titleTypographyProps={{
            className: classes.cardHeading,
          }}
          title={template.title}
          subheader={
            template.isDefault ? t("text_management.default") : t("general.created_at", { date: createdAtFormatted })
          }
          action={
            <Tooltip title={t("general.send")}>
              <IconButton onClick={() => setSmsDialogOpen(true)}>
                <Textsms color={"primary"} />
              </IconButton>
            </Tooltip>
          }
        />
        <CardContent>
          <Typography style={{ whiteSpace: "pre-line" }} component={"label"}>
            {previewText}
          </Typography>
        </CardContent>
        <CardActions className={classes.cardActions}>
          {((noDuplicates && isAdmin(user)) || !template.isDefault || isDenovoAdmin(user)) && (
            <Tooltip title={t("general.edit")}>
              <IconButton onClick={() => setTemplateToEdit(template)}>
                <Edit />
                {template.isDefault && <AdminBadge denovoMode={isDenovoAdmin(user) ? true : false} />}
              </IconButton>
            </Tooltip>
          )}
          {!template.isDefault && !noDuplicates && (
            <Tooltip title={t("general.delete")}>
              <IconButton onClick={() => setDeleteDialogOpen(true)}>
                <Delete />
              </IconButton>
            </Tooltip>
          )}
          {template.isDefault && !noDuplicates && (
            <Tooltip title={t("general.duplicate")}>
              <IconButton onClick={() => setDuplicateDialogOpen(true)}>
                <ControlPointDuplicateOutlined />
              </IconButton>
            </Tooltip>
          )}
        </CardActions>
      </Card>
    </Fragment>
  )
}
