import React, { FunctionComponent } from "react"
import { Theme, makeStyles, Grid, Typography } from "@material-ui/core"

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    height: "100%"
  }
}))

interface ISectionNoDataIndicatorProps {}

export const SectionNoDataIndicator: FunctionComponent<
  ISectionNoDataIndicatorProps
> = props => {
  const classes = useStyles()
  const { children } = props
  return (
    <Grid
      container
      justify={"center"}
      alignItems={"center"}
      className={classes.container}
    >
      <Typography align={"center"} color={"secondary"}>
        {children}
      </Typography>
    </Grid>
  )
}
