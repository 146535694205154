import React, { FunctionComponent } from "react"
import { Theme, makeStyles } from "@material-ui/core"
import { useTranslation } from "react-i18next"
import { TextFilterProvider } from "../../context/TextFilterContext"
import { LogPageContent } from "../partials/logs/LogPageContent"
import { TopBar } from "../partials/heading/TopBar"
import { LogPageFilter } from "../partials/logs/LogPageFilter"
import { LogFilterContextProvider } from "../partials/logs/context/LogFilterContext"

const useStyles = makeStyles((theme: Theme) => ({}))

interface ILogPageProps {}

export const LogPage: FunctionComponent<ILogPageProps> = (props) => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <TextFilterProvider>
      <LogFilterContextProvider>
        <TopBar>{t("log.heading")}</TopBar>
        <LogPageFilter />
        <LogPageContent />
      </LogFilterContextProvider>
    </TextFilterProvider>
  )
}
