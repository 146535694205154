export const FilterModelKeys = Object.freeze({
  associationId: "associationId",
  townId: "townId",
  emptyingId: "emptyingId",
  association: "association",
  municipal: "municipal",
  emptying: "emptying",
  tourDate: "tourDate",
  filterRating: "filterRating",
  filterFractionType: "filterFractionType",
  filterFractionFrom: "filterFractionFrom",
  filterFractionTo: "filterFractionTo",
  filterDateFrom: "filterDateFrom",
  filterDateUntil: "filterDateUntil",
  filterClassification: "filterClassification",
  filterTourType: "filterTourType"
})
