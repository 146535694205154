import React, { FunctionComponent, Fragment, useMemo } from "react"
import { useTranslation } from "react-i18next"
import { IGenericChannelTypeSectionProps } from "./ChannelTypeSectionTypes"
import { ChannelTypeSection, IChannelTypeAction } from "./ChannelTypeSection"
import { useWidgetDownload } from "./hooks/useWidgetDownload"

interface INewspaperChannelTypeSectionProps extends IGenericChannelTypeSectionProps {}

export const NewspaperChannelTypeSection: FunctionComponent<INewspaperChannelTypeSectionProps> = (props) => {
  const { t } = useTranslation()
  const { channelType, report, widgets } = props
  const onDownload = useWidgetDownload(channelType, report)

  const actions: IChannelTypeAction[] = useMemo(
    () => [
      {
        name: t("general.download"),
        onClick: onDownload,
      },
    ],
    [t, onDownload],
  )

  return <ChannelTypeSection widgets={widgets} channelType={channelType} report={report} actions={actions} />
}
