import React, {
  Fragment,
  FunctionComponent,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react"
import { useMutation } from "react-apollo"
import Grid from "@material-ui/core/Grid"
import Paper from "@material-ui/core/Paper"
import { useTranslation } from "react-i18next"
import { useHistory } from "react-router"
import ReportCreateDialog from "../report/ReportCreate"
import { CircularProgress, makeStyles, Theme, Typography } from "@material-ui/core"
import { TourTypeInfo, tourTypeInfoForValue } from "../../../domain/models/TourTypes"
import { generateWidgetTitleFromRawData } from "../../../domain/helper/GenerateWidgetTitle"
import lodash from "lodash"
import { ChannelType, Rating, TourType, WidgetType } from "../../../api/graphql/generated/globalTypes"
import { AnalysisFilterContext } from "../../../context/AnalysisFilterContext"
import { ApolloError } from "apollo-boost"
import { AnalysisMap } from "./AnalysisMap"
import { AnalysisPieGraphSection } from "./AnalysisPieGraphSection"
import { AnalysisHistoryGraphSection } from "./AnalysisHistoryGraphSection"
import {
  CREATE_REPORT_MUTATION,
  CreateReportResult,
  CreateReportVariables,
} from "../../../api/graphql/mutation/create-reports"
import { AnalysisPictureSection } from "./AnalysisPictureSection"
import { NoDataErrorIndicator } from "../indicator/NoDataErrorIndicator"
import { AnalysisIndicator } from "./AnalysisIndicator"
import { TextFilterContext } from "../../../context/TextFilterContext"
import moment from "moment"
import { createReportVariables } from "../../../api/graphql/generated/createReport"
import { AnalysisTrendGraphSection } from "./AnalysisTrendGraphSection"
import { AnalysisTrendComparisonGraphSection } from "./AnalysisTrendComparisonGraphSection"
import { AnalysisWasteQualityPieGraphSection } from "./waste-quality/AnalysisWasteQualityPieGraphSection"
import { AuthContext } from "../../../context/AuthContext"
import { useAnalyticsFromFilterContext } from "./context/useAnalyticsFromFilterContext"
import { rfidStringToArray } from "../../../utils/rfids"

interface IStyleProps {
  isAdmin: boolean
}

const useStyles = makeStyles((theme: Theme) => ({
  mainGrid: {
    marginTop: (props: IStyleProps) => (props.isAdmin ? 275 : 195),
    padding: theme.spacing(2),
    width: "100%",
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    height: 40,
    marginTop: "16px",
    marginBottom: "8px",
  },

  paper: {
    marginTop: 0,
    padding: "12px",
    justifyContent: "center",
    color: theme.palette.text.secondary,
    borderRadius: theme.spacing(0.5),
    flex: 1,
  },
  paperFilled: {
    margin: 0,
    padding: 0,
    justifyContent: "center",
    color: theme.palette.text.secondary,
    borderRadius: theme.spacing(0.5),
  },
  paperRating: {
    margin: 0,
    padding: 0,
    justifyContent: "center",
    // color: theme.palette.text.secondary,
    borderRadius: theme.spacing(0.5),
    textAlign: "center",
    alignContent: "center",
    verticalAlign: "center",
    backgroundColor: theme.palette.primary.main,
  },
  spanRating: {
    fontFamily: "Roboto",
    fontSize: "14px",
    fontWeight: "bold",
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: 1.14,
    letterSpacing: "-0.1px",
    color: "#ffffff",
  },
  fab: {
    margin: theme.spacing(1),
  },
  drawerPaper: {
    position: "relative",
    width: 240,
  },
  toolbar: theme.mixins.toolbar,
  toggleContainer: {
    height: 56,
    padding: `${theme.spacing}px ${theme.spacing(2)}px`,
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    margin: `${theme.spacing}px 0`,
    background: theme.palette.background.default,
  },
}))

interface IAnalysisPageContentProps {}

export const AnalysisPageContent: FunctionComponent<IAnalysisPageContentProps> = (props) => {
  const history = useHistory()
  const { isAdmin, isHousingManagement, hasAccessToWasteComposition } = useContext(AuthContext)
  const classes = useStyles({ isAdmin: isAdmin() })
  const { t } = useTranslation()

  const {
    permittedAssociations,
    filterModel,
    selectedEmptying,
    getAnalyticsQueryVariables,
    userAndPermissionsLoading,
    applyFilter,
    setApplyFilter,
    persistedTowns,
    persistedAssociation,
  } = useContext(AnalysisFilterContext)
  const { setAssociation } = useContext(TextFilterContext)

  const {
    materialAnalysis,
    materialAnalysisForMap,
    evaluation,
    fetchAnalysis,
    fetchAnalysisForMap,
    analysisCalledForMap,
    analysisForMapError,
    analysisForMapLoading,
    analysisLoading,
    analysisAvailableForOtherRoles,
    invalidateLocalAnalysis,
    removeFromLocalMaterialAnalysis,
  } = useAnalyticsFromFilterContext(() => setApplyFilter(false))

  const [reportCreationWidgetType, setReportCreationWidgetType] = useState<WidgetType | null>(null)
  const [isCreateInProgress, setIsCreateInProgress] = useState<boolean>(false)
  const [createReportError, setCreateReportError] = useState<string | null>(null)
  const [historyGraphToggle, setHistoryGraphToggle] = useState<boolean>(true)
  const [meanCompact, setMeanCompact] = useState<boolean>(true)
  const [widgetComparisonTownIds, setWidgetComparisonTownIds] = useState<string[]>([])
  const [tourTypeInfo, setTourTypeInfo] = useState<TourTypeInfo | null>(tourTypeInfoForValue(TourType.residual))

  const [createReportMutation] = useMutation<CreateReportResult, CreateReportVariables>(CREATE_REPORT_MUTATION, {
    onCompleted: (data) => handleCreateReportComplete(data),
    onError: (error) => handleCreateReportError(error),
  })

  const handleReportCreate = (widgetType: WidgetType, comparisonTownIds: string[] = []) => {
    setReportCreationWidgetType(widgetType)
    setWidgetComparisonTownIds(comparisonTownIds)
    setCreateReportError(null)
    if (filterModel.associationId) {
      setAssociation(filterModel.associationId)
    }
  }

  const handleReportClose = () => {
    setReportCreationWidgetType(null)
    setWidgetComparisonTownIds([])
  }

  const handleReportMutation = (title: string) => {
    setIsCreateInProgress(true)
    setCreateReportError(null)

    const channelTypesForWidgets: ChannelType[] = [ChannelType.newspaper]

    const widgetTitle = generateWidgetTitleFromRawData(
      permittedAssociations,
      reportCreationWidgetType,
      filterModel.associationId,
      filterModel.townIds,
      selectedEmptying,
      filterModel.dateFrom,
      filterModel.dateUntil,
      t,
    )

    const variables: createReportVariables = {
      title,
      associationId: filterModel.associationId,
      townIds: filterModel.townIds,
      emptyingWeekNumber: lodash.get(selectedEmptying, "weekNumber"),
      emptyingYearNumber: lodash.get(selectedEmptying, "yearNumber"),
      rating: filterModel.rating,
      fractionType: filterModel.fractionType,
      fractionFrom: filterModel.fractionFrom ? parseFloat(filterModel.fractionFrom) : null,
      fractionTo: filterModel.fractionTo ? parseFloat(filterModel.fractionTo) : null,
      dateFrom: filterModel.dateFrom ? moment(filterModel.dateFrom).toISOString() : null,
      dateUntil: filterModel.dateUntil ? moment(filterModel.dateUntil).toISOString() : null,
      displayOnlyAutomaticAnalysis: filterModel.displayOnlyAutomaticAnalysis,
      chartCompact: meanCompact,
      widgetComparisonTownIds,
      createWidgetType: reportCreationWidgetType,
      channelTypesForWidgets,
      widgetTitle,
      tourType: filterModel.tourType,
      rfids: rfidStringToArray(filterModel.rfids),
    }
    createReportMutation({ variables }).then()
  }

  const handleCreateReportComplete = (data: any) => {
    history.push(`/communication-center?id=${data.createReport.id}`)
    setIsCreateInProgress(false)
    setReportCreationWidgetType(null)
  }

  const handleCreateReportError = (error: ApolloError) => {
    setIsCreateInProgress(false)
    setCreateReportError(error.message)
  }

  useEffect(() => {
    setTourTypeInfo(filterModel.tourType ? tourTypeInfoForValue(filterModel.tourType) : null)
  }, [filterModel.tourType, setTourTypeInfo])

  useEffect(() => {
    if (applyFilter && !userAndPermissionsLoading && filterModel.associationId) {
      fetchAnalysisForMap()
    }
  }, [applyFilter, userAndPermissionsLoading, filterModel.associationId, fetchAnalysisForMap])

  const validAnalysis = useMemo(
    () =>
      materialAnalysisForMap.filter(
        (singleAnalysis) =>
          singleAnalysis.calculated?.rating !== Rating.Unknown && !singleAnalysis.invalidity.isInvalid,
      ),
    [materialAnalysisForMap],
  )
  const invalidAnalysis = useMemo(
    () =>
      materialAnalysisForMap.filter(
        (singleAnalysis) => singleAnalysis.calculated?.rating === Rating.Unknown || singleAnalysis.invalidity.isInvalid,
      ),
    [materialAnalysisForMap],
  )

  const showValidAnalysisPictureGallery = useMemo(
    () => validAnalysis.some((analysis) => analysis.documentIds.length > 0) && validAnalysis.length > 0,
    [validAnalysis],
  )
  const showInvalidAnalysisPictureGallery = useMemo(
    () => invalidAnalysis.some((analysis) => analysis.documentIds.length > 0) && invalidAnalysis.length > 0,
    [invalidAnalysis],
  )

  const refMapContainer = useRef(null)
  return (
    <Fragment>
      {/* DIALOG */}
      {!!reportCreationWidgetType && (
        <ReportCreateDialog
          open={!!reportCreationWidgetType}
          onClose={handleReportClose}
          inProgress={isCreateInProgress}
          onCreate={(title: string) => handleReportMutation(title)}
          errorMessage={createReportError}
        />
      )}
      {/* VIEW */}
      {analysisForMapError && <NoDataErrorIndicator />}
      {(analysisForMapLoading || !analysisCalledForMap) && (
        <AnalysisIndicator>
          <CircularProgress />
        </AnalysisIndicator>
      )}
      {!analysisForMapLoading && materialAnalysisForMap.length > 0 && (
        <Grid container className={classes.mainGrid} spacing={2}>
          <Grid item xs={12}>
            <Paper className={classes.paperFilled} ref={refMapContainer}>
              <AnalysisMap materialAnalysis={materialAnalysisForMap} width={refMapContainer?.current?.offsetWidth} />
            </Paper>
          </Grid>

          {showValidAnalysisPictureGallery && (
            <Grid item xs={12}>
              <Paper className={classes.paper}>
                <AnalysisPictureSection
                  onAnalysisDeleted={removeFromLocalMaterialAnalysis}
                  onAnalysisInvalidated={invalidateLocalAnalysis}
                  useBagSeparation
                  materialAnalysis={validAnalysis}
                  title={t("analysis_page.pictures")}
                />
              </Paper>
            </Grid>
          )}
          {showInvalidAnalysisPictureGallery && (
            <Grid item xs={12}>
              <Paper className={classes.paper}>
                <AnalysisPictureSection
                  onAnalysisDeleted={removeFromLocalMaterialAnalysis}
                  materialAnalysis={invalidAnalysis}
                  title={t("analysis_page.invalid_pictures")}
                />
              </Paper>
            </Grid>
          )}
          {materialAnalysisForMap && (
            <Grid item xs={12}>
              <Paper className={classes.paper}>
                <AnalysisWasteQualityPieGraphSection
                  materialAnalysis={materialAnalysisForMap}
                  onReportCreate={() => handleReportCreate(WidgetType.chart_pie_waste_quality)}
                />
              </Paper>
            </Grid>
          )}
          {hasAccessToWasteComposition() && (
            <Grid item xs={12}>
              <AnalysisPieGraphSection
                evaluation={evaluation}
                tourTypeInfo={tourTypeInfo!}
                meanCompact={meanCompact}
                setMeanCompact={setMeanCompact}
                fetchAnalysis={fetchAnalysis}
                analysisLoading={analysisLoading}
                onReportCreate={() => handleReportCreate(WidgetType.chart_pie)}
              />
            </Grid>
          )}
          <Grid item xs={12}>
            <AnalysisHistoryGraphSection
              materialAnalysis={materialAnalysis}
              evaluation={evaluation}
              tourTypeInfo={tourTypeInfo!}
              historyGraphToggle={historyGraphToggle}
              setHistoryGraphToggle={setHistoryGraphToggle}
              onReportCreate={() => handleReportCreate(WidgetType.chart_history)}
              fetchAnalysis={fetchAnalysis}
              analysisLoading={analysisLoading}
            />
          </Grid>
          {persistedAssociation && (
            <Grid item xs={12}>
              <Paper className={classes.paper}>
                <AnalysisTrendComparisonGraphSection
                  tourTypeInfo={tourTypeInfo!}
                  onReportCreate={(comparisonTownIds) =>
                    handleReportCreate(WidgetType.chart_trend_comparison, comparisonTownIds)
                  }
                />
              </Paper>
            </Grid>
          )}
          {persistedTowns?.length === 1 && (
            <Grid item xs={12}>
              <Paper className={classes.paper}>
                <AnalysisTrendGraphSection
                  tourTypeInfo={tourTypeInfo!}
                  onReportCreate={() => handleReportCreate(WidgetType.chart_trend)}
                />
              </Paper>
            </Grid>
          )}
        </Grid>
      )}

      {!analysisForMapLoading && analysisCalledForMap && materialAnalysisForMap.length <= 0 && (
        <AnalysisIndicator>
          <Typography color={"secondary"}>
            {t(
              `analysis_page.${
                isHousingManagement() && analysisAvailableForOtherRoles
                  ? "no_data_for_housing_management"
                  : "no_data_for_this_filter"
              }`,
            )}
          </Typography>
        </AnalysisIndicator>
      )}
    </Fragment>
  )
}
