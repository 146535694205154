import { gql } from "apollo-boost"
import { validateRfidGroupAddress, validateRfidGroupAddressVariables } from "../generated/validateRfidGroupAddress"

export const VALIDATE_RFID_GROUP_ADDRESS_QUERY = gql`
  query validateRfidGroupAddress($townId: ID!, $address: AddressInput!) {
    validateRfidGroupAddress(townId: $townId, address: $address)
  }
`
export type ValidateRfidGroupAddressQueryResult = validateRfidGroupAddress
export type ValidateRfidGroupAddressQueryVariables = validateRfidGroupAddressVariables
