import React, { FunctionComponent, ReactNode } from "react"
import lodash from "lodash"
import { useTranslation } from "react-i18next"
// @material-ui/core components
import ListSubheader from "@material-ui/core/ListSubheader"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ListItemIcon from "@material-ui/core/ListItemIcon"
import ListItemText from "@material-ui/core/ListItemText"
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction"
import { Brightness1, PlayCircleFilled } from "@material-ui/icons"
import ExpandMore from "@material-ui/icons/ExpandMore"
import { Collapse, makeStyles, Theme } from "@material-ui/core"
import { ClassNameMap } from "@material-ui/core/styles/withStyles"
import { FractionInfo, FractionType } from "../../../constants/Constants"
import { TourTypeInfo } from "../../../domain/models/TourTypes"
import { Evaluation } from "../../../api/graphql/fragments/evaluation_fragments"
import { createEvaluationsPerFraction, createEvaluationsPerType } from "../../../constants/DataManipulators"
import Typography from "@material-ui/core/Typography"
import { LabelSetMaterialType } from "../../../api/graphql/generated/globalTypes"

// @Styles
const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  bagText: {
    paddingTop: theme.spacing(2),
    paddingLeft: theme.spacing(2),
  },
}))

interface IWertstoffAnalysisListProps {
  evaluationData: Evaluation
  compact: Boolean
  onCompactChange: () => void
  tourTypeInfo: TourTypeInfo
}

export const WertstoffAnalysisList: FunctionComponent<IWertstoffAnalysisListProps> = (props) => {
  const { evaluationData, compact, onCompactChange, tourTypeInfo } = props
  const { t } = useTranslation()
  const classes = useStyles()

  function createListItems(
    evaluation: Evaluation,
    compact: Boolean,
    classes: ClassNameMap,
    handleCollapse: () => void,
  ) {
    let listItems: any[] = []

    createEvaluationsPerType(evaluation, tourTypeInfo, [FractionType.MAIN, FractionType.MISSTHROW]).forEach((entry) => {
      interface IChildItem {
        info: FractionInfo
        value: number
        percentage: string
      }
      const children: IChildItem[] = lodash
        .compact(
          createEvaluationsPerFraction(evaluation, [LabelSetMaterialType.missthrow]).map((entry) => {
            if (!(entry.value > 0)) return null
            if (entry.fraction.id === tourTypeInfo.fraction.id) return null
            const valueAsNumber = ((entry.value ?? 0) * 100).toFixed(0)
            return {
              value: entry.value * 100,
              percentage: valueAsNumber,
              name: t(entry.fraction.name),
              info: entry.fraction,
            }
          }),
        )
        .sort((a, b) => b.value - a.value)
      const hasChildren = entry.fractionType.collapsible && children.length > 0
      const showChildren = !compact && hasChildren
      const color = entry.fractionType.color
      const name = entry.fractionType.name
      const valueAsNumber = ((entry.value ?? 0) * 100).toFixed(0)

      listItems.push(
        <ListItem
          key={entry.fractionType.id}
          button={hasChildren ? undefined : false}
          onClick={hasChildren ? handleCollapse : undefined}
        >
          <ListItemIcon>
            {!hasChildren ? (
              <Brightness1 style={{ color: color }} />
            ) : !showChildren ? (
              <PlayCircleFilled style={{ color: color }} />
            ) : (
              <ExpandMore style={{ color: color }} />
            )}
          </ListItemIcon>
          <ListItemText inset primary={t(name)} />
          <ListItemSecondaryAction>{valueAsNumber + " %"}</ListItemSecondaryAction>
        </ListItem>,
      )
      if (showChildren) {
        let childItems: ReactNode[] = []
        children.forEach((child) => {
          childItems.push(
            <ListItem key={child.info.id} className={classes.nested}>
              <ListItemIcon>
                <Brightness1 style={{ color: child.info.color }} />
              </ListItemIcon>
              <ListItemText inset primary={t(child.info.name)} />
              <ListItemSecondaryAction>{child.percentage + " %"}</ListItemSecondaryAction>
            </ListItem>,
          )
        })
        listItems.push(
          <Collapse key={entry.fractionType.id + "Collapse"} in={!compact} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {childItems}
            </List>
          </Collapse>,
        )
      }
    })

    return listItems
  }

  function createBagsLine(evaluation: Evaluation): JSX.Element | null {
    const bags = lodash.first(createEvaluationsPerType(evaluation, tourTypeInfo, [FractionType.BAGS], true))
    if (lodash.isNil(bags)) return null

    const valueAsNumber = ((bags.value ?? 0) * 100).toFixed(0)
    return (
      <Typography variant={"body1"} className={classes.bagText}>
        {`${t("analysis_page.list.bag_amount")} (${valueAsNumber} %)`}
      </Typography>
    )
  }

  const listItems = createListItems(evaluationData, compact, classes, onCompactChange)
  const bagLine = createBagsLine(evaluationData)

  return (
    <div>
      <List
        component="nav"
        subheader={<ListSubheader component="div">{t("analysis_page.list.classification")}</ListSubheader>}
        className={classes.root}
      >
        {listItems}
      </List>
      {bagLine}
    </div>
  )
}
