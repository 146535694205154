import { EdaQueue } from "../../../../api/graphql/query/eda-queues"

const sortedEdaQueueNames = [
  "wastescanner-uploads",
  "wastescanner-preprocess-jobs",
  "wastescanner-preprocess-djq",
  "wastescanner-cnn-jobs",
  "wastescanner-cnn-djq",
]
export const sortEdaQueues = (edaQueues: EdaQueue[]) =>
  edaQueues.sort((a, b) => sortedEdaQueueNames.indexOf(a.queueName) - sortedEdaQueueNames.indexOf(b.queueName))
