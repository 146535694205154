import { DeleteHousingManagement as DeleteHousingManagementRes } from "./../generated/DeleteHousingManagement"
import { gql } from "apollo-boost"

export const DELETE_HOUSING_MANAGEMENT_MUTATION = gql`
  mutation DeleteHousingManagement($deleteHousingManagementId: String!) {
    deleteHousingManagement(id: $deleteHousingManagementId)
  }
`

export interface DeleteHousingManagementResult extends DeleteHousingManagementRes {}
