import { gql } from "apollo-boost"
import {
  bookmarksForUser,
  bookmarksForUser_userAndPermissions_permittedAssociations,
  bookmarksForUser_userAndPermissions_permittedAssociations_towns,
  bookmarksForUser_userAndPermissions_permittedAssociations_towns_filters,
  bookmarksForUser_userAndPermissions_permittedAssociations_towns_filters_emptying,
} from "../generated/bookmarksForUser"

export const BOOKMARKS_FOR_USER = gql`
  query bookmarksForUser {
    userAndPermissions {
      permittedAssociations {
        id
        associationId
        name
        accessToAllTowns
        filters(onlyBookmarks: true) {
          id
          name
          associationId
          townIds
          emptyingWeekNumber
          emptyingYearNumber
          rating
          fractionName
          fractionType
          fractionFrom
          fractionTo
          dateFrom
          dateUntil
          source
          emptying {
            id
            startDate
            weekNumber
            yearNumber
            tours {
              id
              name
            }
          }
          tourType
          displayOnlyAutomaticAnalysis
          rfids
        }
        towns {
          id
          name
          filters(onlyBookmarks: true) {
            id
            name
            associationId
            townIds
            emptyingWeekNumber
            emptyingYearNumber
            rating
            fractionName
            fractionType
            fractionFrom
            fractionTo
            dateFrom
            dateUntil
            source
            emptying {
              id
              startDate
              weekNumber
              yearNumber
              tours {
                id
                name
              }
            }
            tourType
            displayOnlyAutomaticAnalysis
            rfids
          }
        }
      }
    }
  }
`

export type BookmarksForUserResult = bookmarksForUser
export type AssociationWithBookmarks = bookmarksForUser_userAndPermissions_permittedAssociations
export type TownWithBookmarks = bookmarksForUser_userAndPermissions_permittedAssociations_towns
export type Bookmark = bookmarksForUser_userAndPermissions_permittedAssociations_towns_filters
export type BookmarkEmptying = bookmarksForUser_userAndPermissions_permittedAssociations_towns_filters_emptying
