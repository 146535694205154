import React, { FunctionComponent } from "react"
import { Theme, makeStyles, Grid, Typography } from "@material-ui/core"
import { useTranslation } from "react-i18next"

import { DetailedMaterialAnalysis } from "../../../../api/graphql/query/analyticsDetail"
import moment from "moment"

const useStyles = makeStyles((theme: Theme) => ({
  textEllipsis: {
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
  },
  text: {
    fontSize: 14,
  },
  textBold: {
    fontSize: 14,
    fontWeight: 700,
  },
}))

interface IWertstoffAnalysisDetailMetadataProps {
  analysis: DetailedMaterialAnalysis
}

export const WertstoffAnalysisDetailMetadata: FunctionComponent<IWertstoffAnalysisDetailMetadataProps> = (props) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { analysis } = props
  const { imageNumber, metadata, rfidData } = analysis

  return (
    <Grid container direction={"row"} alignItems={"center"}>
      <Grid item xs={12} container justify="space-between">
        <Grid item>
          <Typography className={classes.textBold}>{t("analysis_detail.date_time")}</Typography>
        </Grid>
        <Grid item>
          <Typography className={classes.text}>{moment(metadata.recordedAt).format("DD.MM.YYYY / HH:mm")}</Typography>
        </Grid>
      </Grid>
      <Grid item xs={12} container justify="space-between">
        <Grid item>
          <Typography className={classes.textBold}>{t("analysis_detail.image_number")}</Typography>
        </Grid>
        <Grid item>
          <Typography className={classes.text}>{imageNumber || "-"}</Typography>
        </Grid>
      </Grid>
      <Grid item xs={12} container justify="space-between">
        <Grid item>
          <Typography className={classes.textBold}>{t("analysis_detail.rfid_number")}</Typography>
        </Grid>
        <Grid item>
          <Typography className={classes.text}>{rfidData?.rfidIds?.join(",") || "-"}</Typography>
        </Grid>
      </Grid>
    </Grid>
  )
}
