import store from "store"
import { ApolloLink } from "apollo-link"
import { HttpLink } from "apollo-link-http"
import { WebSocketLink } from "apollo-link-ws"
import { onError } from "apollo-link-error"
import { getOperationAST } from "graphql"
import { toast } from "react-toastify"
import i18next from "i18next"
import moment from "moment-timezone"

const HEADER_TIMEZONE = "X-TIMEZONE"

export const authLink = (operation, forward) => {
  const accessToken = store.get("access_token")

  operation.setContext((context) => ({
    ...context,
    headers: {
      ...context.headers,
      Authorization: accessToken && accessToken.length > 0 && accessToken !== "logout" && operation?.operationName !== "signin" ? `Bearer ${accessToken}` : "",
      [HEADER_TIMEZONE]: moment.tz.guess(),
    },
  }))

  return forward(operation)
}

export const errorLink = onError((errors) => {
  /*
  onError receives a callback in the event a GraphQL or network error occurs.
  This example is a bit contrived, but in the real world, you could connect
  a logging service to the errorLink or perform a specific action in response
  to an error.
  */
  const { graphQLErrors, networkError } = errors

  switch (networkError?.statusCode) {
    case 408:
      toast.error(i18next.t("request_timeout"))
      break
    default:
      //  toast.error(i18next.t("request_failed")) // spams to much
      break
  }

  if (networkError?.message === "Failed to fetch") {
    toast.error(i18next.t("request_timeout"))
  }

  if (graphQLErrors && graphQLErrors.some(({message}) => (message || "").includes("Access denied"))){
      store.remove("access_token")
      store.remove("language")
      window.location = "/login"
    }
})

export const subscriptionLink = (uri, config = {}) =>
  new WebSocketLink({
    uri,
    options: { reconnect: true },
    ...config,
  })

export const queryOrMutationLink = (uri, config = {}) => {
  return new HttpLink({
    uri,
    ...config,
    credentials: "same-origin",
  })
}

export const requestLink = ({ queryOrMutationLink, subscriptionLink }) =>
  /*
    This link checks if the operation is a subscription.
    If it is, we use our subscription link to retrieve data over WebSockets.
    If it is a query or mutation, we retrieve data over HTTP.
  */
  ApolloLink.split(
    (operation) => {
      const operationAST = getOperationAST(operation.query, operation.operationName)
      return !!operationAST && operationAST.operation === "subscription"
    },
    queryOrMutationLink,
    subscriptionLink,
  )
