import { gql } from "apollo-boost"
import { FRAGMENT_BASIC_MATERIAL_ANALYSIS } from "./basic_material_analysis_fragment"

export const FRAGMENT_MATERIAL_ANALYSIS_WITH_DOCUMENTS = gql`
  fragment FragmentMaterialAnalysisWithDocuments on MaterialAnalysis {
    ...FragmentBasicMaterialAnalysis
    town {
      id
    }
    imageNumber
    documents {
      id
      documentType
      url
      title
    }
  }
  ${FRAGMENT_BASIC_MATERIAL_ANALYSIS}
`
