import { gql } from "apollo-boost"
import { ReplacementForFilter_replacementInfo_replacements } from "../generated/replacementForFilter"

export const REPLACEMENT_FOR_FILTER = gql`
  query ReplacementForFilter($filterId: ID!) {
    replacementInfo {
      replacements(filterId: $filterId) {
        key
        text
      }
    }
  }
`

export type ReplacementInfoArray = ReplacementForFilter_replacementInfo_replacements[]
