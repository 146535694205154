import React, { FunctionComponent, Fragment, createContext, useState, useCallback } from "react"
import { Theme, makeStyles } from "@material-ui/core"
import { useTranslation } from "react-i18next"
import { ChannelType } from "../../../../api/graphql/generated/globalTypes"
import { reportDetail_report_widgets } from "../../../../api/graphql/generated/reportDetail"
import lodash from "lodash"
import { WidgetModificationType } from "../../../../domain/models/WidgetModels"

const useStyles = makeStyles((theme: Theme) => ({}))

function objectsHaveSameKeys(...objects: any[]) {
  const allKeys = objects.reduce((keys, object) => keys.concat(Object.keys(object)), [])
  const union = new Set(allKeys)
  return objects.every((object) => union.size === Object.keys(object).length)
}

interface IWidgetExportsContext {
  onExportReady: (
    widgetId: string,
    channelType: ChannelType,
    fileName: string,
    title: string | null,
    textContent: string | null,
    uri: string,
    extension: string,
    payload?: any,
  ) => void
  onModification: (type: WidgetModificationType, widget: reportDetail_report_widgets) => void
  widgetExportsDictionary: { [key: string]: { [key: string]: any } }
  exportsReady: boolean
}

export const WidgetExportsContext = createContext<IWidgetExportsContext>({} as IWidgetExportsContext)

interface IWidgetExportsContextProviderProps {
  widgets: reportDetail_report_widgets[]
}

export const WidgetExportsContextProvider: FunctionComponent<IWidgetExportsContextProviderProps> = (props) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { widgets } = props

  const [widgetExportsDictionary, setWidgetExportsDictionary] = useState<{ [key: string]: { [key: string]: any } }>({})
  const [exportsReady, setExportsReady] = useState<boolean>(false)

  const onModification = useCallback(
    (type: WidgetModificationType, widget: reportDetail_report_widgets) => {
      if (type === WidgetModificationType.DELETE) {
        if (!widgetExportsDictionary[widget.channelType] || !!widgetExportsDictionary[widget.channelType][widget.id]) {
          console.warn("can't delete widgetExports: ", widgetExportsDictionary, widget)
        } else {
          delete widgetExportsDictionary[widget.channelType][widget.id]
        }
      }
    },
    [widgetExportsDictionary],
  )

  const onExportReady = useCallback(
    (
      widgetId: string,
      channelType: ChannelType,
      fileName: string,
      title: string | null,
      textContent: string | null,
      uri: string,
      extension: string,
      payload?: any,
    ) => {
      let newDictionary = { ...widgetExportsDictionary }

      if (!widgetId) {
        return
      }

      if (newDictionary[channelType] && (!fileName || !uri || !extension)) {
        delete newDictionary[channelType][widgetId]
      } else {
        if (!newDictionary[channelType]) {
          newDictionary[channelType] = {}
        }
        newDictionary[channelType][widgetId] = {
          title,
          textContent,
          fileName,
          channelType,
          uri,
          extension,
          payload,
        }
      }

      let isReady = false
      const widgetsForChannel = lodash.keyBy(widgets, (w: reportDetail_report_widgets) => w.id)
      isReady = objectsHaveSameKeys(widgetsForChannel, newDictionary[channelType] || {})
      setExportsReady(isReady)

      setWidgetExportsDictionary(newDictionary)
      console.log(channelType, newDictionary)
    },
    [setWidgetExportsDictionary, setExportsReady, widgetExportsDictionary, widgets],
  )

  return (
    <WidgetExportsContext.Provider value={{ onExportReady, widgetExportsDictionary, exportsReady, onModification }}>
      {props.children}
    </WidgetExportsContext.Provider>
  )
}
