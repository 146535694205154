import { gql } from "apollo-boost"

export const FRAGMENT_BASIC_MATERIAL_ANALYSIS = gql`
  fragment FragmentBasicMaterialAnalysis on MaterialAnalysis {
    id
    tourType
    documentIds
    metadata {
      recordedAt
      analysedAt
      wasteScannerId
      location {
        latitude
        longitude
        formattedAddress
      }
      automatic
      mappedClassification
      descriptionLabel
      file
    }
    rfidData {
      rfidIds
    }
    calculatedDetailed {
      rating
      percentOfCategory {
        main
        missThrow
      }
      pixelCountDetailed {
        id
        value
        name
        color
        materialType
      }
    }
    invalidity {
      isInvalid
      invalidReason
      invalidType
    }

    createdAt
    updatedAt
    deletedAt
  }
`
