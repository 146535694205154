import React, { FunctionComponent, useEffect, useRef } from "react"
import { createEvaluationsPerFraction } from "../../../constants/DataManipulators"
import moment, { Moment } from "moment"
import { useTranslation } from "react-i18next"
// @material-ui/core components
import { CartesianGrid, Label, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts"
import lodash from "lodash"
import { basicFractionArray, basicFractionGroupArray, FractionType } from "../../../constants/Constants"
// @ts-ignore
import domToImage from "dom-to-image-more"
import { TourTypeInfo } from "../../../domain/models/TourTypes"
import { TimeLevel, tickFormat } from "./tick-format"
import { LabelSetMaterialType } from "../../../api/graphql/generated/globalTypes"
import { MaterialAnalysisForEvaluation } from "../../../api/graphql/query/material-analysis-and-evaluation"
import { Evaluation } from "../../../api/graphql/fragments/evaluation_fragments"

// @Styles
// const useStyles = makeStyles((theme: Theme) => ({
//   tooltip: {
//     backgroundColor: "red",
//     zIndex: 99
//   }
// }))

interface IWertstoffAnalysisHistoryGraphProps {
  materialAnalysis: MaterialAnalysisForEvaluation[]
  evaluation: Evaluation | null
  tourTypeInfo: TourTypeInfo
  grouped: boolean
  compact: boolean
  legend?: boolean
  onExportCreated?: (fileUri: string, extension: string) => void
}

interface DataForGraph {
  main: number
  missThrow: number
  momentDate: Moment
  groupedKey: string
  tickDate: string | undefined
  tickInterval: number
}

export const WertstoffAnalysisHistoryGraph: FunctionComponent<IWertstoffAnalysisHistoryGraphProps> = (props) => {
  // const classes = useStyles()
  const { t } = useTranslation()
  const { materialAnalysis, evaluation, compact, legend, tourTypeInfo, onExportCreated } = props
  let currentChartRef = useRef(null)
  const analysisDataWithOutInvalid = materialAnalysis.filter((a) => !a.invalidity.isInvalid) ?? []

  useEffect(() => {
    if (!lodash.isNil(onExportCreated)) {
      setTimeout(function () {
        exportChart()
      }, 200)
    }
    // eslint-disable-next-line
  }, [])

  function exportChart() {
    console.log("exportChart started")
    if (!lodash.isFunction(onExportCreated)) {
      return
    }

    let foundNode = window.ReactDOM.findDOMNode(currentChartRef.current)
    if (lodash.isNil(foundNode)) {
      return
    }

    domToImage.toPng(foundNode, { scale: 10 }).then(function (imageBase64: string) {
      if (!lodash.isNil(onExportCreated)) {
        onExportCreated(imageBase64, ".png")
      }
    })
  }

  function createLines(compact: Boolean, tourType: TourTypeInfo): JSX.Element[] {
    let fractionLines: JSX.Element[] = []

    if (compact) {
      lodash
        .compact(
          lodash.filter(basicFractionGroupArray, (x) =>
            [LabelSetMaterialType.main_fraction, LabelSetMaterialType.missthrow].includes(x.id),
          ),
        )
        .forEach((entry) => {
          fractionLines.push(
            <Line
              key={entry.id}
              type="linear"
              dataKey={entry.id}
              stroke={entry.color}
              dot={false}
              activeDot={{ r: 4 }}
              strokeWidth={entry.collapsible ? 1 : 2}
              name={`${t(entry.name)}`}
              isAnimationActive={false}
            />,
          )
        })
    }
    if (!compact && !lodash.isNil(evaluation)) {
      const distributions = createEvaluationsPerFraction(evaluation)
      distributions?.forEach((entry) => {
        fractionLines.push(
          <Line
            key={entry.fraction.id}
            type="linear"
            dataKey={entry.fraction.id}
            stroke={entry.fraction.color}
            dot={false}
            activeDot={{ r: 4 }}
            strokeWidth={entry.fraction.id === tourTypeInfo.fraction.id ? 2 : 1}
            name={`${t(entry.fraction.name)}`}
            isAnimationActive={false}
          />,
        )
      })
    }

    return fractionLines
  }

  let graphData: DataForGraph[] = []

  const analysisData: DataForGraph[] = lodash.compact(
    analysisDataWithOutInvalid.map((d) => {
      const date = moment(d.metadata.recordedAt)
      const distribution = d.calculatedDetailed?.pixelCountDetailed
      const materialDistributionEntry = {}

      distribution.forEach((d) => {
        if (!compact) {
          materialDistributionEntry[d.id] = d.value * 100
        } else {
          materialDistributionEntry[d.materialType] = (materialDistributionEntry[d.materialType] ?? 0) + d.value * 100
        }
      })

      const percentOfCategory = d.calculatedDetailed?.percentOfCategory
      if (lodash.isNil(distribution) || lodash.isNil(percentOfCategory)) {
        return null
      }

      const main = percentOfCategory.main ?? 0.0
      const missThrow = percentOfCategory.missThrow ?? 0.0
      return {
        main: main * 100,
        missThrow: missThrow * 100,
        momentDate: date,
        groupedKey: date.format("YYYY-MM-DD"),
        tickDate: undefined,
        tickInterval: 1,
        ...materialDistributionEntry,
      }
    }),
  )

  let level = TimeLevel.DAY
  let firstData = analysisData[0]
  let lastData = analysisData[analysisData.length - 1]
  if (!lodash.isNil(firstData) && !lodash.isNil(firstData)) {
    if (firstData.momentDate.isSame(lastData.momentDate, "D")) {
      level = TimeLevel.HOUR
    }
  }

  graphData = analysisData.sort((a, b) => (a.momentDate.isBefore(b.momentDate) ? -1 : 1))

  for (let data of graphData) {
    data.tickDate = tickFormat(data.momentDate)
    data.tickInterval = data.momentDate.valueOf()
  }

  // Normalize data to 0-100% instead of decimals
  const availableKeys = basicFractionArray.map((fraction) => fraction.id)
  graphData = graphData.map((data) => {
    let modified = lodash.cloneDeep(data)
    Object.entries(data).forEach(([key, value]) => {
      if (availableKeys.includes(key) && !lodash.isNil(value)) {
        modified = {
          ...modified,
          [key]: value * 100,
        }
      }
    })
    return modified
  })

  const lines = createLines(compact, tourTypeInfo)
  const tickDates = graphData.map((data) => tickFormat(data.momentDate, level))
  const tickDatesDeduplicated = new Set(tickDates)

  return (
    <ResponsiveContainer width={"95%"} height={250}>
      <LineChart data={graphData} margin={{ top: 10, right: 50, left: 20, bottom: 5 }} ref={currentChartRef}>
        <XAxis
          dataKey={"tickInterval"}
          type={"number"}
          tickFormatter={(date) => tickFormat(date, level)}
          domain={["dataMin", "dataMax"]}
          tickCount={tickDatesDeduplicated.size}
          scale={"time"}
          tick={level !== TimeLevel.HOUR}
        >
          <Label
            value={level === TimeLevel.HOUR ? tickFormat(firstData.momentDate) : undefined}
            offset={0}
            position={"insideBottom"}
            style={{ textAnchor: "middle" }}
          />
        </XAxis>
        <YAxis>
          <Label
            value={`${t("graph.percent")}`}
            angle={-90}
            offset={0}
            position={"insideLeft"}
            style={{ textAnchor: "middle" }}
          />
        </YAxis>
        <CartesianGrid vertical={false} />
        {legend && (
          <Legend
            layout={"vertical"}
            verticalAlign={"middle"}
            align={"right"}
            iconType={"circle"}
            iconSize={10}
            wrapperStyle={{ right: "5px", top: "10px" }}
          />
        )}
        <Tooltip
          // className={classes.tooltip}
          labelFormatter={(timeStr) =>
            moment(timeStr).format("DD MMM YYYY") +
            (level === TimeLevel.HOUR ? " " + moment(timeStr).format("HH:mm") : "")
          }
          formatter={(value, name, props) => `${Number(value).toFixed(0)} %`}
          itemSorter={(a, b) => {
            return lodash.get(a, "value", -1) > lodash.get(b, "value", -1) ? -1 : 1
          }}
        />
        {lines}
      </LineChart>
    </ResponsiveContainer>
  )
}
