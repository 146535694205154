import moment from "moment"
import lodash from "lodash"
import { EmptyingOfTown } from '../../../../api/graphql/query/emptyings-for-towns'

export const getFilterDates = (dateUntil: Date | null, dateFrom: Date | null) => {
  let minDateFrom = moment().add(-10, "year").toISOString()
  let maxDateFrom = dateUntil ? moment(dateUntil).toISOString() : moment().toISOString()
  let minDateUntil = dateFrom ? moment(dateFrom).toISOString() : moment().toISOString()
  let maxDateUntil = moment().add(1, "year").toISOString()
  let disableFuture = true

  return {
    minDateFrom,
    minDateUntil,
    maxDateFrom,
    maxDateUntil,
    disableFuture,
  }
}

export const getFilterDatesWithEmptyingBounds = (
  dateUntil: Date | null,
  dateFrom: Date | null,
  emptying: EmptyingOfTown | null,
) => {
  let { minDateFrom, minDateUntil, maxDateFrom, maxDateUntil, disableFuture } = getFilterDates(dateUntil, dateFrom)

  if (lodash.isNil(emptying)) {
    return { minDateFrom, minDateUntil, maxDateFrom, maxDateUntil, disableFuture }
  }
  const startDate = moment(emptying.startDate).add(1, "days")

  const yearNumber = startDate.clone().endOf("isoWeek").year()
  const weekDate = startDate.clone().isoWeekYear(yearNumber).isoWeek(emptying.weekNumber)

  return {
    minDateFrom: weekDate.clone().startOf("week").toISOString(),
    maxDateFrom: weekDate.clone().endOf("week").toISOString(),
    minDateUntil: weekDate.clone().startOf("week").toISOString(),
    maxDateUntil: weekDate.clone().endOf("week").toISOString(),
    disableFuture,
  }
}
