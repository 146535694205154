import React, { FunctionComponent } from "react"
import { useTranslation } from "react-i18next"
import { TopBar } from "../partials/heading/TopBar"
import { EdaContextProvider } from "../../context/EdaContext"
import { EdaPageContent } from "../partials/eda/EdaPageContent"

interface IEdaPageProps {}

export const EdaPage: FunctionComponent<IEdaPageProps> = (props) => {
  const { t } = useTranslation()
  return (
    <EdaContextProvider>
      <TopBar>{t("eda_page.heading")}</TopBar>
      <EdaPageContent />
    </EdaContextProvider>
  )
}
