import React, { FunctionComponent, useRef } from "react"
import { Theme, makeStyles, Grid, useTheme, Typography } from "@material-ui/core"
import { useTranslation } from "react-i18next"
import { WidgetFilter } from "../../../../api/graphql/query/report-detailed"
import { useQuery } from "react-apollo"
import {
  DailyGroupedStatsComparisonForAnalysisQueryResult,
  DailyGroupedStatsComparisonForAnalysisQueryVariables,
  DAILY_GROUPED_STATS_COMPARISON_FOR_ANALYSIS_QUERY,
} from "../../../../api/graphql/query/daily-grouped-stats-comparison-for-analysis"
import { TourTypeInfo } from "../../../../domain/models/TourTypes"
import { ClipLoader } from "react-spinners"
import lodash from "lodash"
import domToImage from "dom-to-image-more"
import { WertstoffAnalysisTrendComparisonGraph } from "../../analysis-data/WertstoffAnalysisTrendComparisonGraph"

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    minHeight: 250,
  },
}))

interface IWidgetTrendComparisonGraphWrapperProps {
  filter: WidgetFilter
  tourTypeInfo: TourTypeInfo | undefined | null
  onExportCreated: () => void
}

export const WidgetTrendComparisonGraphWrapper: FunctionComponent<IWidgetTrendComparisonGraphWrapperProps> = (
  props,
) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { filter, tourTypeInfo, onExportCreated } = props
  const theme = useTheme()

  const { data, loading, error } = useQuery<
    DailyGroupedStatsComparisonForAnalysisQueryResult,
    DailyGroupedStatsComparisonForAnalysisQueryVariables
  >(DAILY_GROUPED_STATS_COMPARISON_FOR_ANALYSIS_QUERY, {
    fetchPolicy: "no-cache",
    variables: {
      ...filter,
      associationIds: [filter.associationId],
      townIds: filter.widgetComparisonTownIds || [],
      firstDate: filter.dateFrom,
      lastDate: filter.dateUntil,
    },
  })
 
  const dailyGroupedStatsComparisonForAnalysis = data?.dailyGroupedStatsComparisonForAnalysis

  const chartRef = useRef(null)

  const exportChart = () => {
    console.log("exportChart started")
    if (!lodash.isFunction(onExportCreated)) {
      return
    }

    let foundNode = window.ReactDOM.findDOMNode(chartRef.current)
    if (lodash.isNil(foundNode)) {
      return
    }

    domToImage.toPng(foundNode, { scale: 10 }).then(function (imageBase64: string) {
      if (!lodash.isNil(onExportCreated)) {
        onExportCreated(imageBase64, ".png")
      }
    })
  }

  return (
    <Grid container className={classes.container}>
      {loading && (
        <Grid item xs container alignItems="center" justify="center">
          <Grid item>
            <ClipLoader color={theme.palette.primary.main} />
          </Grid>
        </Grid>
      )}
      {!loading && error && (
        <Grid item xs container alignItems="center" justify="center">
          <Grid item>
            <Typography>{t("general.no_data_available_reload_page")}</Typography>
          </Grid>
        </Grid>
      )}
      {!loading && !error && dailyGroupedStatsComparisonForAnalysis && (
        <WertstoffAnalysisTrendComparisonGraph
          dailyGroupedStatsComparisonForAnalysis={dailyGroupedStatsComparisonForAnalysis}
          tourTypeInfo={tourTypeInfo}
          chartRef={chartRef}
          onChartIsDrawn={exportChart}
          legend
        />
      )}
    </Grid>
  )
}
