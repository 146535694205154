import React, { FunctionComponent, Fragment, useState, useCallback } from "react"
import { Button, Grid, Typography } from "@material-ui/core"
import { useTranslation } from "react-i18next"
import { useHousingManagements } from "../../../context/HousingManagementContext"
import { TopBar } from "../heading/TopBar"
import { RootGrid } from "../layout/RootGrid"
import { HousingManagementExpansionPanel } from "./HousingManagementExpansionPanel"
import { PageLoadingIndicator } from "../indicator/PageLoadingIndicator"
import { HousingManagementEditDialog } from "./HousingManagementEditDialog"
import { HousingManagement } from "../../../api/graphql/query/housing-managements"
import { HousingManagementImportRfidChipDialog } from "./HousingManagementImportRfidChipDialog"

interface IHousingManagementContentProps {}

export const HousingManagementContent: FunctionComponent<IHousingManagementContentProps> = (props) => {
  const { t } = useTranslation()
  const { housingManagements, housingManagementsLoading, refresh } = useHousingManagements()
  const [housingManagementToEdit, setHousingManagementToEdit] = useState<HousingManagement | null>(null)
  const [housingManagementToImport, setHousingManagementToImport] = useState<HousingManagement | null>(null)
  const [editDialogOpen, setEditDialogOpen] = useState<boolean>(false)
  const [importDialogOpen, setImportDialogOpen] = useState<boolean>(false)

  const createItem = useCallback(() => {
    setHousingManagementToEdit(null)
    setEditDialogOpen(true)
  }, [setHousingManagementToEdit, setEditDialogOpen])

  const editItem = useCallback(
    (housingManagement: HousingManagement) => {
      setHousingManagementToEdit(housingManagement)
      setEditDialogOpen(true)
    },
    [setHousingManagementToEdit, setEditDialogOpen],
  )

  const importItem = useCallback(
    (housingManagement: HousingManagement) => {
      setHousingManagementToImport(housingManagement)
      setImportDialogOpen(true)
    },
    [setHousingManagementToEdit, setEditDialogOpen],
  )

  const editComplete = useCallback(() => {
    setHousingManagementToEdit(null)
    setEditDialogOpen(false)
    refresh()
  }, [setEditDialogOpen, refresh])

  const onEditCancel = useCallback(() => {
    setHousingManagementToEdit(null)
    setEditDialogOpen(false)
  }, [setEditDialogOpen])

  const importComplete = useCallback(() => {
    setImportDialogOpen(false)
    refresh()
  }, [setEditDialogOpen, refresh])

  return (
    <Fragment>
      <HousingManagementEditDialog
        housingManagement={housingManagementToEdit}
        isOpen={editDialogOpen}
        onCloseDialog={onEditCancel}
        onSuccess={editComplete}
      />
      <HousingManagementImportRfidChipDialog
        housingManagement={housingManagementToImport}
        isOpen={importDialogOpen}
        onClose={() => setImportDialogOpen(false)}
        onImportComplete={() => importComplete()}
      />
      <TopBar>{t("housing_management.heading")}</TopBar>
      <RootGrid spacing={2}>
        <Grid item xs={12} container justify={"flex-end"}>
          {!housingManagementsLoading && (
            <Button variant="contained" color="primary" onClick={() => createItem()}>
              {t("housing_management.add_housing_management")}
            </Button>
          )}
        </Grid>

        {!housingManagementsLoading && housingManagements && (
          <Grid item xs={12}>
            {housingManagements.map((housingManagement) => (
              <HousingManagementExpansionPanel
                key={housingManagement.id}
                housingManagement={housingManagement}
                onEdit={() => editItem(housingManagement)}
                onImport={() => importItem(housingManagement)}
              />
            ))}
          </Grid>
        )}
        {!housingManagementsLoading && !housingManagements.length && (
          <Grid container item xs={12} justify="center">
            <Typography variant="h5">{t("housing_management.no_housing_managements")}</Typography>
          </Grid>
        )}
        {housingManagementsLoading && (
          <Grid item xs={12}>
            <PageLoadingIndicator />
          </Grid>
        )}
      </RootGrid>
    </Fragment>
  )
}
