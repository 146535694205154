import React, { FunctionComponent, useContext, useState } from "react"
import { Theme, makeStyles, Grid, Button } from "@material-ui/core"
import { useTranslation } from "react-i18next"
import { PermittedAssociationsContext } from "../../../context/PermittedAssociationsContext"
import { PageLoadingIndicator } from "../../partials/indicator/PageLoadingIndicator"
import { TownExpansionPanelWithRfidTable } from "./TownExpansionPanelWithRfidTable"
import { AssociationSection } from "../citizen-management/AssociationSection"
import { ImportRfidChipDialog } from "../rfid-management/dialogs/ImportRfidChipDialog"
import { ExportRfidChipDialog } from "../rfid-management/dialogs/ExportRfidChipDialog"
import { RfidChipFilterContextProvider } from "../rfid-management/context/RfidChipFilterContext"
import { RootGrid } from "../../partials/layout/RootGrid"

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    paddingRight: theme.spacing(2),
  },
}))

interface IRfidManagementPageContentProps {}

export const RfidManagementPageContent: FunctionComponent<IRfidManagementPageContentProps> = (props) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const [importDialogOpen, setImportDialogOpen] = useState<boolean>(false)
  const [exportDialogOpen, setExportDialogOpen] = useState<boolean>(false)

  const { permittedAssociations, permittedAssociationsLoading } = useContext(PermittedAssociationsContext)

  return (
    <>
      <ImportRfidChipDialog open={importDialogOpen} onClose={() => setImportDialogOpen(false)} />
      <ExportRfidChipDialog open={exportDialogOpen} onClose={() => setExportDialogOpen(false)} />
      <RootGrid spacing={2}>
        {permittedAssociationsLoading && (
          <Grid item xs={12}>
            <PageLoadingIndicator />
          </Grid>
        )}
        {!permittedAssociationsLoading && permittedAssociations && (
          <>
            <Grid item xs={12} container justify={"flex-end"}>
              <Grid item className={classes.button}>
                <Button variant="contained" color="primary" onClick={() => setImportDialogOpen(true)}>
                  {t("rfid_management.import_rfids")}
                </Button>
              </Grid>
              <Grid item>
                <Button variant="contained" color="primary" onClick={() => setExportDialogOpen(true)}>
                  {t("rfid_management.export_rfids")}
                </Button>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              {permittedAssociations.map(
                (association) =>
                  association && (
                    <AssociationSection key={association.id} association={association}>
                      {association.towns.map((town) => (
                        <RfidChipFilterContextProvider key={town.id}>
                          <TownExpansionPanelWithRfidTable town={town} />
                        </RfidChipFilterContextProvider>
                      ))}
                    </AssociationSection>
                  ),
              )}
            </Grid>
          </>
        )}
      </RootGrid>
    </>
  )
}
