import React, { FunctionComponent, useContext, useEffect, useState } from "react"
import lodash from "lodash"

// @material-ui/core components
import { useQuery, useMutation } from "react-apollo"
import {
  WIDGET_TEXT_TEMPLATES_FOR_RATING_QUERY,
  WidgetTextCategoriesWithTemplatesForRatingVariables,
  WidgetTextCategoriesWithTemplatesResultForRating,
  WidgetTextCategoryForRating,
} from "../../../../api/graphql/query/widget-text-templates-for-rating"

import { WidgetModificationType } from "../../../../domain/models/WidgetModels"
import {
  DialogActions,
  Divider,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  Grid,
  FormControl,
  Select,
  MenuItem,
  TextField,
  Radio,
  Theme,
  makeStyles,
} from "@material-ui/core"
import QueryState from "../../state-management/QueryState"
import {
  UPDATE_WIDGET_MUTATION,
  UpdateWidgetResult,
  UpdateWidgetVariables,
} from "../../../../api/graphql/mutation/update-widget"
import {
  DELETE_WIDGET_MUTATION,
  DeleteWidgetResult,
  DeleteWidgetVariables,
} from "../../../../api/graphql/mutation/delete-widget"
import { replacementValueFrom, generateTextFromTemplate } from "../../../../domain/models/TextPlaceholders"
import { getRatingInfo, RatingInfo } from "../../../../domain/helper/CalcRating"
import { TourTypeDictionary, TourTypeInfo } from "../../../../domain/models/TourTypes"
import WidgetHeader from "./WidgetHeader"
import { ChannelType, TextPlaceholderKey } from "../../../../api/graphql/generated/globalTypes"
import { formatText } from "../../../../domain/helper/GenerateWidgetTitle"
import { toast } from "react-toastify"
import { getGraphqlErrorCode } from "../../../../utils/get-graphql-error-code"
import { useTranslation } from "react-i18next"
import { Widget } from "../../../../api/graphql/query/report-detailed"
import { ApolloError } from "apollo-client"
import {
  ReplacementForFilter,
  ReplacementForFilterVariables,
} from "../../../../api/graphql/generated/replacementForFilter"
import { REPLACEMENT_FOR_FILTER, ReplacementInfoArray } from "../../../../api/graphql/query/replacements_for_filter"
import { TextReplacementContext } from "../../../../context/TextReplacementContext"
import { EVALULATION_MINIMUM_FOR_FILTER_QUERY } from "../../../../api/graphql/query/evaluationMinimumForFilter"
import {
  EvaluationMinimumForFilter,
  EvaluationMinimumForFilter_materialAnalysisForFilter_evaluation,
  EvaluationMinimumForFilterVariables,
} from "../../../../api/graphql/generated/EvaluationMinimumForFilter"

// @Styles
const useStyles = makeStyles((theme: Theme) => ({
  content: {
    padding: theme.spacing(2),
  },
  textHeading: {
    fontSize: 16,
  },
  textContainer: {},
  textField: {
    marginTop: "16px",
    marginBottom: "8px",
  },
  title: {
    marginBottom: theme.spacing(0.5),
    overflowWrap: "break-word",
  },
  formControl: {
    width: "100%",
    justifyContent: "center",
  },
  form: {},
}))

const INDEX_CHOOSE = -1
const INDEX_FREETEXT = -2

interface IWidgetTextProps {
  widgetModel: Widget
  onModification: (type: WidgetModificationType, widget: Widget) => void
  onExportCreated: (fileName: string, uri: string, extension: string, payload: any) => void
}

export const WidgetText: FunctionComponent<IWidgetTextProps> = (props) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { widgetModel } = props
  const { placeholders } = useContext(TextReplacementContext)

  // const filter = widgetModel.filter
  const tourType: TourTypeInfo | null | undefined = TourTypeDictionary.find(
    (tourType) => tourType.type === widgetModel.filter?.tourType,
  )

  const [templateRequiredOpen, setTemplateRequiredOpen] = useState(false)
  const [editOpen, setEditOpen] = useState(props.widgetModel.textTitle === null)
  const [textId, setTextId] = useState<string | null | undefined>(null)
  const [textTitle, setTextTitle] = useState<string | null | undefined>(props.widgetModel.textTitle)
  const [textContent, setTextContent] = useState<string | null | undefined>(props.widgetModel.textContent)
  const [selectedTextIndex, setSelectedTextIndex] = useState<number>(
    !lodash.isNil(props.widgetModel.textContent) ? INDEX_FREETEXT : INDEX_CHOOSE,
  )
  const [payload, setPayload] = useState<any | null | undefined>(
    !lodash.isString(props.widgetModel.payload) ? null : JSON.parse(props.widgetModel.payload),
  )

  const [updateWidgetMutation] = useMutation<UpdateWidgetResult, UpdateWidgetVariables>(UPDATE_WIDGET_MUTATION, {
    onError: (error) => {
      handleWidgetMutationError(error)
    },
    onCompleted: () => {
      exportText()
      handleWidgetMutationComplete(WidgetModificationType.EDIT)
    },
  })

  function handleEditSave(refetch?: () => void) {
    if ((!lodash.isNil(textId) && selectedTextIndex !== INDEX_FREETEXT) || selectedTextIndex === INDEX_FREETEXT) {
      let updatedWidgetModel = lodash.cloneDeep(props.widgetModel)
      updatedWidgetModel.textTitle = textTitle ?? null
      updatedWidgetModel.textContent = textContent ?? null
      updatedWidgetModel.title = textTitle ?? null
      updatedWidgetModel.payload = JSON.stringify(payload)

      updateWidgetMutation({
        variables: {
          id: updatedWidgetModel.id,
          textTitle: updatedWidgetModel.textTitle ?? "",
          textContent: updatedWidgetModel.textContent,
          title: updatedWidgetModel.title,
          payload: updatedWidgetModel.payload,
        },
      }).then(() => {
        if (!lodash.isNil(refetch)) {
          refetch()
        }
      })
      setSelectedTextIndex(INDEX_FREETEXT)
      setEditOpen(false)
    } else {
      setTemplateRequiredOpen(true)
    }
  }

  const [deleteWidgetMutation] = useMutation<DeleteWidgetResult, DeleteWidgetVariables>(DELETE_WIDGET_MUTATION, {
    onError: (error) => {
      handleWidgetMutationError(error)
    },
    onCompleted: () => {
      handleWidgetMutationComplete(WidgetModificationType.DELETE)
    },
  })

  function handleEditClose() {
    const textContent = lodash.get(props.widgetModel, "textContent")
    if (lodash.isNil(textContent) || textContent === "") {
      deleteWidgetMutation({
        variables: {
          id: props.widgetModel.id,
        },
      }).then()
    } else {
      closeDialog()
    }
  }

  function closeDialog() {
    setSelectedTextIndex(INDEX_FREETEXT)
    setEditOpen(false)
  }

  function handleTextIndexChange(
    event: React.ChangeEvent<{ name?: string; value: unknown }>,
    possibleTexts: WidgetTextCategoryForRating[] | null,
  ) {
    if (!lodash.isArray(possibleTexts)) {
      return
    }
    let index: number = INDEX_FREETEXT
    if (lodash.isNumber(event.target.value)) {
      index = event.target.value
    }
    const isFreeText = index === INDEX_FREETEXT

    setSelectedTextIndex(index)
    setTextId(null)
    setTextTitle(isFreeText ? null : possibleTexts[index].name)
  }

  function handleChangeFreetext(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
    setTextContent(event.target.value)
  }

  function handleTextTitleChange(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
    setTextTitle(event.target.value)
  }

  function handleModification(type: WidgetModificationType, widgetModel: Widget) {
    if (type === WidgetModificationType.DELETE) {
      props.onModification(type, widgetModel)
    } else if (type === WidgetModificationType.DUPLICATE) {
      props.onModification(type, widgetModel)
    } else if (type === WidgetModificationType.EDIT) {
      setEditOpen(true)
    }
  }

  function generatePayload(
    replacementObject: ReplacementInfoArray | undefined,
    widgetTextCategories: WidgetTextCategoryForRating[] | null,
    overallRating: RatingInfo | null,
    evaluation: EvaluationMinimumForFilter_materialAnalysisForFilter_evaluation | null,
  ) {
    let payload = null
    let widgetTextCategory = lodash.find(widgetTextCategories, (category) => {
      return category.name === "Schwarzes Brett"
    })
    if (lodash.isObject(widgetTextCategory)) {
      let widgetTextTemplate = (widgetTextCategory.widgetTextTemplates || [])[0]
      if (
        props.widgetModel.channelType === ChannelType.black_board &&
        lodash.isObject(widgetTextTemplate) &&
        lodash.isString(lodash.get(widgetTextTemplate, "textTemplate")) &&
        lodash.isObject(replacementObject) &&
        lodash.isObject(overallRating)
      ) {
        let titleAllMissthrow = "??"
        let textAllMissthrow = "??"
        let titleMainMissthrow = "??"
        let textMainMissthrow = "??"
        let titleMunicipalMissthrow = "??"
        let textMunicipalMissthrow = "??"

        let splittedTextByType = widgetTextTemplate.textTemplate.split(
          placeholders?.find((x) => x.key === TextPlaceholderKey.NEW_LINE_DOUBLE)?.mark ?? "",
        )
        for (let index = 0; index < splittedTextByType.length; index++) {
          let titleAndTextString = splittedTextByType[index]
          if (!lodash.isString(titleAndTextString)) {
            // console.warn('titleAndTextString: ', titleAndTextString)
            continue
          }
          let titleAndText = titleAndTextString.split(
            placeholders?.find((x) => x.key === TextPlaceholderKey.NEW_LINE_SINGLE)?.mark ?? "",
          )
          // console.log('titleAndText: ', titleAndText)
          if (lodash.isArray(titleAndText) && titleAndText.length === 2) {
            let title = titleAndText[0]
            let textTemplate = titleAndText[1]
            // console.log('title: ', title, textTemplate, index)
            if (lodash.isString(title) && lodash.isString(textTemplate)) {
              switch (index) {
                case 0:
                  titleAllMissthrow = title
                  textAllMissthrow = generateTextFromTemplate(textTemplate, placeholders ?? [], replacementObject)
                  break
                case 1:
                  titleMainMissthrow = title
                  textMainMissthrow = generateTextFromTemplate(textTemplate, placeholders ?? [], replacementObject)
                  break
                case 2:
                  titleMunicipalMissthrow = title
                  textMunicipalMissthrow = generateTextFromTemplate(textTemplate, placeholders ?? [], replacementObject)
                  break
                default:
                  break
              }
            }
          }
        }

        const mainWasteName = tourType?.fraction.name ?? "--"
        const missthrowValueString = replacementValueFrom(replacementObject, TextPlaceholderKey.MISSTHROW_PERCENTAGE)
        const missthrowValue = parseFloat(missthrowValueString ?? "")
        const mainWastePercentage = (1 - missthrowValue) * 100

        let fractionsWithPercent = Object.entries(evaluation?.materialDistributionWithoutBagsDetailed ?? {}).map(
          ([fractionName, valuePercent]) => {
            return { fraction: `${t(fractionName)}`, value: valuePercent.value * 100 }
          },
        )

        payload = {
          payloadType: "T-MATIX",
          textStatus: overallRating.rating,
          titleAllMissthrow,
          textAllMissthrow,
          titleMainMissthrow,
          textMainMissthrow,
          titleMunicipalMissthrow,
          textMunicipalMissthrow,
          mainWasteName: `${t(mainWasteName)}`,
          mainWasteTotalPercentage: `${mainWastePercentage}`,
          otherWasteTotalPercentage: replacementObject.find((x) => x.key === TextPlaceholderKey.MISSTHROW_PERCENTAGE)
            ?.text,
          otherWasteTotalPercentageMunicipal: replacementObject.find(
            (x) => x.key === TextPlaceholderKey.MISSTHROW_PER_TOWN_PERCENTAGE,
          )?.text,
          fractionsWithPercent: fractionsWithPercent,
        }
      }
    }

    return payload
  }

  function handleRadioChange(text: string, title: string | undefined, id: string | undefined) {
    let payload = generatePayload(replacementObject, widgetTextCategories, overallRating, evaluation)
    setPayload(payload)
    setTextContent(text)
    setTextTitle(title)
    setTextId(id)
  }

  function handleWidgetMutationComplete(modificationType: WidgetModificationType) {
    props.onModification && props.onModification(modificationType, props.widgetModel)
    setSelectedTextIndex(INDEX_FREETEXT)
    setEditOpen(false)
  }

  function handleWidgetMutationError(error: ApolloError) {
    toast.error(t(`api_error.${getGraphqlErrorCode(error)}`))
    props.onModification && props.onModification(WidgetModificationType.EDIT, props.widgetModel)
    setSelectedTextIndex(INDEX_FREETEXT)
    setEditOpen(false)
  }

  function titleFrom(widgetModel: Widget): String {
    return widgetModel.title + " (" + widgetModel.channelType + "-" + widgetModel.id.substring(5, 10) + ")"
  }

  useEffect(() => {
    setTimeout(() => {
      exportText()
    }, 200)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [widgetModel])

  function exportText() {
    let { widgetModel } = props
    let { onExportCreated } = props

    if (!lodash.isFunction(onExportCreated)) {
      return
    }

    if (lodash.isNil(widgetModel) || lodash.isNil(widgetModel.textTitle) || lodash.isNil(widgetModel.textContent)) {
      // don't trigger onExportReady if textWidget doesn't have text content.
      return
    }

    let fileTitle = titleFrom(widgetModel)
    let fileText = widgetModel.textTitle + "\n" + widgetModel.textContent

    onExportCreated(fileTitle, fileText, ".txt", payload)
  }

  function isSaveButtonDisabled(): boolean {
    return (
      selectedTextIndex === INDEX_CHOOSE ||
      (selectedTextIndex === INDEX_FREETEXT && (textContent === "" || lodash.isNil(textContent)))
    )
  }

  function renderChooseTextInfoDialog() {
    return (
      <Dialog
        open={templateRequiredOpen}
        onClose={() => setTemplateRequiredOpen(false)}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">{t("widget_type.choose_text_title")}</DialogTitle>
        <DialogContent>
          <Typography>{t("widget_type.choose_text_info")}</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setTemplateRequiredOpen(false)} color="primary">
            {t("general.close")}
          </Button>
        </DialogActions>
      </Dialog>
    )
  }

  const { data, loading: ratingLoading, error } = useQuery<
    EvaluationMinimumForFilter,
    EvaluationMinimumForFilterVariables
  >(EVALULATION_MINIMUM_FOR_FILTER_QUERY, {
    variables: { filterId: widgetModel?.filter?.id ?? "--" },
  })

  const evaluation = data?.materialAnalysisForFilter?.evaluation ?? null
  console.log("evaluation: ", data)
  const rating = evaluation?.rating
  const overallRating: RatingInfo | null = lodash.isNil(rating) ? null : getRatingInfo(rating)

  const { data: widgetTextResult, loading: widgetTextLoading } = useQuery<
    WidgetTextCategoriesWithTemplatesResultForRating,
    WidgetTextCategoriesWithTemplatesForRatingVariables
  >(WIDGET_TEXT_TEMPLATES_FOR_RATING_QUERY, {
    variables: {
      rating: overallRating?.id,
      associationId: lodash.get(widgetModel, "filter.associationId", null),
      townId: (widgetModel.filter.townIds || []).length === 1 ? widgetModel.filter.townIds[0] : null,
    },
  })
  let widgetTextCategories: WidgetTextCategoryForRating[] | null = lodash.compact(
    widgetTextResult?.widgetTextCategories,
  )

  // show BlackBoard Texts only in BlackBoard Channel
  if (lodash.isArray(widgetTextCategories)) {
    widgetTextCategories = widgetTextCategories.filter((category) => {
      if (widgetModel.channelType === ChannelType.black_board) {
        return category?.name === "Schwarzes Brett"
      } else {
        return category?.name !== "Schwarzes Brett"
      }
    })
  }

  const { data: replacementData, loading: replacementLoading } = useQuery<
    ReplacementForFilter,
    ReplacementForFilterVariables
  >(REPLACEMENT_FOR_FILTER, {
    variables: { filterId: widgetModel.filter?.id ?? "" },
  })
  const replacementObject = replacementData?.replacementInfo?.replacements

  return (
    <div style={{ padding: 0 }}>
      {renderChooseTextInfoDialog()}
      <WidgetHeader widgetModel={widgetModel} onModification={handleModification} />
      <div className={classes.content}>
        <Typography variant={"h5"} className={classes.title}>
          {widgetModel.textTitle || "--"}
        </Typography>
        <div style={{ whiteSpace: "pre-wrap" }}>{formatText(widgetModel.textContent) || "--"}</div>
      </div>

      {/* Dialog for editing the text widget: */}
      {editOpen && (
        <QueryState
          loading={replacementLoading || widgetTextLoading || ratingLoading}
          error={error}
          notNil={replacementData}
        >
          {!lodash.isNil(widgetTextCategories) && (
            <Dialog
              open={editOpen}
              onClose={handleEditClose}
              aria-labelledby="form-dialog-title"
              maxWidth={"lg"}
              fullWidth={true}
              disableBackdropClick={true}
            >
              <DialogTitle id="form-dialog-title">{t("widget_type.title_text")}</DialogTitle>
              <DialogContent style={{ height: 520 }}>
                <Grid container direction={"column"} spacing={4}>
                  <Grid item xs={12}>
                    <Grid container direction={"row"} spacing={4} justify={"flex-start"} alignItems={"center"}>
                      <Grid item xs={6}>
                        <form className={classes.form} noValidate>
                          <FormControl className={classes.formControl} fullWidth>
                            <Select
                              fullWidth
                              value={selectedTextIndex}
                              onChange={(event) => handleTextIndexChange(event, widgetTextCategories)}
                            >
                              {[
                                <MenuItem disabled key={INDEX_CHOOSE} value={INDEX_CHOOSE}>
                                  {t("general.please_choose")}
                                </MenuItem>,
                                ...widgetTextCategories.map((category, index) => {
                                  return (
                                    <MenuItem key={index} value={index}>
                                      {t(category.translationKey)}
                                    </MenuItem>
                                  )
                                }),
                                <MenuItem key={INDEX_FREETEXT} value={INDEX_FREETEXT}>
                                  {t("report.free_text")}
                                </MenuItem>,
                              ]}
                            </Select>
                          </FormControl>
                        </form>
                      </Grid>

                      <Grid item xs={6}>
                        <Typography variant={"body1"}>
                          {`${t("general.rating")}: ${t(overallRating?.rating ?? "??")}`}
                        </Typography>
                      </Grid>
                    </Grid>

                    <Divider variant={"fullWidth"} />
                  </Grid>

                  {selectedTextIndex === INDEX_FREETEXT ? (
                    <Grid item>
                      <Grid container direction={"column"} spacing={2}>
                        <Grid item xs={12}>
                          <TextField
                            label={t("report.title")}
                            rowsMax="1"
                            value={textTitle || ""}
                            onChange={handleTextTitleChange}
                            className={classes.textField}
                            margin="normal"
                            helperText={null}
                            variant="outlined"
                            fullWidth={true}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            label={t("report.enter_free_text")}
                            multiline
                            rows="13"
                            rowsMax="13"
                            value={textContent || ""}
                            onChange={handleChangeFreetext}
                            className={classes.textField}
                            margin="normal"
                            helperText={null}
                            variant="outlined"
                            fullWidth={true}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  ) : selectedTextIndex !== INDEX_CHOOSE ? (
                    <Grid item>
                      <Grid container spacing={10}>
                        {widgetTextCategories[selectedTextIndex]?.widgetTextTemplates?.map((textTemplate) => {
                          let text = generateTextFromTemplate(
                            textTemplate?.textTemplate,
                            placeholders ?? [],
                            replacementObject,
                          )
                          return (
                            <Grid item className={classes.textContainer} key={textTemplate?.id} xs={6}>
                              <Grid container justify={"space-between"} direction={"row"} alignItems={"center"}>
                                <Grid item xs={11}>
                                  <Typography variant={"h6"} className={classes.textHeading}>
                                    {textTemplate?.title}
                                  </Typography>
                                </Grid>
                                <Grid item xs={1}>
                                  <Radio
                                    checked={textId === textTemplate?.id}
                                    value={textTemplate?.title}
                                    color="primary"
                                    onChange={() => handleRadioChange(text, textTemplate?.title, textTemplate?.id)}
                                  />
                                </Grid>
                              </Grid>
                              <div
                                style={{
                                  whiteSpace: "pre-wrap",
                                }}
                              >
                                {formatText(text)}
                              </div>
                            </Grid>
                          )
                        })}
                      </Grid>
                    </Grid>
                  ) : null}
                </Grid>
              </DialogContent>
              <DialogActions>
                <Button onClick={() => handleEditClose()} color="primary">
                  {t("general.cancel")}
                </Button>
                <Button onClick={() => handleEditSave()} color="primary" disabled={isSaveButtonDisabled()}>
                  {t("general.save")}
                </Button>
              </DialogActions>
            </Dialog>
          )}
        </QueryState>
      )}
    </div>
  )
}
