import {
  CreateHousingManagement as CreateHousingManagementRes,
  CreateHousingManagementVariables as CreateHousingManagementVars,
  CreateHousingManagement_createHousingManagement,
} from "./../generated/CreateHousingManagement"
import { gql } from "apollo-boost"

export const CREATE_HOUSING_MANAGEMENT_MUTATION = gql`
  mutation CreateHousingManagement($name: String!, $rfids: [String!]!) {
    createHousingManagement(name: $name, rfids: $rfids) {
      name
      rfids
      associations {
        id
        name
      }
      towns {
        id
        associationId
        name
      }
    }
  }
`

export interface CreateHousingManagementResult extends CreateHousingManagementRes {}
export interface CreateHousingManagement extends CreateHousingManagement_createHousingManagement {}
export interface CreateHousingManagementVariables extends CreateHousingManagementVars {}
