import React, { FunctionComponent, Fragment, useContext } from "react"
import { FormControl, Grid, InputLabel, Select, MenuItem, Input, makeStyles, Theme } from "@material-ui/core"
import { useTranslation } from "react-i18next"
import lodash from "lodash"
import WertstoffDropdown from "../controls/WertstoffDropdown.jsx"
import { WertstoffDatePicker } from "../controls/WertstoffDatePicker"
import { StatisticsFilterContext } from "../../../context/StatisticsFilterContext"
import { getFilterDates } from "../analysis-filter/helpers/getFilterDates"
import { ClassificationSelector, StatisticsFilter } from "../../../api/graphql/generated/globalTypes"
import { useQuery } from "react-apollo"
import { VEHICLES_QUERY, VehiclesResult } from "../../../api/graphql/query/vehicles"
import { getISOString } from "../../../utils/get-iso-string"
import { PermittedAssociationsContext } from "../../../context/PermittedAssociationsContext"

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: "100%",
    },
    getContentAnchorEl: null,
  },
}

const useStyles = makeStyles((theme: Theme) => ({
  formControl: {},
  gridItem: {
    width: "16.6%", // 1 / 5 of 100%
  },
}))

interface IStatisticsFilterComponentProps {}

export const StatisticsFilterComponent: FunctionComponent<IStatisticsFilterComponentProps> = (props) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const { filter, setFilter } = useContext(StatisticsFilterContext)

  const { minDateFrom, minDateUntil, maxDateFrom, maxDateUntil, disableFuture } = getFilterDates(
    filter?.timeRange?.end,
    filter?.timeRange?.start,
  )

  const { permittedAssociations } = useContext(PermittedAssociationsContext)

  const { data: vehiclesData } = useQuery<VehiclesResult>(VEHICLES_QUERY, { fetchPolicy: "network-only" })

  interface ISelectOption {
    id: string | undefined
    name: string
  }
  interface ISelectTarget {
    value: ISelectOption
  }

  const wasteScannerIdOptions: ISelectOption[] = lodash.compact(
    (vehiclesData?.me?.vehicles || []).map((v) => {
      const wasteScannerId = v?.wasteScannerId
      if (!lodash.isString(wasteScannerId) || lodash.isEmpty(wasteScannerId)) return null
      return { id: wasteScannerId, name: wasteScannerId }
    }),
  )

  const permittedAssociationOptions: ISelectOption[] =
    permittedAssociations.map((pm) => {
      return { id: pm.associationId, name: pm.name }
    }) ?? []

  const permittedTowns: ISelectOption[] =
    permittedAssociations
      .find((pm) => pm.associationId === filter.associationId)
      ?.towns.map((town) => {
        return { id: town.id, name: town.name }
      }) ?? []
  let townOptions: ISelectOption[] = [{ id: undefined, name: t("general.all").toUpperCase() }]
  townOptions = townOptions.concat(...permittedTowns)

  const sortedClassificationSelectorKeys = [
    ClassificationSelector.ALL,
    ClassificationSelector.STANDARD,
    ClassificationSelector.AUTOMATIC,
    ClassificationSelector.MANUAL,
  ]
  const classificationOptions: ISelectOption[] =
    sortedClassificationSelectorKeys.map((option) => {
      return { id: option, name: t(`analysis_page.classificationSelector.${option}`) }
    }) ?? []

  const handleChangeTowns = (event: React.ChangeEvent<{ name?: string | undefined; value: unknown }>) => {
    const optionsSelected: string[] = lodash.get(event, "target.value", [])
    const allTownIds = lodash.compact(townOptions.map((pt) => pt.id))
    if (optionsSelected.some((o) => o === undefined)) {
      // "All" option selected
      setFilter({ ...filter, townIds: undefined })
      return
    }
    const townIds: string[] = allTownIds.filter((ptId) => optionsSelected.includes(ptId))
    setFilter({ ...filter, townIds: !!townIds ? townIds : undefined })
  }

  return (
    <Fragment>
      {/* VIEW */}
      <Grid container spacing={2}>
        <Grid item className={classes.gridItem}>
          <WertstoffDropdown
            onChange={(event: React.MouseEvent<ISelectTarget>) => {
              const associationId: string | null = lodash.get(event, "target.value")
              setFilter({ ...filter, associationId, townIds: null })
            }}
            value={filter.associationId ?? null}
            values={permittedAssociationOptions}
            allPossible={true}
            name={t("analysis_page.filter.association")}
          />
        </Grid>
        <Grid item className={classes.gridItem}>
          <FormControl
            className={classes.formControl}
            disabled={lodash.isNil(filter.associationId)}
            fullWidth={true}
            margin={"normal"}
          >
            <InputLabel id="analysis_page.filter.town.label">{t("analysis_page.filter.town")}</InputLabel>
            <Select
              labelId="analysis_page.filter.town.label"
              id="analysis_page.filter.town"
              multiple
              value={filter.townIds ?? []}
              onChange={handleChangeTowns}
              input={<Input />}
              fullWidth
              MenuProps={MenuProps}
            >
              {townOptions.map((town) => (
                <MenuItem key={town.id} value={town.id}>
                  {town.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item className={classes.gridItem}>
          <WertstoffDropdown
            onChange={(event: React.MouseEvent<ISelectTarget>) => {
              const wasteScannerId: string | null = lodash.get(event, "target.value")
              setFilter({ ...filter, wasteScannerId })
            }}
            value={filter.wasteScannerId ?? null}
            values={wasteScannerIdOptions}
            allPossible={true}
            name={t("analysis_page.filter.waste_scanner")}
          />
        </Grid>
        <Grid item className={classes.gridItem}>
          <WertstoffDatePicker
            name={t("analysis_page.filter.period_from")}
            disableFuture={disableFuture}
            minDate={minDateFrom}
            maxDate={maxDateFrom}
            value={getISOString(filter.timeRange?.start)}
            onChange={(date) => {
              let newFilter: StatisticsFilter = lodash.cloneDeep(filter)
              if (!newFilter.timeRange) newFilter.timeRange = {}
              newFilter.timeRange.start = date?.startOf("d").toISOString() ?? null
              setFilter(newFilter)
            }}
          />
        </Grid>
        <Grid item className={classes.gridItem}>
          <WertstoffDatePicker
            name={t("analysis_page.filter.period_to")}
            disableFuture={disableFuture}
            minDate={minDateUntil}
            maxDate={maxDateUntil}
            value={getISOString(filter.timeRange?.end)}
            onChange={(date) => {
              let newFilter: StatisticsFilter = lodash.cloneDeep(filter)
              if (!newFilter.timeRange) newFilter.timeRange = {}
              newFilter.timeRange.end = date?.endOf("d").toISOString() ?? null
              setFilter(newFilter)
            }}
          />
        </Grid>
        <Grid item className={classes.gridItem}>
          <WertstoffDropdown
            onChange={(event: React.MouseEvent<ISelectTarget>) => {
              const classification: ClassificationSelector | null = lodash.get(event, "target.value")
              setFilter({ ...filter, classification })
            }}
            value={filter.classification ?? null}
            values={classificationOptions}
            allPossible={false}
            name={t("analysis_page.filter.classification")}
          />
        </Grid>
      </Grid>
    </Fragment>
  )
}
