// validateTours
import { gql } from "apollo-boost"
import {
  validateTour,
  validateTour_validateTour_warnings
} from "../generated/validateTour"

export const VALIDATE_TOUR_QUERY = gql`
  query validateTour($tour: TourInput!, $tourId: ID) {
    validateTour(tour: $tour, tourId: $tourId) {
      errors {
        id
        type
        line
        additionalData
      }
      warnings {
        id
        type
        line
        additionalData
      }
    }
  }
`

export type ValidateTourResult = validateTour
export type ValidateTourWarning = validateTour_validateTour_warnings
