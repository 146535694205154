import { gql } from "apollo-boost"
import {
  updateAssociation,
  updateAssociationVariables
} from "../generated/updateAssociation"

export const UPDATE_ASSOCIATION_MUTATION = gql`
  mutation updateAssociation($id: ID!, $association: AssociationInputUpdate!) {
    updateAssociation(id: $id, association: $association) {
      name
    }
  }
`

export type UpdateAssociationResult = updateAssociation
export type UpdateAssociationVariables = updateAssociationVariables
