import { gql } from "apollo-boost"
import { postToExternalNewsfeed, postToExternalNewsfeedVariables } from "../generated/postToExternalNewsfeed"

export const POST_TO_EXTERNAL_NEWSFEED_MUTATION = gql`
  mutation postToExternalNewsfeed(
    $townId: ID!
    $title: String!
    $text: String!
    $imageAsBase64: String
    $reportId: ID
    $externalTownType: ExternalTownType!
  ) {
    postToExternalNewsfeed(
      townId: $townId
      title: $title
      text: $text
      imageAsBase64: $imageAsBase64
      reportId: $reportId
      externalTownType: $externalTownType
    ) {
      message
      error
    }
  }
`
export type postToExternalNewsfeedMutationResult = postToExternalNewsfeed
export type postToExternalNewsfeedMutationVariables = postToExternalNewsfeedVariables
