import React, { FunctionComponent } from "react"
import { HousingManagementContextProvider } from "../../context/HousingManagementContext"
import { HousingManagementContent } from "../partials/housing-management/HousingManagementContent"

interface IHousingManagementPageProps {}

export const HousingManagementPage: FunctionComponent<IHousingManagementPageProps> = (props) => {
  return (
    <HousingManagementContextProvider>
      <HousingManagementContent />
    </HousingManagementContextProvider>
  )
}
