import React, { FunctionComponent, useContext } from "react"
import { Theme, makeStyles, Grid } from "@material-ui/core"
import { useTranslation } from "react-i18next"
import { FileTemplateContext } from "./context/FileTemplageContext"
import { PageLoadingIndicator } from "../indicator/PageLoadingIndicator"
import { FileTemplateItem } from "./FileTemplateItem"

const useStyles = makeStyles((theme: Theme) => ({
  gridItem: {
    padding: theme.spacing(1),
  },

  container: {
    flex: 1,
    height: "100%",
    padding: theme.spacing(1),
  },
}))

interface IFileTemplatePageContentProps {}

export const FileTemplatePageContent: FunctionComponent<IFileTemplatePageContentProps> = (props) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const { fileTemplates, fileTemplatesLoading } = useContext(FileTemplateContext)

  return (
    <Grid container justify="center" alignItems="center" className={classes.container}>
      {fileTemplatesLoading && <PageLoadingIndicator />}
      {!fileTemplatesLoading &&
        fileTemplates.map((fileTemplate) => (
          <Grid item xs={12} sm={6} lg={3} key={fileTemplate.url} className={classes.gridItem}>
            <FileTemplateItem fileTemplate={fileTemplate} />
          </Grid>
        ))}
    </Grid>
  )
}
