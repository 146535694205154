import React, { FunctionComponent, useMemo, useContext } from "react"
import { Theme, makeStyles, Grid, Typography, Paper, Button } from "@material-ui/core"
import { useTranslation } from "react-i18next"
import { reportDetail_report_widgets } from "../../../../api/graphql/generated/reportDetail"
import { ReportDetail } from "../../../../api/graphql/query/report-detailed"
import { ChannelType } from "../../../../api/graphql/generated/globalTypes"
import { CommunicationChannelTypeDictionary } from "../../../../domain/models/CommunicationChannelTypes"
import { WidgetSection } from "../widgets/WidgetSection"
import { WidgetExportsContext } from "../context/WidgetExportsContext"

const useStyles = makeStyles((theme: Theme) => ({
  headingContainer: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(3),
  },
  container: {
    padding: theme.spacing(2),
  },
  paper: {
    marginBottom: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  button: {
    marginLeft: theme.spacing(1),
  },
}))

export interface IChannelTypeAction {
  name: string
  onClick: () => void
  disabled?: boolean
}

export interface IChannelTypeSectionProps {
  widgets: reportDetail_report_widgets[]
  channelType: ChannelType
  report: ReportDetail
  actions: IChannelTypeAction[]
}

export const ChannelTypeSection: FunctionComponent<IChannelTypeSectionProps> = (props) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { channelType, widgets, report, actions } = props
  const { exportsReady } = useContext(WidgetExportsContext)

  const noWidgetsAvailable = useMemo(() => widgets && widgets.length <= 0, [widgets])

  return (
    <Paper className={classes.paper} elevation={0}>
      <Grid container className={classes.container}>
        <Grid item xs={12} className={classes.headingContainer}>
          <Grid container justify="space-between" alignItems="center">
            <Typography variant={"h5"}>{t(CommunicationChannelTypeDictionary[channelType].title)}</Typography>
            <Grid item>
              {actions.map((action) => {
                return (
                  <Button
                    onClick={action.onClick}
                    color="primary"
                    variant="contained"
                    disabled={noWidgetsAvailable || action.disabled || !exportsReady}
                    className={classes.button}
                  >
                    {action.name}
                  </Button>
                )
              })}
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <WidgetSection widgets={widgets} channelType={channelType} report={report} />
        </Grid>
      </Grid>
    </Paper>
  )
}
