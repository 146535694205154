import React, { FunctionComponent, useContext } from "react"
import { Theme, makeStyles, Button, useTheme } from "@material-ui/core"
import { useTranslation } from "react-i18next"
import { useLazyQuery } from "react-apollo"
import { StatisticsExcelExportResult, STATISTICS_EXCEL_QUERY } from "../../../api/graphql/query/statisticsExcel"
import { StatisticsExcelExportVariables } from "../../../api/graphql/generated/StatisticsExcelExport"
import { StatisticsFilterContext } from "../../../context/StatisticsFilterContext"
import { ClipLoader } from "react-spinners"

const useStyles = makeStyles((theme: Theme) => ({
  exportButton: { minWidth: 180 },
}))

interface IStatisticsExcelExportButtonProps {}

export const StatisticsExcelExportButton: FunctionComponent<IStatisticsExcelExportButtonProps> = (props) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const theme = useTheme()

  const { contextLoading, generateFilterVariables } = useContext(StatisticsFilterContext)

  const downloadExportedExcel = (data: StatisticsExcelExportResult) => {
    const base64 = data?.statisticsExcelExport?.base64

    if (base64) {
      saveAs(new Blob([Buffer.from(base64, "base64")]), `${t("statistics.excel_export.file_name")}.xlsx`)
    }
  }

  const [materialAnalysisExcelExportQuery, { loading }] = useLazyQuery<
    StatisticsExcelExportResult,
    StatisticsExcelExportVariables
  >(STATISTICS_EXCEL_QUERY, {
    fetchPolicy: "no-cache",
    onCompleted: downloadExportedExcel,
    onError: console.log,
  })

  const onExportButtonClicked = (event: any) => {
    materialAnalysisExcelExportQuery({
      variables: {
        filter: generateFilterVariables(),
      },
    })
  }

  return (
    <Button
      color="primary"
      variant="contained"
      className={classes.exportButton}
      onClick={onExportButtonClicked}
      disabled={contextLoading || loading}
    >
      {loading ? <ClipLoader color={theme.palette.common.white} size={16} /> : t("excel_export.excel_export")}
    </Button>
  )
}
