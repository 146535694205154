import { gql } from "apollo-boost"
import { sendToChannel, sendToChannelVariables } from "../generated/sendToChannel"

export const SEND_TO_CHANNEL_MUTATION = gql`
  mutation sendToChannel(
    $widgetId: ID!
    $channelType: CommunicationChannelType!
    $useRfidGroups: Boolean
    $associationId: ID
  ) {
    sendToChannel(
      widgetId: $widgetId
      channelType: $channelType
      useRfidGroups: $useRfidGroups
      associationId: $associationId
    ) {
      totalReceivers
      ratingLowReceivers
      ratingMediumReceivers
      ratingHighReceivers
      ratingUnknownReceivers
      sentToReceivers
      errorCount
      errorCode
      errorMessage
    }
  }
`

export type SendToChannelResult = sendToChannel
export type SendToChannelVariables = sendToChannelVariables
