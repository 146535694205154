import React, { FunctionComponent, useContext } from "react"
import { useTranslation } from "react-i18next"
import { Grid, makeStyles, Theme, Typography } from "@material-ui/core"
import { StatisticsFilterContext } from "../../../context/StatisticsFilterContext"
import { StatisticsExcelExportButton } from "./StatisticsExcelExportButton"
import { StatisticsRefinementComponent } from "./StatisticsRefinementComponent"

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    padding: theme.spacing(2),
  },
}))

interface IStatisticsOverviewProps {}

export const StatisticsOverview: FunctionComponent<IStatisticsOverviewProps> = (props) => {
  const { t } = useTranslation()

  const { statistics, contextLoading } = useContext(StatisticsFilterContext)

  const classes = useStyles()

  return (
    <Grid
      container
      direction={"row"}
      spacing={1}
      className={classes.container}
      justify="space-evenly"
      alignContent="flex-start"
    >
      <Grid item xs={12}>
        <Typography color="textSecondary" variant={"h6"}>
          {t("statistics.overview.text.total", { total: contextLoading ? "⏳" : statistics?.overall.total.value })}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography color="textPrimary" variant={"body1"}>
          {t("statistics.overview.text.valid", { valid: contextLoading ? "⏳" : statistics?.overall.valid.value })}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography color="textPrimary" variant={"body1"}>
          {t("statistics.overview.text.invalid", {
            invalid: contextLoading ? "⏳" : statistics?.overall.invalid.value,
          })}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <StatisticsRefinementComponent />
      </Grid>
      <Grid item xs={12}>
        <Grid container justify="flex-end">
          <StatisticsExcelExportButton />
        </Grid>
      </Grid>
    </Grid>
  )
}
