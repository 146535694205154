// validateTours
import { gql } from "apollo-boost"
import {
  importCitizens,
  importCitizensVariables,
  importCitizens_importCitizens_errors
} from "../generated/importCitizens"

export const IMPORT_CITIZENS_MUTATION = gql`
  mutation importCitizens($townId: ID!, $file: Upload!) {
    importCitizens(townId: $townId, file: $file) {
      errors {
        id
        type
        line
        additionalData
      }
      warnings {
        id
        type
        line
        additionalData
      }
      isValid
    }
  }
`

export type ImportCitizensVariables = importCitizensVariables
export type ImportCitizensResult = importCitizens
export type ImportCitizensError = importCitizens_importCitizens_errors
