import { gql } from "apollo-boost"
import {
  reportDetail,
  reportDetailVariables,
  reportDetail_report_widgets,
  reportDetail_report_widgets_filter,
  reportDetail_report,
  reportDetail_report_filter,
} from "../generated/reportDetail"

export const REPORT_DETAILED_QUERY = gql`
  query reportDetail($id: ID!) {
    report(id: $id) {
      id
      title
      createdAt
      filter {
        id
        associationId
        townIds
        rating
        fractionName
        fractionType
        fractionFrom
        fractionTo
        dateFrom
        dateUntil
        emptyingWeekNumber
        emptyingYearNumber
        displayOnlyAutomaticAnalysis
        widgetComparisonTownIds
        association {
          name
        }
        towns {
          name
          externalTown {
            id
            externalId
            type
          }
        }
        emptying {
          tours {
            name
          }
        }
        tourType
        rfids
      }
      widgets {
        id
        widgetType
        title
        textTitle
        textContent
        chartCompact
        channelType
        payload
        report {
          id
        }
        filter {
          id
          associationId
          townIds
          rating
          fractionName
          fractionType
          fractionFrom
          fractionTo
          dateFrom
          dateUntil
          emptyingWeekNumber
          emptyingYearNumber
          displayOnlyAutomaticAnalysis
          widgetComparisonTownIds
          association {
            name
          }
          towns {
            name
            externalTown {
              id
              externalId
              type
            }
          }
          emptying {
            tours {
              name
            }
          }
          tourType
          rfids
        }
        individualTextTemplateIds {
          rating
          value
        }
        individualTextTemplates {
          rating
          value
        }
        widgetTextCategory {
          id
          name
        }
      }
      communicationHistory {
        level
        type
        time
        payload {
          reportId
        }
      }
    }
  }
`

export type Widget = reportDetail_report_widgets
export type WidgetFilter = reportDetail_report_widgets_filter
export type ReportDetailVarialbes = reportDetailVariables
export type ReportDetailResult = reportDetail
export type ReportDetail = reportDetail_report
export type ReportDetailFilter = reportDetail_report_filter
