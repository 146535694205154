import React, { FunctionComponent, Fragment, useContext } from "react"
import { Theme, makeStyles, List, ListItem, ListSubheader, ListItemText, ListItemIcon } from "@material-ui/core"
import { useTranslation } from "react-i18next"
import lodash from "lodash"
import { LocalShippingRounded } from "@material-ui/icons"
import { SectionLoadingIndicator } from "./SectionLoadingIndicator"
import { SectionNoDataIndicator } from "./SectionNoDataIndicator"
import { Tour } from "../../../api/graphql/query/tours"
import { PermittedAssociationsContext } from "../../../context/PermittedAssociationsContext"

const useStyles = makeStyles((theme: Theme) => ({
  superList: {
    paddingTop: 3,
    paddingRight: 0,
    paddingBottom: 0,
    paddingLeft: 0,
  },
  list: {
    width: "100%",
    color: theme.palette.secondary.dark,
  },
  subList: {
    paddingTop: theme.spacing(1),
    paddingRight: 0,
    width: "100%",
  },
  subheading: {
    color: theme.palette.secondary.main,
    fontWeight: "normal",
    fontSize: 15,
    lineHeight: "15px",
    paddingBottom: theme.spacing(1),
  },
  listItem: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  subListListItem: {
    paddingTop: theme.spacing(1),
    paddingRight: 0,
    paddingBottom: theme.spacing(1),
  },
}))

interface IVehicleSectionProps {
  tours: Tour[]
  loading: boolean
}

export const VehicleSection: FunctionComponent<IVehicleSectionProps> = (props) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { tours, loading } = props
  const { permittedAssociations } = useContext(PermittedAssociationsContext)

  const associationsWithGroupedVehicles =
    permittedAssociations
      ?.map((association) => {
        return {
          ...association,
          towns: association.towns
            .map((town) => {
              return {
                ...town,
                wasteScanners: lodash.groupBy(
                  tours.filter((tour) => tour.towns.find((t) => t.id === town.id)),
                  "vehicle.wasteScannerId",
                ),
              }
            })
            .filter((town) => Object.keys(town.wasteScanners).length > 0),
        }
      })
      ?.filter((association) => association.towns.length > 0) || []

  return (
    <Fragment>
      {!loading && associationsWithGroupedVehicles.length > 0 && (
        <List className={classes.superList}>
          {associationsWithGroupedVehicles.map((association) => (
            <ListItem key={association.associationId} className={classes.listItem}>
              <List
                disablePadding
                className={classes.list}
                subheader={
                  <ListSubheader disableSticky className={classes.subheading}>
                    {association.name}
                  </ListSubheader>
                }
              >
                {association.towns.map((town) => (
                  <ListItem key={town.id} className={classes.subListListItem}>
                    <List
                      disablePadding
                      className={classes.subList}
                      subheader={
                        <ListSubheader className={classes.subheading} disableSticky>
                          {town.name}
                        </ListSubheader>
                      }
                    >
                      {Object.keys(town.wasteScanners).map((wasteScannerId) => (
                        <ListItem key={`${wasteScannerId} ${town.id}`}>
                          <ListItemIcon>
                            <LocalShippingRounded />
                          </ListItemIcon>
                          <ListItemText>{wasteScannerId}</ListItemText>
                        </ListItem>
                      ))}
                    </List>
                  </ListItem>
                ))}
              </List>
            </ListItem>
          ))}
        </List>
      )}
      {loading && <SectionLoadingIndicator />}
      {!loading && associationsWithGroupedVehicles.length <= 0 && (
        <SectionNoDataIndicator>{t("dashboard_page.vehicle_section.no_data")}</SectionNoDataIndicator>
      )}
    </Fragment>
  )
}
