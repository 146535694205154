import React, { FunctionComponent, Fragment, useState, useCallback, ReactNode } from "react"
import { Theme, makeStyles, Paper, Grid } from "@material-ui/core"
import { PaperHeader } from "./paper-header"
import { ClipLoader } from "react-spinners"
import { theme } from "../../../theme/theme"

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    marginTop: 0,
    padding: "12px",
    justifyContent: "center",
    color: theme.palette.text.secondary,
    borderRadius: theme.spacing(0.5),
    flex: 1,
  },
}))

interface ICustomPaperProps {
  headerContent: ReactNode
  additionalHeader?: ReactNode
  onToggleOpen?: (open: boolean) => void
  contentLoading?: boolean
}

export const CustomPaper: FunctionComponent<ICustomPaperProps> = (props) => {
  const classes = useStyles()
  const [open, setOpen] = useState<boolean>(false)
  const { contentLoading, onToggleOpen, headerContent, additionalHeader, children } = props

  const handleHeaderClicked = useCallback(() => {
    if (onToggleOpen) {
      onToggleOpen(!open)
    }
    setOpen(!open)
  }, [onToggleOpen, open])

  return (
    <Paper className={classes.paper}>
      <Grid container spacing={2} justify="space-between" direction="column">
        <PaperHeader
          additionalHeader={additionalHeader}
          onClick={handleHeaderClicked}
          showAdditionalHeader={open && !contentLoading}
          open={open}
        >
          {headerContent}
        </PaperHeader>
      </Grid>
      {open && contentLoading && (
        <Grid item xs container alignItems="center" justify="center">
          <Grid item>
            <ClipLoader color={theme.palette.primary.main} />
          </Grid>
        </Grid>
      )}
      {open && !contentLoading && children}
    </Paper>
  )
}
