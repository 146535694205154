import React, { FunctionComponent } from "react"
import { RootGrid } from "../layout/RootGrid"
import { Rating } from "../../../api/graphql/generated/globalTypes"
import lodash from "lodash"
import { useQuery } from "react-apollo"
import {
  WidgetTextCategoriesWithTemplatesResult,
  WidgetTextCategoriesWithTemplatesVariables,
  WIDGET_TEXT_TEMPLATES_FOR_CATEGORY_QUERY,
  WidgetTextTemplate,
} from "../../../api/graphql/query/widget-text-templates-for-category"
import { TextCategoryCard } from "./TextCategoryCard"
import { WidgetTextCategory } from "../../../api/graphql/query/widget-text-categories"
import { getRatingInfo, sortRating } from "../../../domain/helper/CalcRating"
import { useTextFilter } from "../../../context/TextFilterContext"
import { Grid } from "@material-ui/core"

interface ITextTemplateViewProps {
  selectedCategoryId: string | string[] | null
}

export const TextTemplateView: FunctionComponent<ITextTemplateViewProps> = (props) => {
  const { selectedCategoryId } = props
  const { associationId, townId } = useTextFilter()

  const {
    data: categoryWithTemplateData,
    loading: categoryWithTemplateLoading,
    error: categoryWithTemplateError,
    refetch: categoryWithTemplateRefetch,
  } = useQuery<WidgetTextCategoriesWithTemplatesResult, WidgetTextCategoriesWithTemplatesVariables>(
    WIDGET_TEXT_TEMPLATES_FOR_CATEGORY_QUERY,
    {
      variables: {
        id: selectedCategoryId as string,
        associationId,
        townId: townId === "all" ? null : townId,
        isExclusive: true,
      },
      fetchPolicy: "network-only",
    },
  )

  if (categoryWithTemplateError) {
    console.log(categoryWithTemplateError)
  }

  const category = lodash.get(categoryWithTemplateData, "widgetTextCategory", []) as WidgetTextCategory

  const templates = lodash.get(
    categoryWithTemplateData,
    "widgetTextCategory.widgetTextTemplates",
    [],
  ) as WidgetTextTemplate[]

  return (
    <RootGrid spacing={2}>
      {Object.keys(Rating)
        .sort(sortRating)
        .map((ratingKey) => (
          <Grid key={ratingKey} item xs={12}>
            <TextCategoryCard
              category={category}
              templates={templates.filter((template) => template.rating === ratingKey)}
              ratingKey={getRatingInfo(ratingKey).id}
              loading={categoryWithTemplateLoading}
              refetch={categoryWithTemplateRefetch}
            />
          </Grid>
        ))}
    </RootGrid>
  )
}
