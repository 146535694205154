import { gql } from "apollo-boost"
import { createDefaultRfidGroups, createDefaultRfidGroupsVariables } from "../generated/createDefaultRfidGroups"

export const CREATE_DEFAULT_RFID_GROUPS_MUTATION = gql`
  mutation createDefaultRfidGroups($townId: ID!) {
    createDefaultRfidGroups(townId: $townId) {
      createdCount
    }
  }
`

export type CreateDefaultRfidGroupsMutationResult = createDefaultRfidGroups
export type CreateDefaultRfidGroupsMutationVariables = createDefaultRfidGroupsVariables
