/*
    Hook which provides all filter functionalities
*/

import React, { createContext, useContext, useEffect, useState } from "react"
import { useQuery } from "react-apollo"
import { ME_SHORT_QUERY, MeShortResult, MeShortUser } from "../api/graphql/query/me_short"
import { AuthContext } from "./AuthContext"

export interface IUserContext {
  user: MeShortUser | null
}

export const UserContext = createContext<IUserContext>({
  user: null,
})

interface IUserContextProvider extends IUserContext {
  children: JSX.Element[] | JSX.Element | null
}

export const UserContextProvider = (props: IUserContextProvider) => {
  const { data: userData, refetch } = useQuery<MeShortResult>(ME_SHORT_QUERY, {
    fetchPolicy: "no-cache",
  })
  const [user, setUser] = useState<MeShortUser | null>(userData?.me ?? null)
  const { authenticated } = useContext(AuthContext)

  useEffect(() => {
    if (authenticated === true) {
      refetch()
        .then((result) => {
          setUser(result.data.me ?? null)
        })
        .catch((reason) => console.error(reason))
    } else {
      setUser(null)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authenticated])

  return <UserContext.Provider value={{ user }}>{props.children}</UserContext.Provider>
}
