import React, { FunctionComponent } from "react"
import {
  Theme,
  makeStyles,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Button,
} from "@material-ui/core"
import { useTranslation } from "react-i18next"

const useStyles = makeStyles((theme: Theme) => ({
  span: {
    whiteSpace: "pre-line",
  },
}))

interface ILogMessageDialogProps {
  onClose: () => void
  onExited: () => void
  open: boolean
  message: string
}

export const LogMessageDialog: FunctionComponent<ILogMessageDialogProps> = (props) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { onClose, onExited, open, message } = props

  return (
    <Dialog open={open} onClose={onClose} onExited={onExited}>
      <DialogTitle>{t("log.property.message")}</DialogTitle>
      <DialogContent>
        <span className={classes.span}>{message}</span>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary" variant="contained">
          {t("general.close")}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
