export const convertValueToTextfieldType = (value: string, textfieldType: string) => {
  switch (textfieldType) {
    case "number":
      if(value === "") {
        return null
      }
      return Number(value)
    default:
      return value
  }
}
