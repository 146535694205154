import React, { FunctionComponent, Fragment } from "react"
import { useTranslation } from "react-i18next"
import { Tour } from "../../../api/graphql/query/tours"
import { SectionLoadingIndicator } from "./SectionLoadingIndicator"
import { TourTable } from "../tour-management/TourTable"
import { SectionNoDataIndicator } from "./SectionNoDataIndicator"

interface ITourSectionProps {
  tours: Tour[]
  loading: boolean
  onAction?: (tour: Tour) => void
}

export const TourSection: FunctionComponent<ITourSectionProps> = props => {
  const { t } = useTranslation()
  const { loading, tours, onAction } = props

  return (
    <Fragment>
      {!loading && tours && tours.length > 0 && (
        <TourTable
          tours={tours}
          loading={loading}
          compact
          pageSize={tours.length}
          showPagination={false}
          onAction={onAction}
        />
      )}
      {loading && <SectionLoadingIndicator />}
      {!loading && (!tours || tours.length <= 0) && (
        <SectionNoDataIndicator>
          {t("dashboard_page.tour_section.no_data")}
        </SectionNoDataIndicator>
      )}
    </Fragment>
  )
}
