import React from "react"
import { makeStyles, Theme } from "@material-ui/core"
import { CheckCircle, Error } from "@material-ui/icons"
import { COLORS } from "../../../theme/theme"
import { ClipLoader } from "react-spinners"

const useStyles = makeStyles((theme: Theme) => ({
  success: {
    color: theme.palette.success.main,
    height: "inherit",
  },
  disabled: {
    height: "inherit",
  },
  failure: {
    color: COLORS.darkred,
    height: "inherit",
  },
  validationSigns: {
    display: "flex",
    marginRight: theme.spacing(1),
    height: 20,
    width: 20,
    justifyContent: "center",
    alignItems: "center",
  },
}))

export interface IValidationSign {
  isValid?: boolean
  loading?: boolean
}

export const ValidationSign: React.FunctionComponent<IValidationSign> = (props) => {
  const { isValid, loading } = props
  const classes = useStyles()

  return (
    <div className={classes.validationSigns}>
      {loading && <ClipLoader size={12} />}
      {!loading && isValid && <CheckCircle className={classes.success} />}
      {!loading && isValid === false && <Error className={classes.failure} />}
      {!loading && isValid === undefined && <Error className={classes.disabled} />}
    </div>
  )
}
