import React, { FunctionComponent, Fragment, createContext, useMemo } from "react"
import { useQuery } from "react-apollo"
import {
  FileTemplate,
  FileTemplateQueryResult,
  FILE_TEMPLATES_QUERY,
} from "../../../../api/graphql/query/file-templates"

interface IFileTemplateContextProviderProps {}

interface IFileTemplateContext {
  fileTemplates: FileTemplate[]
  fileTemplatesLoading: boolean
}

export const FileTemplateContext = createContext<IFileTemplateContext>({
  fileTemplates: [],
  fileTemplatesLoading: true,
})

export const FileTemplateContextProvider: FunctionComponent<IFileTemplateContextProviderProps> = (props) => {
  const { data, loading } = useQuery<FileTemplateQueryResult>(FILE_TEMPLATES_QUERY)

  const fileTemplates = useMemo(() => data?.fileTemplates || [], [data])

  return (
    <FileTemplateContext.Provider
      value={{
        fileTemplates,
        fileTemplatesLoading: loading,
      }}
    >
      {props.children}
    </FileTemplateContext.Provider>
  )
}
