import React from "react"
import PropTypes from "prop-types"
import lodash from "lodash"

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles"

import {
  DialogActions,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  FormGroup,
  FormControlLabel,
  Switch,
  Button,
} from "@material-ui/core"

import { WidgetModificationType } from "../../../../domain/models/WidgetModels"
import { WertstoffAnalysisHistoryGraph } from "../../analysis-data/WertstoffAnalysisHistoryGraph"

import { Mutation } from "react-apollo"
import { UPDATE_FILTER_MUTATION } from "../../../../api/graphql/mutation/update-filter"
import { UPDATE_WIDGET_MUTATION } from "../../../../api/graphql/mutation/update-widget"
import { transformPersistentFilterToUiFilter } from "../../../../domain/helper/TransformPersistentFilterToUiFilter"
import { WertstoffAnalysisPieGraph } from "../../analysis-data/WertstoffAnalysisPieGraph"
import { TourTypeDictionary } from "../../../../domain/models/TourTypes"
import Typography from "@material-ui/core/Typography"
import { toast } from "react-toastify"
import { getGraphqlErrorCode } from "../../../../utils/get-graphql-error-code"
import { withTranslation } from "react-i18next"
import { WidgetType } from "../../../../api/graphql/generated/globalTypes"
import { FilterHeading } from "../../report/FilterHeading"

// @Styles
const styles = (theme) => ({
  content: {
    padding: theme.spacing(2),
  },
  centerContent: {
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)",
  },
})

function Graph(props) {
  const { graphType, analysisData, compact, tourType } = props
  if (graphType === WidgetType.chart_history) {
    return (
      <WertstoffAnalysisHistoryGraph
        materialAnalysis={analysisData.materialAnalysis}
        evaluation={analysisData.evaluation}
        compact={compact}
        legend
        tourTypeInfo={tourType}
        grouped={false}
      />
    )
  } else if (graphType === WidgetType.chart_pie) {
    return (
      <WertstoffAnalysisPieGraph
        evaluation={analysisData.evaluation}
        compact={compact}
        legend
        tourTypeInfo={tourType}
      />
    )
  } else {
    return null
  }
}

class EditWidgetGraphDialog extends React.Component {
  constructor(props) {
    super(props)
    this.props = props

    this.state = {
      //editOpen: lodash.isNil(lodash.get(props.widgetModel, "filter.updatedAt")),
      widgetTitle: props.widgetModel.title,
      filter: transformPersistentFilterToUiFilter(props.widgetModel.filter),
      chartCompact: lodash.get(props.widgetModel, "chartCompact") || false,
    }
  }

  componentWillReceiveProps(nextProps) {
    this.props = nextProps
  }

  handleEditSave = (updateFilterMutation, updateWidgetMutation) => {
    //let updatedFilter = this.state.filter || {}
    /*
        if (lodash.isFunction(updateFilterMutation)) {
            updateFilterMutation(
                {
                    variables: {
                        ...updatedFilter,
                        id: lodash.get(this.props.widgetModel, "filter.id")
                    }
                }
            )
        }
        */
    if (lodash.isFunction(updateWidgetMutation)) {
      updateWidgetMutation({
        variables: {
          chartCompact: this.state.chartCompact,
          id: lodash.get(this.props.widgetModel, "id"),
          title: this.state.widgetTitle,
        },
      })
    }
  }
  /*
    handleFilterChange = (filterKey, event, refetch) => {

        const newFilterModel = this.state.filter || {}
        const newValue = lodash.get(event, "target.value")

        newFilterModel[filterKey] = newValue
        if (filterKey === FilterModelKeys.associationId) {
            newFilterModel[FilterModelKeys.townId] = null
            newFilterModel[FilterModelKeys.tourId] = null
        } else if (filterKey === FilterModelKeys.townId) {
            newFilterModel[FilterModelKeys.tourId] = null
        } else if (filterKey === FilterModelKeys.filterFractionType) {
            newFilterModel["fractionType"] = newValue
        } else if (filterKey === FilterModelKeys.filterFractionFrom) {
            newFilterModel["fractionFrom"] = lodash.isNil(newValue) ? null : Number(newValue)
        } else if (filterKey === FilterModelKeys.filterFractionTo) {
            newFilterModel["fractionTo"] = lodash.isNil(newValue) ? null : Number(newValue)
        } else if (filterKey === FilterModelKeys.filterRating) {
            newFilterModel["rating"] = newValue
        } else if (filterKey === FilterModelKeys.filterDateFrom) {
            newFilterModel["dateFrom"] = moment(newValue).toISOString()
        } else if (filterKey === FilterModelKeys.filterDateUntil) {
            newFilterModel["dateUntil"] = moment(newValue).toISOString()
        } else if (["id", "__typename"].includes(filterKey)) {
            // nothing to do
        } else if (filterKey === FilterModelKeys.association) {
            // nothing to do
        } else if (filterKey === FilterModelKeys.municipal) {
            // nothing to do
        } else if (filterKey === FilterModelKeys.tour) {
            // nothing to do
        }
        else {
            console.log('Unknown filterKey to change: ', filterKey)
        }

        this.setState({
            filter: newFilterModel,
        })

        refetch()
    }
    */

  handleMutationComplete = () => {
    const widgetModel = {
      ...this.props.widgetModel,
      chartCompact: this.state.chartCompact,
    }
    if (lodash.isFunction(this.props.onModification)) {
      this.props.onModification(WidgetModificationType.EDIT, widgetModel)
    }
    this.props.onClose()
  }

  handleMutationError = (error) => {
    toast.error(this.props.t(`api_error.${getGraphqlErrorCode(error)}`))
    if (lodash.isFunction(this.props.onModification)) {
      this.props.onModification(WidgetModificationType.EDIT, this.props.widgetModel)
    }
    this.props.onClose()
  }

  handleChange = (name) => (event) => {
    this.setState({ [name]: event.target.checked })
  }

  handleTitleChange = (event) => {
    this.setState({ widgetTitle: event.target.value })
  }

  render() {
    const { classes, materialAnalysis, tourType, open, widgetModel, onClose, t } = this.props

    console.log(widgetModel)
    return (
      <Mutation
        mutation={UPDATE_FILTER_MUTATION}
        onCompleted={this.handleMutationComplete}
        onError={this.handleMutationError}
      >
        {(updateFilterMutation) => (
          <Mutation
            mutation={UPDATE_WIDGET_MUTATION}
            onCompleted={this.handleMutationComplete}
            onError={this.handleMutationError}
          >
            {(updateWidgetMutation) => (
              <Dialog
                open={open}
                onClose={onClose}
                aria-labelledby="form-dialog-title"
                maxWidth={"md"}
                fullWidth={true}
                className={classes.dialog}
              >
                <DialogTitle id="form-dialog-title">{t("general.diagram")}</DialogTitle>
                <DialogContent style={{ padding: 24 }}>
                  <Grid container justify={"center"} align={"center"} direction={"row"}>
                    <Grid item xs={12}>
                      <FilterHeading filter={this.state.filter} />
                      {/* individual Filter for Widget, we might need it for later
                                                    <WertstoffFilter filterModel={this.transformPersistentFilterToUiFilter(this.state.filter || {})}
                                                      onChange={(key, event) => this.handleFilterChange(key, event, refetch)}
                                                      meData={meData}
                                                      tourType={this.tourType}
                                                    />
                                                  */}
                    </Grid>
                    <Grid item xs={12} style={{ marginTop: 12 }}>
                      <TextField
                        fullWidth
                        label={t("report.title")}
                        value={this.state.widgetTitle}
                        onChange={this.handleTitleChange}
                      />
                    </Grid>
                    {widgetModel.widgetType !== WidgetType.chart_pie_waste_quality && (
                      <Grid item xs={12}>
                        <FormGroup row>
                          <FormControlLabel
                            control={
                              <Switch
                                checked={this.state.chartCompact}
                                onChange={this.handleChange("chartCompact")}
                                value="compact"
                                color="primary"
                              />
                            }
                            label="Fehlwürfe zusammengefasst"
                          />
                        </FormGroup>
                      </Grid>
                    )}
                  </Grid>
                </DialogContent>
                <DialogActions>
                  <Button onClick={onClose} color="primary">
                    {t("general.cancel")}
                  </Button>
                  <Button
                    onClick={() => this.handleEditSave(updateFilterMutation, updateWidgetMutation)}
                    color="primary"
                  >
                    {t("general.save")}
                  </Button>
                </DialogActions>
              </Dialog>
            )}
          </Mutation>
        )}
      </Mutation>
    )
  }
}

EditWidgetGraphDialog.propTypes = {
  widgetModel: PropTypes.object,
  onModification: PropTypes.func,
  onExportCreated: PropTypes.func,
  tourType: PropTypes.instanceOf(TourTypeDictionary),
}

EditWidgetGraphDialog.defaultProps = {
  widgetModel: null,
  onModification: null,
  onExportCreated: null,
}

export default withTranslation()(withStyles(styles)(EditWidgetGraphDialog))
