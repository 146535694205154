import { gql } from "apollo-boost"
import { signin, signinVariables } from "../generated/signin"

export const SIGN_IN_MUTATION = gql`
  mutation signin($email: String!, $password: String!) {
    signin(email: $email, password: $password) {
      token
      valid
    }
  }
`

export type SignInResult = signin
export type SignInVariables = signinVariables
