import React, { FunctionComponent, Fragment, useEffect } from "react"
import { Tooltip, Fab } from "@material-ui/core"
import { useTranslation } from "react-i18next"
import { createColumns, DefaultTable } from "../table/DefaultTable"
import { useQuery } from "react-apollo"
import lodash from "lodash"
import { Column, DerivedDataObject } from "react-table"
import { Edit } from "@material-ui/icons"
import { PageLoadingIndicator } from "../indicator/PageLoadingIndicator"
import { ChipsForArray } from "./ChipsForArray"
import { UserAndPermissions } from "../../../api/graphql/query/users-and-assigned-associations"
import {
  UsersAndAssignedAssociationsResult,
  USERS_AND_ASSIGNED_ASSOCIATIONS_QUERY
} from "../../../api/graphql/query/users-and-assigned-associations"
import { CardListForAssociations } from "./CardListForAssociations"
import { searchFields } from "../../../utils/search-fields"

interface IUserTableProps {
  setUserAndPermissionsToEdit: (userAndPermissions: UserAndPermissions) => void
  toggleRefetch: boolean
  searchTerm: string
}

export const UserTable: FunctionComponent<IUserTableProps> = props => {
  const { t } = useTranslation()
  const { toggleRefetch, setUserAndPermissionsToEdit, searchTerm } = props

  const { data, error, loading, refetch } = useQuery<
    UsersAndAssignedAssociationsResult
  >(USERS_AND_ASSIGNED_ASSOCIATIONS_QUERY)

  const usersAndPermissions = lodash.get(data, "usersAndAssignedAssociations")

  if (error) {
    console.log(error)
  }

  useEffect(() => {
    refetch()
  }, [toggleRefetch, refetch])

  let filteredUsersAndPermissions = lodash.cloneDeep(usersAndPermissions)

  //filter on search
  if (searchTerm.trim().length > 0 && usersAndPermissions) {
    filteredUsersAndPermissions = usersAndPermissions.filter(
      userAndPermission =>
        searchFields(
          [
            userAndPermission.user.email,
            userAndPermission.user.abilities.map(a => t(`user_management.ability.${a}`)).join(" "),
            userAndPermission.permittedAssociations
              .map(permAssociation => permAssociation.name)
              .join(" "),
            userAndPermission.permittedAssociations
              .map(permAssociation =>
                permAssociation.towns.map(town => town.name)
              )
              .join(" ")
          ],
          searchTerm
        )
    )
  }

  const columns: Column[] = createColumns([
    {
      Header: t("user.property.email"),
      accessor: "user.email",
      maxWidth: 250
    },
    {
      Header: t("general.associations_and_towns"),
      accessor: "permittedAssociations",
      Cell: ({ row }: { row: DerivedDataObject }) => {
        const associations =
          lodash.get(row, "_original.permittedAssociations", []) || []
        return <CardListForAssociations associations={associations} />
      }
    },
    {
      Header: t("user.property.default_town"),
      accessor: "user.defaultTown.name",
      maxWidth: 200
    },
    {
      Header: t("user.property.abilities"),
      accessor: "user.abilities",
      width: 250,
      Cell: ({ row }: { row: DerivedDataObject }) => {
        const abilities: string[] = lodash.get(row, "_original.user.abilities", []) || []
        const translatedAbilities = abilities.map(a => t(`user_management.ability.${a}`))
        return <ChipsForArray elements={translatedAbilities} />
      }
    },
    {
      Header: "",
      accessor: "",
      width: 70,
      Cell: ({ row }: { row: DerivedDataObject }) => {
        return (
          <Tooltip title={t("general.edit")}>
            <Fab
              size="small"
              color="primary"
              onClick={() => setUserAndPermissionsToEdit(row._original)}
            >
              <Edit />
            </Fab>
          </Tooltip>
        )
      }
    }
  ])

  const minRows = filteredUsersAndPermissions
    ? filteredUsersAndPermissions.length
    : 0

  return (
    <Fragment>
      {minRows > 0 && !loading && (
        <DefaultTable
          columns={columns}
          loading={loading}
          data={filteredUsersAndPermissions || []}
          minRows={minRows}
          showPagination={false}
        />
      )}
      {loading && <PageLoadingIndicator />}
    </Fragment>
  )
}
