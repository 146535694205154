import React, { FunctionComponent, useMemo, useContext } from "react"
import { Theme, makeStyles, Grid, Typography, Button } from "@material-ui/core"
import { useTranslation } from "react-i18next"
import { WasteQualityList } from "./WasteQualityList"
import { WasteQualityPieGraph } from "./WasteQualityPieGraph"
import { createWasteQualityDictionary } from "./create-waste-quality-dictionary"
import { AuthContext } from "../../../../context/AuthContext"
import { MaterialAnalysisForMap } from "../../../../api/graphql/query/material-analysis-for-map"
import { ExcelExportButton } from "../ExcelExportButton"

const useStyles = makeStyles((theme: Theme) => ({
  reportCreateButton: {
    minWidth: 180,
  },
}))

interface IAnalysisWasteQualityPieGraphSectionProps {
  materialAnalysis: MaterialAnalysisForMap[]
  onReportCreate: () => void
}

export interface IWasteQualityDictionary {
  [key: string]: { analysis: MaterialAnalysisForMap[]; percentage: number }
}

export const AnalysisWasteQualityPieGraphSection: FunctionComponent<IAnalysisWasteQualityPieGraphSectionProps> = (
  props,
) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const { materialAnalysis, onReportCreate } = props
  const { isHousingManagement } = useContext(AuthContext)
  const wasteQualityDataDictionary = useMemo(() => createWasteQualityDictionary(materialAnalysis), [materialAnalysis])

  return (
    <Grid container direction={"row"} alignItems={"stretch"} justify={"space-evenly"}>
      <Grid item xs={12}>
        <Typography variant={"h5"}>{t("analysis_page.waste_quality.heading")}</Typography>
      </Grid>
      <Grid item xs={6} sm={6} md={5} lg={4} xl={4}>
        <WasteQualityList dictionary={wasteQualityDataDictionary} />
      </Grid>
      <Grid item xs={6} sm={6} md={5} lg={4} xl={4}>
        <WasteQualityPieGraph dictionary={wasteQualityDataDictionary} />
      </Grid>
      <Grid item container justify="flex-end" spacing={2}>
        <Grid item>
          <ExcelExportButton />
        </Grid>
        {!isHousingManagement() && (
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              aria-label="Bericht erstellen"
              onClick={onReportCreate}
              className={classes.reportCreateButton}
            >
              {t("report.create_report")}
            </Button>
          </Grid>
        )}
      </Grid>
    </Grid>
  )
}
