// @Wertstoff
import { TourPage } from "../components/pages/TourPage"
import { AssociationManagementPage } from "../components/pages/AssociationManagementPage"
import { CitizenManagementPage } from "../components/pages/CitizenManagementPage"
import { UserManagementPage } from "../components/pages/UserManagementPage"
import { TextManagementPage } from "../components/pages/TextManagementPage"
import { AnalysisPage } from "../components/pages/AnalysisPage"
import { DashboardPage } from "../components/pages/DashboardPage"
import { VehicleManagementPage } from "../components/pages/VehicleManagementPage"
import { LogPage } from "../components/pages/LogPage"

// @material-ui/icons
import {
  Assessment,
  Chat,
  Subway,
  HomeWork,
  NaturePeople,
  People,
  TextFields,
  DashboardRounded,
  LocalShippingRounded,
  Compare,
  PictureAsPdf,
  Cloud,
  Description,
  Memory,
  House,
  FormatAlignJustify,
  Domain,
  List,
  // Cloud,
} from "@material-ui/icons"
import { AbilityName } from "../api/graphql/generated/globalTypes"
import { StatisticsPage } from "../components/pages/StatisticsPage"
import { PDFPage } from "../components/pages/PDFPage"
import { EdaPage } from "../components/pages/EdaPage"
import { FileTemplatePage } from "../components/pages/FileTemplatePage"
import { RfidManagementPage } from "../components/pages/RfidManagementPage"
import { RfidGroupsPage } from "../components/pages/RfidGroupsPage"
import { CommunicationCenterPage } from "../components/pages/CommunicationCenterPage"
import { HousingManagementPage } from "../components/pages/HousingManagementPage"

const dashRoutes = [
  {
    path: "/dashboard",
    name: "menu.dashboard",
    icon: DashboardRounded,
    component: DashboardPage,
    unauthenticatedAccess: false,
    requiredAbilities: [AbilityName.manager, AbilityName.admin, AbilityName.area_manager, AbilityName.housing_management],
  },
  {
    path: "/analysis",
    name: "menu.analysis",
    icon: Assessment,
    component: AnalysisPage,
    unauthenticatedAccess: false,
    requiredAbilities: [AbilityName.manager, AbilityName.admin, AbilityName.area_manager, AbilityName.housing_management],
  },
  {
    path: "/pdf",
    name: "menu.pdf",
    icon: PictureAsPdf,
    component: PDFPage,
    unauthenticatedAccess: false,
    requiredAbilities: [AbilityName.advancement_pdf],
  },
  {
    path: "/statistics",
    name: "menu.statistics",
    icon: Compare,
    component: StatisticsPage,
    unauthenticatedAccess: false,
    requiredAbilities: [AbilityName.admin],
  },
  {
    path: "/communication-center",
    name: "menu.communication_center",
    icon: Chat,
    component: CommunicationCenterPage,
    unauthenticatedAccess: false,
    requiredAbilities: [AbilityName.manager, AbilityName.admin, AbilityName.area_manager],
  },
  {
    path: "/text-management",
    name: "menu.text_management",
    icon: TextFields,
    component: TextManagementPage,
    unauthenticatedAccess: false,
    requiredAbilities: [AbilityName.manager, AbilityName.admin, AbilityName.area_manager],
  },
  {
    path: "/tours",
    name: "menu.tours",
    icon: Subway,
    component: TourPage,
    unauthenticatedAccess: false,
    requiredAbilities: [AbilityName.manager, AbilityName.admin, AbilityName.area_manager],
  },
  {
    path: "/vehicle-management",
    name: "menu.vehicle_management",
    icon: LocalShippingRounded,
    component: VehicleManagementPage,
    unauthenticatedAccess: false,
    requiredAbilities: [AbilityName.admin],
  },
  {
    path: "/association-management",
    name: "menu.association_management",
    icon: HomeWork,
    component: AssociationManagementPage,
    unauthenticatedAccess: false,
    requiredAbilities: [AbilityName.admin, AbilityName.area_manager],
  },
  {
    path: "/housing-managements-management",
    name: "menu.housing_managements_management",
    icon: Domain,
    component: HousingManagementPage,
    unauthenticatedAccess: false,
    requiredAbilities: [AbilityName.admin],
  },
  {
    path: "/rfid-management",
    name: "menu.rfid_management",
    icon: Memory,
    component: RfidManagementPage,
    unauthenticatedAccess: false,
    requiredAbilities: [AbilityName.admin, AbilityName.area_manager],
  },
  {
    path: "/citizen-management",
    name: "menu.citizen_management",
    icon: NaturePeople,
    component: CitizenManagementPage,
    unauthenticatedAccess: false,
    requiredAbilities: [AbilityName.manager, AbilityName.admin, AbilityName.area_manager],
  },
  {
    path: "/rfid-groups",
    name: "menu.rfid_groups",
    icon: House,
    component: RfidGroupsPage,
    unauthenticatedAccess: false,
    requiredAbilities: [AbilityName.admin, AbilityName.area_manager],
  },
  {
    path: "/user-management",
    name: "menu.user_management",
    icon: People,
    component: UserManagementPage,
    unauthenticatedAccess: false,
    requiredAbilities: [AbilityName.admin],
  },
  {
    path: "/eda",
    name: "menu.eda",
    icon: Cloud,
    component: EdaPage,
    unauthenticatedAccess: false,
    requiredAbilities: [AbilityName.admin],
  },
  {
    path: "/log",
    name: "menu.log",
    icon: List,
    component: LogPage,
    unauthenticatedAccess: false,
    requiredAbilities: [AbilityName.admin, AbilityName.area_manager],
  },
  {
    path: "/file-templates",
    name: "menu.file_templates",
    icon: Description,
    component: FileTemplatePage,
    unauthenticatedAccess: false,
    requiredAbilities: [AbilityName.manager, AbilityName.admin, AbilityName.area_manager],
  },
  //REDIRECT ROUTES
  {
    redirect: true,
    path: "/",
    pathTo: "/dashboard",
    name: "menu.dashboard",
    unauthenticatedAccess: false,
    requiredAbilities: [AbilityName.manager, AbilityName.admin, AbilityName.area_manager],
  },
  {
    redirect: true,
    path: "/",
    pathTo: "/login",
    name: "menu.login",
    unauthenticatedAccess: true,
  },
]
export default dashRoutes
