import React, { FunctionComponent, useCallback, useContext, useEffect, useMemo, useState } from "react"
import {
  Theme,
  makeStyles,
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  DialogActions,
  Button,
  Typography,
  CircularProgress,
} from "@material-ui/core"
import { useTranslation } from "react-i18next"
import { UserAndPermissionsAssociation } from "../../../../api/graphql/query/permitted-associations-for-user"
import { PermittedAssociationsContext } from "../../../../context/PermittedAssociationsContext"
import { Dropzone } from "../../dropzone/Dropzone"
import { useMutation } from "react-apollo"
import {
  ImportRfidChipsResult,
  ImportRfidChipsValidationResult,
  ImportRfidChipsVariables,
  IMPORT_RFID_CHIPS_MUTATION,
} from "../../../../api/graphql/mutation/import-rfid-chips"
import { UploadedFile } from "../../tour-management/import-tours/UploadedFile"
import CheckCircle from "@material-ui/icons/CheckCircle"
import { COLORS } from "../../../../theme/theme"
import { ImportCheckResult } from "../../tour-management/import-tours/ImportCheckResult"

const useStyles = makeStyles((theme: Theme) => ({
  loadingIndicator: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
  checkIcon: {
    color: COLORS.lime,
    fontSize: 50,
    marginRight: theme.spacing(2),
  },
  content: {
    overflowY: "hidden",
  },
}))

interface IImportRfidChipDialogProps {
  open: boolean
  onClose: () => void
}

export const ImportRfidChipDialog: FunctionComponent<IImportRfidChipDialogProps> = (props) => {
  const { open, onClose } = props
  const classes = useStyles()
  const { t } = useTranslation()
  const [validationResult, setValidationResult] = useState<ImportRfidChipsValidationResult | null>(null)

  const [importMutation, { loading }] = useMutation<ImportRfidChipsResult, ImportRfidChipsVariables>(
    IMPORT_RFID_CHIPS_MUTATION,
    {
      onCompleted: (data) => setValidationResult(data.importRfidChips),
    },
  )

  const { permittedAssociations } = useContext(PermittedAssociationsContext)
  const [uploadedFile, setUploadedFile] = useState<File | undefined>()
  const [selectedAssociation, setSelectedAssociation] = useState<UserAndPermissionsAssociation | undefined>(
    permittedAssociations[0],
  )
  const [selectedTownId, setSelectedTownId] = useState<string>(permittedAssociations[0]?.towns[0]?.id || "")

  useEffect(() => {
    if (open) {
      setSelectedAssociation(permittedAssociations[0])
      setSelectedTownId(permittedAssociations[0]?.towns[0]?.id || "")
    }
  }, [open, setSelectedAssociation, setSelectedTownId, permittedAssociations])

  const handleChangeAssociationId = useCallback(
    (associationId: string) => {
      const association = permittedAssociations.find((association) => association.id === associationId)

      if (association) {
        setSelectedAssociation(association)
        setSelectedTownId(association.towns[0].id)
      }
    },
    [permittedAssociations],
  )

  const customOnClose = useCallback(() => {
    setValidationResult(null)
    setUploadedFile(undefined)
    onClose()
  }, [onClose, setValidationResult])

  const importRfidChips = useCallback(() => {
    importMutation({
      variables: {
        townId: selectedTownId as string,
        file: uploadedFile as File,
        ignoreWarnings: (validationResult?.warnings || []).length > 0,
      },
    })
  }, [importMutation, selectedTownId, uploadedFile, validationResult])

  return (
    <Dialog maxWidth="xl" onClose={customOnClose} open={open}>
      <DialogTitle>{t("rfid_management.import_dialog.title")}</DialogTitle>
      <DialogContent className={classes.content}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <FormControl fullWidth>
              <InputLabel id="inputlabel-association">
                {t("citizen_management.import_citizens_dialog.association_label")}
              </InputLabel>
              <Select
                value={selectedAssociation?.id || ""}
                onChange={(event) => handleChangeAssociationId(event.target.value as string)}
                disabled={false}
              >
                {permittedAssociations.map((association) => (
                  <MenuItem key={association.id} value={association.id}>
                    {association.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth>
              <InputLabel id="inputlabel-town">{t("citizen_management.import_citizens_dialog.town_label")}</InputLabel>
              <Select
                value={selectedTownId || ""}
                onChange={(event) => {
                  setSelectedTownId(event.target.value as string)
                }}
                disabled={false}
              >
                {selectedAssociation?.towns.map((town) => (
                  <MenuItem key={town.id} value={town.id as string}>
                    {town.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} container justify="center">
            {!uploadedFile && (
              <Dropzone
                accept="application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                onFilesDropped={(files: File[]) => {
                  setUploadedFile(files[0])
                }}
              />
            )}
            {uploadedFile && !validationResult?.importDone && (
              <Grid container justify="center">
                <UploadedFile disabled={loading} file={uploadedFile} onRemove={() => setUploadedFile(undefined)} />
                {loading && <CircularProgress className={classes.loadingIndicator} />}
              </Grid>
            )}
          </Grid>
          {!!validationResult?.importDone && (
            <Grid container justify="center" alignItems="center">
              <CheckCircle className={classes.checkIcon} />
              <Typography variant="h5">{t("rfid_management.import_dialog.success")}</Typography>
            </Grid>
          )}
          {((validationResult?.errors || []).length > 0 || (validationResult?.warnings || []).length > 0) &&
            !validationResult?.importDone && (
              <ImportCheckResult
                errors={validationResult?.errors || ([] as any[])}
                warnings={validationResult?.warnings || ([] as any[])}
              />
            )}
        </Grid>
      </DialogContent>
      <DialogActions>
        {!!validationResult?.importDone ? (
          <Button color="primary" variant={"contained"} onClick={customOnClose}>
            {t("rfid_management.import_dialog.close")}
          </Button>
        ) : (
          <>
            <Button color="primary" onClick={customOnClose}>
              {t("general.cancel")}
            </Button>
            <Button
              disabled={loading || !selectedTownId || !uploadedFile || (validationResult?.errors || []).length > 0}
              color="primary"
              variant={"contained"}
              onClick={importRfidChips}
            >
              {t("rfid_management.import_dialog.import")}
            </Button>
          </>
        )}
      </DialogActions>
    </Dialog>
  )
}
