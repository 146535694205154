import { ClassificationType } from "../../../../domain/models/ClassificationType"

export const convertToClassification = (
  value: boolean | null
): ClassificationType => {
  switch (value) {
    case true:
      return ClassificationType.AUTO
    case false:
      return ClassificationType.MANUALLY
    default:
      return ClassificationType.DEFAULT
  }
}
