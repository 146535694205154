import React, { createContext, useContext, useEffect, useRef } from "react"
import { useUrlChangeEffect, useUrlUpdateEffect } from "../custom-hooks/TourFilter"
import { TourFilterContext } from "./TourFilterContext"

export interface ITourUrlContext {}
export const TourUrlContext = createContext<ITourUrlContext>({})

interface ITourUrlProvider extends ITourUrlContext {
  children: JSX.Element[] | JSX.Element | null
}

export const TourUrlContextProvider = (props: ITourUrlProvider) => {
  const { filter, setFilter, setApplyFilter } = useContext(TourFilterContext)
  const isLoaded = useRef(false)

  useUrlChangeEffect(filter, setFilter)
  useUrlUpdateEffect(isLoaded, filter)
  useEffect(() => {
    if (!isLoaded.current) {
      isLoaded.current = true
      setApplyFilter(true)
    }
  }, [setApplyFilter])

  return <TourUrlContext.Provider value={{}}>{props.children}</TourUrlContext.Provider>
}
