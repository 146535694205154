import React, { FunctionComponent, useState, useEffect, useMemo } from "react"
import { TextField, Grid, Tooltip } from "@material-ui/core"
import { useTranslation } from "react-i18next"
import { useLazyQuery } from "react-apollo"
import {
  ExternalTown,
  ExternalTownSearchQueryResult,
  ExternalTownSearchQueryVariables,
  EXTERNAL_TOWN_SEARCH_QUERY,
} from "../../../api/graphql/query/external-town-search"
import { Autocomplete } from "@material-ui/lab"
import { ClipLoader } from "react-spinners"
import { Check, Close } from "@material-ui/icons"
import { ExternalTownType } from "../../../api/graphql/generated/globalTypes"

interface IExternalTownAutocompleteProps {
  selectedExternalTown: ExternalTown | null
  setSelectedExternalTown: (selectedExternalTown: ExternalTown | null) => void
  selectedType: ExternalTownType
}

export const ExternalTownAutocomplete: FunctionComponent<IExternalTownAutocompleteProps> = (props) => {
  const { t } = useTranslation()
  const { selectedExternalTown, setSelectedExternalTown, selectedType } = props
  const [searchString, setSearchString] = useState<string>("")

  const [externalTownSearchQuery, { data, loading }] = useLazyQuery<
    ExternalTownSearchQueryResult,
    ExternalTownSearchQueryVariables
  >(EXTERNAL_TOWN_SEARCH_QUERY)

  const onSearch = (newValue: string) => {
    if (newValue.length >= 3) {
      externalTownSearchQuery({ variables: { searchString: newValue, type: selectedType } })
    }
    setSearchString(newValue)
  }

  const externalTowns = useMemo(() => data?.externalTownSearch || [], [data])

  useEffect(() => {
    if (
      externalTowns[0]?.name?.toLowerCase() === searchString.toLowerCase() &&
      selectedExternalTown !== externalTowns[0]
    ) {
      setSelectedExternalTown(externalTowns[0])
      setSearchString(externalTowns[0].name)
    }
  }, [searchString, externalTowns, setSelectedExternalTown, setSearchString, selectedExternalTown])

  return (
    <Grid container justify="space-between" alignItems="flex-end" alignContent="flex-end" spacing={1}>
      <Grid item xs>
        <Autocomplete<ExternalTown>
          options={externalTowns}
          value={selectedExternalTown}
          getOptionLabel={(option) => option?.name || option?.postalcode || "-"}
          onChange={(event: any, newValue: any) => setSelectedExternalTown(newValue)}
          loading={loading}
          loadingText={t("general.data_loading")}
          noOptionsText={t("general.no_data_for_this_searchterm")}
          renderInput={(params) => (
            <TextField
              value={searchString}
              onChange={(event) => onSearch(event.target.value)}
              name="abilities"
              {...params}
              variant="standard"
              label={t("general.town")}
              margin="none"
              fullWidth
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <React.Fragment>
                    {loading ? <ClipLoader color="inherit" size={15} /> : null}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                ),
              }}
            />
          )}
        />
      </Grid>
      <Grid item>
        {!!selectedExternalTown && (
          <Tooltip
            title={t("association_management.town_dialog.successfully_linked_with_town", {
              townName: selectedExternalTown.name,
            })}
          >
            <Check color="primary" />
          </Tooltip>
        )}
        {!selectedExternalTown && (
          <Tooltip title={t("association_management.town_dialog.no_linked_town_linked")}>
            <Close />
          </Tooltip>
        )}
      </Grid>
    </Grid>
  )
}
