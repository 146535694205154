import { gql } from "apollo-boost"
import { createTowns, createTownsVariables } from "../generated/createTowns"

export const CREATE_TOWNS_MUTATION = gql`
  mutation createTowns($towns: [TownInput]!) {
    createTowns(towns: $towns) {
      name
    }
  }
`

export type CreateTownsResult = createTowns
export type CreateTownsVariables = createTownsVariables
