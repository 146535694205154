import React, { FunctionComponent, Fragment, useState, useEffect } from "react"
import { Theme, makeStyles, Grid, Button, Paper } from "@material-ui/core"
import { useTranslation } from "react-i18next"
import { TopBar } from "../partials/heading/TopBar"
import { useQuery } from "react-apollo"
import { VEHICLES_QUERY, VehiclesResult, Vehicle } from "../../api/graphql/query/vehicles"
import { ManageVehicleDialog } from "../partials/vehicle-management/ManageVehicleDialog"
import lodash from "lodash"
import { VehicleTable } from "../partials/vehicle-management/VehicleTable"
import { RootGrid } from "../partials/layout/RootGrid"

const useStyles = makeStyles((theme: Theme) => ({
  headerButtons: {
    display: "flex",
    flex: 1,
  },
  tableContainer: {
    flex: 1,
    display: "flex",
  },
}))

interface IVehicleManagementPageProps {}

export const VehicleManagementPage: FunctionComponent<IVehicleManagementPageProps> = (props) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const [dialogOpen, setDialogOpen] = useState<boolean>(false)
  const [vehicleToEdit, setVehicleToEdit] = useState<Vehicle | null>(null)
  const { data, loading, error, refetch } = useQuery<VehiclesResult>(VEHICLES_QUERY)

  const vehicles = lodash.get(data, "me.vehicles", []) as Vehicle[]

  if (error) {
    console.error("error.network_error")
  }

  useEffect(() => {
    setDialogOpen(!lodash.isNil(vehicleToEdit))
  }, [vehicleToEdit])

  return (
    <Fragment>
      {/* DIALOGS */}
      {dialogOpen && (
        <ManageVehicleDialog
          vehicle={vehicleToEdit}
          isOpen={dialogOpen}
          onCloseDialog={() => {
            setVehicleToEdit(null)
            setDialogOpen(false)
          }}
          onSuccess={() => {
            setVehicleToEdit(null)
            setDialogOpen(false)
            refetch()
          }}
        />
      )}
      {/* VIEW */}
      <TopBar>{t("vehicle_management.heading")}</TopBar>
      <RootGrid spacing={2} direction="column" item xs>
        <Grid item>
          <Grid container spacing={1} justify="flex-end" alignItems="center" className={classes.headerButtons}>
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                aria-label="Tour erstellen"
                onClick={() => {
                  setDialogOpen(true)
                }}
              >
                {t("vehicle_management.create_vehicle")}
              </Button>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs container>
          <Paper className={classes.tableContainer}>
            <VehicleTable vehicles={vehicles} loading={loading} onEdit={(vehicle) => setVehicleToEdit(vehicle)} />
          </Paper>
        </Grid>
      </RootGrid>
    </Fragment>
  )
}
