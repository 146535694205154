import { QueryLazyOptions } from "@apollo/react-hooks"
import { ApolloError, ApolloQueryResult } from "apollo-client"
import React, { FunctionComponent, createContext, useContext, useEffect, useState, useMemo, useCallback } from "react"
import { OperationVariables, useLazyQuery } from "react-apollo"
import {
  PERMITTED_ASSOCIATIONS_FOR_USER_QUERY,
  PermittedAssociationsForUserResult,
  UserAndPermissionsAssociation,
  UserAndPermissionsTown,
} from "../api/graphql/query/permitted-associations-for-user"
import lodash from "lodash"
import { AuthContext } from "./AuthContext"

interface IPermittedAssociationsContext {
  permittedAssociations: UserAndPermissionsAssociation[]
  permittedTowns: UserAndPermissionsTown[]
  permittedAssociationsLoading: boolean
  permittedAssociationsError?: ApolloError
  permittedAssociationsQuery: (options?: QueryLazyOptions<OperationVariables>) => void
  refetchPermittedAssociations: (
    variables?: OperationVariables | undefined,
  ) => Promise<ApolloQueryResult<PermittedAssociationsForUserResult>>
}

export const PermittedAssociationsContext = createContext<IPermittedAssociationsContext>({
  permittedAssociations: [],
  permittedTowns: [],
  permittedAssociationsLoading: false,
  permittedAssociationsError: undefined,
  permittedAssociationsQuery: (_options?: QueryLazyOptions<OperationVariables>) => {},
  refetchPermittedAssociations: async () => ({} as Promise<ApolloQueryResult<PermittedAssociationsForUserResult>>),
})

interface IPermittedAssociationsContextProviderProps {}

export const PermittedAssociationsContextProvider: FunctionComponent<IPermittedAssociationsContextProviderProps> = (
  props,
) => {
  const { userId } = useContext(AuthContext)
  const [permittedAssociations, setPermittedAssociations] = useState<UserAndPermissionsAssociation[]>([])
  const [permittedAssociationsQuery, { error, loading, refetch }] = useLazyQuery<PermittedAssociationsForUserResult>(
    PERMITTED_ASSOCIATIONS_FOR_USER_QUERY,
    {
      fetchPolicy: "cache-and-network",
      onCompleted: (data) => {
        updatePermittedAssociations(data?.userAndPermissions.permittedAssociations || [])
      },
    },
  )

  useEffect(() => {
    // specifically for when the user logs out and another user logs in, since there aren't any variables in the query we need to do it by hand
    if (userId !== null && !!permittedAssociationsQuery) {
      permittedAssociationsQuery()
    }
  }, [userId, permittedAssociationsQuery])

  const permittedTowns = useMemo(() => {
    return lodash.flatMap(
      permittedAssociations.filter((x) => x),
      (association) => association.towns,
    )
  }, [permittedAssociations])


  const updatePermittedAssociations = useCallback((newValue: UserAndPermissionsAssociation[]) => {
    newValue.forEach((entry) => {
      entry.towns = lodash.sortBy(entry.towns, (town) => town.name)
    })

    setPermittedAssociations(newValue)
  }, [])

  return (
    <PermittedAssociationsContext.Provider
      value={{
        permittedAssociationsQuery,
        permittedAssociations,
        permittedTowns,
        permittedAssociationsLoading: loading,
        permittedAssociationsError: error,
        refetchPermittedAssociations: refetch,
      }}
    >
      {props.children}
    </PermittedAssociationsContext.Provider>
  )
}
