import React, { FunctionComponent, useMemo, useState, useCallback } from "react"
import {
  Theme,
  makeStyles,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  MenuItem,
  Select,
  Button,
  useTheme,
} from "@material-ui/core"
import { useTranslation } from "react-i18next"
import { WidgetTypesAll } from "../../../../domain/models/WidgetModels"
import { WidgetType } from "../../../../api/graphql/generated/globalTypes"
import { useMutation } from "react-apollo"
import {
  UpdateWidgetVariables,
  UpdateWidgetResult,
  UPDATE_WIDGET_MUTATION,
} from "../../../../api/graphql/mutation/update-widget"
import { Widget } from "../../../../api/graphql/query/report-detailed"
import { ClipLoader } from "react-spinners"

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    minWidth: 120,
  },
}))

interface ITransformWidgetGraphDialogProps {
  open: boolean
  onClose: () => void
  onConfirm: (data: UpdateWidgetResult) => void
  widget: Widget
}

export const TransformWidgetGraphDialog: FunctionComponent<ITransformWidgetGraphDialogProps> = (props) => {
  const classes = useStyles()
  const theme = useTheme()
  const { t } = useTranslation()
  const { open, onClose, onConfirm, widget } = props

  const [selectedWidgetType, setSelectedWidgetType] = useState<WidgetType | -1>(-1)

  const customOnClose = () => {
    setSelectedWidgetType(-1)
    onClose()
  }

  const [updateWidgetMutation, { loading }] = useMutation<UpdateWidgetResult, UpdateWidgetVariables>(
    UPDATE_WIDGET_MUTATION,
    {
      onCompleted: (data) => {
        onConfirm(data)
        customOnClose()
      },
    },
  )

  const onTransform = useCallback(() => {
    if (selectedWidgetType !== -1) {
      updateWidgetMutation({
        variables: {
          id: widget.id,
          widgetType: selectedWidgetType,
        },
      })
    }
  }, [selectedWidgetType, widget, updateWidgetMutation])

  const menuItems = useMemo(() => {
    return [
      <MenuItem key={-1} value={-1}>
        {t("general.please_choose")}
      </MenuItem>,
      ...WidgetTypesAll.filter((widgetType) => widgetType.isGraphic && widgetType.type !== widget.widgetType)
        //filter trend chart, wenn townId nicht gesetzt ist
        .filter((widgetType) => (!!widget.filter?.townId ? true : widgetType.type !== WidgetType.chart_trend))
        .map((widgetType, index) => {
          return (
            <MenuItem key={index} value={widgetType.type}>
              {t(widgetType.title)}
            </MenuItem>
          )
        }),
    ]
  }, [t, widget])

  return (
    <Dialog open={open} onClose={customOnClose} maxWidth="sm" fullWidth>
      <DialogTitle>{t("report.transform_graph")}</DialogTitle>
      <DialogContent>
        <Select
          fullWidth
          value={selectedWidgetType}
          onChange={(event) => setSelectedWidgetType(event.target.value as WidgetType)}
        >
          {menuItems}
        </Select>
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={customOnClose} className={classes.button} disabled={loading}>
          {t("general.cancel")}
        </Button>
        <Button variant="contained" color="primary" onClick={onTransform} className={classes.button} disabled={loading}>
          {loading ? <ClipLoader color={theme.palette.common.white} size={16} /> : t("general.confirm")}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
