import React, { FunctionComponent } from "react"
import { EditorItemTextField } from "./EditorItemTextfield"
import { EditorItemPlaceholder } from "./EditorItemPlaceholder"
import { Rating } from "../../../../api/graphql/generated/globalTypes"

export enum EditorItemType {
  PLACEHOLDER = "PLACEHOLDER",
  FREETEXT = "FREETEXT",
}

export interface IEditorItem {
  value: string
  type: EditorItemType
}

export const createEditorItem = (type: EditorItemType, value?: string) => {
  return {
    value: value ? value : "",
    type,
  }
}

interface IEditorItemProps {
  item: IEditorItem
  rating: Rating
  onChange: (value: string) => void
  onRemove: () => void
}

export const EditorItem: FunctionComponent<IEditorItemProps> = (props) => {
  const { item, onRemove, onChange, rating } = props
  switch (item.type) {
    case EditorItemType.FREETEXT:
      return <EditorItemTextField onRemove={onRemove} value={item.value} onChange={(value) => onChange(value)} />
    case EditorItemType.PLACEHOLDER:
      return (
        <EditorItemPlaceholder
          value={item.value}
          rating={rating}
          onChange={(value) => onChange(value)}
          onRemove={onRemove}
        />
      )
    default:
      return null
  }
}
