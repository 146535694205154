import { gql } from "apollo-boost"
import {
  sendPasswordResetMail,
  sendPasswordResetMailVariables
} from "../generated/sendPasswordResetMail"

export const SEND_PASSWORD_RESET_MAIL_MUTATION = gql`
  mutation sendPasswordResetMail($email: String!) {
    sendPasswordResetMail(email: $email) {
      error
      message
    }
  }
`

export type SendPasswordResetMailResult = sendPasswordResetMail
export type SendPasswordResetMailVariables = sendPasswordResetMailVariables
