import React, { useCallback, useState } from "react"
import { makeStyles, Theme, darken, lighten, Grid, Button } from "@material-ui/core"
import {
  VALIDATE_RFID_QUERY,
  ValidateRfidQueryResult,
  ValidateRfidQueryVariables,
} from "../../../api/graphql/query/validate-rfid"
import { useLazyQuery } from "@apollo/react-hooks"
import { useTranslation } from "react-i18next"
import { ValidationSign } from "../rfid-groups/ValidationSign"
import WertstoffTextfield from "../controls/WertstoffTextfield"

const useStyles = makeStyles((theme: Theme) => ({
  addButton: {
    backgroundColor: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: darken(theme.palette.primary.main, 0.3),
    },
    "&:disabled": {
      backgroundColor: lighten(theme.palette.primary.main, 0.5),
    },
  },
  addIcon: {
    color: theme.palette.common.white,
  },
}))

export interface IHousingManagementRfidCollectionNewItemProps {
  addRfidToGroup: (rfid: string) => void
  existingRfids: string[]
  rfidGroupId?: string
}

export const HousingManagementRfidCollectionNewItem: React.FunctionComponent<IHousingManagementRfidCollectionNewItemProps> = (
  props,
) => {
  const { addRfidToGroup, existingRfids } = props
  const classes = useStyles()
  const { t } = useTranslation()
  const [rfid, setRfid] = useState<string>("")
  const [isValid, setIsValid] = useState<boolean>(false)

  const [validateRfid, { data: rfidValidationResult }] = useLazyQuery<
    ValidateRfidQueryResult,
    ValidateRfidQueryVariables
  >(VALIDATE_RFID_QUERY, {
    onCompleted: () => handleRfidValidation(),
    fetchPolicy: "no-cache",
  })

  const handleRfid = useCallback(
    (rfidValue: string) => {
      if (rfidValue.length <= 0) {
        setIsValid(false)
      } else {
        validateRfid({
          variables: {
            rfid: rfidValue,
          },
        })
      }
    },
    [setIsValid, validateRfid],
  )

  const onChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setRfid(event.target.value)
      handleRfid(event.target.value)
    },
    [handleRfid],
  )

  const addRfid = useCallback(() => {
    if (isValid) {
      addRfidToGroup(rfid)
      setRfid("")
      setIsValid(false)
    }
  }, [isValid, addRfidToGroup, setRfid, setIsValid, rfid])

  const handleRfidValidation = useCallback(() => {
    if (rfidValidationResult?.validateRfid && !existingRfids.includes(rfid) && rfid.length > 0) {
      setIsValid(true)
    } else {
      setIsValid(false)
    }
  }, [rfidValidationResult, existingRfids, rfid, setIsValid])

  return (
    <Grid container spacing={1} alignItems={"center"} key={"new-rfid"}>
      <Grid item>
        <ValidationSign isValid={isValid} />
      </Grid>
      <Grid item xs>
        <WertstoffTextfield
          margin="0"
          debounce
          name={t("rfid_groups.dialog.create.rfid_field")}
          value={rfid}
          onChange={onChange}
          variant="outlined"
          size="small"
        />
      </Grid>
      <Grid item>
        <Button
          color="primary"
          variant="contained"
          onClick={addRfid}
          aria-label="add"
          className={classes.addButton}
          disabled={!isValid}
        >
          {t("general.add")}
        </Button>
      </Grid>
    </Grid>
  )
}
