import React, { FunctionComponent, Fragment, useContext, useMemo } from "react"
import { Theme, makeStyles, Grid } from "@material-ui/core"
import { useTranslation } from "react-i18next"
import { convertFromClassification } from "./helpers/convertFromClassification"
import WertstoffDropdown from "../controls/WertstoffDropdown.jsx"
import { TFunction } from "i18next"
import { ClassificationType } from "../../../domain/models/ClassificationType"
import { AnalysisFilterContext } from "../../../context/AnalysisFilterContext"
import { convertToClassification } from "./helpers/convertToClassification"
import { MaterialAnalysisSource } from "../../../api/graphql/generated/globalTypes"

const useStyles = makeStyles((theme: Theme) => ({}))

const getClassificationOptions = (t: TFunction) => [
  {
    id: ClassificationType.DEFAULT,
    name: t("analysis_page.filter_classification_default"),
  },
  {
    id: ClassificationType.AUTO,
    name: t("analysis_page.filter_classification_auto"),
  },
  {
    id: ClassificationType.MANUALLY,
    name: t("analysis_page.filter_classification_manually"),
  },
]

const getSourceOptions = (t: TFunction) =>
  Object.values(MaterialAnalysisSource).map((source) => ({
    id: source,
    name: t(`material_analysis_source.${source}`),
  }))

interface IAnalysisAdminFilterProps {}

export const AnalysisAdminFilter: FunctionComponent<IAnalysisAdminFilterProps> = (props) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const { filterModel } = useContext(AnalysisFilterContext)

  const classificationOptions = useMemo(() => getClassificationOptions(t), [t])
  const sourceOptions = useMemo(() => getSourceOptions(t), [t])

  return (
    <Grid container spacing={2}>
      <Grid item xs={2}>
        <WertstoffDropdown
          allPossible={false}
          name={t("analysis_page.filter.classification")}
          onChange={(event: any) =>
            filterModel.setDisplayOnlyAutomaticAnalysis(convertFromClassification(event.target.value))
          }
          value={convertToClassification(filterModel.displayOnlyAutomaticAnalysis)}
          values={classificationOptions}
          showAdminBadge
          margin="none"
        />
      </Grid>
      <Grid item xs={2}>
        <WertstoffDropdown
          allPossible
          name={t("analysis_page.filter.source")}
          onChange={(event: any) => filterModel.setSource(event.target.value)}
          value={filterModel.source}
          values={sourceOptions}
          showAdminBadge
          margin="none"
        />
      </Grid>
      <Grid item xs={2}></Grid>
      <Grid item xs={2}></Grid>
      <Grid item xs={2}></Grid>
    </Grid>
  )
}
