import { Rating, TextPlaceholderKey } from "../../api/graphql/generated/globalTypes"
import { TextReplacementPlaceholder } from "../../api/graphql/query/replacement_placeholders"
import { ReplacementInfoArray } from "../../api/graphql/query/replacements_for_filter"
import lodash from "lodash"
import { TFunction } from "i18next"

export const TextPlaceholderIndicator = "$"

export function placeholder(
  placeholders: TextReplacementPlaceholder[],
  key: TextPlaceholderKey,
): TextReplacementPlaceholder | undefined {
  return placeholders.find((x) => x.key === key)
}

export function replacementValueFrom(
  replacementArray: ReplacementInfoArray,
  key: TextPlaceholderKey,
): string | undefined {
  return replacementArray.find((x) => x.key === key)?.text ?? undefined
}

export function generateTextFromTemplate(
  template: string | undefined,
  placeholders: TextReplacementPlaceholder[],
  replacementObject: ReplacementInfoArray | undefined,
  markAsBold?: boolean | undefined,
): string {
  if (lodash.isNil(template) || lodash.isNil(replacementObject)) {
    return "??"
  }
  let text = template
  for (let placeholder of placeholders ?? []) {
    // Split mark to insert "\" before "$"
    let mark = placeholder.markForText ?? placeholder.mark
    const replacementValue = replacementValueFrom(replacementObject, placeholder.key) ?? "??"
    let replacement = placeholder.type.prefix + replacementValue + placeholder.type.suffix
    const firstTextPlaceholderIndicator = mark.indexOf(TextPlaceholderIndicator)
    const lastTextPlaceholderIndicator = mark.lastIndexOf(TextPlaceholderIndicator)
    let markPrefix = mark.substring(0, firstTextPlaceholderIndicator !== -1 ? firstTextPlaceholderIndicator + 1 : 1)
    let markSuffix = mark.substring(lastTextPlaceholderIndicator !== -1 ? lastTextPlaceholderIndicator : mark.length)
    mark = mark.replace(markPrefix, "").replace(markSuffix, "")
    let allOccurrences = new RegExp("\\" + markPrefix + mark + "\\" + markSuffix, "g")

    if (markAsBold === true) {
      replacement = `<b>${replacement}</b>`
    }
    text = text.replace(allOccurrences, replacement)
  }
  return text
}

export function dummyReplacementArray(
  placeholders: TextReplacementPlaceholder[],
  rating: Rating,
  t: TFunction,
): ReplacementInfoArray {
  return placeholders.map((placeholder) => {
    switch (placeholder.key) {
      case TextPlaceholderKey.MISSTHROW_PERCENTAGE:
        return { key: placeholder.key, text: t(`text_placeholder.preview.${placeholder.key}.${rating}`) }
      default:
        return { key: placeholder.key, text: t(`text_placeholder.preview.${placeholder.key}`) }
    }
  })
}

export function placeholderNameReplacementArray(
  placeholders: TextReplacementPlaceholder[],
  t: TFunction,
): ReplacementInfoArray {
  return placeholders.map((placeholder) => {
    return { key: placeholder.key, text: t(`text_placeholder.${placeholder.key}`) }
  })
}
