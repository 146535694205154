import React, { FunctionComponent } from "react"
import { Theme, makeStyles, Grid, useTheme } from "@material-ui/core"
import { ClipLoader } from "react-spinners"

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    height: "100%"
  }
}))

interface ISectionLoadingIndicatorProps {}

export const SectionLoadingIndicator: FunctionComponent<
  ISectionLoadingIndicatorProps
> = props => {
  const classes = useStyles()
  const theme = useTheme()
  return (
    <Grid
      container
      justify={"center"}
      alignItems={"center"}
      className={classes.container}
    >
      <Grid item>
        <ClipLoader color={theme.palette.secondary.main} />
      </Grid>
    </Grid>
  )
}
