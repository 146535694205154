import { gql } from "apollo-boost"
import {
  dailyGroupedStatsForAnalysis,
  dailyGroupedStatsForAnalysisVariables,
  dailyGroupedStatsForAnalysis_dailyGroupedStatsForAnalysis,
} from "../generated/dailyGroupedStatsForAnalysis"
import { FRAGMENT_DAILY_GROUPED_STATS_FOR_ANALYSIS } from "../fragments/daily_grouped_stats_for_analysis"

export const DAILY_GROUPED_STATS_FOR_ANALYSIS_QUERY = gql`
  query dailyGroupedStatsForAnalysis(
    $tourType: TourType
    $associationIds: [ID]
    $displayOnlyAutomaticAnalysis: Boolean
    $townIds: [ID!]!
    $tourIds: [ID]
    $firstDate: Date
    $lastDate: Date
    $fractions: [FractionFilter]
    $rating: Rating
    $disableMapping: Boolean
    $simplifyEvaluation: Boolean
  ) {
    dailyGroupedStatsForAnalysis(
      tourType: $tourType
      associationIds: $associationIds
      displayOnlyAutomaticAnalysis: $displayOnlyAutomaticAnalysis
      townIds: $townIds
      tourIds: $tourIds
      firstDate: $firstDate
      lastDate: $lastDate
      fractions: $fractions
      rating: $rating
      disableMapping: $disableMapping
      simplifyEvaluation: $simplifyEvaluation
    ) {
      ...FragmentDailyGroupedStatsForAnalysis
    }
  }
  ${FRAGMENT_DAILY_GROUPED_STATS_FOR_ANALYSIS}
`
export type DailyGroupedStatsForAnalysisQueryResult = dailyGroupedStatsForAnalysis
export type DailyGroupedStatsForAnalysisQueryVariables = dailyGroupedStatsForAnalysisVariables
export type DailyGroupedStatsForAnalysis = dailyGroupedStatsForAnalysis_dailyGroupedStatsForAnalysis
