import React, { useCallback } from "react"
import { useTranslation } from "react-i18next"
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
  Theme,
  Typography,
} from "@material-ui/core"
import { useMutation } from "@apollo/react-hooks"
import { getGraphQLError } from "../../../../utils/get-graphql-error-code"
import { toast } from "react-toastify"
import { RfidGroup } from "../../../../api/graphql/query/rfid-groups-for-town"
import {
  DeleteRfidGroupMutationResult,
  DeleteRfidGroupMutationVariables,
  DELETE_RFID_GROUP_MUTATION,
} from "../../../../api/graphql/mutation/delete-rfid-group"

const useStyles = makeStyles((theme: Theme) => ({
  dialogRoot: {
    minWidth: "360px",
  },
}))

export interface IDeleteRfidGroupDialog {
  rfidGroup: RfidGroup
  onSuccess: () => void
  isOpen: boolean
  onCloseDialog: () => void
}

export const DeleteRfidGroupDialog: React.FunctionComponent<IDeleteRfidGroupDialog> = (props) => {
  const { rfidGroup, onSuccess, isOpen, onCloseDialog } = props
  const classes = useStyles()
  const { t } = useTranslation()

  const [deleteRfidGroupMutation, { loading: deleteRfidGroupMutationLoading }] = useMutation<
    DeleteRfidGroupMutationResult,
    DeleteRfidGroupMutationVariables
  >(DELETE_RFID_GROUP_MUTATION, {
    onCompleted: () => {
      onSuccess()
    },
    onError: (error) => {
      console.log(error)
      toast.error(t(`rfid_groups.delete_error.${getGraphQLError(error).message}`))
    },
  })

  const deleteRfidGroup = useCallback(() => {
    deleteRfidGroupMutation({
      variables: {
        id: rfidGroup._id,
      },
    })
  }, [rfidGroup, deleteRfidGroupMutation])

  return (
    <Dialog open={isOpen} fullWidth={false} className={classes.dialogRoot} classes={{ paper: classes.dialogRoot }}>
      <DialogTitle>{t("rfid_groups.dialog.delete.title")}</DialogTitle>
      <DialogContent>
        <Typography>{t("rfid_groups.dialog.delete.content")}</Typography>
      </DialogContent>
      <DialogActions style={{ paddingBottom: 10, paddingRight: 10 }} key={"cancel"}>
        <Button color="primary" onClick={onCloseDialog}>
          {t("general.no")}
        </Button>
        <Button color="primary" onClick={deleteRfidGroup} disabled={deleteRfidGroupMutationLoading}>
          {t("general.yes")}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
