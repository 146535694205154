import React, { createContext, useState, useEffect, useMemo, useCallback } from "react"
import { TourType } from "../api/graphql/generated/globalTypes"
import {
  DetailedUserAndPermissionsUser,
  DetailedUserAndPermissionsAssociation,
  DetailedUserAndPermissionsTown,
} from "../api/graphql/query/detailed-user-and-permissions"
import {
  ClassifierFraction,
  ClassifierFractionsResult,
  CLASSIFIER_FRACTIONS_QUERY,
} from "../api/graphql/query/classifier-fractions"
import { Bookmark } from "../api/graphql/query/bookmarks-for-user"
import { IAnalysisFilter } from "../domain/models/AnalysisFilterModel"
import { getInitialAnalysisFilterDates, useFilter } from "../custom-hooks/useFilter"
import { useQuery } from "react-apollo"
import lodash from "lodash"
import { ApolloError } from "apollo-boost"
import { AnalyticsVariables } from "../api/graphql/query/analytics"
import { getEmptyings, MergedEmptying } from "../components/partials/analysis-filter/helpers/getEmptyingOptions"
import { useLocation } from "react-router"
import {
  EMPTYINGS_OF_TOWNS_QUERY,
  EmptyingsOfTownsResult,
  EmptyingsOfTownsVariables,
  EmptyingOfTown,
} from "../api/graphql/query/emptyings-for-towns"
import { useUserAndPermissions } from "../components/partials/analysis/context/useUserAndPermissions"
import { rfidStringToArray } from "../utils/rfids"

export interface IAnalysisFilterContext {
  filterModel: IAnalysisFilter
  currentlyAppliedFilterModel: IAnalysisFilter
  user: DetailedUserAndPermissionsUser | null
  permittedAssociations: DetailedUserAndPermissionsAssociation[] | null
  classifierFractions: ClassifierFraction[]
  userAndPermissionsLoading: boolean
  userAndPermissionsError: ApolloError | undefined
  selectedAssociation: DetailedUserAndPermissionsAssociation | null
  selectedTowns: DetailedUserAndPermissionsTown[] | null
  persistedAssociation: DetailedUserAndPermissionsAssociation | null
  persistedTowns: DetailedUserAndPermissionsTown[] | null
  selectedEmptying: EmptyingOfTown | null
  emptyingsLoading: boolean
  emptyings: MergedEmptying[]
  selectedBookmark: Bookmark | null
  applyFilter: boolean
  setApplyFilter: (applyFilter: boolean) => void
  setSelectedBookmark: (bookmark: Bookmark | null) => void
  updateFilterWithBookmark: (bookmark: Bookmark | null) => void
  getAnalyticsQueryVariables: (useApplied: boolean) => AnalyticsVariables
}

export const AnalysisFilterContext = createContext<IAnalysisFilterContext>({
  filterModel: {} as IAnalysisFilter,
  currentlyAppliedFilterModel: {} as IAnalysisFilter,
  user: null,
  permittedAssociations: null,
  classifierFractions: [],
  userAndPermissionsLoading: false,
  userAndPermissionsError: undefined,
  selectedAssociation: null,
  selectedTowns: null,
  persistedAssociation: null,
  persistedTowns: null,
  selectedEmptying: null,
  emptyings: [],
  emptyingsLoading: false,
  selectedBookmark: null,
  applyFilter: true,
  setApplyFilter: (applyFilter: boolean) => {},
  setSelectedBookmark: () => null,
  updateFilterWithBookmark: (bookmark: Bookmark | null) => null,
  getAnalyticsQueryVariables: (useApplied: boolean) => ({} as AnalyticsVariables),
})

const userAndPermissionsRequiredInRoutes = ["/dashboard", "/analysis"]
const fractionsRequiredInRoutes = ["/analysis", "communication-center"]

interface IFilterProvider extends IAnalysisFilterContext {
  children: JSX.Element[] | JSX.Element | null
}

export const AnalysisFilterProvider = (props: IFilterProvider) => {
  const { children } = props
  const currentlyAppliedFilterModel = useFilter()
  const filterModel = useFilter()
  const [selectedBookmark, setSelectedBookmark] = useState<Bookmark | null>(null)
  const [applyFilter, setApplyFilter] = useState<boolean>(true)
  const [persistedAssociation, setPersistedAssociation] = useState<DetailedUserAndPermissionsAssociation | null>(null)
  const [persistedTowns, setPersistedTowns] = useState<DetailedUserAndPermissionsTown[]>([])
  const [persistedEmptying, setPersistedEmptying] = useState<EmptyingOfTown | null>(null)
  const location = useLocation()

  const {
    user,
    permittedAssociations,
    getInitialValues,
    userAndPermissionsLoading,
    userAndPermissionsError,
  } = useUserAndPermissions(userAndPermissionsRequiredInRoutes)

  const { data: fractionsData } = useQuery<ClassifierFractionsResult>(CLASSIFIER_FRACTIONS_QUERY, {
    variables: { tourType: filterModel.tourType },
    skip:
      filterModel.tourType === null ||
      !fractionsRequiredInRoutes.some((route) => location.pathname.startsWith("/analysis")),
  })

  // parameters needed for the view
  const selectedAssociation = useMemo(() => {
    if (filterModel.associationId && !userAndPermissionsLoading && !lodash.isNil(permittedAssociations)) {
      return (
        permittedAssociations.find((permAssociation) => permAssociation.associationId === filterModel.associationId) ||
        null
      )
    } else if (!userAndPermissionsLoading && permittedAssociations && permittedAssociations.length > 0) {
      const { associationId, townId } = getInitialValues()
      filterModel.setAssociationId(associationId)
      filterModel.setTownIds([townId])
    }
    return null
  }, [filterModel, permittedAssociations, getInitialValues, userAndPermissionsLoading])

  const selectedTowns: DetailedUserAndPermissionsTown[] = useMemo(
    () =>
      selectedAssociation ? selectedAssociation.towns.filter((town) => filterModel.townIds?.includes(town.id)) : [],
    [selectedAssociation, filterModel.townIds],
  )

  const { data: emptyingsData, loading: emptyingsLoading } = useQuery<
    EmptyingsOfTownsResult,
    EmptyingsOfTownsVariables
  >(EMPTYINGS_OF_TOWNS_QUERY, {
    skip:
      !userAndPermissionsRequiredInRoutes.some((route) => location.pathname.startsWith(route)) ||
      !filterModel.associationId,
    variables: {
      ids:
        selectedTowns.length > 0
          ? selectedTowns.map((town) => town.id)
          : selectedAssociation?.towns.map((town) => town.id) || [],
    },
  })

  const emptyings = useMemo(() => getEmptyings(!emptyingsLoading ? emptyingsData?.emptyingsOfTowns || [] : []), [
    emptyingsData,
    emptyingsLoading,
  ])

  const updateFilterWithBookmark = useCallback(
    (bookmark: Bookmark | null) => {
      filterModel.setAssociationId(lodash.get(bookmark, "associationId", permittedAssociations[0].associationId))
      filterModel.setTownIds(lodash.get(bookmark, "townIds", null))
      filterModel.setEmptyingId(bookmark?.emptying?.id ?? null)
      filterModel.setDisplayOnlyAutomaticAnalysis(lodash.get(bookmark, "displayOnlyAutomaticAnalysis", null))
      filterModel.setRating(bookmark?.rating ?? null)
      filterModel.setTourType(lodash.get(bookmark, "tourType", TourType.residual))
      filterModel.setSource(lodash.get(bookmark, "source", null))
      filterModel.setFractionType(bookmark?.fractionType ?? null)
      filterModel.setFractionFrom(bookmark?.fractionFrom ? `${bookmark.fractionFrom}` : null)
      filterModel.setFractionTo(bookmark?.fractionTo ? `${bookmark.fractionTo}` : null)
      filterModel.setDateFrom(bookmark?.dateFrom || getInitialAnalysisFilterDates().dateFrom)
      filterModel.setDateUntil(bookmark?.dateUntil || getInitialAnalysisFilterDates().dateUntil)
      console.log(bookmark, (bookmark?.rfids || []).join(","))
      filterModel.setRfids((bookmark?.rfids || []).join(","))
    },
    [filterModel, permittedAssociations],
  )

  const selectedEmptying = useMemo(() => {
    const selected = lodash.isNil(filterModel.emptyingId)
      ? null
      : emptyings.find((emptying) => emptying?.ids.includes(filterModel.emptyingId ?? "-1")) ?? null

    // this is needs to be here, since when a new emptying is added to the calendarweek, but for example a bookmark still has the old emptyingId saved, we will still find it
    if (selected?.id === filterModel.emptyingId) {
      filterModel.setEmptyingId(selected.ids[0])
    }
    return selected
    // eslint-disable-next-line
  }, [filterModel.emptyingId, filterModel.setEmptyingId, emptyings])

  const getAnalyticsQueryVariables = useCallback(
    (useApplied: boolean = false): AnalyticsVariables => {
      const filterModelForQuery = useApplied ? currentlyAppliedFilterModel : filterModel
      const emptyingForQuery = useApplied ? persistedEmptying : selectedEmptying

      let tourIds = null
      tourIds = (emptyingForQuery?.tours || []).map((tour) => tour.id)
      if (lodash.isEmpty(tourIds)) {
        tourIds = null
      }

      return {
        simplifyEvaluation: true,
        tourType: filterModelForQuery.tourType,
        associationIds: filterModelForQuery.associationId ? [filterModelForQuery.associationId]: [],
        displayOnlyAutomaticAnalysis: filterModelForQuery.displayOnlyAutomaticAnalysis,
        townIds: filterModelForQuery.townIds?.filter((townId) => !!townId),
        tourIds: tourIds,
        ratings: filterModelForQuery.rating ? [filterModelForQuery.rating] : null,
        fractions: !filterModelForQuery.fractionType
          ? null
          : [
              {
                fraction: filterModelForQuery.fractionType,
                range: {
                  start: parseFloat(filterModelForQuery.fractionFrom || ""),
                  end: parseFloat(filterModelForQuery.fractionTo || ""),
                },
              },
            ],
        firstDate: filterModelForQuery.dateFrom,
        lastDate: filterModelForQuery.dateUntil,
        source: filterModelForQuery.source,
        rfids: rfidStringToArray(filterModelForQuery.rfids),
      }
    },
    [filterModel, currentlyAppliedFilterModel, persistedEmptying, selectedEmptying],
  )

  useEffect(() => {
    if (applyFilter) {
      setPersistedAssociation(selectedAssociation)
    }
  }, [selectedAssociation, setPersistedAssociation, applyFilter])

  useEffect(() => {
    if (applyFilter) {
      setPersistedTowns(selectedTowns)
    }
  }, [selectedTowns, setPersistedTowns, applyFilter])

  useEffect(() => {
    if (applyFilter) {
      currentlyAppliedFilterModel.setModel(filterModel)
    }
  }, [currentlyAppliedFilterModel.setModel, filterModel, applyFilter])

  useEffect(() => {
    if (applyFilter) {
      setPersistedEmptying(selectedEmptying)
    }
  }, [selectedEmptying, setPersistedEmptying, applyFilter])

  return (
    <AnalysisFilterContext.Provider
      value={{
        filterModel,
        currentlyAppliedFilterModel,
        user,
        permittedAssociations,
        userAndPermissionsLoading,
        userAndPermissionsError,
        emptyings,
        emptyingsLoading,
        selectedAssociation,
        selectedTowns,
        selectedEmptying,
        selectedBookmark,
        setSelectedBookmark,
        applyFilter,
        setApplyFilter,
        persistedAssociation,
        persistedTowns,
        classifierFractions: fractionsData?.classifierFractions || [],
        updateFilterWithBookmark,
        getAnalyticsQueryVariables,
      }}
    >
      {children}
    </AnalysisFilterContext.Provider>
  )
}
