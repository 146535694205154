import { gql } from "apollo-boost"
import { updateMaterialAnalysis, updateMaterialAnalysisVariables } from "../generated/updateMaterialAnalysis"

export const UPDATE_MATERIAL_ANALYSIS_MUTATION = gql`
  mutation updateMaterialAnalysis(
    $id: ID!
    $mappedClassification: MappedClassification
    $invalidity: MaterialAnalysisInvalidityInput
  ) {
    updateMaterialAnalysis(id: $id, mappedClassification: $mappedClassification, invalidity: $invalidity) {
      id
      invalidity {
        isInvalid
        invalidReason
        invalidType
      }
    }
  }
`

export type UpdateMaterialAnalysisResult = updateMaterialAnalysis
export type UpdateMaterialAnalysisVariables = updateMaterialAnalysisVariables
