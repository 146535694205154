import { ClassificationType } from "../../../../domain/models/ClassificationType"

export const convertFromClassification = (
  value: ClassificationType | null
): boolean | null => {
  switch (value) {
    case ClassificationType.AUTO:
      return true
    case ClassificationType.MANUALLY:
      return false
    default:
      return null
  }
}
