import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  makeStyles,
  TextField,
  Theme,
} from "@material-ui/core"
import { useMutation } from "@apollo/react-hooks"
import { RfidGroupRfidCollection } from "../RfidGroupRfidCollection"
import { RfidGroup } from "../../../../api/graphql/query/rfid-groups-for-town"
import {
  UpdateRfidGroupMutationResult,
  UpdateRfidGroupMutationVariables,
  UPDATE_RFID_GROUP_MUTATION,
} from "../../../../api/graphql/mutation/update-rfid-group"

const useStyles = makeStyles((theme: Theme) => ({
  content: {
    width: 600,
  },
  validationField: {
    display: "flex",
    verticalAlign: "bottom",
  },
  dialogActions: {
    padding: theme.spacing(4),
  },
}))

export interface IUpdateRfidGroupDialog {
  onSuccess: () => void
  isOpen: boolean
  onCloseDialog: () => void
  rfidGroup: RfidGroup
}

export const UpdateRfidGroupDialog: React.FunctionComponent<IUpdateRfidGroupDialog> = (props) => {
  const { onSuccess, isOpen, onCloseDialog, rfidGroup } = props
  const classes = useStyles()
  const { t } = useTranslation()
  const [rfids, setRfids] = useState<string[]>(rfidGroup.rfids)

  const [updateRfidGroupMutation, { loading: updateRfidGroupMutationLoading }] = useMutation<
    UpdateRfidGroupMutationResult,
    UpdateRfidGroupMutationVariables
  >(UPDATE_RFID_GROUP_MUTATION, {
    onError: (error) => {
      console.log("error:", error)
    },
    onCompleted: (data) => {
      if (data.updateRfidGroup.address) {
        onSuccess()
      }
    },
  })

  return (
    <Dialog maxWidth="xl" open={isOpen}>
      <DialogTitle>{t("rfid_groups.dialog.update.title")}</DialogTitle>
      <DialogContent className={classes.content}>
        <Grid container spacing={2} alignItems="center" alignContent="center">
          <Grid item xs={12} className={classes.validationField}>
            <TextField
              label={t("rfid_groups.property.street")}
              value={rfidGroup.address.street}
              fullWidth
              disabled={true}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} className={classes.validationField}>
            <TextField
              label={t("rfid_groups.property.streetNumber")}
              value={rfidGroup.address.streetNumber}
              fullWidth
              disabled={true}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} className={classes.validationField}>
            <TextField
              label={t("rfid_groups.property.postalcode")}
              value={rfidGroup.address.postalcode}
              fullWidth
              disabled={true}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} className={classes.validationField}>
            <TextField
              label={t("rfid_groups.property.city")}
              value={rfidGroup.address.city}
              fullWidth
              disabled={true}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12}>
            <RfidGroupRfidCollection isValidGroupAddress={true} rfids={rfids} setRfids={setRfids} />
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions className={classes.dialogActions}>
        <Button color="primary" onClick={onCloseDialog}>
          {t("general.cancel")}
        </Button>
        <Button
          color="primary"
          variant="contained"
          onClick={() => {
            updateRfidGroupMutation({
              variables: {
                id: rfidGroup._id,
                rfids,
              },
            })
          }}
          disabled={updateRfidGroupMutationLoading}
        >
          {t("rfid_groups.dialog.update.update_button")}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
