import React, { FunctionComponent, useMemo } from "react"
import { Theme, makeStyles, Card, Grid, Typography, useTheme } from "@material-ui/core"
import { KeyboardArrowDown, KeyboardArrowRight, KeyboardArrowUp } from "@material-ui/icons"

const directionIndicatorSize = 50

const useStyles = makeStyles((theme: Theme) => ({
  card: {
    height: "100%",
    position: "relative",
    overflow: "visible",
  },
  headingContainer: {
    padding: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
    borderTopLeftRadius: theme.spacing(0.5),
    borderTopRightRadius: theme.spacing(0.5),
  },
  heading: {
    color: "white",
    fontSize: 12,
  },
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    alignContent: "center",
  },
  directionIndicator: {
    position: "absolute",
    width: directionIndicatorSize,
    height: directionIndicatorSize,
    zIndex: 2,
    borderRadius: 90,
    backgroundColor: theme.palette.secondary.main,
    display: "flex",
  },
  indicatorIcon: {
    color: "#ffffff",
  },
}))

const defaultBoardLaneHeight = 70

interface IEdaBoardLaneProps {
  heading?: string
  horizontal?: boolean
  content: JSX.Element
  direction?: "right" | "up" | "down"
  boardLaneHeightPercentage?: number
}

export const EdaBoardLane: FunctionComponent<IEdaBoardLaneProps> = (props) => {
  const { heading, content, direction, boardLaneHeightPercentage = defaultBoardLaneHeight } = props
  const classes = useStyles()
  const theme = useTheme()

  const { directionIndicatorStyles, DirectionIndicatorIcon } = useMemo(() => {
    let _directionIndicatorStyles = {}
    let _Icon = KeyboardArrowRight
    switch (direction) {
      case "right":
        _directionIndicatorStyles = {
          top: `calc(${defaultBoardLaneHeight / 2 + (100 - boardLaneHeightPercentage) / 2}% - ${
            directionIndicatorSize / 2
          }px)`,
          right: -directionIndicatorSize / 2 - theme.spacing(1),
        }
        _Icon = KeyboardArrowRight
        break
      case "up":
        _directionIndicatorStyles = {
          right: `calc(25% - ${directionIndicatorSize / 2}px)`,
          top: -directionIndicatorSize / 2 - theme.spacing(1),
        }
        _Icon = KeyboardArrowUp
        break
      case "down":
        _directionIndicatorStyles = {
          right: `calc(50% - ${directionIndicatorSize / 2}px)`,
          bottom: -directionIndicatorSize / 2 - theme.spacing(1),
        }
        _Icon = KeyboardArrowDown
        break
    }

    return {
      directionIndicatorStyles: _directionIndicatorStyles,
      DirectionIndicatorIcon: _Icon,
    }
  }, [direction, boardLaneHeightPercentage, theme])

  return (
    <Card className={classes.card}>
      <Grid container className={classes.container}>
        <Grid item xs={12} className={classes.headingContainer}>
          <Typography className={classes.heading} align="center">
            {heading}
          </Typography>
        </Grid>
        <Grid item xs={12} className={classes.container}>
          {content}
        </Grid>
      </Grid>
      {direction && (
        <Card className={classes.directionIndicator} style={directionIndicatorStyles}>
          <Grid container justify="center" alignItems="center" alignContent="center">
            <DirectionIndicatorIcon className={classes.indicatorIcon} />
          </Grid>
        </Card>
      )}
    </Card>
  )
}
