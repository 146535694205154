import { gql } from "apollo-boost"
import {
  createAssociation,
  createAssociationVariables
} from "../generated/createAssociation"

export const CREATE_ASSOCIATION_MUTATION = gql`
  mutation createAssociation($association: AssociationInput!) {
    createAssociation(association: $association) {
      name
    }
  }
`

export type CreateAssociationResult = createAssociation
export type CreateAssociationVariables = createAssociationVariables
