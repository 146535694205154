import React, { FunctionComponent, Fragment } from "react"
import { Theme, makeStyles, Card, Grid, Typography } from "@material-ui/core"
import { useTranslation } from "react-i18next"
import WarningIcon from "@material-ui/icons/Warning"
import ErrorIcon from "@material-ui/icons/Error"

import { ImportInfo } from "./ImportInfo"
import { ImportInfoItemType } from "./ImportInfoItemType"
import { ErrorTypes, WarningTypes } from "../../../../api/graphql/generated/globalTypes"

const useStyles = makeStyles((theme: Theme) => ({
  card: {
    margin: 10,
    minHeight: 80,
  },
  container: {
    minHeight: 80,
  },
  icon: {
    margin: 15,
    marginTop: 16,
  },
  warningIcon: {
    color: "#FFC857",
  },
  text: {
    margin: 10,
  },
}))

interface IImportInfoItemProps {
  type: ImportInfoItemType
  item: ImportInfo
}

export const ImportInfoItem: FunctionComponent<IImportInfoItemProps> = (props) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const renderIcon = () => {
    if (props.type === ImportInfoItemType.ERROR) {
      return <ErrorIcon color="primary" className={classes.icon} fontSize="large" />
    } else if (props.type === ImportInfoItemType.WARNING) {
      return <WarningIcon className={`${classes.icon} ${classes.warningIcon}`} fontSize="large" />
    }
  }

  const renderText = () => {
    let text = ""

    // TODO: simplify
    switch (props.item.type.toUpperCase()) {
      case ErrorTypes.HEADER_INCORRECT:
        text = t(`import_info.error_types.${ErrorTypes.HEADER_INCORRECT}`)
        break
      case ErrorTypes.TIME_SPAN_INCORRECT:
        text = t(`import_info.error_types.${ErrorTypes.TIME_SPAN_INCORRECT}`, {
          date: props.item.additionalData[0],
          time1: props.item.additionalData[1],
          time2: props.item.additionalData[2],
          line: props.item.line,
        })
        break
      case ErrorTypes.TIME_FORMAT_INCORRECT:
        text = t(`import_info.error_types.${ErrorTypes.TIME_FORMAT_INCORRECT}`, {
          line: props.item.line,
          date: props.item.additionalData[0],
          time1: props.item.additionalData[1],
          time2: props.item.additionalData[2],
        })
        break
      case ErrorTypes.ASSOCIATION_NOT_FOUND:
        text = t(`import_info.error_types.${ErrorTypes.ASSOCIATION_NOT_FOUND}`, {
          line: props.item.line,
          association: props.item.additionalData[0],
        })
        break
      case ErrorTypes.TOWN_NOT_FOUND:
        text = t(`import_info.error_types.${ErrorTypes.TOWN_NOT_FOUND}`, {
          line: props.item.line,
          town: props.item.additionalData[0],
        })
        break
      case ErrorTypes.DUPLICATE:
        text = t(`import_info.error_types.${ErrorTypes.DUPLICATE}`, {
          line: props.item.line,
        })
        break
      case WarningTypes.OVERLAPPING_ENTRY:
        if (props.item.additionalData[1] == null) {
          text = t(`import_info.warning_types.${WarningTypes.OVERLAPPING_ENTRY}_1`, {
            line: props.item.line,
          })
        } else {
          text = t(`import_info.warning_types.${WarningTypes.OVERLAPPING_ENTRY}_1`, {
            line: props.item.line,
            line2: props.item.additionalData[1],
          })
        }
        break
      case ErrorTypes.FRACTION_NOT_FOUND:
        text = t(`import_info.error_types.${ErrorTypes.FRACTION_NOT_FOUND}`, {
          line: props.item.line,
          fraction: props.item.additionalData[0],
        })
        break
      case ErrorTypes.ENTRY_INCOMPLETE:
        text = t(`import_info.error_types.${ErrorTypes.ENTRY_INCOMPLETE}`, {
          line: props.item.line,
        })
        break
      case ErrorTypes.WASTESCANNER_NOT_FOUND:
        text = t(`import_info.error_types.${ErrorTypes.WASTESCANNER_NOT_FOUND}`, {
          line: props.item.line,
          wastescanner: props.item.additionalData[0],
        })
        break
      case ErrorTypes.RFID_NOT_MATCHING_TOWN:
        text = t(`import_info.error_types.${ErrorTypes.RFID_NOT_MATCHING_TOWN}`, {
          line: props.item.line,
          rfid: props.item.additionalData[0],
        })
        break
      case ErrorTypes.RFID_NOT_FOUND:
        text = t(`import_info.error_types.${ErrorTypes.RFID_NOT_FOUND}`, {
          line: props.item.line,
          rfid: props.item.additionalData[0],
        })
        break
      case WarningTypes.CODE_FOR_RFID_UPDATED:
        text = t(`import_info.warning_types.${WarningTypes.CODE_FOR_RFID_UPDATED}`, {
          line: props.item.line,
          rfid: props.item.additionalData[0],
          originalCode: props.item.additionalData[1],
          newCode: props.item.additionalData[2],
        })
        break
      case ErrorTypes.BOOLEAN_VALUE_INCORRECT:
        text = t(`import_info.error_types.${ErrorTypes.BOOLEAN_VALUE_INCORRECT}`, {
          line: props.item.line,
        })
        break
      case ErrorTypes.PREFERRED_COMMUNICATION_CHANNEL_VALUE_INCORRECT:
        text = t(`import_info.error_types.${ErrorTypes.PREFERRED_COMMUNICATION_CHANNEL_VALUE_INCORRECT}`, {
          line: props.item.line,
        })
        break
      case ErrorTypes.NO_ACCESS_TO_VEHICLE:
        text = t(`import_info.error_types.${ErrorTypes.NO_ACCESS_TO_VEHICLE}`, {
          line: props.item.line,
          wasteScannerId: props.item.additionalData[0],
        })
        break
      case WarningTypes.RFID_ALREADY_EXISTS:
        text = t(`import_info.warning_types.${WarningTypes.RFID_ALREADY_EXISTS}`, {
          line: props.item.line,
        })
        break
      case WarningTypes.RFID_NOT_FOUND:
        text = t(`import_info.warning_types.${WarningTypes.RFID_NOT_FOUND}`, {
          line: props.item.line,
          rfid: props.item.additionalData[0],
        })
        break
      case WarningTypes.RFID_IMPORTED_IN_OTHER_TOWN:
        text = t(`import_info.warning_types.${WarningTypes.RFID_IMPORTED_IN_OTHER_TOWN}`, {
          line: props.item.line,
          rfid: props.item.additionalData[0],
          town: props.item.additionalData[1],
        })
        break
      case WarningTypes.RFID_FOUND_IN_OTHER_TOWN:
        text = t(`import_info.warning_types.${WarningTypes.RFID_FOUND_IN_OTHER_TOWN}`, {
          line: props.item.line,
          rfid: props.item.additionalData[0],
          town: props.item.additionalData[1],
        })
        break
      case WarningTypes.UNKNOWN_RFID_CHIP_TYPE:
        text = t(`import_info.warning_types.${WarningTypes.UNKNOWN_RFID_CHIP_TYPE}`, {
          line: props.item.line,
          type: props.item.additionalData[0],
        })
        break
    }

    return text
  }

  return (
    <Fragment>
      <Card className={classes.card}>
        <Grid container className={classes.container} direction="row" alignItems="center">
          <Grid>{renderIcon()}</Grid>
          <Grid style={{ flex: 1 }}>
            <Typography className={classes.text}>{renderText()}</Typography>
          </Grid>
        </Grid>
      </Card>
    </Fragment>
  )
}
