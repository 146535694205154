import React, { useCallback, useState, useEffect } from "react"
import { useTranslation } from "react-i18next"
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  makeStyles,
  TextField,
  Theme,
} from "@material-ui/core"
import { useLazyQuery, useMutation, useQuery } from "@apollo/react-hooks"
import {
  ValidateRfidGroupAddressQueryResult,
  ValidateRfidGroupAddressQueryVariables,
  VALIDATE_RFID_GROUP_ADDRESS_QUERY,
} from "../../../../api/graphql/query/rfid-group-validate-address"
import { Town } from "../../../../api/graphql/query/towns"
import { RfidGroupRfidCollection } from "../RfidGroupRfidCollection"
import { ValidationSign } from "../ValidationSign"
import {
  CreateRfidGroupMutationResult,
  CreateRfidGroupMutationVariables,
  CREATE_RFID_GROUP_MUTATION,
} from "../../../../api/graphql/mutation/create-rfid-group"
import { AddressInput } from "../../../../api/graphql/generated/globalTypes"

const useStyles = makeStyles((theme: Theme) => ({
  content: {
    width: 600,
  },
  validationField: {
    display: "flex",
    verticalAlign: "bottom",
  },
  dialogActions: {
    padding: theme.spacing(4),
  },
}))

export interface ICreateRfidGroupDialog {
  onSuccess: () => void
  isOpen: boolean
  onCloseDialog: () => void
  town: Town
}

export const CreateRfidGroupDialog: React.FunctionComponent<ICreateRfidGroupDialog> = (props) => {
  const { onSuccess, isOpen, onCloseDialog, town } = props
  const classes = useStyles()
  const { t } = useTranslation()

  const [isValid, setIsValid] = useState<boolean | undefined>(undefined)
  const [address, setAddress] = useState<AddressInput>({ city: "", postalcode: "", street: "", streetNumber: "" })
  const [rfids, setRfids] = useState<string[]>([])

  const isAnyFieldEmpty = useCallback(
    () => !address.street || !address.streetNumber || !address.city || !address.postalcode,
    [address],
  )

  const { data: addressValidationResult } = useQuery<
    ValidateRfidGroupAddressQueryResult,
    ValidateRfidGroupAddressQueryVariables
  >(VALIDATE_RFID_GROUP_ADDRESS_QUERY, {
    onCompleted: () => handleGroupAddressValidation(),
    fetchPolicy: "no-cache",
    skip: isAnyFieldEmpty(),
    variables: {
      townId: town.id,
      address,
    },
  })

  useEffect(() => {
    setRfids([])
    if (isAnyFieldEmpty()) {
      setIsValid(undefined)
    }
  }, [isAnyFieldEmpty])

  const handleGroupAddressValidation = useCallback(() => {
    if (addressValidationResult && addressValidationResult.validateRfidGroupAddress) {
      setIsValid(true)
    } else {
      setIsValid(false)
    }
  }, [addressValidationResult, setIsValid])

  const [createRfidGroupMutation, { loading: createRfidGroupMutationLoading }] = useMutation<
    CreateRfidGroupMutationResult,
    CreateRfidGroupMutationVariables
  >(CREATE_RFID_GROUP_MUTATION, {
    onError: (error) => {
      console.log("error:", error)
    },
    onCompleted: (data) => {
      if (data.createRfidGroup.address) {
        onSuccess()
      }
    },
  })

  return (
    <Dialog maxWidth="xl" open={isOpen}>
      <DialogTitle>{t("rfid_groups.dialog.create.title")}</DialogTitle>
      <DialogContent className={classes.content}>
        <Grid container spacing={2} alignItems="center" alignContent="center">
          <Grid item xs={12} className={classes.validationField}>
            <ValidationSign isValid={isValid !== false && !!address.street} />
            <TextField
              label={t("rfid_groups.property.street")}
              value={address.street}
              fullWidth
              onChange={(event) => {
                setAddress({ ...address, street: event.target.value || "" })
              }}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} className={classes.validationField}>
            <ValidationSign isValid={isValid !== false && !!address.streetNumber} />
            <TextField
              label={t("rfid_groups.property.streetNumber")}
              value={address.streetNumber}
              fullWidth
              onChange={(event) => {
                setAddress({ ...address, streetNumber: event.target.value || "" })
              }}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} className={classes.validationField}>
            <ValidationSign isValid={isValid !== false && !!address.postalcode} />
            <TextField
              label={t("rfid_groups.property.postalcode")}
              value={address.postalcode}
              fullWidth
              onChange={(event) => {
                setAddress({ ...address, postalcode: event.target.value || "" })
              }}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} className={classes.validationField}>
            <ValidationSign isValid={isValid !== false && !!address.city} />
            <TextField
              label={t("rfid_groups.property.city")}
              value={address.city}
              fullWidth
              onChange={(event) => {
                setAddress({ ...address, city: event.target.value || "" })
              }}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12}>
            <RfidGroupRfidCollection isValidGroupAddress={isValid} rfids={rfids} setRfids={setRfids} />
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions className={classes.dialogActions}>
        <Button color="primary" onClick={onCloseDialog}>
          {t("general.cancel")}
        </Button>
        <Button
          color="primary"
          variant="contained"
          onClick={() => {
            createRfidGroupMutation({
              variables: {
                rfids,
                address,
                townId: town.id,
              },
            })
          }}
          disabled={!isValid || createRfidGroupMutationLoading}
        >
          {t("rfid_groups.dialog.create.create_button")}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
