import { LabelSetMaterialType } from "../api/graphql/generated/globalTypes"

export interface FractionInfo {
  id: string
  name: string
  color: string
}

// Temporarily added here; will be removed
export enum MaterialDistributionEnum {
  Battery = "Battery",
  BlownBottle = "BlownBottle",
  GarbageBagOrganic = "GarbageBagOrganic",
  GarbageBagOther = "GarbageBagOther",
  GarbageBagTransp = "GarbageBagTransp",
  GarbageBagYellow = "GarbageBagYellow",
  Glass = "Glass",
  Hazardous = "Hazardous",
  Metal = "Metal",
  Organic = "Organic",
  PET = "PET",
  Paper = "Paper",
  Plastic = "Plastic",
  PlasticOther = "PlasticOther",
  Residual = "Residual",
  Spraycan = "Spraycan",
}

export const basicFractionArray: FractionInfo[] = [
  {
    id: MaterialDistributionEnum.Residual,
    name: "analysis_page.fractions." + MaterialDistributionEnum.Residual,
    color: "rgb(100, 100, 100)",
  },
  {
    id: MaterialDistributionEnum.Organic,
    name: "analysis_page.fractions." + MaterialDistributionEnum.Organic,
    color: "rgb(34, 119, 17)",
  },
  // ----
  {
    id: MaterialDistributionEnum.Battery,
    name: "analysis_page.fractions." + MaterialDistributionEnum.Battery,
    color: "rgb(0, 255, 0)",
  },
  {
    id: MaterialDistributionEnum.BlownBottle,
    name: "analysis_page.fractions." + MaterialDistributionEnum.BlownBottle,
    color: "rgb(255, 255, 153)",
  },
  {
    id: MaterialDistributionEnum.Glass,
    name: "analysis_page.fractions." + MaterialDistributionEnum.Glass,
    color: "rgb(0, 0, 255)",
  },
  {
    id: MaterialDistributionEnum.Hazardous,
    name: "analysis_page.fractions." + MaterialDistributionEnum.Hazardous,
    color: "rgb(255, 0, 255)",
  },
  {
    id: MaterialDistributionEnum.Metal,
    name: "analysis_page.fractions." + MaterialDistributionEnum.Metal,
    color: "rgb(128, 0, 128)",
  },
  {
    id: MaterialDistributionEnum.PET,
    name: "analysis_page.fractions." + MaterialDistributionEnum.PET,
    color: "rgb(0, 204, 153)",
  },
  {
    id: MaterialDistributionEnum.Paper,
    name: "analysis_page.fractions." + MaterialDistributionEnum.Paper,
    color: "rgb(255, 0, 128)",
  },
  {
    id: MaterialDistributionEnum.PlasticOther,
    name: "analysis_page.fractions." + MaterialDistributionEnum.PlasticOther,
    color: "rgb(255, 128, 128)",
  },
  {
    id: MaterialDistributionEnum.Spraycan,
    name: "analysis_page.fractions." + MaterialDistributionEnum.Spraycan,
    color: "rgb(100, 100, 255)",
  },
  {
    id: MaterialDistributionEnum.Plastic,
    name: "analysis_page.fractions." + MaterialDistributionEnum.Plastic,
    color: "rgb(255, 231, 78)",
  },
  // ---
  {
    id: MaterialDistributionEnum.GarbageBagOther,
    name: "analysis_page.fractions." + MaterialDistributionEnum.GarbageBagOther,
    color: "rgb(255, 80, 0)",
  },
  {
    id: MaterialDistributionEnum.GarbageBagTransp,
    name: "analysis_page.fractions." + MaterialDistributionEnum.GarbageBagTransp,
    color: "rgb(153, 153, 102)",
  },
  {
    id: MaterialDistributionEnum.GarbageBagYellow,
    name: "analysis_page.fractions." + MaterialDistributionEnum.GarbageBagYellow,
    color: "rgb(240, 230, 0)",
  },
  {
    id: MaterialDistributionEnum.GarbageBagOrganic,
    name: "analysis_page.fractions." + MaterialDistributionEnum.GarbageBagOrganic,
    color: "rgb(136, 204, 0)",
  },
]

export enum FractionType {
  MAIN = "main",
  MISSTHROW = "missThrow",
  BAGS = "bags",
  IGNORE = "ignore",
}

export interface FractionCategoryInfo {
  id: LabelSetMaterialType
  fractionType: FractionType
  name: string
  color: string
  collapsible: boolean
}

export const basicFractionGroupArray: FractionCategoryInfo[] = [
  {
    id: LabelSetMaterialType.main_fraction,
    fractionType: FractionType.MAIN,
    name: "analysis_page.fractions.not_visible",
    color: "#e4e4e4",
    collapsible: false,
  },
  {
    id: LabelSetMaterialType.missthrow,
    fractionType: FractionType.MISSTHROW,
    name: "analysis_page.fractions.miss_sortings",
    color: "#c2c2c2",
    collapsible: true,
  },
  {
    id: LabelSetMaterialType.bags,
    fractionType: FractionType.BAGS,
    name: "analysis_page.fractions.not_visible",
    color: "#e4e4e4",
    collapsible: false,
  },
]
