import { PieLabelRenderProps } from "recharts"

export const renderPieChartLabel = (input: PieLabelRenderProps) => {
  const { cx, cy, midAngle, innerRadius, outerRadius, percent } = input
  const RADIAN = Math.PI / 180
  const radius = Number(innerRadius) + (Number(outerRadius) - Number(innerRadius)) * 0.65
  const x = Number(cx) + radius * Math.cos(-Number(midAngle) * RADIAN)
  const y = Number(cy) + radius * Math.sin(-Number(midAngle) * RADIAN)
  const percentReadable = Number(percent) * 100
  return (
    <text x={x} y={y} fill="white" textAnchor={"middle"} dominantBaseline="central">
      {percentReadable >= 5.0 ? `${percentReadable.toFixed(0)}%` : ""}
    </text>
  )
}
