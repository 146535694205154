import React, { FunctionComponent, Fragment, useState, useEffect, useCallback } from "react"
import { CitizenTabelForTown } from "./CitizenTableForTown"
import { CitizenForTown } from "../../../api/graphql/query/citizens-for-town"
import lodash from "lodash"
import { ManageCitizenDialog } from "./ManageCitizenDialog"
import { TownExpansionPanel } from "./TownExpansionPanel"
import { UserAndPermissionsTown } from "../../../api/graphql/query/permitted-associations-for-user"
import { Grid, Button, makeStyles, Theme } from "@material-ui/core"
import GridItem from "../layout/GridItem"
import { useTranslation } from "react-i18next"
import { SendCustomMessageToChannelDialog } from "../report/dialogs/SendCustomMessageToChannelDialog"
import { ChannelType } from "../../../api/graphql/generated/globalTypes"

const useStyles = makeStyles((theme: Theme) => ({
  channelButtons: {
    paddingBottom: theme.spacing(2),
  },
}))

interface ITownExpansionPanelWithCitizenTableProps {
  town: UserAndPermissionsTown
}

export const TownExpansionPanelWithCitizenTable: FunctionComponent<ITownExpansionPanelWithCitizenTableProps> = (
  props,
) => {
  const { town } = props
  const { t } = useTranslation()
  const classes = useStyles()
  // we use an extra variable for isOpen, so we can remove the town table from the dom (lazy loading for towns)
  // could also be used for lazy loading the details of an association, but since there arent that many, this isnt necessary
  const [open, setOpen] = useState<boolean>(false)

  const [toggleRefetch, setToggleRefetch] = useState<boolean>(false)
  const [citizenManagementDialogOpen, setCitizenManagementDialogOpen] = useState<boolean>(false)
  const [citizenToEdit, setCitizenToEdit] = useState<CitizenForTown | undefined>(undefined)

  const [toggleSmsDialog, setToggleSmsDialog] = useState<boolean>(false)
  const [toggleEmailDialog, setToggleEmailDialog] = useState<boolean>(false)

  useEffect(() => {
    setCitizenManagementDialogOpen(!lodash.isNil(citizenToEdit))
  }, [citizenToEdit])

  const onCitizenManagementDialogClose = () => {
    setCitizenToEdit(undefined)
    setCitizenManagementDialogOpen(false)
  }

  const onCitizenManagementDialogSuccess = () => {
    onCitizenManagementDialogClose()
    setToggleRefetch(!toggleRefetch)
  }

  const handleSendSMS = useCallback(() => {
    setToggleSmsDialog(!toggleSmsDialog)
  }, [toggleSmsDialog, setToggleSmsDialog])

  const handleSendEmail = useCallback(() => {
    setToggleEmailDialog(!toggleEmailDialog)
  }, [toggleEmailDialog, setToggleEmailDialog])

  const closeSMSDialog = useCallback(() => {
    setToggleSmsDialog(false)
  }, [setToggleSmsDialog])

  const closeEmailDialog = useCallback(() => {
    setToggleEmailDialog(false)
  }, [setToggleEmailDialog])

  return (
    <Fragment>
      {/* DIALOGS */}
      {citizenManagementDialogOpen && !!citizenToEdit && (
        <ManageCitizenDialog
          onCloseDialog={onCitizenManagementDialogClose}
          onSuccess={onCitizenManagementDialogSuccess}
          isOpen={citizenManagementDialogOpen}
          citizen={citizenToEdit}
        />
      )}
      {toggleSmsDialog && (
        <SendCustomMessageToChannelDialog
          open={toggleSmsDialog}
          onClose={closeSMSDialog}
          channelType={ChannelType.sms}
          townId={town.id}
          associationId={town.associationId}
        />
      )}
      {toggleEmailDialog && (
        <SendCustomMessageToChannelDialog
          open={toggleEmailDialog}
          onClose={closeEmailDialog}
          channelType={ChannelType.email}
          townId={town.id}
        />
      )}
      {/* VIEW */}
      <TownExpansionPanel town={town} open={open} toggle={() => setOpen(!open)}>
        <Grid container justify="flex-end" direction="row" className={classes.channelButtons}>
          <GridItem>
            <Button
              variant="contained"
              color="primary"
              aria-label="SMS"
              onClick={() =>
                lodash.isFunction(handleSendSMS) ? handleSendSMS() : console.log("handleSendSMS not sepecified")
              }
            >
              {t("sms_dialog.heading")}
            </Button>
          </GridItem>
          <GridItem>
            <Button
              variant="contained"
              color="primary"
              aria-label="Senden"
              onClick={() =>
                lodash.isFunction(handleSendEmail) ? handleSendEmail() : console.log("handleSendEmail not sepecified")
              }
            >
              {t("email_dialog.heading")}
            </Button>
          </GridItem>
        </Grid>
        <CitizenTabelForTown town={town} setCitizenToEdit={setCitizenToEdit} toggleRefetch={toggleRefetch} />
      </TownExpansionPanel>
    </Fragment>
  )
}
