import { gql } from "apollo-boost"
import { updateRfidGroup, updateRfidGroupVariables } from "../generated/updateRfidGroup"

export const UPDATE_RFID_GROUP_MUTATION = gql`
  mutation updateRfidGroup($id: ID!, $rfids: [String!]) {
    updateRfidGroup(id: $id, rfids: $rfids) {
      address {
        street
        streetNumber
        city
        postalcode
      }
      rfids
    }
  }
`

export type UpdateRfidGroupMutationResult = updateRfidGroup
export type UpdateRfidGroupMutationVariables = updateRfidGroupVariables
