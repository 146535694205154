import React, { FunctionComponent } from "react"
import { Theme, makeStyles, Grid } from "@material-ui/core"
import { GridProps } from "@material-ui/core/Grid"

const useStyles = makeStyles((theme: Theme) => ({
  rootGrid: {
    padding: theme.spacing(1)
  },
  rootGridRoot: {
    width: "100%",
    margin: 0
  }
}))

interface IRootGridProps extends GridProps {}

export const RootGrid: FunctionComponent<IRootGridProps> = props => {
  const classes = useStyles()
  const { children, className, ...rest } = props
  const cn = className ? `${classes.rootGrid} ${className}` : classes.rootGrid
  return (
    <Grid
      container
      classes={{ root: classes.rootGridRoot }}
      className={cn}
      justify={"center"}
      alignItems={"stretch"}
      {...rest}
    >
      {children}
    </Grid>
  )
}
