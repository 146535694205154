import React, { FunctionComponent, Fragment, useCallback, useState } from "react"
import { useDropzone } from "react-dropzone"
import {
  Theme,
  makeStyles,
  Dialog,
  DialogTitle,
  DialogActions,
  Button,
  Grid,
  CircularProgress,
  Typography,
} from "@material-ui/core"
import { useApolloClient } from "react-apollo"
import { toast } from "react-toastify"
import CheckCircleIcon from "@material-ui/icons/CheckCircle"
import BackupIcon from "@material-ui/icons/Backup"
import ErrorIcon from "@material-ui/icons/Error"
import { useTranslation } from "react-i18next"
import { UploadedFile } from "./UploadedFile"
import { ImportCheckResult } from "./ImportCheckResult"
import { ImportInfo } from "./ImportInfo"
import { VALIDATE_TOURS_QUERY } from "../../../../api/graphql/query/validate-tours"
import { IMPORT_TOURS_MUTATION } from "../../../../api/graphql/mutation/import-tours"

const useStyles = makeStyles((theme: Theme) => ({
  dialog: {
    maxWidth: 1000,
  },
  content: {
    width: 800,
    minHeight: 200,
  },
  info: {
    marginRight: theme.spacing(3),
    marginBottom: theme.spacing(1),
    marginLeft: theme.spacing(3),
  },
  dropZone: {
    height: 200,
    width: 760,
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    borderWith: 0.5,
    borderStyle: "dashed",
    borderColor: theme.palette.secondary.light,
  },
  icon: {
    color: theme.palette.secondary.light,
    fontSize: 45,
    marginBottom: 10,
  },
  circularProgress: {
    marginTop: 30,
  },
  text: {
    fontSize: "20px",
    fontWeight: 500,
    fontStyle: "normal",
    fontStretch: "normal",
    linHeight: "1.2",
    letterSpacing: "normal",
  },
  checkIcon: {
    color: "#4caf50",
    fontSize: 50,
    marginRight: 20,
  },
  errorIcon: {
    fontSize: 50,
    marginRight: 20,
  },
}))

interface IImportToursDialogProps {
  open: boolean
  onClose: () => void
}

export const ImportToursDialog: FunctionComponent<IImportToursDialogProps> = (props) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const client = useApolloClient()

  const [successImportedTours, setSuccessImportedTours] = useState<string | undefined>(undefined)
  const [importError, setImportError] = useState<boolean>(false)
  const [uploadDisabled, setUploadDisabled] = useState<boolean>(true)
  const [uploadedFile, setUploadedFile] = useState<any | undefined>()
  const [importing, setImporting] = useState<boolean>(false)
  const [errors, setErrors] = useState<ImportInfo[] | undefined>(undefined)
  const [warnings, setWarnings] = useState<ImportInfo[] | undefined>(undefined)

  const onDrop = useCallback((acceptedFiles) => {
    setUploadedFile(acceptedFiles[0])
    setUploadDisabled(false)
  }, [])

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: "text/csv,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    maxSize: 2097152,
    onDropRejected: () => {
      toast.error(t("request_form.upload.error_file_selector"))
    },
  })

  const doCleanup = () => {
    setImporting(false)
    setUploadDisabled(true)
    setUploadedFile(undefined)
    setWarnings(undefined)
    setErrors(undefined)
    setSuccessImportedTours(undefined)
    setImportError(false)
  }

  const onFileRemoved = () => {
    doCleanup()
  }

  const tryImport = async () => {
    try {
      const { data } = await client.mutate({
        mutation: IMPORT_TOURS_MUTATION,
        variables: { excelFile: uploadedFile },
      })
      setSuccessImportedTours(data.importTours.message)
    } catch (error) {
      setImportError(true)
    }
  }

  const doImportNevertheless = async () => {
    setSuccessImportedTours(undefined)
    setImportError(false)
    setWarnings(undefined)
    setErrors(undefined)
    setImporting(true)

    await tryImport()
  }

  const doImport = async () => {
    setUploadDisabled(true)
    setImporting(true)
    setSuccessImportedTours(undefined)
    setImportError(false)

    const { data } = await client.query({
      query: VALIDATE_TOURS_QUERY,
      variables: { excelFile: uploadedFile },
      fetchPolicy: "network-only",
    })

    if (
      data &&
      data.validateTours &&
      data.validateTours.errors.length === 0 &&
      data.validateTours.warnings.length === 0
    ) {
      await tryImport()
    } else {
      setWarnings(data.validateTours.warnings)
      setErrors(data.validateTours.errors)
    }

    setImporting(false)
    setUploadDisabled(data.validateTours.errors.length > 0)
  }

  const renderDropZoneText = () => {
    return !isDragActive ? (
      <Grid container justify="center" alignItems="center" direction="column">
        <BackupIcon className={classes.icon} />
        <Typography className={classes.text}>{t("import_tours_dialog.move_files")}</Typography>
        <Typography className={classes.text}>{t("import_tours_dialog.select_files")}</Typography>
      </Grid>
    ) : (
      <Typography className={classes.text}>{t("import_tours_dialog.drop_files")}</Typography>
    )
  }

  const renderDropZone = () => {
    return (
      // @ts-ignore
      <Grid container justify="center" alignItems="center" className={classes.dropZone} {...getRootProps()}>
        {/* @ts-ignore */}
        <input
          {...getInputProps()}
          accept="text/csv,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        />

        {renderDropZoneText()}
      </Grid>
    )
  }

  const renderSelectedFile = () => {
    if (!uploadedFile) {
      return null
    }

    return (
      <Grid container justify="center">
        <UploadedFile
          disabled={importing}
          file={uploadedFile}
          onRemove={onFileRemoved}
          reupload={(errors && errors.length > 0) || (warnings && warnings.length > 0)}
        />
        {importing && <CircularProgress className={classes.circularProgress} />}
        {(warnings || errors) && renderImportCheckResult()}
      </Grid>
    )
  }

  const renderSuccess = () => {
    return (
      <Grid className={classes.content} container justify="center" alignItems="center">
        <CheckCircleIcon className={classes.checkIcon} />
        <Typography variant="h5">{t("import_tours_dialog.success", { number: successImportedTours })}</Typography>
      </Grid>
    )
  }

  const renderError = () => {
    return (
      <Grid className={classes.content} container justify="center" alignItems="center">
        <ErrorIcon className={classes.errorIcon} color="primary" />
        <Typography variant="h5">{t("import_tours_dialog.error", { number: 20 })}</Typography>
      </Grid>
    )
  }

  const renderImportCheckResult = () => {
    return <ImportCheckResult errors={errors} warnings={warnings} />
  }

  return (
    <Fragment>
      <Dialog
        onExit={doCleanup}
        maxWidth="xl"
        onClose={props.onClose}
        aria-labelledby="simple-dialog-title"
        open={props.open}
      >
        <DialogTitle id="simple-dialog-title">
          {successImportedTours ? t("import_tours_dialog.import_success_heading") : t("tour_page.import_tours")}
        </DialogTitle>

        {!successImportedTours && (
          <Grid className={classes.content}>
            <Grid>
              <Typography className={classes.info}>{t("import_tours_dialog.import_info")}</Typography>
            </Grid>
            {uploadedFile ? renderSelectedFile() : renderDropZone()}
          </Grid>
        )}

        {successImportedTours && renderSuccess()}
        {importError && renderError()}
        <DialogActions key={"cancel"}>
          <Button color="primary" onClick={props.onClose}>
            {successImportedTours ? t("general.close") : t("general.cancel")}
          </Button>
          {warnings && warnings.length > 0 ? (
            <Fragment>
              {!successImportedTours && (
                <Button
                  color="primary"
                  variant={"contained"}
                  onClick={doImportNevertheless}
                  disabled={errors && errors.length > 0}
                >
                  {t("import_tours_dialog.import_nevertheless")}
                </Button>
              )}
            </Fragment>
          ) : (
            <Fragment>
              {!successImportedTours && (
                <Button disabled={uploadDisabled} color="primary" variant={"contained"} onClick={doImport}>
                  {t("import_tours_dialog.import")}
                </Button>
              )}
            </Fragment>
          )}
        </DialogActions>
      </Dialog>
    </Fragment>
  )
}
