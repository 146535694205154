import { gql } from "apollo-boost"
import {
  unassignableAddressesXLSXForTown,
  unassignableAddressesXLSXForTownVariables,
  unassignableAddressesXLSXForTown_unassignableAddressesXLSXForTown,
} from "../generated/unassignableAddressesXLSXForTown"

export const UNASSIGNABLE_ADDRESSES_XLSX_FOR_TOWN_QUERY = gql`
  query unassignableAddressesXLSXForTown($townId: ID) {
    unassignableAddressesXLSXForTown(townId: $townId) {
      base64
    }
  }
`
export type unassignableAddressesXLSXResult = unassignableAddressesXLSXForTown
export type unassignableAddressXLSX = unassignableAddressesXLSXForTown_unassignableAddressesXLSXForTown
export type unassignableAddressesXLSXVariables = unassignableAddressesXLSXForTownVariables
