import React, { FunctionComponent, Fragment, useMemo, useCallback, useEffect } from "react"
import { Theme, makeStyles, Grid, Typography, Chip } from "@material-ui/core"
import { useTranslation } from "react-i18next"
import lodash from "lodash"
import { MaterialAnalysisWithDocuments } from "../../../api/graphql/query/analyticsDocuments"
import { getRatingInfo } from "../../../domain/helper/CalcRating"
import { DocumentType, Rating } from "../../../api/graphql/generated/globalTypes"
import moment from "moment"

export const WERTSTOFF_PICTURE_GALLERY_ITEM_HEIGHT = 103
export const WERTSTOFF_PICTURE_GALLERY_ITEM_WIDTH = 180

const useStyles = makeStyles((theme: Theme) => ({
  imageWrapper: {
    width: WERTSTOFF_PICTURE_GALLERY_ITEM_WIDTH,
    height: WERTSTOFF_PICTURE_GALLERY_ITEM_HEIGHT,
    padding: 3,
    borderRadius: 4,
    cursor: "pointer",
    objectFit: "cover",
    maxWidth: "200px",
    position: "relative",
  },
  image: {
    width: WERTSTOFF_PICTURE_GALLERY_ITEM_WIDTH,
    height: WERTSTOFF_PICTURE_GALLERY_ITEM_HEIGHT,
    cursor: "pointer",
    objectFit: "cover",
    maxWidth: "200px",
    borderRadius: 2,
  },
  dateWrapper: {
    position: "absolute",
    left: theme.spacing(1) + 23,
    bottom: theme.spacing(0.75),
    height: 22,
    width: "auto",
    borderRadius: 90,
  },
  date: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    fontSize: 12,
    borderRadius: 90,
    color: "white",
  },
  imageNumberWrapper: {
    position: "absolute",
    bottom: theme.spacing(0.75),
    left: theme.spacing(0.75),
    borderRadius: 90,
    width: 22,
    height: 22,
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  imageNumber: {
    fontWeight: "bold",
    paddingTop: 1,
    color: theme.palette.primary.contrastText,
  },
}))

interface IWertstoffPictureGalleryItemProps {
  analysis: MaterialAnalysisWithDocuments
  onClick: (analysis: MaterialAnalysisWithDocuments) => void
}

export const WertstoffPictureGalleryItem: FunctionComponent<IWertstoffPictureGalleryItemProps> = (props) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const { analysis, onClick } = props

  const trashPicture = useMemo(
    () => lodash.find(analysis.documents, (d) => d?.documentType === DocumentType.trash_image_low_res),
    [analysis],
  )
  const rating = useMemo(() => analysis.calculatedDetailed?.rating, [analysis])
  const ratingInfo = useMemo(() => getRatingInfo(rating), [rating])
  const backgroundColor = useMemo(
    () => (analysis.invalidity.isInvalid ? getRatingInfo(Rating.Unknown).color : ratingInfo.color),
    [analysis, ratingInfo],
  )

  const customOnClick = useCallback(() => onClick(analysis), [onClick, analysis])

  const imageNumberFontSize = useMemo(() => {
    if (analysis.imageNumber < 100) {
      return 12
    }
    if (analysis.imageNumber < 1000) {
      return 10.5
    }
    return 8.5
  }, [analysis.imageNumber])

  return (
    <Grid item key={analysis.id}>
      <div className={classes.imageWrapper} style={{ backgroundColor }}>
        {!!trashPicture && (
          <img
            key={analysis.id}
            src={trashPicture?.url}
            alt={trashPicture?.title}
            className={classes.image}
            onClick={customOnClick}
          />
        )}
        {!trashPicture && <div className={classes.image} onClick={customOnClick} />}
        <Grid
          onClick={customOnClick}
          container
          justify="center"
          alignItems="center"
          style={{ backgroundColor }}
          className={classes.imageNumberWrapper}
        >
          <Typography className={classes.imageNumber} style={{ fontSize: imageNumberFontSize }}>
            {analysis.imageNumber}
          </Typography>
        </Grid>
        <Grid
          onClick={customOnClick}
          container
          justify="center"
          alignItems="center"
          style={{ backgroundColor }}
          className={classes.dateWrapper}
        >
          <Typography style={{ backgroundColor }} className={classes.date}>
            {moment(analysis.metadata.recordedAt).format("DD.MM.YYYY")}
          </Typography>
        </Grid>
      </div>
    </Grid>
  )
}
