import React, { FunctionComponent } from "react"
import {
  Theme,
  makeStyles,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction,
  Grid,
  Typography,
} from "@material-ui/core"
import { Brightness1 } from "@material-ui/icons"
import { useTranslation } from "react-i18next"
import { getRatingInfo, sortRating } from "../../../../domain/helper/CalcRating"
import { IWasteQualityDictionary } from "./AnalysisWasteQualityPieGraphSection"
import { Rating } from "../../../../api/graphql/generated/globalTypes"

const useStyles = makeStyles((theme: Theme) => ({
  list: {
    width: "100%",
    maxWidth: 360,
  },
  subText: {
    paddingTop: theme.spacing(2),
    paddingLeft: theme.spacing(2),
  },
}))

interface IWasteQualityListProps {
  dictionary: IWasteQualityDictionary
}

export const WasteQualityList: FunctionComponent<IWasteQualityListProps> = (props) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const { dictionary } = props

  return (
    <Grid>
      <List className={classes.list}>
        {Object.keys(dictionary)
          .sort(sortRating)
          .map((key) => {
            if (key !== Rating.Unknown) {
              const { color, rating } = getRatingInfo(key)
              return (
                <ListItem>
                  <ListItemIcon>
                    <Brightness1 style={{ color }} />
                  </ListItemIcon>
                  <ListItemText>{t(rating)}</ListItemText>
                  <ListItemSecondaryAction>
                    ({dictionary[key].analysis.length}) {((dictionary[key].percentage ?? 0) * 100).toFixed(0)}%
                  </ListItemSecondaryAction>
                </ListItem>
              )
            }
          })}
      </List>
      {dictionary[Rating.Unknown].analysis.length > 0 && (
        <Typography variant={"body1"} className={classes.subText}>
          {t("analysis_page.waste_quality.num_of_invalid", { count: dictionary[Rating.Unknown].analysis.length })}
        </Typography>
      )}
    </Grid>
  )
}
