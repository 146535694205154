import React, { FunctionComponent, useContext } from "react"
import { Paper, Grid } from "@material-ui/core"
import { StatisticsDiagram } from "../../partials/statistics/StatisticsDiagram"
import { StatisticsPictureGallery } from "../../partials/statistics/StatisticsPictureGallery"
import { StatisticsOverview } from "../../partials/statistics/StatisticsOverview"
import { RootGrid } from "../../partials/layout/RootGrid"
import { StatisticsFilterContext } from "../../../context/StatisticsFilterContext"

interface IStatisticsPageContentProps {}

export const StatisticsPageContent: FunctionComponent<IStatisticsPageContentProps> = (props) => {
  const { statistics } = useContext(StatisticsFilterContext)
  const materialAnalysis = statistics?.materialAnalysis || []
  const daily = statistics?.daily || []
  return (
    <RootGrid spacing={2}>
      <Grid item xs={12}>
        <Paper>
          <StatisticsOverview />
        </Paper>
      </Grid>
      {daily.length > 0 && (
        <Grid item xs={12}>
          <Paper>
            <StatisticsDiagram />
          </Paper>
        </Grid>
      )}
      {materialAnalysis.length > 0 && (
        <Grid item xs={12}>
          <Paper>
            <StatisticsPictureGallery />
          </Paper>
        </Grid>
      )}
    </RootGrid>
  )
}

