import React, { Fragment, FunctionComponent, useCallback, useContext, useState } from "react"
import { useTranslation } from "react-i18next"
import { Dialog, Grid, IconButton, makeStyles, Paper, Theme, Tooltip, Typography, useTheme } from "@material-ui/core"
import { StatisticsFilterContext } from "../../../context/StatisticsFilterContext"
import lodash from "lodash"
import { Statistics_statistics_materialAnalysis } from "../../../api/graphql/generated/Statistics"
import { DocumentType, InvalidityType } from "../../../api/graphql/generated/globalTypes"
import { WertstoffAnalysisDetail } from "../analysis-data/detail/WertstoffAnalysisDetail"
import { FileCopy } from "@material-ui/icons"
import { SectionLoadingIndicator } from "../dashboard/SectionLoadingIndicator"
import { WertstoffDetailContextProvider } from "../analysis-data/detail/WertstoffDetailContext"

interface IStatisticsPictureGalleryProps {}

// @Styles
const useStyles = makeStyles((theme: Theme) => ({
  invalidContainer: {
    width: 218,
    padding: 6,
  },
  textContainer: {
    padding: "0px 6px 0px  6px",
  },
  textEllipsis: {
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
  },
  imageWrapper: {
    width: 200,
    height: "114px",
    padding: 3,
    borderRadius: 4,
  },
  image: {
    width: 200,
    height: "114px",
    cursor: "pointer",
    objectFit: "cover",
    maxWidth: "200px",
    borderRadius: 2,
  },
  gridList: {
    flexWrap: "nowrap",
    padding: theme.spacing(1),
    // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
    transform: "translateZ(0)",
    overflowX: "scroll",
    height: "100%",
  },
  gridListTitle: {
    width: "28%",
  },
  pictureGallery: {
    overflow: "hidden",
    width: "100%",
  },
  container: {
    paddingTop: theme.spacing(2),
    paddingLeft: theme.spacing(2),
  },
}))

export const StatisticsPictureGallery: FunctionComponent<IStatisticsPictureGalleryProps> = (props) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const classes = useStyles()
  const { statistics, contextLoading, contextError, contextRefetch } = useContext(StatisticsFilterContext)
  const materialAnalysis = statistics?.materialAnalysis
  const [selectedAnalysis, setSelectedAnalysis] = useState<Statistics_statistics_materialAnalysis | null>()

  const onAnalysisDeleted = useCallback(() => {
    contextRefetch()
    setSelectedAnalysis(null)
  }, [contextRefetch, setSelectedAnalysis])

  return (
    <Fragment>
      <Grid container spacing={0} direction="column">
        <Grid item>
          <Typography variant={"h6"} className={classes.container}>
            {t("statistics.invalid_images_title")}
          </Typography>
        </Grid>
        <Grid item className={classes.pictureGallery}>
          <Grid container direction="row" className={classes.gridList} spacing={1}>
            {contextLoading && <SectionLoadingIndicator />}
            {!contextLoading &&
              !contextError &&
              materialAnalysis
                ?.map((analysis) => {
                  let trashPicture = lodash.find(
                    analysis.documents,
                    (d) => d?.documentType === DocumentType.trash_image,
                  )
                  const filePath = analysis?.metadata.file
                  let invalidText = analysis.invalidity.invalidReason

                  if (analysis.invalidity.invalidType === InvalidityType.GENERAL) {
                    invalidText = t(`material_analysis.invalidity_type.${analysis.invalidity.invalidType}`, {
                      reason: analysis.invalidity.invalidReason?.split("0;")[1] || "",
                    })
                  } else if (analysis.invalidity.invalidType !== InvalidityType.OTHER) {
                    invalidText = t(`material_analysis.invalidity_type.${analysis.invalidity.invalidType}`)
                  }

                  return (
                    <Grid item key={analysis.id}>
                      <Paper className={classes.invalidContainer}>
                        <Grid
                          container
                          direction={"column"}
                          justify={"center"}
                          alignItems={"center"}
                          style={{ width: "100%" }}
                        >
                          {filePath && (
                            <Grid item>
                              <Grid container direction={"row"} alignItems={"center"} className={classes.textContainer}>
                                <Grid item style={{ width: "176px" }}>
                                  <Tooltip title={filePath}>
                                    <Typography variant={"body2"} className={classes.textEllipsis}>
                                      {filePath}
                                    </Typography>
                                  </Tooltip>
                                </Grid>
                                <Grid item>
                                  <IconButton
                                    size={"small"}
                                    color="primary"
                                    onClick={() => {
                                      navigator.clipboard.writeText(filePath).then()
                                    }}
                                  >
                                    <FileCopy />
                                  </IconButton>
                                </Grid>
                              </Grid>
                            </Grid>
                          )}
                          <Grid item>
                            <div className={classes.imageWrapper} style={{ background: theme.palette.primary.main }}>
                              <img
                                key={analysis.id}
                                src={trashPicture?.url}
                                alt={trashPicture?.title}
                                className={classes.image}
                                onClick={(_) => setSelectedAnalysis(analysis)}
                              />
                            </div>
                          </Grid>
                          <Grid item style={{ width: "100%" }}>
                            <Typography variant={"body2"} color={"primary"} className={classes.textContainer}>
                              {invalidText}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Paper>
                    </Grid>
                  )
                })
                .filter((x) => x)}
          </Grid>
        </Grid>
      </Grid>
      <Dialog
        open={!!selectedAnalysis}
        aria-labelledby="form-dialog-title"
        disableBackdropClick={false}
        onClose={() => setSelectedAnalysis(null)}
        onBackdropClick={() => setSelectedAnalysis(null)}
        fullWidth={true}
        maxWidth={"md"}
      >
        {!!selectedAnalysis && (
          <WertstoffDetailContextProvider
            analysisId={selectedAnalysis.id}
            displayOnlyAutomaticAnalysis={null}
            onAnalysisDeleted={onAnalysisDeleted}
          >
            <WertstoffAnalysisDetail />
          </WertstoffDetailContextProvider>
        )}
      </Dialog>
    </Fragment>
  )
}
