import { useCallback, useEffect, useState } from "react"
import { useLazyQuery } from "react-apollo"
import {
  AnalyticsDocumentsQueryResult,
  AnalyticsDocumentsQueryVariables,
  ANALYTICS_DOCUMENTS_QUERY,
} from "../../../../api/graphql/query/analyticsDocuments"
import { MaterialAnalysisForMap } from "../../../../api/graphql/query/material-analysis-for-map"
import { ILazyMaterialAnalysisWithDocuments } from "../../analysis/AnalysisPictureSection"

const mapBasicAnalysisToAnalysisWithDocuments = (basicMaterialAnalysis: MaterialAnalysisForMap[]) => {
  return basicMaterialAnalysis.map((analysis) => {
    return ({ ...analysis, documents: [] } as unknown) as Partial<ILazyMaterialAnalysisWithDocuments>
  })
}

const OFFSET = 18

export const useLazyMaterialAnalysisWithDocumentsLoading = (
  materialAnalysis: MaterialAnalysisForMap[],
  open: boolean,
) => {
  const [wasOpenedBefore, setWasOpenendBefore] = useState<boolean>(false)
  const [isFetching, setIsFetching] = useState(false)
  const [disableFetch, setDisableFetch] = useState(materialAnalysis.length <= OFFSET)
  const [lazyMaterialAnalysis, setLazyMaterialAnalysis] = useState<Array<Partial<ILazyMaterialAnalysisWithDocuments>>>(
    [],
  )

  useEffect(() => {
    let overlapping = lazyMaterialAnalysis.filter((lm) =>
      materialAnalysis.slice(0, lazyMaterialAnalysis.length).find((m) => lm.id === m.id),
    )

    if (overlapping.length < lazyMaterialAnalysis.length) {
      setLazyMaterialAnalysis(overlapping)
    }
  }, [materialAnalysis, lazyMaterialAnalysis])

  useEffect(() => {
    if (!wasOpenedBefore && open) {
      setIsFetching(true)
      setLazyMaterialAnalysis([])
      setWasOpenendBefore(true)
    }
  }, [open, wasOpenedBefore, setWasOpenendBefore])

  const updateWithDocuments = (data: AnalyticsDocumentsQueryResult) => {
    const analysisWithDocuments = data.materialAnalysis?.materialAnalysis

    if (analysisWithDocuments) {
      const updated = lazyMaterialAnalysis.map((basicAnalysis) => {
        return (
          analysisWithDocuments.find((analysisWithDocument) => analysisWithDocument.id === basicAnalysis.id) ||
          basicAnalysis
        )
      })

      setLazyMaterialAnalysis(updated)
    }
  }

  const [analyticsWithDocumentsQuery] = useLazyQuery<AnalyticsDocumentsQueryResult, AnalyticsDocumentsQueryVariables>(
    ANALYTICS_DOCUMENTS_QUERY,
    {
      fetchPolicy: "network-only",
      onCompleted: updateWithDocuments,
    },
  )

  useEffect(() => {
    if (isFetching) {
      setIsFetching(false)

      const lastIndex = lazyMaterialAnalysis.length
      const newAnalysis = mapBasicAnalysisToAnalysisWithDocuments(materialAnalysis.slice(lastIndex, lastIndex + OFFSET))
      const newLazyMaterialAnalysis = [...lazyMaterialAnalysis, ...newAnalysis]

      setLazyMaterialAnalysis(newLazyMaterialAnalysis)
      if (newLazyMaterialAnalysis.length === materialAnalysis.length) {
        setDisableFetch(true)
      }
      analyticsWithDocumentsQuery({
        variables: { ids: newAnalysis.map((analysis) => analysis.id) },
      })
    }
  }, [isFetching, lazyMaterialAnalysis, materialAnalysis, analyticsWithDocumentsQuery])

  const removeAnalysisFromLazyMaterialAnalysis = useCallback(
    (id: string) => {
      const clone = [...lazyMaterialAnalysis]
      clone.splice(
        clone.findIndex((materialAnalysis) => materialAnalysis.id === id),
        1,
      )
      setLazyMaterialAnalysis(clone)
    },
    [lazyMaterialAnalysis],
  )

  return {
    disableFetch,
    isFetching,
    setIsFetching,
    lazyMaterialAnalysis,
    removeAnalysisFromLazyMaterialAnalysis,
  }
}
