import { gql } from "apollo-boost"
import { deleteTour, deleteTourVariables } from "../generated/deleteTour"

export const DELETE_TOUR_MUTATION = gql`
  mutation deleteTour($id: ID!) {
    deleteTour(id: $id) {
      message
      code
    }
  }
`

export type DeleteTourResult = deleteTour
export type DeleteTourVariables = deleteTourVariables
