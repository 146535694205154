import { v4 } from "uuid"
import { TFunction } from "i18next"
import { WidgetType } from "../../api/graphql/generated/globalTypes"

export enum WidgetTypeWeb {
  NEW_WIDGET = "new_widget",
  TEXT = "text",
  TEXT_INDIVIDUAL = "text_individual",
  CHART_PIE = "chart_pie",
  CHART_HISTORY = "chart_history",
}

export const WidgetTypesAll = [
  {
    type: WidgetType.text,
    title: "widget_type.title_text",
    genericTitle: "widget_type.generic_title_text",
    isGraphic: false,
  },
  {
    type: WidgetType.text_individual,
    title: "widget_type.title_individual_text",
    genericTitle: "widget_type.title_pie_chart_generic",
    isGraphic: false,
  },
  {
    type: WidgetType.chart_pie,
    title: "widget_type.title_pie_chart",
    genericTitle: "widget_type.title_pie_chart_generic",
    isGraphic: true,
  },
  {
    type: WidgetType.chart_pie_waste_quality,
    title: "widget_type.title_pie_chart_waste_quality",
    genericTitle: "widget_type.title_pie_chart_waste_quality_generic",
    isGraphic: true,
  },
  {
    type: WidgetType.chart_history,
    title: "widget_type.title_history_chart",
    genericTitle: "widget_type.title_history_chart_generic",
    isGraphic: true,
  },
  {
    type: WidgetType.chart_trend,
    title: "widget_type.title_trend_chart",
    genericTitle: "widget_type.title_trend_chart_generic",
    isGraphic: true,
  },
  {
    type: WidgetType.chart_trend_comparison,
    title: "widget_type.title_chart_trend_chart_comparison",
    genericTitle: "widget_type.title_trend_chart_comparison_generic",
    isGraphic: true,
  },
]

export enum WidgetModificationType {
  EDIT = "modify_edit",
  TRANSFORM = "modify_transform",
  DUPLICATE = "modify_duplicate",
  DELETE = "modify_delete",
}

export class WidgetModel {
  /// type: WidgetType
  type: WidgetTypeWeb
  /// reportId: ID of Report
  reportId: String | null
  widgetTitle: String | null
  id: String
  constructor(type: WidgetTypeWeb, reportId: String | null, widgetTitle: String | null) {
    this.type = type
    this.reportId = reportId
    this.widgetTitle = widgetTitle
    this.id = v4()
  }
}

export class WidgetNewModel extends WidgetModel {
  constructor(t: TFunction) {
    super(WidgetTypeWeb.NEW_WIDGET, null, t("communication_channels.new_component"))
  }
}
