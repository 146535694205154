import React, { FunctionComponent, useContext, useMemo } from "react"
import { Theme, makeStyles, Grid, useTheme, Typography } from "@material-ui/core"
import { useTranslation } from "react-i18next"
import { BarChart, CartesianGrid, YAxis, XAxis, Bar, ResponsiveContainer, Tooltip } from "recharts"
import { StatisticsFilterContext } from "../../../context/StatisticsFilterContext"
import { StatisticsRefinementContext } from "../../../context/StatisticsRefinementContext"
import { Frequency } from "../../../api/graphql/generated/globalTypes"
import { SectionLoadingIndicator } from "../dashboard/SectionLoadingIndicator"
import moment from "moment"

const useStyles = makeStyles((theme: Theme) => ({
  heading: {
    paddingTop: theme.spacing(2),
    paddingLeft: theme.spacing(2),
  },
  diagramContainer: {
    paddingBottom: theme.spacing(2),
    paddingTop: theme.spacing(2),
  },
}))

interface IStatisticsDiagramProps {}

export const StatisticsDiagram: FunctionComponent<IStatisticsDiagramProps> = (props) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { selectedFrequency } = useContext(StatisticsRefinementContext)
  const { statistics, contextLoading } = useContext(StatisticsFilterContext)
  const theme = useTheme()

  const { collection, transformLabel } = useMemo(() => {
    const FORMAT = "DD.MM.YYYY"
    switch (selectedFrequency) {
      case Frequency.daily: {
        const collection = statistics?.daily || []
        return {
          collection,
          transformLabel: (date: string | number) => moment(date).format(FORMAT),
        }
      }
      case Frequency.weekly: {
        const collection = statistics?.weekly || []
        return {
          collection,
          transformLabel: (date: string | number) => `${t("general.CW")} ${moment(date).format("WW - GGGG")}`,
        }
      }
      case Frequency.monthly: {
        const collection = statistics?.monthly || []
        return {
          collection,
          transformLabel: (date: string | number) => `${moment(date).format("MMMM")} - ${moment(date).format("YYYY")}`,
        }
      }
      default: {
        return { collection: [], transformLabel: (date: string | number) => date }
      }
    }
  }, [selectedFrequency, statistics, t])

  const hideTicks = collection.length > 8

  const xLabel = hideTicks
    ? `${transformLabel(collection[0].date)} ${t("general.to")} ${transformLabel(
        collection[collection.length - 1].date,
      )}`
    : undefined

  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography variant={"h6"} className={classes.heading}>
          {t("statistics.diagram.title")}
        </Typography>
      </Grid>
      <Grid item xs={12} className={classes.diagramContainer}>
        {contextLoading && <SectionLoadingIndicator />}
        {!contextLoading && (
          <ResponsiveContainer width={"98%"} height={250}>
            <BarChart margin={{ top: 0, right: 0, left: 0, bottom: 0 }} data={collection}>
              <CartesianGrid strokeDasharray="3 3" />
              {/*<Legend layout={"horizontal"} verticalAlign={"bottom"} align={"center"} iconType={"circle"} iconSize={10} /> */}

              <Tooltip labelFormatter={transformLabel} />

              <XAxis dataKey="date" tick={!hideTicks} label={xLabel} tickFormatter={transformLabel} />
              <YAxis />

              <Bar dataKey="total" fill={theme.palette.secondary.main} name={t("statistics.diagram.total") as string} />
              <Bar dataKey="valid" fill={theme.palette.secondary.dark} name={t("statistics.diagram.valid") as string} />
              <Bar
                dataKey="invalid"
                fill={theme.palette.primary.main}
                name={t("statistics.diagram.invalid") as string}
              />
            </BarChart>
          </ResponsiveContainer>
        )}
      </Grid>
    </Grid>
  )
}
