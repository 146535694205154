import React, { FunctionComponent, Fragment, useState, useCallback, useContext } from "react"
import { Theme, makeStyles, FormControlLabel, Checkbox } from "@material-ui/core"
import { useTranslation } from "react-i18next"
import { WerstoffAnalysisInvalidateAnalysisDialog } from "./WerstoffAnalysisInvalidateAnalysisDialog"
import { WertstoffDetailContext } from "./WertstoffDetailContext"

const useStyles = makeStyles((theme: Theme) => ({}))

interface IWertstoffAnalysisInvalidateAnalysisCheckboxProps {}

export const WertstoffAnalysisInvalidateAnalysisCheckbox: FunctionComponent<IWertstoffAnalysisInvalidateAnalysisCheckboxProps> = (
  props,
) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const [invalidateDialogOpen, setInvalidateDialogOpen] = useState<boolean>(false)

  const { shouldInvalidateAnalysis, setShouldInvalidateAnalysis, setInvalidReason } = useContext(WertstoffDetailContext)

  const onChangeCheckbox = useCallback(() => {
    if (shouldInvalidateAnalysis) {
      setShouldInvalidateAnalysis(false)
      setInvalidReason("")
    } else {
      setInvalidateDialogOpen(true)
    }
  }, [shouldInvalidateAnalysis])

  return (
    <Fragment>
      <WerstoffAnalysisInvalidateAnalysisDialog
        open={invalidateDialogOpen}
        onClose={() => setInvalidateDialogOpen(false)}
        onSave={() => setShouldInvalidateAnalysis(true)}
      />
      <FormControlLabel
        control={<Checkbox color="primary" checked={shouldInvalidateAnalysis} onChange={onChangeCheckbox} />}
        label={t("analysis_detail.invalidate_analysis.title")}
      />
    </Fragment>
  )
}
