import React, { FunctionComponent } from "react"
import { Theme, makeStyles, Grid, Typography, Tooltip } from "@material-ui/core"
import { useTranslation } from "react-i18next"
import classNames from "classnames"
import lodash from "lodash"
import moment from "moment"
import { LogLevel, LogType } from "../../../api/graphql/generated/globalTypes"
import { Info } from "@material-ui/icons"
import { CommunicationHistoryEntryByType } from "./types/CommunicationHistoryEntryByType"
import { ReportCommunicationHistoryEntry } from "../../../api/graphql/query/reports"

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    margin: 0,
  },
  content: {
    fontSize: 16,
  },
  title: {
    fontWeight: 600,
  },
  warning: {
    marginLeft: theme.spacing(1),
  },
}))

interface ICommunicationHistoryProps {
  history: ReportCommunicationHistoryEntry[]
}

export const CommunicationHistory: FunctionComponent<ICommunicationHistoryProps> = (props) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const getChannels = (): CommunicationHistoryEntryByType[] => {
    const types: LogType[] = [LogType.daheim_app, LogType.symvaro, LogType.sms, LogType.email]

    return types.map((type: LogType) => {
      const historyByType = props.history.filter((entry: ReportCommunicationHistoryEntry) => entry.type === type)

      return {
        title: t(`communication_channels.${type}`),
        logs: historyByType.filter((entry: ReportCommunicationHistoryEntry) => entry.level === LogLevel.log),
        warning: !lodash.isEmpty(historyByType) && historyByType[0].level === LogLevel.error,
      }
    })
  }

  const channels: CommunicationHistoryEntryByType[] = getChannels()

  const renderTitle = (title: String): JSX.Element => (
    <Grid item xs={2}>
      <Typography className={classNames(classes.content, classes.title)}>{title}</Typography>
    </Grid>
  )

  const renderValue = (value: String, index: number, warning: boolean): JSX.Element => (
    <Grid item xs={2} key={`${value}${index}`} container alignItems="center">
      <Typography className={classes.content}>{value}</Typography>
      {warning && (
        <Tooltip title={t("communication_center.last_post_failed")} className={classes.warning}>
          <Info fontSize="small" />
        </Tooltip>
      )}
    </Grid>
  )

  return (
    <Grid container direction="column" className={classes.wrapper}>
      <Grid item container>
        {renderTitle(t("general.channel"))}
        {channels.map((channel: CommunicationHistoryEntryByType, index: number) =>
          renderValue(channel.title, index, false),
        )}
      </Grid>
      <Grid item container>
        {renderTitle(t("communication_center.last_sent"))}
        {channels.map((channel: CommunicationHistoryEntryByType, index: number) =>
          renderValue(
            lodash.isEmpty(channel.logs)
              ? t("communication_center.no_history_entry")
              : moment(channel.logs[0].time).format("DD.MM.YYYY, HH:mm"),
            index,
            channel.warning,
          ),
        )}
      </Grid>
    </Grid>
  )
}
