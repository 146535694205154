import { gql } from "apollo-boost"
import { deleteMaterialAnalysis, deleteMaterialAnalysisVariables } from "../generated/deleteMaterialAnalysis"

export const DELETE_MATERIAL_ANALYSIS_MUTATION = gql`
  mutation deleteMaterialAnalysis($id: ID!) {
    deleteMaterialAnalysis(id: $id)
  }
`
export type DeleteMaterialAnalysisMutationResult = deleteMaterialAnalysis
export type DeleteMaterialAnalysisMutationVariables = deleteMaterialAnalysisVariables
