import React, { FunctionComponent } from "react"
import { Theme, makeStyles, Grid, Card } from "@material-ui/core"

const useStyles = makeStyles((theme: Theme) => ({
  upperleft: {
    paddingRight: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  upperRight: {
    paddingLeft: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  height100: {
    height: "100%",
  },
  upperContainer: {
    height: "70%",
  },
  lowerContainer: {
    paddingTop: theme.spacing(1),
    height: "30%",
  },
  card: {
    overflow: "visible",
  },
}))

interface IEdaBoardTrippleSectionProps {
  card1: JSX.Element
  card2: JSX.Element
  card3: JSX.Element
}

export const EdaBoardTrippleSection: FunctionComponent<IEdaBoardTrippleSectionProps> = (props) => {
  const classes = useStyles()
  const { card1, card2, card3 } = props
  return (
    <Grid container className={classes.height100} direction="row">
      <Grid item xs={12} className={classes.upperContainer}>
        <Grid container className={classes.height100}>
          <Grid item xs={6} className={`${classes.height100} ${classes.upperleft}`}>
            <Card className={`${classes.height100} ${classes.card}`}>{card1}</Card>
          </Grid>
          <Grid item xs={6} className={`${classes.height100} ${classes.upperRight}`}>
            <Card className={`${classes.height100} ${classes.card}`}>{card2}</Card>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} className={classes.lowerContainer}>
        <Card className={`${classes.height100} ${classes.card}`}>{card3}</Card>
      </Grid>
    </Grid>
  )
}
