import React, { FunctionComponent, useMemo, useContext } from "react"
import { Grid, MenuItem } from "@material-ui/core"
import { useTranslation } from "react-i18next"
import { TextReplacementContextProvider } from "../../../../context/TextReplacementContext"
import { ReportDetail } from "../../../../api/graphql/query/report-detailed"
import { reportDetail_report_widgets } from "../../../../api/graphql/generated/reportDetail"
import { ChannelType, WidgetType } from "../../../../api/graphql/generated/globalTypes"
import { CommunicationChannelTypeDictionary } from "../../../../domain/models/CommunicationChannelTypes"
import { WidgetNewModel, WidgetTypesAll } from "../../../../domain/models/WidgetModels"
import { Widget } from "./Widget"
import WidgetNew from "./WidgetNew"
import { PaperWidgetWrapper } from "./PaperWidgetWrapper"
import { ReportDetailContext } from "../context/ReportDetailContext"
interface IWidgetSectionProps {
  widgets: reportDetail_report_widgets[]
  channelType: ChannelType
  report: ReportDetail
}

export const WidgetSection: FunctionComponent<IWidgetSectionProps> = (props) => {
  const { t } = useTranslation()

  const { widgets, channelType, report } = props

  const { refetchReportDetails } = useContext(ReportDetailContext)

  // would be better if this was somehow incorporated in the ChannelTypeSections
  const menuOptionsForWidgetCreate = useMemo(() => {
    const communicationChannel = CommunicationChannelTypeDictionary[channelType]

    let widgetTypes = WidgetTypesAll
    if (!communicationChannel.canHaveGraphics) {
      widgetTypes = WidgetTypesAll.filter((widgetType) => !widgetType.isGraphic)
    }
    if (!communicationChannel.canHaveIndividualText) {
      widgetTypes = widgetTypes.filter((widgetType) => widgetType.type !== WidgetType.text_individual)
    }
    if (channelType === ChannelType.sms || channelType === ChannelType.email) {
      widgetTypes = widgetTypes.filter((widgetType) => widgetType.type !== WidgetType.text)
    }

    const textWidgets = widgets.filter(
      (widget) =>
        widget.channelType === channelType &&
        (widget.widgetType === WidgetType.text || widget.widgetType === WidgetType.text_individual),
    )

    if (communicationChannel.maxNumOfTextWidgets && communicationChannel.maxNumOfTextWidgets <= textWidgets.length) {
      widgetTypes = widgetTypes.filter(
        (widgetType) => widgetType.type !== WidgetType.text && widgetType.type !== WidgetType.text_individual,
      )
    }

    return [
      <MenuItem key={null} value={-1}>
        {t("general.please_choose")}
      </MenuItem>,
      ...widgetTypes.map((widgetType, index) => {
        return (
          <MenuItem key={index} value={widgetType.title}>
            {t(widgetType.title)}
          </MenuItem>
        )
      }),
    ]
  }, [channelType, widgets, t])

  return (
    <TextReplacementContextProvider>
      <Grid container alignItems="center">
        {widgets.map((widget) => {
          return <Widget widget={widget} channelType={channelType} report={report} />
        })}
        {menuOptionsForWidgetCreate.length > 1 && (
          <Grid item xs={12} md={6} xl={4}>
            <PaperWidgetWrapper flex>
              <WidgetNew
                widgetModel={new WidgetNewModel(t)}
                report={report}
                triggerRefresh={() => refetchReportDetails()}
                channelType={channelType}
                menuItems={menuOptionsForWidgetCreate}
              />
            </PaperWidgetWrapper>
          </Grid>
        )}
      </Grid>
    </TextReplacementContextProvider>
  )
}
