import React, { FunctionComponent, useContext } from "react"
import { Theme, makeStyles, Grid } from "@material-ui/core"
import { EdaContext } from "../../../context/EdaContext"
import { EdaBoardTrippleSection } from "./EdaBoardTrippleSection"
import { EdaBoardLane } from "./EdaBoardLane"
import { EdaBoardLaneQueueContent } from "./EdaBoardLaneQueueContent"

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    height: "100%",
    flex: 1,
    padding: theme.spacing(1),
  },
  lane: {
    padding: theme.spacing(1),
  },
}))

interface IEdaPageContentProps {}

export const EdaPageContent: FunctionComponent<IEdaPageContentProps> = (props) => {
  const classes = useStyles()
  const { edaQueues, edaQueuesLoading } = useContext(EdaContext)

  return (
    <Grid container className={classes.container}>
      <Grid item xs={2} className={classes.lane}>
        <EdaBoardLane
          heading={edaQueues[0]?.queueName}
          content={<EdaBoardLaneQueueContent queue={edaQueues[0]} loading={edaQueuesLoading} />}
          direction="right"
          boardLaneHeightPercentage={100}
        />
      </Grid>
      <Grid item xs={4} className={classes.lane}>
        <EdaBoardTrippleSection
          card1={
            <EdaBoardLane
              heading={edaQueues[1]?.queueName}
              content={<EdaBoardLaneQueueContent queue={edaQueues[1]} loading={edaQueuesLoading} />}
              direction="down"
            />
          }
          card2={
            <EdaBoardLane
              heading={edaQueues[2]?.queueName}
              content={<EdaBoardLaneQueueContent queue={edaQueues[2]} loading={edaQueuesLoading} />}
              direction="right"
            />
          }
          card3={<EdaBoardLane heading="Preprocessor" horizontal content={<></>} direction="up" />}
        />
      </Grid>
      <Grid item xs={4} className={classes.lane}>
        <EdaBoardTrippleSection
          card1={
            <EdaBoardLane
              heading={edaQueues[3]?.queueName}
              content={<EdaBoardLaneQueueContent queue={edaQueues[3]} loading={edaQueuesLoading} />}
              direction="down"
            />
          }
          card2={
            <EdaBoardLane
              heading={edaQueues[4]?.queueName}
              content={<EdaBoardLaneQueueContent queue={edaQueues[4]} loading={edaQueuesLoading} />}
              direction="right"
            />
          }
          card3={<EdaBoardLane heading="CNN" horizontal content={<></>} direction="up" />}
        />
      </Grid>
      <Grid item xs={2} className={classes.lane}>
        <EdaBoardLane heading="Denovo" content={<></>} />
      </Grid>
    </Grid>
  )
}
