import lodash from "lodash"
import { Rating } from "../../api/graphql/generated/globalTypes"

export const ratingsAll = [Rating.High, Rating.Medium, Rating.Low, Rating.Unknown]

export function sortRating(ratingA: Rating | string, ratingB: Rating | string): number {
  const orderedRatings = ratingsAll
  const orderedRatingsAsString = Object.values(orderedRatings).map((rating) => rating.toString())

  let indexA = 0
  if (lodash.isString(ratingA)) {
    indexA = orderedRatingsAsString.indexOf(ratingA)
  } else {
    indexA = orderedRatings.indexOf(ratingA)
  }

  let indexB = 0
  if (lodash.isString(ratingB)) {
    indexB = orderedRatingsAsString.indexOf(ratingB)
  } else {
    indexB = orderedRatings.indexOf(ratingB)
  }

  return indexA - indexB
}

export interface RatingInfo {
  id: Rating;
  rating: string;
  color: string;
}

export function getRatingInfo(rating: Rating | string | undefined | null): RatingInfo {
  let ratingLocalized = getLocalizeableRating(rating) ?? "--"
  switch (rating) {
    case Rating.High:
      return { id: Rating.High, rating: ratingLocalized, color: "#40bc3b" }
    case Rating.Medium:
      return { id: Rating.Medium, rating: ratingLocalized, color: "#ebc116" }
    case Rating.Low:
      return { id: Rating.Low, rating: ratingLocalized, color: "#C20E1A" }
    case Rating.Unknown:
    default:
      return { id: Rating.Unknown, rating: ratingLocalized, color: "#4b9be0" }
  }
}

export function getLocalizeableRating(ratingGraphQL: Rating | string | null | undefined): string {
  if (lodash.isString(ratingGraphQL) && Object.keys(Rating).includes(ratingGraphQL)) {
    return "waste_quality." + ratingGraphQL
  }

  console.warn("getLocalizedRating(ratingGraphQL): ", ratingGraphQL)
  return "--"
}
