import { Bookmark } from "../../../../api/graphql/query/bookmarks-for-user"
import lodash from "lodash"
import moment from "moment"

export const checkIfBookmarkIsEdited = (bookmark: Bookmark | null, filterModel: any): boolean => {
  if (!bookmark) {
    return false
  }

  const equalObj = {
    associationId: bookmark.associationId,
    townIds: bookmark.townIds,
    emptyingId: lodash.get(bookmark, "emptying.id"),
    displayOnlyAutomaticAnalysis: bookmark.displayOnlyAutomaticAnalysis,
    rating: bookmark.rating,
    tourType: bookmark.tourType,
    fractionType: bookmark.fractionType,
    fractionFrom: bookmark.fractionFrom,
    fractionTo: bookmark.fractionTo,
    dateFrom: bookmark.dateFrom,
    dateUntil: bookmark.dateUntil,
    rfids: (bookmark.rfids || []).join(","),
  }

  return !lodash.every(Object.keys(equalObj), (key) => {
    if (lodash.get(equalObj, key, null) instanceof Date) {
      return moment(lodash.get(equalObj, key, null)).isSame(lodash.get(filterModel, key, null), "day")
    }
    return lodash.get(equalObj, key, null) === lodash.get(filterModel, key, null)
  })
}
