import { gql } from "apollo-boost"
import { deleteFilter, deleteFilterVariables } from "../generated/deleteFilter"

export const DELETE_FILTER_MUTATION = gql`
  mutation deleteFilter($id: ID!) {
    deleteFilter(id: $id) {
      error
      message
    }
  }
`

export type DeleteFilterResult = deleteFilter
export type DeleteFilterVariables = deleteFilterVariables
