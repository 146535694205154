import { gql } from "apollo-boost"
import { daheimTowns, daheimTowns_daheimTowns } from "../generated/daheimTowns"

export const DAHEIM_TOWNS_QUERY = gql`
  query daheimTowns {
    daheimTowns {
      id
      name
      latitude
      longitude
    }
  }
`

export type DaheimTownsResult = daheimTowns
export type DaheimTown = daheimTowns_daheimTowns
