import { gql } from "apollo-boost"
import { deleteRfidGroup, deleteRfidGroupVariables } from "../generated/deleteRfidGroup"

export const DELETE_RFID_GROUP_MUTATION = gql`
  mutation deleteRfidGroup($id: ID!) {
    deleteRfidGroup(id: $id) {
      deletedCount
    }
  }
`
export type DeleteRfidGroupMutationResult = deleteRfidGroup
export type DeleteRfidGroupMutationVariables = deleteRfidGroupVariables
