import { IEditorItem } from "../EditorItem"
import { TextTemplateSpecialCharacters } from "../../../../../domain/models/TextTemplateSpecialCharacters"
import lodash from "lodash"

export const generateTextFromEditorItems = (editorItems: IEditorItem[]): string => {
  if (lodash.isEmpty(editorItems)) {
    return ""
  }
  return (
    editorItems[0].value +
    editorItems
      .slice(1)
      .map((item) => {
        if (TextTemplateSpecialCharacters.includes(item.value.slice(0, 1))) {
          return item.value
        } else {
          return " " + item.value
        }
      })
      .join("")
  )
}
