import React, { FunctionComponent, useContext, useEffect, useState } from "react"
import { Theme, makeStyles, Grid, Typography, useTheme, Button } from "@material-ui/core"
import { useTranslation } from "react-i18next"
import { AnalysisFilterContext } from "../../../context/AnalysisFilterContext"
import { useLazyQuery } from "react-apollo"
import {
  DailyGroupedStatsForAnalysisQueryResult,
  DailyGroupedStatsForAnalysisQueryVariables,
  DAILY_GROUPED_STATS_FOR_ANALYSIS_QUERY,
} from "../../../api/graphql/query/daily-grouped-stats-for-analysis"
import { ClipLoader } from "react-spinners"
import { WertstoffAnalysisTrendGraph } from "../analysis-data/WertstoffAnalysisTrendGraph"
import { TourTypeInfo } from "../../../domain/models/TourTypes"
import { AuthContext } from "../../../context/AuthContext"
import { KeyboardArrowDown } from "@material-ui/icons"

interface IStyleProps {
  open: boolean
}

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    minHeight: (props: IStyleProps) => (props.open ? 330 : 0),
  },
  reportCreateButton: {
    minWidth: 180,
  },
  icon: {
    transform: (props: IStyleProps) => `rotate(${props.open ? "180deg" : "0deg"})`,
    transition: "200ms",
    marginRight: theme.spacing(1),
  },
}))

interface IAnalysisTrendGraphSectionProps {
  onReportCreate: () => void
  tourTypeInfo: TourTypeInfo | undefined | null
}

export const AnalysisTrendGraphSection: FunctionComponent<IAnalysisTrendGraphSectionProps> = (props) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const { tourTypeInfo, onReportCreate } = props
  const { persistedTowns, getAnalyticsQueryVariables, applyFilter } = useContext(AnalysisFilterContext)
  const [open, setOpen] = useState<boolean>(false)
  const classes = useStyles({ open })
  const { isHousingManagement } = useContext(AuthContext)

  const [dailyGroupedStatsForAnalysisQuery, { data, loading, error }] = useLazyQuery<
    DailyGroupedStatsForAnalysisQueryResult,
    DailyGroupedStatsForAnalysisQueryVariables
  >(DAILY_GROUPED_STATS_FOR_ANALYSIS_QUERY, {
    fetchPolicy: "cache-first",
  })

  useEffect(() => {
    if (open && !data) {
      const variables = getAnalyticsQueryVariables(false)
      const townIds = (variables?.townIds ?? []).map((townId) => String(townId)).filter((townId) => !!townId)
      dailyGroupedStatsForAnalysisQuery({
        variables: { ...variables, townIds },
      })
    }
  }, [dailyGroupedStatsForAnalysisQuery, getAnalyticsQueryVariables, applyFilter, open, data])

  const dailyGroupedStatsForAnalysis = data?.dailyGroupedStatsForAnalysis || []

  return (
    <Grid container spacing={2} className={classes.container} justify="space-between" direction="column">
      <Grid item container xs={12} onClick={() => setOpen(!open)}>
        <Grid item>
          <KeyboardArrowDown fontSize="large" className={classes.icon} />
        </Grid>
        <Grid item xs>
          <Typography variant={"h5"}>{t("graph.trend.heading", { townName: persistedTowns[0]?.name })}</Typography>
        </Grid>
      </Grid>
      {open && (
        <>
          {loading && (
            <Grid item xs container alignItems="center" justify="center">
              <Grid item>
                <ClipLoader color={theme.palette.primary.main} />
              </Grid>
            </Grid>
          )}
          {!loading && error && (
            <Grid item xs container alignItems="center" justify="center">
              <Grid item>
                <Typography>{t("general.no_data_available_reload_page")}</Typography>
              </Grid>
            </Grid>
          )}
          {!loading && !error && (
            <WertstoffAnalysisTrendGraph
              dailyGroupedStatsForAnalysis={dailyGroupedStatsForAnalysis}
              tourTypeInfo={tourTypeInfo}
            />
          )}
          {!isHousingManagement() && (
            <Grid item container justify={"flex-end"} spacing={2}>
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  aria-label="Bericht erstellen"
                  onClick={onReportCreate}
                  className={classes.reportCreateButton}
                >
                  {t("report.create_report")}
                </Button>
              </Grid>
            </Grid>
          )}
        </>
      )}
    </Grid>
  )
}
