import React, { FunctionComponent, useState, useEffect, useMemo } from "react"
import { TextField, Grid, Tooltip } from "@material-ui/core"
import { useTranslation } from "react-i18next"
import { useLazyQuery } from "react-apollo"
import {
  TownBoundarySearchQueryResult,
  TownBoundarySearchVariables,
  TownBoundarySearchResult,
  TOWN_BOUNDARY_SEARCH_QUERY,
} from "../../../../api/graphql/query/townBoundarySearch"
import { Autocomplete } from "@material-ui/lab"
import { ClipLoader } from "react-spinners"
import { Check, Close } from "@material-ui/icons"

interface ITownBoundaryAutocompleteProps {
  selectedTownBoundary: TownBoundarySearchResult | null
  setSelectedTownBoundary: (selectedTownBoundaryId: TownBoundarySearchResult | null) => void
}

export const TownBoundaryAutocomplete: FunctionComponent<ITownBoundaryAutocompleteProps> = (props) => {
  const { t } = useTranslation()
  const { selectedTownBoundary, setSelectedTownBoundary } = props
  const [searchString, setSearchString] = useState<string>("")

  const [townBoundarySearchQuery, { data, loading }] = useLazyQuery<
    TownBoundarySearchQueryResult,
    TownBoundarySearchVariables
  >(TOWN_BOUNDARY_SEARCH_QUERY, { fetchPolicy: "no-cache" })

  const onSearch = (newValue: string) => {
    if (newValue.length >= 3) {
      townBoundarySearchQuery({ variables: { searchString: newValue } })
    }
    setSearchString(newValue)
  }

  const townBoundaries = useMemo(() => data?.townBoundarySearch || [], [data])

  useEffect(() => {
    if (townBoundaries[0]?.townName?.toLowerCase() === searchString.toLowerCase()) {
      setSelectedTownBoundary(townBoundaries[0])
      setSearchString(townBoundaries[0].townName)
    }
  }, [searchString, townBoundaries, setSelectedTownBoundary, setSearchString])

  return (
    <Grid container justify="space-between" alignItems="flex-end" alignContent="flex-end" spacing={1}>
      <Grid item xs>
        <Autocomplete<TownBoundarySearchResult>
          options={townBoundaries}
          value={selectedTownBoundary}
          getOptionLabel={(option) => option.townName}
          onChange={(event: any, newValue: any) => setSelectedTownBoundary(newValue)}
          loading={loading}
          loadingText={t("general.data_loading")}
          noOptionsText={t("general.no_data_for_this_searchterm")}
          renderInput={(params) => (
            <TextField
              value={searchString}
              onChange={(event) => onSearch(event.target.value)}
              name="abilities"
              {...params}
              variant="standard"
              label={t("general.town")}
              margin="none"
              fullWidth
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <React.Fragment>
                    {loading ? <ClipLoader color="inherit" size={15} /> : null}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                ),
              }}
            />
          )}
        />
      </Grid>
      <Grid item>
        {!!selectedTownBoundary && (
          <Tooltip
            title={t("association_management.town_dialog.successfully_linked_with_town", {
              townName: selectedTownBoundary.townName,
            })}
          >
            <Check color="primary" />
          </Tooltip>
        )}
        {!selectedTownBoundary && (
          <Tooltip title={t("association_management.town_dialog.no_linked_town_linked")}>
            <Close />
          </Tooltip>
        )}
      </Grid>
    </Grid>
  )
}
