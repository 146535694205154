import { gql } from "apollo-boost"
import { rfidReadingCountForTour, rfidReadingCountForTourVariables } from "../generated/rfidReadingCountForTour"

export const RFID_READING_COUNT_FOR_TOUR_QUERY = gql`
  query rfidReadingCountForTour($id: ID!) {
    tour(id: $id) {
      rfidReadingCount
    }
  }
`
export type RfidReadingCountForTourVariables = rfidReadingCountForTourVariables
export type RfidReadingCountForTourResult = rfidReadingCountForTour