import React, { FunctionComponent, Fragment, useCallback } from "react"
import { Theme, makeStyles, Paper, List, ListItem, ListItemText, Grid, Divider } from "@material-ui/core"
import { useQuery } from "react-apollo"
import {
  WidgetTextCategoriesResult,
  WIDGET_TEXT_CATEGORY_QUERY,
} from "../../../api/graphql/query/widget-text-categories"
import lodash from "lodash"
import { PageLoadingIndicator } from "../indicator/PageLoadingIndicator"
import { useHistory } from "react-router"
import { AssociationFilter } from "../text-filter/AssociationFilter"
import { TownFilter } from "../text-filter/TownFilter"
import { ClearTextFilterButton } from "../text-filter/ClearTextFilterButton"
import { useTranslation } from "react-i18next"
import { COLORS } from "../../../theme/theme"
import { useTextFilter } from "../../../context/TextFilterContext"

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    height: "100%",
    background: COLORS.lightgrey,
  },
  gridFilter: {
    padding: theme.spacing(2),
  },
  townFilter: {
    marginTop: theme.spacing(2),
  },
  clearButton: {
    marginTop: theme.spacing(2),
  },
}))

interface ITextCategoryListProps {
  selectedCategoryId: string | string[] | null
}

export const TextCategoryList: FunctionComponent<ITextCategoryListProps> = (props) => {
  const classes = useStyles()
  const history = useHistory()
  const { t } = useTranslation()
  const { setTownId } = useTextFilter()
  const { selectedCategoryId } = props

  const { data: categoryData, loading: categoryLoading, error: categoryError } = useQuery<WidgetTextCategoriesResult>(
    WIDGET_TEXT_CATEGORY_QUERY,
  )

  if (categoryError) {
    console.log(categoryError)
  }

  const categories = lodash
    .compact(categoryData?.widgetTextCategories ?? [])
    .filter((value) => value.name !== "Schwarzes Brett")

  const bulletinBoard = lodash
    .compact(categoryData?.widgetTextCategories ?? [])
    .find((value) => value.name === "Schwarzes Brett")

  const selectBulletinBoard = useCallback(() => {
    if (bulletinBoard) {
      setTownId("all")
      history.push(`/text-management?category=${bulletinBoard.id}`)
    }
  }, [setTownId, history, bulletinBoard])

  return (
    <Paper elevation={3} className={classes.paper}>
      {!categoryLoading && (
        <Fragment>
          <Grid container direction="column" justify={"center"} className={classes.gridFilter}>
            <AssociationFilter onChange={() => {}} />
            <TownFilter style={classes.townFilter} />
            <ClearTextFilterButton className={classes.clearButton} />
          </Grid>
          <List>
            {categories.map((category) => (
              <Fragment key={category.id}>
                <ListItem
                  selected={category.id === selectedCategoryId}
                  button
                  onClick={() => history.push(`/text-management?category=${category.id}`)}
                >
                  <ListItemText>{t(category.translationKey)}</ListItemText>
                </ListItem>
                <Divider />
              </Fragment>
            ))}
          </List>
        </Fragment>
      )}
      {bulletinBoard && (
        <>
          <List>
            <ListItem button selected={bulletinBoard.id === selectedCategoryId} onClick={selectBulletinBoard}>
              <ListItemText>{t(bulletinBoard.translationKey)}</ListItemText>
            </ListItem>
          </List>
          <Divider />
        </>
      )}
      {categoryLoading && <PageLoadingIndicator />}
    </Paper>
  )
}
