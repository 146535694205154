import { useMutation } from "@apollo/react-hooks"
import { Button } from "@material-ui/core"
import React, { FunctionComponent, Fragment, useCallback, useState } from "react"
import { useTranslation } from "react-i18next"
import { ClipLoader } from "react-spinners"
import {
  DeleteMaterialAnalysisMutationResult,
  DeleteMaterialAnalysisMutationVariables,
  DELETE_MATERIAL_ANALYSIS_MUTATION,
} from "../../../../api/graphql/mutation/delete-material-analysis"
import { ConfirmDialog } from "../../dialog/ConfirmDialog"

const iconSize = 24

interface IWerstoffAnalysisDetailDeleteButtonProps {
  id: string
  onCompleted: (id: string) => void
  onError: () => void
}

export const WerstoffAnalysisDetailDeleteButton: FunctionComponent<IWerstoffAnalysisDetailDeleteButtonProps> = (
  props,
) => {
  const { id, onCompleted, onError } = props
  const { t } = useTranslation()

  const [confirmDialogOpen, setConfirmDialogOpen] = useState<boolean>(false)

  const [deleteMutation, { loading }] = useMutation<
    DeleteMaterialAnalysisMutationResult,
    DeleteMaterialAnalysisMutationVariables
  >(DELETE_MATERIAL_ANALYSIS_MUTATION, {
    variables: { id },
    onCompleted: (data) => data.deleteMaterialAnalysis && onCompleted(data.deleteMaterialAnalysis[0]),
    onError,
  })

  const onDelete = useCallback(() => {
    deleteMutation()
    setConfirmDialogOpen(false)
  }, [deleteMutation])

  return (
    <Fragment>
      <ConfirmDialog
        open={confirmDialogOpen}
        text={t("analysis_detail.delete_dialog.text")}
        heading={t("analysis_detail.delete_dialog.heading")}
        confirmText={t("general.delete")}
        onConfirm={onDelete}
        onClose={() => setConfirmDialogOpen(false)}
      />
      <Button
        color="primary"
        onClick={() => setConfirmDialogOpen(true)}
        disabled={loading}
        fullWidth
        variant="outlined"
      >
        {!loading && t("general.delete")}
        {loading && <ClipLoader color="primary" size={iconSize - 4} />}
      </Button>
    </Fragment>
  )
}
