import React, { FunctionComponent, Fragment } from "react"
import { Theme, makeStyles, Typography, Grid } from "@material-ui/core"
import { useTranslation } from "react-i18next"
import lodash from "lodash"
import { TownWithAssociation } from "../../../api/graphql/query/towns-with-association"
import { UserAndPermissionsAssociation } from "../../../api/graphql/query/permitted-associations-for-user"

const useStyles = makeStyles((theme: Theme) => ({
  associationSection: {
    marginBottom: theme.spacing(4),
  },
  sectionHeading: {
    color: theme.palette.secondary.dark,
    marginBottom: theme.spacing(1),
    fontSize: 20,
  },
  sectionInfo: {
    color: theme.palette.secondary.dark,
    marginBottom: theme.spacing(1),
    fontSize: 14,
  },
}))

interface IAssociationSectionProps {
  association: UserAndPermissionsAssociation
}

export const AssociationSection: FunctionComponent<IAssociationSectionProps> = (props) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { association } = props

  const towns = lodash.get(association, "towns", []) as TownWithAssociation[]

  return (
    <Fragment>
      <section className={classes.associationSection}>
        <Typography className={classes.sectionHeading}>{association.name}</Typography>
        {props.children}
        {towns.length === 0 && (
          <Grid container>
            <Typography className={classes.sectionInfo}>{t("association_management.no_towns")}</Typography>
          </Grid>
        )}
      </section>
    </Fragment>
  )
}
