import React, { FunctionComponent, useMemo, useCallback, useState, useContext } from "react"
import { Theme, makeStyles, Grid, ListItemText, ListItem, Tooltip, IconButton } from "@material-ui/core"
import { useTranslation } from "react-i18next"
import { Report } from "../../../api/graphql/query/reports"
import moment from "moment"
import { useHistory } from "react-router"
import { CommunicationCenterContext } from "./CommunicationCenterContext"
import { Delete } from "@material-ui/icons"
import ReportDelete from "../../partials/report/ReportDelete"
import { CommunicationHistory } from "../report/CommunicationHistory"

const useStyles = makeStyles((theme: Theme) => ({
  listItem: {
    overflow: "hidden",
    whiteSpace: "nowrap",
  },
  container: {
    whiteSpace: "nowrap",
    flexWrap: "nowrap",
  },
}))

interface ICommunicationCenterReportListItemProps {
  report: Report
  expanded: boolean
  onClick: () => void
}

export const CommunicationCenterReportListItem: FunctionComponent<ICommunicationCenterReportListItemProps> = (
  props,
) => {
  const { report, expanded, onClick } = props
  const classes = useStyles()
  const { t } = useTranslation()
  const history = useHistory()
  const { fetchReports } = useContext(CommunicationCenterContext)

  const [deleteOpen, setDeleteOpen] = useState<boolean>(false)

  const date = useMemo(() => moment(report.createdAt).format("DD.MM.YYYY"), [report])
  const communicationHistory = useMemo(
    () => report.communicationHistory?.filter((historyEntry) => historyEntry !== null) || [],
    [report],
  ) as any

  const customOnClick = useCallback(() => {
    history.push(`/communication-center?id=${report.id}`)
    onClick()
  }, [onClick, history, report])

  return (
    <>
      <ReportDelete open={deleteOpen} report={report} onClose={() => setDeleteOpen(false)} onComplete={fetchReports} />

      <ListItem className={classes.listItem} button onClick={customOnClick}>
        <Grid container direction="row" className={classes.container}>
          <Grid item xs={expanded ? 2 : 12}>
            <Grid container direction="column">
              <ListItemText primary={report.title} secondary={date} />
            </Grid>
          </Grid>

          {expanded && (
            <Grid item xs={10} className={classes.container}>
              <Grid container>
                <Grid item xs>
                  <CommunicationHistory history={communicationHistory} />
                </Grid>
                <Grid item>
                  <Tooltip title={t("general.delete")}>
                    <IconButton aria-label="Löschen" onClick={() => setDeleteOpen(true)}>
                      <Delete />
                    </IconButton>
                  </Tooltip>
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
      </ListItem>
    </>
  )
}
