import React, { FunctionComponent, Fragment } from "react"
import { Theme, makeStyles, List } from "@material-ui/core"
import { useTranslation } from "react-i18next"
import { useQuery } from "react-apollo"
import {
  BookmarksForUserResult,
  BOOKMARKS_FOR_USER,
  AssociationWithBookmarks,
  Bookmark,
} from "../../../api/graphql/query/bookmarks-for-user"
import lodash from "lodash"
import { filterAssociationBookmarks } from "../analysis-filter/bookmark/helpers/filterAssociationBookmarks"
import { BookmarkForAssociationList } from "../analysis-filter/bookmark/BookmarkForAssociationList"
import { SectionLoadingIndicator } from "./SectionLoadingIndicator"
import { SectionNoDataIndicator } from "./SectionNoDataIndicator"

const useStyles = makeStyles((theme: Theme) => ({
  list: {
    //has to be this specific, so it lines up with the tourtable
    paddingTop: 3,
    paddingRight: 0,
    paddingBottom: 0,
    paddingLeft: 0,
  },
}))

interface IBookmarkSectionProps {
  onAction: (bookmark: Bookmark) => void
}

export const BookmarkSection: FunctionComponent<IBookmarkSectionProps> = (props) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const { onAction } = props

  const { data, loading, error } = useQuery<BookmarksForUserResult>(BOOKMARKS_FOR_USER, { fetchPolicy: "network-only" })

  if (error) {
    console.log(error)
  }

  const associations: AssociationWithBookmarks[] = lodash.get(data, "userAndPermissions.permittedAssociations")

  const filteredAssociations = associations && filterAssociationBookmarks(associations)

  return (
    <Fragment>
      {!loading && filteredAssociations && filteredAssociations.length > 0 && (
        <List className={classes.list}>
          {filteredAssociations.map((association) => (
            <BookmarkForAssociationList
              key={association.id}
              association={association}
              onSelectBookmark={onAction}
              selectedBookmark={null}
            />
          ))}
        </List>
      )}
      {loading && <SectionLoadingIndicator />}
      {!loading && (!filteredAssociations || filteredAssociations.length <= 0) && (
        <SectionNoDataIndicator>{t("bookmark.no_bookmarks_found")}</SectionNoDataIndicator>
      )}
    </Fragment>
  )
}
