import React, { FunctionComponent, useState, Fragment, useEffect } from "react"
import lodash from "lodash"
import { UserAndPermissionsTown } from "../../../api/graphql/query/permitted-associations-for-user"
import { TownExpansionPanel } from "../citizen-management/TownExpansionPanel"
import { RfidTableWrapper } from "./RfidTableWrapper"
import { RfidChip } from "../../../api/graphql/query/rfid-chip-for-town"
import { ManageRfidChipDialog } from "./dialogs/ManageRfidChipDialog"
import { DeleteRfidChipDialog } from "./dialogs/DeleteRfidChipDialog"

interface ITownExpansionPanelWithRfidTableProps {
  town: UserAndPermissionsTown
}

export const TownExpansionPanelWithRfidTable: FunctionComponent<ITownExpansionPanelWithRfidTableProps> = (props) => {
  const { town } = props
  // we use an extra variable for isOpen, so we can remove the town table from the dom (lazy loading for towns)
  // could also be used for lazy loading the details of an association, but since there arent that many, this isnt necessary
  const [open, setOpen] = useState<boolean>(false)

  // Town Management Dialog Stuff

  const [toggleRefetch, setToggleRefetch] = useState<boolean>(false)
  const [rfidChipToEdit, setRfidChipToEdit] = useState<RfidChip>()
  const [rfidChipToDelete, setRfidChipToDelete] = useState<RfidChip>()

  const onManagementDialogClose = () => {
    setRfidChipToEdit(undefined)
  }

  const onManagementDialogSuccess = () => {
    setToggleRefetch(!toggleRefetch)
    onManagementDialogClose()
  }

  const onDeleteDialogClose = () => {
    setRfidChipToDelete(undefined)
  }

  const onDeleteDialogSuccess = () => {
    setToggleRefetch(!toggleRefetch)
    onDeleteDialogClose()
  }

  return (
    <Fragment>
      {/* DIALOGS */}
      {!!rfidChipToEdit && (
        <ManageRfidChipDialog
          isOpen={!!rfidChipToEdit}
          onCloseDialog={() => setRfidChipToEdit(undefined)}
          rfidChip={rfidChipToEdit}
          onSuccess={onManagementDialogSuccess}
        />
      )}
      {!!rfidChipToDelete && (
        <DeleteRfidChipDialog
          isOpen={!!rfidChipToDelete}
          onCloseDialog={() => setRfidChipToDelete(undefined)}
          rfidChip={rfidChipToDelete}
          onSuccess={onDeleteDialogSuccess}
        />
      )}
      {/* VIEW */}
      <TownExpansionPanel open={open} toggle={() => setOpen(!open)} town={town}>
        <RfidTableWrapper
          setRfidChipToEdit={setRfidChipToEdit}
          setRfidChipToDelete={setRfidChipToDelete}
          toggleRefetch={toggleRefetch}
          town={town}
        />
      </TownExpansionPanel>
    </Fragment>
  )
}
