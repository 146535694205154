import React, { FunctionComponent, useState, useContext, useMemo } from "react"
import { useTranslation } from "react-i18next"
import { IGenericChannelTypeSectionProps } from "./ChannelTypeSectionTypes"
import { ChannelTypeSection, IChannelTypeAction } from "./ChannelTypeSection"
import { PostToExternalNewsfeedDialog } from "../dialogs/PostToExternalNewsfeedDialog"
import { ReportDetailContext } from "../context/ReportDetailContext"
import { WidgetType } from "../../../../api/graphql/generated/globalTypes"
import { WidgetExportsContext } from "../context/WidgetExportsContext"
import { useWidgetDownload } from "./hooks/useWidgetDownload"

interface IExternalTownChannelTypeSectionProps extends IGenericChannelTypeSectionProps {}

export const ExternalTownChannelTypeSection: FunctionComponent<IExternalTownChannelTypeSectionProps> = (props) => {
  const { t } = useTranslation()
  const { channelType, report, widgets } = props
  const { refetchReportDetails } = useContext(ReportDetailContext)
  const { widgetExportsDictionary } = useContext(WidgetExportsContext)

  const [sendDialogOpen, setSendDialogOpen] = useState<boolean>(false)
  const onDownload = useWidgetDownload(channelType, report)

  const actions: IChannelTypeAction[] = useMemo(() => {
    let disabled = false
    const textWidget = widgets.find((widget) => widget.widgetType === WidgetType.text)
    if (!textWidget || textWidget.textContent === "") {
      disabled = true
    }

    return [
      {
        name: t("general.send"),
        onClick: () => setSendDialogOpen(true),
        disabled,
      },
      {
        name: t("general.download"),
        onClick: onDownload,
        disabled,
      },
    ]
  }, [widgets, t, onDownload])

  return (
    <>
      {(report.filter.townIds || []).length === 1 && sendDialogOpen && (
        <PostToExternalNewsfeedDialog
          open={sendDialogOpen}
          onClose={() => setSendDialogOpen(false)}
          refetchReportDetails={refetchReportDetails}
          widgets={widgets}
          widgetExports={widgetExportsDictionary}
          townId={report.filter.townIds[0]}
          channelType={channelType}
          report={report}
        />
      )}
      <ChannelTypeSection widgets={widgets} channelType={channelType} report={report} actions={actions} />
    </>
  )
}
