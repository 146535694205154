import React, { FunctionComponent } from "react"
import { Theme, makeStyles, Grid } from "@material-ui/core"

const useStyles = makeStyles((theme: Theme) => ({
  rootGrid: {
    flexGrow: 1,
    padding: theme.spacing(2)
  }
}))

interface IAnalysisIndicatorProps {}

export const AnalysisIndicator: FunctionComponent<
  IAnalysisIndicatorProps
> = props => {
  const classes = useStyles()
  const { children } = props
  return (
    <Grid
      container
      className={classes.rootGrid}
      direction={"column"}
      justify={"center"}
      alignItems={"center"}
    >
      {children}
    </Grid>
  )
}
