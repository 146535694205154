import { gql } from "apollo-boost"
import { deleteRfidChip, deleteRfidChipVariables } from "../generated/deleteRfidChip"

export const DELETE_RFID_CHIP_MUTATION = gql`
  mutation deleteRfidChip($id: ID!) {
    deleteRfidChip(id: $id) {
      deletedCount
    }
  }
`
export type DeleteRfidChipMutationResult = deleteRfidChip
export type DeleteRfidChipMutationVariables = deleteRfidChipVariables
