import React, { FunctionComponent } from "react"
import { Theme, makeStyles, Grid, Typography } from "@material-ui/core"
import { useTranslation } from "react-i18next"
import {
  TrendGraphOverallIndicator,
  TrendGraphMinMaxIndicator,
  TrendGraphTrendIndicator,
} from "./WertstoffAnalysisTrendGraphTooltip"

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    width: 120,
  },
  typo: {
    color: (props: any) => props.color,
  },
}))

export const WertstoffAnalysisTrendGraphLegend: FunctionComponent<any> = (props) => {
  const { color, showTrend, town } = props
  const classes = useStyles({ color })
  const { t } = useTranslation()

  return (
    <Grid container spacing={1} alignContent="flex-start" className={classes.container}>
      <Grid item xs={2} container justify="center" alignContent="center">
        <TrendGraphOverallIndicator color={color} />
      </Grid>
      <Grid item xs={10}>
        <Typography className={classes.typo} align="left">
          {t("graph.trend.tooltip.overall")}
        </Typography>
      </Grid>
      <Grid item xs={2} container justify="center" alignContent="center">
        <TrendGraphMinMaxIndicator color={color} />
      </Grid>
      <Grid item xs={10}>
        <Typography className={classes.typo} align="left">
          {t("graph.trend.tooltip.max")}
        </Typography>
      </Grid>
      <Grid item xs={2} container justify="center" alignContent="center">
        <TrendGraphMinMaxIndicator color={color} />
      </Grid>
      <Grid item xs={10}>
        <Typography className={classes.typo} align="left">
          {t("graph.trend.tooltip.min")}
        </Typography>
      </Grid>
      {showTrend && (
        <>
          <Grid item xs={2} container justify="center" alignContent="center">
            <TrendGraphTrendIndicator color={color} />
          </Grid>
          <Grid item xs={10}>
            <Typography className={classes.typo} align="left">
              {t("graph.trend.tooltip.trend")}
            </Typography>
          </Grid>
        </>
      )}
      {!!town && (
        <>
          <Grid item xs={12} container justify="center" alignContent="center">
            {town}
          </Grid>
        </>
      )}
    </Grid>
  )
}
