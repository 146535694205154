import React, { FunctionComponent, Fragment, useContext } from "react"
import { Theme, makeStyles, Grid, Typography, Link, CircularProgress } from "@material-ui/core"
import { useTranslation } from "react-i18next"
import lodash from "lodash"
import { ManageUserContext } from "./context/ManageUserContext"
import { Done, NoEncryption } from "@material-ui/icons"
import {
  SendPasswordResetMailResult,
  SendPasswordResetMailVariables,
  SEND_PASSWORD_RESET_MAIL_MUTATION,
} from "../../../../api/graphql/mutation/send-password-reset-mail"
import { useMutation } from "react-apollo"
import { getGraphqlErrorCode } from "../../../../utils/get-graphql-error-code"
import { toast } from "react-toastify"

const useStyles = makeStyles((theme: Theme) => ({
  passwordInfoText: {
    fontSize: 14,
  },
  linkResetPassword: {
    cursor: "pointer",
  },
  progress: {
    display: "block",
  },
  passwordInfo: {
    marginBottom: theme.spacing(1),
  },
}))

interface IManageUserDialogResetPasswordProps {}

export const ManageUserDialogResetPassword: FunctionComponent<IManageUserDialogResetPasswordProps> = (props) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const { userAndPermissions, email } = useContext(ManageUserContext)

  const isPasswordSet: boolean = lodash.get(userAndPermissions, "user.isPasswordSet", false)

  const [sendPasswordResetMailMutation, { loading: sendPasswordResetMailMutationLoading }] = useMutation<
    SendPasswordResetMailResult,
    SendPasswordResetMailVariables
  >(SEND_PASSWORD_RESET_MAIL_MUTATION, {
    onError: (error) => {
      toast.error(t(`api_error.${getGraphqlErrorCode(error)}`))
    },
    onCompleted: (data) => {
      if (!lodash.get(data, "sendPasswordResetMail.error")) {
        toast.success("Email wurde gesendet.")
      }
    },
  })

  const onSendPasswordResetMail = async () => {
    await sendPasswordResetMailMutation({
      variables: { email },
    })
  }

  return (
    <Fragment>
      <Grid className={classes.passwordInfo} container item spacing={1} xs={12} alignItems="center">
        <Grid item>{isPasswordSet ? <Done /> : <NoEncryption />}</Grid>
        <Grid item>
          <Typography className={classes.passwordInfoText}>
            {isPasswordSet
              ? t("user_management.dialog.info_password_set")
              : t("user_management.dialog.info_password_not_set")}
          </Typography>
        </Grid>
      </Grid>
      <Grid container item xs={12} spacing={1} alignItems="center">
        <Grid item>
          <Link className={classes.linkResetPassword} onClick={onSendPasswordResetMail}>
            {t("user_management.dialog.link_reset_password")}
          </Link>
        </Grid>
        {sendPasswordResetMailMutationLoading && (
          <Grid item>
            <CircularProgress size={14} className={classes.progress} />
          </Grid>
        )}
      </Grid>
    </Fragment>
  )
}
