import { gql } from "apollo-boost"
import {
  unassignableAddresses,
  unassignableAddresses_unassignableAddresses,
  unassignableAddressesVariables,
} from "../generated/unassignableAddresses"

export const UNASSIGNABLE_ADDRESSES_QUERY = gql`
  query unassignableAddresses($townId: ID) {
    unassignableAddresses(townId: $townId) {
      id
      address
      comment
    }
  }
`

export type UnassignableAddressesQueryResult = unassignableAddresses
export type UnassignableAddress = unassignableAddresses_unassignableAddresses
export type UnassignableAddressesQueryVariables = unassignableAddressesVariables
