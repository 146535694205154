import { LegendProps } from "recharts"
import { Grid, makeStyles, Theme, Typography } from "@material-ui/core"
import { sortRating } from "../../../../domain/helper/CalcRating"

export const WasteQualityPieChartLegend = (props: LegendProps) => {
  const { payload } = props

  const sorted =
    payload?.map((item) => item).sort((a: any, b: any) => sortRating(a.payload.rating, b.payload.rating)) || []

  return (
    <>
      {sorted.map((item) => (
        <Grid container spacing={1} alignItems="center" alignContent="center">
          <Grid item>
            <div style={{ height: 10, width: 10, borderRadius: 90, background: item.color }} />
          </Grid>
          <Grid item>
            <Typography>{item.value}</Typography>
          </Grid>
        </Grid>
      ))}
    </>
  )
}
