import React, { Fragment, FunctionComponent, useCallback, useEffect, useMemo, useState } from "react"
import { Theme, makeStyles, Tabs, Tab, Grid } from "@material-ui/core"
import { useTranslation } from "react-i18next"
import { useQuery } from "react-apollo"
import {
  FractionMappingInput,
  LabelSetMaterialType,
  MappedFractionInput,
  TourType,
} from "../../../api/graphql/generated/globalTypes"
import { ClassifiersResult, CLASSIFIERS_QUERY } from "../../../api/graphql/query/classifiers"
import lodash from "lodash"
import i18n from "../../../utils/i18n"
import { FractionMappingTab } from "./FractionMappingTab"
import { TabPanel } from "../tabs/TabPanel"

const useStyles = makeStyles((theme: Theme) => ({
  item: {
    padding: theme.spacing(0.5),
    marginTop: theme.spacing(0.5),
  },
  tabs: {
    color: theme.palette.secondary.dark,
    background: theme.palette.secondary.light,
    borderTopLeftRadius: theme.spacing(1),
    borderTopRightRadius: theme.spacing(1),
  },
  tabPanel: {
    background: theme.palette.secondary.light,
    borderBottomLeftRadius: theme.spacing(1),
    borderBottomRightRadius: theme.spacing(1),
  },
}))

interface IFractionMappingDialogTabsProps {
  defaultFractionMapping?: FractionMappingInput[]
  defaultFractionMappingLoading: boolean
  onChange: (fractionMapping: FractionMappingInput[]) => void
  fractionMapping?: FractionMappingInput[]
}

export const FractionMappingDialogTabs: FunctionComponent<IFractionMappingDialogTabsProps> = (props) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const [currentTab, setCurrentTab] = useState<number>(0)

  const { defaultFractionMapping, fractionMapping, onChange } = props
  const { data, loading } = useQuery<ClassifiersResult>(CLASSIFIERS_QUERY)

  const fractionTranslations = useMemo(
    () =>
      lodash.uniqBy(
        data?.classifiers.flatMap((classifier) =>
          classifier.classes.flatMap((classifierClass) => classifierClass.translations),
        ),
        "en",
      ),
    [data],
  )

  const translation = useMemo(() => i18n.language.substring(0, 2), [])

  const onChangeInTab = useCallback(
    (tourType: TourType, mappedFraction: MappedFractionInput) => {
      const changedFractionMap = fractionMapping.find((fractionMap) => fractionMap.tourType === tourType) || {
        tourType,
        mappedFractions: [],
      }
      const unchangedFractionMaps = fractionMapping.filter((fractionMap) => fractionMap.tourType !== tourType)
      const indexToRemove = changedFractionMap.mappedFractions.findIndex(
        (_mappedFraction) => _mappedFraction.fraction === mappedFraction.fraction,
      )

      if (indexToRemove >= 0) {
        changedFractionMap.mappedFractions.splice(indexToRemove, 1)
      } else {
        changedFractionMap.mappedFractions.push(mappedFraction)
      }

      onChange([...unchangedFractionMaps, changedFractionMap])
    },
    [onChange, fractionMapping],
  )

  const getFractionMapForTourType = useCallback(
    (tourType: TourType) =>
      fractionMapping?.find((fractionMap) => fractionMap.tourType === tourType) ||
      defaultFractionMapping.find((fractionMap) => fractionMap.tourType === tourType),
    [fractionMapping, defaultFractionMapping],
  )

  const isDisabled = useCallback(
    (tourType: TourType) => !fractionMapping?.find((entry) => entry.tourType === tourType),
    [fractionMapping],
  )

  const onToggleCustomFractionMapping = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, tourType: TourType) => {
      const checked = event.target.checked
      const newFractionMaps = (fractionMapping || []).filter((fractionMap) => fractionMap.tourType !== tourType)
      if (checked) {
        const changedFractionMap = (defaultFractionMapping || []).find(
          (fractionMap) => fractionMap.tourType === tourType,
        )

        if (changedFractionMap) {
          newFractionMaps.push({
            ...changedFractionMap,
            mappedFractions: [
              ...changedFractionMap.mappedFractions.filter(
                (mappedFraction) => mappedFraction.mappedMaterialType === LabelSetMaterialType.main_fraction,
              ),
            ],
          })
        }
      }
      onChange([...newFractionMaps])
    },
    [onChange, defaultFractionMapping, fractionMapping],
  )

  return (
    <Fragment>
      <Grid item xs={12}>
        <Tabs
          value={currentTab}
          onChange={(_, value) => setCurrentTab(value)}
          variant="fullWidth"
          className={classes.tabs}
        >
          {Object.values(TourType).map((tourType) => (
            <Tab key={tourType} label={t(`tour_types.${tourType}`)} />
          ))}
        </Tabs>
      </Grid>
      <Grid item xs={12} className={classes.tabPanel}>
        {defaultFractionMapping &&
          Object.values(TourType).map((tourType, index) => (
            <TabPanel value={currentTab} index={index} key={`${tourType}-panel`}>
              <FractionMappingTab
                disabled={isDisabled(tourType)}
                fractionTranslations={fractionTranslations}
                fractions={defaultFractionMapping
                  .find((fractionMap) => fractionMap.tourType === tourType)
                  .mappedFractions.map((mappedFraction) => mappedFraction.fraction)}
                translation={translation}
                fractionMap={getFractionMapForTourType(tourType)}
                onChange={(mappedFraction) => onChangeInTab(tourType, mappedFraction)}
                toggleCustomFractionMapping={(event) => onToggleCustomFractionMapping(event, tourType)}
              />
            </TabPanel>
          ))}
      </Grid>
    </Fragment>
  )
}
