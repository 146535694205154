import React, { FunctionComponent, createContext, useState } from "react"

interface IRfidChipFilterContext {
  rfidSearchTerm: string
  setRfidSearchTerm: (_: string) => void
  uniquelyAssignableChecked: boolean | null
  setUniquelyAssignableChecked: (_: boolean | null) => void
}

export const RfidChipFilterContext = createContext<IRfidChipFilterContext>({
  rfidSearchTerm: "",
  setRfidSearchTerm: (_: string) => {},
  uniquelyAssignableChecked: false,
  setUniquelyAssignableChecked: (_: boolean | null) => {},
})

interface IRfidChipFilterContextProps {}

export const RfidChipFilterContextProvider: FunctionComponent<IRfidChipFilterContextProps> = (props) => {
  const [rfidSearchTerm, setRfidSearchTerm] = useState<string>("")
  const [uniquelyAssignableChecked, setUniquelyAssignableChecked] = useState<boolean | null>(null)

  return (
    <RfidChipFilterContext.Provider
      value={{ rfidSearchTerm, setRfidSearchTerm, uniquelyAssignableChecked, setUniquelyAssignableChecked }}
    >
      {props.children}
    </RfidChipFilterContext.Provider>
  )
}
