import React, { FunctionComponent, Fragment } from "react"
import { Theme, makeStyles, Grid, Typography } from "@material-ui/core"
import { useTranslation } from "react-i18next"
import { TopBar } from "../partials/heading/TopBar"
import { TextCategoryList } from "../partials/text-management/TextCategoryList"
import lodash from "lodash"
import queryString from "query-string"
import { useLocation } from "react-router"
import { TextTemplateView } from "../partials/text-management/TextTemplateView"
import { ViewHeadline } from "@material-ui/icons"
import { TextReplacementContextProvider } from "../../context/TextReplacementContext"

const useStyles = makeStyles((theme: Theme) => ({
  //this makes the view full height
  gridContainer: {
    flex: 1,
  },
  infoText: {
    color: theme.palette.secondary.main,
  },
}))

interface ITextManagementPageProps {}

export const TextManagementPage: FunctionComponent<ITextManagementPageProps> = (props) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const location = useLocation()
  const categoryId = lodash.get(queryString.parse(location.search), "category", "")

  return (
    <Fragment>
      <TextReplacementContextProvider>
        <TopBar>{t("text_management.heading")}</TopBar>
        <Grid container className={classes.gridContainer}>
          <Grid item xs={2}>
            <TextCategoryList selectedCategoryId={categoryId} />
          </Grid>
          {categoryId && (
            <Grid item xs={10}>
              <TextTemplateView selectedCategoryId={categoryId} />
            </Grid>
          )}
          {!categoryId && (
            <Grid item xs={10} container justify={"center"} alignContent={"center"} spacing={1}>
              <Grid item>
                <ViewHeadline className={classes.infoText} />
              </Grid>
              <Grid item>
                <Typography className={classes.infoText}>{t("text_management.please_choose_category")}</Typography>
              </Grid>
            </Grid>
          )}
        </Grid>
      </TextReplacementContextProvider>
    </Fragment>
  )
}
