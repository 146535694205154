import React, { FunctionComponent, useContext } from "react"
import { PageLoadingIndicator } from "../indicator/PageLoadingIndicator"
import { makeStyles, Theme } from "@material-ui/core"
import { ReportHeading } from "./ReportHeading"
import { ReportDetailContent } from "./ReportDetailContent"
import { ReportDetailContext } from "./context/ReportDetailContext"
import { CommunicationCenterContext } from "../communication-center/CommunicationCenterContext"

const useStyles = makeStyles((theme: Theme) => ({
  reportDetailDiv: {
    margin: theme.spacing(2),
  },
}))

interface IReportDetailProps {
  reportId: string
  refetchReports: () => void
}

export const ReportDetail: FunctionComponent<IReportDetailProps> = (props) => {
  const { fetchReports } = useContext(CommunicationCenterContext)
  const { loadingReportDetails, reportDetail, refetchAllReportData } = useContext(ReportDetailContext)
  const classes = useStyles()

  if (!loadingReportDetails && reportDetail) {
    return (
      <div className={classes.reportDetailDiv}>
        <ReportHeading report={reportDetail} refetchReports={fetchReports} />
        <ReportDetailContent report={reportDetail} refetchReportDetails={refetchAllReportData} />
      </div>
    )
  } else if (loadingReportDetails) {
    return <PageLoadingIndicator />
  }

  return <></>
}
