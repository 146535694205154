import React from "react"
import store from "store"
import jwt from "jsonwebtoken"
import dot from "../utils/dot-prop-immutable"
import lodash from "lodash"
import { AbilityName } from "../api/graphql/generated/globalTypes"

export const AuthContext = React.createContext()

class AuthProvider extends React.Component {
  state = { token: null }

  constructor(props) {
    super()
    this.isAuthenticated = this.isAuthenticated.bind(this)
    this.hasOneRequiredAbility = this.hasOneRequiredAbility.bind(this)
    this.isAdmin = this.isAdmin.bind(this)
    this.isAreaManager = this.isAreaManager.bind(this)
    this.isFractionImageAbility = this.isFractionImageAbility.bind(this)
    this.isHousingManagement = this.isHousingManagement.bind(this)
    this.isDenovoAdmin = this.isDenovoAdmin.bind(this)
    this.isManager = this.isManager.bind(this)
    this.handleTokenChange = this.handleTokenChange.bind(this)
    this.hasAccessToWasteComposition = this.hasAccessToWasteComposition.bind(this)

    const payload = jwt.decode(props.token)

    this.state = {
      token: props.token,
      abilities: payload ? dot.get(payload, "abilities", []) : [],
    }
  }

  handleTokenChange(token) {
    store.set("access_token", token)
    const payload = jwt.decode(token)

    this.setState({
      token: token,
      abilities: payload ? dot.get(payload, "abilities", []) : [],
      userId: payload ? dot.get(payload, "user_id", null) : null,
    })
  }

  isAuthenticated() {
    let authenticationStatus = !!this.state.token && this.state.token !== "" && this.state.token !== "logout"
    return authenticationStatus
  }

  hasOneRequiredAbility(abilites) {
    if (!lodash.isArray(abilites)) {
      throw new Error("Abilities has to be an Array!")
    }

    return abilites.map((ability) => this.state.abilities.indexOf(ability) >= 0).includes(true)
  }

  isAdmin() {
    return this.hasOneRequiredAbility([AbilityName.admin])
  }

  isFractionImageAbility() {
    return this.hasOneRequiredAbility([AbilityName.fraction_images])
  }

  isDenovoAdmin(user) {
    const hasDenovoEmail = lodash.get(user, "email") === "admin@denovo.at"
    return this.hasOneRequiredAbility([AbilityName.admin]) && hasDenovoEmail
  }

  isManager() {
    return this.hasOneRequiredAbility([AbilityName.manager, AbilityName.area_manager])
  }

  isAreaManager() {
    return this.hasOneRequiredAbility([AbilityName.admin, AbilityName.area_manager])
  }

  isHousingManagement() {
    return this.hasOneRequiredAbility([AbilityName.housing_management])
  }

  hasAccessToWasteComposition() {
    return this.hasOneRequiredAbility([AbilityName.admin, AbilityName.waste_composition])
  }

  getAbilities = () => {
    return this.state.abilities
  }

  getFirstname = () => {
    return "Benutzer"
  }

  logout = () => {
    store.remove("access_token")
    store.remove("language")
    this.props.client.cache.reset()

    this.setState({
      token: null,
      abilities: [],
    })
  }

  authin = (token) => {
    this.handleTokenChange(token)

    const payload = jwt.decode(token)
    this.setState({
      abilities: payload ? dot.get(payload, "abilities", []) : [],
    })
  }

  render() {
    return (
      <AuthContext.Provider
        value={{
          isAuthenticated: this.isAuthenticated,
          hasOneRequiredAbility: this.hasOneRequiredAbility,
          isAdmin: this.isAdmin,
          isFractionImageAbility: this.isFractionImageAbility,
          isAreaManager: this.isAreaManager,
          isDenovoAdmin: this.isDenovoAdmin,
          isManager: this.isManager,
          isHousingManagement: this.isHousingManagement,
          hasAccessToWasteComposition: this.hasAccessToWasteComposition,
          getFirstname: this.getFirstname,
          getAbilities: this.getAbilities,
          authin: this.authin,
          logout: this.logout,
          authenticated: !!this.state.token,
          userId: this.state.userId,
        }}
      >
        {this.props.children}
      </AuthContext.Provider>
    )
  }
}

const AuthConsumer = AuthContext.Consumer

export { AuthProvider, AuthConsumer }
