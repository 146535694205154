import React, { FunctionComponent, useCallback, useContext } from "react"
import {
  Theme,
  makeStyles,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
} from "@material-ui/core"
import { useTranslation } from "react-i18next"
import { WertstoffDetailContext } from "./WertstoffDetailContext"

const useStyles = makeStyles((theme: Theme) => ({
  content: {
    marginBottom: theme.spacing(3),
  },
}))

interface IWerstoffAnalysisInvalidateAnalysisDialogProps {
  open: boolean
  onClose: () => void
  onSave: () => void
}

export const WerstoffAnalysisInvalidateAnalysisDialog: FunctionComponent<IWerstoffAnalysisInvalidateAnalysisDialogProps> = (
  props,
) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { open, onClose, onSave } = props
  const { invalidReason, setInvalidReason } = useContext(WertstoffDetailContext)

  const customOnSave = useCallback(() => {
    onSave()
    onClose()
  }, [onSave, onClose])

  return (
    <Dialog open={open} onClose={onClose} maxWidth="xs" fullWidth>
      <DialogTitle>{t("analysis_detail.invalidate_analysis.title")}</DialogTitle>
      <DialogContent className={classes.content}>
        <TextField
          label={t("analysis_detail.invalidate_analysis.reason")}
          fullWidth
          value={invalidReason}
          onChange={(event) => setInvalidReason(event.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={onClose}>
          {t("general.cancel")}
        </Button>
        <Button variant="contained" color="primary" onClick={customOnSave}>
          {t("analysis_detail.invalidate_analysis.invalidate")}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
