import { gql } from "apollo-boost"
import { deleteTourData, deleteTourDataVariables } from "../generated/deleteTourData"

export const DELETE_TOUR_DATA = gql`
  mutation deleteTourData($tourId: ID!, $keepMaterialAnalysis: Boolean!) {
    deleteTourData(tourId: $tourId, keepMaterialAnalysis: $keepMaterialAnalysis) {
      deletedCountRfidReadings
      deletedCountMaterialAnalysis
    }
  }
`

export type DeleteTourDataResult = deleteTourData
export type DeleteTourDataVariables = deleteTourDataVariables
