import { gql } from "apollo-boost"
import {
  analyticsDocuments_materialAnalysis_materialAnalysis,
  analyticsDocuments,
  analyticsDocumentsVariables,
} from "../generated/analyticsDocuments"
import { FRAGMENT_MATERIAL_ANALYSIS_WITH_DOCUMENTS } from "../fragments/material-analysis-with-documents"

export const ANALYTICS_DOCUMENTS_QUERY = gql`
  query analyticsDocuments($ids: [ID!]!) {
    materialAnalysis(ids: $ids) {
      materialAnalysis {
        ...FragmentMaterialAnalysisWithDocuments
      }
    }
  }
  ${FRAGMENT_MATERIAL_ANALYSIS_WITH_DOCUMENTS}
`

export type MaterialAnalysisWithDocuments = analyticsDocuments_materialAnalysis_materialAnalysis
export type AnalyticsDocumentsQueryResult = analyticsDocuments
export type AnalyticsDocumentsQueryVariables = analyticsDocumentsVariables
