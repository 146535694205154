import { gql } from "apollo-boost"
import { deleteUser, deleteUserVariables } from "../generated/deleteUser"

export const DELETE_USER_MUTATION = gql`
  mutation deleteUser($id: ID!) {
    deleteUser(id: $id) {
      error
      message
    }
  }
`

export type DeleteUserResult = deleteUser
export type DeleteUserVariables = deleteUserVariables
