import React, { FunctionComponent, useEffect, Fragment, useContext } from "react"
import { Grid, Tooltip, Fab, Typography, useTheme, makeStyles, Theme } from "@material-ui/core"
import { useTranslation } from "react-i18next"
import { DefaultTable, IColumnHeader, EColumnHeaderType, createColumns, cellForRow } from "../table/DefaultTable"
import { useQuery } from "react-apollo"
import {
  TownsForAssociationResult,
  TOWNS_FOR_ASSOCIATION_QUERY,
  TownsForAssociationVariables,
  TownForAssociation,
} from "../../../api/graphql/query/towns-for-association"
import { Column, DerivedDataObject } from "react-table"
import lodash from "lodash"
import { Edit } from "@material-ui/icons"
import { ClipLoader } from "react-spinners"
import { UserAndPermissionsAssociation } from "../../../api/graphql/query/permitted-associations-for-user"
import { AuthContext } from "../../../context/AuthContext"
import { sortASC } from "../../../utils/sort"

const useStyles = makeStyles((theme: Theme) => ({
  tableContent: {
    fontSize: 15,
  },
}))

interface ITownTableForAssociationProps {
  association: UserAndPermissionsAssociation
  setTownToEdit: (town: TownForAssociation) => void
  toggleRefetch: boolean
  setTownsForAssociationToEdit: (town: TownForAssociation[]) => void
}

export const TownTableForAssociation: FunctionComponent<ITownTableForAssociationProps> = (props) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { association, setTownToEdit, toggleRefetch, setTownsForAssociationToEdit } = props
  const theme = useTheme()

  const { data, error, loading, refetch } = useQuery<TownsForAssociationResult, TownsForAssociationVariables>(
    TOWNS_FOR_ASSOCIATION_QUERY,
    {
      variables: { id: association.associationId },
      notifyOnNetworkStatusChange: true,
    },
  )

  const { isAdmin } = useContext(AuthContext)
  useEffect(() => {
    refetch()
  }, [toggleRefetch, refetch])

  const allTowns: TownForAssociation[] = lodash.get(data, "association.towns", [])
  const permittedTowns = allTowns
    .filter((town) => association.towns.some((permittedTown) => permittedTown.id === town.id))
    .sort((a, b) => sortASC(a.name.toLowerCase(), b.name.toLowerCase()))

  useEffect(() => {
    permittedTowns && setTownsForAssociationToEdit(permittedTowns)
    // eslint-disable-next-line
  }, [setTownsForAssociationToEdit])

  if (error) {
    console.log(error)
  }

  const columnHeaders: IColumnHeader[] = [
    {
      title: t("town.property.name"),
      type: EColumnHeaderType.STRING,
      accessor: "name",
    },
    {
      title: t("town.property.latitude"),
      type: EColumnHeaderType.STRING,
      accessor: "latitude",
    },
    {
      title: t("town.property.longitude"),
      type: EColumnHeaderType.STRING,
      accessor: "longitude",
    },
    {
      title: t("town.property.radius"),
      type: EColumnHeaderType.STRING,
      accessor: "radius",
    },
  ]

  const columns: Column[] = createColumns(
    columnHeaders
      .map((column) => {
        return {
          Header: column.title,
          accessor: column.accessor,
          Cell: ({ row }: { row: DerivedDataObject }) => {
            return cellForRow(column, row)
          },
        }
      })
      .concat(
        lodash.compact([
          isAdmin()
            ? {
                Header: t("town.property.classification"),
                accessor: "displayOnlyAutomaticAnalysis",
                Cell: ({ row }: { row: DerivedDataObject }) => {
                  switch (row._original.displayOnlyAutomaticAnalysis) {
                    case true:
                      return (
                        <Typography className={classes.tableContent}>
                          {t("town.property.classification_auto")}
                        </Typography>
                      )
                    case false:
                      return (
                        <Typography className={classes.tableContent}>
                          {t("town.property.classification_manually")}
                        </Typography>
                      )
                    default:
                      return (
                        <Typography className={classes.tableContent}>
                          {t("town.property.classification_unset")}
                        </Typography>
                      )
                  }
                },
              }
            : null,
          {
            Header: t("general.rating_schema"),
            accessor: "customRatingSchema",
            Cell: ({ row }: { row: DerivedDataObject }) =>
              row._original.ratingSchema ? t("general.custom") : t("general.default"),
          },
          {
            Header: "",
            accessor: "",
            Cell: ({ row }: { row: DerivedDataObject }) => {
              return (
                <Grid container item direction="row" spacing={2} justify="flex-end">
                  <Grid item>
                    <Tooltip title={t("general.edit")}>
                      <Fab size="small" color="primary" onClick={() => setTownToEdit(row._original)}>
                        <Edit />
                      </Fab>
                    </Tooltip>
                  </Grid>
                </Grid>
              )
            },
          },
        ]),
      ),
  )

  const minRows = permittedTowns ? permittedTowns.length : 0

  return (
    <Fragment>
      {/* VIEW */}
      {minRows > 0 && (
        <DefaultTable
          columns={columns}
          loading={loading}
          data={permittedTowns}
          minRows={minRows}
          showPagination={false}
        />
      )}
      {loading && minRows <= 0 && (
        <Grid container justify="center">
          <ClipLoader color={theme.palette.primary.main} />
        </Grid>
      )}
      {minRows === 0 && !loading && (
        <Grid container justify="center">
          <Typography>{t("association_management.no_towns")}</Typography>
        </Grid>
      )}
    </Fragment>
  )
}
