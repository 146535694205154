import React, { FunctionComponent } from "react"
import { Theme, makeStyles, useTheme } from "@material-ui/core"
import { useTranslation } from "react-i18next"

interface IStyle {
  color: string
  disableFontSize: boolean
}

const useStyles = makeStyles((theme: Theme) => ({
  badge: {
    fontSize: (props: IStyle) => (!props.disableFontSize ? 11 : undefined),
    width: "min-content",
    border: (props: IStyle) => `1px solid ${props.color}`,
    padding: 1,
    paddingLeft: 2,
    paddingRight: 2,
    borderRadius: 2,
    lineHeight: 1,
    color: (props: IStyle) => props.color,
    transition: "all 300ms linear",
  },
}))

interface IAdminBadgeProps {
  color?: string
  disableFontSize?: boolean
  activeColor?: string
  active?: boolean
  denovoMode?: boolean
}

export const AdminBadge: FunctionComponent<IAdminBadgeProps> = (props) => {
  const { color, activeColor, active, disableFontSize, denovoMode } = props
  const theme = useTheme()

  const denovoColor = theme.palette.info.main
  const defaultBadgeColor = denovoMode === true ? denovoColor : color ? color : theme.palette.primary.main
  const activeBadgeColor = activeColor ? activeColor : theme.palette.common.white
  const badgeColor = !!active ? activeBadgeColor : defaultBadgeColor

  const classes = useStyles({
    color: badgeColor,
    disableFontSize: !!disableFontSize,
  })
  const { t } = useTranslation()

  return <div className={classes.badge}>{!denovoMode ? t("admin") : t("denovo").toUpperCase()}</div>
}
