import { createEditorItem, EditorItemType } from "../EditorItem"
import { TextPlaceholderIndicator } from "../../../../../domain/models/TextPlaceholders"

export const generateEditorItemsFromText = (text?: string) => {
  //use this regex once firefox and safari support positive lookbehind
  ///(?<=\$\w*\$)|(?=\$\w*\$)|(?<=\$-*\$)|(?=\$-*\$)/
  const splitText = text?.split(/(?=\$[\w-]*\$)/g) || []

  //throw this out :(
  const formattedSplitText: string[] = []
  splitText.forEach((item) => {
    if (item.includes(TextPlaceholderIndicator)) {
      const splitItem = item.split(TextPlaceholderIndicator)
      formattedSplitText.push(`${TextPlaceholderIndicator}${splitItem[0]}${splitItem[1]}${TextPlaceholderIndicator}`)
      formattedSplitText.push(splitItem[2].trim())
    } else {
      formattedSplitText.push(item.trim())
    }
  })

  return formattedSplitText
    .map((item) => {
      if (item.startsWith(TextPlaceholderIndicator) && item.endsWith(TextPlaceholderIndicator)) {
        return createEditorItem(EditorItemType.PLACEHOLDER, item)
      } else {
        return createEditorItem(EditorItemType.FREETEXT, item)
      }
    })
    .filter((item) => item.value !== "")
}
