import { useCallback, useMemo } from "react"
import { useQuery } from "react-apollo"
import {
  DetailedUserAndPermissionsAssociation,
  DetailedUserAndPermissionsResult,
  DETAILED_USER_AND_PERMISSIONS_QUERY,
} from "../../../../api/graphql/query/detailed-user-and-permissions"
import lodash from "lodash"

export const useUserAndPermissions = (userAndPermissionsRequiredInRoutes: string[]) => {
  const { data: userAndPermissionsData, loading: userAndPermissionsLoading, error: userAndPermissionsError } = useQuery<
    DetailedUserAndPermissionsResult
  >(DETAILED_USER_AND_PERMISSIONS_QUERY, {
    skip: !userAndPermissionsRequiredInRoutes.some((route) => location.pathname.startsWith(route)),
  })

  if (userAndPermissionsError) {
    console.log(userAndPermissionsError)
  }

  const user = useMemo(() => lodash.get(userAndPermissionsData, "userAndPermissions.user", null), [
    userAndPermissionsData,
  ])

  const permittedAssociations = useMemo(
    () =>
      lodash.get(
        userAndPermissionsData,
        "userAndPermissions.permittedAssociations",
        null,
      ) as DetailedUserAndPermissionsAssociation[],
    [userAndPermissionsData],
  )

  const getInitialValues = useCallback(() => {
    const defaultAssociationId = lodash.get(
      userAndPermissionsData,
      "userAndPermissions.user.defaultTown.association.id",
    )
    //could be shortened, but its more readable this way
    //ASSOCIATION ID
    const fallbackAssociation = permittedAssociations[0]
    const defaultAssociation =
      permittedAssociations.find((permAssociation) => permAssociation.associationId === defaultAssociationId) || null
    const selectedAssociation = defaultAssociation || fallbackAssociation

    //TOWN ID
    const fallbackTownId = selectedAssociation?.accessToAllTowns ? null : selectedAssociation?.towns[0]?.id
    const defaultTownId = lodash.get(userAndPermissionsData, "userAndPermissions.user.defaultTown.id", null)
    const selectedTownId = defaultTownId || fallbackTownId

    return {
      associationId: selectedAssociation?.associationId,
      townId: selectedTownId,
    }
  }, [userAndPermissionsData, permittedAssociations])

  return { user, permittedAssociations, getInitialValues, userAndPermissionsLoading, userAndPermissionsError }
}
