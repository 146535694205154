import { gql } from "apollo-boost"
import { deleteWidget, deleteWidgetVariables } from "../generated/deleteWidget"

export const DELETE_WIDGET_MUTATION = gql`
  mutation deleteWidget($id: ID!) {
    deleteWidget(id: $id)
  }
`

export type DeleteWidgetResult = deleteWidget
export type DeleteWidgetVariables = deleteWidgetVariables
