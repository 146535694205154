import { gql } from "apollo-boost"
import {
  updateWidgetTextTemplate,
  updateWidgetTextTemplateVariables
} from "../generated/updateWidgetTextTemplate"

export const UPDATE_WIDGET_TEMPLATE_MUTATION = gql`
  mutation updateWidgetTextTemplate(
    $id: ID!
    $template: WidgetTextTemplateInputUpdate!
  ) {
    updateWidgetTextTemplate(id: $id, template: $template) {
      id
    }
  }
`

export type UpdateWidgetTextTemplateResult = updateWidgetTextTemplate
export type UpdateWidgetTextTemplateVariables = updateWidgetTextTemplateVariables
