import React, { FunctionComponent, useCallback } from "react"
import { Theme, makeStyles, Button } from "@material-ui/core"
import { useTranslation } from "react-i18next"
import { useLazyQuery } from "react-apollo"
import {
  unassignableAddressesXLSXResult,
  unassignableAddressesXLSXVariables,
  UNASSIGNABLE_ADDRESSES_XLSX_FOR_TOWN_QUERY,
} from "../../../../api/graphql/query/export-unassignable-addresses-for-town"

const useStyles = makeStyles((theme: Theme) => ({
  exportContainer: {
    display: "flex",
    justifyContent: "flex-end",
  },
}))

interface IUnassignableAddressesExportButtonProps {
  onClose: () => void
  townId: string
  townName: string
}

export const UnassignableAddressesExportButton: FunctionComponent<IUnassignableAddressesExportButtonProps> = (
  props,
) => {
  const { onClose, townId, townName } = props
  const classes = useStyles()
  const { t } = useTranslation()

  const downloadExportedExcel = (data: any) => {
    const base64 = data?.unassignableAddressesXLSXForTown?.base64

    if (base64) {
      saveAs(new Blob([Buffer.from(base64, "base64")]), `unassignable-addresses-${townName}.xlsx`)
    }
    onClose()
  }

  const [unassignableAddressesExcelExportQuery, { loading }] = useLazyQuery<
    unassignableAddressesXLSXResult,
    unassignableAddressesXLSXVariables
  >(UNASSIGNABLE_ADDRESSES_XLSX_FOR_TOWN_QUERY, {
    fetchPolicy: "no-cache",
    onCompleted: downloadExportedExcel,
    onError: console.log,
  })

  const onExportButtonClicked = useCallback(
    (event: any) => {
      unassignableAddressesExcelExportQuery({
        variables: {
          townId,
        },
      })
    },
    [unassignableAddressesExcelExportQuery, townId],
  )

  return (
    <div className={classes.exportContainer}>
      <Button color="primary" variant="outlined" onClick={onExportButtonClicked} disabled={loading}>
        {t("rfid_management.unassignable_addresses.dialog.export_addresses")}
      </Button>
    </div>
  )
}
