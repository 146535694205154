import React, { FunctionComponent, useContext, useMemo } from "react"
import { useTranslation } from "react-i18next"
import { useTextFilter } from "../../../context/TextFilterContext"
import { FilterDropDown } from "./FilterDropDown"
import { PermittedAssociationsContext } from "../../../context/PermittedAssociationsContext"

interface IAssociationFilterProps {
  onChange?: Function
  style?: any
}

export const AssociationFilter: FunctionComponent<IAssociationFilterProps> = (props) => {
  const { onChange } = props
  const { t } = useTranslation()
  const { setAssociation, associationId } = useTextFilter()
  const { permittedAssociations } = useContext(PermittedAssociationsContext)

  const items = useMemo(() => {
    return permittedAssociations.map((association) => ({
      key: association.associationId,
      value: association.name,
    }))
  }, [permittedAssociations])

  return (
    <FilterDropDown
      style={props.style}
      items={items}
      selectedItem={associationId || ""}
      onChange={(id: string) => {
        setAssociation(id)
        onChange && onChange()
      }}
      keyPrefix="associations"
      title={t("tour.property.association")}
      noSelectionEnabled={false}
    />
  )
}
