import React, { FunctionComponent, Fragment, useState, useEffect, useCallback } from "react"
import {
  Theme,
  makeStyles,
  Card,
  CardHeader,
  IconButton,
  CardContent,
  Grid,
  Tooltip,
  Divider,
  Typography,
} from "@material-ui/core"
import { useTranslation } from "react-i18next"
import { Add } from "@material-ui/icons"
import { PageLoadingIndicator } from "../indicator/PageLoadingIndicator"
import { WidgetTextTemplate } from "../../../api/graphql/query/widget-text-templates-for-category"
import { TextTemplateCard } from "./TextTemplateCard"
import { ManageTemplateDialog } from "./dialogs/ManageTemplateDialog"
import lodash from "lodash"
import { WidgetTextCategory } from "../../../api/graphql/query/widget-text-categories"
import { Rating } from "../../../api/graphql/generated/globalTypes"

const useStyles = makeStyles((theme: Theme) => ({
  infoText: {
    color: theme.palette.secondary.main,
  },
  infoTextSection: {
    minHeight: theme.spacing(20),
  },
}))

interface ITextCategoryCardProps {
  ratingKey: Rating
  category: WidgetTextCategory
  templates: WidgetTextTemplate[]
  loading: boolean
  refetch: () => void
}

export const TextCategoryCard: FunctionComponent<ITextCategoryCardProps> = (props) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { ratingKey, templates, loading, category, refetch } = props
  const [manageTemplateDialogOpen, setManageTemplateDialogOpen] = useState<boolean>(false)
  const [templateToEdit, setTemplateToEdit] = useState<WidgetTextTemplate | null>(null)

  const isBulletinBoard = useCallback(() => {
    if (category.name === "Schwarzes Brett") {
      return true
    }
    return false
  }, [category])

  useEffect(() => {
    setManageTemplateDialogOpen(!lodash.isNil(templateToEdit))
  }, [templateToEdit])

  const onSuccessManageTemplateDialogOpen = () => {
    refetch()
    onCloseManageTemplateDialogOpen()
  }

  const onCloseManageTemplateDialogOpen = () => {
    setManageTemplateDialogOpen(false)
    setTemplateToEdit(null)
  }

  return (
    <Fragment>
      {/* DIALOGS */}
      {manageTemplateDialogOpen && (
        <ManageTemplateDialog
          open={manageTemplateDialogOpen}
          onClose={onCloseManageTemplateDialogOpen}
          onSuccess={onSuccessManageTemplateDialogOpen}
          category={category}
          template={templateToEdit}
          ratingKey={ratingKey}
        />
      )}
      {/* VIEW */}
      <Card elevation={0}>
        <CardHeader
          title={t(`waste_quality.${ratingKey}`)}
          action={
            !isBulletinBoard() && (
              <Tooltip title={t("text_management.add_template")}>
                <IconButton onClick={() => setManageTemplateDialogOpen(true)}>
                  <Add />
                </IconButton>
              </Tooltip>
            )
          }
        />
        <Divider variant={"middle"} />
        <CardContent>
          {loading && <PageLoadingIndicator />}
          <Grid container spacing={2}>
            {!loading &&
              templates.map((template) => (
                <Grid key={template.id} item xs={4}>
                  <TextTemplateCard
                    refetch={refetch}
                    template={template}
                    setTemplateToEdit={setTemplateToEdit}
                    rating={ratingKey}
                    noDuplicates={isBulletinBoard()}
                  />
                </Grid>
              ))}
          </Grid>
          {!loading && templates.length <= 0 && (
            <Grid
              item
              xs={12}
              container
              justify={"center"}
              alignContent={"center"}
              spacing={1}
              className={classes.infoTextSection}
            >
              <Typography className={classes.infoText}>{t("text_management.no_templates_available")}</Typography>
            </Grid>
          )}
        </CardContent>
      </Card>
    </Fragment>
  )
}
