import React, { Fragment, FunctionComponent, useContext, useState, useEffect, useCallback, useMemo } from "react"
import { Grid, Button, makeStyles, Theme } from "@material-ui/core"
import { useTranslation } from "react-i18next"
import { AnalysisFilterContext } from "../../../context/AnalysisFilterContext"
import { Bookmark } from "../../../api/graphql/query/bookmarks-for-user"
import { BookmarkDrawer } from "./bookmark/BookmarkDrawer"
import { AnalysisFilterHeader } from "./AnalysisFilterHeader"
import lodash from "lodash"
import WertstoffDropdown from "../controls/WertstoffDropdown.jsx"
import { Rating, TourType } from "../../../api/graphql/generated/globalTypes"
import { getFilterDatesWithEmptyingBounds } from "./helpers/getFilterDates"
import { WertstoffDatePicker } from "../controls/WertstoffDatePicker"
import moment, { Moment } from "moment"
import { preprocessPercentage } from "./helpers/preprocessPercentage"
import WertstoffTextfield from "../controls/WertstoffTextfield"
import { getEmptyingOptions } from "./helpers/getEmptyingOptions"
import { DetailedUserAndPermissionsAssociation } from "../../../api/graphql/query/detailed-user-and-permissions"
import { getLocalizeableRating } from "../../../domain/helper/CalcRating"
import { TFunction } from "i18next"
import { getInitialAnalysisFilterDates } from "../../../custom-hooks/useFilter"

const useStyles = makeStyles((theme: Theme) => ({
  applyButton: {
    marginBottom: theme.spacing(1),
  },
}))

const getRatingOptions = (t: TFunction) => [
  { id: Rating.High, name: t(getLocalizeableRating(Rating.High)) },
  { id: Rating.Medium, name: t(getLocalizeableRating(Rating.Medium)) },
  { id: Rating.Low, name: t(getLocalizeableRating(Rating.Low)) },
  { id: Rating.Unknown, name: t(getLocalizeableRating(Rating.Unknown)) },
]

export const getTourTypeOptions = (t: TFunction) => [
  {
    id: TourType.residual,
    name: t("analysis_page.filter_tourtype_residual"),
  },
  {
    id: TourType.organic,
    name: t("analysis_page.filter_tourtype_organic"),
  },
]

interface IAnalysisFilterProps {}

export const AnalysisFilter: FunctionComponent<IAnalysisFilterProps> = (props) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const {
    permittedAssociations: queryPermittedAssociations,
    filterModel,
    setApplyFilter,
    selectedAssociation,
    updateFilterWithBookmark,
    selectedBookmark,
    setSelectedBookmark,
    selectedEmptying,
    emptyings,
    emptyingsLoading,
    classifierFractions,
  } = useContext(AnalysisFilterContext)

  // bookmark
  const [bookmarkDrawerOpen, setBookmarkDrawerOpen] = useState<boolean>(false)
  // this is done so we dont have to deal with null values
  const permittedAssociations = useMemo(() => (queryPermittedAssociations ? queryPermittedAssociations : []), [
    queryPermittedAssociations,
  ])

  const ratingOptions = useMemo(() => getRatingOptions(t), [t])
  const tourTypeOptions = useMemo(() => getTourTypeOptions(t), [t])

  const emptyingOptions = useMemo(() => getEmptyingOptions(emptyings, t), [emptyings, t])

  const onCloseBookmarkDrawer = useCallback(() => {
    setBookmarkDrawerOpen(false)
  }, [setBookmarkDrawerOpen])

  const onSelectBookmark = useCallback(
    (bookmark: Bookmark | null) => {
      updateFilterWithBookmark(bookmark)
      setSelectedBookmark(bookmark)
      onCloseBookmarkDrawer()
    },
    [updateFilterWithBookmark, setSelectedBookmark, onCloseBookmarkDrawer],
  )

  const handleChangeAssociation = useCallback(
    (event: any) => {
      filterModel.setAssociationId(event.target.value)
      const newAssociation =
        permittedAssociations.find(
          (association: DetailedUserAndPermissionsAssociation) => association.associationId === event.target.value,
        ) || ({} as DetailedUserAndPermissionsAssociation)
      if (!newAssociation.accessToAllTowns) {
        filterModel.setTownIds([newAssociation.towns[0].id])
      } else {
        filterModel.setTownIds(null)
      }
      filterModel.setEmptyingId(null)
      filterModel.setTourDate(null)
    },
    [filterModel, permittedAssociations],
  )

  const handleChangeTown = useCallback(
    (event: any) => {
      const selectedOptions = event.target.value
      if (selectedOptions.includes(undefined)) {
        filterModel.setTownIds(null)
      } else {
        filterModel.setTownIds(event.target.value.length === 0 ? null : event.target.value)
      }
      filterModel.setEmptyingId(null)
      filterModel.setTourDate(null)
    },
    [filterModel],
  )

  const handleChangeEmptying = useCallback(
    (event: any) => {
      const newEmptyingId = event.target.value ?? null
      filterModel.setEmptyingId(newEmptyingId)
      filterModel.setTourDate(null)

      if (!lodash.isString(newEmptyingId)) {
        const { dateFrom, dateUntil } = getInitialAnalysisFilterDates()
        filterModel.setDateFrom(dateFrom)
        filterModel.setDateUntil(dateUntil)
        return
      }

      const newSelectedEmptying = emptyings.find((emptying) => emptying?.id === newEmptyingId)
      console.log(newSelectedEmptying)
      const startDate = moment(newSelectedEmptying?.startDate)

      if (startDate.isValid()) {
        filterModel.setDateFrom(startDate.toDate())
        let endDate: Moment | null = startDate.add(6, "days")
        filterModel.setDateUntil(endDate?.toDate() ?? null)
      } else {
        filterModel.setDateFrom(null)
        filterModel.setDateUntil(null)
      }
    },
    [filterModel, emptyings],
  )

  const { minDateFrom, minDateUntil, maxDateFrom, maxDateUntil, disableFuture } = useMemo(
    () => getFilterDatesWithEmptyingBounds(filterModel.dateUntil, filterModel.dateFrom, selectedEmptying),
    [filterModel.dateUntil, filterModel.dateFrom, selectedEmptying],
  )

  //this effect is needed for when the user switches back to this page from another without reloading the site
  useEffect(() => {
    setApplyFilter(true)
  }, [setApplyFilter])

  return (
    <Fragment>
      {/* BOOKMARK DRAWER */}
      <BookmarkDrawer
        open={bookmarkDrawerOpen}
        onClose={onCloseBookmarkDrawer}
        selectedBookmark={selectedBookmark}
        onSelectBookmark={onSelectBookmark}
      />
      {/* VIEW */}
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <AnalysisFilterHeader
            onOpenBookmarkDrawer={() => setBookmarkDrawerOpen(true)}
            selectedBookmark={selectedBookmark}
            onSelectBookmark={onSelectBookmark}
          />
        </Grid>
        <Grid item xs={2}>
          <WertstoffDropdown
            onChange={(event: any) => filterModel.setTourType(event.target.value)}
            value={filterModel.tourType}
            values={tourTypeOptions}
            allPossible={false}
            name={t("analysis_page.filter_tourtype_name")}
            margin="none"
          />
        </Grid>
        <Grid item xs={2}>
          <WertstoffDropdown
            onChange={handleChangeAssociation}
            value={filterModel.associationId}
            values={permittedAssociations}
            allPossible={false}
            name={t("analysis_page.filter.association")}
            customKey={"associationId"}
            margin="none"
          />
        </Grid>
        <Grid item xs={2}>
          <WertstoffDropdown
            onChange={handleChangeTown}
            multiple
            value={filterModel.townIds ?? []}
            values={lodash.get(selectedAssociation, "towns", [])}
            allPossible={!!lodash.get(selectedAssociation, "accessToAllTowns")}
            disabled={lodash.isNil(selectedAssociation)}
            name={t("analysis_page.filter.town")}
            margin="none"
          />
        </Grid>
        <Grid item xs={2}>
          <WertstoffDropdown
            onChange={handleChangeEmptying}
            value={filterModel.emptyingId ?? ""}
            values={emptyingOptions}
            name={t("analysis_page.filter.empty_week")}
            disabled={emptyingsLoading}
            allTranslationKey="analysis_page.filter.empty_week_last_10_days"
            margin="none"
          />
        </Grid>
        <Grid item xs={2}>
          <WertstoffDatePicker
            name={t("analysis_page.filter.period_from")}
            disableFuture={disableFuture}
            minDate={minDateFrom}
            maxDate={maxDateFrom}
            isClearable={false}
            value={filterModel.dateFrom && moment(filterModel.dateFrom).toISOString()}
            onChange={(date) => {
              filterModel.setDateFrom(date?.startOf("day").toDate() ?? null)
            }}
            helperText={filterModel.emptyingId ? t("analysis_page.adjusted_date_to_emptying") : undefined}
            margin="none"
          />
        </Grid>
        <Grid item xs={2}>
          <WertstoffDatePicker
            name={t("analysis_page.filter.period_to")}
            disableFuture={disableFuture}
            minDate={minDateUntil}
            maxDate={maxDateUntil}
            value={filterModel.dateUntil && moment(filterModel.dateUntil).toISOString()}
            onChange={(date) => filterModel.setDateUntil(date?.endOf("day").toDate() ?? null)}
            helperText={filterModel.emptyingId ? t("analysis_page.adjusted_date_to_emptying") : undefined}
            margin="none"
          />
        </Grid>
        <Grid item xs={2}>
          <WertstoffDropdown
            onChange={(event: any) => {
              const newFractionType: string | null = event.target.value ?? null
              filterModel.setFractionType(newFractionType)
              if (lodash.isNil(newFractionType)) {
                filterModel.setFractionFrom(null)
                filterModel.setFractionTo(null)
              }
            }}
            translate
            value={filterModel.fractionType}
            values={classifierFractions}
            name={t("analysis_page.filter.waste_type")}
            translatemargin="none"
            margin="none"
          />
        </Grid>
        <Grid item xs={2}>
          <WertstoffTextfield
            debounce
            onChange={(event) => filterModel.setFractionFrom(event.target.value)}
            value={filterModel.fractionFrom ? Math.round(parseFloat(filterModel.fractionFrom) * 10000) / 100 : null}
            disabled={lodash.isNil(filterModel.fractionType)}
            name={t("analysis_page.filter.percent_of")}
            preprocessor={preprocessPercentage}
            adornment="%"
            margin="none"
          />
        </Grid>
        <Grid item xs={2}>
          <WertstoffTextfield
            debounce
            onChange={(event) => filterModel.setFractionTo(event.target.value)}
            value={filterModel.fractionTo ? Math.round(parseFloat(filterModel.fractionTo) * 10000) / 100 : null}
            disabled={lodash.isNil(filterModel.fractionType)}
            name={t("analysis_page.filter.percent_to")}
            preprocessor={preprocessPercentage}
            adornment="%"
            margin="none"
          />
        </Grid>
        <Grid item xs={2}>
          <WertstoffTextfield
            debounce
            onChange={(event) => filterModel.setRfids(event.target.value)}
            value={filterModel.rfids || ""}
            name={t("analysis_page.filter.rfids")}
            margin="none"
          />
        </Grid>
        <Grid item xs={2}>
          <WertstoffDropdown
            onChange={(event: any) => filterModel.setRating(event.target.value ?? undefined)}
            value={filterModel.rating}
            values={ratingOptions}
            name={t("analysis_page.filter.rating")}
            margin="none"
          />
        </Grid>
        <Grid item xs={2} container alignContent="flex-end" alignItems="flex-end">
          <Button
            color="primary"
            variant="contained"
            onClick={() => setApplyFilter(true)}
            fullWidth
            className={classes.applyButton}
          >
            {t("general.apply")}
          </Button>
        </Grid>
      </Grid>
    </Fragment>
  )
}
