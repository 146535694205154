import i18n, { Resource } from "i18next"
import store from "store"
import { initReactI18next } from "react-i18next"
import LngDetector from "i18next-browser-languagedetector"
import moment from "moment"
import { config } from "./config"
import { translations } from "../translations/translations"

export const I18N_LOCAL_STORAGE = "i18nextLng"

const defaultLanguages = ["de", "en", "sl"]

export const AVAILABLE_LANGUAGES = config.REACT_APP_ACTIVE_LANGUAGES
  ? config.REACT_APP_ACTIVE_LANGUAGES.split(",")
  : defaultLanguages

const resources: Resource = {}

AVAILABLE_LANGUAGES.forEach((langKey) => {
  if (translations[langKey]) {
    resources[langKey] = {
      translation: translations[langKey],
    }
  }
})

i18n
  .use(LngDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: store.get("language") || "de",
    interpolation: {
      escapeValue: false,
    },
    detection: {
      order: ["querystring", "cookie", "localStorage", "navigator", "htmlTag", "path", "subdomain"],
      lookupLocalStorage: I18N_LOCAL_STORAGE,
    },
  })

export const changeLanguage = (lng: string) => {
  store.set("language", lng)
  updateMomentLocale(lng)
  return i18n.changeLanguage(lng)
}

export const updateMomentLocale = (lng: string | null) => {
  const language = lng === null ? store.get("language") || "de" : lng

  const allLocales = moment.locales()
  const locale = allLocales.find((momentLocale) => momentLocale.startsWith(language, 0))

  // https://momentjscom.readthedocs.io/en/latest/moment/07-customization/16-dow-doy/
  moment.updateLocale(locale || language, { week: { dow: 1, doy: 4 } })
}

export default i18n
