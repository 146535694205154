import { gql } from "apollo-boost"
import { createRfidGroup, createRfidGroupVariables } from "../generated/createRfidGroup"

export const CREATE_RFID_GROUP_MUTATION = gql`
  mutation createRfidGroup($townId: ID!, $address: AddressInput!, $rfids: [String!]!) {
    createRfidGroup(townId: $townId, address: $address, rfids: $rfids) {
      address {
        street
        streetNumber
        city
        postalcode
      }
    }
  }
`

export type CreateRfidGroupMutationResult = createRfidGroup
export type CreateRfidGroupMutationVariables = createRfidGroupVariables
