import { gql } from "apollo-boost"
import { updateCitizen, updateCitizenVariables } from "../generated/updateCitizen"

export const UPDATE_CITIZEN_MUTATION = gql`
  mutation updateCitizen($id: ID!, $citizen: CitizenInput!) {
    updateCitizen(id: $id, citizen: $citizen) {
      firstName
    }
  }
`

export type UpdateCitizenResult = updateCitizen
export type UpdateCitizenVariables = updateCitizenVariables
