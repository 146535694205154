import { ClipLoader } from "react-spinners"
import React, { useCallback } from "react"
import { useTranslation } from "react-i18next"
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
  Theme,
  Typography,
} from "@material-ui/core"
import { useMutation } from "@apollo/react-hooks"
import { RfidChip } from "../../../../api/graphql/query/rfid-chip-for-town"
import {
  DeleteRfidChipMutationResult,
  DeleteRfidChipMutationVariables,
  DELETE_RFID_CHIP_MUTATION,
} from "../../../../api/graphql/mutation/delete-rfid-chip"
import { getGraphQLError } from "../../../../utils/get-graphql-error-code"
import { toast } from "react-toastify"

const useStyles = makeStyles((theme: Theme) => ({
  dialogRoot: {
    minWidth: "360px",
  },
}))

export interface IDeleteRfidChipDialog {
  rfidChip: RfidChip
  onSuccess: () => void
  isOpen: boolean
  onCloseDialog: () => void
}

export const DeleteRfidChipDialog: React.FunctionComponent<IDeleteRfidChipDialog> = (props) => {
  const { rfidChip, onSuccess, isOpen, onCloseDialog } = props
  const classes = useStyles()
  const { t } = useTranslation()

  const [deleteRfidChipMutation, { loading }] = useMutation<
    DeleteRfidChipMutationResult,
    DeleteRfidChipMutationVariables
  >(DELETE_RFID_CHIP_MUTATION, {
    onCompleted: () => {
      onSuccess()
    },
    onError: (error) => {
      console.log(error)
      toast.error(t(`rfid_management.delete_error.${getGraphQLError(error).message}`))
      onCloseDialog()
    },
  })

  const deleteRfidChip = useCallback(() => {
    deleteRfidChipMutation({
      variables: {
        id: rfidChip.id,
      },
    })
  }, [rfidChip, deleteRfidChipMutation])

  return (
    <Dialog open={isOpen} fullWidth={false} className={classes.dialogRoot} classes={{ paper: classes.dialogRoot }}>
      <DialogTitle>{t("rfid_management.dialog.delete.title")}</DialogTitle>
      <DialogContent>
        <Typography>{t("rfid_management.dialog.delete.content")}</Typography>
      </DialogContent>
      <DialogActions style={{ paddingBottom: 10, paddingRight: 10 }} key={"cancel"}>
        <Button color="primary" onClick={onCloseDialog}>
          {t("general.no")}
        </Button>
        <Button color="primary" variant={"contained"} onClick={deleteRfidChip}>
          {loading ? <ClipLoader color={"white"} size={17} /> : t("general.yes")}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
