import { gql } from "apollo-boost"
import { updateRfidChip, updateRfidChipVariables } from "../generated/updateRfidChip"

export const UPDATE_RFID_CHIP_MUTATION = gql`
  mutation updateRfidChip(
    $id: ID!
    $rfid: String!
    $detailedAddress: DetailedAddressInput!
    $code: String
    $uniquelyAssignable: Boolean
    $type: RfidChipType
    $comment: String
  ) {
    updateRfidChip(
      id: $id
      rfid: $rfid
      detailedAddress: $detailedAddress
      code: $code
      uniquelyAssignable: $uniquelyAssignable
      type: $type
      comment: $comment
    ) {
      rfid
      detailedAddress {
        city
        street
        streetNumber
        postalcode
      }
      code
      uniquelyAssignable
    }
  }
`

export type UpdateRfidChipMutationResult = updateRfidChip
export type UpdateRfidChipMutationVariables = updateRfidChipVariables
