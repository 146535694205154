import React, { Fragment, FunctionComponent, useEffect, useState, useContext, useMemo } from "react"
import { Button, makeStyles, Theme, Grid, Paper, useTheme, Typography } from "@material-ui/core"
import { useTranslation } from "react-i18next"
import lodash from "lodash"
import { Tour } from "../../../api/graphql/query/tours"
import { TourTable } from "./TourTable"
import { ManageTourDialog } from "./ManageTourDialog"
import { RootGrid } from "../layout/RootGrid"
import { TopBar } from "../heading/TopBar"
import { ImportToursDialog } from "./import-tours/ImportToursDialog"
import { ImportTourReadingsDialog } from "./ImportTourReadingsDialog"
import { AuthContext } from "../../../context/AuthContext"
import { AdminBadge } from "../badge/AdminBadge"
import { AbilityName } from "../../../api/graphql/generated/globalTypes"
import { TourFilterComponent } from "./TourFilterComponent"
import { TourFilterContext } from "../../../context/TourFilterContext"
import { SectionLoadingIndicator } from "../dashboard/SectionLoadingIndicator"

const useStyles = makeStyles((theme: Theme) => ({
  headerButtons: {
    display: "flex",
  },
  paper: {
    marginTop: 0,
    marginBottom: theme.spacing(1),
    padding: theme.spacing(2),
    justifyContent: "center",
    color: theme.palette.text.secondary,
    borderRadius: theme.spacing(0),
  },
  tableWrapper: {
    display: "flex",
    flex: 1,
  },
}))

interface ITourPageContentProps {}

export const TourPageContent: FunctionComponent<ITourPageContentProps> = (props) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { tours, contextRefetch, contextLoading } = useContext(TourFilterContext)

  const [dialogOpen, setDialogOpen] = useState<boolean>(false)
  const [importDialogOpen, setImportDialogOpen] = useState<boolean>(false)
  const [importTourReadingsDialogOpen, setImportTourReadingsDialogOpen] = useState<boolean>(false)
  const [tourToEdit, setTourToEdit] = useState<Tour | undefined>(undefined)
  const [loadingTour, setLoadingTour] = useState<Tour | {} | undefined>(undefined)

  useEffect(() => {
    setDialogOpen(!lodash.isNil(tourToEdit))
  }, [tourToEdit])

  useEffect(() => {
    setLoadingTour(undefined)
  }, [tours, setLoadingTour])

  const { hasOneRequiredAbility } = useContext(AuthContext)
  const theme = useTheme()

  const tableLoading = useMemo(() => !!loadingTour || contextLoading, [loadingTour, contextLoading])

  return (
    <Fragment>
      {/* DIALOGS */}
      {dialogOpen && (
        <ManageTourDialog
          tour={tourToEdit}
          isOpen={dialogOpen}
          onCloseDialog={() => {
            setTourToEdit(undefined)
            setDialogOpen(false)
          }}
          onSuccess={(tourId: string) => {
            setLoadingTour(tours.find((tour) => tourId === tour.id) || {}) // could maybe used for a single skeleton loading line
            setTourToEdit(undefined)
            setDialogOpen(false)
            contextRefetch()
          }}
        />
      )}
      <ImportToursDialog open={importDialogOpen} onClose={() => setImportDialogOpen(false)} />
      {importTourReadingsDialogOpen && (
        <ImportTourReadingsDialog
          open={importTourReadingsDialogOpen}
          onClose={() => setImportTourReadingsDialogOpen(false)}
        />
      )}
      {/* VIEW */}
      <TopBar>{t("tour_page.heading")}</TopBar>
      <Paper elevation={2} className={classes.paper}>
        <TourFilterComponent />
      </Paper>
      <RootGrid spacing={2} direction="column" item xs>
        <Grid item>
          <Grid container spacing={1} justify="flex-end" alignItems="center" className={classes.headerButtons}>
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                aria-label="Touren importieren"
                disabled={importDialogOpen}
                onClick={() => {
                  setImportDialogOpen(true)
                }}
              >
                {t("tour_page.import_tours")}
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                aria-label="Tour erstellen"
                onClick={() => {
                  setDialogOpen(true)
                }}
              >
                {t("tour_page.create_tour")}
              </Button>
            </Grid>
            {hasOneRequiredAbility([AbilityName.admin, AbilityName.manager, AbilityName.area_manager]) && (
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  aria-label="Rfid Lesungen importieren"
                  disabled={importDialogOpen}
                  onClick={() => {
                    setImportTourReadingsDialogOpen(true)
                  }}
                >
                  <Grid container spacing={1} alignItems="center">
                    <Grid item>{t("tour_page.import_rfid_readings")}</Grid>
                  </Grid>
                </Button>
              </Grid>
            )}
          </Grid>
        </Grid>
        {tours.length > 0 && !tableLoading && (
          <Grid item xs container>
            <Paper className={classes.tableWrapper}>
              <TourTable
                tours={tours}
                loading={tableLoading}
                onAction={(tour) => {
                  setTourToEdit(tour)
                }}
              />
            </Paper>
          </Grid>
        )}
        {tours.length <= 0 && !tableLoading && (
          <Grid item xs container justify="center" alignItems="center">
            <Grid item>
              <Typography color="secondary">{t("analysis_page.no_data_for_this_filter")}</Typography>
            </Grid>
          </Grid>
        )}
        {tableLoading && (
          <Grid item xs container justify="center" alignItems="center">
            <SectionLoadingIndicator />
          </Grid>
        )}
      </RootGrid>
    </Fragment>
  )
}
