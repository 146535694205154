import React, { Fragment, FunctionComponent, useContext, useState } from "react"
import { useTranslation } from "react-i18next"
import { RootGrid } from "../partials/layout/RootGrid"
import { PageLoadingIndicator } from "../partials/indicator/PageLoadingIndicator"
import { AssociationSection } from "../partials/citizen-management/AssociationSection"
import { TopBar } from "../partials/heading/TopBar"
import { Button, Grid, useTheme } from "@material-ui/core"
import { ImportCitizensDialog } from "../partials/citizen-management/ImportCitizensDialog"
import { ExportCitizensDialog } from "../partials/citizen-management/ExportCitizensDialog"
import { AdminBadge } from "../partials/badge/AdminBadge"
import { TownExpansionPanelWithCitizenTable } from "../partials/citizen-management/TownExpansionPanelWithCitizenTable"
import { AuthContext } from "../../context/AuthContext"
import { AbilityName } from "../../api/graphql/generated/globalTypes"
import { PermittedAssociationsContext } from "../../context/PermittedAssociationsContext"

interface ICitizenManagementPageProps {}

export const CitizenManagementPage: FunctionComponent<ICitizenManagementPageProps> = (props) => {
  const { t } = useTranslation()
  const theme = useTheme()

  const [importDialogOpen, setImportDialogOpen] = useState<boolean>(false)
  const [exportDialogOpen, setExportDialogOpen] = useState<boolean>(false)

  const { permittedAssociationsLoading, permittedAssociationsError, permittedAssociations } = useContext(
    PermittedAssociationsContext,
  )

  const { hasOneRequiredAbility } = useContext(AuthContext)

  if (permittedAssociationsError) {
    console.log(permittedAssociationsError)
  }

  return (
    <Fragment>
      {/* DIALOGS */}
      {importDialogOpen && (
        <ImportCitizensDialog
          associations={permittedAssociations}
          open={importDialogOpen}
          onClose={() => setImportDialogOpen(false)}
        />
      )}
      {exportDialogOpen && (
        <ExportCitizensDialog
          associations={permittedAssociations}
          open={exportDialogOpen}
          onClose={() => setExportDialogOpen(false)}
        />
      )}
      <TopBar>{t("citizen_management.heading")}</TopBar>
      <RootGrid spacing={2} justify="flex-end">
        {permittedAssociationsLoading && (
          <Grid item xs={12}>
            <PageLoadingIndicator />
          </Grid>
        )}

        {!permittedAssociationsLoading && permittedAssociations && (
          <Fragment>
            {hasOneRequiredAbility([AbilityName.admin, AbilityName.area_manager]) && (
              <>
                <Grid item>
                  <Button
                    variant="contained"
                    color="primary"
                    aria-label="Bürger importieren"
                    disabled={importDialogOpen}
                    onClick={() => {
                      setImportDialogOpen(true)
                    }}
                  >
                    <Grid container spacing={1} alignItems="center">
                      <Grid item>{t("citizen_management.import_citizens")}</Grid>
                      <Grid item>
                        <AdminBadge color={theme.palette.common.white} />
                      </Grid>
                    </Grid>
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    color="primary"
                    aria-label="Bürger exportieren"
                    disabled={exportDialogOpen}
                    onClick={() => {
                      setExportDialogOpen(true)
                    }}
                  >
                    <Grid container spacing={1} alignItems="center">
                      <Grid item>{t("citizen_management.export_citizens")}</Grid>
                      <Grid item>
                        <AdminBadge color={theme.palette.common.white} />
                      </Grid>
                    </Grid>
                  </Button>
                </Grid>
              </>
            )}
            <Grid item xs={12}>
              {permittedAssociations.map(
                (association) =>
                  association && (
                    <AssociationSection key={association.id} association={association}>
                      {association.towns.map(
                        (town) => town && <TownExpansionPanelWithCitizenTable key={town.id} town={town} />,
                      )}
                    </AssociationSection>
                  ),
              )}
            </Grid>
          </Fragment>
        )}
      </RootGrid>
    </Fragment>
  )
}
