import React, { FunctionComponent, Fragment } from "react"
import { Grid, useTheme, Tooltip, Fab, Typography } from "@material-ui/core"
import { useTranslation } from "react-i18next"
import { RfidChip } from "../../../api/graphql/query/rfid-chip-for-town"
import { ClipLoader } from "react-spinners"
import { IColumnHeader, EColumnHeaderType, cellForRow, createColumns, DefaultTable } from "../table/DefaultTable"
import { DerivedDataObject, Column } from "react-table"
import { Check, Clear, Delete, Edit } from "@material-ui/icons"
import lodash from "lodash"
import { rfidChips_rfidChips_detailedAddress } from "../../../api/graphql/generated/rfidChips"
import { compareDetailedAddress } from "../../../utils/compare-detailed-address"
import { RfidChipType } from "../../../api/graphql/generated/globalTypes"

interface IRfidTableForTownProps {
  rfidChips: RfidChip[]
  setRfidChipToEdit: (rfidChip: RfidChip) => void
  setRfidChipToDelete: (rfidChip: RfidChip) => void
  loading: boolean
}

export const RfidTableForTown: FunctionComponent<IRfidTableForTownProps> = (props) => {
  const { t } = useTranslation()
  const { rfidChips, setRfidChipToEdit, setRfidChipToDelete, loading } = props
  const theme = useTheme()

  const columnHeaders: IColumnHeader[] = [
    {
      title: t("rfid.property.rfid"),
      type: EColumnHeaderType.STRING,
      accessor: "rfid",
    },
    {
      title: t("rfid.property.address"),
      accessor: "detailedAddress",
      type: EColumnHeaderType.CUSTOM,
      customTitle: (detailedAddress: rfidChips_rfidChips_detailedAddress) =>
        detailedAddress
          ? `${detailedAddress.street} ${detailedAddress.streetNumber}, ${detailedAddress.postalcode} ${detailedAddress.city}`
          : "",
      sortMethod: compareDetailedAddress,
    },
    {
      title: t("rfid.property.type"),
      accessor: "type",
      type: EColumnHeaderType.CUSTOM,
      customTitle: (type: RfidChipType | null | undefined, row: DerivedDataObject) => {
        const comment = (row._original as RfidChip).comment
        return type ? t(`rfid.property.types.${type}`, { comment: comment ? ` (${comment})` : "" }) : "-"
      },
    },
    {
      title: t("rfid.property.code"),
      type: EColumnHeaderType.STRING,
      accessor: "code",
    },
    {
      title: t("rfid.property.uniquely_assignable"),
      type: EColumnHeaderType.CUSTOM,
      accessor: "uniquelyAssignable",
      customTitle: (uniquelyAssignable: boolean | null) => {
        switch (uniquelyAssignable) {
          case true:
            return <Check />
          case false:
            return <Clear />
          default:
            return ""
        }
      },
    },
    {
      title: t("rfid.property.citizens"),
      type: EColumnHeaderType.CUSTOM,
      accessor: "citizens",
      customTitle: (citizens: any[]) => `${citizens.length}`,
    },
  ]

  const columns: Column[] = createColumns(
    columnHeaders
      .map((column) => {
        return {
          Header: column.title,
          accessor: column.accessor,
          Cell: ({ row }: { row: DerivedDataObject }) => cellForRow(column, row),
          sortMethod: column.sortMethod,
        } as Column
      })
      .concat({
        Header: "",
        accessor: "",
        Cell: ({ row }: { row: DerivedDataObject }) => {
          return (
            <Grid container item direction="row" spacing={2} justify="flex-end">
              <Grid item>
                <Tooltip title={t("general.delete")} style={{ marginRight: 8 }}>
                  <Fab size="small" color="primary" onClick={() => setRfidChipToDelete(row._original)}>
                    <Delete />
                  </Fab>
                </Tooltip>
                <Tooltip title={t("general.edit")}>
                  <Fab size="small" color="primary" onClick={() => setRfidChipToEdit(row._original)}>
                    <Edit />
                  </Fab>
                </Tooltip>
              </Grid>
            </Grid>
          )
        },
      }),
  )

  const numberOfChips = lodash.isArray(rfidChips) ? rfidChips.length : 0
  return (
    <Fragment>
      {numberOfChips > 0 && !loading && (
        <DefaultTable
          columns={columns}
          loading={loading}
          data={rfidChips}
          minRows={1}
          defaultPageSize={10}
          showPagination={true}
        />
      )}
      {loading && (
        <Grid container justify="center">
          <ClipLoader color={theme.palette.primary.main} />
        </Grid>
      )}
      {numberOfChips === 0 && !loading && (
        <Grid container justify="center">
          <Typography>{t("rfid_management.no_rfids")}</Typography>
        </Grid>
      )}
    </Fragment>
  )
}
