import React, { FunctionComponent, Fragment } from "react"
import { Theme, makeStyles, Typography, Grid } from "@material-ui/core"
import { useTranslation } from "react-i18next"
import { HousingManagementById_housingManagement_associations } from "../../../api/graphql/generated/HousingManagementById"
import { PageLoadingIndicator } from "../indicator/PageLoadingIndicator"
import lodash from "lodash"

const useStyles = makeStyles((theme: Theme) => ({
  title: {
    fontSize: 16,
    fontWeight: "bold",
    paddingBottom: theme.spacing(2),
    color: theme.palette.primary.main,
  },
  name: {
    border: `1px solid ${theme.palette.grey[300]}`,
    borderRadius: 8,
    padding: theme.spacing(1),
  },
}))

interface IHousingManagementAssociationsProps {
  loading: boolean
  associations?: HousingManagementById_housingManagement_associations[]
}

export const HousingManagementAssociations: FunctionComponent<IHousingManagementAssociationsProps> = (props) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { loading, associations } = props
  return (
    <Fragment>
      <Typography className={classes.title}>{t("housing_management.associations")}</Typography>
      <Grid container spacing={2}>
        {!loading &&
          associations &&
          lodash
            .orderBy(associations, (a) => a.name)
            .map((association, i) => (
              <Grid item key={i}>
                <Typography className={classes.name}>{association.name}</Typography>
              </Grid>
            ))}

        {!loading && !associations.length && (
          <Grid item>
            <Typography>-</Typography>
          </Grid>
        )}
        {loading && <PageLoadingIndicator />}
      </Grid>
    </Fragment>
  )
}
