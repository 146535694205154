import React, { FunctionComponent, useMemo } from "react"
import {
  Theme,
  makeStyles,
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  DialogContentText,
  DialogActions,
  Button,
  CircularProgress,
} from "@material-ui/core"
import { useTranslation } from "react-i18next"
import { useMutation } from "react-apollo"
import {
  postToExternalNewsfeedMutationResult,
  postToExternalNewsfeedMutationVariables,
  POST_TO_EXTERNAL_NEWSFEED_MUTATION,
} from "../../../../api/graphql/mutation/post-to-external-newsfeed"
import { Warning } from "@material-ui/icons"
import { Report } from "../../../../api/graphql/query/reports"
import { ChannelType, ExternalTownType, WidgetType } from "../../../../api/graphql/generated/globalTypes"
import lodash from "lodash"
import { ExportExtensionType } from "../../../../domain/models/ExportExtensionType"
import { CommunicationChannelTypeDictionary } from "../../../../domain/models/CommunicationChannelTypes"
import { toast } from "react-toastify"

const useStyles = makeStyles((theme: Theme) => ({
  imagePieGraph: {
    width: 300,
    height: 170,
    objectFit: "cover",
  },
  imageHistoryGraph: {
    width: 500,
    height: "auto",
  },
  fallbackImage: {
    width: 500,
    height: "auto",
  },
  errorContainer: {
    backgroundColor: "#e22e3a",
    borderLeftColor: "#c20e1a",
    borderLeftWidth: 5,
    color: "white",
  },
}))

interface IPostToExternalNewsfeedDialogProps {
  widgetExports: any
  open: boolean
  widgets: any[]
  townId: string
  report: Report
  onClose?: () => void
  refetchReportDetails: () => void
  channelType: ChannelType
}

export const PostToExternalNewsfeedDialog: FunctionComponent<IPostToExternalNewsfeedDialogProps> = (props) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { open, widgets, widgetExports, townId, report, onClose, channelType, refetchReportDetails } = props

  const handleMutationComplete = (data: postToExternalNewsfeedMutationResult) => {
    const error = lodash.get(data, "postToDaheimNewsfeed.error", false)
    if (error) {
      toast.error(t("post_to_daheim_dialog.error"))
      console.error("handleMutationComplete", error)
    }

    toast.success(t("sms_dialog.success_message"))
    handleClose()
    refetchReportDetails()
  }

  const [postToExternalNewsfeedMutation, { loading, error }] = useMutation<
    postToExternalNewsfeedMutationResult,
    postToExternalNewsfeedMutationVariables
  >(POST_TO_EXTERNAL_NEWSFEED_MUTATION, { onCompleted: handleMutationComplete })

  const daheimAppExports = useMemo(() => widgetExports[channelType as any], [widgetExports, channelType])
  const externalTownType = useMemo(
    () => CommunicationChannelTypeDictionary[channelType].externalTownType || ExternalTownType.daheim,
    [channelType],
  )
  const { title, text, image } = useMemo(() => {
    if (!lodash.isNil(daheimAppExports)) {
      const textObject = Object.values(daheimAppExports).find((exp: any) => exp.extension === ExportExtensionType.TXT)
      const imageObject = Object.values(daheimAppExports).find((exp: any) => exp.extension === ExportExtensionType.PNG)
      return {
        text: `${lodash.get(textObject, "textContent")}`.split("$-$").join("<br/>").split("\\n").join("<br/>"),
        title: lodash.get(textObject, "title"),
        image: lodash.get(imageObject, "uri"),
      }
    }
    return {
      text: "",
      title: "",
      image: "",
    }
  }, [daheimAppExports])

  const imageClassName = useMemo(() => {
    const graphWidget = widgets.find(
      (widget) => widget.widgetType === WidgetType.chart_history || widget.widgetType === WidgetType.chart_pie,
    )
    switch (lodash.get(graphWidget, "widgetType")) {
      case WidgetType.chart_pie:
        return classes.imagePieGraph
      case WidgetType.chart_history:
        return classes.imageHistoryGraph
      default:
        return classes.fallbackImage
    }
  }, [widgets, classes])

  const handleSend = () => {
    postToExternalNewsfeedMutation({
      variables: {
        townId,
        title,
        text,
        imageAsBase64: image,
        reportId: lodash.get(report, "id"),
        externalTownType,
      },
    })
  }

  const handleClose = () => {
    if (onClose) {
      onClose()
    }
  }

  return (
    <Dialog open={open} onClose={handleClose} maxWidth={"md"}>
      <DialogTitle>{t("post_to_daheim_dialog.heading", {externalTownType: t(`external_town_type.${externalTownType}`) })}</DialogTitle>
      <DialogContent>
        <Grid container direction={"column"} alignContent={"center"} alignItems={"center"}>
          <Grid item xs={12}>
            <img src={image} alt="" className={imageClassName}></img>
          </Grid>
          <Grid item xs={12}>
            <h1>{title}</h1>
            <DialogContentText>
              {text.split("<br/>").map((line, index) => (
                <span key={`daheim_line_${index}`}>
                  {line}
                  <br />
                </span>
              ))}
            </DialogContentText>
          </Grid>
        </Grid>

        {error && (
          <Grid container direction={"column"} alignItems={"stretch"}>
            <Grid item xs={12} className={classes.errorContainer}>
              <span role="img" aria-label="Warning">
                <Warning />
              </span>
              {t("post_to_daheim_dialog.error")}
            </Grid>
          </Grid>
        )}
      </DialogContent>
      <DialogActions>
        <Button disabled={loading} onClick={handleClose} color="primary">
          {t("general.cancel")}
        </Button>
        <Button variant="contained" disabled={loading} onClick={handleSend} color="primary">
          {loading ? <CircularProgress size={22} /> : t("general.send")}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
