import React, { FunctionComponent, useCallback, useMemo, useState } from "react"
import { Grid, makeStyles, Theme } from "@material-ui/core"
import { WertstoffPictureGallery } from "../analysis-data/WertstoffPictureGallery"
import { MaterialAnalysisWithDocuments } from "../../../api/graphql/query/analyticsDocuments"
import { AnalysisPictureSectionHeader } from "./AnalysisPictureSectionHeader"
import { useLazyMaterialAnalysisWithDocumentsLoading } from "../analysis-data/hooks/useLazyMaterialAnalysisWithDocumentsLoading"
import { useMaterialAnalysisWithDocumentsSearch } from "../analysis-data/hooks/useMaterialAnalysisWithDocumentsSearch"
import { WERTSTOFF_PICTURE_GALLERY_ITEM_HEIGHT } from "../analysis-data/WertstoffPictureGalleryItem"
import { MaterialAnalysisInvalidityInput } from "../../../api/graphql/generated/globalTypes"
import { MaterialAnalysisForMap } from "../../../api/graphql/query/material-analysis-for-map"

const useStyles = makeStyles((theme: Theme) => ({
  pictureGallery: {
    overflowY: "hidden",
    width: "100%",
    height: (props: any) => (props.open ? WERTSTOFF_PICTURE_GALLERY_ITEM_HEIGHT * 2 + theme.spacing(6) : 0),
  },
}))

interface IAnalysisPictureSectionProps {
  title: string
  materialAnalysis: MaterialAnalysisForMap[]
  useBagSeparation?: boolean
  onAnalysisDeleted: (id: string) => void
  onAnalysisInvalidated?: (id: string, invalidity: MaterialAnalysisInvalidityInput) => void
}

export interface ILazyMaterialAnalysisWithDocuments extends MaterialAnalysisWithDocuments {
  loaded?: boolean
}

export const AnalysisPictureSection: FunctionComponent<IAnalysisPictureSectionProps> = (props) => {
  const { materialAnalysis, useBagSeparation, onAnalysisDeleted, onAnalysisInvalidated } = props
  const [open, setOpen] = useState<boolean>(false)

  const classes = useStyles({ open })

  const {
    disableFetch: disableLazyLoadingFetch,
    isFetching: isLazyFetching,
    setIsFetching,
    lazyMaterialAnalysis,
    removeAnalysisFromLazyMaterialAnalysis,
  } = useLazyMaterialAnalysisWithDocumentsLoading(materialAnalysis, open)

  const {
    isInSearchView,
    searchString,
    onChangeSeachString,
    foundMaterialAnalysis,
    search,
    searchLoading,
    removeAnalysisFromFoundMaterialAnalysis,
  } = useMaterialAnalysisWithDocumentsSearch(materialAnalysis)

  const { analysis, disableFetch } = useMemo(
    () => ({
      analysis: isInSearchView ? foundMaterialAnalysis : lazyMaterialAnalysis,
      disableFetch: isInSearchView ? true : disableLazyLoadingFetch,
    }),
    [isInSearchView, lazyMaterialAnalysis, foundMaterialAnalysis, disableLazyLoadingFetch],
  )

  const customOnAnalysisDeleted = useCallback(
    (id: string) => {
      removeAnalysisFromFoundMaterialAnalysis(id)
      removeAnalysisFromLazyMaterialAnalysis(id)
      onAnalysisDeleted(id)
    },
    [onAnalysisDeleted, removeAnalysisFromLazyMaterialAnalysis, removeAnalysisFromFoundMaterialAnalysis],
  )

  return (
    <Grid container spacing={0} direction="column">
      <AnalysisPictureSectionHeader
        toggleOpen={() => setOpen(!open)}
        open={open}
        title={props.title}
        searchString={searchString}
        onChangeSeachString={onChangeSeachString}
        search={search}
      />
      <Grid item className={classes.pictureGallery}>
        <WertstoffPictureGallery
          onAnalysisInvalidated={onAnalysisInvalidated}
          onAnalysisDeleted={customOnAnalysisDeleted}
          useBagSeparation={useBagSeparation}
          disableFetch={disableFetch}
          isFetching={isLazyFetching}
          searchIsLoading={searchLoading}
          setIsFetching={setIsFetching}
          analysisData={analysis}
        />
      </Grid>
    </Grid>
  )
}
