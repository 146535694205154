import { HousingManagementById, HousingManagementById_housingManagement } from './../generated/HousingManagementById';
import { gql } from "apollo-boost"
export const HOUSE_MANAGEMENT_BY_ID_QUERY = gql`
  query HousingManagementById($housingManagementId: ID!) {
    housingManagement(id: $housingManagementId) {
      name
      rfids
      associations {
        id
        name
      }
      towns {
        id
        associationId
        name
      }
    }
  }
`

export interface HousingManagementByIdResult extends HousingManagementById {}
export interface HousingManagementDetails extends HousingManagementById_housingManagement {}