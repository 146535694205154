import React, { FunctionComponent } from "react"
// import { Theme, makeStyles } from "@material-ui/core"
import { useTranslation } from "react-i18next"
import { TourTypeDictionary } from "../../../domain/models/TourTypes"
import { FilterDropDown, Item } from "./FilterDropDown"
import { useTextFilter } from "../../../context/TextFilterContext"

// const useStyles = makeStyles((theme: Theme) => ({}))

interface IFractionFilterProps {
  onChange: Function
}

export const FractionFilter: FunctionComponent<
  IFractionFilterProps
> = props => {
  // const classes = useStyles()
  const { t } = useTranslation()
  const { setFractionId, fractionId } = useTextFilter()
  const tourTypesItems: Item[] = TourTypeDictionary.map(type => ({
    key: type.type,
    value: type.title
  }))
  const { onChange } = props
  return (
    <FilterDropDown
      items={tourTypesItems}
      noSelectionEnabled
      selectedItem={fractionId}
      onChange={(id: string) => {
        setFractionId(id)
        onChange()
      }}
      keyPrefix="TourTypes"
      title={t("general.main_fraction")}
    />
  )
}
