import React, { FunctionComponent, useState, useContext, useMemo, useEffect } from "react"
import { Theme, makeStyles, RadioGroup, Tabs, Tab, Grid } from "@material-ui/core"
import { useTranslation } from "react-i18next"
import { AssociationAndTownSelection } from "./AssociationAndTownSelection"
import { TabPanel } from "../../tabs/TabPanel"
import { ManageUserContext } from "./context/ManageUserContext"
import { VehicleSelection } from "./VehicleSelection"
import { AbilityName } from "../../../../api/graphql/generated/globalTypes"
import { HousingManagementSelection } from "./HousingManagementSelection"
import { HousingManagementContextProvider } from "../../../../context/HousingManagementContext"

const useStyles = makeStyles((theme: Theme) => ({
  tabPanel: {
    height: window.innerHeight / 2.5,
    minHeight: 200,
    padding: theme.spacing(2),
    background: theme.palette.secondary.light,
    borderBottomLeftRadius: theme.spacing(1),
    borderBottomRightRadius: theme.spacing(1),
  },
  tabs: {
    color: theme.palette.secondary.dark,
    background: theme.palette.secondary.light,
    borderTopLeftRadius: theme.spacing(1),
    borderTopRightRadius: theme.spacing(1),
  },
}))

interface IManageUserTabsProps {}

export const ManageUserTabs: FunctionComponent<IManageUserTabsProps> = (props) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const [currentTab, setCurrentTab] = useState<number>(0)

  const { defaultTownId, setDefaultTownId, abilities } = useContext(ManageUserContext)

  const tabLabelTranslations = useMemo(
    () =>
      abilities.includes(AbilityName.housing_management)
        ? ["user_management.housing_managements"]
        : ["user_management.associations_and_towns", "user_management.vehicles"],
    [abilities],
  )

  useEffect(() => {
    if (tabLabelTranslations.length === 1 && currentTab !== 0) {
      setCurrentTab(0)
    }
  }, [tabLabelTranslations, currentTab])

  return (
    <Grid container>
      <Grid item xs={12}>
        <Tabs
          value={currentTab}
          onChange={(_, value) => setCurrentTab(value)}
          variant="fullWidth"
          className={classes.tabs}
        >
          {tabLabelTranslations.map((translation) => (
            <Tab label={t(translation)} />
          ))}
        </Tabs>
      </Grid>

      <Grid item xs={12} className={classes.tabPanel}>
        {abilities.includes(AbilityName.housing_management) ? (
          <TabPanel value={currentTab} index={0}>
            <HousingManagementContextProvider>
              <HousingManagementSelection />
            </HousingManagementContextProvider>
          </TabPanel>
        ) : (
          <>
            <TabPanel value={currentTab} index={0}>
              <RadioGroup value={defaultTownId} onChange={(event) => setDefaultTownId(event.target.value)}>
                <AssociationAndTownSelection />
              </RadioGroup>
            </TabPanel>
            <TabPanel value={currentTab} index={1}>
              <VehicleSelection />
            </TabPanel>
          </>
        )}
      </Grid>
    </Grid>
  )
}
