import React, { FunctionComponent, createContext } from "react"
import { useQuery } from "react-apollo"
import { EdaQueue, EdaQueuesQueryResult, EDA_QUEUES_QUERY } from "../api/graphql/query/eda-queues"
import { sortEdaQueues } from "../components/partials/eda/helpers/SortEdaLanes"

interface IEdaContextProviderProps {}

interface IEdaContext {
  edaQueues: EdaQueue[]
  edaQueuesLoading: boolean
}

export const EdaContext = createContext<IEdaContext>({ edaQueues: [], edaQueuesLoading: false })

export const EdaContextProvider: FunctionComponent<IEdaContextProviderProps> = (props) => {
  const { data, loading: edaQueuesLoading } = useQuery<EdaQueuesQueryResult>(EDA_QUEUES_QUERY, { pollInterval: 5000 })

  const edaQueues = data?.edaQueues ? sortEdaQueues(data.edaQueues) : []

  return <EdaContext.Provider value={{ edaQueues, edaQueuesLoading }}>{props.children}</EdaContext.Provider>
}
