import React, { FunctionComponent } from "react"
import {
  Theme,
  makeStyles,
  Grid,
  ListItemText,
  ListItem,
  Tooltip,
  Radio
} from "@material-ui/core"
import { useTranslation } from "react-i18next"
import { Clear } from "@material-ui/icons"
import { TownsOfAssociationWithTowns } from "../../../../api/graphql/fragments/association-with-town"

interface IStyle {
  isOdd: boolean
}

const useStyles = makeStyles((theme: Theme) => ({
  listItem: {
    background: (props: IStyle) =>
      props.isOdd ? theme.palette.common.white : theme.palette.secondary.light,
    width: "100%"
  },
  clearButton: {
    cursor: "pointer",
    color: theme.palette.secondary.dark
  },
  checkboxRoot: {
    padding: 0
  }
}))

interface ITownListItemProps {
  town: TownsOfAssociationWithTowns,
  index: number,
  onRemove?: () => void,
  removable?: boolean
}

export const TownListItem: FunctionComponent<ITownListItemProps> = props => {
  const { town, index, onRemove, removable } = props
  const isRemovable = !!removable
  const isOdd = index % 2 === 1
  const classes = useStyles({ isOdd })
  const { t } = useTranslation()
  return (
    <ListItem className={classes.listItem}>
      <ListItemText>
        <Grid
          container
          justify="space-between"
          alignContent="center"
          alignItems="center"
        >
          <Grid item>{town.name}</Grid>
          <Grid item>
            <Grid container alignContent="center" alignItems="center">
              <Grid item>
                <Tooltip title={t("user_management.set_town_as_default")}>
                  <Radio
                    value={town.id}
                    disableFocusRipple
                    disableRipple
                    disableTouchRipple
                    classes={{ root: classes.checkboxRoot }}
                  />
                </Tooltip>
              </Grid>
              {isRemovable && (
                <Grid item>
                  <Clear
                    className={classes.clearButton}
                    onClick={onRemove ? onRemove : undefined}
                  />
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </ListItemText>
    </ListItem>
  )
}
