import { gql } from "apollo-boost"
import { classifierFractions, classifierFractions_classifierFractions } from "../generated/classifierFractions"

export const CLASSIFIER_FRACTIONS_QUERY = gql`
  query classifierFractions($tourType: TourType!) {
    classifierFractions(tourType: $tourType) {
      id
      name
      classifierIds
    }
  }
`

export type ClassifierFractionsResult = classifierFractions
export type ClassifierFraction = classifierFractions_classifierFractions
