import React, { FunctionComponent, useState, useCallback, useEffect, useContext, useMemo } from "react"
import {
  Theme,
  makeStyles,
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  CircularProgress,
  Typography,
  DialogActions,
  Button,
} from "@material-ui/core"
import { useTranslation } from "react-i18next"
import { Dropzone } from "../dropzone/Dropzone"
import { UploadedFile } from "../tour-management/import-tours/UploadedFile"
import { CheckCircle } from "@material-ui/icons"
import {
  IMPORT_RFID_READINGS_MUTATION,
  ImportRfidReadingsResult,
  ImportRfidReadingsVariables,
  ImportRfidReadingsValidationResult,
} from "../../../api/graphql/mutation/import-rfid-readings"
import { useMutation } from "react-apollo"
import { toast } from "react-toastify"
import { PermittedAssociationsContext } from "../../../context/PermittedAssociationsContext"
import { UserAndPermissionsAssociation } from "../../../api/graphql/query/permitted-associations-for-user"
import { ImportCheckResult } from "./import-tours/ImportCheckResult"
import { COLORS } from "../../../theme/theme"

const useStyles = makeStyles((theme: Theme) => ({
  content: {
    width: 800,
    minHeight: 150,
    overflowY: "hidden",
  },
  checkIcon: {
    color: COLORS.lime,
    fontSize: 50,
    marginRight: theme.spacing(2),
  },
}))

interface IImportTourReadingsDialogProps {
  open: boolean
  onClose: () => void
}

export const ImportTourReadingsDialog: FunctionComponent<IImportTourReadingsDialogProps> = (props) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { open, onClose } = props

  const [uploadedFile, setUploadedFile] = useState<File | undefined>()
  const { permittedAssociations } = useContext(PermittedAssociationsContext)
  const [selectedAssociation, setSelectedAssociation] = useState<UserAndPermissionsAssociation | undefined>(
    permittedAssociations[0],
  )
  const [selectedTownId, setSelectedTownId] = useState<string>(permittedAssociations[0]?.towns[0]?.id || "")
  const [validationData, setValidationData] = useState<ImportRfidReadingsValidationResult | undefined>()

  const [importRfidReadingsMutation, { loading: importLoading }] = useMutation<
    ImportRfidReadingsResult,
    ImportRfidReadingsVariables
  >(IMPORT_RFID_READINGS_MUTATION, {
    onError: (error) => {
      console.log(error)
      toast.error(t("tour_page.import_tour_readings_dialog.unknown_error"))
    },
    onCompleted: (data) => setValidationData(data.importRfidReadings),
    fetchPolicy: "no-cache",
  })

  const { importSuccessful, errors, warnings } = useMemo(
    () => ({
      importSuccessful: validationData?.importDone || false,
      errors: validationData?.errors || [],
      warnings: validationData?.warnings || [],
    }),
    [validationData],
  )

  const handleChangeAssociationId = useCallback(
    (associationId: string) => {
      const association = permittedAssociations.find((association) => association.associationId === associationId)

      if (association) {
        setSelectedAssociation(association)
        setSelectedTownId(association.towns[0].id)
      }
    },
    [permittedAssociations, setSelectedAssociation, setSelectedTownId],
  )

  const onRemoveUploadedFile = useCallback(() => {
    setUploadedFile(undefined)
    setValidationData(undefined)
  }, [setUploadedFile])

  useEffect(() => {
    if (open) {
      setSelectedAssociation(permittedAssociations[0])
      setSelectedTownId(permittedAssociations[0]?.towns[0]?.id || "")
    }
  }, [open, setSelectedAssociation, setSelectedTownId, permittedAssociations])

  const importRfidReadings = useCallback(() => {
    importRfidReadingsMutation({
      variables: {
        townId: selectedTownId,
        file: uploadedFile,
        ignoreWarnings: warnings.length > 0,
      },
    })
  }, [importRfidReadingsMutation, selectedTownId, uploadedFile, warnings])

  const importButtonDisabled = useMemo(
    () => importLoading || !selectedTownId || !uploadedFile || (validationData?.errors || []).length > 0,
    [importLoading, selectedTownId, uploadedFile, validationData],
  )

  return (
    <Dialog maxWidth="xl" onClose={onClose} aria-labelledby="simple-dialog-title" open={open}>
      <DialogTitle id="simple-dialog-title">{t("tour_page.import_tour_readings_dialog.title")}</DialogTitle>
      <DialogContent className={classes.content}>
        <Grid container direction="column" spacing={2}>
          <Grid item container spacing={2}>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <InputLabel id="inputlabel-association">
                  {t("tour_page.import_tour_readings_dialog.association_label")}
                </InputLabel>
                <Select
                  value={selectedAssociation?.associationId || ""}
                  onChange={(event) => handleChangeAssociationId(event.target.value as string)}
                  disabled={importLoading || importSuccessful}
                >
                  {permittedAssociations.map((association) => (
                    <MenuItem key={association.associationId} value={association.associationId}>
                      {association.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <InputLabel id="inputlabel-town">{t("tour_page.import_tour_readings_dialog.town_label")}</InputLabel>
                <Select
                  value={selectedTownId || ""}
                  onChange={(event) => {
                    setSelectedTownId(event.target.value as string)
                  }}
                  disabled={importLoading || importSuccessful}
                >
                  {selectedAssociation?.towns.map((town) => (
                    <MenuItem key={town.id} value={town.id as string}>
                      {town.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          {!uploadedFile && (
            <Grid item>
              <Dropzone
                accept="application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                onFilesDropped={(files: File[]) => {
                  setUploadedFile(files[0])
                }}
              />
            </Grid>
          )}
          {uploadedFile && !importSuccessful && (
            <Grid item container justify="center">
              <UploadedFile disabled={importLoading} file={uploadedFile} onRemove={onRemoveUploadedFile} />
              {importLoading && <CircularProgress />}
            </Grid>
          )}
          {importSuccessful && (
            <Grid container justify="center" alignItems="center">
              <CheckCircle className={classes.checkIcon} />
              <Typography variant="h5">{t("rfid_management.import_dialog.success")}</Typography>
            </Grid>
          )}
          {(errors.length > 0 || warnings.length > 0) && <ImportCheckResult errors={errors} warnings={warnings} />}
        </Grid>
      </DialogContent>
      <DialogActions>
        {importSuccessful ? (
          <Button color="primary" variant={"contained"} onClick={onClose}>
            {t("rfid_management.import_dialog.close")}
          </Button>
        ) : (
          <>
            <Button color="primary" onClick={onClose}>
              {t("general.cancel")}
            </Button>
            <Button disabled={importButtonDisabled} color="primary" variant={"contained"} onClick={importRfidReadings}>
              {t(
                `rfid_management.import_dialog.${
                  !importButtonDisabled && warnings.length > 0 ? "import_anyway" : "import"
                }`,
              )}
            </Button>
          </>
        )}
      </DialogActions>
    </Dialog>
  )
}
