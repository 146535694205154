import { gql } from "apollo-boost"
import {
  validateCustomCommunication,
  validateCustomCommunicationVariables,
} from "../generated/validateCustomCommunication"

export const VALIDATE_CUSTOM_COMMUNICATION_QUERY = gql`
  query validateCustomCommunication($channelType: CommunicationChannelType!, $townId: ID!) {
    validateCustomCommunication(channelType: $channelType, townId: $townId) {
      channelType
      receivers
    }
  }
`

export type ValidateCustomCommunicationResult = validateCustomCommunication
export type ValidateCustomCommunicationVariables = validateCustomCommunicationVariables
