import { CreateFilterVariables } from "../../../../api/graphql/mutation/create-filter"
import { UpdateFilterVariables } from "../../../../api/graphql/mutation/update-filter"
import moment from "moment"
import { EmptyingOfTown } from "../../../../api/graphql/query/emptyings-for-towns"
import { IAnalysisFilter } from "../../../../domain/models/AnalysisFilterModel"
import { rfidStringToArray } from "../../../../utils/rfids"

export const getFilterInputVariables = (
  filterModel: IAnalysisFilter,
  name: string | null,
  selectedEmptying: EmptyingOfTown | null,
  userId: string | null | undefined,
): CreateFilterVariables | UpdateFilterVariables => {
  return {
    filter: {
      name,
      associationId: filterModel.associationId,
      townIds: filterModel.townIds,
      isBookmark: true,
      emptyingWeekNumber: selectedEmptying?.weekNumber,
      emptyingYearNumber: selectedEmptying?.yearNumber,
      rating: filterModel.rating,
      fractionType: filterModel.fractionType,
      fractionFrom: filterModel.fractionFrom ? parseFloat(filterModel.fractionFrom) : null,
      fractionTo: filterModel.fractionTo ? parseFloat(filterModel.fractionTo) : null,
      dateFrom: filterModel.dateFrom ? moment(filterModel.dateFrom).toISOString() : null,
      dateUntil: filterModel.dateUntil ? moment(filterModel.dateUntil).toISOString() : null,
      displayOnlyAutomaticAnalysis: filterModel.displayOnlyAutomaticAnalysis,
      source: filterModel.source,
      tourType: filterModel.tourType,
      userId,
      rfids: rfidStringToArray(filterModel.rfids),
    },
  }
}
