import React, { FunctionComponent, Fragment, useEffect } from "react"
import { Grid, useTheme, Tooltip, Fab, Typography, makeStyles, Theme } from "@material-ui/core"
import { useTranslation } from "react-i18next"
import { useQuery } from "react-apollo"
import {
  CitizensForTownResult,
  CITIZENS_FOR_TOWN_QUERY,
  CitizensForTownVariables,
  CitizenForTown,
} from "../../../api/graphql/query/citizens-for-town"
import { ClipLoader } from "react-spinners"
import { IColumnHeader, EColumnHeaderType, cellForRow, createColumns, DefaultTable } from "../table/DefaultTable"
import { DerivedDataObject, Column } from "react-table"
import { Edit } from "@material-ui/icons"
import lodash from "lodash"
import { UserAndPermissionsTown } from "../../../api/graphql/query/permitted-associations-for-user"
import { citizensForTown_town_citizens_detailedAddress } from "../../../api/graphql/generated/citizensForTown"
import { CitizenCommunicationChannel } from "../../../api/graphql/generated/globalTypes"
import { compareDetailedAddress } from "../../../utils/compare-detailed-address"

const useStyles = makeStyles((theme: Theme) => ({
  preferredCommunicationChannel: {
    fontSize: 10,
    height: 12,
    width: 12,
    backgroundColor: theme.palette.primary.light,
    borderRadius: 90,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "white",
    fontWeight: 700,
  },
  preferredCommunicationChannelText: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    marginLeft: 5,
  },
  rfidText: {
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
}))

interface ICitizenTabelForTownProps {
  town: UserAndPermissionsTown
  setCitizenToEdit: (citizen: CitizenForTown) => void
  toggleRefetch: boolean
}

export const CitizenTabelForTown: FunctionComponent<ICitizenTabelForTownProps> = (props) => {
  const { t } = useTranslation()
  const { town, toggleRefetch, setCitizenToEdit } = props
  const theme = useTheme()
  const classes = useStyles()

  const { data, error, loading, refetch } = useQuery<CitizensForTownResult, CitizensForTownVariables>(
    CITIZENS_FOR_TOWN_QUERY,
    {
      variables: { id: town.id },
    },
  )

  useEffect(() => {
    refetch()
  }, [toggleRefetch, refetch])

  if (error) {
    console.log(error)
  }

  const preferredCommunicationChannelCell = (text: String) => (
    <Grid container direction="row" alignItems="center">
      <Grid item className={classes.preferredCommunicationChannel}>
        b
      </Grid>
      <Grid item xs className={classes.preferredCommunicationChannelText}>
        {text}
      </Grid>
    </Grid>
  )

  const columnHeaders: IColumnHeader[] = [
    {
      title: t("citizen.property.lastName"),
      type: EColumnHeaderType.STRING,
      accessor: "lastName",
    },
    {
      title: t("citizen.property.firstName"),
      type: EColumnHeaderType.STRING,
      accessor: "firstName",
    },
    {
      title: t("citizen.property.rfids"),
      type: EColumnHeaderType.CUSTOM,
      accessor: "rfids",
      customTitle: (rfids: string[]) => (
        <Tooltip title={rfids.join(", ")}>
          <Typography className={classes.rfidText}>{rfids.join(", ")}</Typography>
        </Tooltip>
      ),
    },
    {
      title: t("citizen.property.address"),
      type: EColumnHeaderType.CUSTOM,
      accessor: "detailedAddress",
      customTitle: (detailedAddress: citizensForTown_town_citizens_detailedAddress) =>
        detailedAddress
          ? `${detailedAddress.street} ${detailedAddress.streetNumber}, ${detailedAddress.postalcode} ${detailedAddress.city}`
          : "",
      sortMethod: compareDetailedAddress,
    },
    {
      title: t("citizen.property.phone1"),
      type: EColumnHeaderType.CUSTOM,
      accessor: "phones[0]",
      customTitle: (phone: string, citizen) =>
        citizen._original.preferredCommunicationChannels?.includes(CitizenCommunicationChannel.phone)
          ? preferredCommunicationChannelCell(phone)
          : phone,
    },
    {
      title: t("citizen.property.phone2"),
      type: EColumnHeaderType.STRING,
      accessor: "phones[1]",
    },
    {
      title: t("citizen.property.email"),
      type: EColumnHeaderType.CUSTOM,
      accessor: "email",
      customTitle: (email: string, citizen) =>
        citizen._original.preferredCommunicationChannels?.includes(CitizenCommunicationChannel.email)
          ? preferredCommunicationChannelCell(email)
          : email,
    },
  ]

  const citizens = lodash.get(data, "town.citizens", []) as CitizenForTown[]

  citizens.sort((a: CitizenForTown, b: CitizenForTown) => {
    if (String(a.lastName) < String(b.lastName)) {
      return -1
    }
    if (String(a.lastName) > String(b.lastName)) {
      return 1
    }
    return 0
  })

  const columns: Column[] = createColumns(
    columnHeaders
      .map((column) => {
        return {
          Header: column.title,
          accessor: column.accessor,
          Cell: ({ row }: { row: DerivedDataObject }) => {
            return cellForRow(column, row)
          },
          sortMethod: column.sortMethod,
        } as Column
      })
      .concat({
        Header: "",
        accessor: "",
        Cell: ({ row }: { row: DerivedDataObject }) => {
          return (
            <Grid container item direction="row" spacing={2} justify="flex-end">
              <Grid item>
                <Tooltip title={t("general.edit")}>
                  <Fab size="small" color="primary" onClick={() => setCitizenToEdit(row._original)}>
                    <Edit />
                  </Fab>
                </Tooltip>
              </Grid>
            </Grid>
          )
        },
      }),
  )

  const numberOfCitizens = lodash.isArray(citizens) ? citizens.length : 0
  return (
    <Fragment>
      {numberOfCitizens > 0 && !loading && (
        <DefaultTable
          columns={columns}
          loading={loading}
          data={citizens}
          minRows={1}
          defaultPageSize={10}
          showPagination={true}
        />
      )}
      {loading && (
        <Grid container justify="center">
          <ClipLoader color={theme.palette.primary.main} />
        </Grid>
      )}
      {numberOfCitizens === 0 && !loading && (
        <Grid container justify="center">
          <Typography>{t("citizen_management.no_citizens")}</Typography>
        </Grid>
      )}
    </Fragment>
  )
}
