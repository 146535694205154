import { gql } from "apollo-boost"
import { HousingManagements, HousingManagements_housingManagements } from "../generated/HousingManagements"

export const HOUSING_MANAGEMENTS_QUERY = gql`
  query HousingManagements {
    housingManagements {
      id
      name
      rfids
    }
  }
`

export interface HousingManagementsResult extends HousingManagements {}
export interface HousingManagement extends HousingManagements_housingManagements {}
