import React, { FunctionComponent, useRef, useEffect, useMemo } from "react"
import { Tour, TownForTour } from "../../../api/graphql/query/tours"
import { createColumns, DefaultTable, EColumnHeaderType, cellForRow, IColumnHeader } from "../table/DefaultTable"
import { Column, DerivedDataObject, TableProps } from "react-table"
import moment from "moment"
import { Fab, makeStyles, Theme, IconButton } from "@material-ui/core"
import { Edit, OpenInNewRounded, Clear, Check } from "@material-ui/icons"
import { useTranslation } from "react-i18next"
import { titleForTourType } from "../../../domain/models/TourTypes"
import { sortASC } from "../../../utils/sort"
import { tours_tours } from "../../../api/graphql/generated/tours"

interface ITourTableProps extends Partial<TableProps> {
  tours: Tour[]
  loading: boolean
  onAction?: (tour: Tour) => void
  compact?: boolean
}

const sortTours = (a: tours_tours, b: tours_tours) => {
  let result = sortASC(a.towns[0]?.association.name, b.towns[0]?.association.name)

  if (result !== 0) {
    return result
  }

  result = sortASC(a.towns[0]?.name, b.towns[0]?.name)

  if (result !== 0) {
    return result
  }

  return sortASC(a.tourStart, b.tourStart)
}

const sortToursCompact = (a: tours_tours, b: tours_tours) => {
  let result = sortASC(a.tourStart, b.tourStart)

  if (result !== 0) {
    return result
  }

  result = sortASC(a.towns[0]?.association.name, b.towns[0]?.association.name)

  if (result !== 0) {
    return result
  }

  return sortASC(a.towns[0]?.name, b.towns[0]?.name)
}

const useStyles = makeStyles((theme: Theme) => ({
  fab: {
    margin: theme.spacing(1),
  },
}))

export const TourTable: FunctionComponent<ITourTableProps> = (props) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { tours, loading, onAction, compact, ...rest } = props

  const tableData = tours
    .map((tour) => {
      return {
        ...tour,
        customTimeRange: `${moment(tour.tourStart).format("HH:mm")} - ${moment(tour.tourEnd).format("HH:mm")}`,
      }
    })
    .sort(compact ? sortToursCompact : sortTours)

  const tourToScrollTo = tableData.find((tour) => moment(tour.tourStart).isAfter(moment().startOf("day")))

  const tableRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    tableRef?.current?.scrollIntoView()
  }, [])

  const tourColumnHeaders: IColumnHeader[] = [
    {
      title: t("tour.property.association"),
      type: EColumnHeaderType.STRING,
      accessor: "towns[0].association.name",
      isInCompactView: true,
    },
    {
      title: t("tour.property.towns"),
      type: EColumnHeaderType.CUSTOM,
      accessor: "towns",
      customTitle: (towns: TownForTour[]) => towns.map((town) => town.name).join(", "),
      isInCompactView: true,
    },
    {
      title: t("tour.property.tourType"),
      type: EColumnHeaderType.CUSTOM,
      accessor: "tourType",
      customTitle: (value: string) => titleForTourType(value, t),
      isInCompactView: true,
    },
    {
      title: t("tour.property.date"),
      type: EColumnHeaderType.DATE,
      accessor: "tourStart",
      isInCompactView: true,
    },
    {
      title: t("tour.property.timePeriod"),
      type: EColumnHeaderType.STRING,
      accessor: "customTimeRange",
      isInCompactView: true,
    },
    {
      title: t("tour.property.wasteScanner"),
      type: EColumnHeaderType.STRING,
      accessor: "vehicle.wasteScannerId",
      isInCompactView: false,
    },
    {
      title: t("tour.property.rfidReader"),
      type: EColumnHeaderType.STRING,
      accessor: "rfidReader",
      isInCompactView: false,
    },
    {
      title: t("tour.property.geocept_device"),
      type: EColumnHeaderType.STRING,
      accessor: "geoCeptImei",
      isInCompactView: false,
    },
    {
      title: t("tour.property.is_splitted"),
      type: EColumnHeaderType.CUSTOM,
      accessor: "includesSplittedImages",
      customTitle: (includesSplittedImages: boolean) => (includesSplittedImages ? <Check /> : <Clear />),
      isInCompactView: false,
    },
    {
      title: t("tour.tableColumn.analysis_count"),
      type: EColumnHeaderType.INT,
      accessor: "materialAnalysisCount",
      isInCompactView: false,
    },
    {
      title: t("tour.tableColumn.analysis_count_split_all"),
      type: EColumnHeaderType.INT,
      accessor: "materialAnalysisCountSplitAll",
      isOnlyInCompactView: true,
    },
    {
      title: t("tour.tableColumn.analysis_count_valid"),
      type: EColumnHeaderType.INT,
      accessor: "materialAnalysisCountValid",
      isOnlyInCompactView: true,
    },
    {
      title: t("tour.tableColumn.analysis_has_rfid_readings"),
      type: EColumnHeaderType.CUSTOM,
      accessor: "materialAnalysisCountHasRfidReadings",
      isOnlyInCompactView: true,
      customTitle: (count: number) => (count > 0 ? <Check /> : <Clear />),
    },
  ].filter((header) => {
    if (compact) {
      return !!header.isInCompactView || !!header.isOnlyInCompactView
    } else {
      return !header.isOnlyInCompactView
    }
  })

  const columnsRaw = tourColumnHeaders.map((column) => {
    return {
      Header: column.title,
      accessor: column.accessor,
      Cell: ({ row }: { row: DerivedDataObject }) => cellForRow(column, row),
    }
  })

  const columns: Column[] = useMemo(
    () =>
      createColumns(
        compact
          ? columnsRaw.concat({
              Header: "",
              accessor: "id",
              Cell: ({ row }: { row: DerivedDataObject }) => (
                <div ref={row.id === tourToScrollTo?.id ? tableRef : undefined}>
                  <IconButton
                    color={"secondary"}
                    size={"small"}
                    aria-label="edit"
                    className={classes.fab}
                    onClick={() => onAction && onAction(row._original)}
                    disabled={row.materialAnalysisCount <= 0}
                  >
                    <OpenInNewRounded />
                  </IconButton>
                </div>
              ),
            })
          : columnsRaw.concat({
              Header: t("tour.tableColumn.action"),
              accessor: "id",
              Cell: ({ row }: { row: DerivedDataObject }) => (
                <Fab
                  color="primary"
                  size={"small"}
                  aria-label="edit"
                  className={classes.fab}
                  onClick={() => onAction && onAction(row._original)}
                >
                  <Edit />
                </Fab>
              ),
            }),
      ),
    [compact, columnsRaw, classes, onAction, t],
  )

  return (
    <DefaultTable columns={columns} loading={loading} data={tableData} minRows={1} defaultPageSize={10} {...rest} />
  )
}
