import React, { FunctionComponent, useState, Fragment } from "react"
import { UserAndPermissionsTown } from "../../../api/graphql/query/permitted-associations-for-user"
import { TownExpansionPanel } from "../citizen-management/TownExpansionPanel"
import { RfidGroupTableWrapper } from "./RfidGroupTableWrapper"
import { UpdateRfidGroupDialog } from "./dialogs/UpdateRfidGroupDialog"
import { DeleteRfidGroupDialog } from "./dialogs/DeleteRfidGroupDialog"
import { CreateRfidGroupDialog } from "./dialogs/CreateRfidGroupDialog"
import { CreateDefaultRfidGroupsDialog } from "./dialogs/CreateDefaultRfidGroupsDialog"
import { Button, Grid, makeStyles, Theme } from "@material-ui/core"
import { RfidGroup } from "../../../api/graphql/query/rfid-groups-for-town"
import { useTranslation } from "react-i18next"
import GridItem from "../layout/GridItem"

const useStyles = makeStyles((theme: Theme) => ({
  rfidGroupButtons: {
    paddingBottom: theme.spacing(3),
  },
}))

interface ITownExpansionPanelWithRfidGroupTableProps {
  town: UserAndPermissionsTown
}

export const TownExpansionPanelWithRfidGroupTable: FunctionComponent<ITownExpansionPanelWithRfidGroupTableProps> = (
  props,
) => {
  const { town } = props
  const classes = useStyles()
  const { t } = useTranslation()
  const [open, setOpen] = useState<boolean>(false)
  const [toggleRefetch, setToggleRefetch] = useState<boolean>(false)
  const [rfidGroupToEdit, setRfidGroupToEdit] = useState<RfidGroup>()
  const [rfidGroupToDelete, setRfidGroupToDelete] = useState<RfidGroup>()
  const [toggleCreateDialog, setToggleCreateDialog] = useState<boolean>(false)
  const [toggleCreateDefaultDialog, setToggleCreateDefaultDialog] = useState<boolean>(false)

  const onEditDialogClose = () => {
    setRfidGroupToEdit(undefined)
  }

  const onEditDialogSuccess = () => {
    setToggleRefetch(!toggleRefetch)
    onEditDialogClose()
  }

  const onDeleteDialogClose = () => {
    setRfidGroupToDelete(undefined)
  }

  const onDeleteDialogSuccess = () => {
    setToggleRefetch(!toggleRefetch)
    onDeleteDialogClose()
  }

  const onCreateDialogSuccess = () => {
    setToggleCreateDialog(false)
    setToggleRefetch(true)
  }

  const onCreateDefaultDialogSuccess = () => {
    setToggleCreateDefaultDialog(false)
    setToggleRefetch(true)
  }

  return (
    <Fragment key={town.id}>
      {/* DIALOGS */}
      {!!rfidGroupToEdit && (
        <UpdateRfidGroupDialog
          isOpen={!!rfidGroupToEdit}
          onCloseDialog={() => setRfidGroupToEdit(undefined)}
          rfidGroup={rfidGroupToEdit}
          onSuccess={onEditDialogSuccess}
        />
      )}
      {!!rfidGroupToDelete && (
        <DeleteRfidGroupDialog
          isOpen={!!rfidGroupToDelete}
          onCloseDialog={() => setRfidGroupToDelete(undefined)}
          rfidGroup={rfidGroupToDelete}
          onSuccess={onDeleteDialogSuccess}
        />
      )}
      {toggleCreateDialog && (
        <CreateRfidGroupDialog
          isOpen={toggleCreateDialog}
          onCloseDialog={() => setToggleCreateDialog(false)}
          onSuccess={onCreateDialogSuccess}
          town={town}
        />
      )}
      {toggleCreateDefaultDialog && (
        <CreateDefaultRfidGroupsDialog
          isOpen={toggleCreateDefaultDialog}
          onCloseDialog={() => setToggleCreateDefaultDialog(false)}
          onSuccess={onCreateDefaultDialogSuccess}
          town={town}
        />
      )}
      {/* VIEW */}
      <TownExpansionPanel open={open} toggle={() => setOpen(!open)} town={town}>
        <Grid item xs={12} container justify={"flex-end"} className={classes.rfidGroupButtons}>
          <GridItem>
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                setToggleCreateDefaultDialog(true)
              }}
            >
              {t("rfid_groups.create_default_rfid_groups_button")}
            </Button>
          </GridItem>
          <GridItem>
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                setToggleCreateDialog(true)
              }}
            >
              {t("rfid_groups.add_rfid_group_button")}
            </Button>
          </GridItem>
        </Grid>
        <RfidGroupTableWrapper
          setRfidGroupToEdit={setRfidGroupToEdit}
          setRfidGroupToDelete={setRfidGroupToDelete}
          toggleRefetch={toggleRefetch}
          town={town}
        />
      </TownExpansionPanel>
    </Fragment>
  )
}
