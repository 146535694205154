import React, { FunctionComponent } from "react"
import { Grid, makeStyles, Theme, Typography } from "@material-ui/core"
import Paper from "@material-ui/core/Paper"
import { UserAndPermissionsAssociation } from "../../../api/graphql/query/users-and-assigned-associations"
import { useTranslation } from "react-i18next"

const useStyles = makeStyles((theme: Theme) => ({
  infoAllTowns: {
    fontSize: 14,
    color: theme.palette.secondary.main
  },
  associationSection: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1)
  },
  info: {
    fontSize: 14
  },
  label: {
    borderRadius: `${theme.spacing(0.5)}px 0 ${theme.spacing(0.5)}px 0`,
    width: "fit-content",
    paddingRight: theme.spacing(1),
    paddingLeft: theme.spacing(1),
    fontSize: 12,
    backgroundColor: theme.palette.primary.main,
    color: "#ffffff"
  },
  paper: {
    background: theme.palette.secondary.light,
    height: "100%",
    paddingTop: 0,
    paddingBottom: theme.spacing(1)
  }
}))

interface ICardListForAssociationsProps {
  associations: UserAndPermissionsAssociation[]
}

export const CardListForAssociations: FunctionComponent<
  ICardListForAssociationsProps
> = props => {
  const classes = useStyles()
  const { t } = useTranslation()

  const { associations } = props

  return (
    <Grid container spacing={1}>
      {associations.map((association, index) => (
        <Grid item key={`${association.id}${index}`}>
          <Paper className={classes.paper}>
            <Typography className={classes.label}>
              {association.name}
            </Typography>
            <section className={classes.associationSection}>
              {association.accessToAllTowns ? (
                <Typography className={classes.infoAllTowns}>
                  {t("user_management.all_towns_assigned")}
                </Typography>
              ) : (
                association.towns.map((town, index) => (
                  <Grid item key={`${town.id}${index}`}>
                    <Typography className={classes.info}>
                      {town.name}
                    </Typography>
                  </Grid>
                ))
              )}
            </section>
          </Paper>
        </Grid>
      ))}
    </Grid>
  )
}
