import { gql } from "apollo-boost"
import {
  reports,
  reports_reports,
  reports_reports_communicationHistory,
  reports_reports_filter,
  reportsVariables,
} from "../generated/reports"

export const REPORTS_QUERY = gql`
  query reports($associationId: ID!, $townId: ID) {
    reports(associationId: $associationId, townId: $townId) {
      id
      title
      createdAt
      filter {
        id
        tourType
      }
      widgets {
        id
        title
        filter {
          tourType
        }
      }
      communicationHistory {
        level
        type
        time
        payload {
          reportId
        }
      }
    }
  }
`

export type ReportsVariables = reportsVariables
export type ReportsResult = reports
export type Report = reports_reports
export type ReportFilter = reports_reports_filter
export type ReportCommunicationHistoryEntry = reports_reports_communicationHistory
