import { gql } from "apollo-boost"
import { updateUser, updateUserVariables } from "../generated/updateUser"

export const UPDATE_USER_MUTATION = gql`
  mutation updateUser($userAndPermissions: UserAndPermissionsInputUpdate!) {
    updateUser(userAndPermissions: $userAndPermissions) {
      id
      email
      abilities
    }
  }
`

export type UpdateUserResult = updateUser
export type UpdateUserVariables = updateUserVariables
