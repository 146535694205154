import React, { FunctionComponent, useState, useEffect, useCallback, useContext } from "react"
import {
  Theme,
  makeStyles,
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  DialogActions,
  Button,
} from "@material-ui/core"
import { useTranslation } from "react-i18next"
import { UserAndPermissionsAssociation } from "../../../api/graphql/query/permitted-associations-for-user"
import { useLazyQuery } from "react-apollo"
import { COLORS } from "../../../theme/theme"
import {
  citizensXLSXResult,
  citizensXLSXVariables,
  CITIZENS_XLSX_FOR_TOWN_QUERY,
} from "../../../api/graphql/query/citizens-export"
import { PermittedAssociationsContext } from "../../../context/PermittedAssociationsContext"

const useStyles = makeStyles((theme: Theme) => ({
  content: {
    width: 800,
  },
  row: {
    marginBottom: theme.spacing(2),
  },
  checkIcon: {
    color: COLORS.lime,
    fontSize: 50,
    marginRight: theme.spacing(2),
  },
  loadingIndicator: {
    marginTop: theme.spacing(4),
  },
}))

interface IExportCitizensDialogProps {
  open: boolean
  onClose: () => void
  associations: UserAndPermissionsAssociation[]
}

export const ExportCitizensDialog: FunctionComponent<IExportCitizensDialogProps> = (props) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { open, onClose } = props

  const { permittedAssociations } = useContext(PermittedAssociationsContext)
  const [selectedAssociation, setSelectedAssociation] = useState<UserAndPermissionsAssociation | undefined>(
    permittedAssociations[0],
  )
  const [selectedTownId, setSelectedTownId] = useState<string>(permittedAssociations[0]?.towns[0]?.id || "")

  const downloadExportedExcel = (data: any) => {
    const base64 = data?.citizensXLSXForTown?.base64

    if (base64) {
      saveAs(new Blob([Buffer.from(base64, "base64")]), "citizens-export.xlsx")
    }
    onClose()
  }

  const [citizensExcelExportQuery, { loading }] = useLazyQuery<citizensXLSXResult, citizensXLSXVariables>(
    CITIZENS_XLSX_FOR_TOWN_QUERY,
    {
      fetchPolicy: "no-cache",
      onCompleted: downloadExportedExcel,
      onError: console.log,
    },
  )

  const onExportButtonClicked = (event: any) => {
    citizensExcelExportQuery({
      variables: {
        townId: selectedTownId as string,
      },
    })
  }

  const handleChangeAssociationId = useCallback(
    (associationId: string) => {
      const association = permittedAssociations.find((association) => association.id === associationId)

      if (association) {
        setSelectedAssociation(association)
        setSelectedTownId(association.towns[0].id)
      }
    },
    [permittedAssociations],
  )

  useEffect(() => {
    if (open) {
      setSelectedAssociation(permittedAssociations[0])
      setSelectedTownId(permittedAssociations[0]?.towns[0]?.id || "")
    }
  }, [open, setSelectedAssociation, setSelectedTownId, permittedAssociations])

  return (
    <Dialog maxWidth="xl" onClose={onClose} aria-labelledby="simple-dialog-title" open={open}>
      <DialogTitle id="simple-dialog-title">{t("citizen_management.export_citizens_dialog.title")}</DialogTitle>
      <DialogContent className={classes.content}>
        {selectedAssociation && (
          <Grid container spacing={2} className={classes.row}>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <InputLabel id="inputlabel-association">
                  {t("citizen_management.export_citizens_dialog.association_label")}
                </InputLabel>
                <Select
                  value={selectedAssociation?.id || ""}
                  onChange={(event) => handleChangeAssociationId(event.target.value as string)}
                  disabled={loading}
                >
                  {permittedAssociations.map((association) => (
                    <MenuItem key={association.id} value={association.id}>
                      {association.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <InputLabel id="inputlabel-town">
                  {t("citizen_management.export_citizens_dialog.town_label")}
                </InputLabel>
                <Select
                  value={selectedTownId || ""}
                  onChange={(event) => {
                    setSelectedTownId(event.target.value as string)
                  }}
                  disabled={loading}
                >
                  {selectedAssociation.towns.map((town) => (
                    <MenuItem key={town.id} value={town.id as string}>
                      {town.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        )}
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={onClose}>
          {t("general.cancel")}
        </Button>
        <Button
          disabled={loading || !selectedTownId || !selectedAssociation?.id}
          color="primary"
          variant={"contained"}
          onClick={onExportButtonClicked}
        >
          {t("citizen_management.export_citizens_dialog.export")}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
