import React, { FunctionComponent, Fragment, useContext } from "react"
import { Theme, makeStyles, Grid, Paper } from "@material-ui/core"
import { AnalysisFilterContext } from "../../context/AnalysisFilterContext"
import { AnalysisUrlProvider } from "../../context/AnalysisUrlContext"
import { AnalysisPageContent } from "../partials/analysis/AnalysisPageContent"
import { AnalysisFilter } from "../partials/analysis-filter/AnalysisFilter"
import { AnalysisAdminFilter } from "../partials/analysis-filter/AnalysisAdminFilter"
import { PageLoadingIndicator } from "../partials/indicator/PageLoadingIndicator"
import { NoDataErrorIndicator } from "../partials/indicator/NoDataErrorIndicator"
import { COLORS } from "../../theme/theme"
import { AbilityName } from "../../api/graphql/generated/globalTypes"
import { AuthContext } from "../../context/AuthContext"
import { AnalysisPageNoAssociationsIndicator } from "../partials/analysis/AnalysisPageNoAssociationsIndicator"

const useStyles = makeStyles((theme: Theme) => ({
  filter: {
    position: "fixed",
    top: 0,
    zIndex: 10,
    [theme.breakpoints.up("md")]: {
      width: "calc(100% - (260px))",
    },
    padding: 0,
  },
  filterGridItem: {
    padding: "0 15 !important",
    marginBottom: theme.spacing(1),
  },
  paper: {
    padding: theme.spacing(2),
    justifyContent: "center",
    color: theme.palette.text.secondary,
    borderRadius: theme.spacing(0),
    marginTop: 0,
    position: "relative",
  },
  upperPaper: {
    zIndex: 999,
  },
  lowerPaper: {
    background: COLORS.lightgrey,
    zIndex: 998,
  },
}))

interface IAnalysisPageProps {}

export const AnalysisPage: FunctionComponent<IAnalysisPageProps> = (props) => {
  const classes = useStyles()
  const { hasOneRequiredAbility } = useContext(AuthContext)

  const { userAndPermissionsLoading, userAndPermissionsError, selectedAssociation } = useContext(AnalysisFilterContext)

  return (
    <Fragment>
      {!selectedAssociation && !userAndPermissionsLoading && <AnalysisPageNoAssociationsIndicator />}
      {!selectedAssociation && userAndPermissionsLoading && <PageLoadingIndicator />}
      {selectedAssociation && !userAndPermissionsLoading && userAndPermissionsError && <NoDataErrorIndicator />}
      {selectedAssociation && !userAndPermissionsLoading && !userAndPermissionsError && (
        <Grid container className={classes.filter} direction={"column"} justify={"center"} alignItems={"stretch"}>
          <Grid item xs={12} className={classes.filterGridItem}>
            <Paper elevation={1} className={`${classes.paper} ${classes.upperPaper}`}>
              <AnalysisFilter />
            </Paper>
            {hasOneRequiredAbility([AbilityName.admin]) && (
              <Paper elevation={1} className={`${classes.paper} ${classes.lowerPaper}`}>
                <AnalysisAdminFilter />
              </Paper>
            )}
          </Grid>
        </Grid>
      )}

      {selectedAssociation && !userAndPermissionsLoading && !userAndPermissionsError && (
        <AnalysisUrlProvider>
          <AnalysisPageContent />
        </AnalysisUrlProvider>
      )}
    </Fragment>
  )
}
