import { createMuiTheme } from "@material-ui/core"

export const COLORS = {
  turquoise: "#17BEBB",
  orange: "#F77F00",
  lime: "#9BE564",
  pink: "#EF476F",
  yellow: "#FFC857",
  primary: "#C20E1A",
  lightgrey: "#EFEFEF",
  disabled: "#e0e0e0",
  disabledForeground: "#a6a6a6",
  green: "#4caf50",
  darkred: "#ab003c",
  red: "#C20E1A"
}

export const theme = {
  palette: {
    primary: { main: COLORS.primary },
    secondary: { dark: "#41474c", main: "#9a9da0", light: "#f7f7f7 " },
    error: { main: COLORS.yellow, dark: COLORS.red },
    success: { main: COLORS.green },
  },
  paper: {
    radius: "1px",
  },
  button: {
    radius: "2px",
  },
}
export const muiTheme = createMuiTheme(theme)
