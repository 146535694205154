import { gql } from "apollo-boost"
import {
  dailyGroupedStatsComparisonForAnalysis,
  dailyGroupedStatsComparisonForAnalysisVariables,
  dailyGroupedStatsComparisonForAnalysis_dailyGroupedStatsComparisonForAnalysis,
} from "../generated/dailyGroupedStatsComparisonForAnalysis"
import {
  FRAGMENT_DAILY_GROUPED_STATS_FOR_ANALYSIS,
  FRAGMENT_DAILY_GROUPED_STATS_FOR_ANALYSIS_WITH_TOWN,
} from "../fragments/daily_grouped_stats_for_analysis"

export const DAILY_GROUPED_STATS_COMPARISON_FOR_ANALYSIS_QUERY = gql`
  query dailyGroupedStatsComparisonForAnalysis(
    $tourType: TourType
    $associationIds: [ID]
    $displayOnlyAutomaticAnalysis: Boolean
    $townIds: [ID!]!
    $tourIds: [ID]
    $firstDate: Date
    $lastDate: Date
    $fractions: [FractionFilter]
    $rating: Rating
    $disableMapping: Boolean
    $simplifyEvaluation: Boolean
  ) {
    dailyGroupedStatsComparisonForAnalysis(
      tourType: $tourType
      associationIds: $associationIds
      displayOnlyAutomaticAnalysis: $displayOnlyAutomaticAnalysis
      townIds: $townIds
      tourIds: $tourIds
      firstDate: $firstDate
      lastDate: $lastDate
      fractions: $fractions
      rating: $rating
      disableMapping: $disableMapping
      simplifyEvaluation: $simplifyEvaluation
    ) {
      townStats {
        town {
          id
          name
        }
        stats {
          ...FragmentDailyGroupedStatsForAnalysis
        }
      }
      overallStats {
        ...FragmentDailyGroupedStatsForAnalysisWithTown
      }
    }
  }
  ${FRAGMENT_DAILY_GROUPED_STATS_FOR_ANALYSIS}
  ${FRAGMENT_DAILY_GROUPED_STATS_FOR_ANALYSIS_WITH_TOWN}
`
export type DailyGroupedStatsComparisonForAnalysisQueryResult = dailyGroupedStatsComparisonForAnalysis
export type DailyGroupedStatsComparisonForAnalysisQueryVariables = dailyGroupedStatsComparisonForAnalysisVariables
export type DailyGroupedStatsComparisonForAnalysis = dailyGroupedStatsComparisonForAnalysis_dailyGroupedStatsComparisonForAnalysis
