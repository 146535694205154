import React from "react"
import PropTypes from "prop-types"
import { withTranslation } from "react-i18next"

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles"
import { MuiPickersUtilsProvider, TimePicker } from "@material-ui/pickers"
import moment from "moment"
import { ArrowLeft, ArrowRight } from "@material-ui/icons"

// @Styles
const styles = (theme) => ({
  menu: {
    width: 200,
  },
  textField: {
    height: 40,
    marginTop: "16px",
    marginBottom: "8px",
  },
})

class WertstoffTimePicker extends React.Component {
  constructor(props) {
    super(props)
    this.props = props
  }

  componentWillReceiveProps(nextProps) {
    this.props = nextProps
  }

  handleChange = (date) => {
    const event = { target: { value: date } }
    this.props.onChange(event)
  }

  render() {
    const { classes, name, value, disableFuture, minDate, maxDate, t } = this.props
    return (
      <MuiPickersUtilsProvider utils={MomentUtils} libInstance={moment} locale={language}>
        <TimePicker
          label={name}
          disableFuture={disableFuture}
          maxDateMessage="Zeitraum darf nicht in der Zukunft liegen"
          value={value}
          minDate={minDate}
          maxDate={maxDate}
          onChange={(value) => this.handleChange(value)}
          clearable
          autoOk
          clearLabel={t("general.delete")}
          cancelLabel={t("general.cancel")}
          okLabel={"Ok"}
          leftArrowIcon={<ArrowLeft color={"primary"} />}
          rightArrowIcon={<ArrowRight color={"primary"} />}
          fullWidth
          margin="normal"
          className={classes.textField}
        />
      </MuiPickersUtilsProvider>
    )
  }
}

WertstoffTimePicker.propTypes = {
  name: PropTypes.string,
  value: PropTypes.string,
  disableFuture: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  error: PropTypes.bool,
  disabled: PropTypes.bool,
  allPossible: PropTypes.bool,
  minDate: PropTypes.string,
  maxDate: PropTypes.string,
}

WertstoffTimePicker.defaultProps = {
  value: null,
  values: [],
  error: false,
  disabled: false,
  allPossible: true,
  minDate: null,
  maxDate: null,
}

export default withStyles(styles)(withTranslation()(WertstoffTimePicker))
