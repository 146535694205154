import { ChannelType, ExternalTownType } from "../../api/graphql/generated/globalTypes"

export interface CommunicationChannel {
  externalTownType?: ExternalTownType
  title: string
  maxNumOfTextWidgets: number
  canHaveGraphics: boolean
  canHaveIndividualText: boolean
  hasSendButton: boolean
  hasDownloadButton: boolean
  hasTestButton: boolean
}

export const CommunicationChannelTypeDictionary: { [key: string]: CommunicationChannel } = {
  [ChannelType.daheim_app]: {
    externalTownType: ExternalTownType.daheim,
    title: "communication_channels.daheim_app",
    maxNumOfTextWidgets: 1,
    canHaveGraphics: true,
    canHaveIndividualText: false,
    hasSendButton: true,
    hasDownloadButton: true,
    hasTestButton: false,
  },
  [ChannelType.symvaro]: {
    externalTownType: ExternalTownType.symvaro,
    title: "communication_channels.symvaro",
    maxNumOfTextWidgets: 1,
    canHaveGraphics: true,
    canHaveIndividualText: false,
    hasSendButton: true,
    hasDownloadButton: true,
    hasTestButton: false,
  },
  [ChannelType.newspaper]: {
    title: "communication_channels.newspaper",
    canHaveGraphics: true,
    canHaveIndividualText: false,
    hasSendButton: false,
    hasDownloadButton: true,
    hasTestButton: false,
    maxNumOfTextWidgets: 0,
  },
  [ChannelType.sms]: {
    title: "communication_channels.sms",
    maxNumOfTextWidgets: 1,
    canHaveGraphics: false,
    canHaveIndividualText: true,
    hasSendButton: true,
    hasDownloadButton: false,
    hasTestButton: true,
  },
  [ChannelType.email]: {
    title: "communication_channels.email",
    maxNumOfTextWidgets: 1,
    canHaveGraphics: false,
    canHaveIndividualText: true,
    hasSendButton: true,
    hasDownloadButton: false,
    hasTestButton: true,
  },
}
