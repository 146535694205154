import React, { FunctionComponent, useMemo } from "react"
import { Theme, makeStyles, Card, Grid, Divider, Typography } from "@material-ui/core"
import { useTranslation } from "react-i18next"
import { OVERALL_DATA_KEY, TREND_DATA_KEY } from "./trend-line"

const useStyles = makeStyles((theme: Theme) => ({
  card: {
    padding: theme.spacing(1),
    width: "35%",
  },
  overallIndicator: {
    width: 10,
    height: 10,
    borderRadius: "50%",
    background: (props: any) => props.color,
  },
  minMaxIndicator: {
    width: 10,
    height: 10,
    background: (props: any) => props.color,
  },
  trendIndicator: {
    width: 3,
    height: 5,
    background: (props: any) => props.color,
  },
  trendMiddleIndicator: {
    width: 2,
    height: 5,
    background: "transparent",
  },
  typo: {
    color: (props: any) => props.color,
  },
}))

interface IIndicatorProps {
  color: string
}

export const TrendGraphMinMaxIndicator = (props: IIndicatorProps) => {
  const classes = useStyles({ color: props.color })
  return <div className={classes.minMaxIndicator} />
}
export const TrendGraphOverallIndicator = (props: IIndicatorProps) => {
  const classes = useStyles({ color: props.color })
  return <div className={classes.overallIndicator} />
}
export const TrendGraphTrendIndicator = (props: IIndicatorProps) => {
  const classes = useStyles({ color: props.color })
  return (
    <>
      <div className={classes.trendIndicator} />
      <div className={classes.trendMiddleIndicator} />
      <div className={classes.trendIndicator} />
    </>
  )
}

export const WertstoffAnalysisTrendGraphTooltip: FunctionComponent<any> = (props) => {
  const { payload, formatter, label, labelFormatter, separator, color, showTrend } = props
  const classes = useStyles({ color })
  const { t } = useTranslation()

  const { min, max, overall, trend, town } = useMemo(() => {
    const overallItem = payload.find((item: any) => item.name === OVERALL_DATA_KEY)
    const trendItem = payload.find((item: any) => item.name === TREND_DATA_KEY)

    return {
      min: overallItem?.payload?.min?.value ?? 0,
      max: overallItem?.payload?.max?.value ?? 0,
      overall: overallItem?.payload?.overall ?? 0,
      trend: trendItem?.payload?.trend ?? 0,
      town: overallItem?.payload?.town ?? 0,
    }
  }, [payload])



  return (
    <Card className={classes.card}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Typography color="secondary">{labelFormatter(label)}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={1} container justify="center" alignContent="center">
          <TrendGraphOverallIndicator color={color} />
        </Grid>
        <Grid item xs={11}>
          <Typography className={classes.typo}>{`${t("graph.trend.tooltip.overall")} ${separator} ${formatter(
            overall,
          )}`}</Typography>
        </Grid>
        <Grid item xs={1} container justify="center" alignContent="center">
          <TrendGraphMinMaxIndicator color={color} />
        </Grid>
        <Grid item xs={11}>
          <Typography className={classes.typo}>{`${t("graph.trend.tooltip.max")} ${separator} ${formatter(
            max,
          )}`}</Typography>
        </Grid>
        <Grid item xs={1} container justify="center" alignContent="center">
          <TrendGraphMinMaxIndicator color={color} />
        </Grid>
        <Grid item xs={11}>
          <Typography className={classes.typo}>{`${t("graph.trend.tooltip.min")} ${separator} ${formatter(
            min,
          )}`}</Typography>
        </Grid>
        {showTrend && (
          <>
            <Grid item xs={1} container justify="center" alignContent="center">
              <TrendGraphTrendIndicator color={color} />
            </Grid>
            <Grid item xs={11}>
              <Typography className={classes.typo}>{`${t("graph.trend.tooltip.trend")} ${separator} ${formatter(
                trend,
              )}`}</Typography>
            </Grid>
          </>
        )}
        {!!town && (
          <>
            <Grid item xs={12}>
              <Typography className={classes.typo}>{town.name}</Typography>
            </Grid>
          </>
        )}
      </Grid>
    </Card>
  )
}
