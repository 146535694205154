import React, { FunctionComponent, Fragment } from "react"
import { Theme, makeStyles, Fab, Grid, Typography, useTheme, Tooltip } from "@material-ui/core"
import { useTranslation } from "react-i18next"
import { DefaultTable, EColumnHeaderType, IColumnHeader, cellForRow, createColumns } from "../table/DefaultTable"
import { Vehicle, ImageUsage } from "../../../api/graphql/query/vehicles"
import { DerivedDataObject } from "react-table"
import { Edit } from "@material-ui/icons"
import lodash from "lodash"
import { ClipLoader } from "react-spinners"
import { sortASC } from "../../../utils/sort"

const useStyles = makeStyles((theme: Theme) => ({
  fab: {
    margin: theme.spacing(1),
  },
}))

interface IVehicleTableProps {
  vehicles: Vehicle[]
  loading: boolean
  onEdit: (vehicle: Vehicle) => void
}

export const VehicleTable: FunctionComponent<IVehicleTableProps> = (props) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const theme = useTheme()
  const { vehicles, loading, onEdit } = props

  const columnHeaders: IColumnHeader[] = [
    {
      title: t("vehicle.property.wasteScannerId"),
      type: EColumnHeaderType.STRING,
      accessor: "wasteScannerId",
    },
    {
      title: t("vehicle.property.rfidReader"),
      type: EColumnHeaderType.STRING,
      accessor: "rfidReader",
    },
    {
      title: t("vehicle.property.geocept_device"),
      type: EColumnHeaderType.STRING,
      accessor: "geoCeptImei",
    },
    {
      title: t("vehicle.property.number_of_tours"),
      type: EColumnHeaderType.INT,
      accessor: "tourCount",
    },
    {
      title: t("vehicle.property.image_usage"),
      type: EColumnHeaderType.CUSTOM,
      accessor: "imageUsage",
      customTitle: (usage: ImageUsage) =>
        usage.available
          ? t("vehicle.property.image_usage_info", { available: usage.available, used: usage.used })
          : String(usage.used),
    },
  ]

  const columns = createColumns(
    columnHeaders
      .map((column) => {
        return {
          Header: column.title,
          accessor: column.accessor,
          Cell: ({ row }: { row: DerivedDataObject }) => cellForRow(column, row),
        }
      })
      .concat([
        {
          Header: "",
          accessor: "id",
          Cell: ({ row }: { row: DerivedDataObject }) => (
            <Grid container item direction="row" spacing={2} justify="flex-end">
              <Grid item>
                <Tooltip title={t("general.edit")}>
                  <Fab
                    color="primary"
                    size={"small"}
                    aria-label="edit"
                    className={classes.fab}
                    onClick={() => onEdit(row._original)}
                    disabled={!lodash.isEmpty(lodash.get(row, "vehicle"))}
                  >
                    <Edit />
                  </Fab>
                </Tooltip>
              </Grid>
            </Grid>
          ),
        },
      ]),
  )

  const sortedVehicles = vehicles.sort((a, b) => sortASC(a.wasteScannerId, b.wasteScannerId))

  return (
    <Fragment>
      {vehicles.length > 0 && !loading && (
        <DefaultTable
          columns={columns}
          loading={loading}
          data={sortedVehicles}
          minRows={1}
          defaultPageSize={10}
          showPagination={true}
        />
      )}
      {loading && (
        <Grid container justify="center">
          <ClipLoader color={theme.palette.primary.main} />
        </Grid>
      )}
      {vehicles.length === 0 && !loading && (
        <Grid container justify="center">
          <Typography>{t("general.no_data")}</Typography>
        </Grid>
      )}
    </Fragment>
  )
}
