import React, { FunctionComponent, useCallback, useContext, useMemo } from "react"
import { Grid, Typography, Button, makeStyles, Theme } from "@material-ui/core"
import { useTranslation } from "react-i18next"
import { ToggleButtonGroup, ToggleButton } from "@material-ui/lab"
import { titleForTourType, TourTypeInfo } from "../../../domain/models/TourTypes"
import { AnalysisFilterContext } from "../../../context/AnalysisFilterContext"
import { WertstoffAnalysisHistoryGraph } from "../analysis-data/WertstoffAnalysisHistoryGraph"
import { ExcelExportButton } from "./ExcelExportButton"
import { AuthContext } from "../../../context/AuthContext"
import { CustomPaper } from "../paper/custom-paper"
import { MaterialAnalysisForEvaluation } from "../../../api/graphql/query/material-analysis-and-evaluation"
import { Evaluation } from "../../../api/graphql/fragments/evaluation_fragments"

const useStyles = makeStyles((theme: Theme) => ({
  reportCreateButton: {
    minWidth: 180,
  },
}))

interface IAnalysisHistoryGraphSectionProps {
  materialAnalysis: MaterialAnalysisForEvaluation[]
  evaluation: Evaluation
  tourTypeInfo: TourTypeInfo
  onReportCreate: () => void
  historyGraphToggle: boolean
  setHistoryGraphToggle: (value: boolean) => void
  fetchAnalysis: () => void
  analysisLoading: boolean
}

export const AnalysisHistoryGraphSection: FunctionComponent<IAnalysisHistoryGraphSectionProps> = (props) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const {
    analysisLoading,
    fetchAnalysis,
    materialAnalysis,
    evaluation,
    tourTypeInfo,
    onReportCreate,
    historyGraphToggle,
    setHistoryGraphToggle,
  } = props
  const { filterModel } = useContext(AnalysisFilterContext)
  const { isHousingManagement } = useContext(AuthContext)

  const handleOpenToggled = useCallback(
    (open: boolean) => {
      if (open) {
        fetchAnalysis()
      }
    },
    [fetchAnalysis],
  )

  const onHistoryGraphToggleClicked = useCallback(
    (event: React.MouseEvent<HTMLElement, MouseEvent>, value: boolean) => {
      setHistoryGraphToggle(value)
      event.stopPropagation()
    },
    [setHistoryGraphToggle],
  )

  const additionalHeader = useMemo(
    () => (
      <Grid item>
        <ToggleButtonGroup value={historyGraphToggle} size={"small"} exclusive onChange={onHistoryGraphToggleClicked}>
          <ToggleButton value={true}>
            {t("analysis_page.tout_type_title_quota", {
              type: titleForTourType(filterModel.tourType, t),
            })}
          </ToggleButton>
          <ToggleButton value={false}>{t("analysis_page.miss_sortings_quota")}</ToggleButton>
        </ToggleButtonGroup>
      </Grid>
    ),
    [t, filterModel.tourType, historyGraphToggle, onHistoryGraphToggleClicked],
  )
  return (
    <CustomPaper
      headerContent={
        <Grid item xs>
          <Typography variant={"h5"}>{t("analysis_page.seperation_quality_history")}</Typography>
        </Grid>
      }
      additionalHeader={additionalHeader}
      contentLoading={analysisLoading}
      onToggleOpen={handleOpenToggled}
    >
      {materialAnalysis && !analysisLoading && (
        <Grid container spacing={2}>
          {materialAnalysis && (
            <WertstoffAnalysisHistoryGraph
              materialAnalysis={materialAnalysis}
              evaluation={evaluation}
              tourTypeInfo={tourTypeInfo}
              compact={historyGraphToggle}
              grouped={filterModel.emptyingId === null}
            />
          )}
          <Grid item container justify={"flex-end"} spacing={2}>
            <Grid item>
              <ExcelExportButton />
            </Grid>
            {!isHousingManagement() && (
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  aria-label="Bericht erstellen"
                  onClick={onReportCreate}
                  className={classes.reportCreateButton}
                >
                  {t("report.create_report")}
                </Button>
              </Grid>
            )}
          </Grid>
        </Grid>
      )}
    </CustomPaper>
  )
}
