import { gql } from "apollo-boost"
import { rfidChipsXLSXForTown, rfidChipsXLSXForTownVariables } from "../generated/rfidChipsXLSXForTown"

export const RFIDS_XLSX_FOR_TOWN_QUERY = gql`
  query rfidChipsXLSXForTown($townId: ID!) {
    rfidChipsXLSXForTown(townId: $townId) {
      base64
    }
  }
`

export type rfidChipsXLSXResult = rfidChipsXLSXForTown
export type rfidChipsXLSXVariables = rfidChipsXLSXForTownVariables
