import { gql } from "apollo-boost"

export const EVALULATION_MINIMUM_FOR_FILTER_QUERY = gql`
  query EvaluationMinimumForFilter($filterId: ID!) {
    materialAnalysisForFilter(filterId: $filterId) {
      evaluation {
        rating
        materialDistributionWithoutBagsDetailed {
          id
          value
          name
          color
          materialType
        }
      }
    }
  }
`
