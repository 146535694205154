import React, { FunctionComponent, useState } from "react"
import { ButtonGroup, Button, Grid } from "@material-ui/core"
import { useTranslation } from "react-i18next"
import { ExternalTownType } from "../../../api/graphql/generated/globalTypes"
import { ExternalTown } from "../../../api/graphql/query/external-town-search"
import { DaheimTownSelect } from "./DaheimTownSelect"
import { DaheimTown } from "../../../api/graphql/query/daheim-towns"
import { ExternalTownAutocomplete } from "./ExternalTownAutocomplete"

interface IExternalTownSelectProps {
  onChange: (externalTown: ExternalTown | null) => void
}

export const ExternalTownSelect: FunctionComponent<IExternalTownSelectProps> = (props) => {
  const { t } = useTranslation()
  const { onChange } = props

  const [selectedType, setSelectedType] = useState<ExternalTownType | null>(null)
  const [selectedDaheimTown, setSelectedDaheimTown] = useState<DaheimTown | null>(null)
  const [selectedExternalTown, setSelectedExternalTown] = useState<ExternalTown | null>(null)

  const onSelectExternalTown = (externalTown: ExternalTown | null) => {
    setSelectedExternalTown(externalTown)
    onChange(externalTown)
  }

  const onSelectDaheimTown = (daheimTown: DaheimTown | null) => {
    let newExternalTown: ExternalTown | null = null

    if (daheimTown) {
      newExternalTown = {
        id: "-1",
        externalId: daheimTown.id,
        latitude: daheimTown.latitude || null,
        longitude: daheimTown.longitude || null,
        name: daheimTown.name,
        type: ExternalTownType.daheim,
        postalcode: null,
      }
    }
    setSelectedDaheimTown(daheimTown)
    onChange(newExternalTown)
  }

  const onSelectType = (type: ExternalTownType) => {
    setSelectedDaheimTown(null)
    setSelectedExternalTown(null)
    setSelectedType(type)
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <ButtonGroup color="primary" fullWidth>
          {Object.values(ExternalTownType).map((type) => (
            <Button
              variant={type === selectedType ? "contained" : undefined}
              disableElevation
              onClick={() => onSelectType(type)}
              key={type.toString()}
            >
              {t(`external_town_type.${type}`)}
            </Button>
          ))}
        </ButtonGroup>
      </Grid>

      <Grid item xs={12}>
        {selectedType === ExternalTownType.daheim && (
          <DaheimTownSelect daheimTown={selectedDaheimTown} setDaheimTown={onSelectDaheimTown} />
        )}
        {selectedType === ExternalTownType.symvaro && (
          <ExternalTownAutocomplete
            selectedExternalTown={selectedExternalTown}
            setSelectedExternalTown={onSelectExternalTown}
            selectedType={selectedType}
          />
        )}
      </Grid>
    </Grid>
  )
}
