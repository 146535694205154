import React, { FunctionComponent, Fragment, useContext, useMemo, useCallback } from "react"
import { Theme, makeStyles, Grid, Paper } from "@material-ui/core"
import { useTranslation } from "react-i18next"
import { reportDetail_report_widgets } from "../../../../api/graphql/generated/reportDetail"
import { ChannelType, WidgetType } from "../../../../api/graphql/generated/globalTypes"
import { ReportDetail } from "../../../../api/graphql/query/report-detailed"
import WidgetGraph from "./WidgetGraph"
import { WidgetText } from "./WidgetText"
import { WidgetTextIndividual } from "./WidgetTextIndividual"
import { WidgetExportsContext } from "../context/WidgetExportsContext"
import { PaperWidgetWrapper } from "./PaperWidgetWrapper"
import { ReportDetailContext } from "../context/ReportDetailContext"
import { WidgetModificationType } from "../../../../domain/models/WidgetModels"

const useStyles = makeStyles((theme: Theme) => ({
  widgetContainer: {
    margin: theme.spacing(1),
  },
}))

interface IWidgetProps {
  widget: reportDetail_report_widgets
  channelType: ChannelType
  report: ReportDetail
}

export const Widget: FunctionComponent<IWidgetProps> = (props) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const { onExportReady, onModification } = useContext(WidgetExportsContext)
  const { refetchReportDetails } = useContext(ReportDetailContext)

  const { channelType, widget, report } = props

  const customOnModification = useCallback(
    (type: WidgetModificationType, widget: reportDetail_report_widgets) => {
      onModification(type, widget)
      refetchReportDetails()
    },
    [refetchReportDetails, onModification],
  )

  const graphWidget = useMemo(
    () => (
      <WidgetGraph
        report={report}
        widgetModel={widget}
        onModification={customOnModification}
        onExportCreated={(fileName: string, uri: string, extension: string) =>
          onExportReady(widget.id, channelType, fileName, widget.title, widget.textContent, uri, extension)
        }
      />
    ),
    [report, widget, onExportReady, channelType, customOnModification],
  )

  switch (widget.widgetType) {
    case WidgetType.text:
      return (
        <Grid item md={12} lg={6} xl={4}>
          <PaperWidgetWrapper>
            <WidgetText
              widgetModel={widget}
              onModification={customOnModification}
              onExportCreated={(fileName, uri, extension) =>
                onExportReady(widget.id, channelType, fileName, widget.title, widget.textContent, uri, extension)
              }
            />
          </PaperWidgetWrapper>
        </Grid>
      )
    case WidgetType.text_individual:
      return (
        <Grid item xs={12}>
          <PaperWidgetWrapper>
            <WidgetTextIndividual
              widgetModel={widget}
              onModification={customOnModification}
              onExportCreated={(fileName, uri, extension) =>
                onExportReady(widget.id, channelType, fileName, widget.title, widget.textContent, uri, extension)
              }
            />
          </PaperWidgetWrapper>
        </Grid>
      )
    case WidgetType.chart_trend:
      return (
        <Grid item md={12}>
          <PaperWidgetWrapper>{graphWidget}</PaperWidgetWrapper>
        </Grid>
      )
    case WidgetType.chart_trend_comparison:
      return (
        <Grid item xs={12} xl={8}>
          <PaperWidgetWrapper>{graphWidget}</PaperWidgetWrapper>
        </Grid>
      )
    case WidgetType.chart_history:
      return (
        <Grid item md={12} xl={8}>
          <PaperWidgetWrapper>{graphWidget}</PaperWidgetWrapper>
        </Grid>
      )
    case WidgetType.chart_pie:
      return (
        <Grid item md={12} lg={6} xl={4}>
          <PaperWidgetWrapper>{graphWidget}</PaperWidgetWrapper>
        </Grid>
      )
    case WidgetType.chart_pie_waste_quality:
      return (
        <Grid item md={12} lg={6} xl={4}>
          <PaperWidgetWrapper>{graphWidget}</PaperWidgetWrapper>
        </Grid>
      )
    default:
      return <div>{t("report.widget_not_recognised")}</div>
  }
}
