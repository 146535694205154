import React, { FunctionComponent } from "react"
import { Grid, Chip } from "@material-ui/core"

interface IChipsForArrayProps {
  elements: string[]
}

export const ChipsForArray: FunctionComponent<IChipsForArrayProps> = props => {
  const { elements } = props
  return (
    <Grid container spacing={1}>
      {elements.map((element: string, index: number) => (
        <Grid item key={`${element}${index}`}>
          <Chip label={element} />
        </Grid>
      ))}
    </Grid>
  )
}
