import React, { Fragment, FunctionComponent, useCallback, useMemo, useState } from "react"
import Dialog from "@material-ui/core/Dialog"
import { WertstoffAnalysisDetail } from "./detail/WertstoffAnalysisDetail"
import { Grid, makeStyles, Theme, Typography, useTheme } from "@material-ui/core"
import { useTranslation } from "react-i18next"
import { MaterialAnalysisWithDocuments } from "../../../api/graphql/query/analyticsDocuments"
import { ClipLoader } from "react-spinners"
import { WertstoffPictureGalleryItem } from "./WertstoffPictureGalleryItem"
import { ChevronRight } from "@material-ui/icons"
import ScrollContainer from "react-indiana-drag-scroll"
import { WertstoffDetailContextProvider } from "./detail/WertstoffDetailContext"
import { MaterialAnalysisInvalidityInput } from "../../../api/graphql/generated/globalTypes"

// @Styles
const useStyles = makeStyles((theme: Theme) => ({
  container: {
    width: "100%",
    overflowX: "scroll",
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(2),
  },
  gridList: {
    flexWrap: "nowrap",
    position: "relative",
  },
  gridListLower: {
    flexWrap: "nowrap",
    paddingTop: theme.spacing(0.5),
  },
  gridListTitle: {
    width: "28%",
  },
  showMore: {
    cursor: "pointer",
    marginLeft: theme.spacing(1),
  },
  fullHeight: {
    height: "100%",
  },
}))

interface IWertstoffPictureGalleryProps {
  analysisData: MaterialAnalysisWithDocuments[]
  setIsFetching: (fetch: boolean) => void
  isFetching: boolean
  searchIsLoading: boolean
  disableFetch: boolean
  useBagSeparation?: boolean
  onAnalysisDeleted: (id: string) => void
  onAnalysisInvalidated?: (id: string, invalidity: MaterialAnalysisInvalidityInput) => void
}

export const WertstoffPictureGallery: FunctionComponent<IWertstoffPictureGalleryProps> = (props) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const theme = useTheme()
  const [selectedAnalysis, setSelectedAnalysis] = useState<MaterialAnalysisWithDocuments | null>()

  const {
    analysisData,
    disableFetch,
    setIsFetching,
    isFetching,
    useBagSeparation,
    onAnalysisDeleted,
    searchIsLoading,
    onAnalysisInvalidated,
  } = props

  const onClick = useCallback((analysis: MaterialAnalysisWithDocuments) => {
    setSelectedAnalysis(analysis)
  }, [])

  const handleClose = useCallback(() => {
    setSelectedAnalysis(null)
  }, [])

  const customOnAnalysisDeleted = useCallback(
    (id: string) => {
      onAnalysisDeleted(id)
      handleClose()
    },
    [onAnalysisDeleted, handleClose],
  )

  const customOnAnalysisUpdated = useCallback(
    (id: string, invalidity: MaterialAnalysisInvalidityInput) => {
      console.log(!!onAnalysisInvalidated, invalidity)
      if (!!onAnalysisInvalidated && invalidity.isInvalid) {
        onAnalysisInvalidated(id, invalidity)
      }
      handleClose()
    },
    [onAnalysisDeleted, handleClose],
  )

  const upper = useMemo(
    () =>
      analysisData
        .filter((_, index) => index % 2 === 0)
        .map((analysis) => <WertstoffPictureGalleryItem analysis={analysis} onClick={onClick} />),
    [analysisData, onClick],
  )
  const lower = useMemo(
    () =>
      analysisData
        .filter((_, index) => index % 2 !== 0)
        .map((analysis) => <WertstoffPictureGalleryItem analysis={analysis} onClick={onClick} />),
    [analysisData, onClick],
  )

  return (
    <Fragment>
      {analysisData.length > 0 && (
        <ScrollContainer className={classes.container} horizontal vertical={false} hideScrollbars={false}>
          <Grid item xs={11} container className={classes.gridList} spacing={1}>
            {!searchIsLoading && upper}
            {!disableFetch && (
              <Grid xs={1} item container alignContent="center" alignItems="center" className={classes.showMore}>
                {!isFetching && (
                  <ChevronRight
                    fontSize="large"
                    onClick={() => setIsFetching(true)}
                    color={disableFetch ? "secondary" : "primary"}
                  />
                )}
                {isFetching && <ClipLoader color={theme.palette.primary.main} size={34} />}
              </Grid>
            )}
          </Grid>
          <Grid item xs={11} container className={classes.gridListLower} spacing={1}>
            {!searchIsLoading && lower}
          </Grid>
        </ScrollContainer>
      )}
      {((analysisData.length === 0 && isFetching) || searchIsLoading) && (
        <Grid container justify="center" alignItems="center" className={classes.fullHeight}>
          <ClipLoader size={32} />
        </Grid>
      )}
      {analysisData.length === 0 && !isFetching && !searchIsLoading && (
        <Grid container justify="center" alignItems="center" className={classes.fullHeight}>
          <Typography>{t("analysis_page.no_data_for_this_filter")}</Typography>
        </Grid>
      )}
      {!!selectedAnalysis && (
        <Dialog
          open={!!selectedAnalysis}
          onClose={handleClose}
          aria-labelledby="form-dialog-title"
          disableBackdropClick={false}
          onBackdropClick={handleClose}
          fullWidth={true}
          maxWidth={"md"}
        >
          <WertstoffDetailContextProvider
            onAnalysisDeleted={customOnAnalysisDeleted}
            onAnalysisUpdated={customOnAnalysisUpdated}
            useBagSeparation={useBagSeparation}
            analysisId={selectedAnalysis.id}
          >
            <WertstoffAnalysisDetail />
          </WertstoffDetailContextProvider>
        </Dialog>
      )}
    </Fragment>
  )
}
