import React, { FunctionComponent, Fragment } from "react"
import { Theme, makeStyles, Grid, Typography, Button } from "@material-ui/core"
import { useTranslation } from "react-i18next"
import DeleteIcon from "@material-ui/icons/Delete"
import PublishIcon from "@material-ui/icons/Publish"

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    marginLeft: 25,
  },
  text: {
    flex: 1,
    marginLeft: 10,
    fontWeight: "bold",
  },
}))

interface IUploadedFileProps {
  disabled: boolean
  onRemove: () => void
  file: any
  reupload?: boolean
}

export const UploadedFile: FunctionComponent<IUploadedFileProps> = (props) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const getIcon = () => {
    let icon = <DeleteIcon />

    if (props.reupload) {
      icon = <PublishIcon />
    }

    return icon
  }

  return (
    <Fragment>
      <Grid container alignItems="center">
        <Button
          disabled={props.disabled}
          variant="contained"
          color="secondary"
          className={classes.button}
          startIcon={getIcon()}
          onClick={props.onRemove}
        >
          {props.reupload ? t("general.reupload") : t("general.remove")}
        </Button>
        <Typography className={classes.text}>{props.file?.name || "lol"}</Typography>
      </Grid>
    </Fragment>
  )
}
