import { gql } from "apollo-boost"
import { validateRfid, validateRfidVariables } from "../generated/validateRfid"

export const VALIDATE_RFID_QUERY = gql`
  query validateRfid($rfid: String!) {
    validateRfid(rfid: $rfid)
  }
`

export type ValidateRfidQueryResult = validateRfid
export type ValidateRfidQueryVariables = validateRfidVariables
