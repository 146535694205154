import React, { FunctionComponent, useState, useCallback, useMemo } from "react"
import {
  Theme,
  makeStyles,
  Button,
  DialogActions,
  Typography,
  DialogTitle,
  Dialog,
  DialogContent,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core"
import { useTranslation } from "react-i18next"
import { useMutation } from "react-apollo"
import {
  DeleteTourDataResult,
  DeleteTourDataVariables,
  DELETE_TOUR_DATA,
} from "../../../api/graphql/mutation/delete-tour-data"
import { Tour } from "../../../api/graphql/query/tours"

const useStyles = makeStyles((theme: Theme) => ({}))

interface IDeleteTourDataDialogProps {
  open: boolean
  onClose: () => void
  tour: Tour
  onDeleted: () => void
}

export const DeleteTourDataDialog: FunctionComponent<IDeleteTourDataDialogProps> = (props) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { open, onClose, tour, onDeleted } = props

  const [keepMaterialAnalysis, setKeepMaterialAnalysis] = useState<boolean>(true)
  const [deletedCountMaterialAnalysis, setDeletedCountMaterialAnalysis] = useState<number>()
  const [deletedCountRfidReadings, setDeletedCountRfidReadings] = useState<number>()

  const [deleteTourDataMutation, { loading }] = useMutation<DeleteTourDataResult, DeleteTourDataVariables>(
    DELETE_TOUR_DATA,
    {
      onError: (error) => {
        console.log("error: ", error)
      },
      onCompleted: (data) => {
        setDeletedCountRfidReadings(data.deleteTourData.deletedCountRfidReadings)
        setDeletedCountMaterialAnalysis(data.deleteTourData.deletedCountMaterialAnalysis)
        onDeleted()
      },
    },
  )

  const onExited = useCallback(() => {
    setDeletedCountMaterialAnalysis(undefined)
    setDeletedCountRfidReadings(undefined)
  }, [setDeletedCountRfidReadings, setDeletedCountMaterialAnalysis])

  const onDelete = () => {
    deleteTourDataMutation({ variables: { tourId: tour.id, keepMaterialAnalysis } })
  }

  const showResult = useMemo(
    () => deletedCountMaterialAnalysis !== undefined && deletedCountRfidReadings !== undefined,
    [deletedCountMaterialAnalysis, deleteTourDataMutation],
  )

  return (
    <Dialog open={open} onClose={onClose} onExited={onExited}>
      <DialogTitle>
        {!showResult && t("tour.dialog.delete_tour_data.title")}
        {showResult && t("tour.dialog.delete_tour_data.title_deleted")}
      </DialogTitle>
      <DialogContent>
        {!showResult && (
          <>
            <Typography>{t("tour.dialog.delete_tour_data.text")}</Typography>
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  checked={!keepMaterialAnalysis}
                  onChange={() => setKeepMaterialAnalysis(!keepMaterialAnalysis)}
                />
              }
              label={t("tour.dialog.delete_tour_data.delete_material_analysis_too")}
            />
          </>
        )}
        {showResult && (
          <Typography>
            {t("tour.dialog.delete_tour_data.text_deleted", {
              materialAnalysisCount: deletedCountMaterialAnalysis,
              rfidReadingsCount: deletedCountRfidReadings,
            })}
          </Typography>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color={"primary"} disabled={loading}>
          {!showResult && t("general.cancel")}
          {showResult && t("general.close")}
        </Button>

        {!showResult && (
          <Button onClick={onDelete} color={"primary"} disabled={loading}>
            {t("tour.dialog.delete_tour_data.button")}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  )
}
