import { gql } from "apollo-boost"
import { FRAGMENT_EVALUATION_FULL } from "../fragments/evaluation_fragments"
import {
  analyticsDetail_materialAnalysis_materialAnalysis,
  analyticsDetail,
  analyticsDetailVariables,
} from "../generated/analyticsDetail"

export const ANALYTICS_DETAIL_QUERY = gql`
  query analyticsDetail($ids: [ID!]!, $simplifyEvaluation: Boolean) {
    materialAnalysis(
      ids: $ids
      skipDisplayOnlyAutomaticAnalysisCheck: true
      simplifyEvaluation: $simplifyEvaluation
    ) {
      materialAnalysis {
        id
        documents {
          id
          documentType
          url
          title
        }
        association {
          id
          name
        }
        town {
          id
          name
        }
        tour {
          id
          name
        }
        tourType
        metadata {
          recordedAt
          analysedAt
          wasteScannerId
          location {
            latitude
            longitude
            formattedAddress
          }
          automatic
          mappedClassification
          descriptionLabel
          file
        }
        rfidData {
          rfidIds
        }
        calculatedDetailed {
          rating
          percentOfCategory {
            main
            missThrow
          }
          pixelCountDetailed {
            id
            value
            name
            color
            materialType
          }
        }
        invalidity {
          isInvalid
          invalidReason
          invalidType
        }
        imageNumber
        createdAt
        updatedAt
        deletedAt
      }
      evaluation {
        ...FragmentEvaluationFull
      }
    }
  }
  ${FRAGMENT_EVALUATION_FULL}
`

export type AnalyticsDetailQueryResult = analyticsDetail
export type AnalyticsDetailQueryVariables = analyticsDetailVariables
export type DetailedMaterialAnalysis = analyticsDetail_materialAnalysis_materialAnalysis
