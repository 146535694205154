/*
    Hook which provides all filter functionalities
*/

import React, { createContext, useContext, useMemo } from "react"
import { useQuery } from "react-apollo"
import {
  HousingManagement,
  HousingManagementsResult,
  HOUSING_MANAGEMENTS_QUERY,
} from "../api/graphql/query/housing-managements"

interface HousingManagementContextType {
  housingManagements: HousingManagement[]
  housingManagementsLoading: boolean
  refresh: () => void
}

export const HousingManagementContext = createContext<HousingManagementContextType>({
  housingManagementsLoading: true,
  housingManagements: [],
  refresh: () => {},
})

export const HousingManagementContextProvider = (props: any) => {
  const { data, loading: housingManagementsLoading, refetch: refetchHousingManagement } = useQuery<
    HousingManagementsResult
  >(HOUSING_MANAGEMENTS_QUERY, {
    fetchPolicy: "no-cache",
  })

  const housingManagements = useMemo(() => data?.housingManagements ?? [], [data])

  const refresh = () => refetchHousingManagement()

  return (
    <HousingManagementContext.Provider value={{ housingManagements, housingManagementsLoading, refresh }}>
      {props.children}
    </HousingManagementContext.Provider>
  )
}

export const useHousingManagements = () => useContext(HousingManagementContext)
