import React, { FunctionComponent, Fragment, useState, useContext } from "react"
import { Theme, makeStyles, Grid, Typography, FormControlLabel, Radio } from "@material-ui/core"
import { useTranslation } from "react-i18next"
import { ChevronRight } from "@material-ui/icons"
import { Frequency } from "../../../api/graphql/generated/globalTypes"
import { StatisticsRefinementContext } from "../../../context/StatisticsRefinementContext"
import lodash from "lodash"

interface IStyleProps {
  open: boolean
}

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    padding: theme.spacing(2),
  },
  openIcon: {
    transition: "0.2s",
    transform: (props: IStyleProps) => (props.open ? "rotate(180deg)" : "rotate(0deg)"),
  },
  refineResults: {
    cursor: "pointer",
  },
  checkboxRoot: {
    padding: 0,
    marginRight: theme.spacing(0.5),
  },
}))

export const freqSorted = [Frequency.daily, Frequency.weekly, Frequency.monthly]

export function sortFrequency(freqA: Frequency | string, freqB: Frequency | string): number {
  const orderedFrequencyAsString = Object.values(freqSorted).map((freq) => freq.toString())

  let indexA = 0
  if (lodash.isString(freqA)) {
    indexA = orderedFrequencyAsString.indexOf(freqA)
  } else {
    indexA = freqSorted.indexOf(freqA)
  }

  let indexB = 0
  if (lodash.isString(freqB)) {
    indexB = orderedFrequencyAsString.indexOf(freqB)
  } else {
    indexB = freqSorted.indexOf(freqB)
  }

  return indexA - indexB
}

interface IStatisticsRefinementComponentProps {}

export const StatisticsRefinementComponent: FunctionComponent<IStatisticsRefinementComponentProps> = (props) => {
  const [open, setOpen] = useState<boolean>(false)
  const classes = useStyles({ open })
  const { selectedFrequency, setSelectedFrequency } = useContext(StatisticsRefinementContext)
  const { t } = useTranslation()

  return (
    <Fragment>
      <Grid container spacing={2}>
        <Grid item onClick={() => setOpen(!open)} className={classes.refineResults}>
          <Grid container>
            <Grid item>
              <Typography color="secondary" variant={"body1"}>
                {t("statistics.overview.text.refine_results")}
              </Typography>
            </Grid>
            <Grid item>
              <ChevronRight color="primary" className={classes.openIcon} />
            </Grid>
          </Grid>
        </Grid>
        {open && (
          <Grid item xs>
            <Grid container spacing={1}>
              {Object.values(Frequency)
                .sort(sortFrequency)
                .map((frequency) => (
                  <Grid item key={frequency}>
                    <FormControlLabel
                      control={
                        <Radio
                          disableFocusRipple
                          disableRipple
                          disableTouchRipple
                          classes={{ root: classes.checkboxRoot }}
                          color="primary"
                          checked={selectedFrequency === frequency}
                          onChange={() => setSelectedFrequency(frequency)}
                        />
                      }
                      label={t(`frequency.${frequency}`)}
                    />
                  </Grid>
                ))}
            </Grid>
          </Grid>
        )}
      </Grid>
    </Fragment>
  )
}
