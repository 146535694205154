import { gql } from "apollo-boost"

export const ADVANCEMENT_PDF_ITEMS = gql`
  query AdvancementPdfItems(
    $tourType: TourType
    $associationIds: [ID!]
    $townIds: [ID!]
    $firstDate: Date
    $lastDate: Date
    $ratings: [Rating!]
  ) {
    materialAnalysis(
      tourType: $tourType
      associationIds: $associationIds
      townIds: $townIds
      firstDate: $firstDate
      lastDate: $lastDate
      ratings: $ratings
    ) {
      materialAnalysis {
        id
      }
    }
  }
`
