import React, { FunctionComponent, useCallback, useState } from "react"
import {
  Theme,
  makeStyles,
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  DialogActions,
  Button,
  Typography,
  CircularProgress,
} from "@material-ui/core"
import { useTranslation } from "react-i18next"
import { useMutation } from "react-apollo"
import {
  ImportRfidChipsForHousingManagementResult,
  IMPORT_RFID_CHIPS_FOR_HOUSING_MANAGEMENT_MUTATION,
} from "../../../api/graphql/mutation/import-rfid-chips-for-housing-management"
import CheckCircle from "@material-ui/icons/CheckCircle"
import { HousingManagement } from "../../../api/graphql/query/housing-managements"
import {
  ImportRfidChipsForHousingManagement,
  ImportRfidChipsForHousingManagementVariables,
} from "../../../api/graphql/generated/ImportRfidChipsForHousingManagement"
import { COLORS } from "../../../theme/theme"
import { Dropzone } from "../dropzone/Dropzone"
import { UploadedFile } from "../tour-management/import-tours/UploadedFile"
import { ImportCheckResult } from "../tour-management/import-tours/ImportCheckResult"

const useStyles = makeStyles((theme: Theme) => ({
  loadingIndicator: {
    marginTop: theme.spacing(4),
  },
  checkIcon: {
    color: COLORS.lime,
    fontSize: 50,
    marginRight: theme.spacing(2),
  },
  contentContainer: {},
}))

interface IHousingManagementImportRfidChipDialogProps {
  housingManagement: HousingManagement
  isOpen: boolean
  onImportComplete: () => void
  onClose: () => void
}

export const HousingManagementImportRfidChipDialog: FunctionComponent<IHousingManagementImportRfidChipDialogProps> = (
  props,
) => {
  const { isOpen, onClose, housingManagement, onImportComplete } = props
  const classes = useStyles()
  const { t } = useTranslation()
  const [validationResult, setValidationResult] = useState<ImportRfidChipsForHousingManagementResult | null>(null)

  const [importMutation, { loading }] = useMutation<
    ImportRfidChipsForHousingManagement,
    ImportRfidChipsForHousingManagementVariables
  >(IMPORT_RFID_CHIPS_FOR_HOUSING_MANAGEMENT_MUTATION, {
    onCompleted: (data) => setValidationResult(data.importRfidChipsForHousingManagement),
  })
  const [uploadedFile, setUploadedFile] = useState<File | undefined>()

  const customOnClose = useCallback(() => {
    setUploadedFile(undefined)
    setValidationResult(null)
    if (validationResult?.importDone) {
      onClose()
    } else {
      onImportComplete()
    }
  }, [onClose, setUploadedFile])

  const importRfidChips = useCallback(() => {
    importMutation({
      variables: {
        importRfidChipsForHousingManagementId: housingManagement.id,
        file: uploadedFile as File,
        ignoreWarnings: (validationResult?.warnings || []).length > 0,
      },
    })
  }, [importMutation, housingManagement, uploadedFile, validationResult])

  return (
    <Dialog fullWidth maxWidth="md" onClose={customOnClose} open={isOpen}>
      <DialogTitle>{t("rfid_management.import_dialog.title")}</DialogTitle>
      <DialogContent>
        <Grid container spacing={2} className={classes.contentContainer}>
          <Grid item xs={12} container justify="center">
            {!uploadedFile && (
              <Dropzone
                accept="application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                onFilesDropped={(files: File[]) => {
                  setUploadedFile(files[0])
                }}
              />
            )}
            {uploadedFile && !validationResult?.importDone && (
              <Grid container justify="center">
                <UploadedFile disabled={loading} file={uploadedFile} onRemove={() => setUploadedFile(undefined)} />
                {loading && <CircularProgress className={classes.loadingIndicator} />}
              </Grid>
            )}
          </Grid>
          {!!validationResult?.importDone && (
            <Grid container justify="center" alignItems="center">
              <CheckCircle className={classes.checkIcon} />
              <Typography variant="h5">{t("rfid_management.import_dialog.success")}</Typography>
            </Grid>
          )}
          {((validationResult?.errors || []).length > 0 || (validationResult?.warnings || []).length > 0) &&
            !validationResult?.importDone && (
              <ImportCheckResult
                errors={validationResult?.errors || ([] as any[])}
                warnings={validationResult?.warnings || ([] as any[])}
              />
            )}
        </Grid>
      </DialogContent>
      <DialogActions>
        {!!validationResult?.importDone ? (
          <Button color="primary" variant={"contained"} onClick={customOnClose}>
            {t("rfid_management.import_dialog.close")}
          </Button>
        ) : (
          <>
            <Button color="primary" onClick={customOnClose}>
              {t("general.cancel")}
            </Button>
            <Button
              disabled={loading || !uploadedFile || (validationResult?.errors || []).length > 0}
              color="primary"
              variant={"contained"}
              onClick={importRfidChips}
            >
              {t("rfid_management.import_dialog.import")}
            </Button>
          </>
        )}
      </DialogActions>
    </Dialog>
  )
}
