import React, { FunctionComponent, useCallback, useEffect, useState } from "react"
import {
  Theme,
  makeStyles,
  ExpansionPanel,
  ExpansionPanelSummary,
  Typography,
  ExpansionPanelDetails,
  Grid,
  Fab,
  Button,
  Divider,
} from "@material-ui/core"
import { useTranslation } from "react-i18next"
import { ExpandMore, Edit, Publish } from "@material-ui/icons"
import { HousingManagement } from "../../../api/graphql/query/housing-managements"
import {
  HOUSE_MANAGEMENT_BY_ID_QUERY,
  HousingManagementDetails,
} from "../../../api/graphql/query/housing-managements-by-id"
import { useLazyQuery, useMutation } from "react-apollo"
import {
  HousingManagementById,
  HousingManagementByIdVariables,
} from "../../../api/graphql/generated/HousingManagementById"

import { UPDATE_HOUSING_MANAGEMENT_MUTATION } from "../../../api/graphql/mutation/update-housing-management"
import {
  UpdateHousingManagement,
  UpdateHousingManagementVariables,
} from "../../../api/graphql/generated/UpdateHousingManagement"
import { PageLoadingIndicator } from "../indicator/PageLoadingIndicator"
import { HousingManagementAssociations } from "./HousingManagementAssociations"
import { HousingManagementTowns } from "./HousingManagementTowns"
import { HousingManagementRfidDialog } from "./HousingManagementRfidDialog"

const useStyles = makeStyles((theme: Theme) => ({
  expPanelSummaryActive: {
    background: theme.palette.primary.main,
    color: "#ffffff",
  },
  expPanelSummary: {
    height: 60,
  },
  expPanelSummaryTypo: {
    fontSize: 20,
  },
  expPanelSummaryContainer: {
    padding: theme.spacing(1),
  },
  section: {
    marginTop: theme.spacing(2),
  },
  importIcon: {
    marginRight: theme.spacing(2),
  },
  title: {
    fontSize: 16,
    color: theme.palette.primary.main,
    fontWeight: "bold",
    paddingBottom: theme.spacing(2),
  },
  divider: {
    marginTop: theme.spacing(2),
  },
}))

interface IHousingManagementExpansionPanelProps {
  housingManagement: HousingManagement
  onEdit: () => void
  onImport: () => void
}

export const HousingManagementExpansionPanel: FunctionComponent<IHousingManagementExpansionPanelProps> = (props) => {
  const { housingManagement, onEdit, onImport } = props
  const classes = useStyles()
  const { t } = useTranslation()
  const [open, setOpen] = useState<boolean>(false)
  const [rfidsOpen, setRfidsOpen] = useState<boolean>(false)
  const toggle = useCallback(() => setOpen(!open), [open])
  const [housingManagementDetails, setHousingManagementDetails] = useState<HousingManagementDetails | null>(null)
  const [housingManagementById, { loading: fetching }] = useLazyQuery<
    HousingManagementById,
    HousingManagementByIdVariables
  >(HOUSE_MANAGEMENT_BY_ID_QUERY, {
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      setHousingManagementDetails(data.housingManagement)
    },
  })

  const [updateHousingManagement, { loading: updating }] = useMutation<
    UpdateHousingManagement,
    UpdateHousingManagementVariables
  >(UPDATE_HOUSING_MANAGEMENT_MUTATION, {
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      setHousingManagementDetails(data.updateHousingManagement)
    },
  })
  const updateRfids = useCallback(
    (rfids: string[]) =>
      updateHousingManagement({
        variables: {
          updateHousingManagementId: housingManagement.id,
          name: housingManagement.name,
          rfids,
        },
      }),
    [updateHousingManagement, housingManagement],
  )

  useEffect(() => {
    housingManagementById({
      variables: {
        housingManagementId: housingManagement.id,
      },
    })
  }, [housingManagement])

  return (
    <>
      <HousingManagementRfidDialog
        isOpen={rfidsOpen}
        onCloseDialog={() => setRfidsOpen(false)}
        rfids={housingManagementDetails?.rfids}
        setRfids={updateRfids}
      />
      <ExpansionPanel expanded={open}>
        <ExpansionPanelSummary
          expandIcon={<ExpandMore />}
          className={classes.expPanelSummary}
          classes={{ expanded: classes.expPanelSummaryActive }}
          onClick={toggle}
        >
          <Grid container className={classes.expPanelSummaryContainer} justify="space-between">
            <Typography className={classes.expPanelSummaryTypo}>{housingManagement.name}</Typography>
          </Grid>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Grid container>
            {open && (
              <>
                <Grid item xs>
                  <Grid item xs={12} className={classes.section}>
                    <HousingManagementAssociations
                      associations={housingManagementDetails.associations}
                      loading={updating}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Divider className={classes.divider} />
                  </Grid>
                  <Grid item xs={12} className={classes.section}>
                    <HousingManagementTowns towns={housingManagementDetails.towns} loading={updating} />
                  </Grid>
                  <Grid item xs={12}>
                    <Divider className={classes.divider} />
                  </Grid>
                  <Grid item xs={12} className={classes.section}>
                    <Typography className={classes.title}>{t("housing_management.rfids.title")}</Typography>
                    <Button color="primary" onClick={() => setRfidsOpen(true)} variant="contained">
                      {t("housing_management.rfids.button")}
                    </Button>
                  </Grid>
                </Grid>
                <Grid item>
                  <Grid container xs={12} justify="flex-end" className={classes.section}>
                    <Fab
                      color="primary"
                      size={"small"}
                      aria-label="edit"
                      onClick={(e) => {
                        e.stopPropagation()
                        onImport()
                      }}
                      className={classes.importIcon}
                    >
                      <Publish />
                    </Fab>
                    <Fab
                      color="primary"
                      size={"small"}
                      aria-label="edit"
                      onClick={(e) => {
                        e.stopPropagation()
                        onEdit()
                      }}
                    >
                      <Edit />
                    </Fab>
                  </Grid>
                </Grid>
              </>
            )}
            {open && fetching && (
              <Grid item xs={12}>
                <PageLoadingIndicator />
              </Grid>
            )}
          </Grid>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    </>
  )
}
