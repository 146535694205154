import { gql } from "apollo-boost"
import {
  defaultClassifiersForAssociation,
  defaultClassifiersForAssociationVariables,
  defaultClassifiersForAssociation_defaultClassifiersForAssociation,
} from "../generated/defaultClassifiersForAssociation"

export const DEFAULT_CLASSIFIERS_FOR_ASSOCIATION_QUERY = gql`
  query defaultClassifiersForAssociation($associationId: String!) {
    defaultClassifiersForAssociation(associationId: $associationId) {
      tourType
      classifierId
    }
  }
`

export type DefaultClassifiersForAssociationQueryResult = defaultClassifiersForAssociation
export type DefaultClassifiersForAssociationQueryVariables = defaultClassifiersForAssociationVariables
export type DefaultClassifierForAssociation = defaultClassifiersForAssociation_defaultClassifiersForAssociation
