import React, { FunctionComponent, useState, useEffect, Fragment, useContext } from "react"
import { Theme, makeStyles, Grid, Typography, Card, CardActionArea, CardContent } from "@material-ui/core"
import { useTranslation } from "react-i18next"
import {
  WidgetTextCategoriesWithTemplatesResult,
  WIDGET_TEXT_TEMPLATES_FOR_CATEGORY_QUERY,
  WidgetTextCategoriesWithTemplatesVariables,
  WidgetTextTemplate,
} from "../../../../api/graphql/query/widget-text-templates-for-category"
import { useQuery } from "react-apollo"
import { WidgetTextCategory } from "../../../../api/graphql/query/widget-text-categories"
import lodash from "lodash"
import { Rating } from "../../../../api/graphql/generated/globalTypes"
import { ClipLoader } from "react-spinners"
import classNames from "classnames"
import { ValueForRating } from "../../../../api/graphql/query/report"
import { getRatingInfo, sortRating } from "../../../../domain/helper/CalcRating"
import { generateTextFromTemplate, placeholderNameReplacementArray } from "../../../../domain/models/TextPlaceholders"
import { TextReplacementContext } from "../../../../context/TextReplacementContext"
import { generateBoldTexts } from "../../helpers/TextPlaceholderBold"

const useStyles = makeStyles((theme: Theme) => ({
  heading: {
    fontSize: 18,
    marginLeft: theme.spacing(1),
    color: theme.palette.secondary.main,
    fontWeight: "bold",
  },
  lane: {
    overflowY: "scroll",
  },
  templateHeading: {
    fontSize: 16,
    color: theme.palette.primary.main,
    marginBottom: theme.spacing(1),
  },
  templateCard: {
    textAlign: "left",
    margin: theme.spacing(1),
    cursor: "pointer",
  },
  selectedCard: {
    backgroundColor: theme.palette.primary.main,
    color: "#ffffff",
  },
  selectedHeading: {
    color: "#ffffff",
    fontWeight: "bold",
  },
  cardActionArea: {
    padding: theme.spacing(1),
  },
  cardContent: {
    padding: theme.spacing(1),
    cursor: "default",
  },
}))

interface ITextTemplatesForCategoryProps {
  selectedCategory: WidgetTextCategory
  onChangedTextTemplateSelection: ((selectedTextTemplateIds: ValueForRating[]) => void) | null
  textTemplateIds: ValueForRating[] | null
  associationId: string
  townId: string | null
  clickable?: boolean
}

export const TextTemplatesForCategory: FunctionComponent<ITextTemplatesForCategoryProps> = (props) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { selectedCategory, textTemplateIds, onChangedTextTemplateSelection, associationId, townId, clickable } = props
  const { placeholders } = useContext(TextReplacementContext)
  const [selectedTemplatesIds, setSelectedTemplatesIds] = useState<ValueForRating[]>(
    lodash.isNil(textTemplateIds) ? [] : textTemplateIds,
  )

  useEffect(() => {
    if (lodash.isFunction(onChangedTextTemplateSelection)) {
      onChangedTextTemplateSelection(selectedTemplatesIds)
    }
    // eslint-disable-next-line
  }, [selectedTemplatesIds, selectedCategory])

  const {
    data: categoryWithTemplateData,
    loading: categoryWithTemplateLoading,
    // error: categoryWithTemplateError
  } = useQuery<WidgetTextCategoriesWithTemplatesResult, WidgetTextCategoriesWithTemplatesVariables>(
    WIDGET_TEXT_TEMPLATES_FOR_CATEGORY_QUERY,
    {
      variables: {
        id: lodash.get(selectedCategory, "id"),
        associationId: associationId,
        townId: townId === "all" ? null : townId,
        isExclusive: false,
      },
      fetchPolicy: "network-only",
      skip: !selectedCategory,
    },
  )

  const templates: WidgetTextTemplate[] = lodash.get(
    categoryWithTemplateData,
    "widgetTextCategory.widgetTextTemplates",
    [],
  )

  const isInEditMode = !lodash.isNil(onChangedTextTemplateSelection)
  const filteredTemplateIds = isInEditMode
    ? selectedTemplatesIds.filter((tuple) => {
        return templates.some((template) => {
          return template.id === tuple.value
        })
      })
    : textTemplateIds || []

  const Typo = (props: any) => (
    <Fragment>
      <Typography
        className={classNames(classes.templateHeading, {
          [classes.selectedHeading]: props.selected && isInEditMode,
        })}
      >
        {props.template.title}
      </Typography>
      <Typography style={{ whiteSpace: "pre-line" }}>
        {generateBoldTexts(
          generateTextFromTemplate(
            props.template.textTemplate,
            placeholders,
            placeholderNameReplacementArray(placeholders, t),
            true,
          ),
          t,
        )}
      </Typography>
    </Fragment>
  )

  return (
    <Grid container>
      {Object.keys(Rating)
        .sort(sortRating)
        .map((ratingKey, _) => (
          <Grid item xs={3} key={ratingKey}>
            <Typography className={classes.heading}>{t(`waste_quality.${ratingKey}`)}</Typography>
            <div className={classes.lane}>
              {categoryWithTemplateLoading && (
                <Grid container justify="center">
                  <ClipLoader />
                </Grid>
              )}
              {!categoryWithTemplateLoading &&
                templates
                  .filter((template) => template.rating === ratingKey)
                  .map((template) => {
                    const foundTemplate = filteredTemplateIds.find((value) => {
                      return lodash.get(value, "rating") === ratingKey
                    })
                    const selected: boolean = template.id === lodash.get(foundTemplate, "value")
                    const isInEditMode = !lodash.isNil(onChangedTextTemplateSelection)
                    if (!isInEditMode && !selected) {
                      return <div key={template.id} />
                    }
                    return (
                      <Card
                        key={template.id}
                        className={classNames(classes.templateCard, {
                          [classes.selectedCard]: selected && isInEditMode,
                        })}
                        onClick={() => {
                          const rating = getRatingInfo(template.rating)
                          if (lodash.isNil(rating)) {
                            return
                          }
                          const valueForRating: ValueForRating = {
                            rating: rating.id,
                            value: template.id,
                          }
                          setSelectedTemplatesIds(
                            lodash.uniqBy(filteredTemplateIds.concat(valueForRating).reverse(), "rating"),
                          )
                        }}
                      >
                        {!!clickable && (
                          <CardActionArea className={classes.cardActionArea}>
                            <Typo selected={selected} template={template} />
                          </CardActionArea>
                        )}
                        {!clickable && (
                          <CardContent className={classes.cardContent}>
                            <Typo selected={selected} template={template} />
                          </CardContent>
                        )}
                      </Card>
                    )
                  })}
            </div>
          </Grid>
        ))}
    </Grid>
  )
}
