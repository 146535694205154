import React, { FunctionComponent, createContext, useState, useEffect, useMemo } from "react"
import {
  UserAndPermissions,
  UserAndPermissionsAssociation,
  UserAndPermissionsUserHousingManagement,
  UserAndPermissionsUserVehicle,
} from "../../../../../api/graphql/query/users-and-assigned-associations"
import lodash from "lodash"
import { AbilityName } from "../../../../../api/graphql/generated/globalTypes"
import { useMutation } from "react-apollo"
import {
  CreateUserResult,
  CreateUserVariables,
  CREATE_USER_MUTATION,
} from "../../../../../api/graphql/mutation/create-user"
import { getGraphQLErrorLocalized } from "../../../../../utils/get-graphql-error-code"
import { useTranslation } from "react-i18next"
import {
  UpdateUserResult,
  UpdateUserVariables,
  UPDATE_USER_MUTATION,
} from "../../../../../api/graphql/mutation/update-user"
import { toast } from "react-toastify"

interface IManageUserContext {
  userId: string
  isInEditMode: boolean
  isInCreateMode: boolean
  onSuccess: () => void
  isValid: boolean
  createUserMutationLoading: boolean
  updateUserMutationLoading: boolean
  onCreateUserAndPermissions: () => void
  onUpdateUserAndPermissions: () => void

  userAndPermissions: UserAndPermissions | null
  permittedAssociations: UserAndPermissionsAssociation[]
  setPermittedAssociations: (newValue: UserAndPermissionsAssociation[]) => void
  defaultTownId: string
  setDefaultTownId: (defaultTownId: string) => void
  email: string
  setEmail: (email: string) => void
  abilities: AbilityName[]
  updateAbilities: (abilities: AbilityName[]) => void
  vehicles: UserAndPermissionsUserVehicle[]
  setVehicles: (vehicles: UserAndPermissionsUserVehicle[]) => void
  housingManagements: UserAndPermissionsUserHousingManagement[]
  setHousingManagements: (housingManagement: UserAndPermissionsUserHousingManagement[]) => void
}

export const ManageUserContext = createContext<IManageUserContext>({} as IManageUserContext)

interface IManageUserContextProviderProps {
  userAndPermissions: UserAndPermissions | null
  onSuccess: () => void
}

export const ManageUserContextProvider: FunctionComponent<IManageUserContextProviderProps> = (props) => {
  const { userAndPermissions, onSuccess } = props
  const { t } = useTranslation()

  const isInCreateMode = lodash.isNil(userAndPermissions)
  const isInEditMode = !isInCreateMode

  console.log(userAndPermissions)
  const id = userAndPermissions?.user.id || ""

  const [vehicles, setVehicles] = useState<UserAndPermissionsUserVehicle[]>(userAndPermissions?.user.vehicles || [])
  const [permittedAssociations, setPermittedAssociations] = useState<UserAndPermissionsAssociation[]>(
    userAndPermissions?.permittedAssociations || [],
  )
  const [email, setEmail] = useState<string>(userAndPermissions?.user.email || "")
  //currently uneditable
  const [name] = useState<string | undefined>(userAndPermissions?.user.name || undefined)
  const [defaultTownId, setDefaultTownId] = useState<string | null>(userAndPermissions?.user.defaultTown?.id || null)
  const [abilities, setAbilities] = useState<AbilityName[]>(userAndPermissions?.user.abilities || [])
  const [housingManagements, setHousingManagements] = useState<UserAndPermissionsUserHousingManagement[]>(userAndPermissions?.user.housingManagements || [])

  const [createUserMutation, { loading: createUserMutationLoading }] = useMutation<
    CreateUserResult,
    CreateUserVariables
  >(CREATE_USER_MUTATION, {
    onError: (error) => {
      toast.error(getGraphQLErrorLocalized(error, t))
    },
    onCompleted: (data) => {
      console.log("create success: ", data)
      onSuccess()
    },
  })

  const [updateUserMutation, { loading: updateUserMutationLoading }] = useMutation<
    UpdateUserResult,
    UpdateUserVariables
  >(UPDATE_USER_MUTATION, {
    onError: (error) => {
      toast.error(getGraphQLErrorLocalized(error, t))
    },
    onCompleted: (data) => {
      console.log("update success: ", data)
      onSuccess()
    },
    update: () => undefined,
  })

  const getBasicMutationVariables = () => {
    const variables = {
      userAndPermissions: {
        user: {
          name,
          defaultTownId,
          abilities,
          vehicleIds: vehicles.map((vehicle) => vehicle.id),
          housingManagementIds: housingManagements.map((hm) => hm.id),
        },
        permittedAssociations: permittedAssociations.map((permAssociation) => {
          return {
            associationId: permAssociation.associationId,
            towns: permAssociation.towns.map((permTown) => permTown.id),
            accessToAllTowns: permAssociation.accessToAllTowns,
          }
        }),
      },
    }

    if (abilities.includes(AbilityName.housing_management)) {
      variables.userAndPermissions.permittedAssociations = []
      variables.userAndPermissions.user.vehicleIds = []
      variables.userAndPermissions.user.defaultTownId = null
    } else {
      variables.userAndPermissions.user.housingManagementIds = []
    }

    return variables
  }

  const getCreateMutationVariables = (): CreateUserVariables => {
    const variables = getBasicMutationVariables()
    return {
      userAndPermissions: {
        user: {
          ...variables.userAndPermissions.user,
          email,
        },
        permittedAssociations: variables.userAndPermissions.permittedAssociations,
      },
    }
  }

  const getUpdateMutationVariables = (): UpdateUserVariables => {
    const variables = getBasicMutationVariables()
    return {
      userAndPermissions: {
        user: {
          ...variables.userAndPermissions.user,
          id,
        },
        permittedAssociations: variables.userAndPermissions.permittedAssociations,
      },
    }
  }

  const isValid = useMemo(() => {
    if (lodash.isNil(email) || lodash.isEmpty(abilities)) {
      return false
    }

    if (abilities.includes(AbilityName.housing_management)) {
      if (lodash.isEmpty(housingManagements)) {
        return false
      }
    } else {
      const isAdminAndAssociationsEmpty = abilities.includes(AbilityName.admin) && lodash.isEmpty(permittedAssociations)
      const associationsAndTownsValid =
        isAdminAndAssociationsEmpty ||
        permittedAssociations.find(
          (permAssociation) => permAssociation.accessToAllTowns || !lodash.isEmpty(permAssociation.towns),
        )

      if (!associationsAndTownsValid) {
        return false
      }
    }
    return true
  }, [permittedAssociations, email, abilities, housingManagements])

  const onCreateUserAndPermissions = async () => {
    if (isValid) {
      await createUserMutation({
        variables: getCreateMutationVariables(),
      })
    }
  }

  const onUpdateUserAndPermissions = async () => {
    if (isValid) {
      await updateUserMutation({
        variables: getUpdateMutationVariables(),
      })
    }
  }

  const updateAbilities = (newAbilities: AbilityName[]) => {
    if (newAbilities.includes(AbilityName.housing_management) && !abilities.includes(AbilityName.housing_management)) {
      setAbilities([AbilityName.housing_management])
    } else if (
      !!newAbilities.find((ability) => ability !== AbilityName.housing_management) &&
      abilities.includes(AbilityName.housing_management)
    ) {
      setAbilities(newAbilities.filter((ability) => ability !== AbilityName.housing_management))
    } else {
      setAbilities(newAbilities)
    }
  }

  return (
    <ManageUserContext.Provider
      value={{
        userId: id,
        userAndPermissions,
        isInEditMode,
        isInCreateMode,
        onSuccess,
        isValid,
        createUserMutationLoading,
        updateUserMutationLoading,
        onCreateUserAndPermissions,
        onUpdateUserAndPermissions,
        setPermittedAssociations,
        permittedAssociations,
        email,
        setEmail,
        abilities,
        updateAbilities,
        defaultTownId,
        setDefaultTownId,
        vehicles,
        setVehicles,
        housingManagements,
        setHousingManagements,
      }}
    >
      {props.children}
    </ManageUserContext.Provider>
  )
}
