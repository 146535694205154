import React, { FunctionComponent } from "react"
import {
  Theme,
  makeStyles,
  ExpansionPanel,
  ExpansionPanelSummary,
  Typography,
  ExpansionPanelDetails,
  Grid,
} from "@material-ui/core"
import { ExpandMore } from "@material-ui/icons"
import { UserAndPermissionsTown } from "../../../api/graphql/query/permitted-associations-for-user"

const useStyles = makeStyles((theme: Theme) => ({
  expPanelSummaryActive: {
    background: theme.palette.primary.main,
    color: "#ffffff",
  },
  expPanelSummary: {
    height: 60,
  },
  expPanelSummaryTypo: {
    fontSize: 20,
  },
  subheading: {
    color: theme.palette.primary.main,
    fontSize: 18,
    marginBottom: theme.spacing(1),
  },
  section: {
    marginTop: theme.spacing(2),
  },
  detailSection: {
    margin: theme.spacing(1),
  },
}))

interface ITownExpansionPanelProps {
  town: UserAndPermissionsTown
  open: boolean
  toggle: () => void
}

export const TownExpansionPanel: FunctionComponent<ITownExpansionPanelProps> = (props) => {
  const classes = useStyles()
  const { town, open, toggle } = props
  // we use an extra variable for isOpen, so we can remove the town table from the dom (lazy loading for towns)
  // could also be used for lazy loading the details of an association, but since there arent that many, this isnt necessary

  return (
    <ExpansionPanel expanded={open}>
      <ExpansionPanelSummary
        expandIcon={<ExpandMore />}
        className={classes.expPanelSummary}
        classes={{ expanded: classes.expPanelSummaryActive }}
        onClick={toggle}
      >
        <Typography className={classes.expPanelSummaryTypo}>{town.name}</Typography>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails>
        <Grid container>
          {open && (
            <Grid item xs={12} className={classes.section}>
              {props.children}
            </Grid>
          )}
        </Grid>
      </ExpansionPanelDetails>
    </ExpansionPanel>
  )
}
