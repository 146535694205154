import React, { FunctionComponent, useMemo, useState, useContext } from "react"
import { Theme, makeStyles, Grid, Paper, Divider, ListItem, ListItemText } from "@material-ui/core"
import { useTranslation } from "react-i18next"
import { COLORS } from "../../../theme/theme"
import { AssociationFilter } from "../text-filter/AssociationFilter"
import { FractionFilter } from "../text-filter/FractionFilter"
import { TownFilter } from "../text-filter/TownFilter"
import { ClearTextFilterButton } from "../text-filter/ClearTextFilterButton"
import { CommunicationCenterContext } from "./CommunicationCenterContext"
import { CommunicationCenterReportListItem } from "./CommunicationCenterReportListItem"
import { PageLoadingIndicator } from "../indicator/PageLoadingIndicator"

export const COMMUNICATION_CENTER_LIST_WIDTH = 260

const useStyles = makeStyles((theme: Theme) => ({
  listContainer: {
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    width: 200,
    background: COLORS.lightgrey,
    borderRadius: 0,
    transition: "width 0.3s",
    padding: theme.spacing(2),
    overflowY: "scroll",
    zIndex: 99,
  },
  list: {
    marginTop: theme.spacing(2),
  },
}))

interface ICommunicationCenterSidebarProps {}

export const CommunicationCenterSidebar: FunctionComponent<ICommunicationCenterSidebarProps> = (props) => {
  const [expanded, setExpanded] = useState<boolean>(false)
  const classes = useStyles()
  const { t } = useTranslation()
  const { reports, reportsLoading, reportsError, fetchReports } = useContext(CommunicationCenterContext)

  const width = useMemo(() => (!expanded ? COMMUNICATION_CENTER_LIST_WIDTH : "90%"), [expanded])
  const loading = useMemo(() => reportsLoading, [reportsLoading])

  const onFilterChange = () => {
    fetchReports()
  }

  return (
    <Paper
      className={classes.listContainer}
      elevation={3}
      onMouseEnter={() => setExpanded(true)}
      onMouseLeave={() => setExpanded(false)}
      style={{ width }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <FractionFilter
            onChange={() => {
              onFilterChange()
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <AssociationFilter onChange={() => {}} />
        </Grid>
        <Grid item xs={12}>
          <TownFilter onChange={() => {}} />
        </Grid>
        <Grid item xs={12}>
          <Grid container justify={"center"}>
            <ClearTextFilterButton />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
      </Grid>
      <Grid container className={classes.list}>
        {loading && <PageLoadingIndicator />}
        {!loading &&
          reports.map((report) => (
            <Grid item xs={12} key={report.id}>
              <CommunicationCenterReportListItem
                report={report}
                expanded={expanded}
                onClick={() => setExpanded(false)}
              />
            </Grid>
          ))}
        {!loading && reports.length === 0 && !reportsError && (
          <ListItem>
            <ListItemText primary={t("errors.no_reports")} secondary={t("errors.no_reports2")} />
          </ListItem>
        )}
        {reportsError && (
          <ListItem>
            <ListItemText primary={t("errors.generic1")} secondary={t("errors.generic2")} />
          </ListItem>
        )}
      </Grid>
    </Paper>
  )
}
