import { DailyGroupedStatsForAnalysis } from "../../../api/graphql/query/daily-grouped-stats-for-analysis"
import { sortASC } from "../../../utils/sort"

export const trimDailyGroupedAnaylsis = (dailyGroupedStats: DailyGroupedStatsForAnalysis[]) => {
  const dailyGroupedStatsOrdered = dailyGroupedStats.sort((a, b) => sortASC(a.date, b.date))
  const dailyGroupedStatsNotNull = dailyGroupedStatsOrdered.filter(
    (dailyGroupedStat) => dailyGroupedStat.overall !== null,
  )

  const firstIndex = dailyGroupedStatsOrdered.indexOf(dailyGroupedStatsNotNull[0])
  const lastIndex = dailyGroupedStatsOrdered.indexOf(dailyGroupedStatsNotNull[dailyGroupedStatsNotNull.length - 1])

  return dailyGroupedStatsOrdered.slice(firstIndex, lastIndex + 1)
}

export const trimDailyGroupedAnaylsisWithBoundaries = (
  dailyGroupedStats: DailyGroupedStatsForAnalysis[],
  boundaryStats: DailyGroupedStatsForAnalysis[],
) => {
  const dailyGroupedStatsOrdered = dailyGroupedStats.sort((a, b) => sortASC(a.date, b.date))
  const boundaryStatsOrdered = boundaryStats.sort((a, b) => sortASC(a.date, b.date))
  const boundaryStatsNotNull = boundaryStatsOrdered.filter((dailyGroupedStat) => dailyGroupedStat.overall !== null)

  const firstIndex = boundaryStatsOrdered.indexOf(boundaryStatsNotNull[0])
  const lastIndex = boundaryStatsOrdered.indexOf(boundaryStatsNotNull[boundaryStatsNotNull.length - 1])

  return dailyGroupedStatsOrdered.slice(firstIndex, lastIndex + 1)
}
