import React, { FunctionComponent } from "react"
import { Grid } from "@material-ui/core"
import { AdminBadge } from "./AdminBadge"
import { Divider } from "@material-ui/core"

interface IAdminBadgeDividerProps {}

export const AdminBadgeDivider: FunctionComponent<IAdminBadgeDividerProps> = (props) => {
  return (
    <Grid container justify="space-between" alignItems="center" alignContent="center" spacing={1}>
      <Grid item xs>
        <Divider />
      </Grid>
      <Grid item>
        <AdminBadge disableFontSize />
      </Grid>
      <Grid item xs>
        <Divider />
      </Grid>
    </Grid>
  )
}
