/*
    Hook which provides all filter functionalities
*/

import React, { createContext, useState } from "react"
import { Statistics, Statistics_statistics, StatisticsVariables } from "../api/graphql/generated/Statistics"
import { useQuery } from "@apollo/react-hooks"
import { STATISTICS_QUERY } from "../api/graphql/query/statistics"
import { ClassificationSelector, StatisticsFilter } from "../api/graphql/generated/globalTypes"
import { ApolloError } from "apollo-client"
import moment from "moment"
import { forceUTC } from "../domain/helper/ForceUTC"

interface StatisticsFilterContextType {
  statistics: Statistics_statistics | undefined
  filter: StatisticsFilter
  setFilter: (filter: StatisticsFilter) => void
  resetFilter: () => void

  contextError: ApolloError | undefined
  contextLoading: boolean
  contextRefetch: () => void
  generateFilterVariables: () => StatisticsFilter
}

export const StatisticsFilterContext = createContext<StatisticsFilterContextType>({
  statistics: undefined,
  filter: {},
  setFilter: () => {},
  resetFilter: () => {},
  contextError: undefined,
  contextLoading: true,
  contextRefetch: () => {},
  generateFilterVariables: () => {
    return {}
  },
})

export const StatisticsFilterProvider = (props: any) => {
  const auth = useStatisticsFilterProvider()

  return <StatisticsFilterContext.Provider value={auth}>{props.children}</StatisticsFilterContext.Provider>
}

const useStatisticsFilterProvider = (): StatisticsFilterContextType => {
  const [filter, setFilter] = useState<StatisticsFilter>({
    timeRange: { start: moment().startOf("month") },
    classification: ClassificationSelector.STANDARD,
  })

  const generateFilterVariables = () => {
    const start = filter?.timeRange?.start ? forceUTC(filter.timeRange.start) : null
    const end = filter?.timeRange?.end ? forceUTC(filter.timeRange.end) : null

    return {
      ...filter,
      timeRange: {
        start,
        end,
      },
    }
  }

  const { data, error: contextError, loading: contextLoading, refetch: contextRefetch } = useQuery<
    Statistics,
    StatisticsVariables
  >(STATISTICS_QUERY, { variables: { filter: generateFilterVariables() }, fetchPolicy: "no-cache" })
  const statistics = data?.statistics
  const resetFilter = () => {
    setFilter({})
  }

  return {
    statistics,
    filter,
    setFilter,
    resetFilter,
    contextError,
    contextLoading,
    contextRefetch,
    generateFilterVariables,
  }
}
