import { gql } from "apollo-boost"
import {
  detailedUserAndPermissions,
  detailedUserAndPermissionsVariables,
  detailedUserAndPermissions_userAndPermissions_user,
  detailedUserAndPermissions_userAndPermissions_permittedAssociations,
  detailedUserAndPermissions_userAndPermissions_permittedAssociations_towns,
  detailedUserAndPermissions_userAndPermissions,
} from "../generated/detailedUserAndPermissions"

export const DETAILED_USER_AND_PERMISSIONS_QUERY = gql`
  query detailedUserAndPermissions($userId: ID) {
    userAndPermissions(userId: $userId) {
      user {
        id
        email
        abilities
        defaultTown {
          id
          name
          association {
            id
            name
          }
        }
      }
      permittedAssociations {
        id
        associationId
        name
        accessToAllTowns
        latitude
        longitude
        radius
        documentDeletionRangeInDays
        towns {
          id
          name
          latitude
          longitude
          radius
          documentDeletionRangeInDays
        }
      }
    }
  }
`

export type DetailedUserAndPermissionsResult = detailedUserAndPermissions
export type DetailedUserAndPermissions = detailedUserAndPermissions_userAndPermissions
export type DetailedUserAndPermissionsUser = detailedUserAndPermissions_userAndPermissions_user
export type DetailedUserAndPermissionsAssociation = detailedUserAndPermissions_userAndPermissions_permittedAssociations
export type DetailedUserAndPermissionsTown = detailedUserAndPermissions_userAndPermissions_permittedAssociations_towns
// export type DetailedUserAndPermissionsEmptying = detailedUserAndPermissions_userAndPermissions_permittedAssociations_towns_emptyings
export type DetailedUserAndPermissionsVariables = detailedUserAndPermissionsVariables
