import {
  ImportRfidChipsForHousingManagement_importRfidChipsForHousingManagement,
} from "./../generated/ImportRfidChipsForHousingManagement"
import { gql } from "apollo-boost"

export const IMPORT_RFID_CHIPS_FOR_HOUSING_MANAGEMENT_MUTATION = gql`
  mutation ImportRfidChipsForHousingManagement($importRfidChipsForHousingManagementId: String!, $file: Upload!, $ignoreWarnings: Boolean) {
    importRfidChipsForHousingManagement(id: $importRfidChipsForHousingManagementId, file: $file, ignoreWarnings: $ignoreWarnings) {
      importDone
      errors {
        id
        type
        line
        additionalData
      }
      warnings {
        id
        type
        line
        additionalData
      }
    }
  }
`


export interface ImportRfidChipsForHousingManagementResult extends ImportRfidChipsForHousingManagement_importRfidChipsForHousingManagement{}