import React from "react"
import PropTypes from "prop-types"
import lodash from "lodash"
import { withTranslation } from "react-i18next"

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles"

import { WidgetModificationType } from "../../../../domain/models/WidgetModels"
import WidgetHeader from "./WidgetHeader"
import { WertstoffAnalysisHistoryGraph } from "../../analysis-data/WertstoffAnalysisHistoryGraph"
import QueryState from "../../state-management/QueryState"

import { Query } from "react-apollo"
import { TourTypeDictionary } from "../../../../domain/models/TourTypes"
import { WertstoffAnalysisPieGraph } from "../../analysis-data/WertstoffAnalysisPieGraph"
import EditWidgetGraphDialog from "../dialogs/EditWidgetGraphDialog"
import Typography from "@material-ui/core/Typography"
import { Grid } from "@material-ui/core"
import { WidgetType } from "../../../../api/graphql/generated/globalTypes"
import { ANALYTICS_FOR_FILTER_QUERY } from "../../../../api/graphql/query/analyticsForFilter"
import { WidgetTrendGraphWrapper } from "./WidgetTrendGraphWrapper"
import { WidgetTrendComparisonGraphWrapper } from "./WidgetTrendComparisonGraphWrapper"
import { WidgetPieChartWasteQuality } from "./WidgetPieChartWasteQuality"

// @Styles
const styles = (theme) => ({
  content: {
    padding: theme.spacing(2),
  },
  centerContent: {
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)",
  },
})

function Graph(props) {
  const { widgetModel, analysisData, tourType, onExportCreated, fileTitle, evaluation, filter } = props

  if (lodash.isNil(tourType)) {
    return null
  }

  switch (widgetModel.widgetType) {
    case WidgetType.chart_history:
      const fullAnalysis = { materialAnalysis: analysisData, evaluation }
      return (
        <WertstoffAnalysisHistoryGraph
          materialAnalysis={fullAnalysis.materialAnalysis}
          evaluation={fullAnalysis.evaluation}
          compact={widgetModel.chartCompact}
          legend
          tourTypeInfo={tourType}
          grouped={false}
          onExportCreated={(fileUri, extension) => onExportCreated(fileTitle, fileUri, extension)}
        />
      )
    case WidgetType.chart_pie:
      return (
        <WertstoffAnalysisPieGraph
          evaluation={evaluation}
          compact={widgetModel.chartCompact}
          legend
          tourTypeInfo={tourType}
          onExportCreated={(fileUri, extension) => onExportCreated(fileTitle, fileUri, extension)}
        />
      )
    case WidgetType.chart_pie_waste_quality:
      return (
        <WidgetPieChartWasteQuality
          materialAnalysis={analysisData}
          onExportCreated={(fileUri, extension) => onExportCreated(fileTitle, fileUri, extension)}
        />
      )
    case WidgetType.chart_trend:
      return (
        <WidgetTrendGraphWrapper
          filter={filter}
          tourTypeInfo={tourType}
          onExportCreated={(fileUri, extension) => onExportCreated(fileTitle, fileUri, extension)}
        />
      )
    case WidgetType.chart_trend_comparison:
      return (
        <WidgetTrendComparisonGraphWrapper
          filter={filter}
          tourTypeInfo={tourType}
          onExportCreated={(fileUri, extension) => onExportCreated(fileTitle, fileUri, extension)}
        />
      )
    default:
      return null
  }
}

class WidgetGraph extends React.Component {
  constructor(props) {
    super(props)
    this.props = props

    this.state = {
      //editOpen: lodash.isNil(lodash.get(props.widgetModel, "filter.updatedAt")),
      editOpen: false,
      tourType: TourTypeDictionary.find(
        (tourType) => tourType.type === lodash.get(props.widgetModel, "filter.tourType"),
      ),
    }
  }

  componentWillReceiveProps(nextProps) {
    this.props = nextProps
  }

  handleEditDialogClose = (refetch) => {
    this.setState({
      editOpen: false,
    })
    /* we can use that if we want to refetch the analytics after editing (useful if we have to reuse widget level filters)
      if (lodash.isFunction(refetch)) {
        refetch()
      }
    */
  }

  handleModification = (type, widgetModel) => {
    if (type === WidgetModificationType.DELETE) {
      this.props.onModification(type, widgetModel)
    } else if (type === WidgetModificationType.DUPLICATE) {
      this.props.onModification(type, widgetModel)
    } else if (type === WidgetModificationType.TRANSFORM) {
      this.props.onModification(type, widgetModel)
    } else if (type === WidgetModificationType.EDIT) {
      this.props.onModification(type, widgetModel)
      this.setState({
        editOpen: true,
      })
    }
  }

  handleQueryCompleted = (data) => {
    const materialAnalysis = lodash.get(data, "materialAnalysisForFilter.materialAnalysis")
    if (lodash.isNil(materialAnalysis) || lodash.isEmpty(materialAnalysis)) {
      this.props.onExportFailed()
    }
  }

  titleFrom = (widgetModel) => {
    let title =
      widgetModel.title +
      " (" +
      widgetModel.widgetType +
      "-" +
      widgetModel.channelType +
      "-" +
      widgetModel.id.substring(5, 10) +
      ")"
    return title
  }

  render() {
    const { onExportCreated, t } = this.props
    return (
      <Query
        query={ANALYTICS_FOR_FILTER_QUERY}
        variables={{
          filterId: this.props.widgetModel.filter.id ?? "--",
        }}
        onCompleted={this.handleQueryCompleted}
      >
        {({ data, loading, error, refetch }) => {
          const materialAnalysis = lodash.get(data, "materialAnalysisForFilter.materialAnalysis")
          const evaluation = lodash.get(data, "materialAnalysisForFilter.evaluation")

          return (
            <QueryState loading={loading} error={error} notNil={materialAnalysis}>
              <div style={{ padding: 0 }}>
                <WidgetHeader widgetModel={this.props.widgetModel} onModification={this.handleModification} />
                {!lodash.isNil(this.props.widgetModel.filter) && (
                  <Grid container justify="center" align="center">
                    {!lodash.isNil(materialAnalysis) &&
                    !lodash.isEmpty(materialAnalysis) &&
                    !lodash.isNil(evaluation) ? (
                      <Graph
                        filter={this.props.report.filter}
                        widgetModel={this.props.widgetModel}
                        analysisData={materialAnalysis}
                        evaluation={evaluation}
                        legend
                        onExportCreated={onExportCreated}
                        tourType={this.state.tourType}
                        fileTitle={this.titleFrom(this.props.widgetModel)}
                      />
                    ) : (
                      <Grid item>
                        <Typography variant={"body1"}>
                          {t("general.to_few_data_diagram")}
                          {t("general.please_change_filter")}
                        </Typography>
                      </Grid>
                    )}
                  </Grid>
                )}

                {/* Dialog for editing the text widget: */}
                {this.state.editOpen && (
                  <EditWidgetGraphDialog
                    open={this.state.editOpen}
                    materialAnalysis={lodash.get(data, "materialAnalysis")}
                    tourType={this.state.tourType}
                    widgetModel={this.props.widgetModel}
                    onModification={(type, widgetModel) => this.handleModification(type, widgetModel)}
                    onClose={() => this.handleEditDialogClose(refetch)}
                  />
                )}
              </div>
            </QueryState>
          )
        }}
      </Query>
    )
  }
}

WidgetGraph.propTypes = {
  widgetModel: PropTypes.object,
  onModification: PropTypes.func,
  onExportCreated: PropTypes.func,
  onExportFailed: PropTypes.func,
}

WidgetGraph.defaultProps = {
  widgetModel: null,
  onModification: null,
  onExportCreated: null,
  onExportFailed: () => null,
}

export default withStyles(styles)(withTranslation()(WidgetGraph))
