import React, { FunctionComponent, Fragment } from "react"
import { Theme, makeStyles, Card, Grid, Typography, Button } from "@material-ui/core"
import { useTranslation } from "react-i18next"
import { Description } from "@material-ui/icons"
import { FileTemplate } from "../../../api/graphql/query/file-templates"

const useStyles = makeStyles((theme: Theme) => ({
  card: {
    padding: theme.spacing(1),
  },
  iconContainer: {
    backgroundColor: theme.palette.primary.main,
    borderRadius: 90,
    width: 90,
    height: 90,
  },
  icon: {
    color: theme.palette.common.white,
    width: 60,
    height: 60,
  },
  button: {
    width: "100%",
    textDecoration: "none",
  },
  text: {
    whiteSpace: "nowrap",
    fontSize: 20,
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  a: {
    textDecoration: "none",
  },
  itemContainer: {
    flexWrap: "nowrap",
  },
}))

interface IFileTemplateItemProps {
  fileTemplate: FileTemplate
}

export const FileTemplateItem: FunctionComponent<IFileTemplateItemProps> = (props) => {
  const classes = useStyles()
  const { fileTemplate } = props
  const { t } = useTranslation()
  return (
    <Fragment>
      <Card className={classes.card}>
        <Grid container className={classes.itemContainer} direction="column" alignItems="center">
          <Grid item>
            <Grid container className={classes.iconContainer} justify="center" alignItems="center">
              <Description className={classes.icon} />
            </Grid>
          </Grid>
          <Grid item>
            <Typography className={classes.text}>{fileTemplate.name}</Typography>
          </Grid>
          <Grid item>
            <a className={classes.a} href={fileTemplate.url} target="_blank">
              <Button color="primary" variant="outlined" className={classes.button}>
                {t("general.download")}
              </Button>
            </a>
          </Grid>
        </Grid>
      </Card>
    </Fragment>
  )
}
