import React, { FunctionComponent, Fragment, useState, useContext } from "react"
import { Grid, Typography, Tooltip, IconButton, makeStyles, Theme } from "@material-ui/core"
import { useTranslation } from "react-i18next"
import { Add, Bookmarks, Save, Delete } from "@material-ui/icons"
import { CreateBookmarkDialog } from "./bookmark/CreateBookmarkDialog"
import { useMutation } from "react-apollo"
import {
  CreateFilterResult,
  CREATE_FILTER_MUTATION,
  CreateFilterVariables,
} from "../../../api/graphql/mutation/create-filter"
import { AnalysisFilterContext } from "../../../context/AnalysisFilterContext"
import lodash from "lodash"
import { Bookmark } from "../../../api/graphql/query/bookmarks-for-user"
import { ApolloError } from "apollo-boost"
import { checkIfBookmarkIsEdited } from "./helpers/checkIfBookmarkIsEdited"
import {
  UpdateFilterResult,
  UPDATE_FILTER_MUTATION,
  UpdateFilterVariables,
} from "../../../api/graphql/mutation/update-filter"
import { getFilterInputVariables } from "./helpers/getFilterInputVariables"
import {
  DeleteFilterVariables,
  DELETE_FILTER_MUTATION,
  DeleteFilterResult,
} from "../../../api/graphql/mutation/delete-filter"
import { UserContext } from "../../../context/UserContext"

const useStyles = makeStyles((theme: Theme) => ({
  editedTypo: {
    fontSize: 12,
  },
}))

interface IAnalysisFilterHeaderProps {
  onOpenBookmarkDrawer: () => void
  selectedBookmark: Bookmark | null
  onSelectBookmark: (bookmark: Bookmark | null) => void
}

export const AnalysisFilterHeader: FunctionComponent<IAnalysisFilterHeaderProps> = (props) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const { filterModel, selectedEmptying } = useContext(AnalysisFilterContext)
  const { onOpenBookmarkDrawer, onSelectBookmark, selectedBookmark } = props

  const [bookmarkCreateDialogOpen, setBookmarkCreateDialogOpen] = useState<boolean>(false)

  const [createFilter] = useMutation<CreateFilterResult, CreateFilterVariables>(CREATE_FILTER_MUTATION, {
    onCompleted: (data) => onFilterCreated(data),
    onError: (error) => onMutationError(error),
  })
  const [updateFilter] = useMutation<UpdateFilterResult, UpdateFilterVariables>(UPDATE_FILTER_MUTATION, {
    onCompleted: (data) => onFilterCreated(data),
    onError: (error) => onMutationError(error),
  })
  const [deleteFilter] = useMutation<DeleteFilterResult, DeleteFilterVariables>(DELETE_FILTER_MUTATION, {
    onCompleted: (data) => onFilterDeleted(data),
    onError: (error) => onMutationError(error),
  })

  const onFilterCreated = (data: CreateFilterResult | UpdateFilterResult) => {
    const createdBookmark = lodash.get(data, "createFilter")
    const updatedBookmark = lodash.get(data, "updateFilter")

    if (createdBookmark) {
      onSelectBookmark(createdBookmark)
    } else if (updatedBookmark) {
      onSelectBookmark(updatedBookmark)
    }
    setBookmarkCreateDialogOpen(false)
  }

  const onFilterDeleted = (data: DeleteFilterResult) => {
    onSelectBookmark(null)
    setBookmarkCreateDialogOpen(false)
  }

  const onMutationError = (error: ApolloError) => {
    console.log(error)
  }

  const { user } = useContext(UserContext)

  const onCreate = (name: string) => {
    const filter: CreateFilterVariables = getFilterInputVariables(filterModel, name, selectedEmptying, user?.id)
    createFilter({
      variables: { ...filter },
    }).then()
  }

  const onUpdate = () => {
    const filter: UpdateFilterVariables = {
      ...getFilterInputVariables(filterModel, selectedBookmark?.name ?? null, selectedEmptying, user?.id),
      id: selectedBookmark?.id ?? "",
    }
    console.log("filter: ", filter)
    updateFilter({
      variables: { ...filter },
    }).then()
  }

  const onDelete = () => {
    if (selectedBookmark) {
      deleteFilter({ variables: { id: selectedBookmark.id } }).then()
    }
  }

  const isEdited = checkIfBookmarkIsEdited(selectedBookmark, filterModel)

  return (
    <Fragment>
      {/* DIALOGS */}
      {bookmarkCreateDialogOpen && (
        <CreateBookmarkDialog
          open={bookmarkCreateDialogOpen}
          onClose={() => setBookmarkCreateDialogOpen(false)}
          onCreate={(name) => onCreate(name)}
        />
      )}
      <Grid container justify={"space-between"}>
        <Grid item>
          <Typography color={"primary"} variant="h6">
            {t("analysis_page.heading")}
          </Typography>
        </Grid>
        <Grid item>
          <Grid container justify={"center"} alignContent={"center"} alignItems={"center"} spacing={1}>
            {selectedBookmark && isEdited && (
              <Grid item>
                <Typography color="secondary" className={classes.editedTypo}>
                  {`(${t("general.edited")})`}
                </Typography>
              </Grid>
            )}
            {selectedBookmark && (
              <Grid item>
                <Typography color="secondary">{selectedBookmark.name}</Typography>
              </Grid>
            )}
            {selectedBookmark && (
              <Grid item>
                <Tooltip title={t("bookmark.delete_bookmark")}>
                  <IconButton size={"small"} onClick={onDelete}>
                    <Delete color={"primary"} />
                  </IconButton>
                </Tooltip>
              </Grid>
            )}
            {selectedBookmark && (
              <Grid item>
                <Tooltip title={t("bookmark.save_bookmark")}>
                  <IconButton size={"small"} onClick={onUpdate} disabled={!isEdited}>
                    {isEdited ? <Save color={"primary"} /> : <Save color={"secondary"} />}
                  </IconButton>
                </Tooltip>
              </Grid>
            )}
            <Grid item>
              <Tooltip title={t("bookmark.save_new_bookmark")}>
                <IconButton size={"small"} onClick={() => setBookmarkCreateDialogOpen(true)}>
                  <Add color={"primary"} />
                </IconButton>
              </Tooltip>
            </Grid>
            <Grid item>
              <Tooltip title={t("general.bookmark")}>
                <IconButton size={"small"} onClick={onOpenBookmarkDrawer}>
                  <Bookmarks color={"primary"} />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Fragment>
  )
}
