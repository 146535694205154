import React, { FunctionComponent, useMemo } from "react"
import _ from "lodash"
import { useTranslation } from "react-i18next"

// @material-ui/core components
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableRow from "@material-ui/core/TableRow"
import { createEvaluationsPerFraction } from "../../../constants/DataManipulators"
import { Grid, makeStyles, Theme, Tooltip, Typography } from "@material-ui/core"
import { Evaluation } from "../../../api/graphql/fragments/evaluation_fragments"
import { LabelSetMaterialType } from "../../../api/graphql/generated/globalTypes"
import { theme } from "../../../theme/theme"
import { CheckCircle, AddCircle } from "@material-ui/icons"

// @Styles
const useStyles = makeStyles((theme: Theme) => ({
  tableRow: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.background.default,
    },
  },
  colorIndicator: {
    margin: 0,
    padding: 0,
    borderRadius: 20,
    height: "10px",
    width: "10px",
  },
  mainFractionIndicator: {
    margin: 0,
    padding: 0,
    height: "25px",
    width: "7px",
  },
  text: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  table: {},
  heading: {
    fontSize: 16,
  },
  subHeading: {
    fontSize: 13,
    marginLeft: theme.spacing(1),
    color: theme.palette.secondary.main,
  },
  missThrowIndicator: {
    msTransform: "rotate(45deg) translate3d( 0, 0, 0)",
    transform: "rotate(45deg) translate3d( 0, 0, 0)",
  },
  mainFractionInfo: {
    display: "flex",
    opacity: (props: IStyleProps) => (props.showIndicator ? 1 : 0),
  },
}))

interface IWertstoffAnalysisTableProps {
  evaluation: Evaluation
  compact: boolean
  labelSetMaterialTypes: LabelSetMaterialType[]
  withBags?: boolean
  showBagText?: boolean
}

interface TableData {
  id: string
  name: string
  isMainFraction: boolean
  percentMean: number
  color: string
}

interface IStyleProps {
  showIndicator: boolean
}

export const WertstoffAnalysisTable: FunctionComponent<IWertstoffAnalysisTableProps> = (props) => {
  const { t } = useTranslation()
  const { evaluation, compact, labelSetMaterialTypes, withBags } = props
  
  const showBagText = labelSetMaterialTypes.length === 1 && labelSetMaterialTypes.includes(LabelSetMaterialType.bags)
  const classes = useStyles({ showIndicator: !showBagText })

  const tableData = useMemo(() => {
    if (_.isNil(evaluation)) {
      return []
    }
    // Percentage correction deviation calculated for 100 percent
    let deviation = showBagText ? Math.round(evaluation.percentOfCategoryWithBags.bags * 100) : 100

    let tableData: TableData[] = []
    createEvaluationsPerFraction(evaluation, labelSetMaterialTypes, !!withBags)
      .filter((entry) => Math.round(entry.value * 100) > 0)
      .sort((a, b) => b.value - a.value)
      .forEach((entry) => {
        if (entry.value > 0) {
          deviation = deviation - Math.round(entry.value * 100)
          tableData.push({
            id: entry.fraction.id,
            name: t(entry.fraction.name),
            percentMean: Math.round(entry.value * 100),
            color: entry.fraction.color,
            isMainFraction: entry.isMainFraction,
          })
        }
      })
    if (tableData.length > 0) {
      // Apply deviation to main distribution percentMean for percentage correction
      tableData[0].percentMean = tableData[0].percentMean + deviation
    }

    return tableData
  }, [evaluation, t, withBags, showBagText, labelSetMaterialTypes])

  let rowStyle = {
    height: compact ? 30 : 42,
  }
  let cellStyle = {
    paddingRight: compact ? 12 : 24,
    paddingLeft: compact ? 12 : 24,
    paddingTop: compact ? 6 : 12,
    paddingBottom: compact ? 6 : 12,
  }

  const FractionTableRow = (props: { evaluation: TableData }) => (
    <TableRow key={props.evaluation.id} className={classes.tableRow} style={rowStyle}>
      <TableCell scope="row" style={cellStyle}>
        <Grid container spacing={1} alignItems={"center"}>
          <Grid item>
            <div className={classes.colorIndicator} style={{ backgroundColor: props.evaluation.color }} />
          </Grid>
          <Grid item>
            <Typography className={classes.text}>{props.evaluation.name}</Typography>
          </Grid>
        </Grid>
      </TableCell>
      <TableCell style={cellStyle} align="right">
        <Grid container alignItems="center" spacing={1}>
          <Grid item xs>{`${props.evaluation.percentMean} %`}</Grid>
          <Grid item className={classes.mainFractionInfo}>
            <Tooltip title={t(`general.${props.evaluation.isMainFraction ? "main_fraction" : "missthrow"}`)}>
              {props.evaluation.isMainFraction ? (
                <CheckCircle
                  style={{
                    color: theme.palette.success.main,
                  }}
                />
              ) : (
                <AddCircle
                  className={classes.missThrowIndicator}
                  style={{
                    color: theme.palette.error.dark,
                  }}
                />
              )}
            </Tooltip>
          </Grid>
        </Grid>
      </TableCell>
    </TableRow>
  )

  return (
    <Grid container>
      {tableData.length > 0 && showBagText && (
        <Grid item xs={12} container>
          <Grid container>
            <Grid item xs container alignItems="center">
              <Typography variant={"body1"} className={classes.heading}>
                {t("analysis_page.list.bag_amount")}
              </Typography>
              <Typography variant={"body1"} className={classes.subHeading}>
                ({t("analysis_page.list.bag_amount_description")})
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant={"body1"} className={classes.heading}>{`${(
                (evaluation.percentOfCategoryWithBags.bags ?? 0) * 100
              ).toFixed(0)} %`}</Typography>
            </Grid>
          </Grid>
        </Grid>
      )}
      <Grid item xs={12}>
        <Table className={classes.table}>
          <TableBody>
            {tableData.map((n) => (
              <FractionTableRow key={n.id} evaluation={n} />
            ))}
          </TableBody>
        </Table>
      </Grid>
    </Grid>
  )
}
