import { gql } from "apollo-boost"
import {
  verifyCommunicationChannel,
  verifyCommunicationChannelVariables,
} from "../generated/verifyCommunicationChannel"

export const VERIFY_COMMUNICATION_CHANNEL_MUTATION = gql`
  mutation verifyCommunicationChannel($token: String!) {
    verifyCommunicationChannel(token: $token) {
      error
      message
    }
  }
`

export type VerifyCommunicationChannelResult = verifyCommunicationChannel
export type VerifyCommunicationChannelVariables = verifyCommunicationChannelVariables
