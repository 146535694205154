import { useCallback, useContext } from "react"
import lodash from "lodash"
import moment from "moment"
import { ReportDetail } from "../../../../../api/graphql/query/report-detailed"
import { ChannelType } from "../../../../../api/graphql/generated/globalTypes"
import { WidgetExportsContext } from "../../context/WidgetExportsContext"
import { ExportExtensionType } from "../../../../../domain/models/ExportExtensionType"
import JSZip from "jszip"

export const useWidgetDownload = (channelType: ChannelType, report: ReportDetail) => {
  const { widgetExportsDictionary } = useContext(WidgetExportsContext)

  const onDownload = useCallback(() => {
    let zipName =
      report.title +
      "_" +
      moment().format("DD.MM.YYYY-HH.mm")
    let zip = new JSZip()

    //@ts-ignore
    let zipFolder = zip.folder(zipName)

    let textFolder = zipFolder.folder("texte")
    let chartsFolder = zipFolder.folder("grafiken")

    for (let widgetExport of lodash.toArray(widgetExportsDictionary[channelType])) {
      let fullFileName = widgetExport.fileName + widgetExport.extension
      if (widgetExport.extension === ExportExtensionType.TXT) {
        let content = widgetExport.uri
        textFolder.file(fullFileName, content)
      } else if (widgetExport.extension === ExportExtensionType.DOCX) {
        let content = widgetExport.uri
        textFolder.file(fullFileName, content)
      } else if (widgetExport.extension === ExportExtensionType.PNG) {
        let uri = widgetExport.uri
        let idx = uri.indexOf("base64,") + "base64,".length
        let content = uri.substring(idx)

        chartsFolder.file(fullFileName, content, { base64: true })
      } else {
        console.log("handleDownloadWidgets: Unknown extension for ", widgetExport)
      }
    }

    // Generate the zip file asynchronously and trigger download
    //@ts-ignore
    zip.generateAsync({ type: "blob" }).then(function (content) {
      // Force download of the Zip file
      saveAs(content, zipName + ".zip")
    })
  }, [channelType, report, widgetExportsDictionary])

  return onDownload
}
