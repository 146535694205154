import React from "react"
import { makeStyles, Fab } from "@material-ui/core"
import LogoutIcon from "@material-ui/icons/ExitToApp"

import { AuthConsumer } from "../../../context/AuthContext"

const useStyles = makeStyles(theme => ({
  fab: {
    marginTop: 10,
    marginRight: 10,
    marginBottom: 10,
    marginLeft: 0
  },
  icon: {
    transform: "scaleX(-1)"
  }
}))

export const LogoutButton = props => {
  const classes = useStyles()

  const handleLogout = logout => {
    logout()
  }

  return (
    <AuthConsumer>
      {({ logout }) => (
        <Fab
          size="small"
          color="primary"
          aria-label="Logout"
          className={classes.fab}
          onClick={() => handleLogout(logout)}
        >
          <LogoutIcon className={classes.icon} />
        </Fab>
      )}
    </AuthConsumer>
  )
}
