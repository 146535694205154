import lodash from "lodash"
import formatDate from "../../utils/format-date"
import React from "react"
import { Typography } from "@material-ui/core"
import { nameOfEmptying } from "../../utils/emptying-helper"

export function generateWidgetTitleFromFilter(widgetType, filter, t) {
  //if text, just use the regular title
  let title = t(widgetType.genericTitle)
  //if graphic, generate
  if (widgetType.isGraphic) {
    if (!lodash.isNil(filter)) {
      // if emptying else date
      const { emptyingWeekNumber, emptyingYearNumber } = filter
      if (!lodash.isNil(emptyingWeekNumber) && !lodash.isNil(emptyingYearNumber)) {
        const emptying = {
          weekNumber: emptyingWeekNumber,
          yearNumber: emptyingYearNumber
        }
        title += " " + nameOfEmptying(emptying, t)
      } else {
        const dateFrom = lodash.get(filter, "dateFrom")
        const dateUntil = lodash.get(filter, "dateUntil")
        const dateFromExists = !lodash.isNil(dateFrom)
        const dateUntilExists = !lodash.isNil(dateUntil)

        if (dateFromExists && dateUntilExists) {
          title += " " + formatDate(dateFrom) + " bis " + formatDate(dateUntil)
        } else if (dateFromExists && !dateUntilExists) {
          title += " ab " + formatDate(dateFrom)
        } else if (!dateFromExists && dateUntilExists) {
          title += " bis " + formatDate(dateFrom)
        }
      }
      //if town else association
      const town = lodash.get(filter, "town")
      if (!lodash.isNil(town)) {
        title += " " + town.name
      } else {
        const association = lodash.get(filter, "association")
        if (!lodash.isNil(association)) {
          title += " " + association.name
        }
      }
    }
  }
  return title
}

export function generateWidgetTitleFromRawData(
  permittedAssociations,
  widgetType,
  associationId,
  townId,
  emptying,
  dateFrom,
  dateUntil,
  t
) {
  let title = t(widgetType.genericTitle)

  let association = undefined
  let town = undefined

  if (!lodash.isNil(associationId)) {
    association = permittedAssociations.find(
      association => association.associationId === associationId
    )
  }

  if (!lodash.isNil(townId) && !lodash.isNil(association)) {
    town = association.towns.find(town => town.id === townId)
  }

  if (!lodash.isNil(emptying)) {
    title += " " + nameOfEmptying(emptying, t)
  } else {
    //add date if no emptying
    const dateFromExists = !lodash.isNil(dateFrom)
    const dateUntilExists = !lodash.isNil(dateUntil)

    if (dateFromExists && dateUntilExists) {
      title += " " + formatDate(dateFrom) + " bis " + formatDate(dateUntil)
    } else if (dateFromExists && !dateUntilExists) {
      title += " ab " + formatDate(dateFrom)
    } else if (!dateFromExists && dateUntilExists) {
      title += " bis " + formatDate(dateFrom)
    }
  }

  if (town) {
    title += " " + town.name
  } else if (association) {
    title += " " + association.name
  }
  return title
}

export function formatText(text) {
  if (!lodash.isNil(text)) {
    const texts = text.split("\\n")
    return texts.map((paragraph, index) => {
      return (
        <Typography variant={"body1"} key={index}>
          {paragraph}
        </Typography>
      )
    })
  } else {
    return <p></p>
  }
}
