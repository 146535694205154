import React, { FunctionComponent, useEffect, useContext, useState, ChangeEvent } from "react"
import {
  Theme,
  makeStyles,
  Grid,
  Typography,
  Card,
  TextField,
  Button,
  Divider,
  CircularProgress,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core"
import { Trans, useTranslation } from "react-i18next"
import { useLocation, useHistory } from "react-router"
import queryString from "query-string"
import lodash, { isArray } from "lodash"
import { AuthContext } from "../../context/AuthContext"
import { useMutation } from "react-apollo"
import {
  CHANGE_PASSWORD_MUTATION,
  ChangePasswordResult,
  ChangePasswordVariables,
} from "../../api/graphql/mutation/change-password"
import { toast } from "react-toastify"
import { getGraphqlErrorCode, getWERErrorCode } from "../../utils/get-graphql-error-code"
import { LoginLogo } from "../partials/logo/LoginLogo"
import { ChangePasswordError } from "../../api/graphql/generated/globalTypes"

const useStyles = makeStyles((theme: Theme) => ({
  background: {
    background: theme.palette.secondary.light,
    height: "100vh",
  },
  wrapper: {
    flexDirection: "row",
    height: "70vh",
    minHeight: "500px",
    marginRight: "auto;",
    marginBottom: "0px;",
    marginLeft: "auto;",
    width: "500px",
    color: "white",
  },
  infoTypo: {
    color: theme.palette.secondary.main,
    fontSize: 14,
  },
  link: {
    color: theme.palette.primary.main,
  },
  headingTypo: {
    marginTop: 10,
    textTransform: "uppercase",
  },
  card: {
    padding: theme.spacing(3),
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(5),
  },
  cardHeading: {
    color: theme.palette.secondary.dark,
    textTransform: "uppercase",
    fontSize: 16,
  },
  control: {
    width: "80%",
  },
  buttonSection: {
    marginTop: theme.spacing(3),
  },
  loadingIndicator: {
    color: theme.palette.secondary.main,
  },
  passwordInfo: {
    textAlign: "center",
    width: "80%",
  },
}))

interface IPasswordResetPageProps {
  initiallySetPassword: boolean
}

export const PasswordResetPage: FunctionComponent<IPasswordResetPageProps> = ({ initiallySetPassword }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { search } = useLocation()
  const history = useHistory()
  const authentication: any = useContext(AuthContext)
  const token = lodash.get(queryString.parse(search), "password-reset-token", "") as string

  const [acceptedPrivacyPolicy, setAcceptedPrivacyPolicy] = useState<boolean>(!initiallySetPassword)

  const [changePasswordMutation, { loading: changePasswordMutationLoading }] = useMutation<
    ChangePasswordResult,
    ChangePasswordVariables
  >(CHANGE_PASSWORD_MUTATION, {
    onError: (error) => {
      if (Object.values<string>(ChangePasswordError).includes(getWERErrorCode(error))) {
        toast.error(t(`api_error.change_password.${getWERErrorCode(error)}`))
      } else {
        toast.error(t(`api_error.${getGraphqlErrorCode(error)}`))
      }

      console.log("error: ", error)
    },
    onCompleted: (data) => {
      if (!lodash.get(data, "changePassword.error")) {
        history.push("/login")
      }
      console.log("update success: ", data)
    },
  })

  const [isValid, setIsValid] = useState<boolean>(false)
  const [password, setPassword] = useState<string>("")
  const [passwordCheck, setPasswordCheck] = useState<string>("")

  useEffect(() => {
    authentication.logout()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    setIsValid(!!password && password.length >= 6 && password === passwordCheck && acceptedPrivacyPolicy)
  }, [password, passwordCheck, acceptedPrivacyPolicy])

  const onPasswordReset = async () => {
    if (isValid) {
      await changePasswordMutation({
        variables: {
          token,
          password,
        },
      })
    }
  }

  return (
    <div className={classes.background}>
      <Grid container justify="center" alignContent="center" className={classes.wrapper}>
        <Grid item>
          <Card className={classes.card}>
            <form>
              <Grid container spacing={3}>
                <Grid item xs={12} container justify={"center"}>
                  <Grid item xs={12} container>
                    <LoginLogo />
                  </Grid>
                </Grid>
                <Grid item xs={12} container justify={"center"}>
                  <Typography className={classes.passwordInfo}>
                    {t(`api_error.change_password.${ChangePasswordError.NOT_ENOUGH_DIFFERENCT_CHARACTERS}`)}
                  </Typography>
                </Grid>
                <Grid item xs={12} container justify={"center"}>
                  <TextField
                    className={classes.control}
                    onChange={(event: ChangeEvent<HTMLInputElement>) => setPassword(event.target.value)}
                    name={"password"}
                    type={"password"}
                    label={t("password_reset.new_password")}
                  />
                </Grid>
                <Grid item xs={12} container justify={"center"}>
                  <TextField
                    className={classes.control}
                    onChange={(event: ChangeEvent<HTMLInputElement>) => setPasswordCheck(event.target.value)}
                    name={"passwordCheck"}
                    type={"password"}
                    label={t("password_reset.new_password_again")}
                  />
                </Grid>
                {initiallySetPassword && (
                  <Grid item xs={12} container justify="center">
                    <FormControlLabel
                      className={classes.control}
                      control={
                        <Checkbox
                          color="primary"
                          checked={acceptedPrivacyPolicy}
                          onChange={() => setAcceptedPrivacyPolicy(!acceptedPrivacyPolicy)}
                        />
                      }
                      label={
                        <>
                          {/* avoid reformatting here, as translation might not work after changing line breaks */}
                          {/* prettier-ignore */}
                          <Trans i18nKey="password_reset.accept_privacy_policy">
                            Ich habe die <a className={classes.link} href={t("privacy_policy_link")} target="_blank">Datenschutzerklärung</a> und die <a className={classes.link} href={t("terms_and_conditions_link")} target="_blank">allgemeinen Geschäftsbedingungen</a> gelesen und akzeptiert."
                          </Trans>
                        </>
                      }
                    />
                  </Grid>
                )}
                <Grid item xs={12} container justify={"center"}>
                  <Button
                    color={"primary"}
                    variant={"contained"}
                    className={classes.control}
                    disabled={!isValid || changePasswordMutationLoading}
                    onClick={onPasswordReset}
                    endIcon={
                      changePasswordMutationLoading && (
                        <CircularProgress size={20} className={classes.loadingIndicator} />
                      )
                    }
                    type={"submit"}
                  >
                    {t("password_reset.set_password")}
                  </Button>
                </Grid>
                <Grid item xs={12} container spacing={1} className={classes.buttonSection}>
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography className={classes.infoTypo}>
                      {t("password_reset.back_to")}{" "}
                      <a className={classes.link} href={"/login"}>
                        {t("password_reset.login")}
                      </a>
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </form>
          </Card>
        </Grid>
      </Grid>
    </div>
  )
}
