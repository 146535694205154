import React, { FunctionComponent, useState, useCallback, useContext, useEffect } from "react"
import { Edit, Close, Done } from "@material-ui/icons"
import { useMutation } from "react-apollo"
import { useTranslation } from "react-i18next"
import {
  MUTATION as UPDATE_REPORT_MUTATION,
  UpdateReportMutationResult,
  UpdateReportMutationVariables,
} from "../../../api/graphql/mutation/update-report"
import { IconButton, Grid, Tooltip, TextField, Divider, makeStyles, Theme } from "@material-ui/core"
import Typography from "@material-ui/core/Typography"
import { OverallRatingHeading } from "./OverallRatingHeading"
import { reportDetail_report } from "../../../api/graphql/generated/reportDetail"
import { CommunicationCenterContext } from "../communication-center/CommunicationCenterContext"
import { CommunicationHistory } from "./CommunicationHistory"
import { ReportCommunicationHistoryEntry } from "../../../api/graphql/query/reports"
import lodash from "lodash"
import { FilterHeading } from "./FilterHeading"

const useStyles = makeStyles((theme: Theme) => ({
  heading: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    marginBottom: theme.spacing(3),
    marginTop: theme.spacing(3),
  },
  clickableIcon: {
    cursor: "pointer",
  },
  arrowBack: {
    marginTop: -theme.spacing(2),
  },
  headingSection: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  headingSectionCommunicationHistory: {
    marginTop: theme.spacing(1),
    padding: theme.spacing(1),
  },
}))

interface IReportHeadingProps {
  report: reportDetail_report
  refetchReports: () => void
}

export const ReportHeading: FunctionComponent<IReportHeadingProps> = (props) => {
  const { report } = props
  const [toggleEditReportTitle, setToggleEditReportTitle] = useState<boolean>(false)
  const [reportTitle, setReportTitle] = useState<string>(report.title)
  const classes = useStyles()
  const { t } = useTranslation()
  const { fetchReports } = useContext(CommunicationCenterContext)

  const [updateReportMutation, { loading: updateReportLoading }] = useMutation<
    UpdateReportMutationResult,
    UpdateReportMutationVariables
  >(UPDATE_REPORT_MUTATION, {
    onCompleted: () => {
      fetchReports()
    },
    onError: (error) => console.log("error: ", error),
  })

  const updateReport = useCallback(() => {
    updateReportMutation({
      variables: {
        id: report.id,
        title: reportTitle,
      },
    })
    setToggleEditReportTitle(false)
  }, [reportTitle, report, updateReportMutation, setToggleEditReportTitle])

  return (
    <Grid container className={classes.heading} direction="row" justify="space-between" alignItems="baseline">
      <Grid item xs>
        <Grid container direction={"column"}>
          <Grid item>
            {!toggleEditReportTitle ? (
              <Grid container justify={"space-between"} alignItems={"center"}>
                <Grid item>
                  <Typography variant={"h4"}>{reportTitle}</Typography>
                </Grid>
                <Grid item>
                  <Tooltip title={t("general.edit")} aria-label="Bearbeiten">
                    <IconButton
                      aria-label="Bearbeiten"
                      onClick={() => {
                        setToggleEditReportTitle(true)
                      }}
                      className={classes.clickableIcon}
                    >
                      <Edit />
                    </IconButton>
                  </Tooltip>
                </Grid>
              </Grid>
            ) : (
              <Grid container direction={"row"} justify={"space-between"} alignItems={"center"}>
                <Grid item>
                  <Typography variant={"h4"}>
                    <TextField
                      fullWidth
                      label={t("report.title")}
                      value={reportTitle}
                      onChange={(event) => {
                        setReportTitle(event.target.value)
                      }}
                    />
                  </Typography>
                </Grid>
                <Grid item>
                  <Grid container>
                    <Grid item>
                      <Tooltip title={t("general.cancel")} aria-label="Abbrechen">
                        <IconButton
                          aria-label="Abbrechen"
                          onClick={() => {
                            setReportTitle(report.title)
                          }}
                        >
                          <Close />
                        </IconButton>
                      </Tooltip>
                    </Grid>
                    <Grid item>
                      <Tooltip title={t("general.confirm")} aria-label="Bestätigen">
                        <IconButton aria-label={"Bestätigen"} onClick={updateReport}>
                          <Done />
                        </IconButton>
                      </Tooltip>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            )}
          </Grid>
          <Grid item className={classes.headingSection}>
            <FilterHeading filter={report.filter} />
          </Grid>
          <Divider />
          <Grid item className={classes.headingSection}>
            {report.filter && <OverallRatingHeading filter={report.filter} />}
          </Grid>
          <Divider />
          <Grid item className={classes.headingSectionCommunicationHistory}>
            {report.communicationHistory && <CommunicationHistory history={report.communicationHistory} />}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}
