import React from "react"
import { makeStyles, Theme } from "@material-ui/core"
import { ExpandLess, ExpandMore } from "@material-ui/icons"
import ReactTable, { TableProps, Column, CellInfo, DerivedDataObject, SortFunction } from "react-table"
import "../../styles/default-react-table.css"
import { useTranslation } from "react-i18next"
import lodash from "lodash"
import moment from "moment"

const useStyles = makeStyles((theme: Theme) => ({
  table: {
    margin: theme.spacing(0),
  },
}))

export const renderHeader = (cellInfo: CellInfo, column: any, title: string) => {
  return (
    <div className={"icon-header"}>
      <span> {title} </span>
      <span className={"icon-up"}>
        <ExpandLess />
      </span>
      <span className={"icon-down"}>
        <ExpandMore />
      </span>
    </div>
  )
}

export const createColumns = (columns: Column[]): Column[] => {
  return columns.map((column) => ({
    ...column,
    Header: (cellInfo: CellInfo, col: any) =>
      renderHeader(cellInfo, col, typeof column.Header === "string" ? column.Header : ""),
  }))
}

interface IDefaultTableProps extends TableProps {}

export const DefaultTable: React.FunctionComponent<Partial<IDefaultTableProps>> = (props) => {
  const { ...rest } = props
  const classes = useStyles()
  const { t } = useTranslation()
  return (
    <ReactTable
      className={classes.table}
      noDataText={t("table.no_data")}
      previousText={t("table.previous")}
      nextText={t("table.next")}
      pageText={t("table.page")}
      rowsText={t("table.rows")}
      ofText={t("table.of")}
      loadingText={t("general.data_loading")}
      defaultPageSize={props.minRows}
      {...rest}
    />
  )
}

export enum EColumnHeaderType {
  DATE,
  TIME,
  DATETIME,
  STRING,
  INT,
  NUMBER1DIGIT,
  NUMBER2DIGIT,
  ARRAY_COUNT,
  CUSTOM,
}

export interface IColumnHeader {
  title: string
  type: EColumnHeaderType
  accessor: string
  customTitle?: ((value: any, row: DerivedDataObject) => string | JSX.Element) | undefined
  compact?: boolean
  width?: number
  sortMethod?: SortFunction | undefined
}

const Cell = (props: any) => {
  return (
    <div
      style={{
        overflow: "hidden",
        textOverflow: "ellipsis",
      }}
    >
      {props.value}
    </div>
  )
}

export function cellForRow(column: IColumnHeader, row: DerivedDataObject) {
  const value = lodash.get(row, column.accessor)

  switch (column.type) {
    case EColumnHeaderType.INT:
      return <Cell value={Number(value).toFixed(0)} />
    case EColumnHeaderType.NUMBER1DIGIT:
      return <Cell value={Number(value).toFixed(1)} />
    case EColumnHeaderType.NUMBER2DIGIT:
      return <Cell value={Number(value).toFixed(2)} />
    case EColumnHeaderType.ARRAY_COUNT:
      return <Cell value={(lodash.isArray(value) ? value.length : -1).toString()} />
    case EColumnHeaderType.TIME:
      return <Cell value={moment(value).format("HH:mm")} />
    case EColumnHeaderType.DATE:
      return <Cell value={moment(value).format("ll")} />
    case EColumnHeaderType.DATETIME:
      return <Cell value={moment(value).format("lll")} />
    case EColumnHeaderType.STRING:
      return <Cell value={value} />
    case EColumnHeaderType.CUSTOM:
      return <Cell value={lodash.isNil(column.customTitle) ? "--" : column.customTitle(value, row)} />
  }
}
