import React, { FunctionComponent } from "react"
import {
  Theme,
  makeStyles,
  FormControl,
  InputLabel,
  Select,
  MenuItem
} from "@material-ui/core"
import { useTranslation } from "react-i18next"
import classnames from "classnames"

const useStyles = makeStyles((theme: Theme) => ({
  formControl: {
    width: "100%",
    justifyContent: "center"
  }
}))

export interface Item {
  key: string
  value: string
}

interface IFilterDropDownProps {
  selectedItem: string | null
  onChange: Function
  items: Item[]
  keyPrefix: string
  noSelectionEnabled?: boolean
  title: string
  style?: any
}

export const FilterDropDown: FunctionComponent<
  IFilterDropDownProps
> = props => {
  const classes = useStyles(props)
  const { t } = useTranslation()
  const {
    selectedItem,
    onChange,
    items,
    keyPrefix,
    noSelectionEnabled,
    title
  } = props

  return (
    <FormControl
      className={`${props.style} ${classnames(classes.formControl)}`}
    >
      <InputLabel id="filter_dropdown_label_id">{title}</InputLabel>
      <Select
        labelId="filter_dropdown_label_id"
        value={selectedItem}
        onChange={event => {
          onChange(event.target.value)
        }}
        fullWidth
      >
        {noSelectionEnabled && (
          <MenuItem key={`${keyPrefix || "key"}all`} value={"all"}>
            {t("general.all")}
          </MenuItem>
        )}

        {items.map((item, index) => (
          <MenuItem key={`${keyPrefix || "key"}${index}`} value={item.key}>
            {t(item.value)}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}
