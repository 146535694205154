import React, { FunctionComponent } from "react"
import { makeStyles, Grid, Typography } from "@material-ui/core"
import { LanguageChanger } from "./LanguageChanger"
import { UserInfo } from "./UserInfo"
import { LogoutButton } from "./LogoutButton"
import { useTranslation } from "react-i18next"

const useStyles = makeStyles((theme) => ({
  grid: {
    padding: 10,
    width: "90%",
  },
  link: {
    color: theme.palette.text.secondary,
  },
  termsAndConditionsLink: {
    marginBottom: theme.spacing(2)
  }
}))

interface ISideBarUserSettingsProps {}

export const SideBarUserSettings: FunctionComponent<ISideBarUserSettingsProps> = (props) => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <Grid container justify={"space-between"} alignContent={"center"} alignItems={"center"} className={classes.grid}>
      <Grid item xs={12}>
        <a href={t("privacy_policy_link")} className={classes.link} target="_blank" rel="noopener noreferrer">
          <Typography align="center" color="textSecondary" variant="caption">
            {t("login_page.data_privacy_statement")}
          </Typography>
        </a>
      </Grid>
      <Grid item xs={12} className={classes.termsAndConditionsLink}>
        <a href={t("terms_and_conditions_link")} className={classes.link} target="_blank" rel="noopener noreferrer">
          <Typography align="center" color="textSecondary" variant="caption">
            {t("login_page.terms_and_conditions")}
          </Typography>
        </a>
      </Grid>
      <Grid item xs={12}>
        <LanguageChanger />
      </Grid>
      <Grid item xs={3}>
        <LogoutButton />
      </Grid>
      <Grid item xs={9}>
        <UserInfo />
      </Grid>
    </Grid>
  )
}
