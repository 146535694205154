import React, { FunctionComponent, Fragment, ChangeEvent, useMemo, useCallback } from "react"
import { Theme, makeStyles, Grid, Tooltip, Switch, Divider, FormControlLabel, Checkbox } from "@material-ui/core"
import { useTranslation } from "react-i18next"
import { classifiers_classifiers_classes_translations } from "../../../api/graphql/generated/classifiers"
import {
  FractionMappingInput,
  LabelSetMaterialType,
  MappedFractionInput,
} from "../../../api/graphql/generated/globalTypes"
import { COLORS } from "../../../theme/theme"
import { v4 } from "uuid"

const useStyles = makeStyles((theme: Theme) => ({
  item: {
    padding: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(1),
  },
  itemLeft: {
    borderRightWidth: 1,
    borderTopWidth: 0,
    borderLeftWidth: 0,
    borderBottomWidth: 0,
    borderColor: COLORS.disabled,
    border: "solid",
  },
  divider: {
    backgroundColor: COLORS.disabled,
    height: 1,
  },
  checkboxContainer: {
    padding: theme.spacing(1.5),
  },
}))

interface IFractionMappingTabProps {
  fractions?: string[]
  fractionTranslations?: classifiers_classifiers_classes_translations[]
  translation: string
  fractionMap?: FractionMappingInput
  onChange: (mappedFraction: MappedFractionInput) => void
  disabled: boolean
  toggleCustomFractionMapping: (event: React.ChangeEvent<HTMLInputElement>) => void
}

export const FractionMappingTab: FunctionComponent<IFractionMappingTabProps> = (props) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const {
    fractions,
    translation,
    fractionTranslations,
    onChange,
    fractionMap,
    disabled,
    toggleCustomFractionMapping,
  } = props

  const key = useMemo(() => v4(), [])

  const onChangeFraction = (event: ChangeEvent<HTMLInputElement>, fraction: string) =>
    onChange({
      fraction,
      mappedMaterialType: event.target.checked ? LabelSetMaterialType.main_fraction : undefined,
    })

  const isChecked = useCallback(
    (fraction: string) =>
      (fractionMap?.mappedFractions || []).find((mappedFraction) => mappedFraction.fraction === fraction)
        ?.mappedMaterialType === LabelSetMaterialType.main_fraction,
    [fractionMap],
  )

  const translateFraction = useCallback(
    (fraction: string) => {
      const fractionTranslation = (fractionTranslations || []).find(
        (fractionTranslation) => fractionTranslation.en === fraction,
      )

      return fractionTranslation ? fractionTranslation[translation] : fraction
    },
    [fractionTranslations, translation],
  )

  return (
    <Fragment>
      <Grid container>
        <Grid item xs={12} className={classes.checkboxContainer}>
          <FormControlLabel
            control={<Checkbox color="primary" checked={!disabled} onChange={toggleCustomFractionMapping} />}
            label={t("fraction_assignment.use_custom_fraction_mapping")}
          />
        </Grid>
        <Grid item xs={12}>
          <Divider className={classes.divider} />
        </Grid>
        {(fractions || []).map((fraction, index) => (
          <Fragment key={`${key}-${fraction}`}>
            <Grid item xs={6} className={index % 2 === 0 ? `${classes.item} ${classes.itemLeft}` : classes.item}>
              <Grid container alignItems="center" justify="space-between">
                <Grid item>{translateFraction(fraction)}</Grid>
                <Grid item>
                  <Tooltip title={t("fraction_assignment.main_fraction")}>
                    <Switch
                      color="primary"
                      onChange={(event) => onChangeFraction(event, fraction)}
                      checked={isChecked(fraction)}
                      disabled={disabled}
                    />
                  </Tooltip>
                </Grid>
              </Grid>
            </Grid>
            {index % 2 !== 0 && index + 1 < fractions.length && (
              <Grid item xs={12}>
                <Divider className={classes.divider} />
              </Grid>
            )}
          </Fragment>
        ))}
      </Grid>
    </Fragment>
  )
}
