import React, { FunctionComponent } from "react"
import { Theme, makeStyles, Grid, Typography, TextField, Fade, Fab } from "@material-ui/core"
import { useTranslation } from "react-i18next"
import { KeyboardArrowDown, Search } from "@material-ui/icons"

interface IStyleProps {
  open: boolean
}

const useStyles = makeStyles((theme: Theme) => ({
  icon: {
    transform: (props: IStyleProps) => `rotate(${props.open ? "180deg" : "0deg"})`,
    transition: "200ms",
    marginRight: theme.spacing(1),
  },
  header: {
    cursor: "pointer",
  },
  searchBar: {},
}))

interface IAnalysisPictureSectionHeaderProps {
  toggleOpen: () => void
  open: boolean
  title: string
  searchString: string
  onChangeSeachString: (searchString: string) => void
  search: () => void
}

export const AnalysisPictureSectionHeader: FunctionComponent<IAnalysisPictureSectionHeaderProps> = (props) => {
  const { toggleOpen, open, title, searchString, onChangeSeachString, search } = props
  const classes = useStyles({ open })
  const { t } = useTranslation()

  return (
    <Grid item className={classes.header} onClick={open ? undefined : toggleOpen}>
      <Grid container justify="space-between" alignItems="center" alignContent="center">
        <Grid item onClick={open ? toggleOpen : undefined}>
          <KeyboardArrowDown fontSize="large" className={classes.icon} />
        </Grid>
        <Grid item onClick={open ? toggleOpen : undefined} xs>
          <Typography variant={"h5"}>{title}</Typography>
        </Grid>
        <Fade in={open}>
          <Grid item>
            <Grid container spacing={2}>
              <Grid item>
                <TextField
                  placeholder={t("general.search")}
                  value={searchString}
                  onChange={(event) => onChangeSeachString(event.target.value)}
                />
              </Grid>
              <Grid item>
                <Fab color="primary" size="small" onClick={search} disabled={!searchString}>
                  <Search />
                </Fab>
              </Grid>
            </Grid>
          </Grid>
        </Fade>
      </Grid>
    </Grid>
  )
}
