import React, { useCallback } from "react"
import { useTranslation } from "react-i18next"
import { Grid, makeStyles, Theme, Typography, List, Divider } from "@material-ui/core"
import { RfidGroupRfidCollectionItem } from "./RfidGroupRfidCollectionItem"
import { RfidGroupRfidCollectionNewItem } from "./RfidGroupRfidCollectionNewItem"

const useStyles = makeStyles((theme: Theme) => ({
  rfidContainer: {
    minHeight: 160,
    marginTop: theme.spacing(2),
    border: "1px solid",
    borderColor: theme.palette.grey[500],
    padding: theme.spacing(2),
  },
  rfidList: {
    flexGrow: 1,
  },
}))

export interface IRfidGroupRfidCollection {
  setRfids: (rfids: string[]) => void
  rfids: string[]
  isValidGroupAddress: Boolean | undefined
}

export const RfidGroupRfidCollection: React.FunctionComponent<IRfidGroupRfidCollection> = (props) => {
  const { rfids, setRfids, isValidGroupAddress } = props
  const classes = useStyles()
  const { t } = useTranslation()

  const removeRfid = useCallback(
    (rfid: string) => {
      const newRfids = rfids.filter((item) => item !== rfid)
      setRfids(newRfids)
    },
    [rfids, setRfids],
  )

  const addRfidToGroup = useCallback(
    (rfid: string) => {
      const newRfids = [...rfids, rfid]
      setRfids(newRfids)
    },
    [rfids, setRfids],
  )

  if (isValidGroupAddress === undefined)
    return (
      <Grid item xs={12} className={classes.rfidContainer}>
        <Typography>{t("rfid_groups.dialog.create.undefined_address_info")}</Typography>
      </Grid>
    )

  if (isValidGroupAddress === false)
    return (
      <Grid item xs={12} className={classes.rfidContainer}>
        <Typography>{t("rfid_groups.dialog.create.invalid_address_info")}</Typography>
      </Grid>
    )

  return (
    <Grid container item className={classes.rfidContainer}>
      <Grid item xs={12}>
        <Typography>{t("rfid_groups.dialog.rfid_collection_title")}</Typography>
      </Grid>
      <Grid item xs={12}>
        <List dense={false} className={classes.rfidList}>
          {rfids.length > 0 &&
            rfids.map((rfid) => {
              return <RfidGroupRfidCollectionItem rfid={rfid} removeRfid={removeRfid} key={rfid} />
            })}
          <Divider />
          <RfidGroupRfidCollectionNewItem addRfidToGroup={addRfidToGroup} existingRfids={rfids} />
        </List>
      </Grid>
    </Grid>
  )
}
