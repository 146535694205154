// validateTours
import { gql } from "apollo-boost"
import {
  importRfidChips,
  importRfidChipsVariables,
  importRfidChips_importRfidChips,
  importRfidChips_importRfidChips_errors,
} from "../generated/importRfidChips"

export const IMPORT_RFID_CHIPS_MUTATION = gql`
  mutation importRfidChips($townId: ID!, $file: Upload!, $ignoreWarnings: Boolean!) {
    importRfidChips(townId: $townId, file: $file, ignoreWarnings: $ignoreWarnings) {
      importDone
      errors {
        id
        type
        line
        additionalData
      }
      warnings {
        id
        type
        line
        additionalData
      }
    }
  }
`

export type ImportRfidChipsVariables = importRfidChipsVariables
export type ImportRfidChipsResult = importRfidChips
export type ImportRfidChipsValidationResult = importRfidChips_importRfidChips
export type ImportRfidChipsError = importRfidChips_importRfidChips_errors
