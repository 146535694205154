import React, { FunctionComponent, Fragment, useContext } from "react"
import { Theme, makeStyles, Grid, List, TextField, Typography } from "@material-ui/core"
import { useTranslation } from "react-i18next"
import { useQuery } from "react-apollo"
import { Vehicle, VehiclesResult, VEHICLES_QUERY } from "../../../../api/graphql/query/vehicles"
import { ManageUserContext } from "./context/ManageUserContext"
import lodash from "lodash"
import { Autocomplete } from "@material-ui/lab"
import { ClipLoader } from "react-spinners"
import { VehicleListItem } from "./VehicleListItem"

const useStyles = makeStyles((theme: Theme) => ({}))

interface IVehicleSelectionProps {}

export const VehicleSelection: FunctionComponent<IVehicleSelectionProps> = (props) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const { data, loading } = useQuery<VehiclesResult>(VEHICLES_QUERY)

  const { vehicles, setVehicles } = useContext(ManageUserContext)

  const allVehicles = lodash.get(data, "me.vehicles", []) as Vehicle[]

  const vehicleOptions = allVehicles.filter((option) => !vehicles.map((a) => a.id).includes(option.id))

  const onAddVehicle = (newValue: Vehicle[]) => {
    setVehicles([...vehicles, newValue[newValue.length - 1]])
  }

  const onRemoveVehcile = (removed: Vehicle) => {
    const newVehciles = [...vehicles]
    newVehciles.splice(vehicles.indexOf(removed), 1)
    setVehicles(newVehciles)
  }

  return (
    <Fragment>
      <Grid item xs={12}>
        <Autocomplete
          multiple
          options={vehicleOptions}
          value={vehicles}
          getOptionLabel={(option) => option.wasteScannerId}
          onChange={(event, newValue) => onAddVehicle(newValue)}
          loading={loading}
          loadingText={t("general.data_loading")}
          disableClearable
          renderTags={() => null}
          renderInput={(params) => (
            <TextField
              name="abilities"
              {...params}
              variant="standard"
              label={t("user_management.vehicles")}
              margin="normal"
              fullWidth
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <React.Fragment>
                    {loading ? <ClipLoader color="inherit" size={15} /> : null}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                ),
              }}
            />
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <List>
          {vehicles.map((vehicle, index) => (
            <VehicleListItem vehicle={vehicle} onRemove={onRemoveVehcile} key={vehicle.wasteScannerId} />
          ))}
        </List>
      </Grid>
    </Fragment>
  )
}
