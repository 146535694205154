import { gql } from "apollo-boost"
import { createTours, createToursVariables } from "../generated/createTours"

export const CREATE_TOURS_MUTATION = gql`
  mutation createTours($tours: [TourInput!]!) {
    createTours(tours: $tours) {
      towns {
        name
      }
      vehicle {
        id
        wasteScannerId
      }
      tourEnd
      tourStart
      tourType
      id
    }
  }
`

export type CreateToursResult = createTours
export type CreateToursVariables = createToursVariables
