import React, { FunctionComponent, Fragment, ReactNode } from "react"
import { Theme, makeStyles, Grid, Typography } from "@material-ui/core"
import { useTranslation } from "react-i18next"
import { KeyboardArrowDown } from "@material-ui/icons"

interface IStyleProps {
  open: boolean
}

const useStyles = makeStyles((theme: Theme) => ({
  icon: {
    transform: (props: IStyleProps) => `rotate(${props.open ? "180deg" : "0deg"})`,
    transition: "200ms",
    marginRight: theme.spacing(1),
  },
}))

interface IPaperHeaderProps {
  open: boolean
  onClick: () => void
  additionalHeader?: ReactNode
  showAdditionalHeader: boolean
}

export const PaperHeader: FunctionComponent<IPaperHeaderProps> = (props) => {
  const { additionalHeader, showAdditionalHeader, children, onClick, open } = props
  const classes = useStyles({ open })
  const { t } = useTranslation()
  return (
    <Grid container item xs={12} onClick={onClick}>
      <Grid item>
        <KeyboardArrowDown fontSize="large" className={classes.icon} />
      </Grid>
      <Grid container item xs>
        {children}
        {open && showAdditionalHeader && additionalHeader}
      </Grid>
    </Grid>
  )
}
