import { gql } from "apollo-boost"
import {
  updateLanguage,
  updateLanguageVariables
} from "../generated/updateLanguage"

export const UPDATE_LANGUAGE_MUTATION = gql`
  mutation updateLanguage($language: String!) {
    updateLanguage(language: $language) {
      error
      message
      errorCode
    }
  }
`

export type UpdateLanguageResult = updateLanguage
export type UpdateLanguageVariables = updateLanguageVariables
