import React, { FunctionComponent, useState } from "react"
import {
  Dialog,
  DialogContent,
  DialogTitle,
  TextField,
  DialogActions,
  Button
} from "@material-ui/core"
import { useTranslation } from "react-i18next"

interface ICreateBookmarkDialogProps {
  open: boolean
  onClose: () => void
  onCreate: (name: string) => void
}

export const CreateBookmarkDialog: FunctionComponent<
  ICreateBookmarkDialogProps
> = props => {
  const { t } = useTranslation()
  const { open, onClose, onCreate } = props

  const [name, setName] = useState<string>("")

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth={"xs"}>
      <DialogTitle>{t("bookmark.create_bookmark")}</DialogTitle>
      <DialogContent>
        <TextField
          label={t("general.name")}
          fullWidth
          value={name}
          onChange={event => setName(event.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onClose()} color={"primary"}>
          {t("general.cancel")}
        </Button>
        <Button
          onClick={() => onCreate(name)}
          disabled={name.length < 4}
          color={"primary"}
        >
          {t("general.save")}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
