import React, { FunctionComponent, useMemo, useState } from "react"
import { Theme, makeStyles, Grid, Tooltip, Fab } from "@material-ui/core"
import { Subject } from "@material-ui/icons"
import { useTranslation } from "react-i18next"
import { LogEntry } from "../../../api/graphql/query/logs"
import { cellForRow, createColumns, DefaultTable, EColumnHeaderType, IColumnHeader } from "../table/DefaultTable"
import { Column, DerivedDataObject } from "react-table"
import { LogType } from "../../../api/graphql/generated/globalTypes"
import { Email, PhoneIphone } from "@material-ui/icons"
import { logs_logs_payload_citizen } from "../../../api/graphql/generated/logs"
import { sortASC } from "../../../utils/sort"
import { LogMessageDialog } from "./LogMessageDialog"

const useStyles = makeStyles((theme: Theme) => ({}))

interface ILogTableProps {
  logEntries: LogEntry[]
}

export const LogTable: FunctionComponent<ILogTableProps> = (props) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { logEntries } = props
  const [activeMessage, setActiveMessage] = useState<string>(null)
  const [messageDialogOpen, setMessageDialogOpen] = useState<boolean>(false)

  const columnHeaders: IColumnHeader[] = useMemo(
    () => [
      {
        title: "",
        type: EColumnHeaderType.CUSTOM,
        customTitle: (type: LogType) => (type === LogType.sms ? <PhoneIphone /> : <Email />),
        accessor: "type",
        width: 50,
      },
      {
        title: t("log.property.time"),
        type: EColumnHeaderType.DATETIME,
        accessor: "time",
        width: 180,
      },
      {
        title: t("log.property.userEmail"),
        type: EColumnHeaderType.STRING,
        accessor: "userEmail",
        width: 200,
      },
      {
        title: t("log.property.receiver"),
        type: EColumnHeaderType.CUSTOM,
        accessor: "payload.communicationChannelReceiver",
        customTitle: (channelReceiver?: string[]) => (channelReceiver ? channelReceiver.join(", ") : ""),
        width: 200,
      },
      {
        title: t("log.property.citizen"),
        type: EColumnHeaderType.CUSTOM,
        accessor: "payload.citizen",
        customTitle: (citizen?: logs_logs_payload_citizen) =>
          citizen ? `${citizen.firstName} ${citizen.lastName}` : "",
        width: 200,
      },
      {
        title: t("log.property.message"),
        type: EColumnHeaderType.STRING,
        accessor: "payload.message",
      },
    ],
    [t],
  )

  const columns: Column[] = useMemo(
    () =>
      createColumns(
        columnHeaders
          .map((column) => {
            return {
              width: column.width,
              Header: column.title,
              accessor: column.accessor,
              Cell: ({ row }: { row: DerivedDataObject }) => cellForRow(column, row),
            }
          })
          .concat({
            width: 100,
            Header: "",
            accessor: "",
            Cell: ({ row }: { row: DerivedDataObject }) => (
              <>
                {row._original?.payload?.message && (
                  <Grid container item direction="row" spacing={2} justify="flex-end">
                    <Grid item>
                      <Tooltip title={t("log.show_message")}>
                        <Fab
                          size="small"
                          color="primary"
                          onClick={() => {
                            setMessageDialogOpen(true)
                            setActiveMessage(row._original.payload.message)
                          }}
                        >
                          <Subject />
                        </Fab>
                      </Tooltip>
                    </Grid>
                  </Grid>
                )}
              </>
            ),
          }),
      ),
    [columnHeaders],
  )

  const sorted = useMemo(() => logEntries.sort((a, b) => sortASC(b.time, a.time)), [logEntries])

  return (
    <>
      <LogMessageDialog
        open={messageDialogOpen}
        onClose={() => setMessageDialogOpen(false)}
        message={activeMessage}
        onExited={() => setActiveMessage(null)}
      />
      <DefaultTable columns={columns} data={sorted} minRows={1} defaultPageSize={10} />
    </>
  )
}
