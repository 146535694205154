import { gql } from "apollo-boost"
import { validateCommunication, validateCommunicationVariables } from "../generated/validateCommunication"

export const VALIDATE_COMMUNICATION_QUERY = gql`
  query validateCommunication($widgetId: ID!, $channelType: CommunicationChannelType!, $useRfidGroups: Boolean) {
    validateCommunication(widgetId: $widgetId, channelType: $channelType, useRfidGroups: $useRfidGroups) {
      totalReceivers
      ratingLowReceivers
      ratingMediumReceivers
      ratingHighReceivers
      ratingUnknownReceivers
      sentToReceivers
      errorCount
      errorCode
      errorMessage
    }
  }
`

export type ValidateCommunicationResult = validateCommunication
export type ValidateCommunicationVariables = validateCommunicationVariables
