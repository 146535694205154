import { gql } from "apollo-boost"
import { StatisticsExcelExport } from "../generated/StatisticsExcelExport"

export const STATISTICS_EXCEL_QUERY = gql`
  query StatisticsExcelExport($filter: StatisticsFilter!, $frequency: Frequency) {
    statisticsExcelExport(filter: $filter, frequency: $frequency) {
      base64
    }
  }
`

export type StatisticsExcelExportResult = StatisticsExcelExport
