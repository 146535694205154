import React, { FunctionComponent } from "react"
import {
  Theme,
  makeStyles,
  ListItem,
  List,
  ListSubheader
} from "@material-ui/core"
import {
  AssociationWithBookmarks,
  Bookmark
} from "../../../../api/graphql/query/bookmarks-for-user"
import { BookmarkListItem } from "./BookmarkListItem"
import lodash from "lodash"

const useStyles = makeStyles((theme: Theme) => ({
  list: {
    width: "100%",
    color: theme.palette.secondary.dark
  },
  subList: {
    paddingTop: theme.spacing(1),
    paddingRight: 0,
    width: "100%"
  },
  subheading: {
    color: theme.palette.secondary.main,
    fontWeight: "normal",
    fontSize: 15,
    lineHeight: "15px",
    paddingBottom: theme.spacing(1)
  },
  listItem: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1)
  },
  subListListItem: {
    paddingTop: theme.spacing(1),
    paddingRight: 0,
    paddingBottom: theme.spacing(1)
  }
}))

interface IBookmarkForAssociationListProps {
  association: AssociationWithBookmarks
  onSelectBookmark: (bookmark: Bookmark) => void
  selectedBookmark: Bookmark | null
}

export const BookmarkForAssociationList: FunctionComponent<
  IBookmarkForAssociationListProps
> = props => {
  const classes = useStyles()
  const { association, onSelectBookmark, selectedBookmark } = props

  return (
    <ListItem key={association.id} className={classes.listItem}>
      <List
        disablePadding
        className={classes.list}
        subheader={
          <ListSubheader disableSticky className={classes.subheading}>
            {association.name}
          </ListSubheader>
        }
      >
        {association.filters.map(filter => (
          <BookmarkListItem
            key={filter.id}
            filter={filter}
            selected={filter.id === lodash.get(selectedBookmark, "id")}
            onClick={bookmark => onSelectBookmark(bookmark)}
          />
        ))}
        {association.towns.map(town => (
          <ListItem key={town.id} className={classes.subListListItem}>
            <List
              disablePadding
              className={classes.subList}
              subheader={
                <ListSubheader className={classes.subheading} disableSticky>
                  {town.name}
                </ListSubheader>
              }
            >
              {town.filters.map(filter => (
                <BookmarkListItem
                  key={filter.id}
                  filter={filter}
                  selected={filter.id === lodash.get(selectedBookmark, "id")}
                  onClick={bookmark => onSelectBookmark(bookmark)}
                />
              ))}
            </List>
          </ListItem>
        ))}
      </List>
    </ListItem>
  )
}
