import React, { FunctionComponent, useCallback, useEffect, useState } from "react"
import {
  Theme,
  makeStyles,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Grid,
  Checkbox,
  FormControlLabel,
} from "@material-ui/core"
import { useTranslation } from "react-i18next"
import { FractionMappingInput, LabelSetMaterialType } from "../../../api/graphql/generated/globalTypes"
import { FractionMappingDialogTabs } from "./FractionMappingDialogTabs"
import lodash from "lodash"

const useStyles = makeStyles((theme: Theme) => ({
  item: {
    padding: theme.spacing(0.5),
    marginTop: theme.spacing(0.5),
  },
  tabs: {
    color: theme.palette.secondary.dark,
    background: theme.palette.secondary.light,
    borderTopLeftRadius: theme.spacing(1),
    borderTopRightRadius: theme.spacing(1),
  },
  tabPanel: {
    background: theme.palette.secondary.light,
    borderBottomLeftRadius: theme.spacing(1),
    borderBottomRightRadius: theme.spacing(1),
  },
}))

export interface IBaseFractionMappingDialogProps {
  open: boolean
  onClose: () => void
  onChange: (fractionMapping: FractionMappingInput[]) => void
  fractionMapping: FractionMappingInput[]
}

export interface IFractionMappingDialogProps extends IBaseFractionMappingDialogProps {
  defaultFractionMapping: FractionMappingInput[]
  defaultFractionMappingLoading: boolean
}

export const FractionMappingDialog: FunctionComponent<IFractionMappingDialogProps> = (props) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const { open, onClose, onChange, fractionMapping, defaultFractionMapping, defaultFractionMappingLoading } = props

  return (
    <Dialog onClose={onClose} open={open}>
      <DialogTitle>{t("fraction_assignment.assign_fractions")}</DialogTitle>
      <DialogContent>
        <Grid container>
          {open && (
            <FractionMappingDialogTabs
              defaultFractionMapping={defaultFractionMapping}
              defaultFractionMappingLoading={defaultFractionMappingLoading}
              onChange={onChange}
              fractionMapping={fractionMapping}
            />
          )}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button color="primary" variant="contained" onClick={onClose}>
          {t("general.close")}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
