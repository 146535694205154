import React from "react"
import { IconButton, Grid, Typography, makeStyles } from "@material-ui/core"
import { Delete } from "@material-ui/icons"

const useStyles = makeStyles(theme => ({
  rfidContainer: {
    border: `1px solid ${theme.palette.grey[300]}`,
    borderRadius: 8,
    padding: theme.spacing(1)
  },

}))

export interface IHousingManagementRfidCollectionItemProps {
  rfid: string
  removeRfid: (rfid: string) => void
}

export const HousingManagementRfidCollectionItem: React.FunctionComponent<IHousingManagementRfidCollectionItemProps> = (
  props,
) => {
  const { rfid, removeRfid } = props
  const classes = useStyles();

  return (
    <Grid item key={rfid}>
      <Grid container justify="space-between" alignItems="center" className={classes.rfidContainer}>
        <Grid item>
          <Typography>{rfid}</Typography>
        </Grid>
        <Grid item>
          <IconButton edge="end" aria-label="delete" size="small" onClick={() => removeRfid(rfid)}>
            <Delete />
          </IconButton>
        </Grid>
      </Grid>
    </Grid>
  )
}
