import React, { useCallback } from "react"
import { useTranslation } from "react-i18next"
import { Divider, Grid, makeStyles, Theme } from "@material-ui/core"
import { HousingManagementRfidCollectionItem } from "./HousingManagementRfidCollectionItem"
import { HousingManagementRfidCollectionNewItem } from "./HousingManagementRfidCollectionNewItem"
import lodash from "lodash"

const useStyles = makeStyles((theme: Theme) => ({
  rfidContainer: {
    position: "relative",
  },
  newItemContainer: {
    background: theme.palette.background.paper,
  },
  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}))

export interface IHousingManagementRfidCollectionProps {
  setRfids: (rfids: string[]) => void
  rfids: string[]
}

export const HousingManagementRfidCollection: React.FunctionComponent<IHousingManagementRfidCollectionProps> = (
  props,
) => {
  const { rfids, setRfids } = props
  const classes = useStyles()
  const { t } = useTranslation()

  const removeRfid = useCallback(
    (rfid: string) => {
      const newRfids = rfids.filter((item) => item !== rfid)
      setRfids(newRfids)
    },
    [rfids, setRfids],
  )

  const addRfidToGroup = useCallback(
    (rfid: string) => {
      const newRfids = [...rfids, rfid]
      setRfids(newRfids)
    },
    [rfids, setRfids],
  )

  return (
    <Grid container item className={classes.rfidContainer}>
      <Grid item xs={12} className={classes.newItemContainer}>
        <HousingManagementRfidCollectionNewItem addRfidToGroup={addRfidToGroup} existingRfids={rfids} />
      </Grid>
      <Grid item xs={12}>
        <Divider className={classes.divider} />
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={2} justify="flex-start">
          {rfids.length > 0 &&
            lodash
              .orderBy(rfids, (rfid) => rfid)
              .map((rfid) => {
                return <HousingManagementRfidCollectionItem rfid={rfid} removeRfid={removeRfid} key={rfid} />
              })}
        </Grid>
      </Grid>
    </Grid>
  )
}
