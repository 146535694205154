import React, { FunctionComponent, useMemo, useEffect } from "react"
import { useTranslation } from "react-i18next"
import {
  ResponsiveContainer,
  Scatter,
  XAxis,
  YAxis,
  Label,
  CartesianGrid,
  Line,
  Tooltip,
  ComposedChart,
  Legend,
} from "recharts"
import { DailyGroupedStatsForAnalysis } from "../../../api/graphql/query/daily-grouped-stats-for-analysis"
import moment from "moment"
import { TourTypeInfo } from "../../../domain/models/TourTypes"
import { tickFormat, TimeLevel } from "./tick-format"
import lodash from "lodash"
import { WertstoffAnalysisTrendGraphTooltip } from "./WertstoffAnalysisTrendGraphTooltip"
import { WertstoffAnalysisTrendGraphLegend } from "./WertstoffAnalysisTrendGraphLegend"
import { getValuesForTrendLine, OVERALL_DATA_KEY, TREND_DATA_KEY } from "./trend-line"
import { Town } from "../../../api/graphql/query/towns"
import { trimDailyGroupedAnaylsis } from "./trend-graph-helpers"

export interface IWertstoffAnalysisTrendGraphProps {
  dailyGroupedStatsForAnalysis: DailyGroupedStatsForAnalysis[]
  towns?: Town[]
  tourTypeInfo: TourTypeInfo | undefined | null
  chartRef?: any
  onChartIsDrawn?: () => void
  legend?: boolean
}

export const WertstoffAnalysisTrendGraph: FunctionComponent<IWertstoffAnalysisTrendGraphProps> = (props) => {
  const { t } = useTranslation()
  const { dailyGroupedStatsForAnalysis, tourTypeInfo, chartRef, onChartIsDrawn, legend } = props

  useEffect(() => {
    if (!!onChartIsDrawn && dataPointsForTrendLine.length === 1) {
      setTimeout(function () {
        onChartIsDrawn()
      }, 200)
    }
    // eslint-disable-next-line
  }, [])

  //we trim the analysis data, so we dont show useless empty datapoints at the beginning or end of the graphic
  const dailyGroupedStatsForAnalysisTrimmed = useMemo(() => {
    return trimDailyGroupedAnaylsis(dailyGroupedStatsForAnalysis)
  }, [dailyGroupedStatsForAnalysis])

  const dataPointsForTrendLine = useMemo(() => {
    return getValuesForTrendLine(dailyGroupedStatsForAnalysisTrimmed)
  }, [dailyGroupedStatsForAnalysisTrimmed])

  // //we need to create a scatter for each day, so we can join them with a line
  const scatters = useMemo(
    () =>
      dailyGroupedStatsForAnalysisTrimmed.map((dailyGrouped) => (
        <Scatter
          key={dailyGrouped.date}
          dataKey="value"
          data={[
            { date: dailyGrouped.date, value: dailyGrouped.min?.value },
            { date: dailyGrouped.date, value: dailyGrouped.max?.value },
          ]}
          fill={tourTypeInfo?.fraction.color}
          line
          shapeRendering="optimizeSpeed"
          shape="square"
          strokeWidth={2}
          onAnimationEnd={dataPointsForTrendLine.length === 1 && onChartIsDrawn ? onChartIsDrawn : undefined}
        />
      )),
    [dailyGroupedStatsForAnalysisTrimmed, tourTypeInfo, dataPointsForTrendLine, onChartIsDrawn],
  )

  return (
    <ResponsiveContainer width={"95%"} height={250} ref={chartRef}>
      <ComposedChart data={dailyGroupedStatsForAnalysisTrimmed} margin={{ top: 10, right: 50, left: 20, bottom: 5 }}>
        <XAxis
          dataKey="date"
          tickFormatter={(date) => tickFormat(date, TimeLevel.DAY)}
          allowDuplicatedCategory={false}
        />
        <YAxis tickFormatter={(value) => value * 100}>
          <Label
            value={`${t("graph.percent")}`}
            angle={-90}
            offset={0}
            position={"insideLeft"}
            style={{ textAnchor: "middle" }}
          />
        </YAxis>
        <CartesianGrid />
        <Tooltip
          content={(props: any) => (
            <WertstoffAnalysisTrendGraphTooltip
              {...props}
              color={tourTypeInfo?.fraction.color}
              showTrend={dataPointsForTrendLine.length > 1}
            />
          )}
          // className={classes.tooltip}
          labelFormatter={(timeStr) => moment(timeStr).format("DD MMM YYYY")}
          formatter={(value, name, props) => `${(Number(value) * 100).toFixed(0)} %`}
          itemSorter={(a, b) => {
            return lodash.get(a, "value", -1) > lodash.get(b, "value", -1) ? -1 : 1
          }}
        />
        {scatters}
        <Scatter
          dataKey={OVERALL_DATA_KEY}
          data={dailyGroupedStatsForAnalysisTrimmed}
          fill={tourTypeInfo?.fraction.color}
          shapeRendering="optimizeSpeed"
          onAnimationEnd={() => (dataPointsForTrendLine.length === 1 && onChartIsDrawn ? onChartIsDrawn : undefined)}
        />
        {!!legend && (
          <Legend
            layout={"vertical"}
            verticalAlign={"middle"}
            align={"right"}
            iconType={"circle"}
            iconSize={10}
            content={() => (
              <WertstoffAnalysisTrendGraphLegend
                color={tourTypeInfo?.fraction.color}
                showTrend={dataPointsForTrendLine.length > 1}
              />
            )}
            wrapperStyle={{ right: "5px", top: "10px" }}
          />
        )}
        {dataPointsForTrendLine.length > 1 && (
          <Line
            dot={false}
            strokeWidth={2}
            strokeDasharray="8 8"
            dataKey={TREND_DATA_KEY}
            data={dataPointsForTrendLine}
            stroke={tourTypeInfo?.fraction.color}
            onAnimationEnd={onChartIsDrawn && onChartIsDrawn}
          />
        )}
      </ComposedChart>
    </ResponsiveContainer>
  )
}
