import React, { FunctionComponent } from "react"
import { Dialog, DialogContent, DialogTitle, Typography, DialogActions, Button } from "@material-ui/core"
import { useTranslation } from "react-i18next"

interface IConfirmDialogProps {
  open: boolean
  onConfirm: () => void
  onClose: () => void
  heading: string
  text: string
  confirmText: string
  loading?: boolean
}

export const ConfirmDialog: FunctionComponent<IConfirmDialogProps> = (props) => {
  const { t } = useTranslation()
  const { open, onClose, onConfirm, heading, text, confirmText, loading } = props
  return (
    <Dialog open={open}>
      <DialogTitle>{heading}</DialogTitle>
      <DialogContent>
        <Typography>{text}</Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color={"primary"} disabled={loading}>
          {t("general.cancel")}
        </Button>
        <Button onClick={onConfirm} color={"primary"} disabled={loading}>
          {confirmText}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
