import React, { FunctionComponent } from "react"
import { Theme, makeStyles, Grid, Card, Typography } from "@material-ui/core"
import { useTranslation } from "react-i18next"
import { DeleteSweep } from "@material-ui/icons"

const useStyles = makeStyles((theme: Theme) => ({
  iconCircle: {
    backgroundColor: theme.palette.primary.main,
    borderRadius: 90,
    width: 80,
    height: 80,
    display: "flex",
  },
  iconCircleContainer: {
    flex: 1,
  },
  icon: {
    color: "#ffffff",
    width: 40,
    height: 40,
  },
  headingTypo: {
    marginTop: theme.spacing(2.5),
    letterSpacing: 2,
    fontWeight: 100,
  },
}))

interface ILoginLogoProps {}

export const LoginLogo: FunctionComponent<ILoginLogoProps> = (props) => {
  const classes = useStyles()
  const { t } = useTranslation()
  return (
    <Grid container justify="center" direction="column" alignItems="center">
      <Card className={classes.iconCircle}>
        <Grid container justify="center" alignItems="center" className={classes.iconCircleContainer}>
          <DeleteSweep className={classes.icon} />
        </Grid>
      </Card>
      <Typography className={classes.headingTypo} variant="h4" align="center">
        {t("general.wastescanner")}
      </Typography>
    </Grid>
  )
}
