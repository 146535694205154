import React, { FunctionComponent, Fragment, useState, useEffect, useContext } from "react"
import { Theme, makeStyles, Card, Grid, CardContent, CardHeader, TextField, Typography } from "@material-ui/core"
import { useTranslation } from "react-i18next"
import { IEditorItem, EditorItem, EditorItemType, createEditorItem } from "./EditorItem"
import lodash from "lodash"
import { AddEditorItemButton } from "./AddEditorItemButton"
import moment from "moment"
import { createdAtDate } from "../../../../utils/format-date"
import { generateTextFromEditorItems } from "./helpers/generateTextFromEditorItems"
import { generateEditorItemsFromText } from "./helpers/generateEditorItemsFromText"
import { TextReplacementContext } from "../../../../context/TextReplacementContext"
import { Rating } from "../../../../api/graphql/generated/globalTypes"
import { dummyReplacementArray, generateTextFromTemplate } from "../../../../domain/models/TextPlaceholders"

const useStyles = makeStyles((theme: Theme) => ({
  fullHeight: {
    display: "flex",
    flex: 1,
  },
  textEditorCard: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
  },
  textEditor: {
    outline: "none",
  },
  menu: {
    backgroundColor: "#ddd",
  },
  editorItem: {
    display: "flex",
  },
  cardHeading: {
    color: theme.palette.secondary.main,
  },
}))

interface ITextTemplateEditorProps {
  text: string
  title: string
  rating: Rating
  onTextChange: (newText: string) => void
  onTitleChange: (newTitle: string) => void
}

export const TextTemplateEditor: FunctionComponent<ITextTemplateEditorProps> = (props) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { text, title, rating, onTextChange, onTitleChange } = props
  const { placeholders } = useContext(TextReplacementContext)

  const [editorItems, setEditorItems] = useState<IEditorItem[]>(generateEditorItemsFromText(text))

  const [previewText, setPreviewText] = useState<string | null>(null)

  const debounceGeneratePreviewText = lodash.debounce((editorItems, t, setPreviewText) => {
    setPreviewText(generateTextFromTemplate(text, placeholders, dummyReplacementArray(placeholders, rating, t)))
  }, 100)
  useEffect(() => {
    debounceGeneratePreviewText(editorItems, t, setPreviewText)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editorItems, t])

  const onAddEditorItem = (index: number, type: EditorItemType) => {
    const copy = lodash.cloneDeep(editorItems)
    copy.splice(index + 1, 0, createEditorItem(type))
    setEditorItems(copy)
    onTextChange(generateTextFromEditorItems(copy))
  }

  const onRemoveEditorItem = (index: number) => {
    const copy = lodash.cloneDeep(editorItems)
    copy.splice(index, 1)
    setEditorItems(copy)
    onTextChange(generateTextFromEditorItems(copy))
  }

  const onChangeEditorItem = (index: number, value: string) => {
    const copy = lodash.cloneDeep(editorItems)
    copy[index] = { ...copy[index], value }
    setEditorItems(copy)
    onTextChange(generateTextFromEditorItems(copy))
  }

  return (
    <Fragment>
      <Grid container spacing={2} direction={"row"}>
        <Grid item xs={12} className={classes.fullHeight}>
          <Card className={classes.textEditorCard}>
            <CardHeader title={t("text_management.text_editor")} classes={{ root: classes.cardHeading }} />
            <CardContent>
              <TextField fullWidth value={title} onChange={(event) => onTitleChange(event.target.value)} />
            </CardContent>
            <CardContent className={classes.textEditor}>
              <Grid container spacing={1} alignItems={"center"}>
                {editorItems.length <= 0 && (
                  <Grid item className={classes.editorItem}>
                    <AddEditorItemButton index={0} onAdd={(type) => onAddEditorItem(0, type)} />
                  </Grid>
                )}
                {editorItems.map((item, index) => (
                  <Fragment key={index}>
                    <Grid item>
                      <EditorItem
                        item={item}
                        rating={rating}
                        onChange={(value) => onChangeEditorItem(index, value)}
                        onRemove={() => onRemoveEditorItem(index)}
                      />
                    </Grid>
                    <Grid item className={classes.editorItem}>
                      <AddEditorItemButton index={index} onAdd={(type) => onAddEditorItem(index, type)} />
                    </Grid>
                  </Fragment>
                ))}
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} className={classes.fullHeight}>
          <Card className={classes.textEditorCard}>
            <CardHeader title={t("text_management.preview")} classes={{ root: classes.cardHeading }} />
            <CardHeader
              title={title}
              titleTypographyProps={{
                className: classes.textEditor,
              }}
              subheader={t("general.created_at", {
                date: createdAtDate(moment()),
              })}
            />
            <CardContent>
              <Typography style={{ whiteSpace: "pre-line" }} component={"label"}>
                {previewText}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Fragment>
  )
}
