import moment, { Moment } from "moment"
import lodash from "lodash"

export enum TimeLevel {
  DAY = "DAY",
  HOUR = "HOUR",
}

export const tickFormat = (date: Date | Moment, level?: TimeLevel): string | undefined => {
  let tickDateFormat = "DD MMM"
  if (level === TimeLevel.DAY) {
    tickDateFormat = "DD MMM"
  } else if (level === TimeLevel.HOUR) {
    tickDateFormat = "HH:mm"
  }

  if (lodash.isNil(date)) {
    console.log("date nil: ", date)
    return undefined
  }
  if (!lodash.isDate(date) && moment(date).isValid()) {
    // console.log("date: ", moment(date).format(tickDateFormat))
    return moment(date).format(tickDateFormat)
  }
  let momentDate = moment(date)
  if (momentDate.isValid()) {
    return momentDate.format(tickDateFormat)
  }
  console.log("unknown: ", typeof date)
  return undefined
}
