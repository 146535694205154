import { gql } from "apollo-boost"
import { me_short, me_short_me } from "../generated/me_short"

export const ME_SHORT_QUERY = gql`
  query me_short {
    me {
      id
      name
      email
      abilities
    }
  }
`

export type MeShortResult = me_short
export type MeShortUser = me_short_me
