import React, { FunctionComponent, useMemo } from "react"
import { Theme, makeStyles, Card, Grid, Divider, Typography } from "@material-ui/core"
import { useTranslation } from "react-i18next"
import { OVERALL_DATA_KEY, TREND_DATA_KEY, TOWN_DATA_KEY } from "./trend-line"
import {
  TrendGraphOverallIndicator,
  TrendGraphMinMaxIndicator,
  TrendGraphTrendIndicator,
} from "./WertstoffAnalysisTrendGraphTooltip"

const useStyles = makeStyles((theme: Theme) => ({
  card: {
    padding: theme.spacing(1),
    width: "40%",
  },
  overallIndicator: {
    width: 10,
    height: 10,
    borderRadius: "50%",
    background: (props: any) => props.color,
  },
  minMaxIndicator: {
    width: 10,
    height: 10,
    background: (props: any) => props.color,
  },
  trendIndicator: {
    width: 3,
    height: 5,
    background: (props: any) => props.color,
  },
  trendMiddleIndicator: {
    width: 2,
    height: 5,
    background: theme.palette.common.white,
  },
  typo: {
    color: (props: any) => props.color,
  },
}))

interface ITownItem {
  townName: string
  overall: number
}

export const WertstoffAnalysisTrendComparisonGraphTooltip: FunctionComponent<any> = (props) => {
  const { payload, formatter, label, labelFormatter, separator, color, showTrend } = props
  const classes = useStyles({ color })
  const { t } = useTranslation()

  const { min, max, overall, trend, townItems } = useMemo(() => {
    const overallItem = payload?.find((item: any) => item.name === OVERALL_DATA_KEY)
    const trendItem = payload?.find((item: any) => item.name === TREND_DATA_KEY)
    const townItems = payload?.filter((item: any) => item.name === TOWN_DATA_KEY)

    return {
      min: overallItem?.payload?.min ?? {},
      max: overallItem?.payload?.max ?? {},
      overall: overallItem?.payload?.overall ?? 0,
      trend: trendItem?.payload?.trend ?? 0,
      townItems: (townItems || []).map((townStat: any) => {
        return { townName: townStat?.payload?.town?.name, overall: townStat?.payload?.overall }
      }),
    }
  }, [payload])

  return (
    <Card className={classes.card}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Typography color="secondary">{labelFormatter(label)}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={1} container justify="center" alignContent="center">
          <TrendGraphOverallIndicator color={color} />
        </Grid>
        <Grid item xs={11}>
          <Typography className={classes.typo}>{`${t("graph.trend.tooltip.overall")} ${separator} ${formatter(
            overall,
          )}`}</Typography>
        </Grid>
        <Grid item xs={1} container justify="center" alignContent="center">
          <TrendGraphMinMaxIndicator color={color} />
        </Grid>
        <Grid item xs={11}>
          <Typography className={classes.typo}>{`${t("graph.trend.tooltip.max")} ${separator} ${formatter(
            max.value,
          )} (${max?.town?.name})`}</Typography>
        </Grid>
        <Grid item xs={1} container justify="center" alignContent="center">
          <TrendGraphMinMaxIndicator color={color} />
        </Grid>
        <Grid item xs={11}>
          <Typography className={classes.typo}>{`${t("graph.trend.tooltip.min")} ${separator} ${formatter(
            min.value,
          )} (${min?.town?.name})`}</Typography>
        </Grid>
        {showTrend && (
          <>
            <Grid item xs={1} container justify="center" alignContent="center">
              <TrendGraphTrendIndicator color={color} />
            </Grid>
            <Grid item xs={11}>
              <Typography className={classes.typo}>{`${t("graph.trend.tooltip.trend")} ${separator} ${formatter(
                trend,
              )}`}</Typography>
            </Grid>
          </>
        )}
        {!!townItems && (
          <>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <Typography className={classes.typo} align="left">
                Ø {t("general.towns")}
              </Typography>
            </Grid>
            {townItems.map((townItem: ITownItem) => (
              <Grid item xs={12} key={townItem.townName}>
                <Typography className={classes.typo} align="left">
                  {`${townItem.townName}: ${formatter(townItem.overall)}`}
                </Typography>
              </Grid>
            ))}
          </>
        )}
      </Grid>
    </Card>
  )
}
