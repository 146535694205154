import React, { FunctionComponent, useState, useEffect, Fragment } from "react"
import { makeStyles, Theme, Button, Paper, Grid, Card, TextField, InputAdornment } from "@material-ui/core"
import { useTranslation } from "react-i18next"
import { RootGrid } from "../partials/layout/RootGrid"
import { UserTable } from "../partials/user-management/UserTable"
import lodash from "lodash"
import { ManageUserDialog } from "../partials/user-management/manage-dialog/ManageUserDialog"
import { UserAndPermissions } from "../../api/graphql/query/users-and-assigned-associations"
import { TopBar } from "../partials/heading/TopBar"
import { Search } from "@material-ui/icons"
import { ManageUserContextProvider } from "../partials/user-management/manage-dialog/context/ManageUserContext"

const useStyles = makeStyles((theme: Theme) => ({
  controlCard: {
    padding: theme.spacing(2),
  },
}))

interface IUserManagementPageProps {}

export const UserManagementPage: FunctionComponent<IUserManagementPageProps> = (props) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const [searchTerm, setSearchTerm] = useState<string>("")
  const [toggleRefetch, setToggleRefetch] = useState<boolean>(false)
  const [userAndPermissions, setUserAndPermissions] = useState<UserAndPermissions | null>(null)
  const [userManagementDialogOpen, setUserManagementDialogOpen] = useState<boolean>(false)

  useEffect(() => {
    setUserManagementDialogOpen(!lodash.isNil(userAndPermissions))
  }, [userAndPermissions])

  const onUserManagementDialogClose = () => {
    setUserManagementDialogOpen(false)
    setUserAndPermissions(null)
  }

  const onUserManagementDialogSuccess = () => {
    onUserManagementDialogClose()
    setToggleRefetch(!toggleRefetch)
  }

  return (
    <Fragment>
      {/* DIALOGS */}
      {userManagementDialogOpen && (
        <ManageUserContextProvider onSuccess={onUserManagementDialogSuccess} userAndPermissions={userAndPermissions}>
          <ManageUserDialog onCloseDialog={onUserManagementDialogClose} isOpen={userManagementDialogOpen} />
        </ManageUserContextProvider>
      )}
      {/* VIEW */}
      <TopBar>{t("user_management.heading")}</TopBar>
      <RootGrid spacing={2}>
        <Grid item xs={12}>
          <Card className={classes.controlCard}>
            <Grid container spacing={2} justify={"center"}>
              <Grid item xs>
                <TextField
                  value={searchTerm}
                  onChange={(event) => setSearchTerm(event.target.value)}
                  fullWidth
                  placeholder={t("general.search")}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Search />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item>
                <Button variant="contained" color="primary" onClick={() => setUserManagementDialogOpen(true)}>
                  {t("user_management.add_user")}
                </Button>
              </Grid>
            </Grid>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Paper>
            <UserTable
              setUserAndPermissionsToEdit={setUserAndPermissions}
              toggleRefetch={toggleRefetch}
              searchTerm={searchTerm}
            />
          </Paper>
        </Grid>
      </RootGrid>
    </Fragment>
  )
}
