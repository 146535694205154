import { gql } from "apollo-boost"
import {
  townsForAssociation,
  townsForAssociationVariables,
  townsForAssociation_association_towns,
  townsForAssociation_association_towns_fractionMapping_mappedFractions,
} from "../generated/townsForAssociation"

export const TOWNS_FOR_ASSOCIATION_QUERY = gql`
  query townsForAssociation($id: ID!) {
    association(id: $id) {
      towns {
        documentDeletionRangeInDays
        id
        name
        zipCode
        latitude
        longitude
        radius
        defaultClassifiers {
          tourType
          classifierId
        }
        externalTown {
          id
          externalId
          type
        }
        tours {
          id
        }
        fractionMapping {
          tourType
          mappedFractions {
            fraction
            mappedMaterialType
          }
        }
        displayOnlyAutomaticAnalysis
        ratingSchema {
          residual {
            percentageFrom
            percentageTo
          }
          organic {
            percentageFrom
            percentageTo
          }
        }
        boundary {
          id
          townName
        }
      }
    }
  }
`

export type TownsForAssociationResult = townsForAssociation
export type TownsForAssociationVariables = townsForAssociationVariables
export type TownForAssociation = townsForAssociation_association_towns
export type FractionMapping = townsForAssociation_association_towns_fractionMapping_mappedFractions
