import React, { FunctionComponent } from "react"
import {
  Theme,
  makeStyles,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Grid
} from "@material-ui/core"
import { useTranslation } from "react-i18next"
import { ValidateTourWarning } from "../../../api/graphql/query/validate-tour"
import { Warning } from "@material-ui/icons"

const useStyles = makeStyles((theme: Theme) => ({
  dialogRoot: {
    minHeight: "200px",
    minWidth: "360px"
  },
  icon: {
    color: "#FFC857"
  }
}))

interface ITourWarningsDialogProps {
  isOpen: boolean
  isInCreateMode: boolean
  onCloseDialog: () => void
  onConfirmClicked: () => void
  warnings: ValidateTourWarning[]
}

export const TourWarningsDialog: FunctionComponent<ITourWarningsDialogProps> = props => {
  const classes = useStyles()
  const { t } = useTranslation()
  const {
    isInCreateMode,
    isOpen,
    onCloseDialog,
    onConfirmClicked,
    warnings
  } = props

  return (
    <Dialog
      open={isOpen}
      fullWidth={false}
      className={classes.dialogRoot}
      classes={{ paper: classes.dialogRoot }}
    >
      <DialogTitle>
        {isInCreateMode
          ? t("tour.dialog.create_anyway.title")
          : t("tour.dialog.update_anyway.title")}
      </DialogTitle>
      <DialogContent>
        {warnings.map(warning => (
          <Grid container spacing={2} key={warning.id}>
            <Grid container item justify="center" alignItems="center">
              <Grid item xs>
                <Warning className={classes.icon} />
              </Grid>
              <Grid item xs={11}>
                {t(`tour_page.warnings.${warning.type.toLowerCase()}`)}
              </Grid>
            </Grid>
          </Grid>
        ))}
      </DialogContent>
      <DialogActions
        style={{ paddingBottom: 10, paddingRight: 10 }}
        key={"cancel"}
      >
        <Button color="primary" onClick={() => onCloseDialog()}>
          {t("general.cancel")}
        </Button>
        <Button
          color="primary"
          variant={"contained"}
          onClick={() => onConfirmClicked()}
        >
          {isInCreateMode
            ? t("tour.dialog.create_anyway.button")
            : t("tour.dialog.update_anyway.button")}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
